import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'


export class modeloFolio {
  Usuario!: Usuario
  Sucursal!: Sucursal
  tipoDocumento!: string
  totalDisponible!: number
  alerta!: number
  codigo!: number

}
