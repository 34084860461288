import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'

export class modeloSolicitudBancaria {
  nombreResponsable!: string
  fechaSolicitud!: string
  motivoPago!: string
  jefeAutoriza!: string
  montoPago!: number
  empresaAsumeGasto!: string
  asumeFactura!: string
  inversion!: string
  nombreTransferencia!: string
  rut!: string
  NumeroCuenta!: string
  estado!: string
  archivos!: any
  banco!: string
  bancoCliente!: string
  NumeroCuentaAsume!: string
  tipoCuenta !: string
  link!: string
  correo!: string
  idSucursal!: number
  idUsuario!: number
  razonRechazo!: string;
  horaActual!: string
  createdAt!: Date;
  updatedAt!: Date;
  RutEmpresa!: string;
  razonApruebo!: string;
  Usuario!: Usuario
  perfilUsuario!: string
  rolId!: number
  autoriza!:string
  fotoFactura!: any
  estadoFactura!: string;
  confirmado!:string;
  id!:any;
  confirmadoPorFinanza!:string;
  notificarPlazo!:string;
  idArriendo!:string;
}
