<mat-expansion-panel hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title> Filtros </mat-panel-title>
    <mat-panel-description>Para visualizar, Presiona Aqui 
      <mat-icon>touch_app_white_18dp</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
    <form [formGroup]="Filtro">
      <mat-grid-list cols = "3" rowHeight = "70px">
        <mat-grid-tile >
        <div class="col" >
        <mat-form-field class="full-width" style="width:140%"  [formGroup]="formFilter">
          <mat-select
            placeholder="Bodegas"
            formControlName="idBodegas"
          >
            <mat-option
              *ngFor="let bodegas of lista_bodegas"
              [value]="bodegas.id"
            >
              {{ bodegas.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile >
        <div class="col">
          <mat-form-field class="full-width" style="width:140%"  [formGroup]="formFilter">
            <mat-select
              placeholder="Categorias"
              formControlName="idCategorias"
            >
 
              <mat-option
                *ngFor="let categorias of lista_categorias"
                [value]="categorias.id"
              >
                {{ categorias.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile >
        <div class="col" >
          <mat-form-field class="full-width" style="width:140%"  [formGroup]="formFilter">
            <mat-select
              placeholder="Productos"
              formControlName="idProductos">
              <mat-option
                *ngFor="let productos of lista_productos"
                [value]="productos.id"
              >
                {{ productos.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile >
        <div class="col">
          <mat-form-field class="full-width" style="width:120%"  [formGroup]="formFilter">
            <input matInput placeholder="Stock" formControlName="idStock" type="number" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile >
        <div class="col">
          <mat-form-field class="full-width" style="width:120%"  [formGroup]="formFilter">
            <input matInput placeholder="Codigo de barras" formControlName="CodigoBarras" type="number" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      </mat-grid-list>
  
    </form>


    <button style="margin-right: 9rem" mat-raised-button (click)="filtrar()" color="accent">
      Filtrar
 </button>
</mat-expansion-panel>



