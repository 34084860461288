<h1>Registro de Guia de Despacho</h1>
<!-- Modal de carga -->
<div *ngIf="isLoading" [ngClass]="{
  'modal-overlay': !pdfUrl || estado === 'ERROR',
  'modal-overlay2': pdfUrl}">
  <div [ngClass]="{
    'modal-content': !pdfUrl || estado === 'ERROR',
    'modal-content2': pdfUrl}">
    <div *ngIf="mostrarSpinner" class="spinner"></div>
    <div>
      <p [ngClass]="{
        'aprobado': estado === 'APROBADO',
        'rechazado': estado === 'RECHAZADO' || estado === 'ERROR',
        'pendiente': estado === 'PENDIENTE' || estado === 'APROBADO CON REPAROS'
      }" class="p-contenido">{{ mensajeSII }}</p>
      <p *ngIf="estado != '' && !pdfUrl  && estado !='ERROR'">Generando PDF</p>
    </div>

    <p *ngIf="estado != '' && estado !='ERROR'">Trackid: <b>{{ trackId }}</b></p>

    <p *ngIf="estado === 'RECHAZADO'">Motivo: {{ razonRechazada }}</p>

    <!-- Mostrar PDF solo si hay una URL y el estado es 'RECHAZADO' -->
    <div *ngIf="pdfUrl" class="pdf-container">
      <iframe [src]="pdfUrl" class="iframe-pdf"></iframe>
    </div>

    <button *ngIf="estado != '' && pdfUrl && estado != 'ERROR'" class="btn-cerrar" (click)="cerrarModal()">Cerrar</button>
    <button *ngIf="estado === 'ERROR'" class="btn-cerrar" (click)="cerrarModalReintentar()">Cerrar</button>
  </div>
</div>



<div class="mat-elevation-z8">
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> Guia de Despacho </mat-panel-title>
      <mat-panel-description>
        Para Crear una Guia de Despacho Presiona Aqui
        <mat-icon>touch_app_white_18dp</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-horizontal-stepper linear>
      <!-- Informacion Basica -->
      <mat-step [stepControl]="infBasica" completed="false">
        <form [formGroup]="infBasica" novalidate>
          <ng-template matStepLabel>Informacion Basica</ng-template>
          <div class="row">
            <!-- bodega  -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-select
                  placeholder="Ingresa Bodega"
                  formControlName="bodega"
                >
                  <mat-option
                    *ngFor="let bodega of bodega"
                    value="{{ bodega.nombre }}"
                  >
                    {{ bodega.nombre }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- cliente -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Clientes</mat-label>
                <input
                  placeholder="Ingrese Cliente"
                  matInput
                  formControlName="cliente"
                  [(ngModel)]="termino"
                  [matAutocomplete]="auto"
                  (input)="getClienteInfacturacion()"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #auto="matAutocomplete"
                  (optionSelected)="opcionSeleccionada($event)"
                >
                  <mat-option *ngFor="let Cliente of cliente" [value]="Cliente">
                    {{ Cliente.nombre }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-select
                  placeholder="Seleccione empresa"
                  formControlName="empresa"
                  (selectionChange)="seleccionarEmpresa($event)"
                >
                  <mat-option
                    *ngFor="let empresa of empresas"
                    value="{{ empresa.rut }}"
                  >
                    {{ empresa.nombre }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- rutCliente -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Ingresa Rut"
                  formControlName="rutCliente"
                  [value]="this.rutCliente"
                  readonly="»readonly»"
                />
              </mat-form-field>
            </div>
            <!-- giro -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Ingresa Giro"
                  formControlName="giro"
                  [value]="this.giro"
                  readonly="»readonly»"
                />
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 1 -->
          <div class="row">
            <!-- Direccion -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Ingresa Direccion"
                  formControlName="direccion"
                  [value]="this.direccion"
                  readonly="»readonly»"
                />
              </mat-form-field>
            </div>

            <!-- Comuna -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Ingresa Comuna"
                  formControlName="comuna"
                  [value]="this.comuna"
                  readonly="»readonly»"
                />
              </mat-form-field>
            </div>
            <!-- ciudad -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Ingresa ciudad"
                  formControlName="ciudad"
                  [value]="this.ciudad"
                  readonly="»readonly»"
                />
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 2 -->
          <div class="row">
            <!-- contacto -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Ingresa Contacto"
                  formControlName="contacto"
                  [value]="this.contacto"
                  readonly="»readonly»"
                />
              </mat-form-field>
            </div>

            <!-- Plazo Entrega -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Ingresa Plazo Entrega"
                  formControlName="plazoEntrega"
                  type="number"
                />
              </mat-form-field>
            </div>

            <!-- vendedor  -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Selecciona Vendedor </mat-label>
                <mat-select formControlName="vendedor">
                  <mat-option value="Finanzas Grupo Firma">
                    Finanzas Grupo Firma
                  </mat-option>
                  <mat-option value="Karla Barreto">Karla Barreto</mat-option>
                  <mat-option value="Licitaciones GF"
                    >Licitaciones GF</mat-option
                  >
                  <mat-option value="Maryari Vegas">Maryari Vegas</mat-option>
                  <mat-option value="Patricio Muñoz">Patricio Muñoz</mat-option>
                  <mat-option value="Rent a Car Bio Bio"
                    >Rent a Car Bio Bio</mat-option
                  >
                  <mat-option value="Rent a Car Metropolitano">
                    Rent a Car Metropolitano
                  </mat-option>
                  <mat-option value="Rent a Car Maule Curico">
                    Rent a Car Maule Curico
                  </mat-option>
                  <mat-option value="Rent a Car Maule Talca">
                    Rent a Car Maule Talca
                  </mat-option>
                  <mat-option value="Rent a Car Maule Linares">
                    Rent a Car Maule Linares
                  </mat-option>
                  <mat-option value="Rent a Car Maule O'Higgins Rancagua">
                    Rent a Car O'Higgins Rancagua
                  </mat-option>
                  <mat-option value="Solanch Macarena Tejos Carrasco">
                    Solanch Macarena Tejos Carrasco
                  </mat-option>
                  <mat-option value="Sucursal las Condes">
                    Sucursal las Condes
                  </mat-option>
                  <mat-option value="Williams De Sousa"
                    >Williams De Sousa</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <!-- estado -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label> Estado</mat-label>
                <input
                  matInput
                  value="Pendiente"
                  placeholder="Ingresa estado"
                  formControlName="estado"
                  required
                  readonly="»readonly»"
                />
              </mat-form-field>
            </div>
          </div>
          <!-- boton  de acciones-->
          <div>
            <button mat-button matStepperNext>
              Siguiente <mat-icon>arrow_forward_ios_white_18dp</mat-icon>
            </button>
          </div>
        </form>
      </mat-step>

      <!-- Informacion Transportista -->
      <mat-step [stepControl]="infTransporte" completed="false">
        <form [formGroup]="infTransporte" novalidate>
          <ng-template matStepLabel>Informacion Transporte</ng-template>
          <div class="row">
            <!-- rut tranportista -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Ingresa Rut Transporte"
                  formControlName="rutTransporte"
                  maxlength="10"
                />
                <mat-error *ngIf="infTransporte.get('rutTransporte').hasError('invalidRut')">
                  El RUT no es válido. Debe ser en el formato 12345678-9.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Ingresa Rut Chofer"
                  formControlName="rutChofer"
                  maxlength="10"
                />
                <mat-error *ngIf="infTransporte.get('rutChofer').hasError('invalidRut')">
                  El RUT no es válido. Debe ser en el formato 12345678-9.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <!-- Patente -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Patente"
                  formControlName="Patente"
                  maxlength="6"
                />
                <mat-error *ngIf="infTransporte.get('Patente').hasError('invalidPatente')">
                  La patente no es válida. Debe ser en el formato ABCD12.
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Nombre Chofer -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Nombre Chofer"
                  formControlName="NombreChofer"
                />
              </mat-form-field>
            </div>
          </div>
          
          <div class="row">
            <!-- Direccion Destino -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Dirección destino"
                  formControlName="DireccionDestino"
                />
              </mat-form-field>
            </div>
            <!-- Comuna Destino -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Comuna destino"
                  formControlName="ComunaDestino"
                />
              </mat-form-field>
            </div>
            <!-- Ciudad Destino -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Ciudad destino"
                  formControlName="CiudadDestino"
                />
              </mat-form-field>
            </div>
            <!-- Tipo traslado  -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Selecciona Tipo Traslado </mat-label>
                <mat-select formControlName="tipoTraslado" (selectionChange)="onSelectionChange($event)">
                  <mat-option [value]="{ nombre: 'Operacion constituye venta', tipo: 1 }">
                    Operacion constituye venta
                  </mat-option>
                  <mat-option [value]="{ nombre: 'Ventas por efectuar', tipo: 2 }">Ventas por efectuar</mat-option>
                  <mat-option [value]="{ nombre: 'Consignaciones', tipo: 3 }"
                    >Consignaciones</mat-option
                  >
                  <mat-option [value]="{ nombre: 'Entrega gratuita', tipo: 4 }">Entrega gratuita</mat-option>
                  <mat-option [value]="{ nombre: 'Traslados Internos', tipo: 5 }">Traslados Internos</mat-option>
                  <mat-option [value]="{ nombre: 'Otros traslados no venta', tipo: 6 }"
                    >Otros traslados no venta</mat-option
                  >
                  <mat-option [value]="{ nombre: 'Guia de devolucion', tipo: 7 }">
                    Guía de devolución
                  </mat-option>
                  <mat-option [value]="{ nombre: 'Traslado para exportacion (no venta)', tipo: 8 }">
                    Traslado para exportación (no venta)
                  </mat-option>
                  <mat-option [value]="{ nombre: 'Venta para exportacion', tipo: 9 }">
                    Venta para exportación
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          
          <!-- boton  de acciones-->
          <div>
            <button mat-button matStepperNext>
              Siguiente <mat-icon>arrow_forward_ios_white_18dp</mat-icon>
            </button>
          </div>
        </form>
      </mat-step>

      <!-- Informacion Pago -->
      <mat-step [stepControl]="infPago">
        <form [formGroup]="infPago" novalidate>
          <ng-template matStepLabel>Informacion de Pago</ng-template>
          <div class="row">
            <!-- folio -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Folio</mat-label>
                <input matInput formControlName="folio" [value]="this.folio" readonly="»readonly»" />
              </mat-form-field>
            </div>
            <!-- Tipo documento -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Tipo Documento</mat-label>
                <input
                  matInput
                  value="Facturacion"
                  formControlName="tipoDocumento"
                  required
                  readonly="»readonly»"
                />
              </mat-form-field>
            </div>
            <!-- Fecha -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  [matDatepicker]="picker"
                  placeholder="Fecha"
                  formControlName="fechaCreacion"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error
                  *ngIf="infPago.controls['fechaCreacion'].hasError('required')"
                  >Este campo es <strong>requerido. Presiona el icono</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Válida por -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Válida por</mat-label>
                <input
                  matInput
                  placeholder="Ingresa validez"
                  formControlName="valida"
                  type="number"
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!-- observacionesPago -->
              <mat-form-field appearance="legacy">
                <mat-label>Observaciones Pago</mat-label>
                <textarea
                  matInput
                  type="string"
                  placeholder="Ingrese observaciones"
                  formControlName="observacionesPago"
                ></textarea>
              </mat-form-field>
            </div>
            <!-- forma de pago -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Tipo de Pago </mat-label>
                <mat-select formControlName="formaPago">
                  <mat-option value="cheque">Cheque</mat-option>
                  <mat-option value="credito">Credito</mat-option>
                  <mat-option value="DepositoEfectivo"
                    >Deposito en efectivo</mat-option
                  >
                  <mat-option value="efectivo">Efectivo</mat-option>
                  <mat-option value="tarjetaCredito"
                    >Tarjeta Credito</mat-option
                  >
                  <mat-option value="tarjetaDebito">Tarjeta Debito</mat-option>
                  <mat-option value="transferenicaElectronica"
                    >Transferencia Electronica</mat-option
                  >
                  <mat-option value="notaCreditoDevolucion"
                    >Nota Credito Devolcuion</mat-option
                  >
                  <mat-option value="webPay">Webpay</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Monedas -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Tipo de Moneda </mat-label>
                <mat-select formControlName="moneda">
                  <mat-option value="pesos">Pesos</mat-option>
                  <mat-option value="uf">UF</mat-option>
                  <mat-option value="dolar">Dolar</mat-option>
                  <mat-option value="euro">Euro</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- ordenAlfabetico -->
            <div class="col">
              <mat-slide-toggle>orden Alfabetico</mat-slide-toggle>
            </div>
          </div>

          <div>
            <button mat-button matStepperPrevious>
              <mat-icon>arrow_back_ios_new_white_18dp</mat-icon> Anterior
            </button>
            <button mat-button matStepperNext>
              Siguiente <mat-icon>arrow_forward_ios_white_18dp</mat-icon>
            </button>
          </div>
        </form>
      </mat-step>

      <!-- Informacion Producto -->
      <mat-step [stepControl]="infProducto">
        <form [formGroup]="infProducto" novalidate (ngSubmit)="onSubmit()">
          <ng-template matStepLabel>Informacion de Producto</ng-template>
          <div formArrayName="productos">
            <button
              (click)="addProductos()"
              (click)="OperacionTotal()"
              type="button"
              mat-mini-fab
              color="accent"
            >
            </button>
            <hr />
            <ng-container
              *ngFor="let producto of productos.controls; let i = index"
            >
              <div [formGroupName]="i">
                <mat-card>
                  <div class="col">
                    <mat-card-content>
                      <mat-card-header>
                        <h1>Detalle de Producto</h1>
                      </mat-card-header>
                      <hr />
                      <div class="row">
                        <!-- Producto -->
                        <div class="col">
                          <mat-form-field appearance="legacy">
                            <mat-label>Producto</mat-label>
                            <mat-select formControlName="producto" (selectionChange)="getIdProducto($event, i)">
                              <mat-option
                                *ngFor="let productos of productosServicio"
                                value="{{ productos.nombre }}"
                                >{{ productos.nombre }}</mat-option
                              >
                            </mat-select>

                          </mat-form-field>
                        </div>
                        <!-- Descripción -->
                        <div class="col">
                          <mat-form-field appearance="legacy">
                            <mat-label>Descripción</mat-label>
                            <input
                              (ngModelChange)="OperacionTotal()"
                              matInput
                              formControlName="descripcion"
                              type="string"
                            />
                          </mat-form-field>
                        </div>
                        <!-- Cantidad -->
                        <div class="col">
                          <mat-form-field appearance="legacy">
                            <mat-label>Cantidad</mat-label>
                            <input
                              (ngModelChange)="OperacionTotal()"
                              matInput
                              formControlName="cantidad"
                              type="number"
                            />
                          </mat-form-field>
                        </div>
                        <!-- Precio neto -->
                        <div class="col">
                          <mat-form-field appearance="legacy">
                            <mat-label>Precio neto</mat-label>
                            <input
                              matInput
                              (ngModelChange)="OperacionTotal()"
                              formControlName="precioNeto"
                              type="number"
                            />
                            <span matPrefix>$&nbsp;</span>
                          </mat-form-field>
                        </div>
                        <!-- eliminar -->
                        <button
                          mat-mini-fab
                          color="warn"
                          (click)="deleteProducto(i)"
                          (click)="OperacionTotal()"
                        >
                          x
                        </button>
                      </div>
                      <div class="row">
                        <!-- Af/Ex -->
                        <div class="col">
                          <mat-form-field appearance="legacy">
                            <mat-label>Af/Ex</mat-label>
                            <input
                              matInput
                              formControlName="AfEx"
                              type="string"
                              [value]="this.afecto"
                              readonly="»readonly»"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                    </mat-card-content>
                  </div>
                </mat-card>
              </div>
            </ng-container>
            <hr />

            <!-- informacion Sub total  -->
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>Informacion del sub total </mat-panel-title>
                <mat-panel-description>
                  Para Observa el SubTotal Presiona Aqui
                  <mat-icon>touch_app_white_18dp</mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="col">
                <mat-card-content>
                  <mat-card-header>
                    <h1>Detalle de Sub Total Productos</h1>
                  </mat-card-header>
                  <hr />
                  <div class="row">
                    <!-- Sub Total-->
                    <ng-container
                      *ngFor="
                        let resultado of this.respuestaTotal;
                        let i = index
                      "
                    >
                      <div class="col">
                        <mat-form-field appearance="legacy">
                          <mat-label>{{ 'Producto' }} {{ i + 1 }}</mat-label>
                          <input matInput readonly="»readonly»" />{{
                            resultado
                          }}
                          <span matPrefix>$&nbsp;</span>
                        </mat-form-field>
                      </div>
                    </ng-container>
                  </div>
                </mat-card-content>
              </div>
            </mat-expansion-panel>
          </div>
          <hr />
          <!-- informacion final  -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Total de Pago </mat-panel-title>
              <mat-panel-description>
                Para Observa el Total a pagar y las Observaciones Presiona Aqui
                <mat-icon>touch_app_white_18dp</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <mat-card>
                  <mat-card-content>
                    <div class="row">
                      <div class="col">
                        <mat-form-field appearance="legacy">
                          <mat-label>Todo los Sub Totales</mat-label>
                          <input
                            matInput
                            formControlName="subTotal"
                            readonly="»readonly»"
                          />{{ this.respuestaTotal }}
                          <span matPrefix>$&nbsp;</span>
                        </mat-form-field>
                      </div>
                      <div class="col">
                        <mat-form-field appearance="legacy">
                          <mat-label>Sub Total. </mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="respTotal"
                            [value]="this.respTotal"
                            readonly="»readonly»"
                          />
                          <span matPrefix>$&nbsp;</span>
                        </mat-form-field>
                      </div>
                      <div class="col">
                        <mat-form-field appearance="legacy">
                          <mat-label>IVA</mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="iva"
                            [value]="this.iva"
                            readonly="»readonly»"
                          />
                          <span matPrefix>%&nbsp;</span>
                        </mat-form-field>
                      </div>
                      <div class="col">
                        <mat-form-field appearance="legacy">
                          <mat-label>Total</mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="totalGeneral"
                            [value]="this.totalGeneral"
                            readonly="»readonly»"
                          />
                          <span matPrefix>$&nbsp;</span>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <!-- comentarios -->
                        <mat-form-field appearance="standard">
                          <mat-label>Observaciones o Comentarios</mat-label>
                          <textarea
                            matInput
                            type="string"
                            placeholder="Ingrese observaciones"
                            formControlName="comentarios"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </mat-expansion-panel>
          <br />
          <div>
            <button mat-button matStepperPrevious>
              <mat-icon>arrow_back_ios_new_white_18dp</mat-icon> Anterior
            </button>
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="infProducto.invalid"
            >
              <mat-icon>send</mat-icon> Enviar
            </button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-expansion-panel>
</div>