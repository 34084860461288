<br>
<h1> Modulo Hostal </h1>

<app-hostal-egresos-credito-formulario></app-hostal-egresos-credito-formulario>

<div style="margin-left: 25%;">
    <ul class="legend">
      <li><span class="superawesome"></span> Pagados</li>
  <li><span class="awesomeN"></span> Proximos pagos a vencerse</li>
      <li><span class="awesome"></span> Pagos vencidos</li>
    </ul>
    </div>
    <br>
    <br>    
    <div style="text-align:center">
    <button mat-raised-button color="accent"  (click)="ActualizarCalendario()">
      Actualizar Calendario
    </button>   
    <full-calendar  [options]="calendarOptions"></full-calendar>
    <mat-divider></mat-divider>
    <br>
    