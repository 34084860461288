import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { IngresosAbogado } from '@app/_models/abogados/ingresosAbogado'
import { Empresa } from '@app/_models/shared/empresa'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Usuario } from '@app/_models/shared/usuario'
import { HostalService } from '@app/_pages/hostal/hostal.service'
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'
import { AbogadosService } from '@app/_pages/abogados/abogados.service'

@Component({
  selector: 'app-abogado-ingresos-acciones',
  templateUrl: './abogado-ingresos-acciones.component.html',
  styleUrls: ['./abogado-ingresos-acciones.component.scss'],
  providers: [
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } }
  ]
})

export class AbogadoIngresosAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  empresa = new Empresa();

  clickButton = true
  public previsualizacion: any
  public previsualizacionContrato: any
  public loading: boolean
  panelOpenState = false
  tiposCliente: string[]
  estadoPagos: string[]
  tiposPagos: string[]
  tiposDocumentos: string[]
  idIngresoAbogado: number;
  public archivos: any = []
  public archivos2: any = []
  dataArrayImg = []
  dataArrayContrato = []
  

  addressForm = this.fb.group({
    idSucursal: [null, Validators.required],
    descripcionIngreso: [null, Validators.required],
    monto: [null, Validators.required],
    nAutorizacion: [null, Validators.required],
    fecha: [null, Validators.required],
    nombreCliente: ['', Validators.required],
    tipoCliente: [null, Validators.required],
    movil: ['', Validators.required],
    nDocumento: ['', Validators.required],
    correo: ['', Validators.required],
    referenciaCliente: [null, Validators.required],
    tipoPago: [null, Validators.required],
    tipoDocumento: [null, Validators.required],
    img: null,
    contrato: null

  });

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private abogadoService: AbogadosService,
    private hostalService: HostalService,
    private sucursalService: SucursalSharedService,
    private sanitizer: DomSanitizer,
    private empresaService: EmpresaSharedService,
    private http: HttpClient,
  ) {

    this.tiposCliente = [
      'Asesoria empresarial',
      'Civil',
      'Familia',
      'Laboral',
      'Penal'
    ];

    this.tiposPagos = [
      'Efectivo',
      'Transferencia',
      'Cheque'
    ];

    this.estadoPagos = [
      'PENDIENTE',
      'PAGADO'
    ];

    this.tiposDocumentos = [
      'Boleta',
      'Factura',
      'Recibo de dinero'
    ];

  }

  ngOnInit(): void {

    this.idIngresoAbogado = parseInt(localStorage.getItem('idIngresoAbogado'))

    this.getIngresoAbogadoById(this.idIngresoAbogado);

  }

  ingresoAbogado = new IngresosAbogado();

  get formControl() {

    return this.addressForm.controls

  }
  idSucursal: number;
  //METODO QUE TRAE LOS INGRESOS, 
  //ASIGNA LAS IMAGENES A LA PREVISUALIZACION
  getIngresoAbogadoById(id: number) {

    this.abogadoService.getIngresoAbogadoByIdImage(id).subscribe((data: IngresosAbogado) => {

      this.ingresoAbogado = data;

      this.formControl.fecha.setValue(this.ingresoAbogado.fecha);
      this.formControl.nombreCliente.setValue(this.ingresoAbogado.nombreCliente);
      this.formControl.idSucursal.setValue(this.ingresoAbogado.idSucursal);
      this.formControl.tipoCliente.setValue(this.ingresoAbogado.tipoCliente);
      this.formControl.correo.setValue(this.ingresoAbogado.correo);
      this.formControl.movil.setValue(this.ingresoAbogado.movil);
      this.formControl.tipoPago.setValue(this.ingresoAbogado.tipoPago);
      this.formControl.monto.setValue(this.ingresoAbogado.monto);
      this.formControl.nAutorizacion.setValue(this.ingresoAbogado.nAutorizacion);
      this.formControl.tipoDocumento.setValue(this.ingresoAbogado.tipoDocumento);
      this.formControl.nDocumento.setValue(this.ingresoAbogado.nDocumento);
      this.formControl.descripcionIngreso.setValue(this.ingresoAbogado.descripcionIngreso);
      this.idSucursal = this.ingresoAbogado.idSucursal;
      this.formControl.referenciaCliente.setValue(this.ingresoAbogado.referenciaCliente);

      this.previsualizacion = this.ingresoAbogado.img;
      this.previsualizacionContrato = this.ingresoAbogado.contrato;

    })

  }

  onSubmit() {

    const ingresoAbogado: IngresosAbogado = new IngresosAbogado();

    if (confirm('¿Seguro que desea actualizar ingresos de Firma abogados?')) {

      if (this.ingresoAbogado.img.length > 0 && this.dataArrayImg.length > 0) {
        ingresoAbogado.img = this.dataArrayImg;
      }

      if (this.ingresoAbogado.contrato.length > 0 && this.dataArrayContrato.length > 0) {
        ingresoAbogado.contrato = this.dataArrayContrato
      }
      ingresoAbogado.idSucursal = this.idSucursal;
      ingresoAbogado.monto = this.addressForm.value.monto;
      ingresoAbogado.nAutorizacion = this.addressForm.value.nAutorizacion;
      ingresoAbogado.fecha = this.addressForm.value.fecha;
      ingresoAbogado.nombreCliente = this.addressForm.value.nombreCliente;
      ingresoAbogado.tipoCliente = this.addressForm.value.tipoCliente;
      ingresoAbogado.movil = this.addressForm.value.movil;
      ingresoAbogado.correo = this.addressForm.value.correo;
      ingresoAbogado.tipoPago = this.addressForm.value.tipoPago;
      ingresoAbogado.referenciaCliente = this.addressForm.value.referenciaCliente;
      ingresoAbogado.nDocumento = this.addressForm.value.nDocumento;
      ingresoAbogado.tipoDocumento = this.addressForm.value.tipoDocumento;
      ingresoAbogado.descripcionIngreso = this.addressForm.value.descripcionIngreso;

      this.actualizarIngresoAbogado(this.idIngresoAbogado, ingresoAbogado);

    }

  }

  actualizarIngresoAbogado(id: number, ingresosAbogado: IngresosAbogado) {
    this.abogadoService.updateIngresoAbogado(id, ingresosAbogado)
      .subscribe(
        updatedIngreso => {
          this.subirIMG(this.archivos);
          this.subirIMG(this.archivos2);
          this.snackBar.open('Ingreso abogado editado con éxito',
          'Cerrar',{ duration: 2000, verticalPosition: 'top'}
          )
          this.addressForm.reset();
          this.abogadoService.closeDialogModal();
          window.location.reload()
        },
        error => {
          console.error('Error al actualizar el ingreso:', error);
          if (error && error.error) {
            console.error('Mensaje de error del servidor:', error.error);
          }
        }
      );
  }

  getEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((empresa) => {
        empresa.Sucursals = Object.values(empresa.Sucursals).sort((a, b) => (a.razonSocial > b.razonSocial) ? 1 : -1);
        this.empresa = empresa;
      });
  }

  get f() {
    return this.addressForm.controls
  }

  closeDialog() {
    this.abogadoService.closeDialogModal()
    window.location.reload()
  }

  //CAPTURA LA IMAGEN
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'ingresoAbogado' + Date.now() + '.' + extension
    this.dataArrayImg.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }
  //CAPTURA EL CONTRATO
  capturarFile2(event) {
    this.archivos2 = []
    this.dataArrayContrato = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'ingresoAbogado' + Date.now() + '.' + extension
    this.dataArrayContrato.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacionContrato = imagen.base

    })
    this.archivos2.push(archivoNuevo)
    this.clickButton = false
  }

  //EXTRAE LA IMAGEN
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG(archivo) {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      archivo.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/ingresoFirma/uploadFirma`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
        },
          (error) => {
            this.loading = false;
          }
        );
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }
  subirContrato() {
    try {

      this.loading = true;
      const formularioDeDatos = new FormData()
      this.archivos2.forEach((archivo) => {
        formularioDeDatos.append('file', archivo)
      })
      this.http
        .post(`${environment.apiUrl}/ingresoFirma/uploadFirmaContrato`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
        },
          (error) => {
            this.loading = false;
          }
        );
    } catch (error) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }
  numberConvert = ''
  result2 = '';

  transform(val: any) {
    if (val) {
      val = this.format_number(val, '')
    }
    return val
  }

  format_number(number: any, prefix: any) {
    let thousand_separator = '.',
      decimal_separator = ',',
      regex = new RegExp('[^' + decimal_separator + '\\d]', 'g'),
      number_string = number.replace(regex, '').toString(),
      split = number_string.split(decimal_separator),
      rest = split[0].length % 3,
      result = split[0].substr(0, rest),
      thousands = split[0].substr(rest).match(/\d{3}/g)
    if (thousands) {
      let separator = rest ? thousand_separator : ''
      result += separator + thousands.join(thousand_separator)
    }
    result =
      split[1] != undefined ? result + decimal_separator + split[1] : result

    this.result2 = result
    this.numberConvert = number_string
    return prefix == undefined ? result : result ? prefix + result : ''
  }


  restrictNumeric(e: any) {
    let input
    if (e.metaKey || e.ctrlKey) {
      return true
    }
    if (e.which === 32) {
      return false
    }
    if (e.which === 0) {
      return true
    }
    if (e.which < 33) {
      return true
    }
    input = String.fromCharCode(e.which)
    return !!/[\d\s]/.test(input)
  }


}