import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'

export class EgresosFijoImportadora {
  id!: string;
  tipoEgreso!: string;
  fecha!: Date
  monto!: number;
  descripcion!: string;
  tipoPago!: string;
  sucursal!: string;
  usuario!: string;
  Sucursal!: Sucursal;
  Usuario!: Usuario;
  numeroCuota!: string;
  RespaldoEgresoFijoImportadoras!: any[];
  grupoGastos!: string;
  responsable!: string;
  montoCuota!: number;
  gastosFijos!: string
  producto!: string
  idSucursal!: number;
  idUsuario!: number;
  fechaf!: string;
  idIngreso!: string;
  archivos!:any;  
  banco!: string;
  numeroCuenta!: string;
  proyecto: String;
  region: String;   
  costoAmortizacion: number;
  tasaInteres: number; 
}
