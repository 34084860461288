<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Actualizar IMG">
    <form [formGroup]="addressForm" novalidate (ngSubmit)="agregarIMG()">
      <mat-card class="shipping-card">
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Informacion IMG </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <input
                mat-stroked-button
                (change)="capturarFile($event)"
                type="file"
              />
              <br />
              <button
                mat-stroked-button
                color="primary"
                [disabled]="loading"
                (click)="subirIMG()"
                (click)="clickButton = false"
                class="separador"
              >
                {{ loading ? 'Cargando....' : 'Subir IMG' }}
              </button>
              <hr />
              <h3>Previsualizacion</h3>
              <div class="row">
                <div class="col">
                  <img width="400px" [src]="previsualizacion" alt="img" />
                </div>
              </div>
            </div>
            <hr class="separador" />
          </div>
        </mat-expansion-panel>
        <mat-card-actions>
          <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>

  <mat-tab label="IMAGEN 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  
  <mat-tab label="EDITAR 📝">
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form
      [formGroup]="addressForm"
      novalidate
      class="mat-elevation-z10"
      (ngSubmit)="onSubmit()"
    >
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Ingresos Inmobiliaria</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Informacion Basica -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Información Cliente </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- informacion fila uno -->
            <div class="row">
              <!-- Sucursales -->
             <!--  <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Sucursales"
                    formControlName="idSucursal"
                  >
                    <mat-option *ngFor="let s of sucursales" value="{{ s.id }}">
                      {{ s.razonSocial }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['idSucursal'].hasError('required')
                    "
                  >
                    Sucursal es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div> -->
              <!-- Propiedades -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Propiedad"
                    formControlName="propiedad"
                  >
                    <mat-option value="Bicentenario 1">
                      Bicentenario 1
                    </mat-option>
                    <mat-option value="Bicentenario 2">
                      Bicentenario 2
                    </mat-option>
                    <mat-option value="Pucara"> Pucara </mat-option>
                    <mat-option value="Cabaña 1"> Cabaña 1 </mat-option>
                    <mat-option value="Cabaña 2"> Cabaña 2 </mat-option>
                    <mat-option value="Valles de Santa Fe">
                      Valles de Santa Fe
                    </mat-option>
                    <mat-option value="Casa Jardin del Este">
                      Casa Jardin del Este
                    </mat-option>
                    <mat-option value="Cerezos"> Cerezos </mat-option>
                    <mat-option value="Chépica"> Chépica </mat-option>
                    <mat-option value="Otra"> Otra </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['propiedad'].hasError('required')
                    "
                  >
                    propiedad es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field
                  class="full-width"
                  *ngIf="f.propiedad.value == 'Otra'"
                >
                  <input
                    matInput
                    placeholder="Otra Propiedad"
                    formControlName="otraPropiedad"
                    type="text"
                  />
                </mat-form-field>
              </div>
              <!-- Ingresos -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Ingreso"
                    formControlName="tipoIngreso"
                  >
                    <mat-option value="Venta"> Venta </mat-option>
                    <mat-option value="Arriendo"> Arriendo </mat-option>
                    <mat-option value="Leaseback"> Leaseback </mat-option>
                    <mat-option value="Leasing"> Leasing </mat-option>
                    <mat-option value="Hipoteca"> Hipoteca </mat-option>
                    <mat-option value="Hospedaje"> Hospedaje </mat-option>
                    <mat-option value="Otro"> Otro </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoIngreso'].hasError('required')
                    "
                  >
                    tipo ingreso es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- Descripcion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Descripcion Ingreso"
                    formControlName="descripcionIngreso"
                  />
                  <mat-icon matSuffix>edit_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['descripcionIngreso'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Fecha"
                    formControlName="fecha"
                    required
                  />
                  <mat-icon matSuffix>event_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['fecha'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- monto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Monto"
                    formControlName="monto"
                    type="text"
                  />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error
                    *ngIf="addressForm.controls['monto'].hasError('required')"
                  >
                    Monto es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="N Autorizacion"
                    formControlName="nAutorizacion"
                  />
                  <mat-icon matSuffix></mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['nAutorizacion'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Referencia Cliente"
                    formControlName="referenciaCliente"
                  />
                  <mat-icon matSuffix></mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['referenciaCliente'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="N Documento"
                    formControlName="nDocumento"
                  />
                  <mat-icon matSuffix></mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['nDocumento'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                  placeholder="Tipo Pago"
                  formControlName="tipoPago"
                  >
                  <mat-option
                  *ngFor="let tipoPago of tipoPago"
                  [value]="tipoPago"
                  >
                  {{ tipoPago }}
                  </mat-option>
               </mat-select>

                  <mat-icon matSuffix></mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoPago'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Cliente"
                    formControlName="cliente"
                  />
                  <mat-icon matSuffix></mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['cliente'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                  placeholder="Tipo Cliente"
                  formControlName="tipoCliente"
                  >
                  <mat-option
                  *ngFor="let tipo of tipoCliente"
                  [value]="tipo"
                  >
                  {{ tipo }}
                  </mat-option>
               </mat-select>
                  <mat-icon matSuffix></mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoCliente'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <br class="separador" />

          <!-- Informacion IMG -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Información Imagen</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- Capturar img -->
                <div>
                  <input
                    mat-stroked-button
                    (change)="capturarFile($event)"
                    type="file"
                  />
                </div>
                <br />
                <div>
                  <button
                    mat-stroked-button
                    color="primary"
                    [disabled]="loading"
                    (click)="subirIMG()"
                    (click)="clickButton = false"
                  >
                    {{ loading ? 'Cargando....' : 'Subir IMG' }}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" type="submit" >
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
