//import { Categoria } from './../../../_models/factura/categoria';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DialogRespaldosComponent } from 'src/app/_components/dialogs/dialog-respaldos/dialog-respaldos.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service';
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service';
import { Sucursal } from '@app/_models/shared/sucursal';
import { AlertHelper } from '@app/_helpers/alert.helper';
import { first } from 'rxjs/operators';
import { CategoriasService } from '../categorias.service';
import { Categoria } from '@app/_models/categoria/categoria';
import { create } from 'domain';

@Component({
  selector: 'app-categorias-form',
  templateUrl: './categorias-form.component.html',
  styleUrls: ['./categorias-form.component.scss']
})
export class CategoriasFormComponent implements OnInit {
  formularioListo = new EventEmitter<string>()

  categoria: Categoria = new Categoria()

  constructor(
    private fb: FormBuilder,
    private categoriaService: CategoriasService,
    private snackBar: MatSnackBar,
  ) { }

  // ? Configuración de formulario
  addressForm = this.fb.group({
    nombre: ['', Validators.required],
    estado: ['', Validators.required]
  })

  ngOnInit(): void {
  }

  onSubmit() {
    //Si el formulario esta correcto
    switch (this.addressForm.status) {

      case 'VALID':

        this.categoria.nombre = this.addressForm.value.nombre;
        this.categoria.estado = this.addressForm.value.estado;
        
        this.categoriaService
        .create(this.categoria)
        .pipe()
        .subscribe(
        (data) => {
          
          this.snackBar.open('Categoria creada con éxito.','Cerrar',{
            duration: 2000,
            verticalPosition: 'top',
          });
        },
        (error) => {
          this.snackBar.open('No se pudo crear la categoria, favor contactar a informática.', 'Cerrar',{
            duration:2000,
            verticalPosition: 'top',
            });
        });
    
    break;
      //Si el formulario es incorrecto
    case 'INVALID':
        this.snackBar.open('El formulario debe ser completado.', 'Cerrar',{
          duration: 2000,
          verticalPosition: 'top',
        });
        break;

      default:
        
        
    }
  }

}
