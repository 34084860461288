import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cesion-home',
  templateUrl: './cesion-home.component.html',
  styleUrls: ['./cesion-home.component.scss']
})
export class CesionHomeComponent implements OnInit {
  constructor( ){ }
  ngOnInit(): void {

  }
}
