import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Categoria } from '@app/_models/categoria/categoria';
import { Producto } from '@app/_models/productos/producto';
import { Variante } from '@app/_models/variante/variante';
import { CategoriasService } from '@app/_pages/categorias/categorias.service';
import { VariantesService } from '@app/_pages/variantes/variantes.service';
import { first } from 'rxjs/operators';
import { ProductosService } from '../../productos.service';

@Component({
  selector: 'app-dialog-productos-editar',
  templateUrl: './dialog-productos-editar.component.html',
  styleUrls: ['./dialog-productos-editar.component.scss']
})
export class DialogProductosEditarComponent implements OnInit {

  formularioListo = new EventEmitter<string>()
  idProducto : any;
  nombreProducto: any;
  dataProducto: any;
  categorias: Categoria[] = [];
  montoTotal: number = 0;
  iva : number= 0;
  montoNeto: number = 0;
  unitarioMargen: number = 0;
  gananciaMargen: number = 0;
  porcentajeMargen: number= 0;
 

  variante: Variante = new Variante()

  addressForm = this.fb.group({
    cosa: false,
    idProducto: ['', Validators.required],
    tipo: ['', Validators.required],
    codigoSKU: ['', Validators.required],
    nombre: ['', Validators.required],
    idCategoria: ['', Validators.required],
    moneda: [],
    unitario: [],
    ganancia: [],
    neto: [],
    venta: [],
    estado: ['',Validators.required],

  });

  addressForm2 = this.fb.group({
    variantes: this.fb.array([])
  })

  get variantes(){
    return this.addressForm2.get('variantes') as FormArray;
  }
  
  isEditable = false ;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private productosService: ProductosService,
    private categoriasService: CategoriasService,
    private variantesService: VariantesService
  ) { }

isShown: boolean = false ; // hidden by default
mostrarFormulario() {
  if(this.addressForm.value.cosa == false)
  {
    this.isShown = ! this.isShown;
  }
  if(this.addressForm.value.cosa == true)
  {
    this.isShown = ! this.isShown;
  }

}

  ngOnInit(): void {
    this.idProducto = localStorage.getItem("idProductosEdit");
    this.nombreProducto = localStorage.getItem("nombreProductosEdit");
    this.getProducto();
    this.getCategorias();
  }

  getCategorias(){
    this.categoriasService
    .categoriaGet()
    .pipe(first())
    .subscribe((categorias) => {
      this.categorias = categorias;
    });
  }

  agregarVariante(){

      //this.variante.idProducto = this.dataProducto.pop().id.toString();

      const varianteForm = this.fb.group({
       
        nombreVariante: [''],
        codigoSKUVariante: [''],
        unitarioVariante: [],
        netoVariante: [],
        estadoVariante: [''],
        descripcionVariante: [''],
      });
      this.variantes.push(varianteForm)
  }

  calcularGanancia()
  {

    this.unitarioMargen = this.addressForm.controls.unitario.value;
    this.porcentajeMargen = this.addressForm.controls.ganancia.value/ 100
    this.gananciaMargen = this.unitarioMargen / (1-this.porcentajeMargen)

    this.montoNeto = this.addressForm.controls.neto.value
    this.iva = this.gananciaMargen * 0.19
    this.montoTotal = this.gananciaMargen + this.iva
    this.montoTotal.toFixed();
    this.gananciaMargen.toFixed();

  }

  calcularUnitario(){
    this.unitarioMargen = this.addressForm.controls.unitario.value;
    this.gananciaMargen = this.unitarioMargen;

    this.iva = this.gananciaMargen * 0.19
    this.montoTotal = this.gananciaMargen + this.iva
    this.montoTotal.toFixed();
    this.gananciaMargen.toFixed();

    if(this.addressForm.controls.ganancia.value > 0){
      this.calcularGanancia();
    }
  }

  eliminarVariante(indice: number){
    this.variantes.removeAt(indice);
  }



  get f() {
    return this.addressForm.controls;
  }

   // Obtener el producto que se quiere editar
  getProducto() {
  //Carga Tabla 
    this.productosService.productoGet().pipe(first()).subscribe((result: Producto[]) => {
    this.dataProducto = result.map(Producto => {
    return Producto;
    });

   // Obtener el producto que se quiere editar y asignar sus valores al formulario
    this.dataProducto.forEach((x:any) => {
    if(x.id == this.idProducto){
      
      this.f.tipo.setValue(x.tipo);
      this.f.codigoSKU.setValue(x.codigoSKU);
      this.f.nombre.setValue(x.nombre);
      this.f.idCategoria.setValue(x.idCategoria);
      this.f.moneda.setValue(x.moneda);
      this.f.unitario.setValue(x.unitario);
      this.f.ganancia.setValue(x.ganancia);
      this.f.neto.setValue(x.neto);
      this.f.venta.setValue(x.venta);
      this.f.estado.setValue(x.estado);
      } 
    });
    });
  }

    // Metodo editar producto
    onSubmit(){
      this.productosService
      .updateProducto(this.idProducto, this.addressForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.snackBar.open('Producto actualizado con exito', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
          this.productosService.closeDialogModal();
        },
        (error) => {
          this.snackBar.open(error, 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
        }
      );

      if(this.isShown == true)
      {

        for (let i = 0; i < this.variantes.value.length; i++){
        //Obtener datos de variantes
        this.variante.nombreVariante = this.variantes.value[i].nombreVariante;
        this.variante.codigoSKUVariante = this.variantes.value[i].codigoSKUVariante;
        this.variante.unitarioVariante = this.variantes.value[i].unitarioVariante;
        this.variante.netoVariante = this.variantes.value[i].netoVariante;
        this.variante.estadoVariante = this.variantes.value[i].estadoVariante;
        this.variante.descripcionVariante = this.variantes.value[i].descripcionVariante;
        this.variante.stockVariante = this.variantes.value[i].stockVariante;
        this.variante.idProducto = this.idProducto;
        
        
        this.variantesService
      .createVariante(this.variante)
      .pipe()
      .subscribe(
        (data) => {
          this.snackBar.open('Variante ingresado con exito', 'cerrar', {
            duration: 2000,
            verticalPosition: 'top',
          });
          this.addressForm2.reset();
        },
        (error) => {
        this.snackBar.open('No se pudo ingresar la variante, contacte con informatica', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
       
       
      }
      );

      }  
    }
    }

  //Cerrar Modal
  closeDialog(){
    this.productosService.closeDialogModal();
  }

}
