import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FileUploader, FileSelectDirective } from 'ng2-file-upload'
import { environment } from '@environments/environment'

export interface DialogData {
  url: any
}
// ! TODO recordar importae FileLoadModule.

@Component({
  selector: 'app-dialog-respaldos',
  templateUrl: './dialog-respaldos.component.html',
  styleUrls: ['./dialog-respaldos.component.scss']
})
export class DialogRespaldosComponent implements OnInit {
  responses: string[] = []
  uploader: FileUploader
  hasBaseDropZoneOver: boolean
  hasAnotherDropZoneOver: boolean
  nombre: string = ''

  constructor(
    public dialogRef: MatDialogRef<DialogRespaldosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar
  ) {
    this.uploader = new FileUploader({
      url: `${environment.apiUrl}/${this.data.url}`,
      // url:`http://179.57.172.224:3001/api/${this.data.url}`,
      itemAlias: 'photo',
    });
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {

      if (!response) {
        this.snackBar.open(
          `tenemos problemas para procesar su solicitud, favor contactar equipo de desarrollo`,
          'cerrar',
          {
            duration: 5000
          }
        )
      } else {
        this.responses.push(response)
        this.snackBar.open(`Registro Exitoso: ${response}`, 'cerrar', {
          duration: 5000
        })
      }
    }
    this.uploader.onCompleteAll = () => {
      // ? la funcion envia al origen de la llamada la data
      // ? luego se debe subscribir a la data
      // ? metodo Mediante el metodo afterClose() del dialogComponent;
      this.dialogRef.close(this.responses)
    }

    this.hasBaseDropZoneOver = false
    this.hasAnotherDropZoneOver = false
    this.uploader.onAfterAddingFile = (file) => {
      var extension = file._file.name
      var temporal = extension.split('.')
      var resultado = temporal.pop()
      //create my name
      file.withCredentials = false
      //console.log(file)
      file.file.name = 'photo-' + Date.now() + '.' + resultado
      //save in variable
    }
  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e
  }
  imagePreview: string | ArrayBuffer | null = null;

  onFileSelected(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e
  }

  ngOnInit(): void {}
}

