<mat-toolbar color="primary">
    <!-- <button style="margin-right: 8rem" mat-raised-button (click)="limpiarFiltros()" color="accent">
    Limpiar filtros
  </button> -->
    <span style="margin-right: 8rem; margin-left: 8rem"> Listado Cesionarios </span>
    <button mat-raised-button color="accent" style="margin-left: 8rem" (click)="exportAsXLSX()">
        Exportar
    </button>
    <button mat-raised-button color="accent" style="margin-left: 1rem" (click)="getCesionarios()">
            <mat-icon>autorenew</mat-icon>
    </button>
</mat-toolbar>
<div class="filtros">
    <div class="btn-filtro">
        <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="bold" (click)="getCesionarios()">Todos</mat-button-toggle>
            <mat-button-toggle value="italic" (click)="estado('1')">Vigente</mat-button-toggle>
            <mat-button-toggle value="underline" (click)="estado('0')">No vigente</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <mat-form-field appearance="standard">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input autocomplete="off">
    </mat-form-field>
</div>

<div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">
        <!-- check column -->

        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- data columns -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID </th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Nombre -->
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let element">{{ element.nombre }}</td>
        </ng-container>

        <!-- telefono -->
        <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef>Teléfono</th>
            <td mat-cell *matCellDef="let element">{{ element.telefono }}</td>
        </ng-container>

        <!-- Rut -->
        <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef>Rut</th>
            <td mat-cell *matCellDef="let element">{{ element.rut }}</td>
        </ng-container>
        

        <!-- Dirección -->
        <ng-container matColumnDef="direccion">
            <th mat-header-cell *matHeaderCellDef>Dirección</th>
            <td mat-cell *matCellDef="let element">{{ element.direccion }}</td>
        </ng-container>

        <!-- Contacto -->
       <ng-container matColumnDef="contacto">
            <th mat-header-cell *matHeaderCellDef>Contacto</th>
            <td mat-cell *matCellDef="let element">{{ element.contacto }}</td>
        </ng-container>

        <!-- email -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <!-- estado -->
        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.estado==1; else elseBlock" class="status-ok">Vigente</span>
                <ng-template #elseBlock> <span class="status-no">No vigente</span></ng-template>
            </td>
        </ng-container>

        <!-- Botones -->
        <ng-container matColumnDef="botones">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let element">

                <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color">
                    <mat-button-toggle value="red" (click)="openDialogEdit(element, element.id)" matTooltip="Editar cesionario">
                        <mat-icon>mode_edit</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="green" (click)="deleteCesionario(element.id, element.nombre)" matTooltip="Eliminar cesionario">
                        <mat-icon>delete</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
             
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No existen coincidencias</td>
        </tr>
    </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>