import { Injectable } from '@angular/core';
import { Empresa } from '@app/_models/empresas/empresas-simpleapi.interface';
import { empresas } from '@app/_models/empresas/lista-empresas-simpleapi'

@Injectable({
  providedIn: 'root'
})

// SERVICIO EMPRESAS DE LA SIMPLE API
// GESTIONA LAS EMPREASAS DE FORMA LOCAL PARA LA SIMPLE API
export class EmpresasSimpleapiService {
  empresas: Empresa[];

  constructor() {
    this.empresas = empresas;
  }

  getEmpresas(){
    return this.empresas.sort(
      (a:Empresa, b:Empresa) => a.nombre.localeCompare(b.nombre)
    );
  }
  getEmpresasRut(rut: string) {
    return this.empresas.find((x: Empresa) => x.rut === rut);
  }
}
