<!-- <h3>Respaldar Registro</h3>
<div
  ng2FileDrop
  [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
  (fileOver)="fileOverBase($event)"
  [uploader]="uploader"
  class="well my-drop-zone"
>
  <mat-icon>add</mat-icon> arrastrar arquivos aqui
  <tr *ngFor="let item of uploader.queue">
    <td>
      <strong>{{ item?.file?.name }}</strong>
    </td>
    <td *ngIf="uploader.options.isHTML5" nowrap>
      {{ item?.file?.size / 1024 / 1024 | number: ".2" }} MB
    </td>    
  </tr>
</div>
<input mat-input type="file" ng2FileSelect [uploader]="uploader" multiple />

<button
  mat-fab
  color="accent"
  (click)="uploader.uploadAll()"
  [disabled]="!uploader.getNotUploadedItems().length"
>
  <mat-icon>add</mat-icon>
</button>
{{ responses }}
 -->



<h3>Respaldar Registro</h3>

<!-- Zona de arrastre de archivos -->
<div
  ng2FileDrop
  [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
  (fileOver)="fileOverBase($event)"
  [uploader]="uploader"
  class="my-drop-zone"
  *ngIf="!imagePreview"  
>
  <p><mat-icon>add</mat-icon> Arrastrar archivos aquí</p>
  <div *ngFor="let item of uploader.queue">
    <strong>{{ item?.file?.name }}</strong>
    <p *ngIf="uploader.options.isHTML5">{{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB</p>
  </div>
</div>

<!-- Previsualización de la imagen seleccionada -->
<div class="image-preview" *ngIf="imagePreview">
  <img [src]="imagePreview" alt="Previsualización de la imagen" />
</div>

<!-- Grupo de botones -->
<div class="button-group">
  <!-- Botón personalizado para seleccionar archivos -->
  <button 
    mat-stroked-button
    color="primary"
    class="custom-upload-button"
    (click)="fileInput.click()"
  >
    Seleccionar archivo
  </button>

  <input 
      #fileInput 
      type="file" 
      ng2FileSelect 
      [uploader]="uploader" 
      (change)="onFileSelected($event)" 
      class="hidden-file-input"
  />

  <!-- Botón para subir el archivo -->
  <button
    mat-flat-button
    color="accent"
    class="submit-button"
    (click)="uploader.uploadAll()"
    [disabled]="!uploader.getNotUploadedItems().length"
  >
    Subir Comprobante
  </button>
</div>

{{ responses }} 