import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CesionService } from '../../../cesion.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-ver-cesion',
  templateUrl: './ver-cesion.component.html',
  styleUrls: ['./ver-cesion.component.scss']
})

export class VerCesionComponent implements OnInit {
  
  displayedColumns: string[] = ['id','folioSiguiente', 'primerFolio', 'ultimoFolio', 'fechaAutorizacion', 'fechaVencimiento', 'foliosDisponibles', 'estado'];
  dataSource = [];
  idCesion: any;
  tipoDocumento: string;
  folio: string;
  fechaEmision: string;
  cliente: string;
  rutCliente: string;
  monto: string;
  fechaEnvio: string;
  cesionario: String;
  trackID: string;
  estado: string;
  motivo: string;
  fechaVe: string;
  rutCesionario: string;

  constructor(
    private fb: FormBuilder,
    private cesionService: CesionService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.idCesion = localStorage.getItem('idCesion');
    this.getCesion();
  }

  getCesion() {
    this.cesionService
      .getOne(this.idCesion)
      .pipe(first())
      .subscribe((resultado: any) => {
        // this.cesion = resultado;
        this.trackID = resultado.trackId;
        this.estado = resultado.estado;
        this.motivo = resultado.motivo;
        this.rutCesionario = resultado.Cesionario.rut;
        this.fechaVe = resultado.fechaVencimiento
        this.tipoDocumento = resultado['Factura']['tipoDocumento'];
        this.folio = resultado['Factura']['folio'];
        this.fechaEmision = resultado['Factura']['createdAt'];
        this.cliente = resultado['Cliente']['nombre'];
        this.rutCliente =  resultado['Cliente']['rut'];
        this.monto = resultado['montoCesion'];
        this.fechaEnvio = resultado['createdAt'];
        this.cesionario = resultado['Cesionario']['nombre'];
      })
  }

  //Cerrar Modal
  closeDialog() {
    this.cesionService.closeDialogModal()
    localStorage.removeItem('idCesion')
  }
}
