<mat-tab-group mat-align-tabs="center">
    <mat-tab label="Actualizar IMG 📃 ">
      <form [formGroup]="addressForm" novalidate>
        <mat-card class="shipping-card">
          <!-- Informacion IMG -->
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion IMG </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- Capturar img -->
                <div>
                  <input mat-stroked-button (change)="capturarFile($event)" type="file" />
                </div>
                <br />
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="300px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel>

          <mat-card-actions>
            <button mat-stroked-button color="primary" type="submit" [disabled]="loading" (click)="subirIMG()"
            [disabled]="clickButton" (click)="AgregarIMG()">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-tab>
    <mat-tab label="Comprobante 📃 ">
      <form [formGroup]="addressForm" novalidate>
        <mat-card class="shipping-card">
          <mat-card-content>
            <mat-card>
              <h3>Imagen Previsualizacion</h3>
              <hr />
              <div class="row">
                <!-- Preview img -->
                <div class="col" *ngIf="previsualizacion">
                  <img width="400px" [src]="previsualizacion" alt="img" />
                </div>
              </div>
            </mat-card>
          </mat-card-content>
          <mat-card-actions>
            <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
              (click)="closeDialog()">
              <mat-icon>cancel_white_18dp</mat-icon>Cancelar
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-tab>
    <mat-tab label="Editar 📝">
      <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
        <mat-icon>highlight_off</mat-icon>
      </button>
      <form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
        <mat-card class="shipping-card">
          <mat-card-header>
            <h1>Editar Cliente</h1>
          </mat-card-header>
          <mat-card-content>
            <!-- Informacion Basica -->
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>Informacion Basica </mat-panel-title>
              </mat-expansion-panel-header>

              <!-- informacion fila uno -->
                    <!-- <input matInput placeholder="fecha" formControlName="fecha" required />
                    <mat-error *ngIf="addressForm.controls['fecha'].hasError('required')">
                      Este campo es <strong>requerido.</strong>
                    </mat-error> -->
                    <div class="col">
                      <form [formGroup]="addressForm">
                        <mat-form-field appearance="fill">
                          <mat-label>Fecha</mat-label>
                          <input matInput [value]="addressForm.controls['fecha'].value | date:'dd/MM/yyyy, h:mm a'" disabled>
                          <mat-error *ngIf="addressForm.controls['fecha']">
                            
                          </mat-error>
                        </mat-form-field>
                      </form>
                   
                        
                    </div>
                <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="monto" formControlName="monto" required />
                    <mat-error *ngIf="
                        addressForm.controls['monto']
                      ">
                      Este campo es <strong>requerido.</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="N° Cuotas" formControlName="numeroCuota"  />
                      <mat-error *ngIf="
                          addressForm.controls['numeroCuota']
                        ">
                        
                      </mat-error>
                    </mat-form-field>
                  </div>
              <div class="row">
                <!-- tipo egreso -->
                <div class="col">
                  <mat-form-field class="full-width">
                    <mat-select placeholder="Tipo Egreso" formControlName="tipoEgreso" (ngModelChange)="capturar()"
                      (ngModel)="opcionSeleccionado">
                      <mat-option value="Gastos" (click)="desactivarEdicion()">
                        Gastos
                      </mat-option>
                      <mat-option value="Gastos Fijos" (click)="desactivarEdicion()">
                        Gastos Fijos
                      </mat-option>
                      <mat-option value="Costos" (click)="desactivarEdicion()">
                        Costos
                      </mat-option>
                      <mat-option value="Remuneraciones" (click)="desactivarEdicion()">
                        Remuneraciones
                      </mat-option>
                      <mat-option value="Bancarios" (click)="desactivarEdicion()">
                        Bancarios
                      </mat-option>
                      <mat-option value="Impuestos" (click)="desactivarEdicion()">
                        Impuestos
                      </mat-option>
                      <mat-option value="Inversiones" (click)="desactivarEdicion()">
                        Inversiones
                      </mat-option>
                      <mat-option value="Prestamos Bancarios" (click)="activarEdicion()">
                        Prestamos Bancarios
                      </mat-option>
                      <mat-option value="Prestamos Automotriz" (click)="activarEdicion()">
                        Prestamos Automotriz
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                        addressForm.controls['tipoEgreso']
                      ">
                      
                    </mat-error>
                  </mat-form-field>
                </div>
                

                <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="descripcion" formControlName="descripcion" required />
                    <mat-error *ngIf="
                        addressForm.controls['descripcion']
                      ">
                      
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field class="full-width">
                      <mat-label>Responsable</mat-label>
                      <input matInput [value]="addressForm.controls['responsable'].value " disabled>
                      <mat-error *ngIf="
                          addressForm.controls['responsable']
                        ">
                      
                      </mat-error>
                    </mat-form-field>
                  </div>

              </div>


              <div class="row">

                <!-- Numero cuenta -->
                <div class="col">
                  <div *ngIf="addBanc">
                    <mat-form-field class="full-width">
                      <mat-label>N° Cuenta corriente/Tarjeta</mat-label>
                      <input matInput placeholder="XXXX/4 Últimos Dígitos" formControlName="numeroCuenta" type="number" />
                      <mat-error *ngIf="egresosForm.get('numeroCuenta').hasError('required')">
                        Este campo es obligatorio.
                      </mat-error>
                      <mat-error *ngIf="egresosForm.get('numeroCuenta').hasError('pattern')">
                        Debe ingresar los últimos dígitos de la tarjeta/cuenta
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!-- gastos fijos -->

                <div class="col">
                  <div *ngIf="addt">
                  <mat-form-field class="full-width">
                    <mat-select placeholder="Gastos Fijos" formControlName="gastosFijos">
                      <mat-option *ngFor="let gastosFijos of gastosFijo" [value]="gastosFijos">
                        {{ gastosFijos }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="addressForm.controls['gastosFijos'].hasError('required')">
                      este campo es <strong>requrido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                </div>
                <!-- <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="Numero de Cuenta" formControlName="numeroCuenta"  />
                    <mat-error *ngIf="
                        addressForm.controls['numeroCuenta']
                      ">
                      
                    </mat-error>
                  </mat-form-field>
                </div> -->

                
              </div>
              <!-- <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="grupoGastos" formControlName="grupoGastos" required />
                  <mat-error *ngIf="
                      addressForm.controls['grupoGastos'].hasError('required')
                    ">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div> -->
              <!-- tipo pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Pago"
                    formControlName="tipoPago"
                  >
                    <mat-option
                      *ngFor="let tipoPago of tiposPagos"
                      [value]="tipoPago"
                    >
                      {{ tipoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    "
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>



            </mat-expansion-panel>
            <!-- Informacion IMG -->
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>Informacion IMG </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col">
                  <!-- Capturar img -->
                  <div>
                    <button mat-stroked-button color="primary" [disabled]="loading" (click)="subirIMG()"
                      (click)="clickButton = false">
                      {{ loading ? 'Cargando....' : 'Subir IMG' }}
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <h3>Imagen Previsualizacion</h3>
              <hr />
              <div class="row">
                <!-- Preview img -->
                <div class="col" *ngIf="previsualizacion">
                  <img width="300px" [src]="previsualizacion" alt="img" />
                </div>
              </div>
              <hr />
            </mat-expansion-panel>
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="primary" type="submit">
              <mat-icon>send</mat-icon>
              Guardar
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-tab>
  </mat-tab-group>
