export class StockSaliente {
  fecha!: string
  ndocumento_saliente!: number
  id_bodega!: number
  observacion_saliente!: string
  idProveedor!: number
  ubicacion_saliente!: string
  tipoAjuste!: string
  idAjusteInventario!:number
  idProducto!:number
  cantidad_saliente!:number
  costoUnitario!:number
  subTotal!:number
}