import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { first } from 'rxjs/operators'
import { modeloCompras } from '../../../../../_models/compras/compras'
import { ComprasService } from '../../../compras.service'
import * as JsonToXML from 'js2xmlparser'

@Component({
  selector: 'app-compras-acciones',
  templateUrl: './compras-acciones.component.html',
  styleUrls: ['./compras-acciones.component.scss']
})
export class ComprasAccionesComponent implements OnInit {
  idCompras: any
  razonSocial: any
  rutProveedor: any
  folio: any
  tipoDTE: any
  fechaEmision: any
  montoTotal: any
  descripcion: any
  data: any

  //Modelo de compras
  modelo: modeloCompras = new modeloCompras()

  //Configuracion de formulario
  addressForm = this.fb.group({
    razonSocial: null,
    rutProveedor: null,
    folio: null,
    tipoDTE: null,
    fechaEmision: null,
    descripcion: null,
    montoTotal: null
  })

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private comprasService: ComprasService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.idCompras = localStorage.getItem('idCompras')
    this.getCompras()
  }

  get f() {
    return this.addressForm.controls
  }

  getCompras() {
    this.data = this.comprasService
      .getById(this.idCompras)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idCompras) {
          this.f.razonSocial.setValue(x[0].razonSocial)
          this.f.rutProveedor.setValue(x[0].rutProveedor)
          this.f.folio.setValue(x[0].folio)
          this.f.tipoDTE.setValue(x[0].tipoDTE)
          this.f.fechaEmision.setValue(x[0].fechaEmision)
          this.f.descripcion.setValue(x[0].descripcion)
          this.f.montoTotal.setValue(x[0].montoTotal)
        }
        this.data = x
      })
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
  }

  //Descargar xml
  descargarXml() {
    const DataXml = this.data
    const datoXml = JsonToXML.parse('DTE', DataXml)
    var element = document.createElement('a')
    var blob = new Blob([datoXml], {
      type: 'text/xml'
    })
    var url = URL.createObjectURL(blob)
    element.href = url
    element.setAttribute('download', 'Documento.xml')
    document.body.appendChild(element)
    element.click()
  }

  //aceptar documento
  AceptarDocumento() {
    switch (this.addressForm.status) {
      case 'VALID':
        this.comprasService
          .AceptarCompra(this.idCompras, this.addressForm.value)
          .pipe(first())
          .subscribe(
            (data) => {
              this.snackBar.open('Documento Aceptado con éxito.', 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })

              this.addressForm.reset()
              this.comprasService.closeDialogModal()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo Aceptar documento, favor contactar a informática.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
            }
          )

        break
      case 'INVALID':
        this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }

  //Reclamar documento
  ReclamarDocumento() {
    switch (this.addressForm.status) {
      case 'VALID':
        this.comprasService
          .ReclamarCompra(this.idCompras, this.addressForm.value)
          .pipe(first())
          .subscribe(
            (data) => {
              this.snackBar.open('Reclamo  con éxito.', 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })

              this.addressForm.reset()
              this.comprasService.closeDialogModal()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo hacer el reclamo, favor contactar a informática.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
            }
          )

        break
      case 'INVALID':
        this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }

   //Recibo Mercancia
   ReciboMercancia() {
    switch (this.addressForm.status) {
      case 'VALID':
        this.comprasService
          .ReciboMercancia(this.idCompras, this.addressForm.value)
          .pipe(first())
          .subscribe(
            (data) => {
              this.snackBar.open('Recibo Mercancia  con éxito.', 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })

              this.addressForm.reset()
              this.comprasService.closeDialogModal()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo hacer el reclamo, favor contactar a informática.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
            }
          )

        break
      case 'INVALID':
        this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }

  //Evento de Documento
  EventoDoc() {
    switch (this.addressForm.status) {
      case 'VALID':
        this.comprasService.EventoCompra(this.idCompras, this.addressForm.value)
        .pipe(first())
        .subscribe(
          (data) => {
            this.snackBar.open('Consultado a SII esto tardara unos segundos', 'Cerrar', {
              duration: 5000,
              verticalPosition: 'top'
            })
            setTimeout(() => {
              window.location.reload()
            }, 5000)
          },
          (error) => {
            this.snackBar.open(
              'No se pudo hacer al evento, favor contactar a informática.',
              'Cerrar',
              {
                duration: 2000,
                verticalPosition: 'top'
              }
            )
          }
        )
        break
      case 'INVALID':
        this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }
}
