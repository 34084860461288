<mat-dialog-content>
<button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
    <mat-icon>highlight_off</mat-icon>
</button>
<form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
    <mat-card class="shipping-card">
        <mat-card-header>
            <h1>{{ nombreProducto}}</h1>
        </mat-card-header>
        <br><br>
        <mat-card-content>

        <div class="row">
            <!-- Tipo: Productos/Servicios -->
            <div class="col">
                <mat-form-field class="full-width">
                    
                        <mat-select placeholder="Seleccione" formControlName="tipo">
                            <mat-option value="Producto"> Producto </mat-option>
                            <mat-option value="Servicio"> Servicio </mat-option>
                        </mat-select>
                    <mat-error *ngIf="addressForm.controls['tipo'].hasError('required')">
                        sucursal es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-checkbox style="width: 10%;" id="cosa" (click)="mostrarFormulario()">El producto/servicio contiene variantes</mat-checkbox>
            </div>
            
            </div>

        <div class="row">

            <!-- Codigo SKU -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Codigo SKU" formControlName="codigoSKU"/>
                        <mat-error *ngIf="addressForm.controls['codigoSKU'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

            <!-- Nombre Productos/Servicios -->
            <div class="col">
                <mat-form-field class="full-width">
                    <input matInput placeholder="Nombre" formControlName="nombre"/>
                    <mat-error *ngIf="addressForm.controls['nombre'].hasError('required')">
                        este campo es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">

            <!-- Categoria -->
            <div class="col">
                <mat-form-field class="full-width">
                    <mat-select placeholder="Categoria" formControlName="idCategoria">
                        <mat-option *ngFor="let categoria of categorias" value="{{ categoria.id }}">
                            {{ categoria.nombre }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="addressForm.controls['idCategoria'].hasError('required')">
                        este campo es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Tipo de Moneda-->
            <div class="col">
                <mat-form-field class="full-width">
                        <mat-select placeholder="Seleccione Tipo Moneda" formControlName="moneda">
                            <mat-option value="pesos"> Pesos </mat-option>
                            <mat-option value="uf"> UF </mat-option>
                        </mat-select>
                    <mat-error>
                        sucursal es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>

        </div>
            
        <div class="row">
            <!-- Precio Unitario -->
            <div class="col">
                <mat-form-field class="full-width">
                    <input matInput placeholder="Costo Unitario" formControlName="unitario"/>
                    <mat-error>
                        este campo es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>

             <!-- Margen de Ganancia -->
             <div class="col">
                <mat-form-field class="full-width">
                    <input matInput placeholder="Margen de Ganancia" formControlName="ganancia" />
                    <mat-icon matSuffix>percent_black_24dp</mat-icon>
                    <mat-error *ngIf="addressForm.controls['ganancia'].hasError('required')">
                        este campo es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        
        </div>    
        
        <div class="row">

            <!-- Precio Neto -->
            <div class="col">
                <mat-form-field class="full-width">
                    <input matInput placeholder="Precio Neto" formControlName="neto" />
                  
                    <mat-error>
                        este campo es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Precio Venta-->
            <div class="col">
                <mat-form-field class="full-width">
                    <input matInput placeholder="Precio Venta" formControlName="venta"/>
                    
                    <mat-error>
                        este campo es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>
                
            <!-- Estado -->
            <div class="col">
                <mat-form-field class="full-width">
                        <mat-select placeholder="Estado" formControlName="estado">
                            <mat-option value="Vigente"> Vigente </mat-option>
                            <mat-option value="No Vigente"> No Vigente </mat-option>
                        </mat-select>
                    <mat-error>
                        sucursal es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        </mat-card-content>

        <!-- botón enviar -->
        <button *ngIf="!isShown" style="float: right" mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon> Enviar
        </button>
    </mat-card>
</form>

<br>

<form [formGroup]="addressForm2" novalidate class="mat-elevation-z10" (ngSubmit)="onSubmit()">
    <mat-card class="shipping-card" *ngIf="isShown"><br>
        <div>
            <a class="btn btn-primary" mat-raised-button style="float: right" color="primary" (click)="agregarVariante()"><mat-icon>add_black_18dp</mat-icon>Agregar Variante</a>
        </div>
        <mat-card-header>
            <h1>Variantes</h1>
        </mat-card-header>
        <br>
        <ng-container formArrayName="variantes">
        <ng-container *ngFor="let variante of variantes.controls; let i=index">
        <div [formGroupName]="i">
        <mat-card-content>
            <div class="row">
                <!-- Variantes Nombre-->
                <div class="col-sm-4">
                    <mat-form-field class="full-width">
                        <input [attr.for]="'nombreVariante' + i" [attr.id]="'nombreVariante' + i" matInput placeholder="Nombre" formControlName="nombreVariante"/>
                        
                    </mat-form-field>
                </div>
 
                <!-- Codigo SKU -->
                <div class="col-sm-4">
                    <mat-form-field class="full-width">
                        <input [attr.for]="'codigoSKUVariante' + i" [attr.id]="'codigoSKUVariante' + i"  matInput placeholder="Codigo SKU" formControlName="codigoSKUVariante"/>
                        
                    </mat-form-field>
                </div>

                <!-- Precio Neto -->
                <div class="col-sm-3">
                    <mat-form-field class="full-width">
                        <input [attr.for]="'netoVariante' + i" [attr.id]="'netoVariante' + i"  matInput placeholder="Precio Neto" formControlName="netoVariante"/>
                        
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
            
                <!-- Precio Unitario -->
                <div class="col-sm-4">
                    <mat-form-field class="full-width">
                        <input [attr.for]="'unitarioVariante' + i" [attr.id]="'unitarioVariante' + i"  matInput placeholder="Costo Unitario" formControlName="unitarioVariante"/>
                        
                    </mat-form-field>
                </div>

                <!-- Estado -->
                <div class="col-sm-4">
                    <mat-form-field class="full-width">
                            <mat-select [attr.for]="'estadoVariante' + i" [attr.id]="'estadoVariante' + i"  placeholder="Estado" formControlName="estadoVariante">
                                <mat-option value="Vigente"> Vigente </mat-option>
                                <mat-option value="No Vigente"> No Vigente </mat-option>
                            </mat-select>
                       
                    </mat-form-field>
                </div>

                <!-- Descripcion-->
                <div class="col-sm-3">
                    <mat-form-field class="full-width">
                        <input  [attr.for]="'descripcionVariante' + i" [attr.id]="'descripcionVariante' + i"  matInput placeholder="Descripcion" formControlName="descripcionVariante"/>
                        
                    </mat-form-field>
                </div>

                <div>
                    <a style="height: 10%;" class="btn btn-danger" style="float: right" (click)="eliminarVariante(i)"><mat-icon>delete_black_24dp</mat-icon></a>
                </div>
            </div>
            <br>
            
            
        </mat-card-content>
        </div>
        </ng-container>   
        </ng-container>  
    </mat-card>
    <button *ngIf="isShown" style="float: right" mat-raised-button color="primary" type="submit">
        <mat-icon>send</mat-icon> Enviar
    </button>
</form>
</mat-dialog-content>

