<div style="margin-left: 25%;">
    <ul class="legend">
      <li><span class="superawesome"></span>Gastos por Vencer</li>
      <li><span class="awesomeN"></span> Gastos Pagados </li>
      <li><span class="awesome"></span> Gastos Vencidos</li>
    </ul>
</div>
<br>
<full-calendar [options]="calendarOptions"></full-calendar>
<mat-divider></mat-divider>
<!-- <app-hostal-gastos-fijos-calendario></app-hostal-gastos-fijos-calendario> -->
