<form [formGroup]="addressForm" novalidate class="mat-elevation-z10" (ngSubmit)="onSubmit()">
    <mat-card class="shipping-card">
        <mat-card-header>
            <h1>Ajuste de inventario - Recepción (entrada)</h1>
        </mat-card-header>
        <br>
        <mat-card-content>
            <div class="row">

                <!-- Fecha Emision -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Fecha" formControlName="fecha" [matDatepicker]="picker"
                            [readonly]="true" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="addressForm.controls['fecha'].hasError('required')">
                            Fecha es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- N° Documento -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="N° Documento" formControlName="documento" type="number" />
                        <mat-error *ngIf="addressForm.controls['documento'].hasError('required')">
                            Fecha es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>

            <div class="row">
                <!-- Bodega -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Bodega" formControlName="bodega" (selectionChange)="onBodegaChange()"> 
                            <mat-option *ngFor="let bodega of bodegas" value="{{ bodega.id }}">
                                {{ bodega.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="addressForm.controls['bodega'].hasError('required')">
                            Fecha es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Observacion -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Observacion" formControlName="observacion" />
                        <mat-error *ngIf="addressForm.controls['observacion'].hasError('required')">
                            Nombre es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <!-- Proveedor -->
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Proveedor" formControlName="proveedor">
                            <mat-option *ngFor="let proveedor of proveedores" value="{{ proveedor.id }}">
                                {{ proveedor.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="addressForm.controls['observacion'].hasError('required')">
                            Nombre es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>
            <!--boton volver antiguo-->

        </mat-card-content>

    </mat-card>
</form>

<br><br>

<form [formGroup]="addressForm2" novalidate class="mat-elevation-z10" (ngSubmit)="onSubmit()">
    <mat-card class="shipping-card"  *ngIf="addButton"><br>

        <mat-card-header>
            <h1>Productos</h1>
        </mat-card-header>

        <div class="add-product-container">
            <a class="add-product-btn succes" mat-raised-button style="float: right" (click)="agregarProducto()" >
                <mat-icon>add_black_18dp</mat-icon>Agregar Producto
            </a>
        </div>
        <ng-container formArrayName="productos" >
            <ng-container *ngFor="let producto of productos.controls; let i=index">
                <div [formGroupName]="i">

                    <mat-card-content>
                        <div class="row">
                            <!-- Nombre Producto-->

                            <!-- <input type="hidden" formControlName="formularioId" [attr.data-formulario-index]="i"> -->

                            <div class="column-producto">
                                <mat-form-field class="element-hijo-tamaño">
                                    <mat-select placeholder="Producto" formControlName="productosSeleccionados" 
                                        [(ngModel)]="selectedOptionIdProducts[i]"
                                        (selectionChange)="calcularSubtotal(i)"
                                        (selectionChange)="selectedProductForm = addressForm2"
                                        [attr.for]="'productosSeleccionados' + i"
                                        [attr.id]="'productosSeleccionados' + i"
                                        >
                                        <mat-option *ngFor="let producto of productosDisponibles" value="{{ producto.id }}">
                                            {{ producto.nombre }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- Ubicacion -->
                            <div class="column-10">
                                <mat-form-field class="element-hijo-tamaño">
                                    <input [attr.for]="'ubicacion' + i" [attr.id]="'ubicacion' + i" matInput
                                        placeholder="Ubicacion" formControlName="ubicacion" />
                                </mat-form-field>
                            </div>

                            <!-- Cantidad -->
                            <div class="column-10">
                                <mat-form-field class="element-hijo-tamaño">
                                    <input [attr.for]="'cantidad' + i" [attr.id]="'cantidad' + i"
                                        (input)="calcularSubtotal(i);" matInput placeholder="Cantidad"
                                        formControlName="cantidad" type="number" [disabled]="true"/>
                                </mat-form-field>
                            </div>

                            <!-- Costo -->
                            <div class="column-15">
                                <mat-form-field class="element-hijo-tamaño">
                                    <span matPrefix>$&nbsp;</span>
                                    <input [attr.for]="'costo' + i" [attr.id]="'costo' + i" matInput
                                        placeholder="Costo Unitario" formControlName="costo"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="Costo[i]" readonly />
                                </mat-form-field>
                            </div>

                            <!-- Subtotal -->
                            <div class="column-10">
                                <mat-form-field class="element-hijo-tamaño">
                                    <span matPrefix>$&nbsp;</span>
                                    <input [attr.for]="'subtotal' + i" [attr.id]="'subtotal' + i" matInput
                                        placeholder="Subtotal" formControlName="subtotal"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="subtotal[i]" readonly />
                                </mat-form-field>
                            </div>

                            <!-- Stock-->
                            <div class="column-10">
                                <mat-form-field class="element-hijo-tamaño">
                                    <input (input)="onInputSelected($event)" [attr.for]="'stock' + i"
                                        [attr.id]="'stock' + i" matInput placeholder="Stock Actual"
                                        formControlName="stock" readonly/>
                                </mat-form-field>
                            </div>

                            <!-- Ajuste -->
                            <div class="column-10">
                                <mat-form-field class="element-hijo-tamaño">
                                    <input [attr.for]="'ajuste' + i" [attr.id]="'ajuste' + i" matInput
                                        placeholder="Ajuste Actual" formControlName="ajuste" readonly/>
                                </mat-form-field>
                            </div>

                            <a class="btn btn-danger cont-basurero" style="float: right;padding: 13px;"
                                (click)="eliminarProducto(i)">
                                <mat-icon>delete_black_24dp</mat-icon>
                            </a>

                        </div>

                        <br>

                    </mat-card-content>
                </div>
            </ng-container>
        </ng-container>
        
        <button style="float: right" mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon> Enviar
        </button>

        <div class="row">
            <div class="col-md-6">
                <!-- botón volver -->
                <button routerLink="/inventario/ajusteInventario" mat-raised-button color="primary">
                    <mat-icon>arrow_back_black_24dp</mat-icon> Volver
                </button>
            </div>
        </div>

    </mat-card>

</form>