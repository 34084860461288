import { Empresa } from "../empresas/empresas-simpleapi.interface";

const claveSIIMiguel_Diego_Solanch = "Firma1827";
const claveCertificadoSIIDiego = "Casa318331";
const claveCertificadoSIIMiguel = "Casa315531";
const rutDiegoVargas: string = '18891594-9';
const rutMiguelVargas: string = '17886328-2';
const rutSolanchTejos: string = '19105142-4';
const fechaResolucionProduccion: string = '22-08-2014';
const numeroResolucion: number = 0;

export const empresas: Empresa[] = [
    {
        rut: '76438914-K',
        nombre: 'GF SERVICES SPA',
        giro: 'ALQUILER DE VEHICULOS AUTOMOTORES SIN CHOFER',
        direccion: 'AV. ISIDORO DEL SOLAR 135',
        comuna: 'TALCA',
        ciudad: 'TALCA',
        actividades: [492190,649100,702000,771000],
        telefono: '56999999999',
        rutCertificado: rutMiguelVargas,
        claveCertificado: claveCertificadoSIIMiguel,
        fechaResolucion: '22-08-2014',
        representanteLegal: "",
        userSII: "",
        claveSII: "",
        nombreRepresentante: "",
        correoElectronico: "",
        numeroResolucion: 80,
    },
    {
        rut: '76457046-4',
        nombre: 'SOCIEDAD VARGAS GARRIDO SPA',
        giro: 'ALQUILER DE VEHICULOS AUTOMOTORES SIN CHOFER',
        direccion: 'AV. ISIDORO DEL SOLAR 135',
        comuna: 'TALCA',
        ciudad: 'TALCA',
        actividades: [410010,421000,452002,649100],
        telefono: '56999999999',
        rutCertificado: rutMiguelVargas,
        claveCertificado: claveCertificadoSIIMiguel,
        fechaResolucion: '06-05-2024',
        representanteLegal: rutMiguelVargas,
        userSII: rutMiguelVargas,
        claveSII: claveSIIMiguel_Diego_Solanch,
        nombreRepresentante: "MIGUEL EDUARDO VARGAS GARRIDO",
        correoElectronico: "miguelvargasgarrido@gmail.com",
        numeroResolucion: 0,
    },
    {
        rut: '76971509-6',
        nombre: 'SOCIEDAD GARRIDO VARGAS SPA',
        giro: 'ALQUILER DE VEHICULOS AUTOMOTORES SIN CHOFER',
        direccion: '1 PTE, 4 Y 5 NORTE 1588',
        comuna: 'TALCA',
        ciudad: 'TALCA',
        actividades: [451002,492240,492290,649100],
        telefono: '56999999999',
        rutCertificado: rutDiegoVargas,
        claveCertificado: claveCertificadoSIIDiego,
        //fechaResolucion: '04-02-2021',
        fechaResolucion: '22-08-2014', //produccion
        //numeroResolucion: numeroResolucion,
        representanteLegal: rutDiegoVargas,
        userSII: "76971509-6",
        claveSII: claveSIIMiguel_Diego_Solanch,
        nombreRepresentante: "DIEGO ANTONIO VARGAS GARRIDO",
        correoElectronico: "diego.vargas.garrido@hotmail.com",
        numeroResolucion: 80, //producion 
    },
    {
        rut: '77258157-2',
        nombre: 'SOCIEDAD VARGAS GARRIDO II SPA',
        giro: 'VENTA AL POR MAYOR DE MATERIAS PRIMAS AGRICOLAS. EXPORTADORES DE FRUTA',
        direccion: 'CAMINO DEL AGUA 3985',
        comuna: 'TALCA',
        ciudad: 'TALCA',
        actividades: [463011,649100,771000],
        telefono: '',
        rutCertificado: rutMiguelVargas,
        claveCertificado: claveCertificadoSIIMiguel,
        fechaResolucion: '16-08-2024',
        representanteLegal: rutMiguelVargas,
        userSII: rutMiguelVargas,
        claveSII: claveSIIMiguel_Diego_Solanch,
        nombreRepresentante: "MIGUEL EDUARDO VARGAS GARRIDO",
        correoElectronico: "w.desousa@grupofirma.cl",
        numeroResolucion: 0,
    },
    {
        rut: '76791832-1',
        nombre: 'TERESA DEL CARMEN GARRIDO ROJAS E HIJOS LIMITADA',
        giro: 'ARRIENDO DE AUTOS Y CAMIONETAS SIN CHOFER',
        direccion: '2 NORTE 3030 POB SANTA CLARA',
        comuna: 'TALCA',
        ciudad: 'TALCA',
        actividades: [451002,452001,452002,453000],
        telefono: '56941143456',
        rutCertificado: rutDiegoVargas,
        claveCertificado: claveCertificadoSIIDiego,
        //fechaResolucion: '20-09-2021', /* para desarrollo */
        
        // representanteLegal: rutMiguelVargas,
        representanteLegal: rutDiegoVargas,
        // userSII: "76791832-1",
        userSII: rutDiegoVargas,
        claveSII: claveSIIMiguel_Diego_Solanch,
        nombreRepresentante: "DIEGO ANTONIO VARGAS GARRIDO",
        correoElectronico: "diego.vargas.garrido@hotmail.com",
        fechaResolucion: '22-08-2014', /* para folios reales */
        //numeroResolucion: 0, /* para desarrollo */
        numeroResolucion: 80,  /* para produccion */
    },
    {
        rut: '77265668-8',
        nombre: 'SOCIEDAD TERESA E HIJOS II SPA',
        giro: 'ALQUILER DE VEHICULOS AUTOMOTORES SIN CHOFER',
        direccion: 'AV. ISIDORO DEL SOLAR 135',
        comuna: 'TALCA',
        ciudad: 'TALCA',
        actividades: [451002,452001,452002,453000],
        telefono: '56999999999',
        rutCertificado: rutMiguelVargas,
        claveCertificado: claveCertificadoSIIMiguel,
        fechaResolucion: '22-08-2014',
        representanteLegal: "",
        userSII: "",
        claveSII: "",
        nombreRepresentante: "",
        correoElectronico: "",
        numeroResolucion: 80,
    },
    {
        rut: '76849793-1',
        nombre: 'MIGUEL VARGAS ESPINOSA E HIJOS LIMITADA',
        giro: 'ALQUILER DE VEHICULOS AUTOMOTORES SIN CHOFER',
        direccion: '1 SUR 3155',
        comuna: 'TALCA',
        ciudad: 'TALCA',
        actividades: [492290,551003,559001,649100],
        telefono: '56999999999',
        rutCertificado: rutDiegoVargas,
        claveCertificado: claveCertificadoSIIDiego,
        fechaResolucion: '13-12-2019', 
        representanteLegal: rutDiegoVargas,
        // userSII: "76849793-1",
        userSII: rutDiegoVargas,
        claveSII: claveSIIMiguel_Diego_Solanch,
        nombreRepresentante: "DIEGO ANTONIO VARGAS GARRIDO",
        correoElectronico: "diego.vargas.garrido@hotmail.com",
        numeroResolucion: 0,
    },
    {
        rut: '19105142-4',
        nombre: 'SOLANCH MACARENA TEJOS CARRASCO',
        giro: 'ALQUILER DE VEHICULOS AUTOMOTORES SIN CHOFER',
        direccion: 'AV. ISIDORO DEL SOLAR 135',
        comuna: 'TALCA',
        ciudad: 'TALCA',
        actividades: [451002,492240,492290,771000],
        telefono: '56999999999',
        rutCertificado: rutMiguelVargas,
        // claveCertificado: 'somateca19',
        claveCertificado: claveCertificadoSIIMiguel,
        //fechaResolucion: '03-02-2021', 
        fechaResolucion: '22-08-2014', //produccion

        representanteLegal: rutMiguelVargas,
        // userSII: "19105142-4",
        userSII: rutMiguelVargas,
        claveSII: claveSIIMiguel_Diego_Solanch,
        nombreRepresentante: "SOLANCH MACARENA TEJOS CARRASCO",
        correoElectronico: "siirelkespa@gmail.com",
        //numeroResolucion: 0,
        numeroResolucion:80, //producion

    },
    // {
    //     rut: '77240503-0',
    //     nombre: 'MUNDO MOTOS SPA',
    //     giro: '',
    //     direccion: '15 ORIENTE 1013, DPTO L5',
    //     comuna: 'MAULE',
    //     actividades: [452001,452002,453000,454001],
    //     telefono: '56999999999',
    // },
    {
        rut: '77636544-0',
        nombre: 'FIRMA RENT S.A.',
        giro: 'ALQUILER DE VEHICULOS AUTOMOTORES SIN CHOFER',
        direccion: '1 SUR 3155 FCO ENCINA',
        comuna: 'TALCA',
        ciudad: 'TALCA',
        actividades: [620200,620900,771000],
        telefono: '56999999999',
        //rutCertificado: rutMiguelVargas,
        //claveCertificado: claveCertificadoSIIMiguel,
        rutCertificado: rutDiegoVargas,
        claveCertificado: claveCertificadoSIIDiego,
        //fechaResolucion: '20-12-2022',/* para desarrollo */
        fechaResolucion: '22-08-2014',/* para produccion */

        representanteLegal: rutMiguelVargas,
        // userSII: "77636544-0",
        userSII: rutMiguelVargas,
        claveSII: claveSIIMiguel_Diego_Solanch,
        nombreRepresentante: "MIGUEL EDUARDO VARGAS GARRIDO",
        correoElectronico: "diego.vargas.garrido@hotmail.com",
        //numeroResolucion: 0,/* para desarrollo */
        numeroResolucion: 80,/* para produccion */

    },
    // {
    //     rut: '77640344-K',
    //     nombre: 'GF SYSTEMS S.A',
    //     giro: '',
    //     direccion: 'AV. ISIDORO DEL SOLAR 135',
    //     comuna: 'MAULE',
    //     actividades: [620200,620900],
    //     telefono: '56999999999',
    // },
    // {
    //     rut: '77469792-6',
    //     nombre: 'TEJOS Y GALLEGOS LIMITADA',
    //     giro: '',
    //     direccion: 'LOS COPIHUES LT B ST 41 A',
    //     comuna: 'MAULE',
    //     actividades: [451002],
    //     telefono: '56999999999',
    // },
    {
        rut: '77693308-2',
        nombre: 'GALLEGOS Y VARGAS ABOGADOS LTDA',
        giro: 'SERVICIOS DE ASESORAMIENTO Y REPRESENTACION JURIDICA',
        direccion: 'AV. ISIDORO DEL SOLAR 135',
        comuna: 'TALCA',
        ciudad: 'TALCA',
        actividades: [691001,960909],
        telefono: '56999999999',
        rutCertificado: rutMiguelVargas,
        claveCertificado: claveCertificadoSIIMiguel,
        fechaResolucion: '21-10-2014',
        representanteLegal: "",
        userSII: "",
        claveSII: "",
        nombreRepresentante: "",
        correoElectronico: "",
        numeroResolucion: 99,
    },
]