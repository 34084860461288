<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Informacion De La Cuenta">
    <form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()">
      <mat-card class="shipping-card">
        <h1>Cuenta Bancaria</h1>
        <hr />
        <mat-card-content>
          <mat-card>
            <h3>Informacion General</h3>
            <hr />
            <!-- Fila 1 -->
            <div class="row">
              <!--nombreCuenta -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Nombre"
                    formControlName="nombreCuenta"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>currency_exchange_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!--numeroCuenta -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Cuenta"
                    formControlName="numeroCuenta"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>payments_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!--rut -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Rut"
                    formControlName="rut"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>payments_white_18dp</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- banco -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Banco"
                    formControlName="banco"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>filter_9_plus_white_18dp</mat-icon>
                </mat-form-field>
              </div>

              <!-- tipoMonto -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Tipo Monto"
                    formControlName="tipoMonto"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>filter_9_plus_white_18dp</mat-icon>
                </mat-form-field>
              </div>

              <!-- totalMonto -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Total"
                    formControlName="totalMonto"
                    readonly="»readonly»"
                  />
                  <span matPrefix>$&nbsp;</span>
                  <mat-icon matSuffix>monetization_on_white_18dp</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <br />
            <h3>Para Agregar valor a Total</h3>
            <hr />
            <div class="row">
              <!--rut -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Valor a Agregar"
                    formControlName="addTotal"
                    type="number"
                  />
                  <mat-icon matSuffix>payments_white_18dp</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
          <button  mat-stroked-button color="primary" type="submit">
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
