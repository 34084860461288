<form [formGroup]="addressForm" (ngSubmit)="onSubmit()" class="mat-elevation-z10" novalidate>
    <mat-card class="shipping-card">
        <mat-card-header>
            <h2 class="md-display-1">Nueva bodega</h2>
        </mat-card-header>
        <br><br>
        <mat-card-content>
                <div class="row">
                <!-- sucursales -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Empresa" formControlName="idEmpresa" (selectionChange)="onSelectionChange($event)">
                            <mat-option *ngFor="let empresa of empresas" value="{{ empresa.id }}">
                                {{ empresa.razonSocial }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="addressForm.controls['idEmpresa'].hasError('required')">
                            La empresa es <strong>requerida</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="row">
                <!-- sucursales -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Sucursal" formControlName="idBodegaSucursal">
                            <mat-option *ngFor="let sucursal of sucursales" value="{{ sucursal.id }}">
                                {{ sucursal.razonSocial }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="addressForm.controls['idBodegaSucursal'].hasError('required') && addressForm.controls['idBodegaSucursal'].touched">
                            sucursal es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                  <!-- nombre bodega -->
                  <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Ingrese nombre de la bodega" formControlName="nombre" autocomplete="off" />
                        <mat-error *ngIf="addressForm.controls['nombre'].hasError('required') && addressForm.controls['nombre'].touched">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['nombre'].hasError('minlength') && addressForm.controls['nombre'].touched">
                            El nombre <strong>debe contener más de 5 carácteres</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['nombre'].hasError('pattern') && addressForm.controls['nombre'].touched">
                            El nombre <strong>debe contener sólo letras y numeros</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="row">
                <!-- Dirección -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Ingrese dirección" formControlName="direccion" autocomplete="off"/>
                        <mat-error *ngIf="addressForm.controls['direccion'].hasError('required') && addressForm.controls['direccion'].touched">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['direccion'].hasError('minlength') && addressForm.controls['direccion'].touched">
                            La Dirección debe contener más de <strong>5 carácteres</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Dirección -->
            </div>
        </mat-card-content>
        <!-- botón enviar -->
        <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon> Enviar
        </button>
        <button mat-raised-button style="margin-left: 1em;" color="primary" type="button" (click)="limpiarFormulario()">
            Limpiar
        </button>
    </mat-card>
</form>