import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidarRutService {
  constructor() {}
  validar_rut(rut: string): boolean {
    // Eliminar espacios y guiones
    rut = rut.replace(/\s+/g, '').replace(/-/g, '')

    // Verificar longitud mínima del número del RUT
    if (rut.length < 8 || rut.length > 9) {
      return false
    }

    // Obtener el dígito verificador
    const digitoVerificador: string = rut.charAt(rut.length - 1)
    // Obtener el número del RUT sin el dígito verificador
    const numeroRut: string = rut.substring(0, rut.length - 1)

    // Validar que el número del RUT contenga solo dígitos
    if (!/^\d+$/.test(numeroRut)) {
      return false
    }

    // Validar si el RUT es de todos los dígitos iguales (caso común de RUTs inválidos)
    /* if (/^(\d)\1{7}$/.test(numeroRut)) {
      return false
    } */

    const caracteres: number[] = []
    const serie: number[] = [2, 3, 4, 5, 6, 7]
    let sumatoria = 0
    let k = 0

    // Convertir el número del RUT a una lista de números en orden inverso
    for (let i = numeroRut.length - 1; i >= 0; i--) {
      caracteres.push(parseInt(numeroRut.charAt(i), 10))
    }

    // Calcular la sumatoria
    for (let j = 0; j < caracteres.length; j++) {
      if (k === 6) {
        k = 0
      }
      sumatoria += caracteres[j] * serie[k]
      k++
    }

    // Calcular el dígito verificador
    const resto = sumatoria % 11
    let dv: number | string
    dv = 11 - resto

    if (dv === 10) {
      dv = 'K'
    } else if (dv === 11) {
      dv = 0
    }

    // Comparar el dígito verificador calculado con el proporcionado
    return (
      dv.toString().trim().toUpperCase() ===
      digitoVerificador.toString().trim().toUpperCase()
    )
  }
}