<mat-tab-group>
  <mat-tab label="Ver">
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>

    <form
      [formGroup]="addressForm"
      novalidate
      (ngSubmit)="getCliente()"
      class="mat-elevation-z10"
    >
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Ver Cliente</h1>
        </mat-card-header>
        <mat-card-content>
          <!-- Informacion Basica -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Basica </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Estado del cliente  -->
            <div class="col">
              <section class="estado-section">
                <p>
                  <mat-checkbox  [disabled]="blockSelect" formControlName="estado" value="{{ estado }}"
                    >Cliente Vigente
                  </mat-checkbox>
                </p>
              </section>
            </div>
            <!-- informacion fila uno -->
            <div class="row">
              <!-- Nombre -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Nombre"
                    formControlName="nombre"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>badge_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['nombre'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- alias -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Nombre Fantasía"
                    formControlName="alias"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>branding_watermark_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['alias'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Rut -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Rut"
                    formControlName="rut"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>fingerprint_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['rut'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- informacion fila dos -->
            <div class="row">
              <!-- giro -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Giro"
                    formControlName="giro"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>currency_exchange_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['giro'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- codigo -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Codigo"
                    formControlName="codigo"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>vpn_key_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['codigo'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- Informacion de ubicacion -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Ubicacion</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <!-- direccion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Direccion"
                    formControlName="direccion"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>home_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['direccion'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- ciudad -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Ciudad"
                    formControlName="ciudad"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>home_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['ciudad'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- comuna -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Comuna"
                    formControlName="comuna"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>push_pin_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['comuna'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- Informacion de Pago-->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Informacion de Pago</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <!-- nombre Responsable -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="string"
                    placeholder="Nombre Responsable"
                    formControlName="nombreResponsable"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix
                    >transfer_within_a_station_white_18dp</mat-icon
                  >
                  <mat-error
                    *ngIf="
                      addressForm.controls['nombreResponsable'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- email -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Email"
                    formControlName="email"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>mail_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['email'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- telefono -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Telefono"
                    formControlName="telefono"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>add_call_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['comuna'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- otra inf-->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Otra Informacion </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <!-- descuento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Descuento"
                    formControlName="descuento"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>currency_exchange_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['descuento'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- credito -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Credito"
                    formControlName="credito"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>home_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['credito'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Contacto Comercial  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Contacto Comercial"
                    formControlName="contactoComercial"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>contacts_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['contactoComercial'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- emailComercial  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Email Comercial"
                    formControlName="emailComercial"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>contacts_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['emailComercial'].hasError(
                        'required'
                      )
                    "
                    >Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- lineaCredito  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Linea Credito"
                    formControlName="lineaCredito"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>show_chart_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['lineaCredito'].hasError('required')
                    "
                    >Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- telefonoComercial  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="telefono Comercial"
                    formControlName="telefonoComercial"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>settings_phone_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['telefonoComercial'].hasError(
                        'required'
                      )
                    "
                    >Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!--celular  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Celular"
                    formControlName="celular"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>settings_cell_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['celular'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-label>Tipo de Pago </mat-label>
                  <mat-select formControlName="formaPago"  [disabled]="blockSelect">
                    <mat-option value="cheque">Cheque</mat-option>
                    <mat-option value="credito">Credito</mat-option>
                    <mat-option value="DepositoEfectivo"
                      >Deposito en efectivo</mat-option
                    >
                    <mat-option value="efectivo">Efectivo</mat-option>
                    <mat-option value="tarjetaCredito"
                      >Tarjeta Credito</mat-option
                    >
                    <mat-option value="tarjetaDebito"
                      >Tarjeta Debito</mat-option
                    >
                    <mat-option value="transferenicaElectronica"
                      >Transferencia Electronica</mat-option
                    >
                    <mat-option value="notaCreditoDevolucion"
                      >Nota Credito Devolcuion</mat-option
                    >
                    <mat-option value="webPay">Webpay</mat-option>
                  </mat-select>
                  <mat-icon matSuffix>payments_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['formaPago'].hasError('required')
                    "
                    ><strong> La forma de pago es requerida</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- referencia -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-label>Referencia</mat-label>
                  <textarea
                    matInput
                    placeholder="Ingrese Referencia"
                    formControlName="referencia"
                    readonly="»readonly»"
                  ></textarea>
                  <mat-icon matSuffix>description_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['referencia'].hasError('required')
                    "
                    ><strong> La referencia es requerida</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-card-content>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="Editar">
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form
      [formGroup]="addressForm"
      novalidate
      (ngSubmit)="onSubmit()"
      class="mat-elevation-z10"
    >
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Editar Cliente</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Informacion Basica -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Basica </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Estado del cliente  -->
            <div class="col">
              <section class="estado-section">
                <p>
                  <mat-checkbox formControlName="estado" value="{{ estado }}"
                    >Cliente Vigente
                  </mat-checkbox>
                </p>
              </section>
            </div>
            <!-- informacion fila uno -->
            <div class="row">
              <!-- Nombre -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Nombre"
                    formControlName="nombre"
                    required
                  />
                  <mat-icon matSuffix>badge_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['nombre'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- alias -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Nombre Fantasía"
                    formControlName="alias"
                    required
                  />
                  <mat-icon matSuffix>branding_watermark_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['alias'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Rut -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Rut"
                    formControlName="rut"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>fingerprint_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['rut'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- informacion fila dos -->
            <div class="row">
              <!-- giro -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Giro"
                    formControlName="giro"
                    required
                  />
                  <mat-icon matSuffix>currency_exchange_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['giro'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- codigo -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Codigo"
                    formControlName="codigo"
                    required
                    type="number"
                  />
                  <mat-icon matSuffix>vpn_key_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['codigo'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- Informacion de ubicacion -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Ubicacion</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <!-- direccion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Direccion"
                    formControlName="direccion"
                    required
                  />
                  <mat-icon matSuffix>home_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['direccion'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- ciudad -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Ciudad"
                    formControlName="ciudad"
                    required
                  />
                  <mat-icon matSuffix>home_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['ciudad'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- comuna -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Comuna"
                    formControlName="comuna"
                    required
                  />
                  <mat-icon matSuffix>push_pin_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['comuna'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- Informacion de Pago-->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Informacion de Pago</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <!-- nombre Responsable -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="string"
                    placeholder="Nombre Responsable"
                    formControlName="nombreResponsable"
                    required
                  />
                  <mat-icon matSuffix
                    >transfer_within_a_station_white_18dp</mat-icon
                  >
                  <mat-error
                    *ngIf="
                      addressForm.controls['nombreResponsable'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- email -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Email"
                    formControlName="email"
                    required
                  />
                  <mat-icon matSuffix>mail_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['email'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- telefono -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    minlength="8"
                    maxlength="10"
                    placeholder="Telefono"
                    formControlName="telefono"
                    required
                    type="number"
                  />
                  <mat-icon matSuffix>add_call_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['comuna'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- otra inf-->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Otra Informacion </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <!-- descuento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Descuento"
                    formControlName="descuento"
                    type="number"
                  />
                  <mat-icon matSuffix>currency_exchange_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['descuento'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- credito -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Credito"
                    formControlName="credito"
                  />
                  <mat-icon matSuffix>home_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['credito'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Contacto Comercial  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Contacto Comercial"
                    formControlName="contactoComercial"
                  />
                  <mat-icon matSuffix>contacts_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['contactoComercial'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- emailComercial  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Email Comercial"
                    formControlName="emailComercial"
                  />
                  <mat-icon matSuffix>contacts_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['emailComercial'].hasError(
                        'required'
                      )
                    "
                    >Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- lineaCredito  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Linea Credito"
                    formControlName="lineaCredito"
                  />
                  <mat-icon matSuffix>show_chart_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['lineaCredito'].hasError('required')
                    "
                    >Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- telefonoComercial  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    minlength="8"
                    maxlength="10"
                    placeholder="telefono Comercial"
                    formControlName="telefonoComercial"
                    required
                    type="number"
                  />
                  <mat-icon matSuffix>settings_phone_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['telefonoComercial'].hasError(
                        'required'
                      )
                    "
                    >Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!--celular  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    minlength="8"
                    maxlength="10"
                    placeholder="Celular"
                    formControlName="celular"
                    required
                    type="number"
                  />
                  <mat-icon matSuffix>settings_cell_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['celular'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-label>Tipo de Pago </mat-label>
                  <mat-select formControlName="formaPago">
                    <mat-option value="cheque">Cheque</mat-option>
                    <mat-option value="credito">Credito</mat-option>
                    <mat-option value="DepositoEfectivo"
                      >Deposito en efectivo</mat-option
                    >
                    <mat-option value="efectivo">Efectivo</mat-option>
                    <mat-option value="tarjetaCredito"
                      >Tarjeta Credito</mat-option
                    >
                    <mat-option value="tarjetaDebito"
                      >Tarjeta Debito</mat-option
                    >
                    <mat-option value="transferenicaElectronica"
                      >Transferencia Electronica</mat-option
                    >
                    <mat-option value="notaCreditoDevolucion"
                      >Nota Credito Devolcuion</mat-option
                    >
                    <mat-option value="webPay">Webpay</mat-option>
                  </mat-select>
                  <mat-icon matSuffix>payments_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['formaPago'].hasError('required')
                    "
                    ><strong> La forma de pago es requerida</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- referencia -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-label>Referencia</mat-label>
                  <textarea
                    matInput
                    type="string"
                    placeholder="Ingrese Referencia"
                    formControlName="referencia"
                    required
                  ></textarea>
                  <mat-icon matSuffix>description_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['referencia'].hasError('required')
                    "
                    ><strong> La referencia es requerida</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-raised-button
            color="primary"
            type="submit"
          ><mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
