import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@environments/environment'
import { Observable, BehaviorSubject } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

/* Imports Excel */
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { Prestamos } from '@app/_models/prestamos/prestamos'
import { DialogDetalleEmpresaComponent } from './prestamos-ingresos-egresos/prestamos-list/dialog-detalle-empresa/dialog-detalle-empresa.component'
import { AccionesPrestamosComponent } from './prestamos-ingresos-egresos/prestamos-list/acciones-prestamos/acciones-prestamos.component'
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'
/* Fin Import Excel */

@Injectable({
  providedIn: 'root'
})
export class PrestamosService {
  private empresa = 'Prestamos'

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  //Ingresos y egresos

  prestamosGetAll(): any {
    return this.http.get<[]>(`${environment.apiUrl}/${this.empresa}`)
  }

  buscarPrestamos(prestamos: Prestamos) {
    return this.http.post(
      ` ${environment.apiUrl}/${this.empresa}/conRespaldo`,
      prestamos
    )
  }

  // public exportAsExcelFile(json: any[], excelFileName: string): void {
  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
  //   const workbook: XLSX.WorkBook = {
  //     Sheets: { data: worksheet },
  //     SheetNames: ['data']
  //   }
  //   const excelBuffer: any = XLSX.write(workbook, {
  //     bookType: 'xlsx',
  //     type: 'array'
  //   })
  //   this.saveAsExcelFile(excelBuffer, excelFileName)
  // }
  public async exportAsExcelFilePrestamos(
    json: any[],
    excelFileName: string,
    selectedColumns: string[]
  ): Promise<void> {
   const columnMappings = {
     id: 'ID',
     empresaS: 'Empresa Prestamista',
     empresaD: 'Empresa Recepcionista',
     fecha: 'Fecha',
     monto: 'Monto',
     bancoS: 'Banco Prestamista 2',
     bancoD: 'Banco Prestamista',
     tipoPago: 'Tipo Pago',
     responsable: 'Responsable',
     descripcion: 'Descripcion'
   }

    const newData = await Promise.all(
      json.map(async (item) => {
        const newItem: any = {}
        for (const col of selectedColumns) {
         if (col === 'fecha') {
            if (item[col] !== null) {
              try {
                const fecha = new Date(item[col])
                const formattedFecha = `${fecha.getDate()}/${
                  fecha.getMonth() + 1
                }/${fecha.getFullYear()}`
                newItem[columnMappings[col]] = formattedFecha
              } catch (error) {
                console.error('Error al obtener la fecha:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else {
            newItem[columnMappings[col]] = item[col]
          }
        }

        return newItem
      })
    )

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + 'export' + new Date().getTime() + EXCEL_EXTENSION
    )
  }

  buscarImagen(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/${this.empresa}/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  /* Obteniendo detalle de cada empresa */
  getPrestamos(id: any) {
    return this.http.get(`${environment.apiUrl}/${this.empresa}/${id}`)
  }

  getBancos() {
    return this.http.get<Prestamos>(
      `${environment.apiUrl}/banco/obtenerBancos/`
    )
  }

  getEmpresas() {
    //return this.http.get<[]>(`${environment.apiUrl}/${this.empresa}/empresas/`);
    return this.http.get<[]>(`${environment.apiUrl}/empresa/`)
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogDetalleEmpresa(idPrestamo: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    const dialogRef = this.dialog.open(DialogDetalleEmpresaComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idPrestamoEmpresa')
    localStorage.removeItem('nombreEmpresa')
  }

  //modificaciones sh

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditPrestamo(): void {
    const dialogRef = this.dialog.open(AccionesPrestamosComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  getByIdPrestamos(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/prestamos/detalle/${id}`)
  }

  //Editar Prestamo
  updatePrestamo(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/prestamos/UpdateForm/${id}`,
      params
    )
  }

  getByIdPrestamoSinBase64(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/prestamos/sinBase64/${id}`)
  }

  //Editar Prestamo
  updatePrestamoDetalle(id: any, params: any[]) {
    return this.http.put(`${environment.apiUrl}/prestamos/${id}`, params)
  }
}
