import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'

export class IngresosHostal {
  id!: string
  fecha!: Date
  monto!: number
  tipoPago!: string
  nDocumento!: string
  cliente!: string
  tipoCliente!: string
  descripcionIngreso!: string
  tipoIngreso!: string
  estadoPago!: string
  banco!: string
  nAutorizacion!: string
  referenciaCliente!: string
  archivos!: string[]
  // Mofificado por Mendex
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Sucursal!: Sucursal
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Usuario!: Usuario
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RespaldoIngresos!: any[]
  idSucursal!: number
  idUsuario!: number
  usuario!: string
  sucursal!: string
  fechaf!: string
  tipoDocumento!: string
  numeroCuenta!: number
  Servicios!: any
}


