import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { IngresosInmobiliaria } from '@app/_models/inmobiliaria/ingresoInmobiliaria'
import { Usuario } from '@app/_models/shared/usuario'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'
import { InmobiliariaService } from '../../inmobiliaria.service'

@Component({
  selector: 'app-inmobiliaria-ingresos-acciones',
  templateUrl: './inmobiliaria-ingresos-acciones.component.html',
  styleUrls: ['./inmobiliaria-ingresos-acciones.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class InmobiliariaIngresosAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()

  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  result2 = ''
  numberConvert = ''

  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any

  dataArrayImg = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  idIngresoInmobiliaria: any
  propiedad: any
  fecha: any
  monto: any
  tipoIngreso: any
  descripcionIngreso: any

  //agregado
  nAutorizacion : any
  referenciaCliente : string[]
  nDocumento : any
  tipoPago : string[]
  tipoCliente : string[]
  cliente: any

  modelo: IngresosInmobiliaria = new IngresosInmobiliaria()
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private inmobiliariaService: InmobiliariaService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.tipoCliente = ['Particular', 'Empresa']
    this.tipoPago = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Cheque'
    ]
  }

  ngOnInit(): void {
    this.idIngresoInmobiliaria = localStorage.getItem('idIngresoInmobiliaria')
    this.getIngresoInmobiliaria()
  }

  //Formulario
  addressForm = this.fb.group({
    propiedad: ['', Validators.required],
    fecha: ['', Validators.required],
    monto: ['', Validators.required],
    tipoIngreso: ['', Validators.required],
    descripcionIngreso: ['', Validators.required],
    //agregadoo
    nAutorizacion: ['', Validators.required],
    referenciaCliente: ['', Validators.required],
    nDocumento: ['', Validators.required],
    tipoPago: ['', Validators.required],
    tipoCliente: ['', Validators.required],
    cliente: ['', Validators.required],

    archivos: null
  })

  get f() {
    return this.addressForm.controls
  }

  closeDialog() {
    this.inmobiliariaService.closeDialogModal()
    window.location.reload()
  }

  getIngresoInmobiliaria() {
    this.inmobiliariaService
      .getByIdIngreso(this.idIngresoInmobiliaria)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idIngresoInmobiliaria) {
          this.f.propiedad.setValue(x[0].propiedad)
          this.f.fecha.setValue(x[0].fecha)
          this.f.monto.setValue(x[0].monto)
          this.f.tipoIngreso.setValue(x[0].tipoIngreso)
          this.f.descripcionIngreso.setValue(x[0].descripcionIngreso)
          this.f.archivos.setValue(x[0].archivos)
          //agregado
          this.f.nAutorizacion.setValue(x[0].nAutorizacion)
          this.f.referenciaCliente.setValue(x[0].referenciaCliente)
          this.f.nDocumento.setValue(x[0].nDocumento)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.tipoCliente.setValue(x[0].tipoCliente)
          this.f.cliente.setValue(x[0].cliente)
          

          //agregado

        }
        this.previsualizacion = x[0].archivos
      })
  }
  capturarFile(event) {
    const archivoCapturado = event.target.files[0]
    this.dataArrayImg.push(archivoCapturado.name)
    this.extraerBase64(archivoCapturado).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoCapturado)
  }

  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        console.log(image)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/ingresoInmobiliaria/Upload/IngresoInmobiliaria`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }
  //Actualiza imagen en el fomulario
  agregarIMG() {
    if (confirm('Seguro que desea actulizar IMG de Ingreso')) {
      this.inmobiliariaService
        .getByIdIngresoSinBase64(this.idIngresoInmobiliaria)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idIngresoInmobiliaria) {
            const data = x[0]
            //console.log(data)
            this.addressForm.value.propiedad = data.propiedad
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.monto = data.monto
            this.addressForm.value.tipoIngreso = data.tipoIngreso
            this.addressForm.value.descripcionIngreso = data.descripcionIngreso

            //agregado
            this.addressForm.value.nAutorizacion = data.nAutorizacion
            this.addressForm.value.referenciaCliente = data.referenciaCliente
            this.addressForm.value.nDocumento = data.nDocumento
            this.addressForm.value.tipoCliente = data.tipoCliente
            this.addressForm.value.tipoPago = data.tipoPago
            this.addressForm.value.cliente = data.cliente

            this.addressForm.value.archivos = this.dataArrayImg
            this.inmobiliariaService
              .updateInmobiliariaIngreso(
                this.idIngresoInmobiliaria,
                this.addressForm.value
              )
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(
                    'Imagen actulizada correctamente!.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                  this.addressForm.reset()
                  this.inmobiliariaService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el Ingreso a Inmobiliaria, favor contactar a Soporte.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }

  onSubmit() {
    if (confirm('Seguro que desea actualizar ingresos de Inmobiliaria :')) {
      this.inmobiliariaService
        .getByIdIngresoSinBase64(this.idIngresoInmobiliaria)
        .pipe(first())
        .subscribe((x: any) => {
          const nombreArchivo = x[0].archivos
          const JsonNombreArchivo = JSON.parse(nombreArchivo)
          if (this.dataArrayImg[0] === undefined) {
            this.addressForm.value.archivos = JsonNombreArchivo
          } else {
            this.addressForm.value.archivos = this.dataArrayImg
          }
          switch (this.addressForm.status) {
            case 'VALID':
              this.inmobiliariaService
                .updateInmobiliariaIngreso(
                  this.idIngresoInmobiliaria,
                  this.addressForm.value
                )
                .pipe(first())
                .subscribe(
                  (data) => {
                    this.snackBar.open(
                      'Ingreso Inmobiliaria editado con exito',
                      'Cerrar',
                      { duration: 2000, verticalPosition: 'top' }
                    )
                    this.addressForm.reset()
                    this.inmobiliariaService.closeDialogModal()
                    window.location.reload()
                  },
                  (error) => {
                    this.snackBar.open(
                      'No se pudo editar el Ingreso de Inmobiliaria, favor contactar a Soporte.',
                      'Cerrar',
                      {
                        duration: 2000,
                        verticalPosition: 'top'
                      }
                    )
                  }
                )
              break
            case 'INVALID':
              this.snackBar.open(
                'El formulario debe ser completado.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              break

            default:
              break
          }
        })
    }
  }
}
