<h1>Consolidado Diario de Gastos</h1>
<div class="center-content">
    <mat-expansion-panel [expanded]="true">
        <table matSort align="center">
            <br>
            <tr><th colspan="6" ><h4>Monto total por Empresa</h4></th></tr>
            <tr>
                <th mat-sort-header="egresosHostal">Egresos Hostal </th>
                <th mat-sort-header="egresosLubricentro">Egresos Lubricentro</th>
                <th mat-sort-header="egresosFirma">Egresos Firma Abogados</th>
                <th mat-sort-header="egresosRentaCar">Egresos RentaCar</th>
                <th mat-sort-header="egresosInmobiliaria">Egresos Inmobiliaria</th>
                <th mat-sort-header="egresosImportadora">Egresos Importadora</th>
            </tr>
            <tr>
                <td>{{ this.totalEgresosHostal | currency:'CLP':'symbol':'1.0-0' }}</td>
                <td>{{ this.totalEgresosLubricentro | currency:'CLP':'symbol':'1.0-0' }}</td>
                <td>{{ this.totalEgresosAbogados | currency:'CLP':'symbol':'1.0-0' }}</td>
                <td>{{ this.totalEgresosRentacar | currency:'CLP':'symbol':'1.0-0' }}</td>
                <td>{{ this.totalEgresosInmobiliaria | currency:'CLP':'symbol':'1.0-0' }}</td>
                <td>{{ this.totalEgresosInportadora | currency:'CLP':'symbol':'1.0-0' }}</td>
            </tr>
            <button
            mat-raised-button
            color="accent"
            style="margin-left: 1rem"
            >
            </button>
        </table>
        <div class="text-center">
            <p>Total General: {{ calcularTotalGeneral() | currency:'CLP':'symbol':'1.0-0' }}</p>
          </div>
    </mat-expansion-panel>
</div>