import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Variante } from "@app/_models/variante/variante";
import { environment } from "@environments/environment";

@Injectable({
    providedIn: 'root'
})

export class VariantesService{

    constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private http: HttpClient){

    }

    createVariante(variante: Variante){
    return this.http.post(`${environment.apiUrl}/variantes/crear/`,variante);
    }

    //Metodo que trae todas las variantes de la bd
    varianteGet(): any {
    return this.http.get<[]>(`${environment.apiUrl}/variantes/`);
    }

    varianteGetId(id: number) {
    return this.http.get<Variante>(`${environment.apiUrl}/variantes/${id}`);
    }

    deleteVariante(id: number) {
    return this.http.delete(`${environment.apiUrl}/variantes/borrar/${id}`);
    }

    traerNumeroVariantes(id: number):any{
        return this.http.get(`${environment.apiUrl}/variantes/numeroVariantes/${id}`)
    }

}