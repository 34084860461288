import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'

export class IngresosImportadora {
  id!: number;
  vendedor!: string;
  tipoIngreso!: string;
  medioPago!: string;
  codigoAutorizacion!: string;
  descripcionIngreso!: string;
  monto!: number;
  fecha!: Date;
  referencia!: string;
  sucursal!: string;
  usuario!: string;
  Sucursal!: Sucursal;
  Usuario!: Usuario;
  RespaldoIngresoImportadoras!: any[];
  banco!: string;
  numeroCuenta!: string; 
  archivos!:any
  idUsuario!:number;
  idSucursal!: number;
  Servicios!: any
  nombreCliente!: string;
  tipoCliente!: string;
  Referencia!: string;
  tipoDocumento!: string;
  nDocumento!: string;
  estadoPago!: string;
  tipoPago!: string;
}
