<form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
    <mat-card class="shipping-card">
        <mat-card-header>
            <h1>{{ nombreUsuario }}</h1>
        </mat-card-header>
        <br><br>
        <mat-card-content>
            <div class="row">
                <!-- Rol -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Rol Usuario" formControlName="RolID">
                            <mat-option *ngFor="let rol of roles" value="{{ rol.id }}">
                                {{ rol.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="addressForm.controls['RolID'].hasError('required')">
                            rol es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Correo -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Correo" formControlName="correoElectronico" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" />
                        <mat-error *ngIf="addressForm.controls['correoElectronico'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['correoElectronico'].hasError('pattern')">
                            formato de correo inválido
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <!-- Nombre-->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Nombres" formControlName="nombre" />
                        <mat-error *ngIf="addressForm.controls['nombre'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Apellido  -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Apellidos" formControlName="apellido" />
                        <mat-error *ngIf="addressForm.controls['apellido'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <!-- Nombre Usuario -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Nombre Usuario" formControlName="nombreUsuario" />
                        <mat-error *ngIf="addressForm.controls['nombreUsuario'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Contraseña -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Contraseña" formControlName="hash" type="password" />
                        <mat-error *ngIf="addressForm.controls['hash'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['hash'].hasError('minlength')">
                            la contraseña debe tener un minimo de <strong>6 caracteres</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>

        <!-- botón enviar -->
        <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon> Enviar
        </button>
    </mat-card>
</form>