import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { ComponentsModule } from '../../_components/components.module';
import { AjusteInventarioHomeComponent } from './ajuste-inventario-home/ajuste-inventario-home.component';
import { AjusteInventarioRoutingModule } from './ajusteInventario-routing.module';
import { AjusteInventarioFormComponent } from './ajuste-inventario-form/ajuste-inventario-form.component';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AjusteInventarioFormSalidaComponent } from './ajuste-inventario-form-salida/ajuste-inventario-form-salida.component';
//import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { NgSelectModule } from '@ng-select/ng-select';
//import { DropdownModule } from 'primeng/dropdown';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AjusteInventarioListComponent } from './ajuste-inventario-list/ajuste-inventario-list.component';
import { DialogAjusteInventarioVerComponent } from './ajuste-inventario-list/dialog-ajuste-inventario-ver/dialog-ajuste-inventario-ver.component';

@NgModule({
    declarations: [
        AjusteInventarioHomeComponent,
        AjusteInventarioFormComponent,
        AjusteInventarioFormSalidaComponent,
        AjusteInventarioListComponent,
        DialogAjusteInventarioVerComponent,
        
    ],
    imports: [
        AjusteInventarioRoutingModule,
        CommonModule,
        MaterialModule,
        ComponentsModule,
        //NgbModule,
        //PrimeNgModule,
        //DropdownModule,
        FormsModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA],
    bootstrap:    [ AjusteInventarioFormSalidaComponent ]
    
})

export class AjusteInventarioModule {
    
}