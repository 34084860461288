import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consolidado-bancario-home',
  templateUrl: './consolidado-bancario-home.component.html',
  styleUrls: ['./consolidado-bancario-home.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class ConsolidadoBancarioHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
