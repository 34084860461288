import { ProveedoresService } from './../../../proveedores.service'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { first } from 'rxjs/operators'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-proveedores-table-edit',
  templateUrl: './proveedores-table-edit.component.html',
  styleUrls: ['./proveedores-table-edit.component.scss']
})
export class ProveedoresTableEditComponent implements OnInit {
  idProveedor: any
  nombre: any
  giro: any
  direccion: any
  contacto: any

  //configuracion de formulario
  addressForm = this.fb.group({
    nombre: ['', Validators.required],
    giro: ['', Validators.required],
    direccion: ['', Validators.required],
    contacto: ['', Validators.required]
  })
  constructor(
    private fb: FormBuilder,
    private administracionService: AdministracionService,
    private proveedoresService: ProveedoresService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.idProveedor = localStorage.getItem('idProveedor')
    this.getProveedor()
  }
  get f() {
    return this.addressForm.controls
  }

  //metodo para buscar los datos
  getProveedor() {
    this.proveedoresService
      .getById(this.idProveedor)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idProveedor) {
          this.f.nombre.setValue(x[0].nombre)
          this.f.giro.setValue(x[0].giro)
          this.f.direccion.setValue(x[0].direccion)
          this.f.contacto.setValue(x[0].contacto)
        }
      })
  }


  //Actualizar formulario
  onSubmit() {

    if (confirm('Esta seguro que desea actualizar el proveedor: ')) {
      switch (this.addressForm.status) {

        case 'VALID':
          this.proveedoresService
            .updateProveedor(this.idProveedor, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Proveedor editado con éxito.', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })

                this.addressForm.reset()
                this.proveedoresService.closeDialogModal()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar el proveedor, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break

        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }

    }
  }



  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
  }
}
