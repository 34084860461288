import { Component, OnInit } from '@angular/core'
import { Validators, FormBuilder } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Empresa } from '@app/_models/shared/empresa'
import { Sucursal } from '@app/_models/shared/sucursal'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { LubricentroService } from '@app/_pages/lubricentro/lubricentro.service'
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-lubricentro-egresos-edit',
  templateUrl: './lubricentro-egresos-edit.component.html',
  styleUrls: ['./lubricentro-egresos-edit.component.scss']
})
export class LubricentroEgresosEditComponent implements OnInit {
  idLubricentro: any
  fecha: any
  monto: any
  responsable: any
  tipoEgreso: any
  sucu: any;
  idEmpresa = 3;
  empresa = new Empresa();

  /**  Configuración de formulario  */
  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    monto: ['', Validators.required],
    responsable: ['', Validators.required],
    tipoEgreso: ['', Validators.required],
    tipoPago: ['', Validators.required],
    idSucursal: ['', Validators.required],
    sucu: [''],
    descripcion: ['', Validators.required],
  })

  sucursales: Sucursal[]
  tiposEgresos: string[] = []
  tiposPagos: any[] = []

  constructor(
    private fb: FormBuilder,
    private sucursalService: SucursalSharedService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private administracionService: AdministracionService,
    private lubricentroSevice: LubricentroService,
    private empresaService: EmpresaSharedService,
  ) {
    this.sucursales = this.sucursalService.sucursalListValue
    this.tiposPagos = [
      'Efectivo',
      'Debito',
      'Credito',
      'Transferencia',
      'Cheque'
    ]
    this.tiposEgresos = [
      'Gastos',
      'Costos',
      'Remuneraciones',
      'Impuestos',
      'Bancarios'
    ]
  }

  ngOnInit(): void {
    this.idLubricentro = localStorage.getItem('idEgresoLubricentro')
    this.getIngresoLubricentro()
    this.getEmpresa(this.idEmpresa);
  }

  getIngresoLubricentro() {
    this.lubricentroSevice
      .getById(this.idLubricentro)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idLubricentro) {
          this.f.fecha.setValue(x[0].fecha)
          this.f.monto.setValue(x[0].monto)
          this.f.responsable.setValue(x[0].responsable)
          this.f.tipoEgreso.setValue(x[0].tipoEgreso)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.descripcion.setValue(x[0].descripcion)
          this.f.idSucursal.setValue(x[0].idSucursal)

          this.sucu = x[0].Sucursal.razonSocial
        }

        this.sucu = x[0].Sucursal.razonSocial
      })
  }
  get f() {
    return this.addressForm.controls
  }

  onSubmit() {
    switch (this.addressForm.status) {
      case 'VALID':
        this.lubricentroSevice
          .updateLubricentroEgreso(this.idLubricentro, this.addressForm.value)
          .pipe(first())
          .subscribe(
            (data) => {
              this.snackBar.open('Lubricentro editada con éxito.', 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })

              this.addressForm.reset()
              this.lubricentroSevice.closeDialogModal()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo editar la lubricentro, favor contactar a informática.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                });
            }
          );
        break;

        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar',{
            duration: 2000,
            verticalPosition: 'top',
          });
          break;

        default:
          break;
    }
  }


  getEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x) => {
        x.Sucursals = Object.values(x.Sucursals);
       
        this.empresa = x;


      
      });
  }

   //Cerrar Modal
   closeDialog() {
    this.administracionService.closeDialogModal()
  }
}
