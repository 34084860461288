import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-folio-home',
  templateUrl: './folio-home.component.html',
  styleUrls: ['./folio-home.component.scss']
})
export class FolioHomeComponent implements OnInit {
  constructor( ){ }
  ngOnInit(): void {

  }
}
