import {Component, OnInit, EventEmitter, ViewChildren, ViewChild, QueryList, ElementRef} from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { HostalService } from '@app/_pages/hostal/hostal.service'
import { ConsolidadosHostal } from '@app/_models/hostal/consolidadosHostal'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AlertHelper } from '@app/_helpers/alert.helper'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { SelectionModel } from '@angular/cdk/collections'
import { FormControl, FormGroup } from '@angular/forms'
import Chart from 'chart.js/auto'
import { ChangeDetectorRef } from '@angular/core'
import * as ExcelProper from "exceljs";
import * as Excel from "exceljs";
import * as FileSaver from 'file-saver';
import { link } from 'fs'
import { timeout } from 'rxjs/operators'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

@Component({
  selector: 'app-hostal-consolidados',
  templateUrl: './hostal-consolidados.component.html',
  styleUrls: ['./hostal-consolidados.component.scss']
})
export class HostalConsolidadosComponent implements OnInit {    
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()
  @ViewChild(MatSort) tableOneSort = null

  @ViewChildren(MatPaginator) paginator2 = new QueryList<MatPaginator>()
  @ViewChild(MatSort) tableTwoSort = null

  @ViewChild('barChart', { static: false }) private chartRef!: ElementRef
  @ViewChild('htmlData') htmlData!: ElementRef
  //dataSourceEgresos: MatTableDataSource<ConsolidadosHostal> = new MatTableDataSource();
  //@ViewChild('TableTwoPaginator', {static: true}) tableTwoPaginator: MatPaginator;
  //@ViewChild('TableTwoSort', {static: true}) tableTwoSort: MatSort;

  workbook: ExcelProper.Workbook = new Excel.Workbook();
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';


  formularioListo = new EventEmitter<string>()

  displayedColumns: string[] = ['select', 'id', 'fecha', 'monto']

  displayedColumnsTwo: string[] = ['select', 'id', 'fecha', 'monto']

  dataSource: MatTableDataSource<ConsolidadosHostal> = new MatTableDataSource()
  dataSourceEgresos: MatTableDataSource<ConsolidadosHostal> = new MatTableDataSource()
  dataIngresosTable: ConsolidadosHostal[] = []
  dataEgresosTable: ConsolidadosHostal[] = []

  // ? construccion del formulario,
  consolidadosForm = this.fb.group({
    //agregar el detalle del formulario;
    consolidados: [null, Validators.required],
    sucursales: [null, Validators.required],
    //egresos: [null, Validators.required],
    tramos: [null, Validators.required],
    trimestre: [null],
    semestre: [null],
    month: [null, Validators.required],
    year: [null],
    fechaI: [null],
    fechaF: [null]
  })

  //filtros
  formFilter = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    idSucursal: new FormControl()
  })

  consolidado: ConsolidadosHostal = new ConsolidadosHostal()
  selection = new SelectionModel<ConsolidadosHostal>(true, [])
  dataConsolidados: any
  selectedRows!: any[]
  dataIngresos: any[] = []
  dataEgresos: any[] = []
  totalIngreso: number = 0
  idEmpresa = 1
  totalEgreso: number = 0
  sumIngresos: number = 0
  sumIngresosG: number = 0
  sumEgresosG: number = 0
  sumG: number = 0
  sumEgresos: number = 0
  totalConsolidado: number = 0
  resultadoIE: number = 0
  ingresoPorDia: string = ''
  egresoPorDia: string = ''
  base64I: any
  cont = 0
  fe: string = ''
  montoI = 0
  montoE = 0
  ArrarI: any[] = [];
  ArrarE: any[] = [];
  flag: boolean = false;
  
  mostrarFechaIF: boolean = true
  mostrarTrimestre: boolean = true
  mostrarSemestre: boolean = true
  mostrarMes: boolean = true
  mostrarYear: boolean = true

  //VARIABLES PARA EL GRAFICO
  chart: any
  montoIngreso: any[] = []
  montoEgreso: any[] = []
  totalIngresoG: any[] = []
  totalEgresoG: any[] = []
  totalIngresoC: any[] = []
  totalEgresoC: any[] = []
  totalG: any[] = []
  fechaG: any[] = []
  fecha: any[] = []
  resultado: any[] = []
  ingresoN: any[] = []
  egresoN: any[] = []

  uniqueArr: any[] = []
  uniqueArr2: any[] = []
  fechaUnica: any[] = []
  fechaUnicaE: any[] = []
  todasFecha: any[] = []

  arreglosemestre: any[] = []
  arreglosemestre2: any[] = []
  arreglosemestreF: any[] = []
  arreglosemestreE: any[] = []
  arreglosemestre2E: any[] = []
  arreglosemestreFE: any[] = []

  arreglotrimestre: any[] = []
  arreglotrimestre2: any[] = []
  arreglotrimestreF: any[] = []
  arreglotrimestreE: any[] = []
  arreglotrimestre2E: any[] = []
  arreglotrimestreFE: any[] = []

  arregloyear: any[] = []
  arregloyear2: any[] = []
  arregloyearF: any[] = []
  arregloyearE: any[] = []
  arregloyear2E: any[] = []
  arregloyearFE: any[] = []

  nameMoth: any[] = []

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private alert: AlertHelper,
    private hostalService: HostalService,
    private elementRef: ElementRef,
    private _cdref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    
  }

  //Metodo para mostrar numero de cuotas
  activarFechaIF(): void {
    this.mostrarFechaIF = false
    this.consolidadosForm.controls['fechaI'].setValidators(Validators.required)
    this.consolidadosForm.controls['fechaF'].setValidators(Validators.required)
    this.consolidadosForm.controls['fechaI'].updateValueAndValidity()
    this.consolidadosForm.controls['fechaF'].updateValueAndValidity()

    this.mostrarMes = true
    this.consolidadosForm.controls['month'].clearValidators()
    this.consolidadosForm.controls['month'].updateValueAndValidity()

    this.mostrarTrimestre = true
    this.consolidadosForm.controls['trimestre'].clearValidators()
    this.consolidadosForm.controls['trimestre'].updateValueAndValidity()

    this.mostrarYear = true
    this.consolidadosForm.controls['year'].clearValidators()
    this.consolidadosForm.controls['year'].updateValueAndValidity()

    this.mostrarSemestre = true
    this.consolidadosForm.controls['semestre'].clearValidators()
    this.consolidadosForm.controls['semestre'].updateValueAndValidity()
  }
  //Metodo para ocultar los numeros de cuotas
  activarYear(): void {
    this.mostrarYear = false
    this.consolidadosForm.controls['year'].setValidators(Validators.required)
    this.consolidadosForm.controls['year'].updateValueAndValidity()
    
    this.mostrarFechaIF = true
    this.consolidadosForm.controls['fechaI'].clearValidators()
    this.consolidadosForm.controls['fechaF'].clearValidators()
    this.consolidadosForm.controls['fechaI'].updateValueAndValidity()
    this.consolidadosForm.controls['fechaF'].updateValueAndValidity()

    this.mostrarTrimestre = true
    this.consolidadosForm.controls['trimestre'].clearValidators()
    this.consolidadosForm.controls['trimestre'].updateValueAndValidity()

    this.mostrarMes = true
    this.consolidadosForm.controls['month'].clearValidators()
    this.consolidadosForm.controls['month'].updateValueAndValidity()

    this.mostrarSemestre = true
    this.consolidadosForm.controls['semestre'].clearValidators()
    this.consolidadosForm.controls['semestre'].updateValueAndValidity()
  }

  //Metodo para mostrar el numero de trimestre
  activarTrimestre(): void {
    this.mostrarTrimestre = false
    this.consolidadosForm.controls['trimestre'].setValidators(Validators.required)
    this.consolidadosForm.controls['trimestre'].updateValueAndValidity()

    this.mostrarYear = false
    this.consolidadosForm.controls['year'].setValidators(Validators.required)
    this.consolidadosForm.controls['year'].updateValueAndValidity()

    this.mostrarFechaIF = true
    this.consolidadosForm.controls['fechaI'].clearValidators()
    this.consolidadosForm.controls['fechaF'].clearValidators()
    this.consolidadosForm.controls['fechaI'].updateValueAndValidity()
    this.consolidadosForm.controls['fechaF'].updateValueAndValidity()

    this.mostrarMes = true
    this.consolidadosForm.controls['month'].clearValidators()
    this.consolidadosForm.controls['month'].updateValueAndValidity()

    this.mostrarSemestre = true
    this.consolidadosForm.controls['semestre'].clearValidators()
    this.consolidadosForm.controls['semestre'].updateValueAndValidity()
  }

  //Metodo para mostrar el numero de semestre
  activarSemestre(): void {
    this.mostrarSemestre = false
    this.consolidadosForm.controls['semestre'].setValidators(Validators.required)
    this.consolidadosForm.controls['semestre'].updateValueAndValidity()

    this.mostrarYear = false
    this.consolidadosForm.controls['year'].setValidators(Validators.required)
    this.consolidadosForm.controls['year'].updateValueAndValidity()

    this.mostrarFechaIF = true
    this.consolidadosForm.controls['fechaI'].clearValidators()
    this.consolidadosForm.controls['fechaF'].clearValidators()
    this.consolidadosForm.controls['fechaI'].updateValueAndValidity()
    this.consolidadosForm.controls['fechaF'].updateValueAndValidity()

    this.mostrarMes = true
    this.consolidadosForm.controls['month'].clearValidators()
    this.consolidadosForm.controls['month'].updateValueAndValidity()

    this.mostrarTrimestre = true
    this.consolidadosForm.controls['trimestre'].clearValidators()
    this.consolidadosForm.controls['trimestre'].updateValueAndValidity()
  }

  //Metodo para mostrar el numero de mes
  activarMes(): void {
    this.mostrarMes = false
    this.consolidadosForm.controls['month'].setValidators(Validators.required)
    this.consolidadosForm.controls['month'].updateValueAndValidity()

    this.mostrarYear = false
    this.consolidadosForm.controls['year'].setValidators(Validators.required)
    this.consolidadosForm.controls['year'].updateValueAndValidity()

    this.mostrarFechaIF = true
    this.consolidadosForm.controls['fechaI'].clearValidators()
    this.consolidadosForm.controls['fechaF'].clearValidators()
    this.consolidadosForm.controls['fechaI'].updateValueAndValidity()
    this.consolidadosForm.controls['fechaF'].updateValueAndValidity()

    this.mostrarTrimestre = true
    this.consolidadosForm.controls['trimestre'].clearValidators()
    this.consolidadosForm.controls['trimestre'].updateValueAndValidity()

    this.mostrarSemestre = true
    this.consolidadosForm.controls['semestre'].clearValidators()
    this.consolidadosForm.controls['semestre'].updateValueAndValidity()
  }

  onSubmit() {
    switch (this.consolidadosForm.status) {
      case 'VALID':
        this.flag = true;
        this.consolidado.idEmpresa = this.idEmpresa;
        this.consolidado.consolidados = this.consolidadosForm.value.consolidados
        this.consolidado.sucursales = this.consolidadosForm.value.sucursales
        this.consolidado.tramos = this.consolidadosForm.value.tramos
        this.consolidado.trimestre = this.consolidadosForm.value.trimestre
        this.consolidado.semestre = this.consolidadosForm.value.semestre
        this.consolidado.month = this.consolidadosForm.value.month
        this.consolidado.year = this.consolidadosForm.value.year
        this.consolidado.fechaI = this.consolidadosForm.value.fechaI
        this.consolidado.fechaF = this.consolidadosForm.value.fechaF
        if (this.cont != 0) {
          this.sumEgresos = 0;
          this.sumIngresos = 0;
          this.totalConsolidado = 0;
          this.totalIngresoG = [];
          this.totalEgresoG = [];
          this.dataEgresosTable = [];
          this.dataIngresosTable = [];
          this.dataEgresos = [];
          this.dataIngresos = [];
          this.dataSource = new MatTableDataSource();
          this.dataSourceEgresos = new MatTableDataSource();
          this.fechaUnicaE = [];
          this.fechaUnica = [];
          this.uniqueArr = [];
          this.uniqueArr2 = [];
          this.arreglotrimestreE = [];
          this.arreglotrimestre2E = [];
          this.arreglotrimestreFE = [];
          this.arreglotrimestre = [];
          this.arreglotrimestre2 = [];
          this.arreglotrimestreF = [];

          this.arreglosemestre = [];
          this.arreglosemestreF = [];
          this.arreglosemestre2 = [];
          this.arreglosemestreE = [];
          this.arreglosemestre2E = [];
          this.arreglosemestreFE = [];

          this.arregloyear = [];
          this.arregloyearF = [];
          this.arregloyear2 = [];
          this.arregloyearE = [];
          this.arregloyear2E = [];
          this.arregloyearFE = [];

          this.ingresoN = [];
          this.egresoN = [];
          this.resultado = [];
          this.resultadoIE = 0;
          this.todasFecha = [];
          this.fe = '';
          this.montoE = 0;
          this.montoI = 0;
          this.ArrarE = [];
          this.ArrarI = [];
          this.fecha = [];
        }
        if (this.consolidado.consolidados.length > 0) {
          this.hostalService
            .buscarConsolidado(this.consolidado)
            .pipe()
            .subscribe(
              (data: any) => {
                this.dataConsolidados = data.payload                
                //*************OPCION 2 ************/
                if (this.dataConsolidados.valor == 2) {
                  if(this.consolidadosForm.value.tramos == "Trimestral" || this.consolidadosForm.value.tramos == "Mensual")
                  {
                  this.dataIngresos = this.dataConsolidados.valor1
                  this.formularioListo.emit('true')
                  this.consolidadosForm.reset()

                  //llenando la tabla Ingresos
                  this.dataIngresosTable = this.dataIngresos.map((ingreso) => {
                    return ingreso
                  })
                  this.dataSource = new MatTableDataSource(
                    this.dataIngresosTable
                  )
                  this.dataSource.paginator = this.paginator.toArray()[0]
                  this.dataSource.sort = this.tableOneSort

                  // Obteniendo fecha unica en los ingresos                                   
                  for (var i = 0; i < this.dataIngresos.length; i++) {
                    var res2 = this.dataIngresos[i]['fecha'];
                    if (res2.length <= 10) {
                      if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                        res2 = res2.substring(0,10).split("-").reverse().join("-");
                        res2 = res2.substring(0,10).split("/").reverse().join("-");
                      }
                      let num = res2.indexOf("/");
                      if (num != -1) {
                        let res = res2.replace("/","-");
                        res2 = res.replace("/","-");
                      }
                      let date = new Date(res2);
                      let date2 = date.toISOString();
                      let str = date2.replace("00","04");
                      res2 = str;
                    }
                    this.uniqueArr.push(this.dataIngresos[i]['fecha']) 
                    const fechaTrimestre = res2.substring(5, 7); 
                    this.arreglotrimestre.push(fechaTrimestre);                    
                    this.arreglotrimestre2.push({fecha: fechaTrimestre, monto: this.dataIngresos[i]['monto']});

                  }
                  this.fechaUnica = [...new Set(this.uniqueArr)]
                  this.arreglotrimestre = [...new Set(this.arreglotrimestre)]
                  this.fechaUnica.sort()
                  this.arreglotrimestre.sort()

                  if (this.arreglotrimestre.length >= 1) {
                    

                    const monthMes = this.buscarMes(this.arreglotrimestre);                    
                    this.nameMoth = monthMes;  
                    let sumMonto = 0;                    
                    this.arreglotrimestre.map((num) => {
                      sumMonto = 0;
                      this.arreglotrimestre2.filter((num2) => {
                        if(num2.fecha == num){
                          sumMonto = sumMonto + num2.monto;
                        }                      
                      })
                    this.arreglotrimestreF.push(sumMonto)
                    this.ingresoN.push({
                      fecha: num,
                      monto: sumMonto
                    })                    
                    })   
                    this.totalIngresoG.push(this.arreglotrimestreF[0], this.arreglotrimestreF[1], this.arreglotrimestreF[2])
                    this.totalIngresoG.map((monto)=>{  
                      if(monto == undefined)
                      {
                        monto = 0  ;
                          this.sumIngresos = this.sumIngresos + monto;
                      }
                      else
                      { 
                          this.sumIngresos = this.sumIngresos + monto;
                      }
                    }) 
                  } 
                  else {
                    let sum = 0;
                    this.fechaUnica.map((num) => {
                      sum = 0;
                      this.dataIngresos.filter((num2) => {
                        if(num2.fecha == num){
                          sum = sum + num2.monto;
                        }                      
                      })                                        
                      this.totalIngresoG.push(sum)
                      this.ingresoN.push({
                        fecha: num,
                        monto: sum
                      })
                    })                   
                    this.totalIngresoG.map((monto)=>{                    
                      this.sumIngresos = this.sumIngresos + monto;
                    })  
                    for (let i = 0; i < this.fechaUnica.length; i++) {
                      this.fecha.push(this.fechaUnica[i].substring(0,10).split("-").reverse().join("-"));
                    }                
                    }
                    if(this.arreglotrimestre.length >= 1 ){
                      this.mostrarGraficoTI();
                    } 
                    else {
                      this.mostrarGraficoI()                 
                  } 

                  }
                  if(this.consolidadosForm.value.tramos == "Semestral")
                  {
                    
                  this.dataIngresos = this.dataConsolidados.valor1
                  this.formularioListo.emit('true')
                  this.consolidadosForm.reset()
                  

                  //llenando la tabla Ingresos
                  this.dataIngresosTable = this.dataIngresos.map((ingreso) => {
                    return ingreso
                  })
                  this.dataSource = new MatTableDataSource(
                    this.dataIngresosTable
                  )
                  this.dataSource.paginator = this.paginator.toArray()[0]
                  this.dataSource.sort = this.tableOneSort

                  // Obteniendo fecha unica en los ingresos                                   
                  for (var i = 0; i < this.dataIngresos.length; i++) {
                    var res2 = this.dataIngresos[i]['fecha'];
                    if (res2.length <= 10) {
                      if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                        res2 = res2.substring(0,10).split("-").reverse().join("-");
                        res2 = res2.substring(0,10).split("/").reverse().join("-");
                      }
                      let num = res2.indexOf("/");
                      if (num != -1) {
                        let res = res2.replace("/","-");
                        res2 = res.replace("/","-");
                      }
                      let date = new Date(res2);
                      let date2 = date.toISOString();
                      let str = date2.replace("00","04");
                      res2 = str;
                    }
                    this.uniqueArr.push(this.dataIngresos[i]['fecha']) 
                    const fechaSemestre = res2.substring(5, 7);
                    
                    this.arreglosemestre.push(fechaSemestre);                    
                    this.arreglosemestre2.push({fecha: fechaSemestre, monto: this.dataIngresos[i]['monto']});
                  }
                  this.fechaUnica = [...new Set(this.uniqueArr)]
                  this.arreglosemestre = [...new Set(this.arreglosemestre)]
                  this.fechaUnica.sort()
                  this.arreglosemestre.sort()

                  if (this.arreglosemestre.length >= 1) {

                    const monthMes = this.buscarMes(this.arreglosemestre);                    
                    this.nameMoth = monthMes;  
                    let sumMonto = 0;                    
                    this.arreglosemestre.map((num) => {
                      sumMonto = 0;
                      this.arreglosemestre2.filter((num2) => {
                        if(num2.fecha == num){
                          
                          sumMonto = sumMonto + num2.monto;
                        }                      
                      })
                    this.arreglosemestreF.push(sumMonto)
                    this.ingresoN.push({
                      fecha: num,
                      monto: sumMonto
                    })                    
                    })   
                    this.totalIngresoG.push(this.arreglosemestreF[0], this.arreglosemestreF[1], this.arreglosemestreF[2],this.arreglosemestreF[3],this.arreglosemestreF[4],this.arreglosemestreF[5])
                    this.totalIngresoG.map((monto)=>{  
                      if(monto == undefined)
                      {
                        monto = 0  ;
                          this.sumIngresos = this.sumIngresos + monto;
                      }
                      else
                      { 
                          this.sumIngresos = this.sumIngresos + monto;
                      }
                    }) 
                  } 
                  else {
                    let sum = 0;
                    this.fechaUnica.map((num) => {
                      sum = 0;
                      this.dataIngresos.filter((num2) => {
                        if(num2.fecha == num){
                          sum = sum + num2.monto;
                        }                      
                      })                                        
                      this.totalIngresoG.push(sum)
                      this.ingresoN.push({
                        fecha: num,
                        monto: sum
                      })
                    })                   
                    this.totalIngresoG.map((monto)=>{                    
                      this.sumIngresos = this.sumIngresos + monto;
                    })  
                    for (let i = 0; i < this.fechaUnica.length; i++) {
                      this.fecha.push(this.fechaUnica[i].substring(0,10).split("-").reverse().join("-"));
                    }                
                    }
                    if(this.arreglosemestre.length >= 1){
                      this.mostrarGraficoTI();
                    }
                    else {
                      this.mostrarGraficoI()                 
                    } 
                  }
                  if(this.consolidadosForm.value.tramos == "Year")
                  {
                  
                  this.dataIngresos = this.dataConsolidados.valor1
                  this.formularioListo.emit('true')
                  this.consolidadosForm.reset()

                  //llenando la tabla Ingresos
                  this.dataIngresosTable = this.dataIngresos.map((ingreso) => {
                    return ingreso
                  })
                  this.dataSource = new MatTableDataSource(
                    this.dataIngresosTable
                  )
                  this.dataSource.paginator = this.paginator.toArray()[0]
                  this.dataSource.sort = this.tableOneSort

                  // Obteniendo fecha unica en los ingresos                                   
                  for (var i = 0; i < this.dataIngresos.length; i++) {
                    var res2 = this.dataIngresos[i]['fecha'];
                    if (res2.length <= 10) {
                      if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                        res2 = res2.substring(0,10).split("-").reverse().join("-");
                        res2 = res2.substring(0,10).split("/").reverse().join("-");
                      }
                      let num = res2.indexOf("/");
                      if (num != -1) {
                        let res = res2.replace("/","-");
                        res2 = res.replace("/","-");
                      }
                      let date = new Date(res2);
                      let date2 = date.toISOString();
                      let str = date2.replace("00","04");
                      res2 = str;
                    }
                    this.uniqueArr.push(this.dataIngresos[i]['fecha']) 
                    const fechaYear = res2.substring(5, 7); 
                    this.arregloyear.push(fechaYear);                    
                    this.arregloyear2.push({fecha: fechaYear, monto: this.dataIngresos[i]['monto']});
                  }
                  
                  this.fechaUnica = [...new Set(this.uniqueArr)]
                  this.arregloyear = [...new Set(this.arregloyear)]
                  this.fechaUnica.sort()
                  this.arregloyear.sort()
                 
                  
                  if (this.arregloyear.length >= 1) {
                    
                    const monthMes = this.buscarMes(this.arregloyear);                    
                    this.nameMoth = monthMes;  
                    let sumMonto = 0;                    
                    this.arregloyear.map((num) => {
                      sumMonto = 0;
                      this.arregloyear2.filter((num2) => {
                        if(num2.fecha == num){
                          
                          sumMonto = sumMonto + num2.monto;
                          
                        }                      
                      })
                    this.arregloyearF.push(sumMonto)
                    this.ingresoN.push({
                      fecha: num,
                      monto: sumMonto
                    })                    
                    })  
                    
                    this.totalIngresoG.push(this.arregloyearF[0], this.arregloyearF[1], 
                      this.arregloyearF[2],this.arregloyearF[3],this.arregloyearF[4],
                      this.arregloyearF[5],this.arregloyearF[6],this.arregloyearF[7],
                      this.arregloyearF[8],this.arregloyearF[9],this.arregloyearF[10],this.arregloyearF[11])

                        this.totalIngresoG.map((monto)=>{  
                          if(monto == undefined)
                          {
                            monto = 0  ;
                              this.sumIngresos = this.sumIngresos + monto;
                          }
                          else
                          { 
                              this.sumIngresos = this.sumIngresos + monto;
                          }
                        }) 
                  } 
                  else {
                    let sum = 0;
                    this.fechaUnica.map((num) => {
                      sum = 0;
                      this.dataIngresos.filter((num2) => {
                        if(num2.fecha == num){
                          sum = sum + num2.monto;
                        }                      
                      })                                        
                      this.totalIngresoG.push(sum)
                      this.ingresoN.push({
                        fecha: num,
                        monto: sum
                      })
                    })                   
                    this.totalIngresoG.map((monto)=>{                    
                      this.sumIngresos = this.sumIngresos + monto;
                    })  
                    for (let i = 0; i < this.fechaUnica.length; i++) {
                      this.fecha.push(this.fechaUnica[i].substring(0,10).split("-").reverse().join("-"));
                    }                
                    }
                    if(this.arregloyear.length >= 1){
                      this.mostrarGraficoTI();
                    }
                    else {
                      this.mostrarGraficoI();                 
                    }

                  }
                }
                //*************OPCION 3 ************/
                if (this.dataConsolidados.valor == 3) {
                  if(this.consolidadosForm.value.tramos == "Trimestral" || this.consolidadosForm.value.tramos == "Mensual")
                  {
                  this.dataEgresos = this.dataConsolidados.valor2
                  this.formularioListo.emit('true')
                  this.consolidadosForm.reset()

                  //llenando la tabla Egresos
                  this.dataEgresosTable = this.dataEgresos.map((egreso) => {
                    return egreso
                  })                  
                  this.dataSourceEgresos = new MatTableDataSource(
                    this.dataEgresosTable
                  )                  
                  this.dataSourceEgresos.paginator = this.paginator2.toArray()[0]
                  this.dataSourceEgresos.sort = this.tableTwoSort
                  
                  // Obteniendo fecha unica en los egresos
                  for (var i = 0; i < this.dataEgresos.length; i++) {
                    var res2 = this.dataEgresos[i]['fecha'];
                    if (res2.length <= 10) {
                      if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                        res2 = res2.substring(0,10).split("-").reverse().join("-");
                        res2 = res2.substring(0,10).split("/").reverse().join("-");
                      }
                      let num = res2.indexOf("/");
                      if (num != -1) {
                        let res = res2.replace("/","-");
                        res2 = res.replace("/","-");
                      }
                      let date = new Date(res2);
                      let date2 = date.toISOString();
                      let str = date2.replace("00","04");
                      res2 = str;
                    }
                    this.uniqueArr2.push(this.dataEgresos[i]['fecha'])
                    const fechaTrimestre = res2.substring(5, 7);                     
                    this.arreglotrimestreE.push(fechaTrimestre);
                    this.arreglotrimestre2E.push({fecha: fechaTrimestre, monto: this.dataEgresos[i]['monto']});
                  }  
                  this.fechaUnicaE = [...new Set(this.uniqueArr2)]
                  this.arreglotrimestreE = [...new Set(this.arreglotrimestreE)]
                  this.fechaUnicaE.sort()
                  this.arreglotrimestreE.sort()

                  if(this.arreglotrimestreE.length >= 1){

                    const monthMes = this.buscarMes(this.arreglotrimestreE);                    
                    this.nameMoth = monthMes;
                    let sumMonto = 0;                    
                      this.arreglotrimestreE.map((num) => {
                        sumMonto = 0;
                        this.arreglotrimestre2E.filter((num2) => {
                          if(num2.fecha == num){
                            sumMonto = sumMonto + num2.monto;
                          }                      
                        })
                      this.arreglotrimestreFE.push(sumMonto)
                      this.egresoN.push({
                        fecha: num,
                        monto: sumMonto
                      })                    
                      })   
                      this.totalEgresoG.push(this.arreglotrimestreFE[0], this.arreglotrimestreFE[1], this.arreglotrimestreFE[2])
                      this.totalEgresoG.map((monto)=>{  
                        if(monto == undefined)
                        {
                          monto = 0  ;
                            this.sumEgresos = this.sumEgresos + monto;
                        }
                        else
                        { 
                            this.sumEgresos = this.sumEgresos + monto;
                        }
                      })                       
                    }else {
                    //Se calcula el mes egreso
                  let sumE = 0;
                  this.fechaUnicaE.map((num) => {
                    sumE = 0;
                    this.dataEgresos.filter((num2) => {
                      if(num2.fecha == num){
                        sumE = sumE + num2.monto;
                      }                      
                    })                                        
                    this.totalEgresoG.push(sumE)
                    this.egresoN.push({
                      fecha: num,
                      monto: sumE
                    })
                  })
                  this.totalEgresoG.map((monto)=>{
                    this.sumEgresos = this.sumEgresos + monto;
                  }) 
                  for (let i = 0; i < this.fechaUnicaE.length; i++) {
                    this.fecha.push(this.fechaUnicaE[i].substring(0,10).split("-").reverse().join("-"));
                  }                                                   
                }
                  if(this.arreglotrimestreE.length >= 1){
                    this.mostrarGraficoTE();
                  }
                  else {
                    this.mostrarGraficoE();                  
                  } 
                  }
                  if(this.consolidadosForm.value.tramos == "Semestral")
                  {
                  this.dataEgresos = this.dataConsolidados.valor2
                  this.formularioListo.emit('true')
                  this.consolidadosForm.reset()

                  //llenando la tabla Egresos
                  this.dataEgresosTable = this.dataEgresos.map((egreso) => {
                    return egreso
                  })
                  this.dataSourceEgresos = new MatTableDataSource(
                    this.dataEgresosTable
                  )
                  this.dataSourceEgresos.paginator = this.paginator2.toArray()[0]
                  this.dataSourceEgresos.sort = this.tableTwoSort

                  // Obteniendo fecha unica en los egresos                                   
                  for (var i = 0; i < this.dataEgresos.length; i++) {
                    var res2 = this.dataEgresos[i]['fecha'];
                    if (res2.length <= 10) {
                      if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                        res2 = res2.substring(0,10).split("-").reverse().join("-");
                        res2 = res2.substring(0,10).split("/").reverse().join("-");
                      }
                      let num = res2.indexOf("/");
                      if (num != -1) {
                        let res = res2.replace("/","-");
                        res2 = res.replace("/","-");
                      }
                      let date = new Date(res2);
                      let date2 = date.toISOString();
                      let str = date2.replace("00","04");
                      res2 = str;
                    }
                    this.uniqueArr.push(this.dataEgresos[i]['fecha']) 
                    const fechaSemestre = res2.substring(5, 7);
                    
                    this.arreglosemestreE.push(fechaSemestre);                    
                    this.arreglosemestre2E.push({fecha: fechaSemestre, monto: this.dataEgresos[i]['monto']});
                  }
                  this.fechaUnica = [...new Set(this.uniqueArr)]
                  this.arreglosemestreE = [...new Set(this.arreglosemestreE)]
                  this.fechaUnica.sort()
                  this.arreglosemestreE.sort()

                  if (this.arreglosemestreE.length >= 1) {

                    const monthMes = this.buscarMes(this.arreglosemestreE);                    
                    this.nameMoth = monthMes;  
                    let sumMonto = 0;                    
                    this.arreglosemestreE.map((num) => {
                      sumMonto = 0;
                      this.arreglosemestre2E.filter((num2) => {
                        if(num2.fecha == num){
                          
                          sumMonto = sumMonto + num2.monto;
                        }                      
                      })
                    this.arreglosemestreFE.push(sumMonto)
                    this.egresoN.push({
                      fecha: num,
                      monto: sumMonto
                    })                    
                    })   
                    this.totalEgresoG.push(this.arreglosemestreFE[0], this.arreglosemestreFE[1], this.arreglosemestreFE[2],this.arreglosemestreFE[3],this.arreglosemestreFE[4],this.arreglosemestreFE[5])
                    this.totalEgresoG.map((monto)=>{  
                      if(monto == undefined)
                      {
                        monto = 0  ;
                          this.sumEgresos = this.sumEgresos + monto;
                      }
                      else
                      { 
                          this.sumEgresos = this.sumEgresos + monto;
                      }
                    }) 
                  } 
                  else {
                    let sum = 0;
                    this.fechaUnica.map((num) => {
                      sum = 0;
                      this.dataEgresos.filter((num2) => {
                        if(num2.fecha == num){
                          sum = sum + num2.monto;
                        }                      
                      })                                        
                      this.totalEgresoG.push(sum)
                      this.egresoN.push({
                        fecha: num,
                        monto: sum
                      })
                    })                   
                    this.totalEgresoG.map((monto)=>{                    
                      this.sumEgresos = this.sumEgresos + monto;
                    })  
                    for (let i = 0; i < this.fechaUnica.length; i++) {
                      this.fecha.push(this.fechaUnica[i].substring(0,10).split("-").reverse().join("-"));
                    }                
                    }
                    if(this.arreglosemestreE.length >= 1){
                      this.mostrarGraficoTE();
                    }
                    else {
                      this.mostrarGraficoE();                 
                    } 
                  }
                  if(this.consolidadosForm.value.tramos == "Year")
                  {
                    
                  this.dataEgresos = this.dataConsolidados.valor2
                  this.formularioListo.emit('true')
                  this.consolidadosForm.reset()

                  //llenando la tabla Egresos
                  this.dataEgresosTable = this.dataEgresos.map((egreso) => {
                    return egreso
                  })
                  this.dataSourceEgresos = new MatTableDataSource(
                    this.dataEgresosTable
                  )
                  this.dataSourceEgresos.paginator = this.paginator2.toArray()[0]
                  this.dataSourceEgresos.sort = this.tableTwoSort

                  // Obteniendo fecha unica en los egresos                                   
                  for (var i = 0; i < this.dataEgresos.length; i++) {
                    var res2 = this.dataEgresos[i]['fecha'];
                    if (res2.length <= 10) {
                      if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                        res2 = res2.substring(0,10).split("-").reverse().join("-");
                        res2 = res2.substring(0,10).split("/").reverse().join("-");
                      }
                      let num = res2.indexOf("/");
                      if (num != -1) {
                        let res = res2.replace("/","-");
                        res2 = res.replace("/","-");
                      }
                      let date = new Date(res2);
                      let date2 = date.toISOString();
                      let str = date2.replace("00","04");
                      res2 = str;
                    }
                    this.uniqueArr.push(this.dataEgresos[i]['fecha']) 
                    const fechaYear = res2.substring(5, 7); 
                    this.arregloyearE.push(fechaYear);                    
                    this.arregloyear2E.push({fecha: fechaYear, monto: this.dataEgresos[i]['monto']});
                  }
                  
                  this.fechaUnica = [...new Set(this.uniqueArr)]
                  this.arregloyearE = [...new Set(this.arregloyearE)]
                  this.fechaUnica.sort()
                  this.arregloyearE.sort()
                 
                  if (this.arregloyearE.length >= 1) {
                    const monthMes = this.buscarMes(this.arregloyearE);                    
                    this.nameMoth = monthMes;  
                    let sumMonto = 0;                    
                    this.arregloyearE.map((num) => {
                      sumMonto = 0;
                      this.arregloyear2E.filter((num2) => {
                        if(num2.fecha == num){
                          
                          sumMonto = sumMonto + num2.monto;
                          
                        }                      
                      })
                    this.arregloyearFE.push(sumMonto)
                    this.egresoN.push({
                      fecha: num,
                      monto: sumMonto
                    })                    
                    })  
                    this.totalEgresoG.push(this.arregloyearFE[0], this.arregloyearFE[1], 
                      this.arregloyearFE[2],this.arregloyearFE[3],this.arregloyearFE[4],
                      this.arregloyearFE[5],this.arregloyearFE[6],this.arregloyearFE[7],
                      this.arregloyearFE[8],this.arregloyearFE[9],this.arregloyearFE[10],this.arregloyearFE[11])

                        this.totalEgresoG.map((monto)=>{  
                          if(monto == undefined)
                          {
                            monto = 0  ;
                              this.sumEgresos = this.sumEgresos + monto;
                          }
                          else
                          { 
                              this.sumEgresos = this.sumEgresos + monto;
                          }
                        }) 
                  } 
                  else {
                    let sum = 0;
                    this.fechaUnica.map((num) => {
                      sum = 0;
                      this.dataEgresos.filter((num2) => {
                        if(num2.fecha == num){
                          sum = sum + num2.monto;
                        }                      
                      })                                        
                      this.totalEgresoG.push(sum)
                      this.egresoN.push({
                        fecha: num,
                        monto: sum
                      })
                    })                   
                    this.totalEgresoG.map((monto)=>{                    
                      this.sumEgresos = this.sumEgresos + monto;
                    })  
                    for (let i = 0; i < this.fechaUnica.length; i++) {
                      this.fecha.push(this.fechaUnica[i].substring(0,10).split("-").reverse().join("-"));
                    }                
                    }
                    if(this.arregloyearE.length >= 1){
                      this.mostrarGraficoTE();
                    }
                    else {
                      this.mostrarGraficoE()                 
                    }

                  }
                }
                //*************OPCION 4 ************/ el resultado
                if (this.dataConsolidados.valor == 4) {
                  if(this.consolidadosForm.value.tramos == "Trimestral" || this.consolidadosForm.value.tramos == "Mensual" ){
                    
                    this.dataIngresos = this.dataConsolidados.valor1
                    this.dataEgresos = this.dataConsolidados.valor2
                    this.formularioListo.emit('true')
                    this.consolidadosForm.reset()
  
                    //llenando la tabla Ingresos
                    this.dataIngresosTable = this.dataIngresos.map((ingreso) => {
                      return ingreso
                    })
                    this.dataSource = new MatTableDataSource(this.dataIngresosTable)
                    this.dataSource.paginator = this.paginator.toArray()[0]
                    this.dataSource.sort = this.tableOneSort
  
                    //llenando la tabla Egresos
                    this.dataEgresosTable = this.dataEgresos.map((egreso) => {
                      return egreso
                    })
                    this.dataSourceEgresos = new MatTableDataSource(
                      this.dataEgresosTable
                    )
                    this.dataSourceEgresos.paginator = this.paginator.toArray()[0]
                    this.dataSourceEgresos.sort = this.tableTwoSort
                                    
                    
                    //*********************INGRESOS********************* 
                    // Obteniendo fecha unica en los ingresos                                   
                    for (var i = 0; i < this.dataIngresos.length; i++) {
                      var res2 = this.dataIngresos[i]['fecha'];
                      if (res2.length <= 10) {
                        if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                          res2 = res2.substring(0,10).split("-").reverse().join("-");
                          res2 = res2.substring(0,10).split("/").reverse().join("-");
                        }
                        let num = res2.indexOf("/");
                        if (num != -1) {
                          let res = res2.replace("/","-");
                          res2 = res.replace("/","-");
                        }
                        let date = new Date(res2);
                        let date2 = date.toISOString();
                        let str = date2.replace("00","04");
                        res2 = str;
                      }
                      this.uniqueArr.push(this.dataIngresos[i]['fecha'])
                      this.todasFecha.push(this.dataIngresos[i]['fecha'])
                      const fechaTrimestre = res2.substring(5, 7);                     
                      this.arreglotrimestre.push(fechaTrimestre);                    
                      this.arreglotrimestre2.push({fecha: fechaTrimestre, monto: this.dataIngresos[i]['monto']});
                    }                  
                    this.fechaUnica = [...new Set(this.uniqueArr)]
                    this.arreglotrimestre = [...new Set(this.arreglotrimestre)]
                    this.fechaUnica.sort()
                    this.arreglotrimestre.sort()                                 
                    // si el arreglo tienes 3 meses de los ingresos
                    if(this.arreglotrimestre.length >= 0 || this.arreglotrimestreE.length >= 0){
                      const monthMes = this.buscarMes(this.arreglotrimestre);
                      let sumMonto = 0; 
                      this.arreglotrimestre = []
                      if(this.consolidado.month == "January"){
                        this.arreglotrimestre.push("01");
                        this.nameMoth = ["Enero"]; 
                      }
                      if(this.consolidado.month == "February"){
                        this.arreglotrimestre.push("02");
                        this.nameMoth = ["Febrero"]; 
                      }
                      if(this.consolidado.month == "March"){
                        this.arreglotrimestre.push("03");
                        this.nameMoth = ["Marzo"]; 
                      }
                      if(this.consolidado.month == "April"){
                        this.arreglotrimestre.push("04");
                        this.nameMoth = ["Abril"]; 
                      }
                      if(this.consolidado.month == "May"){
                        this.arreglotrimestre.push("05");
                        this.nameMoth = ["Mayo"]; 
                      }
                      if(this.consolidado.month == "June"){
                        this.arreglotrimestre.push("06");
                        this.nameMoth = ["Junio"]; 
                      }
                      if(this.consolidado.month == "July"){
                        this.arreglotrimestre.push("07");
                        this.nameMoth = ["Julio"]; 
                      }
                      if(this.consolidado.month == "August"){
                        this.arreglotrimestre.push("08");
                        this.nameMoth = ["Agosto"]; 
                      }
                      if(this.consolidado.month == "September"){
                        this.arreglotrimestre.push("09");
                        this.nameMoth = ["Septiembre"]; 
                      }
                      if(this.consolidado.month == "October"){
                        this.arreglotrimestre.push("10");
                        this.nameMoth = ["Octubre"]; 
                      }
                      if(this.consolidado.month == "November"){
                        this.arreglotrimestre.push("11");
                        this.nameMoth = ["Noviembre"]; 
                      }
                      if(this.consolidado.month == "December"){
                        this.arreglotrimestre.push("12");
                        this.nameMoth = ["Diciembre"]; 
                      }
  
                      if(this.consolidado.trimestre == "1"){
                        this.arreglotrimestre.push("01","02","03");
                        this.nameMoth = ["Enero","Febrero","Marzo"]; 
                      }
                      if(this.consolidado.trimestre == "2"){
                        this.arreglotrimestre.push("04","05","06"); 
                        this.nameMoth = ["Abril","Mayo","Junio"];
                      }
                      if(this.consolidado.trimestre == "3"){
                        this.arreglotrimestre.push("07","08","09");
                        this.nameMoth = ["Julio","Agosto","Septiembre"]; 
                      }
                      if(this.consolidado.trimestre == "4"){
                        this.arreglotrimestre.push("10","11","12"); 
                        this.nameMoth = ["Octubre","Noviembre","Diciembre"];
                      }
                            
                      this.arreglotrimestre.sort();
                      this.arreglotrimestre.map((num) => {
                        sumMonto = 0;
                        this.arreglotrimestre2.filter((num2) => {
                          if(num2.fecha == num){
                            sumMonto = sumMonto + num2.monto;
                          }                      
                        })
                      this.arreglotrimestreF.push(sumMonto)
                      this.ingresoN.push({
                        fecha: num,
                        monto: sumMonto
                      })                    
                      })
                      
                      this.totalIngresoG.push(this.arreglotrimestreF[0], this.arreglotrimestreF[1], this.arreglotrimestreF[2])
                      
                      for(var i = 0; i < this.totalIngresoG.length; i++)
                      {
                        if(this.totalIngresoG[i] == undefined)
                         {
                          this.totalIngresoG[i] = 0;
                         }
                      }
                      this.totalIngresoG.map((monto)=>{  
                          this.sumIngresos = this.sumIngresos + monto;
                      })
                      
                      
  
                    } else {                  
                      //se calcula el mes del ingreso
                    let sum = 0;
                    this.fechaUnica.map((num) => {
                      sum = 0;
                      this.dataIngresos.filter((num2) => {
                        if(num2.fecha == num){
                          sum = sum + num2.monto;
                        }                      
                      })                                       
                      this.totalIngresoG.push(sum)
                      this.ingresoN.push({
                        fecha: num,
                        monto: sum
                      })
                    })                    
                    this.totalIngresoG.map((monto)=>{                    
                      this.sumIngresos = this.sumIngresos + monto;
                    })                  
                  }
                    //*********************EGRESOS*********************
                    // Obteniendo fecha unica en los egresos     
                    for (var i = 0; i < this.dataEgresos.length; i++) {
                      var res2 = this.dataEgresos[i]['fecha'];
                      if (res2.length <= 10) {
                        if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                          res2 = res2.substring(0,10).split("-").reverse().join("-");
                          res2 = res2.substring(0,10).split("/").reverse().join("-");
                        }
                        let num = res2.indexOf("/");
                        if (num != -1) {
                          let res = res2.replace("/","-");
                          res2 = res.replace("/","-");
                        }
                        let date = new Date(res2);
                        let date2 = date.toISOString();
                        let str = date2.replace("00","04");
                        res2 = str;
                      }
                      this.uniqueArr2.push(this.dataEgresos[i]['fecha']) 
                      const fechaTrimestre = res2.substring(5, 7);                     
                      this.arreglotrimestreE.push(fechaTrimestre);
                      this.arreglotrimestre2E.push({fecha: fechaTrimestre, monto: this.dataEgresos[i]['monto']});
                    }  
                    this.fechaUnicaE = [...new Set(this.uniqueArr2)]
                    this.arreglotrimestreE = [...new Set(this.arreglotrimestreE)]
                    this.fechaUnicaE.sort()
                    this.arreglotrimestreE.sort()
                    // si el arreglo tienes 3 meses de los egresos
                    if(this.arreglotrimestreE.length  >= 0  ){
                      const monthMes = this.buscarMes(this.arreglotrimestreE);
                      let sumMonto = 0;    
                      this.arreglotrimestreE = []
  
                      if(this.consolidado.month == "January"){
                        this.arreglotrimestreE.push("01");
                        this.nameMoth = ["Enero"]; 
                      }
                      if(this.consolidado.month == "February"){
                        this.arreglotrimestreE.push("02");
                        this.nameMoth = ["Febrero"]; 
                      }
                      if(this.consolidado.month == "March"){
                        this.arreglotrimestreE.push("03");
                        this.nameMoth = ["Marzo"]; 
                      }
                      if(this.consolidado.month == "April"){
                        this.arreglotrimestreE.push("04");
                        this.nameMoth = ["Abril"]; 
                      }
                      if(this.consolidado.month == "May"){
                        this.arreglotrimestreE.push("05");
                        this.nameMoth = ["Mayo"]; 
                      }
                      if(this.consolidado.month == "June"){
                        this.arreglotrimestreE.push("06");
                        this.nameMoth = ["Junio"]; 
                      }
                      if(this.consolidado.month == "July"){
                        this.arreglotrimestreE.push("07");
                        this.nameMoth = ["Julio"]; 
                      }
                      if(this.consolidado.month == "August"){
                        this.arreglotrimestreE.push("08");
                        this.nameMoth = ["Agosto"]; 
                      }
                      if(this.consolidado.month == "September"){
                        this.arreglotrimestreE.push("09");
                        this.nameMoth = ["Septiembre"]; 
                      }
                      if(this.consolidado.month == "October"){
                        this.arreglotrimestreE.push("10");
                        this.nameMoth = ["Octubre"]; 
                      }
                      if(this.consolidado.month == "November"){
                        this.arreglotrimestreE.push("11");
                        this.nameMoth = ["Noviembre"]; 
                      }
                      if(this.consolidado.month == "December"){
                        this.arreglotrimestreE.push("12");
                        this.nameMoth = ["Diciembre"]; 
                      }
  
                      if(this.consolidado.trimestre == "1"){
                        this.arreglotrimestreE.push("01","02","03");
                        this.nameMoth = ["Enero","Febrero","Marzo"]; 
                      }
                      if(this.consolidado.trimestre == "2"){
                        this.arreglotrimestreE.push("04","05","06"); 
                        this.nameMoth = ["Abril","Mayo","Junio"];
                      }
                      if(this.consolidado.trimestre == "3"){
                        this.arreglotrimestreE.push("07","08","09");
                        this.nameMoth = ["Julio","Agosto","Septiembre"]; 
                      }
                      if(this.consolidado.trimestre == "4"){
                        this.arreglotrimestreE.push("10","11","12"); 
                        this.nameMoth = ["Octubre","Noviembre","Diciembre"];
                      }
                        this.arreglotrimestreE.map((num) => {
                          sumMonto = 0;
                          this.arreglotrimestre2E.filter((num2) => {
                            
                            if(num2.fecha == num){
                              sumMonto = sumMonto + num2.monto;
                            }                      
                          })
                        this.arreglotrimestreFE.push(sumMonto)
                        this.egresoN.push({
                          
                          fecha: num,
                          monto: sumMonto
                        })                    
                        })   
                        this.totalEgresoG.push(this.arreglotrimestreFE[0], this.arreglotrimestreFE[1], this.arreglotrimestreFE[2])
                        
                        for(var i = 0; i < this.totalEgresoG.length; i++)
                        {
                          if(this.totalEgresoG[i] == undefined)
                          {
                            this.totalEgresoG[i] = 0;
                          }
                        }
                        
                        
                        this.totalEgresoG.map((monto)=>{  
                              this.sumEgresos = this.sumEgresos + monto;
                        })
                        
                        //****************** INGRESOS - EGRESOS********************                  
                        for (var i = 0; i < this.ingresoN.length; i++) {
                          for (var f = 0; f < this.egresoN.length; f++) {
                            if (this.ingresoN[i].fecha == this.egresoN[f].fecha) {
                              
                              this.resultadoIE = this.ingresoN[i].monto - this.egresoN[f].monto
                              this.resultado.push(this.resultadoIE);
                            }
                          }
                        }                        
                      }else {
                      //Se calcula el mes egreso
                    let sumE = 0;
                    this.fechaUnicaE.map((num) => {
                      sumE = 0;
                      this.dataEgresos.filter((num2) => {
                        if(num2.fecha == num){
                          sumE = sumE + num2.monto;
                        }                      
                      })
                      this.todasFecha.push(num)                                       
                      this.totalEgresoG.push(sumE)
                      this.egresoN.push({
                        fecha: num,
                        monto: sumE
                      })
                    })
                    this.totalEgresoG.map((monto)=>{
                      this.sumEgresos = this.sumEgresos + monto;
                    })
                    this.todasFecha.sort();
                    this.todasFecha = this.todasFecha.filter((element, i) => i === this.todasFecha.indexOf(element))
                    //****************** INGRESOS - EGRESOS********************                  
                    for (var y = 0; y < this.todasFecha.length; y++) {
                      for (var i = 0; i < this.ingresoN.length; i++) {
                        if (this.todasFecha[y] == this.ingresoN[i].fecha) {
                          this.montoI = this.ingresoN[i].monto;
                          break;
                        }
                      }
                      for (var f = 0; f < this.egresoN.length; f++) {
                        if (this.todasFecha[y] == this.egresoN[f].fecha) {
                          this.montoE = this.egresoN[f].monto;
                          break;
                        }
                      }
                      this.ArrarI.push(this.montoI);
                      this.ArrarE.push(this.montoE);
                      this.resultadoIE = this.montoI - this.montoE;
                      this.resultado.push(this.resultadoIE);
                      this.resultadoIE = 0;
                      this.montoI = 0;
                      this.montoE = 0;
                      this.fecha.push(this.todasFecha[y].substring(0,10).split("-").reverse().join("-"));
                    }
                  }
                  this.totalConsolidado = this.sumIngresos - this.sumEgresos
                  if(this.arreglotrimestreE.length >= 1 || this.arreglotrimestre.length >= 1){
                    this.mostrarGraficoT();
                  } else {
                    this.mostrarGraficoIE();                  
                  }
                    }
                    if(this.consolidadosForm.value.tramos == "Semestral"){
                      
                      this.dataIngresos = this.dataConsolidados.valor1
                      this.dataEgresos = this.dataConsolidados.valor2
                      this.formularioListo.emit('true')
                      this.consolidadosForm.reset()
  
                      //llenando la tabla Ingresos
                      this.dataIngresosTable = this.dataIngresos.map((ingreso) => {
                        return ingreso
                      })
                      this.dataSource = new MatTableDataSource(this.dataIngresosTable)
                      this.dataSource.paginator = this.paginator.toArray()[0]
                      this.dataSource.sort = this.tableOneSort
  
                      //llenando la tabla Egresos
                      this.dataEgresosTable = this.dataEgresos.map((egreso) => {
                        return egreso
                      })
                      this.dataSourceEgresos = new MatTableDataSource(this.dataEgresosTable)
                      this.dataSourceEgresos.paginator = this.paginator.toArray()[0]
                      this.dataSourceEgresos.sort = this.tableTwoSort
                                      
                      
                      //*********************INGRESOS********************* 
                      // Obteniendo fecha unica en los ingresos                                   
                      for (var i = 0; i < this.dataIngresos.length; i++) {
                        var res2 = this.dataIngresos[i]['fecha'];
                        if (res2.length <= 10) {
                          if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                            res2 = res2.substring(0,10).split("-").reverse().join("-");
                            res2 = res2.substring(0,10).split("/").reverse().join("-");
                          }
                          let num = res2.indexOf("/");
                          if (num != -1) {
                            let res = res2.replace("/","-");
                            res2 = res.replace("/","-");
                          }
                          let date = new Date(res2);
                          let date2 = date.toISOString();
                          let str = date2.replace("00","04");
                          res2 = str;
                        }
                        this.uniqueArr.push(this.dataIngresos[i]['fecha'])
                        this.todasFecha.push(this.dataIngresos[i]['fecha'])
                        const fechaSemestre = res2.substring(5, 7);                     
                        this.arreglosemestre.push(fechaSemestre);                    
                        this.arreglosemestre2.push({fecha: fechaSemestre, monto: this.dataIngresos[i]['monto']});
                      }                  
                      this.fechaUnica = [...new Set(this.uniqueArr)]
                      this.arreglosemestre = [...new Set(this.arreglosemestre)]
                      this.fechaUnica.sort()
                      this.arreglosemestre.sort()                                 
                      // si el arreglo tienes 3 meses de los ingresos
                      if(this.arreglosemestre.length >= 0 || this.arreglosemestreE.length >= 0){
                        const monthMes = this.buscarMes(this.arreglosemestre);  
                        let sumMonto = 0; 
                        this.arreglosemestre = []
  
                        if(this.consolidado.semestre == "1"){
                          this.arreglosemestre.push("01","02","03","04","05","06"); 
                          this.nameMoth = ["Enero","Febrero","Marzo","Abril","Mayo","Junio"]; 
                        }
                        if(this.consolidado.semestre == "2"){
                          this.arreglosemestre.push("07","08","09","10","11","12"); 
                          this.nameMoth = ["Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
                        }
                              
                        this.arreglosemestre.sort();
                        this.arreglosemestre.map((num) => {
                          sumMonto = 0;
                          this.arreglosemestre2.filter((num2) => {
                            if(num2.fecha == num){
                              sumMonto = sumMonto + num2.monto;
                            }                      
                          })
                        this.arreglosemestreF.push(sumMonto)
                        this.ingresoN.push({
                          fecha: num,
                          monto: sumMonto
                        })                    
                        })
                        
                        this.totalIngresoG.push(this.arreglosemestreF[0], this.arreglosemestreF[1], this.arreglosemestreF[2], this.arreglosemestreF[3], this.arreglosemestreF[4], this.arreglosemestreF[5])
                        
                        for(var i = 0; i < this.totalIngresoG.length; i++)
                        {
                          if(this.totalIngresoG[i] == undefined)
                           {
                            this.totalIngresoG[i] = 0;
                           }
                        }
                        this.totalIngresoG.map((monto)=>{  
                            this.sumIngresos = this.sumIngresos + monto;
                        })
                        
                        
  
                      } else {                  
                        //se calcula el mes del ingreso
                      let sum = 0;
                      this.fechaUnica.map((num) => {
                        sum = 0;
                        this.dataIngresos.filter((num2) => {
                          if(num2.fecha == num){
                            sum = sum + num2.monto;
                          }                      
                        })                                       
                        this.totalIngresoG.push(sum)
                        this.ingresoN.push({
                          fecha: num,
                          monto: sum
                        })
                      })                    
                      this.totalIngresoG.map((monto)=>{                    
                        this.sumIngresos = this.sumIngresos + monto;
                      })                  
                    }
                      //*********************EGRESOS*********************
                      // Obteniendo fecha unica en los egresos     
                      for (var i = 0; i < this.dataEgresos.length; i++) {
                        var res2 = this.dataEgresos[i]['fecha'];
                        if (res2.length <= 10) {
                          if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                            res2 = res2.substring(0,10).split("-").reverse().join("-");
                            res2 = res2.substring(0,10).split("/").reverse().join("-");
                          }
                          let num = res2.indexOf("/");
                          if (num != -1) {
                            let res = res2.replace("/","-");
                            res2 = res.replace("/","-");
                          }
                          let date = new Date(res2);
                          let date2 = date.toISOString();
                          let str = date2.replace("00","04");
                          res2 = str;
                        }
                        this.uniqueArr2.push(this.dataEgresos[i]['fecha']) 
                        const fechaSemestre = res2.substring(5, 7);                     
                        this.arreglosemestreE.push(fechaSemestre);
                        this.arreglosemestre2E.push({fecha: fechaSemestre, monto: this.dataEgresos[i]['monto']});
                      }  
                      this.fechaUnicaE = [...new Set(this.uniqueArr2)]
                      this.arreglosemestreE = [...new Set(this.arreglosemestreE)]
                      this.fechaUnicaE.sort()
                      this.arreglosemestreE.sort()
                      // si el arreglo tienes 3 meses de los egresos
                      if(this.arreglosemestreE.length  >= 0  ){
                        
                        const monthMes = this.buscarMes(this.arreglosemestreE);
                        let sumMonto = 0;    
                        this.arreglosemestreE = []
  
                        if(this.consolidado.semestre == "1"){
                          this.arreglosemestreE.push("01","02","03","04","05","06"); 
                          this.nameMoth = ["Enero","Febrero","Marzo","Abril","Mayo","Junio"]; 
                        }
                        if(this.consolidado.semestre == "2"){
                          this.arreglosemestreE.push("07","08","09","10","11","12"); 
                          this.nameMoth = ["Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]; 
                        }
                        
                          this.arreglosemestreE.map((num) => {
                            sumMonto = 0;
                            this.arreglosemestre2E.filter((num2) => {
                              
                              if(num2.fecha == num){
                                sumMonto = sumMonto + num2.monto;
                              }                      
                            })
                          this.arreglosemestreFE.push(sumMonto)
                          this.egresoN.push({
                            
                            fecha: num,
                            monto: sumMonto
                          })                    
                          })   
                          this.totalEgresoG.push(this.arreglosemestreFE[0], this.arreglosemestreFE[1], this.arreglosemestreFE[2], this.arreglosemestreFE[3], this.arreglosemestreFE[4], this.arreglosemestreFE[5])
                          
                          for(var i = 0; i < this.totalEgresoG.length; i++)
                          {
                            if(this.totalEgresoG[i] == undefined)
                            {
                              this.totalEgresoG[i] = 0;
                            }
                          }
                          
                          
                          this.totalEgresoG.map((monto)=>{  
                                this.sumEgresos = this.sumEgresos + monto;
                          })
                          
                          //****************** INGRESOS - EGRESOS********************                  
                          for (var i = 0; i < this.ingresoN.length; i++) {
                            for (var f = 0; f < this.egresoN.length; f++) {
                              if (this.ingresoN[i].fecha == this.egresoN[f].fecha) {
                                
                                this.resultadoIE = this.ingresoN[i].monto - this.egresoN[f].monto
                                this.resultado.push(this.resultadoIE);
                              }
                            }
                          }                        
                        }else {
                        //Se calcula el mes egreso
                      let sumE = 0;
                      this.fechaUnicaE.map((num) => {
                        sumE = 0;
                        this.dataEgresos.filter((num2) => {
                          if(num2.fecha == num){
                            sumE = sumE + num2.monto;
                          }                      
                        })
                         this.todasFecha.push(num)                                       
                        this.totalEgresoG.push(sumE)
                        this.egresoN.push({
                          fecha: num,
                          monto: sumE
                        })
                      })
                      this.totalEgresoG.map((monto)=>{
                        this.sumEgresos = this.sumEgresos + monto;
                      })
                      this.todasFecha.sort();
                      this.todasFecha = this.todasFecha.filter((element, i) => i === this.todasFecha.indexOf(element))
                      //****************** INGRESOS - EGRESOS********************                  
                      for (var y = 0; y < this.todasFecha.length; y++) {
                        for (var i = 0; i < this.ingresoN.length; i++) {
                          if (this.todasFecha[y] == this.ingresoN[i].fecha) {
                            this.montoI = this.ingresoN[i].monto;
                            break;
                          }
                        }
                        for (var f = 0; f < this.egresoN.length; f++) {
                          if (this.todasFecha[y] == this.egresoN[f].fecha) {
                            this.montoE = this.egresoN[f].monto;
                            break;
                          }
                        }
                        this.ArrarI.push(this.montoI);
                        this.ArrarE.push(this.montoE);
                        this.resultadoIE = this.montoI - this.montoE;
                        this.resultado.push(this.resultadoIE);
                        this.resultadoIE = 0;
                        this.montoI = 0;
                        this.montoE = 0;
                        this.fecha.push(this.todasFecha[y].substring(0,10).split("-").reverse().join("-"));
                      }
                    }
                    
                    this.totalConsolidado = this.sumIngresos - this.sumEgresos
                    if(this.arreglosemestreE.length >= 0|| this.arreglosemestre.length >= 0){
                      this.mostrarGraficoT();
                    } else {
                      this.mostrarGraficoIE();                  
                    }
                    }
                    if(this.consolidadosForm.value.tramos == "Year"){
                      this.dataIngresos = this.dataConsolidados.valor1
                      this.dataEgresos = this.dataConsolidados.valor2
                      this.formularioListo.emit('true')
                      this.consolidadosForm.reset()
  
                      //llenando la tabla Ingresos
                      this.dataIngresosTable = this.dataIngresos.map((ingreso) => {
                        return ingreso
                      })
                      this.dataSource = new MatTableDataSource(this.dataIngresosTable)
                      this.dataSource.paginator = this.paginator.toArray()[0]
                      this.dataSource.sort = this.tableOneSort
  
                      //llenando la tabla Egresos
                      this.dataEgresosTable = this.dataEgresos.map((egreso) => {
                        return egreso
                      })
                      this.dataSourceEgresos = new MatTableDataSource(
                        this.dataEgresosTable
                      )
                      this.dataSourceEgresos.paginator = this.paginator.toArray()[0]
                      this.dataSourceEgresos.sort = this.tableTwoSort
  
                      //*********************INGRESOS********************* 
                      // Obteniendo fecha unica en los ingresos                                   
                      for (var i = 0; i < this.dataIngresos.length; i++) {
                        var res2 = this.dataIngresos[i]['fecha'];
                        if (res2.length <= 10) {
                          if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                            res2 = res2.substring(0,10).split("-").reverse().join("-");
                            res2 = res2.substring(0,10).split("/").reverse().join("-");
                          }
                          let num = res2.indexOf("/");
                          if (num != -1) {
                            let res = res2.replace("/","-");
                            res2 = res.replace("/","-");
                          }
                          let date = new Date(res2);
                          let date2 = date.toISOString();
                          let str = date2.replace("00","04");
                          res2 = str;
                        }
                        this.uniqueArr.push(this.dataIngresos[i]['fecha'])
                        this.todasFecha.push(this.dataIngresos[i]['fecha'])
                        const fechaYear = res2.substring(5, 7);                     
                        this.arregloyear.push(fechaYear);  
                        this.arregloyear2.push({fecha: fechaYear, monto: this.dataIngresos[i]['monto']});
  
                      }  
                      this.fechaUnica = [...new Set(this.uniqueArr)]
                      this.arregloyear = [...new Set(this.arregloyear)]
                      this.fechaUnica.sort()
                      this.arregloyear.sort()                                 
                      // si el arreglo tienes 3 meses de los ingresos
                      if(this.arregloyear.length >= 0 || this.arregloyearE.length >= 0){
                        const llevarmeses = ["01","02","03","04","05","06","07","08","09","10","11","12"]
                        const monthMes = this.buscarMes(this.arregloyear);                    
                        this.nameMoth = monthMes;  
                        let sumMonto = 0; 
                        this.arregloyear = []
                              this.arregloyear.push("01","02","03","04","05","06","07","08","09","10","11","12");                    
                              
                        this.arregloyear.sort();
                        this.arregloyear.map((num) => {
                          sumMonto = 0;
                          this.arregloyear2.filter((num2) => {
                            if(num2.fecha == num){
                              sumMonto = sumMonto + num2.monto;
                            }                      
                          })
                        this.arregloyearF.push(sumMonto)
                        this.ingresoN.push({
                          fecha: num,
                          monto: sumMonto
                        })                    
                        })
                        
                        this.totalIngresoG.push(this.arregloyearF[0], this.arregloyearF[1], this.arregloyearF[2], this.arregloyearF[3], this.arregloyearF[4], this.arregloyearF[5], this.arregloyearF[6], this.arregloyearF[7], this.arregloyearF[8], this.arregloyearF[9], this.arregloyearF[10], this.arregloyearF[11])
                        
                        for(var i = 0; i < this.totalIngresoG.length; i++)
                        {
                          if(this.totalIngresoG[i] == undefined)
                           {
                            this.totalIngresoG[i] = 0;
                           }
                        }
                        this.totalIngresoG.map((monto)=>{  
                            this.sumIngresos = this.sumIngresos + monto;
                        })
                        
  
                      }
  
                      else {                  
                        //se calcula el mes del ingreso
                      let sum = 0;
                      this.fechaUnica.map((num) => {
                        sum = 0;
                        this.dataIngresos.filter((num2) => {
                          if(num2.fecha == num){
                            sum = sum + num2.monto;
                          }                      
                        })                                       
                        this.totalIngresoG.push(sum)
                        this.ingresoN.push({
                          fecha: num,
                          monto: sum
                        })
                      }) 
                                         
                      this.totalIngresoG.map((monto)=>{                    
                        this.sumIngresos = this.sumIngresos + monto;
                      })                  
                    }
                      //*********************EGRESOS*********************
                      // Obteniendo fecha unica en los egresos     
                      for (var i = 0; i < this.dataEgresos.length; i++) {
                        var res2 = this.dataEgresos[i]['fecha'];
                        if (res2.length <= 10) {
                          if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                            res2 = res2.substring(0,10).split("-").reverse().join("-");
                            res2 = res2.substring(0,10).split("/").reverse().join("-");
                          }
                          let num = res2.indexOf("/");
                          if (num != -1) {
                            let res = res2.replace("/","-");
                            res2 = res.replace("/","-");
                          }
                          let date = new Date(res2);
                          let date2 = date.toISOString();
                          let str = date2.replace("00","04");
                          res2 = str;
                        }
                        this.uniqueArr2.push(this.dataEgresos[i]['fecha']) 
                        const fechaYear= res2.substring(5, 7);                     
                        this.arregloyearE.push(fechaYear);
                        this.arregloyear2E.push({fecha: fechaYear, monto: this.dataEgresos[i]['monto']});
                        
                      }  
                      this.fechaUnicaE = [...new Set(this.uniqueArr2)]
                      this.arregloyearE = [...new Set(this.arregloyearE)]
                      this.fechaUnicaE.sort()
                      this.arregloyearE.sort()
                      
                      // si el arreglo tienes 3 meses de los egresos
                      if(this.arregloyearE.length  >= 0  ){
                        const llevarmeses2 = ["01","02","03","04","05","06","07","08","09","10","11","12"]
                        const monthMes = this.buscarMes(this.arregloyear);                    
                        this.nameMoth = monthMes;
                        let sumMonto = 0;    
                        this.arregloyearE = []
                            
                        this.arregloyearE.push("01","02","03","04","05","06","07","08","09","10","11","12")
                              
                          this.arregloyearE.map((num) => {
                            sumMonto = 0;
                            this.arregloyear2E.filter((num2) => {
                              
                              if(num2.fecha == num){
                                sumMonto = sumMonto + num2.monto;
                              }                      
                            })
                          this.arregloyearFE.push(sumMonto)
                          this.egresoN.push({
                            
                            fecha: num,
                            monto: sumMonto
                          })                    
                          })   
                          this.totalEgresoG.push(this.arregloyearFE[0], this.arregloyearFE[1], this.arregloyearFE[2], this.arregloyearFE[3], this.arregloyearFE[4], this.arregloyearFE[5], this.arregloyearFE[6], this.arregloyearFE[7], this.arregloyearFE[8], this.arregloyearFE[9], this.arregloyearFE[10], this.arregloyearFE[11])
                          
                          for(var i = 0; i < this.totalEgresoG.length; i++)
                          {
                            if(this.totalEgresoG[i] == undefined)
                            {
                              this.totalEgresoG[i] = 0;
                            }
                          }
                          
                          this.totalEgresoG.map((monto)=>{  
                                this.sumEgresos = this.sumEgresos + monto;
                          })
                          //****************** INGRESOS - EGRESOS********************                  
                          for (var i = 0; i < this.ingresoN.length; i++) {
                            for (var f = 0; f < this.egresoN.length; f++) {
                              if (this.ingresoN[i].fecha == this.egresoN[f].fecha) {
                                
                                this.resultadoIE = this.ingresoN[i].monto - this.egresoN[f].monto
                                this.resultado.push(this.resultadoIE);
                              }
                            }
                          }                        
                        }else {
                        //Se calcula el mes egreso
                      let sumE = 0;
                      this.fechaUnicaE.map((num) => {
                        sumE = 0;
                        this.dataEgresos.filter((num2) => {
                          if(num2.fecha == num){
                            sumE = sumE + num2.monto;
                          }                      
                        })
                        this.todasFecha.push(num)                                       
                        this.totalEgresoG.push(sumE)
                        this.egresoN.push({
                          fecha: num,
                          monto: sumE
                        })
                      })
                      this.totalEgresoG.map((monto)=>{
                        this.sumEgresos = this.sumEgresos + monto;
                      })
                      this.todasFecha.sort();
                      this.todasFecha = this.todasFecha.filter((element, i) => i === this.todasFecha.indexOf(element))
                      //****************** INGRESOS - EGRESOS********************                  
                      for (var y = 0; y < this.todasFecha.length; y++) {
                        for (var i = 0; i < this.ingresoN.length; i++) {
                          if (this.todasFecha[y] == this.ingresoN[i].fecha) {
                            this.montoI = this.ingresoN[i].monto;
                            break;
                          }
                        }
                        for (var f = 0; f < this.egresoN.length; f++) {
                          if (this.todasFecha[y] == this.egresoN[f].fecha) {
                            this.montoE = this.egresoN[f].monto;
                            break;
                          }
                        }
                        this.ArrarI.push(this.montoI);
                        this.ArrarE.push(this.montoE);
                        this.resultadoIE = this.montoI - this.montoE;
                        this.resultado.push(this.resultadoIE);
                        this.resultadoIE = 0;
                        this.montoI = 0;
                        this.montoE = 0;
                        this.fecha.push(this.todasFecha[y].substring(0,10).split("-").reverse().join("-"));
                      }
                    }
                    this.totalConsolidado = this.sumIngresos - this.sumEgresos
                    
                    if(this.arregloyearE.length >= 0 || this.arregloyear.length >= 0) {
                      this.mostrarGraficoT();
                    } else {
                      this.mostrarGraficoIE();                  
                    }
                    }
                }
                //*************OPCION 1 ************/
                if (this.dataConsolidados.valor == 1) {
                  if(this.consolidadosForm.value.tramos == "Trimestral" || this.consolidadosForm.value.tramos == "Mensual")
                  {
                  this.dataEgresos = this.dataConsolidados.valor2
                  this.formularioListo.emit('true')
                  this.consolidadosForm.reset()

                  //llenando la tabla Egresos
                  this.dataEgresosTable = this.dataEgresos.map((egreso) => {
                    return egreso
                  })                  
                  this.dataSourceEgresos = new MatTableDataSource(
                    this.dataEgresosTable
                  )                  
                  this.dataSourceEgresos.paginator = this.paginator2.toArray()[0]
                  this.dataSourceEgresos.sort = this.tableTwoSort
                  
                  // Obteniendo fecha unica en los egresos
                  for (var i = 0; i < this.dataEgresos.length; i++) {
                    var res2 = this.dataEgresos[i]['fecha'];
                    if (res2.length <= 10) {
                      if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                        res2 = res2.substring(0,10).split("-").reverse().join("-");
                        res2 = res2.substring(0,10).split("/").reverse().join("-");
                      }
                      let num = res2.indexOf("/");
                      if (num != -1) {
                        let res = res2.replace("/","-");
                        res2 = res.replace("/","-");
                      }
                      let date = new Date(res2);
                      let date2 = date.toISOString();
                      let str = date2.replace("00","04");
                      res2 = str;
                    }
                    this.uniqueArr2.push(this.dataEgresos[i]['fecha'])
                    const fechaTrimestre = res2.substring(5, 7);                     
                    this.arreglotrimestreE.push(fechaTrimestre);
                    this.arreglotrimestre2E.push({fecha: fechaTrimestre, monto: this.dataEgresos[i]['monto']});
                  }  
                  this.fechaUnicaE = [...new Set(this.uniqueArr2)]
                  this.arreglotrimestreE = [...new Set(this.arreglotrimestreE)]
                  this.fechaUnicaE.sort()
                  this.arreglotrimestreE.sort()

                  if(this.arreglotrimestreE.length >= 1){

                    const monthMes = this.buscarMes(this.arreglotrimestreE);                    
                    this.nameMoth = monthMes;
                    let sumMonto = 0;                    
                      this.arreglotrimestreE.map((num) => {
                        sumMonto = 0;
                        this.arreglotrimestre2E.filter((num2) => {
                          if(num2.fecha == num){
                            sumMonto = sumMonto + num2.monto;
                          }                      
                        })
                      this.arreglotrimestreFE.push(sumMonto)
                      this.egresoN.push({
                        fecha: num,
                        monto: sumMonto
                      })                    
                      })   
                      this.totalEgresoG.push(this.arreglotrimestreFE[0], this.arreglotrimestreFE[1], this.arreglotrimestreFE[2])
                      this.totalEgresoG.map((monto)=>{  
                        if(monto == undefined)
                        {
                          monto = 0  ;
                            this.sumEgresos = this.sumEgresos + monto;
                        }
                        else
                        { 
                            this.sumEgresos = this.sumEgresos + monto;
                        }
                      })                       
                    }else {
                    //Se calcula el mes egreso
                  let sumE = 0;
                  this.fechaUnicaE.map((num) => {
                    sumE = 0;
                    this.dataEgresos.filter((num2) => {
                      if(num2.fecha == num){
                        sumE = sumE + num2.monto;
                      }                      
                    })                                        
                    this.totalEgresoG.push(sumE)
                    this.egresoN.push({
                      fecha: num,
                      monto: sumE
                    })
                  })
                  this.totalEgresoG.map((monto)=>{
                    this.sumEgresos = this.sumEgresos + monto;
                  }) 
                  for (let i = 0; i < this.fechaUnicaE.length; i++) {
                    this.fecha.push(this.fechaUnicaE[i].substring(0,10).split("-").reverse().join("-"));
                  }                                                   
                }
                  if(this.arreglotrimestreE.length >= 1){
                    this.mostrarGraficoTE();
                  }
                  else {
                    this.mostrarGraficoE();                  
                  } 
                  }
                  if(this.consolidadosForm.value.tramos == "Semestral")
                  {
                  this.dataEgresos = this.dataConsolidados.valor2
                  this.formularioListo.emit('true')
                  this.consolidadosForm.reset()

                  //llenando la tabla Egresos
                  this.dataEgresosTable = this.dataEgresos.map((egreso) => {
                    return egreso
                  })
                  this.dataSourceEgresos = new MatTableDataSource(
                    this.dataEgresosTable
                  )
                  this.dataSourceEgresos.paginator = this.paginator2.toArray()[0]
                  this.dataSourceEgresos.sort = this.tableTwoSort

                  // Obteniendo fecha unica en los egresos                                   
                  for (var i = 0; i < this.dataEgresos.length; i++) {
                    var res2 = this.dataEgresos[i]['fecha'];
                    if (res2.length <= 10) {
                      if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                        res2 = res2.substring(0,10).split("-").reverse().join("-");
                        res2 = res2.substring(0,10).split("/").reverse().join("-");
                      }
                      let num = res2.indexOf("/");
                      if (num != -1) {
                        let res = res2.replace("/","-");
                        res2 = res.replace("/","-");
                      }
                      let date = new Date(res2);
                      let date2 = date.toISOString();
                      let str = date2.replace("00","04");
                      res2 = str;
                    }
                    this.uniqueArr.push(this.dataEgresos[i]['fecha']) 
                    const fechaSemestre = res2.substring(5, 7);
                    
                    this.arreglosemestreE.push(fechaSemestre);                    
                    this.arreglosemestre2E.push({fecha: fechaSemestre, monto: this.dataEgresos[i]['monto']});
                  }
                  this.fechaUnica = [...new Set(this.uniqueArr)]
                  this.arreglosemestreE = [...new Set(this.arreglosemestreE)]
                  this.fechaUnica.sort()
                  this.arreglosemestreE.sort()

                  if (this.arreglosemestreE.length >= 1) {

                    const monthMes = this.buscarMes(this.arreglosemestreE);                    
                    this.nameMoth = monthMes;  
                    let sumMonto = 0;                    
                    this.arreglosemestreE.map((num) => {
                      sumMonto = 0;
                      this.arreglosemestre2E.filter((num2) => {
                        if(num2.fecha == num){
                          
                          sumMonto = sumMonto + num2.monto;
                        }                      
                      })
                    this.arreglosemestreFE.push(sumMonto)
                    this.egresoN.push({
                      fecha: num,
                      monto: sumMonto
                    })                    
                    })   
                    this.totalEgresoG.push(this.arreglosemestreFE[0], this.arreglosemestreFE[1], this.arreglosemestreFE[2],this.arreglosemestreFE[3],this.arreglosemestreFE[4],this.arreglosemestreFE[5])
                    this.totalEgresoG.map((monto)=>{  
                      if(monto == undefined)
                      {
                        monto = 0  ;
                          this.sumEgresos = this.sumEgresos + monto;
                      }
                      else
                      { 
                          this.sumEgresos = this.sumEgresos + monto;
                      }
                    }) 
                  } 
                  else {
                    let sum = 0;
                    this.fechaUnica.map((num) => {
                      sum = 0;
                      this.dataEgresos.filter((num2) => {
                        if(num2.fecha == num){
                          sum = sum + num2.monto;
                        }                      
                      })                                        
                      this.totalEgresoG.push(sum)
                      this.egresoN.push({
                        fecha: num,
                        monto: sum
                      })
                    })                   
                    this.totalEgresoG.map((monto)=>{                    
                      this.sumEgresos = this.sumEgresos + monto;
                    })  
                    for (let i = 0; i < this.fechaUnica.length; i++) {
                      this.fecha.push(this.fechaUnica[i].substring(0,10).split("-").reverse().join("-"));
                    }                
                    }
                    if(this.arreglosemestreE.length >= 1){
                      this.mostrarGraficoTE();
                    }
                    else {
                      this.mostrarGraficoE();                 
                    } 
                  }
                  if(this.consolidadosForm.value.tramos == "Year")
                  {
                    
                  this.dataEgresos = this.dataConsolidados.valor2
                  this.formularioListo.emit('true')
                  this.consolidadosForm.reset()

                  //llenando la tabla Egresos
                  this.dataEgresosTable = this.dataEgresos.map((egreso) => {
                    return egreso
                  })
                  this.dataSourceEgresos = new MatTableDataSource(
                    this.dataEgresosTable
                  )
                  this.dataSourceEgresos.paginator = this.paginator2.toArray()[0]
                  this.dataSourceEgresos.sort = this.tableTwoSort

                  // Obteniendo fecha unica en los egresos                                   
                  for (var i = 0; i < this.dataEgresos.length; i++) {
                    var res2 = this.dataEgresos[i]['fecha'];
                    if (res2.length <= 10) {
                      if (res2.substring(2,3) == '-' || res2.substring(2,3) == '/') {
                        res2 = res2.substring(0,10).split("-").reverse().join("-");
                        res2 = res2.substring(0,10).split("/").reverse().join("-");
                      }
                      let num = res2.indexOf("/");
                      if (num != -1) {
                        let res = res2.replace("/","-");
                        res2 = res.replace("/","-");
                      }
                      let date = new Date(res2);
                      let date2 = date.toISOString();
                      let str = date2.replace("00","04");
                      res2 = str;
                    }
                    this.uniqueArr.push(this.dataEgresos[i]['fecha']) 
                    const fechaYear = res2.substring(5, 7); 
                    this.arregloyearE.push(fechaYear);                    
                    this.arregloyear2E.push({fecha: fechaYear, monto: this.dataEgresos[i]['monto']});
                  }
                  
                  this.fechaUnica = [...new Set(this.uniqueArr)]
                  this.arregloyearE = [...new Set(this.arregloyearE)]
                  this.fechaUnica.sort()
                  this.arregloyearE.sort()
                 
                  if (this.arregloyearE.length >= 1) {
                    const monthMes = this.buscarMes(this.arregloyearE);                    
                    this.nameMoth = monthMes;  
                    let sumMonto = 0;                    
                    this.arregloyearE.map((num) => {
                      sumMonto = 0;
                      this.arregloyear2E.filter((num2) => {
                        if(num2.fecha == num){
                          
                          sumMonto = sumMonto + num2.monto;
                          
                        }                      
                      })
                    this.arregloyearFE.push(sumMonto)
                    this.egresoN.push({
                      fecha: num,
                      monto: sumMonto
                    })                    
                    })  
                    this.totalEgresoG.push(this.arregloyearFE[0], this.arregloyearFE[1], 
                      this.arregloyearFE[2],this.arregloyearFE[3],this.arregloyearFE[4],
                      this.arregloyearFE[5],this.arregloyearFE[6],this.arregloyearFE[7],
                      this.arregloyearFE[8],this.arregloyearFE[9],this.arregloyearFE[10],this.arregloyearFE[11])

                        this.totalEgresoG.map((monto)=>{  
                          if(monto == undefined)
                          {
                            monto = 0  ;
                              this.sumEgresos = this.sumEgresos + monto;
                          }
                          else
                          { 
                              this.sumEgresos = this.sumEgresos + monto;
                          }
                        }) 
                  } 
                  else {
                    let sum = 0;
                    this.fechaUnica.map((num) => {
                      sum = 0;
                      this.dataEgresos.filter((num2) => {
                        if(num2.fecha == num){
                          sum = sum + num2.monto;
                        }                      
                      })                                        
                      this.totalEgresoG.push(sum)
                      this.egresoN.push({
                        fecha: num,
                        monto: sum
                      })
                    })                   
                    this.totalEgresoG.map((monto)=>{                    
                      this.sumEgresos = this.sumEgresos + monto;
                    })  
                    for (let i = 0; i < this.fechaUnica.length; i++) {
                      this.fecha.push(this.fechaUnica[i].substring(0,10).split("-").reverse().join("-"));
                    }                
                    }
                    if(this.arregloyearE.length >= 1){
                      this.mostrarGraficoTE();
                    }
                    else {
                      this.mostrarGraficoE()                 
                    }

                  }
                }

              },
              (error: any) => {
                this.alert.consolidado("Por favor ingrese datos distintos!");
                this.flag = false;                
                console.log(error)
              }
            )
        }

        break
      case 'INVALID':
        this.flag = false;
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
          
        })
        break
      default:
        break
    }
  }
    
  buscarMes(mes: any) {
    let nombreMes;
    let arrayMonth: any = [];
    mes.forEach((element: any) => {      
      if(element == '01'){nombreMes = 'Enero', arrayMonth.push(nombreMes)}
      if(element == '02'){nombreMes = 'Febrero', arrayMonth.push(nombreMes)}
      if(element == '03'){nombreMes = 'Marzo', arrayMonth.push(nombreMes)}
      if(element == '04'){nombreMes = 'Abril', arrayMonth.push(nombreMes)}
      if(element == '05'){nombreMes = 'Mayo', arrayMonth.push(nombreMes)}
      if(element == '06'){nombreMes = 'Junio', arrayMonth.push(nombreMes)}
      if(element == '07'){nombreMes = 'Julio', arrayMonth.push(nombreMes)}
      if(element == '08'){nombreMes = 'Agosto', arrayMonth.push(nombreMes)}
      if(element == '09'){nombreMes = 'Septiembre', arrayMonth.push(nombreMes)}
      if(element == '10'){nombreMes = 'Octubre', arrayMonth.push(nombreMes)}
      if(element == '11'){nombreMes = 'Noviembre', arrayMonth.push(nombreMes)}
      if(element == '12'){nombreMes = 'Diciembre', arrayMonth.push(nombreMes)}
    });    
    return arrayMonth;
  }

  // ? selection rows
  // *  INFO this.selection.selected : return array with all selected objects(rows) into table
  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  public openPDF() : void{
    // Extraemos el
    const DATA = document.getElementById('htmlData') as HTMLCanvasElement;    
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {
      var myBase64Image = this.base64I;          
      const img = canvas.toDataURL('image/PNG');
      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;      
      doc.addImage(myBase64Image, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_consolidadoHostal.pdf`);
    });
  }



  exportAsXLSX(): void {
    let worksheet = this.workbook.addWorksheet('My Sheet', {
      properties: {
        defaultRowHeight: 100,
      }
    });

    worksheet.columns = [      
      { header: 'Imagen', key: 'imagen', width: 150 },
    ];
    
    var myBase64Image = this.base64I;    
    var imageId2 = this.workbook.addImage({
      base64: myBase64Image,
      extension: 'png'
    });

    worksheet.addImage(imageId2, 'A2:A2');    
    var row = worksheet.getRow(2);
    row.height = 300;

    this.workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });      
      FileSaver.saveAs(blob, 'Consolidado-Hostal' + '.xlsx');
    });
  }

  masterToggle() {    
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        })
  }

  // ************************ GRAFICOS ************************
  mostrarGraficoI() {
    if (this.cont != 0) {
      this.chart.destroy();
    }
    this._cdref.detectChanges()
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: {        
        labels: this.fecha,
        datasets: [
          {
            label: 'Ingresos',
            backgroundColor: ['#f2829b'],            
            data: this.totalIngresoG            
          }
        ]
      },
      options: {                        
        animation : {
           onComplete : () => {
            this.base64I = this.chart.toBase64Image();
           }
        }      
     }
    })
    this.cont+=1;
    this.flag = false;
  }

  mostrarGraficoE() {
    if (this.cont != 0) {
      this.chart.destroy();
    }
    this._cdref.detectChanges()
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: {        
        labels: this.fecha,
        datasets: [
          {
            label: 'Egresos',
            backgroundColor: ['#ffe510'],            
            data: this.totalEgresoG            
          }
        ]
      },
      options: {                      
        animation : {
           onComplete : () => {
            this.base64I = this.chart.toBase64Image();
           }
        }      
     }
    })
    this.cont+=1;
    this.flag = false;
  }

  mostrarGraficoIE() {  
    if (this.cont != 0) {
      this.chart.destroy();
    }  
    this._cdref.detectChanges()
    this.chart = new Chart(this.chartRef.nativeElement, {
    type: 'line',
    data: {        
      labels: this.fecha,
      datasets: [
        {
          label: 'Ingresos',            
          data: this.ArrarI,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        },
        {
          label: 'Egresos',                        
          data: this.ArrarE,
          fill: false,
          borderColor: '#64c04b',
          tension: 0.1
        },
        {
          label: 'Resultado',            
          data: this.resultado,
          fill: false,
          borderColor: '#a74bc0',
          tension: 0.1
        },
      ]
    },
    options: {
      //responsive: false,                
      animation : {
        onComplete : () => {
          this.base64I = this.chart.toBase64Image();
          }
        }      
      }
    })
    this.cont+=1;   
    this.flag = false;        
  }
  
  mostrarGraficoT() {
    if (this.cont != 0) {
      this.chart.destroy();
    }  
    this._cdref.detectChanges()
    this.chart = new Chart(this.chartRef.nativeElement, {
    type: 'line',
    data: {        
      labels: this.nameMoth,
      datasets: [
        {
          label: 'Ingresos',            
          data: this.totalIngresoG,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        },
        {
          label: 'Egresos',                        
          data: this.totalEgresoG,
          fill: false,
          borderColor: '#64c04b',
          tension: 0.1
        },
        {
          label: 'Resultado',            
          data: this.resultado,
          fill: false,
          borderColor: '#a74bc0',
          tension: 0.1
        },
      ]
    },
    options: {
      //responsive: false,                
      animation : {
         onComplete : () => {
          this.base64I = this.chart.toBase64Image();
         }
      }      
   }
  })
  this.cont+=1;
  this.flag = false;           
  }
  
  mostrarGrafico() { 
    if (this.cont != 0) {
      this.chart.destroy();
    }
    this._cdref.detectChanges()
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: {
        labels: [
          'Enero',
          'Febrero',
          'Marzo',          
        ],
        //labels: this.fechaG,
        datasets: [
          {
            label: 'Ingresos',
            data: [3883365, 7377359, 5383380],
            //data: this.montoIngreso,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: 'Egresos',
            data: [3243365, 4434359, 3434380],
            //data: this.montoEgreso,
            fill: false,
            borderColor: '#64c04b',
            tension: 0.1
          },
          {
            label: 'Resultado',
            data: [5332365, 6434359, 8434380],
            //data: this.resultado,
            fill: false,
            borderColor: '#a74bc0',
            tension: 0.1
          },
        ]
      }
    })
    this.cont+=1; 
    this.flag = false;   
  }
  
  mostrarGraficoTE() {
    if (this.cont != 0) {
      this.chart.destroy();
    }  
    this._cdref.detectChanges()
    this.chart = new Chart(this.chartRef.nativeElement, {
    type: 'bar',
    data: {        
      labels: this.nameMoth,
      datasets: [
        {
          label: 'Egresos',                        
          data: this.totalEgresoG,
          backgroundColor: ['#ffe510']
          //fill: false,
          //borderColor: '#64c04b',
          //tension: 0.1
        },
      ]
    },
    options: {
      //responsive: false,                
      animation : {
         onComplete : () => {
          this.base64I = this.chart.toBase64Image();
         }
      }      
   }
  })
  this.cont+=1;  
  this.flag = false;         
  }

  mostrarGraficoTI() {
    if (this.cont != 0) {
      this.chart.destroy();
    }  
    this._cdref.detectChanges()
    this.chart = new Chart(this.chartRef.nativeElement, {
    type: 'bar',
    data: {        
      labels: this.nameMoth,
      datasets: [
        {
          label: 'Ingresos',                        
          data: this.totalIngresoG,
          backgroundColor: ['#ffe510']
          //fill: false,
          //borderColor: '#64c04b',
          //tension: 0.1
        },
      ]
    },
    options: {
      //responsive: false,                
      animation : {
         onComplete : () => {
          this.base64I = this.chart.toBase64Image();
         }
      }      
   }
  })
  this.cont+=1;  
  this.flag = false;         
  } 

}
