<mat-toolbar color="primary">
  <button
    style="margin-right: 1rem"
    mat-raised-button
    (click)="limpiarFiltros()"
    color="accent"
  >
    Actualizar tablasss
  </button>
  <span style="margin-right: 1rem">Egresos Hostal</span>
  <span style="margin-right: 2rem"
    >Monto Registros Seleccionados:
    {{ totalSeleccion | currency : '$' : 'symbol' : '1.0-0' }}
  </span>
  <button
    mat-raised-button
    color="accent"
    style="margin-left: 1rem"
    (click)="revelarTotal()"
  >
    Revelar total
  </button>
  <button
    mat-raised-button
    color="accent"
    style="margin-left: 1rem"
    (click)="exportAsXLSX()"
  >
    Exportar a Excel
  </button>
  <button
    mat-raised-button
    color="accent"
    style="margin-left: 1rem"
    (click)="openDialogRegistrarPago()"
  >
    Ver cuotas
  </button>
</mat-toolbar>

<div class="table-responsive">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="id"
    matSortDirection="desc"
    class="mat-elevation-z10"
  >
    <!-- check column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- ID with checkbox -->

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill limit-width-40">
            <input matInput placeholder="ID" formControlName="id" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <!-- date with datapicker -->
    <ng-container matColumnDef="fecha" class="mat-column-date">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="limit-width-180"
      >
        <mat-form-field appearance="fill" class="limit-width-180">
          <mat-label>Fecha</mat-label>
          <mat-date-range-input [formGroup]="formFilter" [rangePicker]="picker">
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
            />
            <input matEndDate formControlName="end" placeholder="End date" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error
            *ngIf="formFilter.controls.start.hasError('matStartDateInvalid')"
            >Invalid start date
          </mat-error>
          <mat-error
            *ngIf="formFilter.controls.end.hasError('matEndDateInvalid')"
            >Invalid end date
          </mat-error>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.fecha | date : 'dd/MM/yyyy' }}
      </td>
    </ng-container>
    <!-- sucursal with text input -->
    <ng-container matColumnDef="sucursal">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <mat-select placeholder="Sucursal" formControlName="idSucursal">
              <mat-option
                *ngFor="let sucursal of sucursales"
                [value]="sucursal.razonSocial"
              >
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.sucursal }}</td>
    </ng-container>

    <!-- Campo Numero de cuotas -->
    <ng-container matColumnDef="numeroCuota">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº de cuotas</th>
      <td mat-cell *matCellDef="let element">
        {{ element.numeroCuota }}
      </td>
    </ng-container>

    <!-- tipo egreso with select -->
    <!-- monto currency fomrat -->

    <ng-container matColumnDef="monto">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill limit-width-80">
            <input matInput placeholder="Monto" formControlName="monto" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.monto | currency : '$' : 'symbol' : '1.0-0' }}
      </td>
    </ng-container>
    <!-- TODO repair source ts -->
    <ng-container matColumnDef="tipoEgreso">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <mat-select placeholder="Tipo Egreso" formControlName="tipoEgreso">
              <mat-option *ngFor="let tipo of tiposEgresos" [value]="tipo">
                {{ tipo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.tipoEgreso }}</td>
    </ng-container>

    <!-- TODO repair source ts -->
    <ng-container matColumnDef="tipoPago">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width" style="margin-right: 10px">
            <mat-select placeholder="Tipo de Pago" formControlName="tipoPago">
              <mat-option *ngFor="let tipo of tiposPagos" [value]="tipo">
                {{ tipo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.tipoPago }}</td>
    </ng-container>

    <ng-container matColumnDef="grupoGastos">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width" style="margin-right: 10px">
            <mat-select
              placeholder="Grupo de envio Gastos"
              formControlName="grupoGastos"
            >
              <mat-option *ngFor="let grupo of grupos" [value]="grupo">
                {{ grupo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.grupoGastos }}</td>
    </ng-container>

    <!-- usuario with select filter -->
    <!-- TODO add filter rule un types and here -->
    <ng-container matColumnDef="usuario">
      <th mat-header-cell *matHeaderCellDef>Usuario</th>
      <td mat-cell *matCellDef="let element">{{ element.usuario }}</td>
    </ng-container>
    <!-- respaldos with button download -->
    <ng-container matColumnDef="respaldos">
      <th mat-header-cell *matHeaderCellDef>Respaldos</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-mini-fab
          (click)="recuperarArchivos(element.RespaldoEgresos)"
        >
          <mat-icon>get_app</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Botones -->
    <ng-container matColumnDef="botones">
      <th mat-header-cell *matHeaderCellDef style="text-align: center">
        Acciones
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          color="accent"
          style="margin-left: 1rem; margin-bottom: 3px"
          (click)="openDialogEdit(element.id)"
        >
          <mat-icon>mode_edit</mat-icon> Editar
        </button>
        <br />
        <button
          mat-raised-button
          color="accent"
          style="margin-left: 1rem; margin-bottom: 3px"
          (click)="deleteEgreso(element.id)"
        >
          <mat-icon>delete</mat-icon> Eliminar
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No existen coincidencias</td>
    </tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>

