import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete'
import { MatSnackBar } from '@angular/material/snack-bar'
import { modeloSolicitudBancaria } from '@app/_models/consolidadoBancario/solicitudBancaria'
import { Empresa } from '@app/_models/shared/empresa'
import { Sucursal } from '@app/_models/shared/sucursal'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  startWith,
  switchMap
} from 'rxjs/operators'
import { validateRUT, clearRUT } from 'validar-rut'
import { ConsolidadoBancarioService } from '../../consolidado-bancario.service'
import { Usuario } from '@app/_pages/inventario/transferencia/interfaces/transferencias'
//import { Date } from 'date-fns';
import { Router } from '@angular/router'
import { modeloCuentasBancaria } from '../../../../_models/consolidadoBancario/cuentasBancaria'

import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs'
import { AuthSharedService } from '../../../shared/shared-services/auth-shared.service'
//import {AutocompleteLibModule} from 'angular-ng-autocomplete';

import { ValidarRutService } from '../../../../../utils/services/validar-rut.service'

@Component({
  selector: 'app-solictud-form',
  templateUrl: './solictud-form.component.html',
  styleUrls: ['./solictud-form.component.scss']
})
export class SolictudFormComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  nombreUsuario = this.usuario.nombre
  apellidoUsuario = this.usuario.apellido
  nombreCompleto = this.usuario.nombre + ' ' + this.usuario.apellido
  result2 = ''
  numberConvert = ''
  notificarPlazo = ''

  @ViewChild('autoDestinatario') autoDestinatario!: MatAutocomplete
  destinatariosControl = new FormControl('')
  filteredOptions2: Observable<any[]>
  public destinatarios$: any[]
  destinatarioExiste: boolean = true
  datosDestinatario: string[] = []
  isSubmitting = false

  mostrarBotonGuardar: boolean = false
  rutValido = true
 
  linkvalue: string
  sessionData: any
  form!: FormGroup
  //formulario general
  nombreResponsable: any
  fechaSolicitud: any
  motivoPago: any
  jefeAutoriza: any
  montoPago: any
  empresaAsumeGastos: any
  asumeFactura: any
  inversion: any
  nombreTransferencia: any
  rut: any
  NumeroCuenta: any
  banco: any
  estado: any
  archivos: any
  bancoCliente: any
  NumeroCuentaAsume: any
  tipoCuenta: any
  link: any
  cuentas: any
  cuentaSeleccionado: any
  termino: any
  tamano_rut: string
  //===> mendex
  sucursales: Sucursal[]
  empresa = new Empresa()
  correo: string
  horaActual: any
  RutEmpresa: any
  today = new Date()
  //Modelo de Solicitud Bancaria
  modelo: modeloSolicitudBancaria = new modeloSolicitudBancaria()
  //variable auxiliar que estara true solo cuando no este seleccionado un destinatario
  crearDestinatarioNuevo: boolean = true
  maxDate = new Date()
  panelWidth: string = '650px'
  selectedOption: string | null = null

  getPanelWidth(): string {
    return '650px'
  }

  constructor(
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService,
    private sucursalService: SucursalSharedService,
    private ValidarRutService: ValidarRutService
  ) {
    this.empresaAsumeGastos = [
      'RENT A CAR',
      'LUBRICENTRO ',
      'HOSTAL',
      'IMPORTADORA/MOTOS',
      'Gasto Personal Teresa',
      'Gasto Personal Miguel',
      'Gasto Personal Diego',
      'Gasto Personal Miguel A',
      'GASTO PERSONAL'
    ]
  }
  ngOnInit(): void {
    this.getCuentaBancaria()
    this.getEmpresa()
    this.maxDate = new Date() // Establecer la fecha máxima como la fecha actual
    this.getDestinatarios()
    this.filteredOptions()
  }

  preventInput(event: KeyboardEvent) {
    event.preventDefault()
  }

 



  

  getDestinatarios(): any {
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (data: any) => {
        console.log('Datos recibidos del servicio:', data) // Verifica que estás recibiendo todos los datos

        // Mapea los datos a una lista de objetos
        let destinatarios = data.map((solicitud) => {
          console.log('Nombre de transferencia:', solicitud.nombreTransferencia) // Verifica los valores de nombreTransferencia
          return {
            nombreTransferencia: solicitud.nombreTransferencia,
            rut: solicitud.rut,
            NumeroCuenta: solicitud.NumeroCuenta,
            banco: solicitud.bancoCliente,
            tipoCuenta: solicitud.tipoCuenta,
            correo: solicitud.correo
          }
        })

        console.log('Destinatarios antes del filtro:', destinatarios) // Verifica los datos antes del filtro

        // Usa un conjunto para mantener los nombres únicos
        const nombresUnicos = new Set()
        destinatarios = destinatarios.filter((destinatario) => {
          if (nombresUnicos.has(destinatario.nombreTransferencia)) {
            return false // Excluye destinatarios con nombres duplicados
          } else {
            nombresUnicos.add(destinatario.nombreTransferencia)
            return true // Incluye destinatarios con nombres únicos
          }
        })

        // Asigna los destinatarios filtrados a la propiedad
        this.destinatarios$ = destinatarios

        console.log('Destinatarios después del filtro:', this.destinatarios$) // Verifica los datos después del filtro
      },
      (error) => {
        console.log(`Ocurrió un error: ${error}`)
      }
    )
  }

  filteredOptions() {
    this.filteredOptions2 = this.destinatariosControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filtrar(value || ''))
    )
  }

  private filtrar(value: string): any[] {
    const filterValue = value;

    return (this.destinatarios$ || []).filter(
      (option) =>
        typeof option.nombreTransferencia === 'string' &&
        option.nombreTransferencia.trim() !== '' &&
        option.nombreTransferencia !== null &&
        option.nombreTransferencia.trim()?.toLowerCase().includes(filterValue)
    )
  }

  /* Método que filtra los destinatarios de la lista para que no se repitan por numero de cuenta */
  filtrarDestinatariosPorNumerosCuentaUnicos(): any {
    /* Utiliza un conjunto (Set) para mantener un registro de números de cuenta únicos */
    const numerosCuentaUnicos = new Set<string>()
    const destinatariosFiltrados = []

    // Recorre los destinatarios y agrega solo aquellos con números de cuenta no repetidos
    for (const destinatario of this.destinatarios$) {
      if (!numerosCuentaUnicos.has(destinatario.NumeroCuenta)) {
        numerosCuentaUnicos.add(destinatario.NumeroCuenta)
        destinatariosFiltrados.push(destinatario)
      }
    }

    return destinatariosFiltrados
  }

  /* Al seleccionar un destinatario, se crea un array el cual se le pasan los valores de las propiedades del destinatario y luego se pasan al modelo para registrarlo en la solicitud */
  selectEvent(event: MatAutocompleteSelectedEvent) {
    const item = event.option.value

    this.destinatarioExiste = true

    this.datosDestinatario = []

    if (this.destinatarioExiste) {
      this.crearDestinatarioNuevo = false
    } else {
      this.crearDestinatarioNuevo = true
    }

    this.datosDestinatario.push(item.nombreTransferencia)
    this.datosDestinatario.push(item.rut)
    this.datosDestinatario.push(item.NumeroCuenta)
    this.datosDestinatario.push(item.banco)
    this.datosDestinatario.push(item.tipoCuenta)
    this.datosDestinatario.push(item.correo)

    this.modelo.nombreTransferencia = this.datosDestinatario[0]
    this.modelo.rut = this.datosDestinatario[1]
    this.modelo.bancoCliente = this.datosDestinatario[3]
    this.modelo.correo = this.datosDestinatario[5]
    this.modelo.NumeroCuenta = this.datosDestinatario[2]
    this.modelo.tipoCuenta = this.datosDestinatario[4]

    this.filteredOptions()

    this.mostrarBotonGuardar = true
  }

  crearDestinatario() {
    this.destinatarioExiste = false
    this.crearDestinatarioNuevo = true

    this.infDtTransferencia.get('nombreTransferencia').enable()
    this.infDtTransferencia.get('rut').enable()
    this.infDtTransferencia.get('NumeroCuenta').enable()
    this.infDtTransferencia.get('bancoCliente').enable()
    this.infDtTransferencia.get('tipoCuenta').enable()
    this.infDtTransferencia.get('correo').enable()

    this.infDtTransferencia.get('nombreTransferencia').setValue('')
    this.infDtTransferencia.get('rut').setValue('')
    this.infDtTransferencia.get('NumeroCuenta').setValue('')
    this.infDtTransferencia.get('bancoCliente').setValue('')
    this.infDtTransferencia.get('tipoCuenta').setValue('')
    this.infDtTransferencia.get('correo').setValue('')
  }

  /*Metodo para saber si se requieren los datos del formulario destinatario dependiendo si hay que crearlo o no*/
  // customValidator(createNewRecipient: boolean): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     if (createNewRecipient) {
  //       // Si createNewRecipient es verdadero, no aplicamos validación.
  //       return null;
  //     } else {
  //       // Aplicamos las validaciones requeridas.
  //       return Validators.required(control);
  //     }
  //   };
  // }

  cambioBoton(event: any) {
    const inputValue = event.target.value

    // Lógica adicional, si es necesario

    this.mostrarBotonGuardar = inputValue.length > 0
  }

  //* ignacio aliste
  mostrarButonEnviar(event: any) {
    var inputRut = event.target.value
    if (this.ValidarRutService.validar_rut(inputRut)) {
      this.mostrarBotonGuardar = true
    } else {
      this.mostrarBotonGuardar = false
    }
  }

  noOption(event: any) {
    if (event.value) {
      this.selectedOption = null
    }
  }

  getEmpresa(): any {
    this.sucursalService.getAll().subscribe((sucursales: Sucursal[]) => {
      this.sucursales = sucursales.sort((a, b) =>
        a.razonSocial > b.razonSocial ? 1 : -1
      )
    })
  }

  infBasica = this._formBuilder.group({
    nombreResponsable: [this.nombreCompleto],
    fechaSolicitud: ['', Validators.required],
    motivoPago: ['', Validators.required],
    jefeAutoriza: ['', Validators.required],
    montoPago: [null, [Validators.required]],
    empresaAsumeGasto: [Validators.required],
    asumeFactura: ['', [Validators.required]],
    inversion: ['', [Validators.required]],
    estado: ['Pendiente'],
    link: [''],
    RutEmpresa: [''],
    termino: [''],
    correo: [''],

    tipoCuenta: [''],

    idSucursal: ['', Validators.required]
  })

  infDtTransferencia = this._formBuilder.group({
    nombreTransferencia: [''],
    rut: [''],
    NumeroCuenta: [''],
    bancoCliente: [''],
    banco: [''],
    NumeroCuentaAsume: [''],
    archivos: null,
    tipoCuenta: [''],
    correo: ['']
  })

  formatoRut() {
    if (
      this.tamano_rut &&
      this.tamano_rut.length >= 9 &&
      this.tamano_rut.length <= 10
    ) {
      const esValido = validateRUT(this.tamano_rut)
      if (esValido) {
        this.snackBar.open('Rut valido', '', {
          duration: 2000,
          verticalPosition: 'top'
        })
        this.rutValido = true
      } else {
        this.snackBar.open('Rut invalido', '11111111-1', {
          duration: 2000,
          verticalPosition: 'top'
        })
        this.rutValido = false
      }
    }
  }

  //LLamar Cuenta bancaria
  getCuentaBancaria() {
    this.consolidadoBancarioService
      .getAllCuentaBancaria()
      .pipe(first())
      .subscribe((cuentas) => {
        this.cuentas = cuentas
        console.log('aqui las cuentas ', typeof this.cuentas)
      })
  }
  displayFn(cuenta: any): string {
    return cuenta && cuenta.nombreCuenta ? cuenta.nombreCuenta : ''
  }
  opcionSeleccionada(event: MatAutocompleteSelectedEvent) {
    const cuenta = event.option.value

    console.log("cuenta seleccionada", cuenta)


    this.termino = cuenta.nombreCuenta
    this.NumeroCuentaAsume = cuenta.numeroCuenta
    this.banco = cuenta.banco
    this.RutEmpresa = cuenta.rut
    this.selectedOption = cuenta

    this.consolidadoBancarioService
      .getById(cuenta.id)
      .subscribe((cuentaData) => {
        cuentaData.map((res) => {
          console.log(res.id)
        })
        // const nombreEmpresa = cuenta[0].nombreCuenta
        const nombreEmpresa = cuenta.nombreCuenta

        // if (
        //   nombreEmpresa === 'Empresa Teresa del Carmen Garrido Rojas e hijos'
        // ) {
        //   this.empresaAsumeGasto = 'RENT A CAR'
        // }
        // if (nombreEmpresa === 'Empresa Firma Abogados') {
        //   this.empresaAsumeGasto = 'RENT A CAR'
        // }
        // if (nombreEmpresa === 'Empresa Soc. Garrido Vargas') {
        //   this.empresaAsumeGasto = 'LUBRICENTRO '
        // }
        // if (nombreEmpresa === 'Empresa Miguel Vargas Espinoza e hijos') {
        //   this.empresaAsumeGasto = 'HOSTAL'
        // }
        // if (nombreEmpresa === 'Empresa Teresa II') {
        //   this.empresaAsumeGasto = 'IMPORTADORA/MOTOS'
        // }
        // if (nombreEmpresa == 'Señora Teresa') {
        //   this.empresaAsumeGasto = 'Gasto Personal Teresa'
        // }
        // if (nombreEmpresa == 'Miguel Vargas') {
        //   this.empresaAsumeGasto = 'Gasto Personal Miguel'
        // }
        // if (nombreEmpresa == 'Diego Vargas') {
        //   this.empresaAsumeGasto = 'Gasto Personal Diego'
        // }
        // if (nombreEmpresa == 'Miguel A Vargas Espinoza') {
        //   this.empresaAsumeGasto = 'Gasto Personal Miguel A'
        // }
        // if (nombreEmpresa === 'Empresa Solanch Macarena Tejos Carrasco') {
        //   this.empresaAsumeGasto = 'RENT A CAR'
        // }
        // if (
        //   nombreEmpresa == 'SOC. VARGAS I' ||
        //   nombreEmpresa == 'SOC. VARGAS II'
        // ) {
        //   this.empresaAsumeGasto = 'INMOBILIARIA'
        // }
        // if (nombreEmpresa == 'TERESA GARRIDO ROJAS BANCO ESTADO') {
        //   this.empresaAsumeGasto = 'GASTO PERSONAL'
        // }
        // if (nombreEmpresa == 'MIGUEL VARGAS GARRIDO BANCO SANTANDER') {
        //   this.empresaAsumeGasto = 'GASTO PERSONAL'
        // }
        // if (nombreEmpresa == 'DIEGO VARGAS GARRIDO BANCO ITAU') {
        //   this.empresaAsumeGasto = 'GASTO PERSONAL'
        // }
        // if (nombreEmpresa == 'MIGUEL VARGAS ESPINOZA BANCO ESTADO') {
        //   this.empresaAsumeGasto = 'GASTO PERSONAL'
        // }
        // if (nombreEmpresa == 'BRAULIO VARGAS GARRIDO BANCO SANTANDER') {
        //   this.empresaAsumeGasto = 'GASTO PERSONAL'
        // }
        // if (nombreEmpresa == 'JAVIER VARGAS GARRIDO BANCO ITAU') {
        //   this.empresaAsumeGasto = 'GASTO PERSONAL'
        // }
        // this.form.get('empresaAsumeGasto')?.setValue(this.empresaAsumeGasto);
      })
  }

  onSubmit() {
    if (this.isSubmitting) {
      return
    }

    if (this.destinatarioExiste) {
      this.form = this._formBuilder.group(Object.assign(this.infBasica.value))
    } else {
      this.form = this._formBuilder.group(
        Object.assign(this.infBasica.value, this.infDtTransferencia.value)
      )
    }

    switch (this.form.status) {
      case 'VALID':
        console.log('aqui el formulario', this.form)
        console.log(
          'value asume factura',
          this.form.value.asumeFactura.nombreCuenta
        )

        this.isSubmitting = true

        this.modelo.nombreResponsable = this.form.value.nombreResponsable
        this.modelo.fechaSolicitud = this.form.value.fechaSolicitud
        this.modelo.motivoPago = this.form.value.motivoPago
        this.modelo.jefeAutoriza = this.form.value.jefeAutoriza
        //this.modelo.montoPago = this.form.value.montoPago
        this.modelo.montoPago = parseInt(this.numberConvert)
        this.modelo.empresaAsumeGasto = this.form.value.empresaAsumeGasto
        this.modelo.asumeFactura = JSON.stringify(this.form.value.asumeFactura.nombreCuenta).slice(1,-1) //felipe
        this.modelo.inversion = this.form.value.inversion

        /*Si el destinatario lo selecciona desde la lista, no se agrega la data de los inputs*/
        if (!this.destinatarioExiste) {
          this.modelo.nombreTransferencia = this.form.value.nombreTransferencia
          this.modelo.rut = this.form.value.rut
          this.modelo.NumeroCuenta = this.form.value.NumeroCuenta
          this.modelo.bancoCliente = this.form.value.bancoCliente
          this.modelo.tipoCuenta = this.form.value.tipoCuenta
          this.modelo.correo = this.form.value.correo
        }

        this.modelo.banco = this.banco
        this.modelo.estado = this.form.value.estado
        this.modelo.NumeroCuentaAsume = this.NumeroCuentaAsume

        // TODO revisar el codgo 
        // if (
        //   this.form.value.link !== null &&
        //   this.form.value.link !== undefined &&
        //   this.form.value.link  !== '' ||
        //   this.form.value.link  !== ""
        // ) {
          
        //   if (!this.form.value.link ?.startsWith('https')) {
        //     this.linkvalue = 'https://' + this.form.value.link .trim()
        //   }
        //   }
        //   else if(this.linkvalue .startsWith('"')) {
        //     this.linkvalue = this.linkvalue.slice(1)
        //     console.log('link 1 ::::::', this.linkvalue)
        //   }
        //   else if (this.linkvalue .startsWith('\\')) {
        //     this.linkvalue = this.linkvalue.slice(1)
        //     console.log('link 2 ::::::', this.linkvalue)
        //   }
        //   else if (this.linkvalue.startsWith('"') && this.linkvalue.endsWith('"')) {
        //     this.linkvalue = this.linkvalue.slice(1, -1)
        //   }else{
        //     this.linkvalue= this.form.value.link 
      
        // }


        this.modelo.link = this.form.value.link
        this.modelo.idUsuario = this.usuario.id
        this.modelo.idSucursal = this.form.value.idSucursal
        this.modelo.createdAt = this.form.value.createdAt
        this.modelo.updatedAt = this.form.value.updatedAt
        this.modelo.notificarPlazo=''


        
        console.log(this.modelo)
        this.consolidadoBancarioService
          .registrarSolicitudBancaria(this.modelo)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.snackBar.open(
                'Solicitud Bancaria registrada con exito',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
               window.location.reload()
            },
            (error: any) => {
              this.snackBar.open(
                'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              this.snackBar.open(error, 'cerrar', {
                duration: 3000,
                verticalPosition: 'top'
              })
            }
          )
          .add(() => {
            this.isSubmitting = false
          })

        break
      case 'INVALID':
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }

  onInputChange(event: any) {
    const inputValue = event.target.value
    // Permitir solo letras y números
    event.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, '')
  }

  transform(val: any) {
    if (val) {
      //console.log(val, '*************')
      val = this.format_number(val, '')
    }
    return val
  }
  format_number(number: any, prefix: any) {
    let thousand_separator = '.',
      decimal_separator = ',',
      regex = new RegExp('[^' + decimal_separator + '\\d]', 'g'),
      number_string = number.replace(regex, '').toString(),
      split = number_string.split(decimal_separator),
      rest = split[0].length % 3,
      result = split[0].substr(0, rest),
      thousands = split[0].substr(rest).match(/\d{3}/g)
    if (thousands) {
      let separator = rest ? thousand_separator : ''
      result += separator + thousands.join(thousand_separator)
    }
    result =
      split[1] != undefined ? result + decimal_separator + split[1] : result

    this.result2 = result
    this.numberConvert = number_string
    return prefix == undefined ? result : result ? prefix + result : ''
  }

  restrictNumeric(e: any) {
    let input
    if (e.metaKey || e.ctrlKey) {
      return true
    }
    if (e.which === 32) {
      return false
    }
    if (e.which === 0) {
      return true
    }
    if (e.which < 33) {
      return true
    }
    input = String.fromCharCode(e.which)
    return !!/[\d\s]/.test(input)
  }

  myFilter = (d: Date | null): boolean => {
    const today = new Date()
    today.setHours(0, 0, 0, 0) // Elimina la hora para comparar solo la fecha
    return d ? d.getTime() === today.getTime() : false
  }
}
