import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { RegistroEgresoFirma } from '@app/_models/abogados/egresosFirma'
import { environment } from '@environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { MatDialog } from '@angular/material/dialog'
import { GastosFijos} from '@app/_models/gastosFijos/gastosFijos'
import { AbogadosGastosFijosEditComponent} from './abogados-gastos-fijos/abogados-gastos-fijos-edit/abogados-gastos-fijos-edit.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DialogRegistrarPagoComponent } from './abogados-ingresos/abogados-ingresos-acciones/dialog-registrar-pago/dialog-registrar-pago.component'
import { DialogRepactarCuotasComponent } from './abogados-ingresos/abogados-ingresos-acciones/dialog-repactar-cuotas/dialog-repactar-cuotas.component'
import { DialogContratosComponent } from './abogados-ingresos/abogados-ingresos-acciones/dialog-contratos/dialog-contratos.component'
import { DialogMostrarContratosComponent} from './abogados-ingresos/abogados-ingresos-acciones/dialog-mostrar-contratos/dialog-mostrar-contratos.component'

import { Empresa } from '@models/shared/empresa'
import { EgresoFirmaCuota } from '@app/_models/abogados/egresoFirmaCuota'
import { AbogadosEgresosCuotaDialogComponent } from './abogados-egresos/abogados-egresos-list/abogados-egresos-cuotas/abogados-egresos-cuota-dialog/abogados-egresos-cuota-dialog.component'
import { AbogadosEgresosCuotasComponent } from './abogados-egresos/abogados-egresos-list/abogados-egresos-cuotas/abogados-egresos-cuotas.component'
import { ConsolidadosAbogados } from '@app/_models/abogados/consolidados-abogados'
import { AbogadosEgresosEditComponent } from './abogados-egresos/abogados-egresos-list/abogados-egresos-edit/abogados-egresos-edit.component'
import { FAbogadosEgresosAccionesComponent } from './abogados-egresos/f-abogados-egresos-table/f-abogados-egresos-acciones/f-abogados-egresos-acciones.component'
import { map } from 'rxjs/operators'
import { UsuarioSharedService } from '../shared/shared-services/usuario-shared.service'
import { SucursalSharedService } from '../shared/shared-services/sucursal-shared.service'
import { IngresosAbogado } from '@app/_models/abogados/ingresosAbogado'
import { AbogadoIngresosAccionesComponent } from './abogados-ingresos/abogados-ingresos-tabla/abogado-ingresos-acciones/abogado-ingresos-acciones.component'
import { EgresosRentacar } from '@app/_models/rentacar/egresoRentacar'
import { EgresoAbogados } from '@app/_models/abogados/egresoAbogados'
import { FormGroup } from '@angular/forms';
import { Cliente } from '@app/_models/abogados/cliente';
import { AbogadosTabsService } from './abogados-tabs.service';
import { first } from 'rxjs/operators';
import { DialogContratosAccionesComponent } from '../abogados/abogados-ingresos/abogados-ingresos-acciones/dialog-mostrar-contratos/dialog-mostrar-contrato-acciones/dialog-mostrar-contrato-acciones.component';
import { Subject } from 'rxjs';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'
@Injectable({
  providedIn: 'root'
})
export class AbogadosService {
  // PUBLIC VALUES
  public tiposIngresosList: Observable<string[]>
  public tiposClientesList: Observable<string[]>
  public referenciasList: Observable<string[]>
  public tiposPagosList: Observable<string[]>
  public estadosPagosList: Observable<string[]>
  //EGRESOS VALUES
  public tiposEgresosList: Observable<string[]>
  private contratoGeneradoSource = new Subject<void>();

  // PRIVATE VALUES

  //INGRESOS VALUES
  private tiposIngresosListSubject: BehaviorSubject<any[]>
  private tiposClientesListSubject: BehaviorSubject<string[]>
  private referenciasListSubject: BehaviorSubject<string[]>
  private tiposPagosListSubject: BehaviorSubject<string[]>
  private estadosPagosListSubject: BehaviorSubject<string[]>
  //EGRESOS VALUES
  private tiposEgresosListSubject: BehaviorSubject<string[]>

  // STRICT LISTS
  private tiposIngresos = [
    'Alojamiento',
    'Desayuno',
    'Almuerzo',
    'Cena',
    'Consumo Bebidas',
    'Consumo Varios'
  ]
  private tiposClientes = ['Particular', 'Empresa']
  private referencias = [
    'Llamada',
    'Booking',
    'Correo',
    'PaginaWeb',
    'Facebook'
  ]
  private tiposPagos = [
    'Efectivo',
    'Debito',
    'Credito',
    'Transferencia',
    'Cheque'
  ]
  private estadosPagos = ['PENDIENTE', 'PAGADO']

  private tiposEgresos = [
    'Gastos',
    'Costos',
    'Remuneraciones',
    'Impuestos',
    'Bancarios'
  ]
  private empresa = 'FirmaAbogado'
  constructor(
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioSharedService,
    private sucursalService : SucursalSharedService,
    private abogadosTabsService: AbogadosTabsService
    
  ) {
    //INGRESOS

    this.tiposIngresosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tiposIngresos')!)
    )
    this.tiposClientesListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tipos_clientes')!)
    )
    this.referenciasListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('referencias')!)
    )
    this.tiposPagosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tipos_pagos')!)
    )
    this.estadosPagosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('estadosPagos')!)
    )
    //EGRESOS
    this.tiposEgresosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tiposEgresos')!)
    )

    // PUBLIC STATES:
    //INGRESOS
    this.tiposIngresosList = this.tiposIngresosListSubject.asObservable()
    localStorage.setItem('tiposIngresos', JSON.stringify(this.tiposIngresos))

    this.tiposClientesList = this.tiposClientesListSubject.asObservable()
    localStorage.setItem('tipos_clientes', JSON.stringify(this.tiposClientes))

    this.referenciasList = this.referenciasListSubject.asObservable()
    localStorage.setItem('referencias', JSON.stringify(this.referencias))

    this.tiposPagosList = this.tiposPagosListSubject.asObservable()
    localStorage.setItem('tipos_pagos', JSON.stringify(this.tiposPagos))

    this.estadosPagosList = this.estadosPagosListSubject.asObservable()
    localStorage.setItem('estadosPagos', JSON.stringify(this.estadosPagos))
    //EGRESOS
    this.tiposEgresosList = this.tiposEgresosListSubject.asObservable()
    localStorage.setItem('tiposEgresos', JSON.stringify(this.tiposEgresos))
  }

  CreateOneIngreso(IngresosAbogado: IngresosAbogado) {
    return this.http.post(
      `${environment.apiUrl}/ingresoFirma/`,
      IngresosAbogado
    )
  }

  getBancos() {
    return this.http.get<any>(
      `${environment.apiUrl}/banco/obtenerBancos/`
    )
  }

  updateIngresoAbogado(
    id: number,
    ingresosAbogado: IngresosAbogado
  ): Observable<IngresosAbogado> {
    return this.http.put<IngresosAbogado>(
      `${environment.apiUrl}/ingresoFirma/updateIngreso/${id}`,
      ingresosAbogado
    )
  }

  getIngresoAbogadoByIdImage(id: any) {
    return this.http.get(
      `${environment.apiUrl}/ingresoFirma/getIngresoAbogadoByIdImage/${id}`
    )
  }

  getAllIngresos() {
    return this.http.get(`${environment.apiUrl}/ingresoFirma`)
  }

  getIngresoAbogadoById(id: number) {
    return this.http.get(`${environment.apiUrl}/ingresoFirma/${id}`)
  }

  /* openDialogEditIngresoAbogado(): void {
    const dialogRef = this.dialog.open(AbogadoIngresosAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  } */
    openDialogEditIngresoAbogado(): void {
      const dialogRef = this.dialog.open(AbogadoIngresosAccionesComponent, {})
      dialogRef.afterClosed().subscribe((res) => {})
    }
    openDialogIngresoAbogadoContrato(): void {
      const dialogRef = this.dialog.open(DialogMostrarContratosComponent, {})
      dialogRef.afterClosed().subscribe((res) => {})
    }
    openDialogEditIngresoAbogadocontrato(): void {
      const dialogRef = this.dialog.open(DialogContratosAccionesComponent, {})
      dialogRef.afterClosed().subscribe((res) => {})
    }
    openDialogEditIngresoAbogadocontratoB(fromCuotas: boolean): void {
      const dialogRef = this.dialog.open(DialogContratosAccionesComponent, {
        data: { cuotavalidator: fromCuotas }
      });
  
      dialogRef.afterClosed().subscribe((res) => {
        // Manejar el cierre del diálogo si es necesario
      });
    }
     
    

  // Metodo que permite abrir un Dialog (Modal)
  openDialogRegistrarPago(idContrato: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idContrato != null) {
      const dialogRef = this.dialog.open(DialogRegistrarPagoComponent, {})
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open('Por favor seleccione un contrato', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogContratos(): void {
    const dialogRef = this.dialog.open(DialogContratosComponent, {})
    dialogRef.afterClosed().subscribe((res) => {
      console.log(res)
    })
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogRepactarCuotas(idContrato: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idContrato != null) {
      const dialogRef = this.dialog.open(DialogRepactarCuotasComponent, {})
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open('Por favor seleccione un contrato', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }
  formateaRut(rut: string): string {
    let value = rut.replace(/\./g, '').replace('-', '');
    if (value.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
      value = value.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
    } else if (value.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
      value = value.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, '$1.$2.$3-$4');
    } else if (value.match(/^(\d)(\d{3})(\d{0,2})$/)) {
      value = value.replace(/^(\d)(\d{0,2})$/, '$1.$2.$3');
    } else if (value.match(/^(\d)(\d{0,2})$/)) {
      value = value.replace(/^(\d)(\d{0,2})$/, '$1.$2');
    }
    return value;
  }
  buscarPorRut(rut: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cliente/rut/${rut}`);
}
  buscarPorId(id: string): Observable<any> {
   return this.http.get(`${environment.apiUrl}/cliente/id/${id}`);
}   
  
subirImagenContrato(formData: FormData): Observable<any> {
  return this.http.post(`${environment.apiUrl}/contratos/subir-imagen`, formData);
}
getBrowser(): string {
  const userAgent = navigator.userAgent;

  if (userAgent.indexOf('Firefox') > -1) {
      return 'Firefox';
  } else if (userAgent.indexOf('SamsungBrowser') > -1) {
      return 'Samsung Internet';
  } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
      return 'Opera';
  } else if (userAgent.indexOf('Trident') > -1) {
      return 'Internet Explorer';
  } else if (userAgent.indexOf('Edge') > -1) {
      return 'Microsoft Edge';
  } else if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1) {
      return 'Chrome';
  } else if (userAgent.indexOf('Safari') > -1) {
      return 'Safari';
  } else {
      return 'Unknown';
  }
}


  buscarExistencia(
    cliente: Cliente,
    usuarioId: string,
    formGroup: FormGroup,
    setElPadreDice: (data: any) => void,
    setIdCliente: (id: string) => void,
    setCausasCliente: (contratos: any) => void
  ): void {
    cliente.idUsuario = usuarioId;
    this.abogadosTabsService
      .crearSinoExiste(cliente.rut, cliente)
      .pipe(first())
      .subscribe((x: any) => {
        setElPadreDice(x);
        const idCliente = x.cliente.id;
        setIdCliente(idCliente);
        localStorage.setItem("idCliente", idCliente);
        localStorage.setItem("nombreCliente", x.cliente.nombre);

        this.abogadosTabsService
          .obtenerContratosCliente(idCliente)
          .subscribe((contratos: any) => {
            setCausasCliente(contratos);
          });

        if (!x.created) {
          formGroup.controls.nombreCliente.setValue(x.cliente.nombre);
          formGroup.controls.email.setValue(x.cliente.email);
          formGroup.controls.telefono.setValue(x.cliente.fono);
          formGroup.controls.direccion.setValue(x.cliente.direccion);
          this.snackBar.open('El Usuario ya existe, confirme la informacion o actualice los datos', 'cerrar', {
            duration: 2000,
            verticalPosition: 'top',
          });
        } else {
          this.snackBar.open('El Usuario no existe, complete la informacion y actualice los datos', 'cerrar', {
            duration: 2000,
            verticalPosition: 'top',
          });
        }
      });
  }

  contratoGenerado$ = this.contratoGeneradoSource.asObservable();
  
  emitirContratoGenerado() {
    this.contratoGeneradoSource.next();
  }








  // METODO PARA EXPORTAR EXCEL
  public async exportAsExcelFile(
    json: any[],
    excelFileName: string,
    selectedColumns: string[]
  ): Promise<void> {
    const columnMappings = {
      // id: 'ID',
      // tipoEgreso: 'Tipo de Egreso',
      // fecha: 'Fecha',
      // monto: 'Monto',
      // //responsable: 'Responsable',
      // //descripcion: 'Descripcion',
      // //tipoPago: 'Tipo de Pago',
      // //sucursal: 'Sucursal',
      // //idUsuario: 'ID Usuario'
      id: 'ID',
      tipoEgreso:'Tipo Egreseo',
      fecha: 'Fecha',
      monto: 'Monto',
      responsable:'Responsable',
      descripcion:'Descripcion',
      tipoPago:'Tipo de Pago',
      nDocumento: 'N° Documento',
      cliente: 'Cliente',
      idSucursal: 'Sucursal',
      sucursal:'Sucursal',
      usuario: 'Nombre Usuario'
    }

    const newData = await Promise.all(
      json.map(async (item) => {
        const newItem: any = {}
        for (const col of selectedColumns) {
          if (col === 'idSucursal') {
            if (item[col] !== null) {
              try {
                const sucursal = await this.sucursalService
                  .getById(item[col])
                  .toPromise()
                newItem[columnMappings[col]] = sucursal.razonSocial
              } catch (error) {
                console.error(
                  'Error al obtener el nombre de la sucursal:',
                  error
                )
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else if (col === 'idUsuario') {
            if (item[col] !== null) {
              try {
                const usuario = await this.usuarioService
                  .getById(item[col])
                  .toPromise()
                newItem[columnMappings[col]] = usuario.id
              } catch (error) {
                console.error('Error al obtener el nombre del usuario:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[col] = ''
            }
          } else if (col === 'fecha') {
            if (item[col] !== null) {
              try {
                const fecha = new Date(item[col])
                const formattedFecha = `${fecha.getDate()}/${
                  fecha.getMonth() + 1
                }/${fecha.getFullYear()}`
                newItem[columnMappings[col]] = formattedFecha
              } catch (error) {
                console.error('Error al obtener la fecha:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else {
            newItem[columnMappings[col]] = item[col]
          }
        }

        return newItem
      })
    )

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + 'export' + new Date().getTime() + EXCEL_EXTENSION
    )
  }

  //INGRESOS VALUES GET METHODS:
  public get tiposIngresosListValue(): string[] {
    return this.tiposIngresosListSubject.value
  }
  public get tiposClientesListValue(): string[] {
    return this.tiposClientesListSubject.value
  }
  public get referenciasListValue(): string[] {
    return this.referenciasListSubject.value
  }
  public get tiposPagosListValue(): string[] {
    return this.tiposPagosListSubject.value
  }
  public get estadosPagosListValue(): string[] {
    return this.estadosPagosListSubject.value
  }
  //EGRESOS VALUES GET METHODS:
  public get tiposEgresosListValue(): string[] {
    return this.tiposEgresosListSubject.value
  }

  /** Consolidados */
  buscarConsolidado(consolidado: ConsolidadosAbogados): any {
    return this.http.post(
      `${environment.apiUrl}/egresoFirma/obtenerEgreso`,
      consolidado
    )
  }

  /* EGRESOS */
  egresoRegistrar(egresosFirma: RegistroEgresoFirma) {
    console.log(egresosFirma)
    return this.http.post(`${environment.apiUrl}/egresoFirma`, egresosFirma)
  }
  egresoGetAll(): any {
    return this.http.get<[]>(`${environment.apiUrl}/egresoFirma/`)
  }

  buscarCuotas(): any {
    return this.http.get<EgresoFirmaCuota>(
      `${environment.apiUrl}/egresoFirmaCuota/`
    )
  }

  getByIdWithSucursales(id: number) {
    return this.http.get<Empresa>(
      `${environment.apiUrl}/empresa/empresaSucursales/${id}`
    )
  }
  egresoGetFiles(fileName: string): any {
    return this.http
      .get(`${environment.apiUrl}/egresoFirma/download/${fileName}`, {
        responseType: 'blob'
      })
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }
  


  buscarImagen(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(`${environment.apiUrl}/egresoFirma/download/${url}`, {
      responseType: 'blob'
    })
  }
  /** metodo actualizar datos editados de una abogados */
  updateFirmaEgreso(id: any, params: any[]) {
    return this.http.put(`${environment.apiUrl}/egresoFirma/${id}`, params)
  }

  getEgreso(id: string): any {
    return this.http.get<any>(`${environment.apiUrl}/egresoFirma/${id}`)
  }

  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idContratoPago')
  }

  /* Egresos Por cuotas */
  getCuotas(id: any) {
    return this.http.get<EgresoFirmaCuota>(
      `${environment.apiUrl}/egresoFirmaCuota/${id}`
    )
  }

  buscarImagenCuota(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoFirmaCuota/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  agregarRespaldos(arrayRespaldos: any): any {
    return this.http.post(
      `${environment.apiUrl}/egresoFirmaCuota/agregarRespaldos/`,
      arrayRespaldos
    )
  }
  buscarImagenC(id: any): any {
    return this.http.get<EgresoFirmaCuota>(
      `${environment.apiUrl}/respaldoEgresoFirmaCuota/${id}`
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogCuota(): void {
    const dialogRef = this.dialog.open(AbogadosEgresosCuotaDialogComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  // Metodo que permite abrir un Dialog (Modal)--edit
  openDialogEditEgreso(): void {
    const dialogRef = this.dialog.open(AbogadosEgresosEditComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }
  updateMonto(id: any, body: any[]) {
    return this.http.put(`${environment.apiUrl}/egresoFirmaCuota/${id}`, body)
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogRegistrarPagoCuota(idEgreso: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idEgreso != null) {
      const dialogRef = this.dialog.open(AbogadosEgresosCuotasComponent, {})
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open(
        'Por favor seleccione un egreso con cuotas sin pagar',
        'cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }

  deleteEgreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/egresoFirma/${id}`)
  }

  getByIdEgreso(id: any) {
    console.log(id)
    return this.http.get<any>(
      `${environment.apiUrl}/egresoFirma/oneGetEgresoFirma/${id}`
    )
  }

  //Editar egreso
  updateEgresoFirma(id: any, params: any[]) {
    console.log(id, params)
    return this.http.put(
      `${environment.apiUrl}/egresoFirma/UpdateForm/${id}`,
      params
    )
  }

  getByIdEgresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoFirma/sinBase64/${id}`
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditEgresoFirma(): void {
    const dialogRef = this.dialog.open(FAbogadosEgresosAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  createGastoFijos(gastos: GastosFijos): any {
    console.log('ruta', gastos)
    return this.http.post(`${environment.apiUrl}/gastosFijos`, gastos)
  }

  getGastoFijos(): Observable<GastosFijos[]> {
    return this.http.get<GastosFijos[]>(`${environment.apiUrl}/gastosFijos`)
  }

  openDialogGastoFijo(detallesGasto: any) {
    const dialogRef = this.dialog.open(AbogadosGastosFijosEditComponent, {
      width: '1000px',
      height: '500px',
      data: detallesGasto
    })

    dialogRef.afterClosed().subscribe((result) => {})
  }
  getByIdGastoFijo(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/gastosFijos/getIDSinBase64/${id}`
    )
  }
  getByIdGastoFijoImagen(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/gastosFijos/oneGetGastoFijos/${id}`
    )
  }
  updateGastoFijos(id: any, data: any) {
    return this.http.put(
      `${environment.apiUrl}/gastosFijos/updateGastoFijos/${id}`,
      data
    )
  }
  createCredito(egresoAbogados: EgresoAbogados): any {
    try {
      return this.http.post(
        `${environment.apiUrl}/egresoFirma/createEgresoHome/`,
        egresoAbogados
      )
    } catch (error) {
      console.log(error)
      return error
    }
  }
  //Métodos para gestionar transacciones webpay
  pagar(idCuota: number, monto: number, empresa: string) {
    let body = {
      idCuota,
      monto,
      empresa
    }
    return this.http.post(`${environment.apiUrl}/egresoFirma/pagar`, body)
  }
  confirmar(token: string) {
    console.log('HostalService token: ', token)
    let body = { token }
    console.log('Antes de hhtp post')
    return this.http.post(`${environment.apiUrl}/egresoFirma/confirmar`, body)
  }
  actualizarPagado(id: number): any {
    let body = {
      id
    }
    return this.http.post(
      `${environment.apiUrl}/egresoFirmaCuota/actualizarPagado/`,
      body
    )
  }
}
