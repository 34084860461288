import { Sucursal } from '../shared/sucursal';
import { Usuario } from '../shared/usuario';

export class RegistroEgresoFirma {
  id!: string;
  fecha!: Date;
  monto!: number;
  idSucursal!: number;
  idUsuario!: number;
  tipoEgreso!: string;
  descripcion!: string;
  Sucursal!: Sucursal;
  Usuario!: Usuario;
  RespaldoEgresosFirma!: any[];
  responsable!: string;
  usuario!: string;
  sucursal!: string;
  fechaf!: string;
  numeroCuota!: string;
  tipoPago!: string;
  grupoGastos!: string;
  archivos!:any;
  banco!: string;
  numeroCuenta!: string
  gastosFijos!: string
  proyecto: String;
  region: String;  
  }
