<mat-card class="shipping-card">
  <mat-expansion-panel hideToggle="">
    <mat-expansion-panel-header>
      <mat-panel-title>Ingreso Hostal! </mat-panel-title>
      <mat-panel-description>Presiona Aquí!</mat-panel-description>
      <mat-icon>touch_app_white_18dp</mat-icon>
    </mat-expansion-panel-header>
    <mat-horizontal-stepper>
      <mat-step [stepControl]="addressForm" completed="false">
        <mat-card-content>

          <form [formGroup]="addressForm">

            <!-- Fila 1 -->
            <div class="row">
              <!-- select fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-label>Seleccionar fecha</mat-label>
                  <input
                    matInput
                    formControlName="fecha"
                    [matDatepicker]="picker"
                    (click)="picker.open()"
                    [readonly]="true"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    *ngIf="addressForm.controls['fecha'].hasError('required')"
                  >
                    fecha es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>



              <!-- sucursales -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Sucursales"
                    formControlName="idSucursal"
                  >
                    <mat-option
                      *ngFor="let sucursal of sucursales"
                      [value]="sucursal.id"
                    >
                      {{ sucursal.razonSocial }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['idSucursal'].hasError('required')
                    "
                  >
                    sucursal es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>


                <!-- checkbox tipoIngreso
                <div class="col" id="checkbox">
                  <div class="tituloTipoServicio"> Tipo de Servicio
                    <hr>
                  </div>
                  <section class="example-section">
                    <mat-checkbox
                      *ngFor="let tipo of defTiposIngreso"
                      class="example-margin"
                      [name]="tipo"
                      (change)="chequearTipoIngreso($event)"
                    >
                      {{ tipo }}
                    </mat-checkbox>
                  </section> -->
                <div class="col" id="checkbox">
                <div class="tituloTipoServicio"> Tipo de Servicio
                  <hr>
                </div>
                <section class="example-section">
                 <mat-checkbox *ngFor="let tipo of defTiposServicios" class="example-margin" [name]="tipo"
                    (change)="chequearTiposServicios($event)">
                    {{ tipo }}
                  </mat-checkbox>
                </section>
              
              </div>

            </div>

            <!-- Fila 2 -->
            <div class="row">
              <!-- cliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Nombre Cliente"
                    formControlName="cliente"
                  />
                  <mat-error
                    *ngIf="addressForm.controls['cliente'].hasError('required')"
                  >
                    cliente es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- tipo cliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Cliente"
                    formControlName="tipoCliente"
                  >
                    <mat-option
                      *ngFor="let tipo of tiposCliente"
                      [value]="tipo"
                    >
                      {{ tipo }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoCliente'].hasError('required')
                    "
                  >
                    Tipo_Cliente es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- referencia -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Referencia"
                    formControlName="referencia"
                  >
                    <mat-option
                      *ngFor="let referencia of referencias"
                      [value]="referencia"
                    >
                      {{ referencia }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['referencia'].hasError('required')
                    "
                  >
                    referencia es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 3 -->
            <div class="row">

              <!-- monto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Monto"
                    formControlName="monto"
                    type="text"
                    [ngModel]="result2"
                    (ngModelChange)="transform($event)"
                    (keypress)="restrictNumeric($event)"
                  />
                  <mat-error
                    *ngIf="addressForm.controls['monto'].hasError('required')"
                  >
                    Monto es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- numero documento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="N° documento"
                    formControlName="nDocumento"
                  />
                  <mat-error
                    *ngIf="
                      addressForm.controls['nDocumento'].hasError('required')
                    "
                  >
                    este campo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- estado pago
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="N° documento"
                    formControlName="nDocumento"
                  />
                  <mat-error
                    *ngIf="
                      addressForm.controls['nDocumento'].hasError('required')
                    "
                  >
                    este campo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div> -->
              <!-- monto
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Monto"
                    formControlName="monto"
                    type="text"
                    [ngModel]="result2"
                    (ngModelChange)="transform($event)"
                    (keypress)="restrictNumeric($event)"
                  />
                  <mat-error
                    *ngIf="addressForm.controls['monto'].hasError('required')"
                  >
                    Monto es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div> -->

              <!-- tipo pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Pago"
                    formControlName="tipoPago"
                  >
                    <mat-option
                      *ngFor="let tipoPago of tiposPagos"
                      [value]="tipoPago"
                    >
                      {{ tipoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    "
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>


               <!-- Tipo banco   -->
              <div class="col">
                <div *ngIf="addBanc">
                  <mat-form-field appearance="legacy" class="full-width">
                    <mat-label>Seleccionar Banco</mat-label>
                    <mat-select formControlName="banco">
                      <mat-option *ngFor="let banco of bancos" [value]="banco.NombreInstitucion"> {{banco.NombreInstitucion}} </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>account_balance_18dp</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <!-- ncuenta -->
              <div class="col">
                <div *ngIf="addBanc">
                  <mat-form-field class="full-width">
                    <mat-label>N° Cuenta corriente/Tarjeta</mat-label>
                    <input matInput placeholder="XXXX/4 Últimos Dígitos" formControlName="numeroCuenta" type="text" />
                    <mat-error *ngIf="addressForm.get('numeroCuenta').hasError('required')">
                      Este campo es obligatorio.
                    </mat-error>
                    <mat-error *ngIf="addressForm.get('numeroCuenta').hasError('pattern')">
                      Debe ingresar los últimos dígitos de la tarjeta/cuenta
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            

            <div class="row">

              <!-- descripcion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="ingrese una descripcion del registro"
                    formControlName="descripcion"
                  />
                  <mat-error
                    *ngIf="
                      addressForm.controls['descripcion'].hasError('required')
                    "
                  >
                    descripcion es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Numero Autorizacion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="N° Autorizacion"
                    formControlName="nAutorizacion"
                  />
                  <mat-error
                    *ngIf="
                      addressForm.controls['nAutorizacion'].hasError('required')
                    "
                  >
                    este campo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Tipo Ingreso -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Ingreso"
                    formControlName="tipoIngreso"
                  >
                    <mat-option value="Venta"> Venta </mat-option>
                    <mat-option value="Arriendo"> Arriendo </mat-option>
                    <mat-option value="Licitacion"> Licitacion </mat-option>
                    <mat-option value="Otro"> Otro </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoIngreso'].hasError('required')
                    "
                  >
                    tipo ingreso es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Tipo Documento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Documento"
                    formControlName="tipoDocumento"
                  >
                    <mat-option value="Factura"> Factura </mat-option>
                    <mat-option value="Boleta"> Boleta </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoDocumento'].hasError('required')
                    "
                  >
                    este campo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <hr class="separador" />

            <!-- Fila 5 Img -->
            <div class="row">
              <div class="col">
                <input
                  mat-stroked-button
                  (change)="capturarFile($event)"
                  type="file"
                />
                <br />
                <hr class="separador" />
                <div class="row">
                  <!-- Preview img -->
                  <div class="col" *ngIf="previsualizacion">
                    <img width="400px" [src]="previsualizacion" alt="img" />
                  </div>
                </div>
                <!-- Boton de accion -->
                <div>
                  <button
                    mat-stroked-button
                    color="primary"
                    type="submit"
                    [disabled]="loading"
                    
                    [disabled]="clickButton"
                    (click)="onSubmit()"
                  >
                    {{ loading ? 'Cargando....' : 'Guardar' }}
                    <mat-icon>send</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-expansion-panel>
</mat-card>
