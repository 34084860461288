
export class Empresa {
  id!: number;
  razonSocial!: string;
  rut!: string;
  descripcion!: string;
  giro!: string;
  actividad!: string;
  direccion!: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Sucursals!: any[];
}
