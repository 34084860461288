import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { Sucursal } from '@app/_models/shared/sucursal'
import { AgroFirmaService } from '@app/_pages/agroFirma/agro-firma.service'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-agro-firma-egresos-edit',
  templateUrl: './agro-firma-egresos-edit.component.html',
  styleUrls: ['./agro-firma-egresos-edit.component.scss']
})
export class AgroFirmaEgresosEditComponent implements OnInit {
  idAgroFirma: any
  fecha: any
  monto: any
  responsable: any
  tipoEgreso: any

  /**  Configuración de formulario  */
  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    monto: ['', Validators.required],
    responsable: ['', Validators.required],
    tipoEgreso: ['', Validators.required],
    tipoPago: ['', Validators.required]
  })

  sucursales: Sucursal[]
  tiposEgresos: string[] = []
  tiposPagos: any[] = []

  constructor(
    private fb: FormBuilder,
    private sucursalService: SucursalSharedService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private administracionService: AdministracionService,
    private agrofirmaService: AgroFirmaService
  ) {
    this.sucursales = this.sucursalService.sucursalListValue
    this.tiposPagos = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Cheque',
      'Pac'
    ]
    this.tiposEgresos = [
      'Gastos',
      'Costos',
      'Remuneraciones',
      'Impuestos',
      'Bancarios'
    ]
  }

  ngOnInit(): void {
    this.idAgroFirma = localStorage.getItem('idEgresoAgroFirma')
    this.getEgresoAgroFirma()
  }

  getEgresoAgroFirma() {
    try {
      this.agrofirmaService
        .obtenerEgreso(this.idAgroFirma)
        .pipe(first())
        .subscribe((x: any) => {
          if (x.id == this.idAgroFirma) {
            this.f.fecha.setValue(x.fecha)
            this.f.monto.setValue(x.monto)
            this.f.responsable.setValue(x.responsable)
            this.f.tipoEgreso.setValue(x.tipoEgreso)
            this.f.tipoPago.setValue(x.tipoPago)
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  get f() {
    return this.addressForm.controls
  }

  onSubmit() {
    switch (this.addressForm.status) {
      case 'VALID':
        this.agrofirmaService
          .updateAgroFirmaEgreso(this.idAgroFirma, this.addressForm.value)
          .pipe(first())
          .subscribe(
            (data) => {
              this.snackBar.open('Lubricentro editada con éxito.', 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })

              this.addressForm.reset()
              this.agrofirmaService.closeDialogModal()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo editar la lubricentro, favor contactar a informática.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
            }
          )
        break

      case 'INVALID':
        this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }
  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
  }
}
