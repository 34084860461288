<!-- <mat-card class="open-card" (click)="showFullContent = !showFullContent">
    <mat-card-title>Folios restantes: {{nombreDTE}}</mat-card-title>
    <mat-card-content>
        <div class="spoiler">
            <div class="content" [ngClass]="{ 'expanded': showFullContent, 'gradient-box': !showFullContent }">
                <mat-chip-list class="single-line-chip-list">
                    <mat-chip *ngFor="let empresa of empresasFolios" [color]="empresa.color" selected>
                        <span>
                            <b>{{empresa.nombre}}:</b> {{empresa.folioRestante ? empresa.folioRestante : 'Sin folios'}}
                        </span>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </mat-card-content>
</mat-card> -->
<h1>Disponibilidad de Folios por Empresa</h1>
<div class=" empresa-container">
  
  <div *ngFor="let empresa of empresasFolios" class="empresa-card" [ngClass]="{ 'con-folios': empresa.folioRestante, 'sin-folios': !empresa.folioRestante }">
    <div class="empresa-info">
      <span class="empresa-nombre">{{empresa.nombre}}</span>
      <span class="empresa-folios">
        <ng-container *ngIf="empresa.folioRestante">
          <mat-icon color="primary">check_circle</mat-icon> {{empresa.folioRestante}}
        </ng-container>
        <ng-container *ngIf="!empresa.folioRestante">
          <mat-icon color="warn">cancel</mat-icon> Sin folios
        </ng-container>
      </span>
    </div>
  </div>
</div>