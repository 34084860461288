import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { modeloCuentasBancaria } from '@app/_models/consolidadoBancario/cuentasBancaria'
import { Chart } from 'chart.js'
import { ConsolidadoBancarioService } from '../../consolidado-bancario.service'

@Component({
  selector: 'app-pago-graf',
  templateUrl: './pago-graf.component.html',
  styleUrls: ['./pago-graf.component.scss']
})
export class PagoGrafComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()
  @ViewChild(MatSort) sort = null

  @ViewChild('barChartDona', { static: false }) private chartRef1!: ElementRef

  modelo: modeloCuentasBancaria = new modeloCuentasBancaria()
  DatosEstado = ['Pendiente', 'Aceptada']
  DatosTotalAceptada = []
  DatosTotalPendiente = []
  DatosCuenta = []
  chartEstado: any
  chartCuentas: any

  //Grfica de cuentas
  DatosCuentaAceptada = []
  DatosCuentaPendiente = []

  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService
  ) {}

  ngOnInit(): void {
    this.getAllEstadoPago()
    this.getAllCuentas()
  }

  getAllEstadoPago() {
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (data: any) => {
        data.forEach((element) => {
          if (element.estado === 'Pendiente') {
            this.DatosTotalPendiente.push(element.montoPago)
          }
          if (element.estado === 'Aceptada') {
            this.DatosTotalAceptada.push(element.montoPago)
          }
        })
        const TotalAceptada = this.DatosTotalAceptada.reduce(
          (prev, curr) => prev + curr,
          0
        )
        const TotalPendiente = this.DatosTotalPendiente.reduce(
          (prev, curr) => prev + curr,
          0
        )

        setTimeout(() => {
          const data = {
            labels: this.DatosEstado,
            datasets: [
              {
                label: 'My First Dataset',
                data: [TotalPendiente, TotalAceptada],
                backgroundColor: [
                  'rgb(255, 99, 132)',
                  'rgb(54, 162, 235)',
                  'rgb(255, 205, 86)',
                  'rgb(204, 51, 153)',
                  'rgb(151, 2, 2)',
                  'rgb(178, 84, 222)',
                  'rgb(84, 130, 222)',
                  'rgb(84, 199, 222)',
                  'rgb(84, 222, 144)',
                  'rgb(192, 222, 84)'
                ],
                hoverOffset: 4
              }
            ]
          }
          this.chartEstado = new Chart('myChart1', {
            type: 'pie',
            data: data
          })
        }, 50)
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  getAllCuentas() {
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (data: any) => {
        data.forEach((element) => {
          if (element.estado === 'Pendiente') {
            this.DatosCuentaPendiente.push(element.montoPago)
          }
          if (element.estado === 'Aceptada') {
            this.DatosCuentaAceptada.push(element.montoPago)
          }
          this.DatosCuenta.push(element.NumeroCuenta)
        })
        setTimeout(() => {
          const data = {
            labels: this.DatosCuenta,
            datasets: [
              {
                label: 'Pago Cuenta Aceptada',
                data: this.DatosCuentaAceptada,
                fill: true,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgb(54, 162, 235)',
                pointBackgroundColor: 'rgb(54, 162, 235)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(54, 162, 235)'

              },
              {
                label: 'Pago Cuenta Pendiente',
                data: this.DatosCuentaPendiente,
                fill: true,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgb(255, 99, 132)',
                pointBackgroundColor: 'rgb(255, 99, 132)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(255, 99, 132)'

              }
            ]
          }
          this.chartEstado = new Chart('myChart2', {
            type: 'radar',
            data: data
          })
        }, 50)
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }
}
