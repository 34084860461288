import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ConfiguracionHomeComponent } from "./configuracion-cesionarios/configuracion-home/configuracion-home.component";

const routes: Routes = [
    { path: 'cesionarios', component: ConfiguracionHomeComponent },
  ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })

export class ConfiguracionRoutingModule{}