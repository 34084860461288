// TODO  agregar atributos de la entidad
export class ConsolidadosHostal {
    consolidados!: string;
    sucursales!: string;    
    tramos!: string;
    month!: string;
    year!: number;
    fechaI!: Date; 
    fechaF!: Date;
    trimestre!: string;
    semestre!: string;
    idEmpresa!: number;
}