import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Boletas } from '@app/_models/boletas/boletas';
import { AdministracionService } from '@app/_pages/administracion/administracion.service';
import { ProductosService } from '@app/_pages/productos/productos.service';
import { first } from 'rxjs/operators';
import { GuiaDespachoService } from '../../guia-despacho.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EmpresasSimpleapiService } from '@app/_pages/shared/shared-services/empresas-simpleapi.service'


@Component({
  selector: 'app-despacho-acciones',
  templateUrl: './despacho-acciones.component.html',
  styleUrls: ['./despacho-acciones.component.scss']
})
export class DespachoAccionesComponent implements OnInit {

  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any

  idFactura: any
  cliente: any
  direccion: any
  ciudad: any
  comuna: any
  contacto: any
  plazoEntrega: any
  vendedor: any
  estado: any
  folio: any
  tipoDocumento: any
  fechaCreacion: any
  valida: any
  formaPago: any
  moneda: any
  observacionesPago: any
  ordenAlfabetico: any
  comentarios: any
  respTotal: any
  iva: any
  totalGeneral: any
  data: any
  pdfUrl: SafeResourceUrl | null = null;
  rutEmisor: string;
  verPDF: boolean = false;
  guia: any;
  //Modelo de Boletas
  modelo: Boletas = new Boletas()

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private despachoService: GuiaDespachoService,
    private _productosService: ProductosService,
    private sanitizer: DomSanitizer,
    private _empresaListService : EmpresasSimpleapiService,
  ) {}

  ngOnInit(): void {
    this.idFactura = localStorage.getItem('idBoleta')
    this.getDespacho()
    this.getProductosFactura()
  }

  ////----------------------------Cambiar estado ------
  //Configuracion de formulario
  addressForm = this.fb.group({
    bodega: null,
    cliente: null,
    direccion: null,
    ciudad: null,
    comuna: null,
    contacto: null,
    plazoEntrega: null,
    vendedor: null,
    estado: null,
    folio: null,
    tipoDocumento: null,
    fechaCreacion: null,
    valida: null,
    formaPago: null,
    moneda: null,
    observacionPago: null,
    ordenAlfabetico: null,
    comentarios: null,
    respTotal: null,
    iva: null,
    totalGeneral: null,
    trackId: null,
    rutCliente: null,
    validaPago: null,
    razonRechazado: null,
    rutTransportista: null,
    rutChofer: null,
    nombreChofer: null,
    direccionDestino: null,
    comunaDestino: null,
    ciudadDestino: null,
    productos: this.fb.array([])
  })

  getProductosFactura() {
    this.despachoService
      .getById(this.idFactura)
      .pipe(first())
      .subscribe((data) => {
        data.forEach((element) => {
          this.descomponerArray = JSON.parse(element.productos)
        })
      })
  }

  get f() {
    return this.addressForm.controls
  }

  getDespacho() {
    this.despachoService
      .getById(this.idFactura)
      .pipe(first())
      .subscribe((x: any) => {
        console.log({x});
        if (x[0].id == this.idFactura) {
          this.rutEmisor = x[0].rutEmisor;
          this.guia = x[0];
          this.f.bodega.setValue(x[0].bodega)
          this.f.cliente.setValue(x[0].nombreCliente)
          this.f.rutCliente.setValue(x[0].rutCliente)
          this.f.direccion.setValue(x[0].direccion_cliente)
          this.f.ciudad.setValue(x[0].ciudad_cliente)
          this.f.comuna.setValue(x[0].comuna_cliente)
          this.f.contacto.setValue(x[0].contacto)
          this.f.plazoEntrega.setValue(x[0].plazoEntrega)
          this.f.vendedor.setValue(x[0].vendedor)
          this.f.estado.setValue(x[0].estado)
          this.f.folio.setValue(x[0].folio)
          this.f.tipoDocumento.setValue(x[0].tipoDocumento)
          this.f.fechaCreacion.setValue(x[0].fechaCreacion)
          this.f.valida.setValue(x[0].valida)
          this.f.formaPago.setValue(x[0].formaPago)
          this.f.moneda.setValue(x[0].moneda)
          this.f.observacionPago.setValue(x[0].observacionesPago)
          this.f.validaPago.setValue(x[0].valida_pago)
          // this.f.ordenAlfabetico.setValue(x[0].ordenAlfabetico)
          this.f.comentarios.setValue(x[0].comentarios)
          this.f.trackId.setValue(x[0].trackId)
          this.f.razonRechazado.setValue(x[0].razonRechazado)
          // this.f.respTotal.setValue(x[0].respTotal)
          // this.f.iva.setValue(x[0].iva)
          this.f.totalGeneral.setValue(x[0].totalGeneral.toString())
          this.f.rutTransportista.setValue(x[0].rutTransportista)
          this.f.rutChofer.setValue(x[0].rutChofer)
          this.f.nombreChofer.setValue(x[0].nombreChofer)
          this.f.direccionDestino.setValue(x[0].direccion_destino)
          this.f.comunaDestino.setValue(x[0].comuna_destino)
          this.f.ciudadDestino.setValue(x[0].ciudad_destino)

          // this.f.productos.setValue(x[0].productos)
        }
      })
  }

  CambiarEstado() {
    if (this.addressForm.value.estado === 'Pendiente') {
      this.addressForm.value.productos = this.descomponerArray
      this.addressForm.value.estado = 'Sobre de Envio'
      switch (this.addressForm.status) {
        case 'VALID':
          this.despachoService
            .updateDespacho(this.idFactura, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open(
                  'Generado sobre de envio con éxito.',
                  'Cerrar',
                  {
                    duration: 5000,
                    verticalPosition: 'top'
                  }
                )
                setTimeout(() => {
                  this.addressForm.reset()
                  //this.boletasService.closeDialogModal()
                  window.location.reload()
                }, 2100)
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo generar el sobre de envio, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break

        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    } else {
      if (this.addressForm.value.estado === 'Sobre de Envio') {
        this.snackBar.open(
          'No se puede generar sobre de envio, ya que este fue creado con esta factura',
          'Cerrar',
          {
            duration: 5000,
            verticalPosition: 'top'
          }
        )
      }
      if (this.addressForm.value.estado === 'Envio a SII') {
        this.snackBar.open(
          'No se puede generar el sobre de envio ya que esta factura fue enviada a SII',
          'Cerrar',
          {
            duration: 5000,
            verticalPosition: 'top'
          }
        )
      }
      if (this.addressForm.value.estado === 'Rechazada') {
        this.snackBar.open(
          'No se puede crear sobre de envio, ya que fue Rechazada',
          'Cerrar',
          {
            duration: 5000,
            verticalPosition: 'top'
          }
        )
      }

    }

  }

  cerrarModal() {
    this.verPDF = false;
  }

  ImprimirPdfDespacho() {
    this.despachoService.traerPDFDespacho(this.f.folio.value, this.rutEmisor).subscribe((resPDF) => {
      this.verPDF = true;
      // Convertir Base64 a URL de datos
      const pdfBase64 = resPDF.pdfb64;
      const pdfDataUrl = `data:application/pdf;base64,${pdfBase64}`;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfDataUrl);
    });
  }

  EnvioSII() {
    if (this.addressForm.value.estado === 'Sobre de Envio') {
      this.addressForm.value.productos = this.descomponerArray
      this.addressForm.value.estado = 'Envio a SII'
      switch (this.addressForm.status) {
        case 'VALID':
          this.despachoService
            .EnvioSII(this.idFactura, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Se envio a SII con éxito.', 'Cerrar', {
                  duration: 5000,
                  verticalPosition: 'top'
                })
                setTimeout(() => {
                  this.addressForm.reset()
                  //this.boletasService.closeDialogModal()
                  window.location.reload()
                }, 2100)
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo enviar Factura a SII, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break

        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    } else {
      if (this.addressForm.value.estado === 'Pendiente') {
        this.snackBar.open(
          'No se puede enviar Factura a SII, revisa su estado esta Pendiente',
          'Cerrar',
          {
            duration: 5000,
            verticalPosition: 'top'
          }
        )
      }
      if (this.addressForm.value.estado === 'Envio a SII') {
        this.snackBar.open(
          'No se puede enviar Factura a SII, ya que este fue enviada...',
          'Cerrar',
          {
            duration: 5000,
            verticalPosition: 'top'
          }
        )
      }
      if (this.addressForm.value.estado === 'Rechazada') {
        this.snackBar.open(
          'No se puede enviar Factura a SII, ya que fue Rechazada',
          'Cerrar',
          {
            duration: 5000,
            verticalPosition: 'top'
          }
        )
      }
    }
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    //this.boletasService.closeDialogModal()
    // window.location.reload()
  }

}
