<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Actualizar IMG 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <!-- Informacion IMG -->
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Informacion IMG </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <!-- Capturar img -->
              <div>
                <input
                  mat-stroked-button
                  (change)="capturarFile($event)"
                  type="file"
                />
              </div>
              <br />
            </div>
          </div>
          <br />
          <h3>Imagen Previsualizacion</h3>
          <hr />
          <div class="row">
            <!-- Preview img -->
            <div class="col" *ngIf="previsualizacion">
              <img width="400px" [src]="previsualizacion" alt="img" />
            </div>
          </div>
          <hr />
        </mat-expansion-panel>

        <mat-card-actions>
          <button mat-stroked-button color="primary" type="submit" [disabled]="loading" (click)="subirIMG()"
            [disabled]="clickButton" (click)="AgregarIMG()">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="Comprobante 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>

  <mat-tab label="Editar 📝">
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form
      [formGroup]="addressForm"
      novalidate
      class="mat-elevation-z10"
    >
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Editar Cliente</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Informacion Basica -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Basica </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- informacion fila uno -->
             <!-- fecha -->
            <div>
                <form [formGroup]="addressForm">
                <mat-form-field appearance="fill">
                  <mat-label>Fecha</mat-label>
                  <input matInput [value]="addressForm.controls['fecha'].value | date:'dd/MM/yyyy, h:mm a'" disabled>
                  <mat-error *ngIf="addressForm.controls['fecha']">
                    
                  </mat-error>
                </mat-form-field>
                </form>
            </div>
            <div class="row">
            
             <!-- Tipo egresos -->
             <div class="col">
              <mat-form-field class="full-width">
                <mat-select
                  placeholder="Tipo Egresos"
                  formControlName="tipoEgreso"
                >
                  <mat-option
                    *ngFor="let tipoEgreso of tiposEgresos"
                    [value]="tipoEgreso"
                  >
                    {{ tipoEgreso }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    addressForm.controls['tipoPago'].hasError('required')
                  "
                >
                  este campo es <strong>requrido</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <!-- gastos fijos -->
            <div *ngIf="addt">
              <mat-form-field class="full-width">
                <mat-select placeholder="Gastos Fijos" formControlName="gastosFijos">
                  <mat-option *ngFor="let gastosFijos of gastosFijo" [value]="gastosFijos">
                    {{ gastosFijos }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addressForm.controls['gastosFijos'].hasError('required')">
                  este campo es <strong>requrido</strong>
                </mat-error>
              </mat-form-field>
              </div>
          </div>
              
              <!-- monto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Monto" formControlName="monto" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error
                    *ngIf="addressForm.controls['monto'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            
            <!-- descripcion -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Descripcion"
                  formControlName="descripcion"
                />
                <mat-icon matSuffix>edit_18dp</mat-icon>
                <mat-error
                  *ngIf="
                    addressForm.controls['descripcion'].hasError('required')
                  "
                >
                  Este campo es <strong>requerido.</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- informacion fila dos -->
            <div class="row">

              <!-- N° Cuotas-->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="N°Cuotas"
                    formControlName="numeroCuota"
                    
                  />
                  <mat-icon matSuffix>account_balance_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['numeroCuota']
                    "
                  >
                    
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- responsable -->
              <div class="col">
                <mat-form-field class="full-width">
                 
                  <input matInput [value]="addressForm.controls['responsable'].value " disabled>
                  <mat-icon matSuffix>edit_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['responsable']
                    "
                  >
                    
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <!-- informacion fila 3 -->
            
            <div class="row">
              <!-- tipo pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Pago"
                    formControlName="tipoPago"
                  >
                    <mat-option
                      *ngFor="let tipoPago of tiposPagos"
                      [value]="tipoPago"
                    >
                      {{ tipoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    "
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div> 
              
              <!-- Numero cuenta -->
            <div class="col">
              <div *ngIf="addBanc">
                <mat-form-field class="full-width">
                  <mat-label>N° Cuenta corriente/Tarjeta</mat-label>
                  <input matInput placeholder="XXXX/4 Últimos Dígitos" formControlName="numeroCuenta" type="number" />
                  <mat-error *ngIf="addressForm.get('numeroCuenta').hasError('required')">
                    Este campo es obligatorio.
                  </mat-error>
                  <mat-error *ngIf="addressForm.get('numeroCuenta').hasError('pattern')">
                    Debe ingresar los últimos dígitos de la tarjeta/cuenta
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            </div>

          </mat-expansion-panel>

          <!-- Informacion IMG -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion IMG </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- Capturar img -->
                <div>
                  <input
                    mat-stroked-button
                    (change)="capturarFile($event)"
                    type="file"
                  />
                </div>
                <br />
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button color="primary" type="submit" [disabled]="loading" (click)="subirIMG()"
             (click)="onSubmit()">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
