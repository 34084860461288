import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { HostalService} from '../../hostal.service'
import { Usuario } from '@models/shared/usuario';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { GastosFijos } from '../../../../_models/gastosFijos/gastosFijos';
import { Empresa } from '@app/_models/shared/empresa'
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
@Component({
  selector: 'app-hostal-gastos-fijos-formulario',
  templateUrl: './hostal-gastos-fijos-formulario.component.html',
  styleUrls: ['./hostal-gastos-fijos-formulario.component.scss']
})
export class HostalGastosFijosFormularioComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>();
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '');
  modelo = new GastosFijos();
  dataArrayImg = []
  empresa = new Empresa();
  idEmpresa = 1;


  form!: FormGroup
  addressForm = this.formulario.group({
    //idSucursal: [null, Validators.required],
    fecha: [null, Validators.required],
    monto: [null, Validators.required],
    idSucursal: [null, Validators.required],
    tipoEgreso: [null, Validators.required],
    estadoGasto: [null, Validators.required],
    descripcionGasto: [null, Validators.required],
  })

  constructor(
    private formulario: FormBuilder,
    private hostalService: HostalService,
    private snackBar: MatSnackBar,
    private empresaService: EmpresaSharedService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getEmpresa(this.idEmpresa);
  }
  getEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x) => {
        x.Sucursals = Object.values(x.Sucursals);
        this.empresa = x;
      });
  }
  onSubmit() {
    this.form = this.formulario.group(this.addressForm.value);
    
    switch (this.addressForm.status) {
      case 'VALID':
        this.modelo.fecha = this.form.value.fecha;
        this.modelo.monto = this.form.value.monto
        this.modelo.idSucursal = this.form.value.idSucursal;
        this.modelo.descripcion = this.form.value.descripcionGasto;
        this.modelo.estado = this.form.value.estadoGasto;
        this.modelo.gasto = this.form.value.tipoEgreso;

        this.hostalService.createGastoFijos(this.modelo)
        .pipe(first())
        .subscribe(
          (data: any) => {
            this.snackBar.open('Agrego cuenta con exito', 'cerrar', {
              duration: 2000,
              verticalPosition: 'top'
            });
            window.location.reload();
          },
          (error: any) => {
            this.snackBar.open(
              'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
              'cerrar',
              {
                duration: 2000,
                verticalPosition: 'top'
              }
            );
            this.snackBar.open(error, 'cerrar', {
              duration: 3000,
              verticalPosition: 'top'
            });
          }
        );
        break;
      case 'INVALID':
        this.snackBar.open('EL formulario debe ser Completado !!', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        });
        break;
  
      default:
        break;
    }
  }

}
