<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Actualizar IMG 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <!-- Informacion IMG -->
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Informacion IMG</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <br />
            </div>
          </div>
          <br />
          <h3>Imagen Previsualizacion</h3>
          <hr />
          <div class="row">
            <!-- Preview img -->
            <div class="col" *ngIf="previsualizacion">
              <img width="400px" [src]="previsualizacion" alt="img" />
            </div>
          </div>
          <hr />
        </mat-expansion-panel>

        <mat-card-actions>
          <button
            mat-stroked-button
            color="primary"
            type="submit"
            [disabled]="loading"
            (click)="subirIMG()"
            [disabled]="clickButton"
          >
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="Comprobante 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="Editar 📝">
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form [formGroup]="addressForm" novalidate class="mat-elevation-z10">
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Editar Cliente</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Informacion Basica -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Basica </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- informacion fila 1 -->
            <div class="row">
              <!-- Nombre Cliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Nombre Cliente"
                    formControlName="cliente"
                    
                  />
                  <mat-icon matSuffix>account_circle</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['cliente'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Tipo Cliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Tipo Cliente"
                    formControlName="tipoCliente"
                   
                  />
                  <mat-icon matSuffix>edit_18dp</mat-icon>
                  <mat-error>
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Fecha"
                    [matDatepicker]="picker"
                    (click)="picker.open()"
                    required
                    formControlName="fecha"
                    
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error>
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              
            </div>
            <!-- FILA 2 -->
            <div class="row">
              <!-- Monto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Monto" formControlName="monto" />
                  <mat-icon matSuffix>account_circle</mat-icon>
                  <mat-error>
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- tipo documento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Documento"
                    formControlName="tipoDocumento"
                  >
                      <mat-option *ngFor="let d of tipoDocumentos" [value]="d" >
                     {{d}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>assignment_ind</mat-icon>
                  <mat-error>
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
             
          <div class="col">
            
          </div>
              
            </div>
            <!-- Fila 3 -->
              <!-- tipo Ingreso -->
             <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Ingreso"
                    formControlName="tipoIngreso"  
                  >
                    <mat-option *ngFor="let ti of tipoIngresos" [value]="ti" >
                     {{ti}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>monetization_on</mat-icon>
                  <mat-error>
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
             <!-- Tipo Pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Pago"
                    formControlName="tipoPago"
                  >
                    <mat-option *ngFor="let tp of tiposPagos" [value]="tp" >
                     {{tp}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>assignment_ind</mat-icon>
                  <mat-error>
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            <div class="row">
              <div class="row">
              <!-- Codigo Autorizacion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Codigo Autorizacion"
                    formControlName="nAutorizacion"
                    
                  />
                  <mat-icon matSuffix>payment</mat-icon>
                  <mat-error
                  
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- referecia en cuotas -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Referencia en cuotas"
                    formControlName="referenciaCliente"
                    
                  />
                  <mat-icon matSuffix>account_circle</mat-icon>
                  <mat-error
                   
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- n documento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="N° Documento"
                    formControlName="nDocumento"
                    
                  />
                  <mat-icon matSuffix>account_circle</mat-icon>
                  <mat-error
                   
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- descripcion  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Descripcion"
                    formControlName="descripcionIngreso"
                 
                  />
                  <mat-icon matSuffix>account_circle</mat-icon>
                  <mat-error
                   
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            </div>
          </mat-expansion-panel>

          <!-- Informacion IMG -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion IMG </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- Capturar img -->
                <div>
                  <input
                    mat-stroked-button
                    (change)="capturarFile($event)"
                    type="file"
                  />
                </div>
                <br />
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-stroked-button
            color="primary"
            type="submit"
            [disabled]="loading"
            (click)="onSubmit()"
            
          >
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
