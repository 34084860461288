import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BodegaListComponent } from '../bodega-list.component';
import { first } from 'rxjs/operators';
import { BodegaService } from '../../bodega.service';

@Component({
  providers:[ BodegaListComponent ],
  selector: 'app-dialog-bodega-editar',
  templateUrl: './dialog-bodega-editar.component.html',
  styleUrls: ['./dialog-bodega-editar.component.scss']
})
export class DialogBodegaEditarComponent implements OnInit {
  formularioListo = new EventEmitter<string>();
  idBodega : any;

  // ? Configuración de formulario
  form = this.fb.group({
    nombre : ['', Validators.required],
    direccion: ['', Validators.required],
    estado: ['1']
  });

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private BodegaService: BodegaService,
  )
  {}
  
  ngOnInit(): void {
    this.idBodega = localStorage.getItem("idCesionario");
    this.getBodegaId();
  }

  get f() {
    return this.form.controls;
  }
  getBodegaId(){
    this.BodegaService
    .getById(this.idBodega)
    .pipe(first())
    .subscribe((bodega: any) => {
      this.f.nombre.setValue(bodega.nombre);
      this.f.direccion.setValue(bodega.direccion);
      this.f.estado.setValue(bodega.estado);
    });
  }
  // Metodo editar cesionario
  onSubmit(){
    this.BodegaService
    .update(this.idBodega, this.form.value)
    .pipe(first())
    .subscribe(
      (data) => {
        this.snackBar.open('bodega actualizada con éxito', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
        this.BodegaService.closeDialogModal();
        this.BodegaService.addResultBodega('Holaaaa')
      },
      (error) => {
        this.snackBar.open(error, 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
      }
    );
  }
  //Cerrar Modal
  closeDialog(){
    this.BodegaService.closeDialogModal();
  }
}
