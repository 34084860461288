<mat-expansion-panel
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
>
  <mat-expansion-panel-header>
    <mat-panel-title>INFORMACIÓN DE LA SOLICITUD</mat-panel-title>
  </mat-expansion-panel-header>

  <!-- FILA DE ENTRADAS -->
  <div class="row" style="max-width: 100%">
    <!-- Numero Cuenta  -->
    <div class="col" style="margin-right: 15px; max-width: 100%;" >
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Numero Cuenta</label
        >
        <input matInput readonly [(ngModel)]="numeroCuenta" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Numero Cuenta Asume -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Numero Cuenta Asume</label
        >
        <input matInput readonly [(ngModel)]="numeroCuentaAsume" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Asume factura -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Asume Factura</label
        >
        <input matInput readonly [(ngModel)]="asumeFactura" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Banco -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Banco</label
        >
        <input matInput readonly [(ngModel)]="banco" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Banco Cliente -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Banco Cliente</label
        >
        <input matInput readonly [(ngModel)]="bancoCliente" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <!-- fila 2 -->
  <div class="row">
    <!-- Correo -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Correo</label
        >
        <input matInput readonly [(ngModel)]="correo" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Empresa Asume el gasto -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Empresa Asume Gasto</label
        >
        <input matInput readonly [(ngModel)]="empresaAsumeGasto" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Estado -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Estado</label
        >
        <input matInput readonly [(ngModel)]="estado" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Fecha Solicitud -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Fecha Solicitud</label
        >
        <input matInput readonly [(ngModel)]="fechaSolicitud" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- ID Sucursal -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Sucursal</label
        >
        <input matInput readonly [(ngModel)]="nombreSucursal" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <!-- Fila 3 -->
  <div class="row">
    <!-- id usuario -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Usuario</label
        >
        <input matInput readonly [(ngModel)]="nombreUsuario" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- inversion -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Inversion</label
        >
        <input matInput readonly [(ngModel)]="inversion" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Jefe autoriza -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Jefe Autoriza</label
        >
        <input matInput readonly [(ngModel)]="jefeAutoriza" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Monto pago -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Monto pago</label
        >
        <input matInput readonly [(ngModel)]="montoPago" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- motivo pago -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Motivo Pago</label
        >
        <textarea matInput readonly [(ngModel)]="motivoPago"  style="width: 100%; height: 150px;"></textarea>
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <!-- Fila 4-->
  <div class="row">
    <!-- Nombre Responsable -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Nombre Responsable</label
        >
        <input matInput readonly [(ngModel)]="nombreResponsable" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Nombre Transferencia -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Nombre Transferencia</label
        >
        <input matInput readonly [(ngModel)]="nombreTransferencia" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Razon Apruebo -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Razon Apruebo</label
        >
        <input matInput readonly [(ngModel)]="razonApruebo" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Razon Rechazo -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Razon Rechazo</label
        >
        <input matInput readonly [(ngModel)]="razonRechazo" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <!-- Fila 5 -->
  <div class="row">
    <!-- Rut -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Rut</label
        >
        <input matInput readonly [(ngModel)]="rut" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
    <!-- Tipo Cuenta -->
    <div class="col" style="margin-right: 15px">
      <mat-form-field class="full-width">
        <label style="margin-bottom: 8px; display: block; margin-left: 5px"
          >Tipo Cuenta</label
        >
        <input matInput readonly [(ngModel)]="tipoCuenta" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>
    </div>
  </div>
</mat-expansion-panel>

<!-- PARA LA IMAGEN DE LA SOLICITUD -->
<mat-expansion-panel
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
  style="margin-top: 1rem"
>
  <mat-expansion-panel-header>
    <mat-panel-title> IMAGEN DE LA SOLICITUD </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-card>
    <mat-card-header>
      <mat-card-title>IMAGEN PAGO OC</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <img
        [src]="previsualizacion"
        alt="Descripción de la imagen"
        style="max-width: 100%; height: auto"
      />
    </mat-card-content>
  </mat-card>
</mat-expansion-panel>
