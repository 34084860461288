import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Categoria } from '@app/_models/categoria/categoria';
import { Producto } from '@app/_models/productos/producto';
import { CategoriasService } from '@app/_pages/categorias/categorias.service';
import { FileUploader } from 'ng2-file-upload';
import { first } from 'rxjs/operators';
import { ProductosService } from '../../productos.service';
import { environment } from '@environments/environment';
export interface DialogData {
  url: any;

}

@Component({
  selector: 'app-dialog-productos-foto',
  templateUrl: './dialog-productos-foto.component.html',
  styleUrls: ['./dialog-productos-foto.component.scss']
})
export class DialogProductosFotoComponent implements OnInit {

  dataSource: MatTableDataSource<Producto> = new MatTableDataSource();

  displayedColumns: string[] = [
    'img',
    'nombre',
    'venta',
    'botones'
  ];

  addressForm = this.fb.group({
    img: [null],

  });

  formFilter = new FormGroup({
    img: new FormControl(),
    tipo: new FormControl(),
    nombre: new FormControl(),
    neto: new FormControl(),
    venta: new FormControl(),
    estado: new FormControl(), 
    botones: new FormControl(),  
    })

  responses: string[] = [];
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  idProducto: any;
  categorias: Categoria[] = [];
  dataProducto: Producto[] = [];
  productos: Producto[] = [];
  dataNuevo :any ;
  dataNuevo2 :any ;
  fotoDelete!: any;
  productosDelete!: any;
  constructor(
    public dialogRef: MatDialogRef<DialogProductosFotoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private productosService: ProductosService,
    private categoriasService: CategoriasService,
    private fb: FormBuilder,
  ) {
    this.uploader = new FileUploader({
      //url: `http://localhost:3000/api/productos/archivo`,
      //url: `http://179.57.172.224:4141/api/productos/archivo`,
      url: `${environment.apiUrl}/productos/archivo`,
      

      //url: `https://www.imlchile.cl:3000/api/${this.data.url}`,      
      itemAlias: 'producto',
    });    
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {

      if (!response) {
        this.snackBar.open(`tenemos problemas para procesar su solicitud, favor contactar equipo de desarrollo`, 'cerrar', {
          duration: 5000,
        });
      } else {
        this.responses.push(response);
        this.snackBar.open(`Registro Exitoso: ${response}`, 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
      }

    };
    this.uploader.onCompleteAll = () => {      
      // ? la funcion envia al origen de la llamada la data
      // ? luego se debe subscribir a la data
      // ? metodo Mediante el metodo afterClose() del dialogComponent;
      this.dialogRef.close(this.responses);
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('id', this.idProducto);
     
   };

  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  ngOnInit(): void {
    this.idProducto = localStorage.getItem("idProductosEdit");
    this.getProductos(this.idProducto);
    this.getCategorias();
    
  }

  //Cerrar Modal
  closeDialog(){
    this.productosService.closeDialogModal();
  }

  // Obtener las categorias
  getCategorias() {
    this.categoriasService
    .categoriaGet()
    .pipe(first())
    .subscribe((categorias) => {
      this.categorias = categorias;
     });
  }

  getProductos(id : number) {
    //Carga Tabla
    id = this.idProducto
    this.productosService.productoGetId(id).pipe(first()).subscribe((dataProducto) => {
        this.dataNuevo = dataProducto;
        this.dataNuevo2 = [this.dataNuevo];
      
       /* this.categorias.forEach((categorias) => {
          //console.log(Producto.idCategoria, "  -  " , categorias.id);
             this.dataNuevo2.nombreCategoria = categorias.nombre;

        })
        console.log([this.dataNuevo.idCategoria])

        */
        
        this.dataSource = new MatTableDataSource(this.dataNuevo2);
      });
  }

        //Metodo eliminar un producto
         borrarFoto(id : number) {

          id = this.idProducto
          this.productosService.productoGetId(id).pipe(first()).subscribe((dataProducto) => {
              this.dataNuevo = dataProducto;
              this.dataNuevo2 = this.dataNuevo.img;
              console.log(this.dataNuevo2);

          
              this.productosService
              .updateProducto(this.idProducto, this.addressForm.value)
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open('Foto eliminada con exito', 'cerrar', {
                  duration: 2000,
                  verticalPosition: 'top',
                });
                  this.productosService.closeDialogModal();
                },
                (error) => {
                  this.snackBar.open(error, 'cerrar', {
                  duration: 2000,
                  verticalPosition: 'top',
                });
                }
              );
            });
          
      }

    //Abrir Modal Subir Foto
    openDialogFotoSubir(id: any) {
      localStorage.setItem('idProductosEdit', id);
      this.productosService.openDialogFotoSubir();
    }
}
