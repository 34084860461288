<!-- <mat-card class="shipping-card"> -->
  <mat-expansion-panel hideToggle="">
    <mat-expansion-panel-header>
      <mat-panel-title> Hostal: Egreso Credito!</mat-panel-title>
      <mat-panel-description>Presiona Aquí!</mat-panel-description>
      <mat-icon>touch_app_white_18dp</mat-icon>
    </mat-expansion-panel-header>
    <mat-horizontal-stepper>
      <mat-step [stepControl]="formCredito" completed="false">
        <mat-card-content>
          <form [formGroup]="formCredito">
            <!-- Fila 1 -->
            <div class="row">
              <!-- select fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-label>Seleccionar fecha</mat-label>
                  <input
                    matInput
                    formControlName="fecha"
                    [matDatepicker]="picker"
                    (click)="picker.open()"
                    [readonly]="true"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    *ngIf="formCredito.controls['fecha'].hasError('required')"
                  >
                    fecha es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- sucursales -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Sucursales"
                    formControlName="idSucursal"
                  >
                    <mat-option
                      *ngFor="let sucursal of empresa.Sucursals"
                      [value]="sucursal.id"
                    >
                      {{ sucursal?.razonSocial }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      formCredito.controls['idSucursal'].hasError('required')
                    "
                  >
                    sucursal es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
  
              <!-- tipo egreso -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Egreso"
                    formControlName="tipoEgreso"
                  >
                    <mat-option value="Prestamos Bancarios">
                      Prestamos Bancarios
                    </mat-option>
                    <mat-option value="Prestamos Automotriz">
                      Prestamos Automotriz
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      formCredito.controls['tipoEgreso'].hasError('required')
                    "
                  >
                    Tipo ingreso es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            
            <div class="row">
               <!-- Descripción Egreso -->
               <div class="col">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Descripción Credito"
                      formControlName="descripcionCredito"
                    />
                    <mat-error
                      *ngIf="
                        formCredito.controls['descripcionCredito'].hasError(
                          'required'
                        )
                      "
                    >
                      Este campo es <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

              <!-- Region -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select 
                    placeholder="Seleccione Región"
                    formControlName="regiones">
                    <mat-option
                      *ngFor="let region of regiones"
                      [value]="region">
                      {{region}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="formCredito.controls['regiones'].hasError('required')">
                    Este campo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Proyecto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Proyecto asociado (opcional)"
                    formControlName="proyecto"/>
                  <mat-error
                    *ngIf="formCredito.controls['descripcionCredito'].hasError('required')">
                    Este campo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div id="detalle-credito">
            
              
              
              
              <div class="row">
                <h2 id="titulo-credito">Detalle Crédito</h2>
              </div>

              <div class="row">
              <!-- Costo Amortizacion -->
              <div class="col">
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    matInput
                    placeholder="Costo de amortizacion"
                    formControlName="costoAmortizacion"
                  />
                </mat-form-field>
              </div>

               <!-- Tasa de interes -->
              <div class="col">
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    matInput
                    placeholder="Tasa de interés"
                    formControlName="tasaInteres"
                  />
                </mat-form-field>
              </div>
              </div>

              <div class="row">
                <!-- Numero de cuotas -->
                <div class="col">
                  <mat-form-field class="full-width" appearance="outline">
                   
                   
                    <input
                      matInput
                      placeholder="Numero de cuotas"
                      formControlName="numeroCuota"
                      (keypress)="onKeyDown($event)"
                    />
                    <mat-error
                      *ngIf="
                        formCredito.controls['numeroCuota'].hasError('required')
                      "
                    >
                      monto es <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- monto -->
                <div class="col">
                  <mat-form-field class="full-width" appearance="outline">
                    <span matPrefix>$ &nbsp;</span>
                    <input
                      matInput
                      placeholder="Monto Cuota"
                      formControlName="monto"
                      [ngModel]="monto"
                      (ngModelChange)="montoChange($event)"
                      (keypress)="onKeyDown($event)"
                    />
                    <mat-error
                      *ngIf="formCredito.controls['monto'].hasError('required')"
                    >
                      monto cuota es <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <mat-card-actions>
              <!-- boton  de acciones-->
              <button
                mat-stroked-button
                color="primary"
                type="submit"
                [disabled]="loading"
                (click)="onSubmit()"
              >
                {{ loading ? 'Cargando....' : 'Guardar' }}
                <mat-icon>send</mat-icon>
              </button>
            </mat-card-actions>
          </form>
        </mat-card-content>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-expansion-panel>
  <!-- </mat-card> -->
  