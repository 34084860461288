import {
  Component,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { modeloSolicitudBancaria } from '@app/_models/consolidadoBancario/solicitudBancaria'
import { ConsolidadoBancarioService } from '../../consolidado-bancario.service'
import { SelectionModel } from '@angular/cdk/collections'
import { Sucursal } from '@app/_models/shared/sucursal'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { format } from 'rut.js'
import { Usuario } from '@app/_models/shared/usuario'
import { first } from 'rxjs/operators'
import { AlertHelper } from '@app/_helpers/alert.helper'

@Component({
  selector: 'app-solictud-table',
  templateUrl: './solictud-table.component.html',
  styleUrls: ['./solictud-table.component.scss']
})
export class SolictudTableComponent implements OnInit {
  // ? childrens
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()
  @ViewChild(MatSort) sort = null

  // ? Inputs & Outputs
  @Input() refrescar = ''
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  nombreUsuario = this.usuario.nombre
  apellidoUsuario = this.usuario.apellido
  nombreCompleto = this.usuario.nombre + ' ' + this.usuario.apellido
  public solicitudListado: any = []
  

  //------inicio tabla-------
  displayedColumns: string[] = [
    'select',
    'id',
    'jefeAutoriza',
    'rut',
    'nombreTransferencia',
    'NumeroCuenta',
    'fechaSolicitud',
    'motivoPago',
    //'perfilUsuario',
    // 'nombreAutoriza',
    'correo',
    //'sucursal',
    //'razonRechazo',
    'montoPago',
    'estado',
    'acciones'
  ]

  user: any = {};
  rol: string = '';

  dataSource: MatTableDataSource<modeloSolicitudBancaria> =
    new MatTableDataSource()
  dataSolicitudBancaria: modeloSolicitudBancaria[] = []
  dataPendiente: modeloSolicitudBancaria[] = []
  dataAceptada: modeloSolicitudBancaria[] = []
  dataRechazada: modeloSolicitudBancaria[] = []
  dataSaldado: modeloSolicitudBancaria[] = []
  totalSeleccion = 0
  conteoPendientes: number = 0;
  selection = new SelectionModel<modeloSolicitudBancaria>(true, [])
  selectedRows!: any[]
  changelog: string[] = []

  formFilter = new FormGroup({
    id: new FormControl(),
    nombreTransferencia: new FormControl(),
    start: new FormControl(),
    end: new FormControl(),
    rut: new FormControl(),
    NumeroCuenta: new FormControl(),
    jefeAutoriza: new FormControl(),
    estado: new FormControl(),
    montoPago: new FormControl()
    //idSucursal: new FormControl()
  })

  //Modelo de Solicitud bancaria
  modeloDia: modeloSolicitudBancaria = new modeloSolicitudBancaria()
  sucursales: Sucursal[] = []
  rolUsuario: string = 'Administrador'
  autoriza: string
  //clickButton = false
  mostrarAlertaPorEmitir = false; 
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,

    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private sucursalService: SucursalSharedService,
    private consolidadoBancarioService: ConsolidadoBancarioService,
    private alert:AlertHelper
  ) {}

  ngOnInit(): void {
    this.sucursales = this.sucursalService.sucursalListValue
    this.pendiente()
    
    // this.getAllSolicitudBancaria()
    this.aplicarfiltros()

    this.user = localStorage.getItem('usuario');
    const parsedUser = JSON.parse(this.user)
    console.log(parsedUser);
    this.rol = parsedUser.rol;
    console.log("ASÑDLASKDÑLASKÑDSKASLÑ",this.rol);
  }

  addressForm = this.fb.group({
    NumeroCuenta: null,
    nombreResponsable: null,
    rut: null,
    jefeAutoriza: null,
    fechaSolicitud: null,
    estado: null,
    razonRechazo: null,
    montoPago: null,
    motivoPago: null,
    correo: null,
    inversion: null,
    nombreTransferencia: null,
    bancoCliente: null,
    NumeroCuentaAsume: null,
    nombreSucursal: null,
    //empresaAsumeGasto: null,
    asumeFactura: null,
    horaSolicitud: null,
    horaEstado: null,
    RutEmpresa: null,
    tipoCuenta: null,
    banco: null,
    razonApruebo: null,
    nombreCompleto: null,
    nombreAutoriza: null
  })
  get f() {
    return this.addressForm.controls
  }

  /*   getAllSolicitudBancaria() {
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (data: modeloSolicitudBancaria[]) => {
        this.dataSolicitudBancaria = data.map((solicitud) => {
          solicitud.rut = format(solicitud.rut)
          solicitud.perfilUsuario = solicitud.Usuario?.nombreUsuario
          //solicitud.autoriza=solicitud.autoriza.U
          //console.log(solicitud)
          return solicitud
        })
        this.dataSource = new MatTableDataSource(this.dataSolicitudBancaria)
        this.dataSource.paginator = this.paginator.toArray()[0]
        this.dataSource.sort = this.sort
        this.rolUsuario = this.usuario.rol
        //this.autoriza = this.usuario.nombreUsuario
        //console.log(this.autoriza);
        console.log(this.usuario)
      },
      (error) => {
        console.log(`Ocurrio un error ${error}`)
      }
    )
  } */
 /*  pendiente() {
    this.dataPendiente = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: modeloSolicitudBancaria[]) => {
        dato.map((element) => {
          if (element.estado == 'Pendiente') {
            this.dataPendiente.push(element);
            this.conteoPendientes++; // Incrementa el contador
            this.dataSource = new MatTableDataSource(this.dataPendiente)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
            if (this.conteoPendientes > 0) {
              this.mostrarAlertaPorEmitir = true;
            }      
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
        this.rolUsuario = this.usuario.rol
      },
      (error) => {
        console.log(`Ocurrio un error ${error}`)
      }
    )
  } */

  pendiente() {
    this.dataPendiente = [];
    this.conteoPendientes = 0; // Reinicia el contador
  
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: modeloSolicitudBancaria[]) => {
        dato.forEach((element) => {
          if (element.estado == 'Pendiente') {

            this.dataPendiente.push(element);
            this.conteoPendientes++; // Incrementa el contador
            if (this.conteoPendientes > 0) {
              this.mostrarAlertaPorEmitir = true;
            } 
          }
          
        });
  
        // Actualizar la dataSource y la tabla
        this.dataSource = new MatTableDataSource(this.dataPendiente);
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.dataSource.sort = this.sort;
  
        // Mostrar alerta si hay elementos en estado 'Pendiente'
        if (this.conteoPendientes > 0) {
          this.alert.pendientes('Atención. Existen ' + this.conteoPendientes + ' registros en estado Pendiente ');
        }
  
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut);
          return Solicitud;
        });
        this.rolUsuario = this.usuario.rol;
      },
      (error) => {
        console.log(`Ocurrió un error: ${error}`);
      }
    );
  }
  

  //Boton para actualizar tabla de facturas
  actualizar(): void {
    window.location.reload()
  }

  //Boton de busqueda
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  //Boton mostrar todos los datos
  todos() {
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (data: any) => {
        this.dataSolicitudBancaria = data.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
        this.dataSolicitudBancaria = data
        this.dataSource = new MatTableDataSource(this.dataSolicitudBancaria)
        this.dataSource.paginator = this.paginator.toArray()[0]
        this.dataSource.sort = this.sort
      },
      (error) => {
        console.log(`Ocurrio un error ${error}`)
      }
    )
  }

  //Boton pendiente

  /*
  //Boton aceptada
  aceptada() {
    this.dataAceptada = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Autorizada' || element.estado == 'Aceptada' ) {
            this.dataAceptada.push(element);
            this.dataAceptada.push(element)
            this.dataSource = new MatTableDataSource(this.dataAceptada)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
      },
      (error) => {
        console.log(`Ocurrio un error ${error}`)
      }
    )
  }
  */

  /*
  //Boton rechazada
  rechazada() {
    this.dataRechazada = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {

        dato.map((element) => {
          if (element.estado == 'Rechazada') {

            this.dataRechazada.push(element)
            this.dataSource = new MatTableDataSource(this.dataRechazada)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
      },
      (error) => {
        console.log(`Ocurrio un error ${error}`)
      }
    )
  }
  */

  /*

  //Boton saldado
  saldado() {
    this.dataSaldado = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Pagada' || element.estado == 'Saldado') {
            this.dataSaldado.push(element)
            this.dataSource = new MatTableDataSource(this.dataSaldado)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
      },
      (error) => {
        console.log(`Ocurrio un error ${error}`)
      }
    )
  }
  */

  //Boton de acciones
  accionesPagoCuentaBancaria(id: any) {
    localStorage.setItem('idSolicitudPagoBancario', id)
    this.consolidadoBancarioService.openDialogEditSolicitdBancaria()
  }

  aplicarfiltros() {
    this.formFilter.valueChanges.subscribe((res) => {
      const { id, monto } = res
      let dataFiltered = this.dataSolicitudBancaria

      if (id) {
      dataFiltered = dataFiltered.filter(
     (data: modeloSolicitudBancaria) => data.id.toString() === id
          )
         }
      if (res.nombreTransferencia) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.nombreTransferencia.toString().includes(res.nombreTransferencia)
        )
      }
      if (res.rut) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.rut.toString().includes(res.rut)
        )
      }
      if (res.NumeroCuenta) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.NumeroCuenta.toString().includes(res.NumeroCuenta)
        )
      }
      if (res.jefeAutoriza) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.jefeAutoriza.toString().includes(res.jefeAutoriza)
        )
      }
      if (res.estado) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.estado.toString().includes(res.estado)
        )
      }
      if (res.montoPago) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.montoPago.toString().includes(res.montoPago)
        )
      }

      if (res.start && res.end) {
        dataFiltered = dataFiltered.filter(
          (data: modeloSolicitudBancaria) =>
            new Date(data.fechaSolicitud) >= res.start &&
            new Date(data.fechaSolicitud) <= res.end
        )
      }

      this.dataSource = new MatTableDataSource(dataFiltered)
      console.log(this.dataSource)
      this.dataSource.paginator = this.paginator.toArray()[0]
      this.totalSeleccion = 0
      this.dataSource.sort = this.sort
      this.selection.clear()
    })
  }

  //SEELECCION DE ID
  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    console.log('linea 329', numSelected)
    console.log('linea 330', numRows)
    this.selectedRows = []
    this.selection.selected.forEach((x) => this.selectedRows.push(x))
    return numSelected === numRows
  }

  showRowJSON(event: any, row: any) {

    if (event.checked) {
      // Mostrar el JSON específico de la fila seleccionada
      //console.log('JSON de fila seleccionada:', row);
      this.solicitudListado.push(row)
    } else {
      //this.solicitudListado = this.solicitudListado.filter(id => id!=row.id);
      this.solicitudListado = this.solicitudListado.filter((item) => item.id !== row.id)
      //console.log('se desmarco');
    }
    console.log(this.solicitudListado)
  }

  // Metodo que sirve para la seleccion de un campo de la tabla
  masterToggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        })
  }

  //exportar execel
  exportAsXLSX(): void {
    this.selectedRows = []
    this.selection.selected.forEach((x) => this.selectedRows.push(x))
    this.consolidadoBancarioService.exportAsExcelFile(
      this.selectedRows,
      'SOLICITUD-ORDEN-DE-COMPRAS'
    )
  }
  
  estadoAutorizado() {
    this.solicitudListado[0].nombreCompleto = this.nombreCompleto

    this.solicitudListado.forEach((row) => {
      row.estado = 'Autorizada'
      row.pendiente = 'false'
      this.consolidadoBancarioService.updatePagoBancario(row.id,row).pipe(first())
      .subscribe(
        (data) => {
          this.snackBar.open('Operacion realizada con exito', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          //this.addressForm.reset()
          //this.consolidadoBancarioService.closeDialogModal()
          window.location.reload()
        },
        (error) => {
          this.snackBar.open(
            'No se pudo editar la cuenta, favor contactar a informática.',
            'Cerrar',
            {
              duration: 2000,
              verticalPosition: 'top'
            }
          )
        }
      )


    })
    //this.selection.clear()

    //window.location.reload()




  }

  estadoRechazado() {
    this.solicitudListado[0].nombreCompleto = this.nombreCompleto
    this.solicitudListado.forEach((row) => {
      row.estado = 'Rechazada'
      row.pendiente = 'false'
      this.consolidadoBancarioService.updatePagoBancario(row.id,row).pipe(first())
      .subscribe(
        (data) => {
          this.snackBar.open('Operacion realizada con exito', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          //this.addressForm.reset()
          //this.consolidadoBancarioService.closeDialogModal()
          window.location.reload()
        },
        (error) => {
          this.snackBar.open(
            'No se pudo editar la cuenta, favor contactar a informática.',
            'Cerrar',
            {
              duration: 2000,
              verticalPosition: 'top'
            }
          )
        }
      )


    })
    //this.selection.clear()

    console.log(this.solicitudListado)
    //window.location.reload()

  }
}
