import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import {  validate, clean, format, getCheckDigit } from 'rut.js'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class RutValidoService {

  constructor(private http:HttpClient) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const rut = control.value;
    if (validate(clean(rut).toString())===true) {
        const valido = 1;
        return this.http.get<any>(`${environment.apiUrl}/cesionario/valido/${valido}`)
        .pipe(
          map(resp => {
          return (resp.rut === '1')
                  ? null 
              : { rutInvalido : true }
          })
        );
    }else{
      const valido = 0;
      return this.http.get<any>(`${environment.apiUrl}/cesionario/valido/${valido}`)
      .pipe(
        map(resp => {
          return (resp.rut == '0')
                  ?  { rutInvalido : true }
              : null
          })
    );
    }
 
  }

}
