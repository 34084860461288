import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Sucursal } from '@app/_models/shared/sucursal'
import { AbogadosService } from '@app/_pages/abogados/abogados.service'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { first } from 'rxjs/operators'
import { Empresa } from '@app/_models/shared/empresa';
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
@Component({
  selector: 'app-abogados-egresos-edit',
  templateUrl: './abogados-egresos-edit.component.html',
  styleUrls: ['./abogados-egresos-edit.component.scss']
})
export class AbogadosEgresosEditComponent implements OnInit {
  idAbogado: any
  fecha: any
  monto: any
  responsable: any
  tipoEgreso: any
  sucu: any;
  idEmpresa = 3;

  empresa = new Empresa();
  /**  Configuración de formulario  */
  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    monto: ['', Validators.required],
    responsable: ['', Validators.required],
    tipoEgreso: ['', Validators.required],
    tipoPago: ['', Validators.required],
    idSucursal: ['', Validators.required],
    sucu: [''],
    descripcion: ['', Validators.required],
  })
  sucursales: Sucursal[]
  tiposEgresos: string[] = []
  tiposPagos: any[] = []

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private abogadosService: AbogadosService,
    private administracionService: AdministracionService,
    private empresaService: EmpresaSharedService,
  ) {
    // this.sucursales = this.sucursalService.sucursalListValue
    this.tiposPagos = [
      'Efectivo',
      'Debito',
      'Credito',
      'Transferencia',
      'Cheque'
    ]
    this.tiposEgresos = [
      'Gastos',
      'Costos',
      'Remuneraciones',
      'Impuestos',
      'Bancarios'
    ]
  }

  ngOnInit(): void {
    this.idAbogado = localStorage.getItem('idAbogado')
    this.getEngresoAbogados()
    this.getEmpresa(this.idEmpresa);
  }
  getEngresoAbogados() {
    try {
      this.abogadosService
        .getEgreso(this.idAbogado)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idAbogado) {
            this.f.fecha.setValue(x[0].fecha)
            this.f.monto.setValue(x[0].monto)
            this.f.responsable.setValue(x[0].responsable)
            this.f.tipoEgreso.setValue(x[0].tipoEgreso)
            this.f.tipoPago.setValue(x[0].tipoPago)
            this.f.descripcion.setValue(x[0].descripcion)
            this.f.idSucursal.setValue(x[0].idSucursal)
            this.sucu = x[0].Sucursal.razonSocial
          }
          this.sucu = x[0].Sucursal.razonSocial
        })
    } catch (error) {
      console.log(error)
    }
  }

  getEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x) => {
        x.Sucursals = Object.values(x.Sucursals);
       
        this.empresa = x;


      
      });
  }


  get f() {
    return this.addressForm.controls
  }
  /** metodo editar hostal service */
  onSubmit() {
    switch (this.addressForm.status) {
      case 'VALID':
        console.log("info: ", this.addressForm.value)
        this.abogadosService
          .updateFirmaEgreso(this.idAbogado, this.addressForm.value)
          .pipe(first())
          .subscribe(
            (data) => {
              this.snackBar.open('Abogados editada con éxito.', 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })

              this.addressForm.reset()
              this.abogadosService.closeDialogModal()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo editar el abogado, favor contactar a informática.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
            }
          )
        break

      case 'INVALID':
        this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
  }
}
