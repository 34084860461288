import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { IngresosInmobiliaria } from '@app/_models/inmobiliaria/ingresoInmobiliaria'
import { EgresosInmobiliaria } from '@app/_models/inmobiliaria/egresoInmobiliaria'
import { environment } from '@environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import {GastosFijos} from '@app/_models/gastosFijos/gastosFijos'
import {InmobiliariaGastosFijosEditComponent} from './inmobiliaria-gastos-fijos/inmobiliaria-gastos-fijos-edit/inmobiliaria-gastos-fijos-edit.component'
import { EgresoInmobiliariaCuota } from '@app/_models/inmobiliaria/egresoInmobiliariaCuota'
import { MatDialog } from '@angular/material/dialog'
import { InmobiliariaEgresosCuotaDialogComponent } from '@app/_pages/inmobiliaria/inmobiliaria-egresos/inmobiliaria-egresos-list/inmobiliaria-egresos-cuotas/inmobiliaria-egresos-cuota-dialog/inmobiliaria-egresos-cuota-dialog.component'
import { InmobiliariaEgresosCuotasComponent } from '@app/_pages/inmobiliaria/inmobiliaria-egresos/inmobiliaria-egresos-list/inmobiliaria-egresos-cuotas/inmobiliaria-egresos-cuotas.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ConsolidadosInmobiliaria } from '@app/_models/inmobiliaria/consolidadosInmobiliaria'
import { InmobiliariaEgresosEditComponent } from './inmobiliaria-egresos/inmobiliaria-egresos-list/inmobiliaria-egresos-edit/inmobiliaria-egresos-edit.component'
import { InmobiliariaIngresosAccionesComponent } from './inmobiliaria-ingresos-tabla/inmobiliaria-ingresos-acciones/inmobiliaria-ingresos-acciones.component'
import { InmobiliariaEgresosAccionesComponent } from './inmobiliaria-egresos/inmobiliaria-egresos-tabla/inmobiliaria-egresos-acciones/inmobiliaria-egresos-acciones.component'
import { UsuarioSharedService } from '../shared/shared-services/usuario-shared.service'
import { SucursalSharedService } from '../shared/shared-services/sucursal-shared.service'

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'

/* Fin Import Excel */

@Injectable({
  providedIn: 'root'
})
export class InmobiliariaService {
  private ingresosInmobiliariaSubject!: BehaviorSubject<IngresosInmobiliaria>
  private egresosInmobiliariaSubject!: BehaviorSubject<EgresosInmobiliaria>

  //Crear listas
  public tiposIngresosList!: Observable<string[]>
  public tiposEgresosList!: Observable<string[]>

  //ingresos values
  private tiposIngresosListSubject!: BehaviorSubject<any[]>
  private tiposEgresosListSubject!: BehaviorSubject<any[]>

  // ! strict lists
  private tiposIngresosInmobiliaria = [
    'Venta',
    'Arriendo',
    'Leaseback',
    'Hipoteca',
    'Otro'
  ]
  private tiposEgresosInmobiliaria = [
    'Gastos',
    'Costos',
    'Remuneraciones',
    'Bancarios',
    'Impuestos',
    'Inversiones'
  ]
  private empresa = 'Inmobiliaria'

  constructor(
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioSharedService,
    private sucursalService : SucursalSharedService
  ) {
    //Init private Subjects;
    //ingresos;
    this.tiposIngresosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tiposIngresosInmobiliaria')!)
    )

    //Egresos
    this.tiposEgresosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tiposEgresosInmobiliaria')!)
    )

    // public states:
    //ingresos;
    this.tiposIngresosList = this.tiposIngresosListSubject.asObservable()
    localStorage.setItem(
      'tiposIngresosInmobiliaria',
      JSON.stringify(this.tiposIngresosInmobiliaria)
    )
    //egresos;
    this.tiposEgresosList = this.tiposEgresosListSubject.asObservable()
    localStorage.setItem(
      'tiposEgresosInmobiliaria',
      JSON.stringify(this.tiposEgresosInmobiliaria)
    )
  }

  //*********** Inicio Metodos Ingresos ************/
  //ingresos values get methods:
  public get tiposIngresosListValue(): string[] {
    return this.tiposIngresosListSubject.value
  }

  public get ingresosInmobiliariaValue(): IngresosInmobiliaria {
    return this.ingresosInmobiliariaSubject.value
  }

  create(ingresosInmobiliaria: IngresosInmobiliaria) {
    console.log(ingresosInmobiliaria)
    return this.http.post(
      `${environment.apiUrl}/ingresoInmobiliaria/conRespaldo`,
      ingresosInmobiliaria
    )
    
  }

  getAll() {
    return this.http.get<[]>(`${environment.apiUrl}/ingresoInmobiliaria`)
  }
  /* cargar imagen */
  ingresoGetFiles(fileName: string) {
    const extencion = fileName.split('.')
    const extend = extencion[1]
    return this.http
      .get(
        `${environment.apiUrl}/ingreso${this.empresa}/download/${fileName}`,
        {
          responseType: 'blob'
        }
      )
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }

  buscarImagenIngreso(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    console.log(extend)
    return this.http.get(
      `${environment.apiUrl}/ingreso${this.empresa}/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }
  //*********** Fin Metodos Ingresos ************/

  //*********** Inicio Metodos Egresos ************/
  //ingresos values get methods:
  public get tiposEgresosListValue(): string[] {
    return this.tiposEgresosListSubject.value
  }

  public get egresosInmobiliariaValue(): EgresosInmobiliaria {
    return this.egresosInmobiliariaSubject.value
  }

  getAllEgresos() {
    return this.http.get<[]>(`${environment.apiUrl}/egresoInmobiliaria`)
  }

  /** metodo actualizar datos editados de una inmobiliaria */
  updateInmobiliariaEgreso(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egreso${this.empresa}/${id}`,
      params
    )
  }

  buscarCuotas(): any {
    return this.http.get<EgresoInmobiliariaCuota>(
      `${environment.apiUrl}/egresoInmobiliariaCuota/`
    )
  }

  createEgresos(ingresosInmobiliaria: IngresosInmobiliaria) {
    console.log(ingresosInmobiliaria)
    return this.http.post(
      `${environment.apiUrl}/egresoInmobiliaria/conRespaldo`,
      ingresosInmobiliaria
    )
  }

  egresoGetFiles(fileName: string): any {
    return this.http
      .get(`${environment.apiUrl}/egreso${this.empresa}/download/${fileName}`, {
        responseType: 'blob'
      })
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }
  buscarImagenEgreso(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egreso${this.empresa}/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }
  getByIdEgreso(id: string) {
    return this.http.get<any>(
      `${environment.apiUrl}/egreso${this.empresa}/${id}`
    )
  }

  /* CONSOLIDADOS */
  buscarConsolidado(consolidado: ConsolidadosInmobiliaria): any {
    return this.http.post(
      `${environment.apiUrl}/ingreso${this.empresa}/ingresosEgresos`,
      consolidado
    )
  }

  /* Egresos Por cuotas */
  getCuotas(id: any) {
    return this.http.get<EgresoInmobiliariaCuota>(
      `${environment.apiUrl}/egresoInmobiliariaCuota/${id}`
    )
  }

  agregarRespaldos(arrayRespaldos: any): any {
    return this.http.post(
      `${environment.apiUrl}/egresoInmobiliariaCuota/agregarRespaldos/`,
      arrayRespaldos
    )
  }

  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idEgresoPago')
  }

  buscarImagenC(id: any): any {
    return this.http.get<EgresoInmobiliariaCuota>(
      `${environment.apiUrl}/respaldoEgresoInmobiliariaCuota/${id}`
    )
  }

  buscarImagenCuota(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoInmobiliariaCuota/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogCuota(): void {
    const dialogRef = this.dialog.open(
      InmobiliariaEgresosCuotaDialogComponent,
      {}
    )
    dialogRef.afterClosed().subscribe((res) => {})
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogRegistrarPago(idEgreso: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idEgreso != null) {
      const dialogRef = this.dialog.open(InmobiliariaEgresosCuotasComponent, {})
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open(
        'Por favor seleccione un egreso con cuotas sin pagar',
        'cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }

  updateMonto(id: any, body: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egresoInmobiliariaCuota/${id}`,
      body
    )
  }

  // METODO PARA EXPORTAR EXCEL
  public async exportAsExcelFile(json: any[], excelFileName: string, selectedColumns: string[]): Promise<void> {
    const columnMappings = {
      'id': 'ID',
      'fecha': 'Fecha',
      'monto': 'Monto',
      'nAutorizacion': 'N° Autorización',
      'tipoPago': 'Tipo de Pago',
      'cliente': 'Cliente',
      'descripcionIngreso': 'Descripción',
      'tipoIngreso': 'Tipo de Ingreso',
      'referenciaCliente': 'Referencia',
      'idUsuario': 'Usuario',
      'idSucursal': 'Sucursal',
    };
  
    const newData = await Promise.all(json.map(async (item) => {
      const newItem: any = {};
      for (const col of selectedColumns) {
        if (col === 'idSucursal') {
          if (item[col] !== null) {
            try {
              const sucursal = await this.sucursalService.getById(item[col]).toPromise();
              newItem[columnMappings[col]] = sucursal.razonSocial;
            } catch (error) {
              console.error("Error al obtener el nombre de la sucursal:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else if (col === 'idUsuario') {
          if (item[col] !== null) {
            try {
              const usuario = await this.usuarioService.getById(item[col]).toPromise();
              newItem[columnMappings[col]] = usuario.nombre + usuario.apellido;
            } catch (error) {
              console.error("Error al obtener el nombre del usuario:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else if (col === 'fecha') {
          if (item[col] !== null) {
            try {
              const fecha = new Date(item[col]);
              const formattedFecha = `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`;
              newItem[columnMappings[col]] = formattedFecha;
            } catch (error) {
              console.error("Error al obtener la fecha:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else {
          newItem[columnMappings[col]] = item[col];
        }
      }
  
      return newItem;
    }));
  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData);
  
    const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: C });
      // Limpiar cualquier estilo existente
      worksheet[headerCell].s = {};
      // Aplicar nuevo estilo
      worksheet[headerCell].s = { fill: { fgColor: { rgb: 'FFFF00' } }, font: { bold: true } };
    }
    
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public async exportAsExcelFileE(json: any[], excelFileName: string, selectedColumns: string[]): Promise<void> {
    const columnMappings = {
      'id': 'ID',
      'tipoEgreso': 'Tipo de Egreso',
      'fecha': 'Fecha',
      'monto': 'Monto',
      'responsable': 'Responsable',
      'descripcion': 'Descripción',
      'tipoPago': 'Tipo de Pago',
      'idUsuario': 'Usuario',
      'idSucursal': 'Sucursal',
    };
  
    const newData = await Promise.all(json.map(async (item) => {
      const newItem: any = {};
      for (const col of selectedColumns) {
        if (col === 'idSucursal') {
          if (item[col] !== null) {
            try {
              const sucursal = await this.sucursalService.getById(item[col]).toPromise();
              newItem[columnMappings[col]] = sucursal.razonSocial;
            } catch (error) {
              console.error("Error al obtener el nombre de la sucursal:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else if (col === 'idUsuario') {
          if (item[col] !== null) {
            try {
              const usuario = await this.usuarioService.getById(item[col]).toPromise();
              newItem[columnMappings[col]] = usuario.nombre + usuario.apellido;
            } catch (error) {
              console.error("Error al obtener el nombre del usuario:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else if (col === 'fecha') {
          if (item[col] !== null) {
            try {
              const fecha = new Date(item[col]);
              const formattedFecha = `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`;
              newItem[columnMappings[col]] = formattedFecha;
            } catch (error) {
              console.error("Error al obtener la fecha:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else {
          newItem[columnMappings[col]] = item[col];
        }
      }
  
      return newItem;
    }));
  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData);
  
    const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: C });
      // Limpiar cualquier estilo existente
      worksheet[headerCell].s = {};
      // Aplicar nuevo estilo
      worksheet[headerCell].s = { fill: { fgColor: { rgb: 'FFFF00' } }, font: { bold: true } };
    }
    
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    )
  }

  // // Metodo que permite abrir un Dialog (Modal)-->editar
  openDialogEditEgreso(): void {
    const dialogRef = this.dialog.open(InmobiliariaEgresosEditComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  deleteEgreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/egresoInmobiliaria/${id}`)
  }

  /* 
      ################ INGRESOS ######################
     Modificaciones realizadas por Méndex  💪💪💪💪💪 
   */
  // Crear Ingresos
  IngresoInmobiliaria(modelo: IngresosInmobiliaria): any {
    console.log(modelo);
   return this.http.post(`${environment.apiUrl}/ingresoInmobiliaria`, modelo)
  }

  getAllWithUsuario() {
    return this.http.get<IngresosInmobiliaria[]>(
      `${environment.apiUrl}/ingresoInmobiliaria/conUsuario`
    )
  }

  getById(id: string) {
    return this.http.get<IngresosInmobiliaria>(
      `${environment.apiUrl}/ingresoInmobiliaria/${id}`
    )
  }

  updateInmobiliariaIngreso(id: any, params: any[]) {
    console.log(params)
    return this.http.put(
      `${environment.apiUrl}/ingresoInmobiliaria/updateIngresoInmobiliaria/${id}`,
      params
    )
  }
  deleteIngreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/ingresoInmobiliaria/${id}`)
  }

  getByIdIngreso(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/ingresoInmobiliaria/oneGetIngresoInmobiliaria/${id}`
    )
  }

  getByIdIngresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/ingresoInmobiliaria/getByIdSinBase64/${id}`
    )
  }

  openDialogEditIngresoInmobiliaria(): void {
    const dialogRef = this.dialog.open(
      InmobiliariaIngresosAccionesComponent,
      {}
    )
    dialogRef.afterClosed().subscribe((res) => {})
  }

  /* 
      ################ EGRESOS ######################
     Modificaciones realizadas por Méndex  💪💪💪💪💪 
     ################################################
   */
  egresoInmobiliaria(modelo: EgresosInmobiliaria): any {
   // console.log(modelo);
    return this.http.post(`${environment.apiUrl}/egresoInmobiliaria`, modelo)
  }

  openDialogEditEgresoInmobiliaria(): void {
    const dialogRef = this.dialog.open(InmobiliariaEgresosAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }
  getByIdEngresoInmobiliaria(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoInmobiliaria/oneGetEgresoInmobiliaria/${id}`
    )
  }
  updateEgresoInmobiliaria(id: any, params: any[]) {
    console.log(params, id)
    return this.http.put(
      `${environment.apiUrl}/egresoInmobiliaria/updateEgresoInmobiliaria/${id}`,
      params
    )
  }
  getByIdEgresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoInmobiliaria/getByIdSinBase64/${id}`
    )
  }
  getDowloadImg(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/egresoInmobiliaria/download-image/${id}`, {
     // responseType:'blob'
    })
  }

  createGastoFijos(gastos: GastosFijos): any {
    console.log("ruta", gastos )
    return this.http.post(`${environment.apiUrl}/gastosFijos`, gastos);
  }

  getGastoFijos(): Observable<GastosFijos[]> {
    return this.http.get<GastosFijos[]>(`${environment.apiUrl}/gastosFijos`);
  }

  openDialogGastoFijo(detallesGasto: any) {
    const dialogRef = this.dialog.open(InmobiliariaGastosFijosEditComponent, {
      width: '1000px',
      height: '500px',
      data: detallesGasto,
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  getByIdGastoFijo(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/gastosFijos/getIDSinBase64/${id}`)
  }
  getByIdGastoFijoImagen(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/gastosFijos/oneGetGastoFijos/${id}`)
  }
  updateGastoFijos(id: any, data: any) {
    return this.http.put(
      `${environment.apiUrl}/gastosFijos/updateGastoFijos/${id}`, data
    );
  }
  createCredito(egresoInmobiliaria: EgresosInmobiliaria):any{
    try {
      return this.http.post(
        `${environment.apiUrl}/egresoInmobiliaria/createEgresoHome/`,
        egresoInmobiliaria
      )
    }catch(error){
      console.log(error)
      return  error
    }
  }
  
    //Métodos para gestionar transacciones webpay
    pagar(idCuota:number, monto:number, empresa:string){
      let body = {
        idCuota,
        monto,
        empresa
      }
      return this.http.post(
        `${environment.apiUrl}/egresoInmobiliaria/pagar`, body
      )
    }
    confirmar(token:string){
      console.log("HostalService token: ", token)
      let body = {token}
        console.log("Antes de hhtp post")
        return this.http.post(
          `${environment.apiUrl}/egresoInmobiliaria/confirmar`, body
        )
    }
    actualizarPagado(id: number): any {
      let body = {
        id
      }
      return this.http.post(
        `${environment.apiUrl}/egresoInmobiliariaCuota/actualizarPagado/`,
        body
      )
    }
}
