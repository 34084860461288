<form [formGroup]="consolidadosForm" novalidate (ngSubmit)="onSubmit()" id="ocultar">
<mat-card class="shipping-card mat-elevation-z10" *ngIf="this.flag == false">
    <mat-card-header>   
    <h1> Consolidados Hostal Plaza Maule </h1>
    </mat-card-header>
<br><br>

<mat-card-content> 
    <div class="row">
       <!-- Consolidados -->
    <div class="col">
    <mat-form-field class="full-width">                        
        <mat-select placeholder="Tipo de consolidado" formControlName="consolidados">   
            <mat-option value="2">
                Ingresos
            </mat-option>
            <mat-option value="3">
                Egresos
            </mat-option> 
            <mat-option value="4">
                Ingresos y Egresos
            </mat-option>                       
            <mat-option value="1">
                Inversiones
            </mat-option>
        </mat-select>
        <mat-error *ngIf="consolidadosForm.controls['consolidados'].hasError('required')">
            tipo consolidado es <strong>requerido</strong>
        </mat-error>              
    </mat-form-field>
    </div>

       <!-- Surcusales -->
       <div class="col">
        <mat-form-field class="full-width">                        
            <mat-select placeholder="Sucursales" formControlName="sucursales">
                <mat-option value="HOSTAL 1">
                    HOSTAL 1
                </mat-option>
                <mat-option value="HOSTAL 2">
                    HOSTAL 2
                </mat-option>
                <mat-option value="CABAÑA">
                    CABAÑA
                </mat-option>                
                <mat-option value="todas">
                    TODAS LAS SUCURSALES
                </mat-option>                
            </mat-select>
            <mat-error *ngIf="consolidadosForm.controls['sucursales'].hasError('required')">
                la sucursal es <strong>requerida</strong>
            </mat-error>              
        </mat-form-field>
        </div>           
   
</div>

<div class="row">

     <!-- Tramos de fechas -->
    <div class="col">
        <mat-form-field class="full-width">                        
            <mat-select placeholder="Tramos de fechas" formControlName="tramos">                
                <mat-option value="Mensual" (click)="activarMes()">
                    Mensual
                </mat-option>                
                <mat-option value="Trimestral" (click)="activarTrimestre()">
                    Trimestral
                </mat-option>
                 <mat-option value="Semestral" (click)="activarSemestre()">
                    Semestral
                </mat-option>
                <mat-option value="Year" (click)="activarYear()">
                    Anual
                </mat-option>                 
            </mat-select>  
            <mat-error *ngIf="consolidadosForm.controls['tramos'].hasError('required')">
                tramo fecha es <strong>requerido</strong>
            </mat-error>      
        </mat-form-field>
       </div>

       <!-- Buscar por mes -->
   <div class="col" *ngIf="!mostrarMes">
    <mat-form-field class="full-width">                        
        <mat-select placeholder="Fechas por mes" formControlName="month">
            <mat-option value="January">
                Enero
            </mat-option>
            <mat-option value="February">
                Febrero
            </mat-option>
            <mat-option value="March">
                Marzo
            </mat-option>
            <mat-option value="April">
                Abril
            </mat-option>            
            <mat-option value="May">
                Mayo
            </mat-option>            
            <mat-option value="June">
                Junio
            </mat-option>            
            <mat-option value="July">
                Julio
            </mat-option>            
            <mat-option value="August">
                Agosto
            </mat-option>            
            <mat-option value="September">
                Septiembre
            </mat-option>            
            <mat-option value="October">
                Octubre
            </mat-option>            
            <mat-option value="November">
                Noviembre
            </mat-option>            
            <mat-option value="December">
                Diciembre
            </mat-option>            
        </mat-select>  
        <mat-error *ngIf="consolidadosForm.controls['tramos'].hasError('required')">
            mes es <strong>requerido</strong>
        </mat-error>      
    </mat-form-field>
   </div>

   <!-- Numero del trimestre -->
   <div class="col" *ngIf="!mostrarTrimestre">
    <mat-form-field class="full-width">                        
        <mat-select placeholder="¿Que trimestre desea?" formControlName="trimestre">
            <mat-option value="1">
                Enero - Marzo
            </mat-option>
            <mat-option value="2">
                Abril - Junio
            </mat-option>
            <mat-option value="3">
                Julio - Septiembre
            </mat-option>
            <mat-option value="4">
                Octubre - Diciembre
            </mat-option>             
        </mat-select>  
        <mat-error *ngIf="consolidadosForm.controls['trimestre'].hasError('required')">
            trimestre es <strong>requerido</strong>
        </mat-error>      
    </mat-form-field>
   </div>

   <!-- Numero del Semestre -->
   <div class="col" *ngIf="!mostrarSemestre">
    <mat-form-field class="full-width">                        
        <mat-select placeholder="¿Que semestre desea?" formControlName="semestre">
            <mat-option value="1">
                Enero - Junio
            </mat-option>
            <mat-option value="2">
                Julio - Diciembre
            </mat-option>            
        </mat-select>  
        <mat-error *ngIf="consolidadosForm.controls['semestre'].hasError('required')">
            semestre es <strong>requerido</strong>
        </mat-error>      
    </mat-form-field>
   </div>

    <!-- Buscar por año -->
   <div id="hola" class="col" *ngIf="!mostrarYear">
    <mat-form-field class="full-width">                        
        <mat-select placeholder="Fechas por año" formControlName="year">
            <mat-option value="2017">
                2017
            </mat-option>
            <mat-option value="2018">
                2018   
            </mat-option>
            <mat-option value="2019">
                2019
            </mat-option>
            <mat-option value="2020">
                2020
            </mat-option>            
            <mat-option value="2021">
                2021
            </mat-option>            
            <mat-option value="2022">
                2022
            </mat-option>            
            <mat-option value="2023">
                2023
            </mat-option>            
            <mat-option value="2024">
                2024
            </mat-option>            
            <mat-option value="2025">
                2025
            </mat-option>            
            <mat-option value="2026">
                2026
            </mat-option>            
            <mat-option value="2027">
                2027
            </mat-option>            
            <mat-option value="2028">
                2028
            </mat-option>            
            <mat-option value="2029">
                2029
            </mat-option>            
            <mat-option value="2030">
                2030
            </mat-option>            
        </mat-select>  
        <mat-error *ngIf="consolidadosForm.controls['tramos'].hasError('required')">
            año es <strong>requerido</strong>
        </mat-error>      
    </mat-form-field>
   </div>

</div>

<div class="row" *ngIf="!mostrarFechaIF">
    
    <!-- Fecha- desde -->
<div class="col">
    <mat-form-field class="full-width">
      <mat-label>Seleccionar fecha desde</mat-label>
      <input matInput formControlName="fechaI" [matDatepicker]="picker" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="consolidadosForm.controls['fechaI'].hasError('required')">
        fecha inicio es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Fecha - hasta -->
  <div class="col">
    <mat-form-field class="full-width">
      <mat-label>Seleccionar fecha hasta</mat-label>
      <input matInput formControlName="fechaF" [matDatepicker]="picker1" />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
      <mat-error *ngIf="consolidadosForm.controls['fechaF'].hasError('required')">
        fecha fin es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
  </div>
</div>
</mat-card-content>
<mat-card-actions>
    <button mat-raised-button color="primary" type="submit">
      <mat-icon>send</mat-icon> Enviar
    </button>            
  </mat-card-actions>
</mat-card>
<mat-card class="shipping-card mat-elevation-z10" *ngIf="this.consolidadosForm.status == 'VALID' && this.flag == true">
    <div class="loader"></div>
</mat-card>
</form>

<br><br>

<mat-card class="shipping-card mat-elevation-z10">
<mat-grid-list cols="2">    
    <div class="table-responsive" id="htmlData">
        <mat-toolbar color="primary">            
            <span class="center-title">Ingresos Hostal</span>         
        </mat-toolbar>        
        <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="desc" class="mat-elevation-z10">
            <!-- check column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <!-- ID with checkbox -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>
            <!-- date with datapicker -->
            <ng-container matColumnDef="fecha" class="mat-column-date">
                <th mat-header-cell *matHeaderCellDef>
                <mat-label>Fecha</mat-label>                    
                </th>
                <td mat-cell *matCellDef="let element">{{ element.fecha | date: 'dd/MM/yyyy' }}</td>
            </ng-container>
                        
            <!-- tipo egreso with select -->
            <!-- monto currency fomrat -->
            <ng-container matColumnDef="monto">
                <th mat-header-cell *matHeaderCellDef>Monto</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.monto | currency: "$":"symbol":"1.0-0" }}
                </td>
            </ng-container>            
    
            <tr mat-header-row *matHeaderRowDef="displayedColumnsTwo"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTwo"></tr>
    
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No existen coincidencias</td>
            </tr>
        </table>
    </div>
    <mat-paginator class="table-responsive"[pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>        
</mat-grid-list>
</mat-card>
<br><br>
<!-- </div> -->
<!-- *********** TABLE EGRESOS **************** -->
<mat-card class="shipping-card mat-elevation-z10">
    <mat-grid-list cols="2">    
        <div class="table-responsive">
            <mat-toolbar color="primary">            
                <span class="center-title">Egresos Hostal</span>         
            </mat-toolbar>        
            <table mat-table [dataSource]="dataSourceEgresos" matSort matSortActive="id" matSortDirection="desc" class="mat-elevation-z10">
                <!-- check column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <!-- ID with checkbox -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                </ng-container>
                <!-- date with datapicker -->
                <ng-container matColumnDef="fecha" class="mat-column-date">
                    <th mat-header-cell *matHeaderCellDef>
                    <mat-label>Fecha</mat-label>                    
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.fecha | date: 'dd/MM/yyyy' }}</td>
                </ng-container>
                            
                <!-- tipo egreso with select -->
                <!-- monto currency fomrat -->
                <ng-container matColumnDef="monto">
                    <th mat-header-cell *matHeaderCellDef>Monto</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.monto | currency: "$":"symbol":"1.0-0" }}
                    </td>
                </ng-container>            
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No existen coincidencias</td>
                </tr>
            </table>
        </div>
        <mat-paginator class="table-responsive"[pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>                
    </mat-grid-list>
    </mat-card>
    <br><br>
<!-- </div> -->
<!-- -----------BOTONES DE TOTALES------------- -->
<mat-card class="shipping-card mat-elevation-z10">
    <div class="row">
    <div class="col">  
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Total ingresos</mat-label>
        <input matInput placeholder="Total ingresos" readonly>
        {{ sumIngresos | currency: "$":"symbol":"1.0-0" }}
      </mat-form-field>
    </div>
    <div class="col">  
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Total egresos</mat-label>
        <input matInput placeholder="Total egresos" readonly>
        {{ sumEgresos | currency: "$":"symbol":"1.0-0" }}
      </mat-form-field>
    </div>
    <div class="col">  
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Consolidado</mat-label>
        <input matInput placeholder="Consolidado" readonly>
        {{ totalConsolidado | currency: "$":"symbol":"1.0-0" }}
      </mat-form-field>
    </div>
    </div>    


<!-- -----------GRAFICOS------------- -->
    
    <div *ngIf="totalIngresoG.length > 0">
    <canvas #barChart>
      {{chart}}
    </canvas>
    </div>

        <div *ngIf="totalEgresoG.length > 0">
        <canvas #barChart>
          {{chart}}
        </canvas>
        </div>
        
        <div class="row" *ngIf="totalEgresoG.length > 0 || totalIngresoG.length > 0" id="ocultar">
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="openPDF()">
                <mat-icon>send</mat-icon> PDF
            </button>            
          </mat-card-actions>
          <mat-card-actions>
            <button id="margin" mat-raised-button color="primary" (click)="exportAsXLSX()">
              <mat-icon>send</mat-icon> Excell
            </button>
          </mat-card-actions>          
        </div>
</mat-card>
