import {
  Component,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { modeloSolicitudBancaria } from '@app/_models/consolidadoBancario/solicitudBancaria'
import { ConsolidadoBancarioService } from '../../consolidado-bancario.service'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'
import * as saveAs from 'file-saver'
import { SelectionModel } from '@angular/cdk/collections'
import { format } from 'rut.js'
import { AlertHelper } from '@app/_helpers/alert.helper';
import { Usuario } from '@app/_models/shared/usuario'
import { ConsolidadoBancarioSharedService } from '../../consolidado-bancario-shared.service'
import { first, map } from 'rxjs/operators'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'

@Component({
  selector: 'app-pago-table',
  templateUrl: './pago-table.component.html',
  styleUrls: ['./pago-table.component.scss']
})
export class PagoTableComponent implements OnInit {
  panelOpenState = false
  // ? childrens
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()
  @ViewChild(MatSort) sort = null

  // ? Inputs & Outputs
  @Input() refrescar = ''
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  //------inicio tabla-------
  displayedColumns: string[] = [
    'select',
    'id',
    'jefeAutoriza',
    'rut',
    'nombreTransferencia',
    'NumeroCuenta',
    'fechaSolicitud',
    'motivoPago',
    'perfilUsuario',
    'nombreAutoriza',
    'razonRechazo',

    //'apruebo_pagado',
    // 'correo',
    'estado',
    'confirmadoPorFinanzas',
    'montoPago',
    'acciones'
    //'razonRechazo'
  ]

  allData: modeloSolicitudBancaria[] = []; // Variable para almacenar todos los registros
 
  totalRecords = 0;
  pageSize = 10; // Tamaño de la página
  pageIndex = 0; // Página actual

  dataSource: MatTableDataSource<modeloSolicitudBancaria> =
    new MatTableDataSource()
  dataSolicitudBancaria: modeloSolicitudBancaria[] = []
  dataPendiente: modeloSolicitudBancaria[] = []
  dataAceptada: modeloSolicitudBancaria[] = []
  dataRechazada: modeloSolicitudBancaria[] = []
  dataSaldado: modeloSolicitudBancaria[] = []
  totalSeleccion = 0
  cantidadPendientes = []
  selection = new SelectionModel<modeloSolicitudBancaria>(true, [])
  selectedRows!: any[]
  mostrarAlertaPorEmitir = false
  datas: modeloSolicitudBancaria[] = []

  //Modelo de Solicitud bancaria
  modeloDia: modeloSolicitudBancaria = new modeloSolicitudBancaria()
  rolUsuario: string

  formFilter = new FormGroup({
    id: new FormControl(),
    nombreTransferencia: new FormControl(),
    start: new FormControl(),
    end: new FormControl(),
    rut: new FormControl(),
    NumeroCuenta: new FormControl(),
    jefeAutoriza: new FormControl(),
    estado: new FormControl(),
    montoPago: new FormControl()
  })

  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService,
    private http: HttpClient,
    private alert: AlertHelper,
    private sharedService: ConsolidadoBancarioSharedService,
    private sucursalService: SucursalSharedService,

  ) {
    this.sharedService.recargarTabla$.subscribe(async () => {
      this.limpiarTabla()
      this.getAllSolicitudBancaria()

      //console.log(this.dataAceptada,this.dataPendiente,this.dataRechazada,this.dataSaldado,this.dataSolicitudBancaria)
      if (this.dataAceptada.length > 0) {
        this.aceptada()
      }
      if (this.dataPendiente.length > 0) {
        this.pendiente()
      }
      if (this.dataRechazada.length > 0 && this.dataAceptada.length <= 0) {
        this.rechazada()
      }
      if (this.dataSaldado.length > 0) {
        this.saldado()
      }
    })
  }

  

  ngOnInit(): void {
    // this.getAllSolicitudBancariaPage(1, 100); // Trae los últimos 100 registros
    this.getAllSolicitudBancaria()
    this.aplicarfiltros()
    // this.getAllSolicitudBacariaOld()
  }
  ngOnChanges(changes: SimpleChanges): void {
    // this.getAllSolicitudBancaria()
    // this.aplicarfiltros()
  }

  limpiarTabla() {
    // Vaciar el array de datos y reiniciar el dataSource
    this.dataSolicitudBancaria = []
    this.dataSource = new MatTableDataSource(this.dataSolicitudBancaria) // Reinicia el dataSource con un array vacío
    this.dataSource.paginator = null // Resetear el paginador
    this.dataSource.sort = null // Resetear el sort

    console.log('Tabla vaciada')
  }

  getAllSolicitudBancaria() {
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (data: modeloSolicitudBancaria[]) => {
        data = data.filter((element) => element.estado !== 'Pendiente') // Filtrar elementos que no son 'Pendiente'
        data.map((element) => {
          if (
            element.estado == 'Aceptada' ||
            element.estado == 'Saldado' ||
            element.estado == 'Autorizada' ||
            element.estado == 'Pagado' ||
            element.estado == 'Rechazada'
          ) {
            //console.log(element)
            this.dataSolicitudBancaria.push(element)
            this.dataSource = new MatTableDataSource(this.dataSolicitudBancaria)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
            if (
              this.dataSolicitudBancaria.some(
                (element) => element.estadoFactura === 'Por Emitir'
              )
            ) {
              this.mostrarAlertaPorEmitir = true
            }
          }
        })

        this.dataSolicitudBancaria = data.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          Solicitud.perfilUsuario = Solicitud.Usuario?.nombreUsuario
          return Solicitud
        })
        this.rolUsuario = this.usuario.rol
      },
      (error) => {
        console.log('Se presentó el siguiente error: ', error)
      }
    )
  }
  
  // getAllSolicitudBancariaPage(page: number, pageSize: number) {
  //   this.consolidadoBancarioService.getAllSolicitudBancariaA(page, pageSize).subscribe(
  //     (response: any) => {
  //       if (response && Array.isArray(response.data)) { // Asegúrate de que el dato recibido es un array
  //         const solicitudesFiltradas = response.data.filter(element => element.estado !== 'Pendiente'); // Filtrar elementos que no son 'Pendiente'
  
  //         solicitudesFiltradas.map((element) => {
  //           if (
  //             element.estado == 'Aceptada' ||
  //             element.estado == 'Saldado' ||
  //             element.estado == 'Autorizada' ||
  //             element.estado == 'Pagado' ||
  //             element.estado == 'Rechazada'
  //           ) {
  //             this.dataSolicitudBancaria.push(element);
  //             this.dataSource = new MatTableDataSource(this.dataSolicitudBancaria);
  //             this.dataSource.paginator = this.paginator.toArray()[0];
  //             this.dataSource.sort = this.sort;
  
  //             if (this.dataSolicitudBancaria.some(el => el.estadoFactura === 'Por Emitir')) {
  //               this.mostrarAlertaPorEmitir = true;
  //             }
  //           }
  //         });
  
  //         // Formatear y actualizar datos
  //         this.dataSolicitudBancaria = solicitudesFiltradas.map((Solicitud) => {
  //           Solicitud.rut = format(Solicitud.rut);
  //           Solicitud.perfilUsuario = Solicitud.Usuario?.nombreUsuario;
  //           return Solicitud;
  //         });
  
  //         this.rolUsuario = this.usuario.rol;
  //       } else {
  //         console.error('Data is not an array or is undefined:', response);
  //       }
  //     },
  //     (error) => {
  //       console.log('Se presentó el siguiente error: ', error);
  //     }
  //   );
  // }
  //Boton para actualizar tabla de facturas
  actualizar(): void {
    this.formFilter.reset()

    this.limpiarTabla()
    this.limpiarFiltros()
  this.getAllSolicitudBancaria()
    // this.aplicarfiltros()
  }

 


  // Boton de busqueda
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
  
  //   // Filtrar entre los primeros 100 registros
  //   this.dataSource.filter = filterValue;
  
  //   // Si no se encuentra nada en los primeros 100 registros
  //   if (this.dataSource.filteredData.length === 0 && filterValue !== '') {
  //     // Limpiar la tabla usando la función limpiarTabla()
  //     this.limpiarTabla();
  
  //     // Llamar a la función que trae todos los registros
  //     this.consolidadoBancarioService. getAllSolicitudBacaria().subscribe(
  //       (data: modeloSolicitudBancaria[]) => {
  //         // Filtrar todos los registros por el valor buscado
  //         const allData = data.filter(element =>
  //           element.nombreResponsable?.toLowerCase().includes(filterValue) ||
  //           element.motivoPago?.toLowerCase().includes(filterValue)
  //         );
  
  //         if (allData.length > 0) {
  //           // Si se encuentran datos, mostrarlos en la tabla
  //           this.dataSource = new MatTableDataSource(allData);
  //           this.dataSource.paginator = this.paginator.toArray()[0];
  //           this.dataSource.sort = this.sort;
  //         } else {
  //           // Si no se encuentran datos, mostrar un mensaje y luego reiniciar la tabla
  //           console.log('No se encontraron registros.');
  //         }
  
  //         // Finalmente, reiniciar la tabla a los primeros 100 registros
  //         this.getAllSolicitudBancariaPage(1, 100);
  //       },
  //       (error) => {
  //         console.error('Error al buscar en todos los registros:', error);
  //       }
  //     );
  //   } else {
  //     // Si se encuentra el dato en los primeros 100 registros
  //     if (this.dataSource.paginator) {
  //       this.dataSource.paginator.firstPage();
  //     }
  //   }
  // }
  //Boton de acciones
  accionesPagoCuentaBancaria(id: any) {
    localStorage.setItem('idPagoBancario', id)
    this.consolidadoBancarioService.openDialogEditPagoCuentaBancaria()
  }
  //Boton de acciones
  PagoBanco(id: any) {
    this.consolidadoBancarioService.getByIdPagoBancario(id).subscribe(
      (data: any) => {
        if (data[0].estado === 'Aceptada' || data[0].estado === 'Autorizada') {
          if (data[0].banco === 'BancoEstado') {
            window.open(
              'https://www.bancoestado.cl',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoChile') {
            window.open(
              'https://portales.bancochile.cl/personas',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoSantander') {
            window.open(
              'https://banco.santander.cl/personas',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoScotiabank') {
            window.open(
              'https://appservtrx.scotiabank.cl/portalempresas/login?_ga=2.124175555.890232252.1666729010-1462344458.1666729010',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoBice') {
            window.open(
              'https://banco.bice.cl/empresas',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoItau') {
            window.open(
              'https://banco.itau.cl/wps/portal/newiol/web/login',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoFalabella') {
            window.open(
              'https://www.bancofalabella.cl/',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoSecurity') {
            window.open(
              'https://empresas.bancosecurity.cl/',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoRipley') {
            window.open(
              'https://www.bancoripley.cl/',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else {
            this.snackBar.open(
              `😭 Link no encontrado Por favor buscar en el navegador`,
              'X',
              {
                duration: 4000,
                verticalPosition: 'top'
              }
            )
          }
        }
        if (data[0].estado === 'Saldado' || data[0].estado === 'Pagado') {
          this.snackBar.open(`Este Pago ya fue Saldado 🤙`, 'X', {
            duration: 4000,
            verticalPosition: 'top'
          })
        }
        if (data[0].estado === 'Rechazada') {
          this.snackBar.open(`Este Pago ya fue Rechazado 🤌`, 'X', {
            duration: 4000,
            verticalPosition: 'top'
          })
        }
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }
  //Boton mostrar todos los datos
  todos() {
    // console.log('llegue a todos')
    // this.consolidadoBancarioService.getAllSolicitudBancariaA(1, 10).subscribe(data => {
    //   console.log('Datos recibidos:', data);
    // });
 
    this.getAllSolicitudBancaria()
    this.limpiarFiltros()
    // this.prueba() 
  }

  //Boton pendiente
  pendiente() {
    // Reiniciar el array de pendientes y la tabla
    this.dataPendiente = []
    this.dataSource = new MatTableDataSource([]) // Reiniciar el dataSource antes de traer los nuevos datos
    this.dataSource.paginator = null // Resetear paginador
    this.dataSource.sort = null // Resetear sort

    // Obtener datos del servicio
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        // Filtrar las solicitudes que están en estado 'Pendiente'
        const datosFiltrados = dato.filter(
          (element) => element.estado === 'Pendiente'
        )

        // Asignar datos filtrados a dataPendiente y actualizar la tabla
        this.dataPendiente = datosFiltrados
        this.dataSource = new MatTableDataSource(this.dataPendiente)

        // Configurar paginador y sort
        this.dataSource.paginator = this.paginator.toArray()[0]
        this.dataSource.sort = this.sort

        // Opcional: Aquí podrías agregar una alerta si no hay datos
        if (this.dataPendiente.length === 0) {
          console.log('No hay solicitudes pendientes.')
        }
      },
      (error) => {
        console.log('Se presentó el siguiente error: ', error)
      }
    )
  }

  //Boton aceptada
  aceptada() {
    // Reiniciar el array de aceptadas y la tabla
    this.dataAceptada = []
    this.dataSource = new MatTableDataSource([]) // Reiniciar el dataSource antes de traer los nuevos datos
    this.dataSource.paginator = null // Resetear el paginador
    this.dataSource.sort = null // Resetear el sort

    // Obtener datos del servicio
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        // Filtrar las solicitudes que están en estado 'Aceptada' o 'Autorizada'
        const datosFiltrados = dato.filter(
          (element) =>
            element.estado === 'Aceptada' || element.estado === 'Autorizada'
        )

        // Asignar los datos filtrados a dataAceptada y actualizar la tabla
        this.dataAceptada = datosFiltrados
        this.dataSource = new MatTableDataSource(this.dataAceptada)

        // Configurar el paginador y sort
        this.dataSource.paginator = this.paginator.toArray()[0]
        this.dataSource.sort = this.sort

        // Opcional: Aquí podrías agregar una alerta si no hay datos
        if (this.dataAceptada.length === 0) {
          console.log('No hay solicitudes aceptadas o autorizadas.')
        }
      },
      (error) => {
        console.log('Se presentó el siguiente error: ', error)
      }
    )
  }

  //Boton rechazada
  rechazada() {
    // Limpiar la tabla y el array de datos antes de cargar los nuevos
    this.dataRechazada = []
    this.dataSource = new MatTableDataSource([]) // Limpiar el dataSource
    this.dataSource.paginator = null // Reiniciar el paginador
    this.dataSource.sort = null // Reiniciar el sort

    // Obtener los datos desde el servicio
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        // Filtrar las solicitudes con estado 'Rechazada'
        const datosRechazados = dato.filter(
          (element) => element.estado === 'Rechazada'
        )

        // Asignar los datos filtrados al array y al dataSource
        this.dataRechazada = datosRechazados.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })

        // Actualizar el dataSource con los datos filtrados
        this.dataSource = new MatTableDataSource(this.dataRechazada)

        // Configurar el paginador y el sort
        this.dataSource.paginator = this.paginator.toArray()[0]
        this.dataSource.sort = this.sort

        // Mostrar un mensaje si no hay solicitudes rechazadas
        if (this.dataRechazada.length === 0) {
          console.log('No hay solicitudes rechazadas.')
        }
      },
      (error) => {
        console.log('Se presentó el siguiente error: ', error)
      }
    )
  }
  prueba() {
    // Limpiar la tabla y el array de datos antes de cargar los nuevos
    this.dataRechazada = []
    this.dataSource = new MatTableDataSource([]) // Limpiar el dataSource
    this.dataSource.paginator = null // Reiniciar el paginador
    this.dataSource.sort = null // Reiniciar el sort

    // Obtener los datos desde el servicio
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        // Filtrar las solicitudes con estado 'Rechazada'
        const datosRechazados = dato.filter(
          (element) =>
            element.estado === 'Pagada' ||
            element.estado === 'Autorizada' ||
            element.estado === 'Rechazada'
        )

        // Asignar los datos filtrados al array y al dataSource
        this.dataRechazada = datosRechazados.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })

        // Actualizar el dataSource con los datos filtrados
        this.dataSource = new MatTableDataSource(this.dataRechazada)

        // Configurar el paginador y el sort
        this.dataSource.paginator = this.paginator.toArray()[0]
        this.dataSource.sort = this.sort

        // Mostrar un mensaje si no hay solicitudes rechazadas
        if (this.dataRechazada.length === 0) {
          console.log('No hay solicitudes rechazadas.')
        }
      },
      (error) => {
        console.log('Se presentó el siguiente error: ', error)
      }
    )
  }

  Devuelto() {
    this.dataRechazada = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Devuelto') {
            this.dataRechazada.push(element)
            this.dataSource = new MatTableDataSource(this.dataRechazada)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
      },
      (error) => {
        console.log('Se a presentado el siguiente error: ', error)
      }
    )
  }

  downloadImage(id: any) {
    localStorage.setItem('idPagoOrdenCompra', id)
    this.http
      .get(`${environment.apiUrl}/solicitudBancaria/download-image/${id}`, {
        responseType: 'blob'
      })
      .subscribe((response) => {
        const url = URL.createObjectURL(response)
        const fileName = 'my-image.jpg'
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(blob)
          })
      })
  }
  /*
    downloadImage(id: any) {
    localStorage.setItem('idPagoOrdenCompra', id)
    this.http
      .get(`${environment.apiUrl}/solicitudBancaria/download-image/${id}`, {
        responseType: 'blob'
      })
      .subscribe((response) => {
        const url = URL.createObjectURL(response)
        const fileName = 'my-image.jpg'
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(blob)
          })
      })
  }

  */

  //Boton saldado
  saldado() {
    this.dataSaldado = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Saldado' || element.estado == 'Pagado') {
            this.dataSaldado.push(element)
            this.dataSource = new MatTableDataSource(this.dataSaldado)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  aplicarfiltros() {
    this.formFilter.valueChanges.subscribe((res) => {
      const { id, monto } = res
      let dataFiltered = this.dataSolicitudBancaria


       if (id) {
            dataFiltered = dataFiltered.filter(
              (data: modeloSolicitudBancaria) => data.id.toString() === id
            )
          }
      if (res.nombreTransferencia) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.nombreTransferencia.toString().includes(res.nombreTransferencia)
        )
      }
      if (res.rut) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.rut.toString().includes(res.rut)
        )
      }
      if (res.NumeroCuenta) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.NumeroCuenta.toString().includes(res.NumeroCuenta)
        )
      }
      if (res.jefeAutoriza) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.jefeAutoriza.toString().includes(res.jefeAutoriza)
        )
      }
      if (res.estado) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.estado.toString().includes(res.estado)
        )
      }
      if (res.montoPago) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.montoPago.toString().includes(res.montoPago)
        )
      }

      if (res.start && res.end) {
        dataFiltered = dataFiltered.filter(
          (data: modeloSolicitudBancaria) =>
            new Date(data.fechaSolicitud) >= res.start &&
            new Date(data.fechaSolicitud) <= res.end
        )
      }

      this.dataSource = new MatTableDataSource(dataFiltered)
      this.dataSource.paginator = this.paginator.toArray()[0]
      this.totalSeleccion = 0
      this.dataSource.sort = this.sort
      this.selection.clear()
    })
  }
  limpiarFiltros() {
    // Reinicia todos los campos del formulario a su estado inicial (vacío o null)
    this.formFilter.reset()

    // Vuelve a cargar todos los datos de la tabla sin aplicar filtros
    this.dataSource = new MatTableDataSource([])
    this.dataSource.paginator = this.paginator.toArray()[0]
    this.dataSource.sort = this.sort
    this.selection.clear() // Limpia la selección si aplica
    this.totalSeleccion = 0 // Reinicia el contador de selecciones
  }
  //SEELECCION DE ID
  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  // Metodo que sirve para la seleccion de un campo de la tabla
  masterToggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        })
  }

  //exportar execel
  exportAsXLSX(): void {
    this.selectedRows = [];
    this.selection.selected.forEach((x) => this.selectedRows.push(x));
  
    // Objeto de mapeo de nombres descriptivos
    const nombresDescriptivos: { [key: string]: string } = {
      Usuario: 'Usuario',
      NumeroCuenta: 'Número de Cuenta',
      nombreResponsable: 'Nombre del Responsable',
      rut: 'RUT',
      jefeAutoriza: 'Jefe Autoriza',
      fechaSolicitud: 'Fecha de Solicitud',
      estado: 'Estado',
      // archivos: 'Archivos',
      // archivoDevuelto: 'Archivo Devuelto',
      montoPago: 'Monto del Pago',
      inversion: 'Inversión',
      motivoPago: 'Motivo del Pago',
      razonRechazo: 'Razón del Rechazo',
      NumeroCuentaAsume: 'Número de Cuenta que Asume',
      asumeFactura: 'Asume Factura',
      banco: 'Banco',
      nombreTransferencia: 'Nombre de la Transferencia',
      bancoCliente: 'Banco del Cliente',
      link: 'Enlace',
      tipoCuenta: 'Tipo de Cuenta',
      // estadoFactura: 'Estado de la Factura',
      // fotoFactura: 'Respaldo de Factura',
      rutaFactura: 'Ruta de la Factura',
      rutaArchivopago: 'Ruta del Archivo de Pago',
      confirmadoPorFinanzas: 'Confirmado por Finanzas',
      perfilUsuario: 'Nombre del Usuario' // Usamos perfilUsuario para obtener el nombre del usuario
    };
  
    // Mapeamos los valores seleccionados con los nuevos nombres
    const mappedRows = this.selectedRows.map((row) => {
      const mappedRow: any = {};
      Object.keys(row).forEach((key) => {
        // Omitimos los campos que no queremos exportar
        if (key === 'idArriendo' || key === 'correo'|| key=='Usuario'|| key =='confirmado'|| key =='notificarPlazo'|| key=='archivos'
            || key==' estadoFactura'|| key== 'empresaAsumeGasto' || key== 'archivoDevuelto'|| key =='fotoFactura'


        ) {
          return; // No incluimos estos campos en el resultado final
        }
  
        // Reemplazamos 'Sucursal' por 'Sucursal.razonSocial'
        if (key === 'Sucursal' && row.Sucursal && row.Sucursal.razonSocial) {
          mappedRow['Sucursal'] = row.Sucursal.razonSocial;
          return; // Ya manejamos este campo, no necesitamos agregarlo más abajo
        }
  
        // Mapeamos el resto de las claves según el objeto nombresDescriptivos
        const newKey = nombresDescriptivos[key] || key; // Asigna el nuevo nombre si existe, si no, deja el original
        mappedRow[newKey] = row[key];
      });
      return mappedRow;
    });
    console.log(mappedRows)
    // Exportamos las filas mapeadas a Excel
    this.consolidadoBancarioService.exportAsExcelFile(
      mappedRows,
      'PAGO-ORDEN-DE-COMPRAS'
    );
  }
  
  // Función que obtiene el nombre de la sucursal basándose en el idSucursal
  obtenerNombreSucursal(idSucursal: number): string {
    let nombreSucursal = 'Sucursal no encontrada';
    this.sucursalService.getAll().pipe(first()).subscribe((sucursales: any) => {
      const sucursal = sucursales.find((element: any) => element.id === idSucursal);
      if (sucursal) {
        nombreSucursal = sucursal.razonSocial;
      }
    });
    return nombreSucursal;
  }

  //*
}
