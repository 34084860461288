import { Sucursal } from '../shared/sucursal';
import { Usuario } from '../shared/usuario';
export class GastosFijos {
    id!: string;
    fecha!: Date;
    monto!: number;
    gasto!: string;
    descripcion!: string;
    estado!: string
    archivos!:any
    Sucursal!: Sucursal;
    sucursal!: string;
    Usuario!: Usuario;
    usuario!: string;
    idSucursal!: any;
    idEmpresa!: any;


}