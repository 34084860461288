<!-- <div class="mat-elevation-z10">
    <mat-card class="shipping-card">
        <mat-card-header>
            <h1> Busqueda de clientes </h1>
        </mat-card-header>
        <br><br>
        <mat-card-content>
            
            <div class="row" style="display: flex; align-items: center;"> -->
                <!-- Rut Cliente -->
                <!-- <div class="col">
                  <form [formGroup]="addressForm" style="display: flex; align-items: center;">
                    <mat-form-field class="full-width" style="flex: 1;">
                      <input 
                        matInput 
                        placeholder="Búsqueda de Clientes por RUT" 
                        formControlName="rutCliente" 
                        aria-label="Ingresar RUT del cliente"
                      />
                    </mat-form-field>
                    <button 
                      mat-raised-button 
                      color="primary" 
                      (click)="buscarEx()" 
                      [disabled]="addressForm.get('rutCliente').invalid || addressForm.get('rutCliente').value.trim() === ''"
                      aria-label="Buscar cliente por RUT"
                      style="margin-left: 8px;" 
                    >
                      <mat-icon>search</mat-icon> Buscar
                    </button>
                  </form>
                </div>
              </div>
            <div class="row"> -->
                <!-- Contenedor de los botones centrado -->
                <!-- <div class="col boton-container"> -->
                    <!-- Boton mostrar tabla contratos clientes -->
                    <!-- <button mat-raised-button color="primary" type="submit" class="boton-inicio" (click)="activarTablaContratos('rutCliente')">
                        <mat-icon>visibility</mat-icon>  Mostrar Contratos Cliente
                    </button> -->
                    <!-- Boton Modal Generar Contrato-->
                    <!-- <button mat-raised-button color="primary" type="submit" class="boton-inicio" (click)="openDialogContratos()">
                        <mat-icon>note_add</mat-icon> Crear Contratos 
                    </button>
                    
                </div>
            </div>
            <br> -->
            <!-- Tabla Contratos Clientes -->
            <!-- <div *ngIf="validacion">
                <app-dialog-mostrar-contratos></app-dialog-mostrar-contratos>
            </div>
        </mat-card-content>
    </mat-card>
</div> -->
<h1>Búsqueda de Clientes</h1>
<mat-card class="shipping-card">
    <mat-expansion-panel hideToggle="">
        <mat-expansion-panel-header>
            <mat-panel-title> Búsqueda de Contratos del Cliente por RUT </mat-panel-title>
            <mat-panel-description>Presiona Aquí!</mat-panel-description>
            <mat-icon>touch_app_white_14dp</mat-icon>
        </mat-expansion-panel-header>
        <div class="floating-message">
           Pago de Cuotas al Buscar Contrato.
        </div>
        <mat-card-content>
            
            <div class="row" style="display: flex; align-items: center;">
                <!-- Rut Cliente -->
                <div class="col">
                  <form [formGroup]="addressForm" style="display: flex; align-items: center;">
                    <mat-form-field class="full-width" style="flex: 1;">
                      <input 
                        matInput 
                        placeholder="Búsqueda de Clientes por RUT" 
                        formControlName="rutCliente" 
                        aria-label="Ingresar RUT del cliente"
                      />
                    </mat-form-field>
                    <button 
                      mat-raised-button 
                      color="primary" 
                      (click)="buscarEx()" 
                      [disabled]="addressForm.get('rutCliente').invalid || addressForm.get('rutCliente').value.trim() === ''"
                      aria-label="Buscar cliente por RUT"
                      style="margin-left: 8px;" 
                    >
                      <mat-icon>search</mat-icon> Buscar
                    </button>
                  </form>
                </div>
              </div>
            <div class="row">
                <!-- Contenedor de los botones centrado -->
                <div class="col boton-container">
                    <!-- Botón mostrar tabla contratos clientes -->
                    <button mat-raised-button color="primary" type="submit" class="boton-inicio" (click)="activarTablaContratos('rutCliente')">
                        <mat-icon>visibility</mat-icon> Mostrar Contratos Cliente
                    </button>
                    <!-- Botón Modal Generar Contrato-->
                    <button mat-raised-button color="primary" type="submit" class="boton-inicio" (click)="openDialogContratos()">
                        <mat-icon>note_add</mat-icon> Crear Contratos 
                    </button>
                    
                </div>
            </div>
            <br>
            <!-- Tabla Contratos Clientes -->
            <div *ngIf="validacion">
                <app-dialog-mostrar-contratos></app-dialog-mostrar-contratos>
            </div>
        </mat-card-content>
    </mat-expansion-panel>
</mat-card>