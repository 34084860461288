import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import { environment } from '@environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { StockActualListComponent } from './stockActual-home/stockActual-list/stockActual-list.component';
import { DialogStockActualComponent } from './stockActual-home/stockActual-list/dialog-stockActual/dialog-stockActual.component';


const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'

@Injectable({
  providedIn: 'root'
})
export class StockActualService {
  constructor(private http: HttpClient, public dialog: MatDialog) {}

  infoTabla() {
    return this.http.get<[]>(`${environment.apiUrl}/stockActual/`)
  }

  infoTablaDetalle() {
    return this.http.get<[]>(`${environment.apiUrl}/stockActual/detalle`)
  }

  openDialogEditEgreso(
    id: number,
    nombre: string,
    categoria: string,
    nombreProducto: string
  ): void {
    const dialogRef = this.dialog.open(DialogStockActualComponent, {
      data: {
        id: id,
        nombre: nombre,
        categoria: categoria,
        producto: nombreProducto
      }
    })
    dialogRef.afterClosed().subscribe((res) => {})
  }

  CloseDialogVariante(): void {
    this.dialog.closeAll()
  }
  productoGet() {
    return this.http.get<[]>(`${environment.apiUrl}/stockActual/stock`)
  }

  getTotalInventary(params: any) {
    const url = `${environment.apiUrl}/productos/totalInventary`
    const queryParams = new HttpParams({ fromObject: params })
    return this.http.get<[]>(url, { params: queryParams })
  }

  getStockInventary(params: any) {
    const url = `${environment.apiUrl}/productos/stockInventary`
    const queryParams = new HttpParams({ fromObject: params })
    return this.http.get<[]>(url, { params: queryParams })
  }

  getStockInventarySalida() {
    const url = `${environment.apiUrl}/productos/stockInventarySaliente`
    return this.http.get<[]>(url)
  }

  getTotalInventarySalida() {
    const url = `${environment.apiUrl}/productos/totalInventarySaliente`
    return this.http.get<[]>(url)
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    console.log('datos que llegan', json)
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    )
  }
}
