<button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
  <mat-icon>highlight_off</mat-icon>
</button>

<form
  [formGroup]="addressForm"
  novalidate
  (ngSubmit)="onSubmit()"
  class="mat-elevation-z10"
>
  <mat-card class="shipping-card">
    <mat-card-header >
      <h1>Editar Egresos de importadora</h1>
    </mat-card-header>

    <mat-card-content>
      <!-- Fila 1 -->
      <div class="row">
        <!-- Tipo Egreso -->
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Tipo Egreso" formControlName="tipoEgreso">
              <mat-option *ngFor="let tipo of tiposEgresos" [value]="tipo">
                {{ tipo }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="addressForm.controls['tipoEgreso'].hasError('required')"
            >
              tipo egreso es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- Fila 2 -->
      <div class="row">
        <!-- Monto -->
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Monto" formControlName="monto" />
            <mat-error
              *ngIf="addressForm.controls['monto'].hasError('required')"
            >
              Este campo es <strong>requerido.</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Fecha -->

        <div class="col">
          <mat-form-field  class="full-width">
            <input matInput [matDatepicker]="picker"placeholder="Fecha" formControlName="fecha" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="addressForm.controls['fecha'].hasError('required')"
              >Este campo es <strong>requerido.</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- Fila 3 -->
      <div class="row">
        <!-- Tipo ingreso  -->
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Tipo Pago" formControlName="tipoPago">
              <mat-option *ngFor="let tipo of tiposPagos" [value]="tipo">
                {{ tipo }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="addressForm.controls['tipoPago'].hasError('required')"
            >
              tipo pago es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Responsable  -->
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Responsable"
              formControlName="responsable"
            />
            <mat-error
              *ngIf="addressForm.controls['responsable'].hasError('required')"
            >
              Este campo es <strong>requerido.</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" type="submit">Guardar</button>
    </mat-card-actions>
  </mat-card>
</form>
