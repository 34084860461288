import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ProveedoresHomeComponent } from './proveedores-home/proveedores-home.component'
import { ProveedoresTableComponent } from './proveedores-home/proveedores-table/proveedores-table.component'
import { ProveedoresFormComponent } from './proveedores-home/proveedores-form/proveedores-form.component'
import { ProveedoresRoutingModule } from './proveedores-routing.module'
import { MatTabsModule } from '@angular/material/tabs'
import { MaterialModule } from '@app/material.module'
import { ComponentsModule } from '@app/_components/components.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { FullCalendarModule } from '@fullcalendar/angular';
import { ProveedoresTableEditComponent } from './proveedores-home/proveedores-table/proveedores-table-edit/proveedores-table-edit.component'
import { MatTableExporterModule } from 'mat-table-exporter';


@NgModule({
  declarations: [
    ProveedoresHomeComponent,
    ProveedoresTableComponent,
    ProveedoresFormComponent,
    ProveedoresTableEditComponent
  ],
  imports: [
    CommonModule,
    ProveedoresRoutingModule,
    MatTabsModule,
    MaterialModule,
    HttpClientModule,
    ComponentsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FormsModule,
    MatTableExporterModule,




  ],
  exports: [
    ProveedoresRoutingModule,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class ProveedoresModule {}
