import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HostalRoutingModule } from './hostal-routing.module';
import { HostalHomeComponent } from './hostal-home/hostal-home.component';
import { MaterialModule } from 'src/app/material.module';
import { ComponentsModule } from 'src/app/_components/components.module';
import { HostalIngresosComponent } from './hostal-ingresos/hostal-ingresos.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HostalIngresosFormComponent } from './hostal-ingresos/hostal-ingresos-form/hostal-ingresos-form.component';
import { HttpClientModule } from '@angular/common/http';
import { HostalIngresosListComponent } from './hostal-ingresos/hostal-ingresos-list/hostal-ingresos-list.component';
import { HostalEgresosComponent } from './hostal-egresos/hostal-egresos.component';
import { HostalEgresosFormComponent } from './hostal-egresos/hostal-egresos-form/hostal-egresos-form.component';
import { HostalEgresosListComponent } from './hostal-egresos/hostal-egresos-list/hostal-egresos-list.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HostalConsolidadosComponent } from './hostal-consolidados/hostal-consolidados.component';
import { HostalEgresosCuotasComponent } from './hostal-egresos/hostal-egresos-list/hostal-egresos-cuotas/hostal-egresos-cuotas.component';
import { HostalEgresosCuotaDialogComponent } from './hostal-egresos/hostal-egresos-list/hostal-egresos-cuotas/hostal-egresos-cuota-dialog/hostal-egresos-cuota-dialog.component';
import { DialogEditEgresosComponent } from './hostal-egresos/hostal-egresos-list/dialog-edit-egresos/dialog-edit-egresos.component';
import { HostalIngresosFormularioComponent } from './hostal-ingresos/hostal-ingresos-formulario/hostal-ingresos-formulario.component';
import { HostalIngresosTablaComponent } from './hostal-ingresos/hostal-ingresos-tabla/hostal-ingresos-tabla.component';
import { HostalIngresosAccionesComponent } from './hostal-ingresos/hostal-ingresos-tabla/hostal-ingresos-acciones/hostal-ingresos-acciones.component';
import { HostalEgresosFormularioComponent } from './hostal-egresos/hostal-egresos-formulario/hostal-egresos-formulario.component';
import { HostalEgresosTablaComponent } from './hostal-egresos/hostal-egresos-tabla/hostal-egresos-tabla.component';
import { HostalEgresosAccionesComponent } from './hostal-egresos/hostal-egresos-tabla/hostal-egresos-acciones/hostal-egresos-acciones.component';
import { HostalGastosFijosComponent } from './hostal-gastos-fijos/hostal-gastos-fijos.component';
import { HostalGastosFijosCalendarioComponent } from './hostal-gastos-fijos/hostal-gastos-fijos-calendario/hostal-gastos-fijos-calendario.component';
import { HostalGastosFijosEditComponent } from './hostal-gastos-fijos/hostal-gastos-fijos-edit/hostal-gastos-fijos-edit.component';
import { HostalGastosFijosFormularioComponent } from './hostal-gastos-fijos/hostal-gastos-fijos-formulario/hostal-gastos-fijos-formulario.component';
import { HostalEgresosCreditoFormularioComponent } from './hostal-home/hostal-egresos-credito-formulario/hostal-egresos-credito-formulario.component';



@NgModule({
  declarations: [
    HostalHomeComponent,
    HostalIngresosComponent,
    HostalIngresosFormComponent,
    HostalIngresosListComponent,
    HostalEgresosComponent,
    HostalEgresosFormComponent,
    HostalEgresosListComponent,
    HostalConsolidadosComponent,
    HostalEgresosCuotasComponent,
    HostalEgresosCuotaDialogComponent,
    DialogEditEgresosComponent,
    HostalIngresosFormularioComponent,
    HostalIngresosTablaComponent,
    HostalIngresosAccionesComponent,
    HostalEgresosFormularioComponent,
    HostalEgresosTablaComponent,
    HostalEgresosAccionesComponent,
    HostalGastosFijosComponent,
    HostalGastosFijosCalendarioComponent,
    HostalGastosFijosEditComponent,
    HostalGastosFijosFormularioComponent,
    HostalEgresosCreditoFormularioComponent 
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    HostalRoutingModule,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
    FullCalendarModule,
  ],
  exports: [
    HostalRoutingModule,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule,   
  ]
})
export class HostalModule { }
