import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProveedoresHomeComponent } from './proveedores-home/proveedores-home.component';


const routes: Routes = [
  {path: 'proveedores', component: ProveedoresHomeComponent }

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProveedoresRoutingModule { }
