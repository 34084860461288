<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Comprobante 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
</mat-tab>
</mat-tab-group>
