import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { Observable, observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog'
import { environment } from '@environments/environment';

import { Bodega } from '@app/_models/inventario/bodega/bodega';
import { DialogBodegaEditarComponent } from "../../inventario/bodega/bodega-list/dialog-bodega-editar/dialog-bodega-editar.component";

/* Imports Excel */
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { map } from 'rxjs/operators';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'
/* Fin Import Excel */

@Injectable({
  providedIn: 'root'
})

export class BodegaService {
  

  private bodega_response$ = new Subject<any>();
  
  constructor(private http: HttpClient, public dialog: MatDialog) { }


  addResultBodega(termino: any){
    this.bodega_response$.next(termino)
  }

  getResultBodega() : Observable<any>{
    return this.bodega_response$.asObservable()
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditSucursal(): void {
      const dialogRef = this.dialog.open(DialogBodegaEditarComponent, {})
      dialogRef.afterClosed().subscribe((res) => {})
  }
  // Cerrar dialog 
  closeDialogModal() {
      this.dialog.closeAll()
  }

  bodegasGetAll(): any {
    return this.http.get<Bodega[]>(`${environment.apiUrl}/bodega`);
  }

  GetBodegas(): any {
    return this.http.get<[]>(`${environment.apiUrl}/bodega/ver`)
    .pipe(
      map((response:[]) => response.map(item => [item['nombre'], item['id'] ]))
    )
  }

  getById(id: any) {
    return this.http.get<Bodega>(`${environment.apiUrl}/bodega/${id}`);
  }

  getIdEmpresaSucursal(id: string) {
    return this.http.get(`${environment.apiUrl}/bodega/sucursal/${id}`);
  }

  create(bodega: Bodega) {
    return this.http.post(`${environment.apiUrl}/bodega`, bodega);
  }

  getSucursales(sucursales : any) {
    return this.http.post<[]>(`${environment.apiUrl}/bodega/sucursales`, sucursales);
  }

  crearBodegaEmpresa(idEmpresa: string, idBodega : string) {
    return this.http.get(`${environment.apiUrl}/bodega/empresas/${idEmpresa}/${idBodega}`);
  }

  update(id: number, params: any[]) {
    return this.http.put(`${environment.apiUrl}/bodega/${id}`, params);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/bodega/${id}`);
  }
 
}


