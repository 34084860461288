import { RequestResponse } from './../../_models/rentacar/requestResponse'
import { ResponseListaArriendos } from './../../_models/rentacar/responseListaArriendos'
import { BehaviorSubject, Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog'
import { RentacarEgresosCuotasComponent } from '@app/_pages/rentacar/rentacar-egresos/rentacar-egresos-list/rentacar-egresos-cuotas/rentacar-egresos-cuotas.component'
import { EgresoRentacarCuota } from '@app/_models/rentacar/egresoRentacarCuota'
import {GastosFijos} from '@app/_models/gastosFijos/gastosFijos'
import {RentacarGastosFijosComponent} from '@app/_pages/rentacar/rentacar-gastos-fijos/rentacar-gastos-fijos.component'
import { RentacarEgresosCuotaDialogComponent } from '@app/_pages/rentacar/rentacar-egresos/rentacar-egresos-list/rentacar-egresos-cuotas/rentacar-egresos-cuota-dialog/rentacar-egresos-cuota-dialog.component'
import { RentacarEgresosEditComponent } from './rentacar-egresos/rentacar-egresos-list/rentacar-egresos-edit/rentacar-egresos-edit.component'

import { Sucursal } from '@app/_models/shared/sucursal'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'

/* Imports Excel */
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { ConsolidadosRentacar } from '@app/_models/rentacar/consolidadosRentacar'
import { EgresosRentacar } from '@app/_models/rentacar/egresoRentacar'
import { IngresoRentacar } from '@app/_models/rentacar/ingresoRentacar'
import { IngresoEgresoAccionesComponent } from './rentacar-ingresos/rentacar-ingresos-list2/ingreso-egreso-acciones/ingreso-egreso-acciones.component'
import { RentacarEgresosAccionesComponent } from './rentacar-egresos/rentacar-egresos-table/rentacar-egresos-acciones/rentacar-egresos-acciones.component'
import { UsuarioSharedService } from '../shared/shared-services/usuario-shared.service'
import { RentacarGastosFijosEditComponent } from './rentacar-gastos-fijos/rentacar-gastos-fijos-edit/rentacar-gastos-fijos-edit.component'

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'
/* Fin Import Excel */

@Injectable({
  providedIn: 'root'
})
export class RentacarService {
  headers: HttpHeaders = new HttpHeaders({
    usertoken: environment.usertoken
  })

  //Crear listas
  public tiposIngresosList!: Observable<string[]>
  public tiposEgresosList!: Observable<string[]>
  sucursales: Sucursal[] = []

  private empresa = 'Rentacar'

  constructor(
    private sucursalService: SucursalSharedService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioSharedService,
    public dialog: MatDialog
  ) {
    this.sucursalService.sucursalList.subscribe((sucursales) => {
      this.sucursales = sucursales
    })
  }

  getListaPagosArriendos(): Observable<ResponseListaArriendos> {
    return this.http.get<ResponseListaArriendos>(
      `${environment.apiRentacarUrl}api/v2/mostrarArriendoFinanzas`,
      { headers: this.headers }
    )
  }

  getLicitaciones(): Observable<RequestResponse> {
    return this.http.get<RequestResponse>(
      `${environment.apiRentacarUrl}licitacion/cargarLicitaciones`,
      { headers: this.headers }
    )
  }

  postIngresoLicitacion(ingresoLicitacion: any): Observable<RequestResponse> {
    return this.http.post<RequestResponse>(
      `${environment.apiRentacarUrl}licitacion/crearIngreso`,
      ingresoLicitacion,
      { headers: this.headers }
    )
  }

  postSubirImagenes() {
    //respaldo
    return this.http.post<RequestResponse>(
      `${environment.apiRentacarUrl}licitacion/subirRespaldo`,
      { headers: this.headers }
    )
  }

  getIngresosLicitacion(): Observable<RequestResponse> {
    return this.http.get<RequestResponse>(
      `${environment.apiRentacarUrl}licitacion/cargarIngresos`,
      { headers: this.headers }
    )
  }

  // Inicio Rentacar Egresos

  createEgresos(egreso: any): any {
    return this.http.post(
      `${environment.apiUrl}/egresoRentacar/conRespaldo`,
      egreso
    )
  }
  createGastoFijos(gastos: GastosFijos): any {
    console.log('ruta', gastos)
    return this.http.post(`${environment.apiUrl}/gastosFijos`, gastos)
  }

  getGastoFijos(): Observable<GastosFijos[]> {
    return this.http.get<GastosFijos[]>(`${environment.apiUrl}/gastosFijos`)
  }

  openDialogGastoFijo(detallesGasto: any) {
    const dialogRef = this.dialog.open(RentacarGastosFijosEditComponent, {
      width: '1000px',
      height: '500px',
      data: detallesGasto
    })

    dialogRef.afterClosed().subscribe((result) => {})
  }
  /*oneGetGastoFijos(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/gastosFijos/oneGetGastoFijos/${id}`);
  }*/
  getByIdGastoFijo(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/gastosFijos/getIDSinBase64/${id}`
    )
  }
  getByIdGastoFijoImagen(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/gastosFijos/oneGetGastoFijos/${id}`
    )
  }
  /*   getByIdEgreso(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoRentACar/oneGetEgresoRentacar/${id}`
    )
  }*/

  oneGetGastoFijos(idGasto: number, archivos: string[]): Observable<any> {
    const body = { archivos }
    return this.http.put(`${environment.apiUrl}/gastosFijos/${idGasto}`, body)
  }
  updateGastoFijos(id: any, data: any) {
    return this.http.put(
      `${environment.apiUrl}/gastosFijos/updateGastoFijos/${id}`,
      data
    )
  }

  getAllCuentaBancaria(): any {
    return this.http.get<any[]>(`${environment.apiUrl}/formCuentaBancaria`)
  }

  getAllEgresos(): any {
    return this.http.get<[]>(`${environment.apiUrl}/egresoRentacar/getEgresos`)
  }

  buscarCuotas(): any {
    return this.http.get<EgresoRentacarCuota>(
      `${environment.apiUrl}/egresoRentacarCuota/`
    )
  }

  egresoGetFiles(fileName: string): any {
    return this.http
      .get(`${environment.apiUrl}/egresoRentacar/download/${fileName}`, {
        responseType: 'blob'
      })
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }

  buscarImagenEgreso(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoRentacar/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  public downloadEgresos(fileName: string): void {
    this.http
      .get('/files/${fileName}', { responseType: 'blob' })
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }

  /* api/egresoRentacar/getDetalle/8 */
  getDetalleEgreso(idEgreso: any): any {
    return this.http.get(
      `${environment.apiUrl}/egresoRentacar/getDetalle/${idEgreso}`
    )
  }

  getEgresosById(id: string): any {
    return this.http.get(`${environment.apiUrl}/egresoRentacar/getEgreso/${id}`)
  }

  getById(id: string): any {
    return this.http.get<any>(
      `${environment.apiUrl}/egreso${this.empresa}/${id}`
    )
  }

  /** metodo actualizar datos editados de una rentacar */
  updateRentacarEgreso(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egreso${this.empresa}/${id}`,
      params
    )
  }

  /* Egresos Por cuotas */

  /* CONSOLIDADOS */
  buscarConsolidado(consolidado: ConsolidadosRentacar): any {
    return this.http.post(
      `${environment.apiUrl}/ingreso${this.empresa}/ingresosEgresos`,
      consolidado
    )
  }

  getCuotas(id: any) {
    return this.http.get<EgresoRentacarCuota>(
      `${environment.apiUrl}/egresoRentacarCuota/${id}`
    )
  }

  buscarImagenCuota(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoRentacarCuota/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  agregarRespaldos(arrayRespaldos: any): any {
    return this.http.post(
      `${environment.apiUrl}/egresoRentacarCuota/agregarRespaldos/`,
      arrayRespaldos
    )
  }

  buscarImagenC(id: any): any {
    return this.http.get<EgresoRentacarCuota>(
      `${environment.apiUrl}/respaldoEgresoRentacarCuota/${id}`
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogCuota(): void {
    const dialogRef = this.dialog.open(RentacarEgresosCuotaDialogComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  updateMonto(id: any, body: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egresoRentacarCuota/${id}`,
      body
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogRegistrarPago(idEgreso: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idEgreso != null) {
      const dialogRef = this.dialog.open(RentacarEgresosCuotasComponent, {})
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open(
        'Por favor seleccione un egreso con cuotas sin pagar',
        'cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }

  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idEgresoPago')
  }

  // METODO PARA EXPORTAR EXCEL
  public async exportAsExcelFile(
    json: any[],
    excelFileName: string,
    selectedColumns: string[]
  ): Promise<void> {
    const columnMappings = {
      id: 'ID',
      nDocumento: 'Folio Contrato',
      fecha: 'Fecha',
      monto: 'Monto',
      nAutorizacion: 'N° Autorizacion',
      tipoPago: 'Tipo de Pago',
      cliente: 'Cliente',
      descripcionIngreso: 'Descripción Ingreso',
      tipoIngreso: 'Tipo de Ingreso',
      referenciaCliente: 'Referencia',
      idUsuario: 'Usuario',
      idSucursal: 'Sucursal'
      //'responsable': 'Responsable',
    }

    const newData = await Promise.all(
      json.map(async (item) => {
        const newItem: any = {}
        for (const col of selectedColumns) {
          if (col === 'idSucursal') {
            if (item[col] !== null) {
              try {
                const sucursal = await this.sucursalService
                  .getById(item[col])
                  .toPromise()
                newItem[columnMappings[col]] = sucursal.razonSocial
              } catch (error) {
                console.error(
                  'Error al obtener el nombre de la sucursal:',
                  error
                )
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else if (col === 'idUsuario') {
            if (item[col] !== null) {
              try {
                const usuario = await this.usuarioService
                  .getById(item[col])
                  .toPromise()
                newItem[columnMappings[col]] = usuario.nombre + usuario.apellido
              } catch (error) {
                console.error('Error al obtener el nombre del usuario:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else if (col === 'fecha') {
            if (item[col] !== null) {
              try {
                const fecha = new Date(item[col])
                const formattedFecha = `${fecha.getDate()}/${
                  fecha.getMonth() + 1
                }/${fecha.getFullYear()}`
                newItem[columnMappings[col]] = formattedFecha
              } catch (error) {
                console.error('Error al obtener la fecha:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else {
            newItem[columnMappings[col]] = item[col]
          }
        }

        return newItem
      })
    )

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData)

    // Establecer el estilo del encabezado (primera fila)
    const headerRange = XLSX.utils.decode_range(worksheet['!ref'])
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: C })
      console.log('Setting style for cell:', headerCell)
      // Limpiar cualquier estilo existente
      worksheet[headerCell].s = {}
      // Aplicar nuevo estilo
      worksheet[headerCell].s = {
        fill: { fgColor: { rgb: 'FFFF00' } },
        font: { bold: true }
      }
    }

    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    )
  }

  // Metodo que permite abrir un Dialog (Modal)-->editar
  openDialogEditEgreso(): void {
    const dialogRef = this.dialog.open(RentacarEgresosEditComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  deleteEgreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/egresoRentacar/${id}`)
  }

  deleteIngreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/ingresoRentacar/${id}`)
  }

  //Crear ingreso
  IngresoRentACar(modelo: IngresoRentacar): any {
    return this.http.post(`${environment.apiUrl}/ingresoRentacar`, modelo)
  }
  //Llamar tabla de Facturas
  getAllIngresoRentacar(): any {
    return this.http.get<[]>(`${environment.apiUrl}/ingresoRentacar`)
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditIngresoRentacar(): void {
    const dialogRef = this.dialog.open(IngresoEgresoAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }
  getByIdIngreso(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/ingresoRentacar/${id}`)
  }
  getByIdIngresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/ingresoRentacar/sinBase64/${id}`
    )
  }
  //Editar cliente
  updateIngresoRentacar(id: any, params: any) {
    if (Array.isArray(params.Servicios)) {
      params.Servicios = params.Servicios.join(' ')
    }
    return this.http.put(`${environment.apiUrl}/ingresoRentacar/${id}`, params)
  }

  //Crear egreso
  EgresoRentACar(modelo: EgresosRentacar): any {
    return this.http.post(`${environment.apiUrl}/egresoRentACar/form`, modelo)
  }

  //Llamar tabla de egreso
  getAllEgresoRentacar(): any {
    return this.http.get<[]>(`${environment.apiUrl}/egresoRentACar`)
  }
  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditEgresoRentacar(): void {
    const dialogRef = this.dialog.open(RentacarEgresosAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  getByIdEgreso(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoRentACar/oneGetEgresoRentacar/${id}`
    )
  }

  //Editar egreso
  updateEgresoRentacar(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egresoRentACar/UpdateForm/${id}`,
      params
    )
  }

  getByIdEgresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoRentACar/sinBase64/${id}`
    )
  }
  createCredito(egresoRentacar: EgresosRentacar): any {
    try {
      return this.http.post(
        `${environment.apiUrl}/egresoRentacar/createEgresoHome/`,
        egresoRentacar
      )
    } catch (error) {
      console.log(error)
      return error
    }
  }
  //Métodos para gestionar transacciones webpay
  pagar(idCuota: number, monto: number, empresa: string) {
    let body = {
      idCuota,
      monto,
      empresa
    }
    console.log(body)
    return this.http.post(`${environment.apiUrl}/egresoRentacar/pagar`, body)
  }
  confirmar(token: string) {
    console.log('HostalService token: ', token)
    let body = { token }
    console.log('Antes de hhtp post')
    return this.http.post(
      `${environment.apiUrl}/egresoRentacar/confirmar`,
      body
    )
  }
  actualizarPagado(id: number): any {
    let body = {
      id
    }
    return this.http.post(
      `${environment.apiUrl}/egresoRentacarCuota/actualizarPagado/`,
      body
    )
  }

  public async exportAsExcelFileEgresos(
    json: any[],
    excelFileName: string,
    selectedColumns: string[]
  ): Promise<void> {

    console.log("columnas seleccionadas en egreso rent a car", selectedColumns);
    const columnMappings = {
      id: 'ID',
      tipoEgreso: 'Tipo Egreso',
      fecha: 'Fecha',
      monto: 'Monto',
      responsable: 'Responsable',
      descripcion: 'Descripcion',
      tipoPago: 'Tipo Pago',
      idUsuario: 'Usuario',
      idSucursal: 'Sucursal'
    }

    const newData = await Promise.all(
      json.map(async (item) => {
        const newItem: any = {}
        for (const col of selectedColumns) {
          if (col === 'idSucursal') {
            if (item[col] !== null) {
              try {
                const sucursal = await this.sucursalService
                  .getById(item[col])
                  .toPromise()
                newItem[columnMappings[col]] = sucursal.razonSocial
              } catch (error) {
                console.error(
                  'Error al obtener el nombre de la sucursal:',
                  error
                )
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else if (col === 'idUsuario') {
            if (item[col] !== null) {
              try {
                const usuario = await this.usuarioService
                  .getById(item[col])
                  .toPromise()
                newItem[columnMappings[col]] = usuario.nombre + usuario.apellido
              } catch (error) {
                console.error('Error al obtener el nombre del usuario:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else if (col === 'fecha') {
            if (item[col] !== null) {
              try {
                const fecha = new Date(item[col])
                const formattedFecha = `${fecha.getDate()}/${
                  fecha.getMonth() + 1
                }/${fecha.getFullYear()}`
                newItem[columnMappings[col]] = formattedFecha
              } catch (error) {
                console.error('Error al obtener la fecha:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else {
            newItem[columnMappings[col]] = item[col]
          }
        }

        return newItem
      })
    )

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData)

    // Establecer el estilo del encabezado (primera fila)
    const headerRange = XLSX.utils.decode_range(worksheet['!ref'])
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: C })
      console.log('Setting style for cell:', headerCell)
      // Limpiar cualquier estilo existente
      worksheet[headerCell].s = {}
      // Aplicar nuevo estilo
      worksheet[headerCell].s = {
        fill: { fgColor: { rgb: 'FFFF00' } },
        font: { bold: true }
      }
    }

    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }
}
