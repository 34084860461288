import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { AdministracionService } from '@app/_pages/administracion/administracion.service';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';
import { IngresosLubricentro } from '../../../../../_models/lubricentro/ingresoLubricentro';
import { LubricentroService } from '../../../lubricentro.service';

@Component({
  selector: 'app-acciones-ingresos-list',
  templateUrl: './acciones-ingresos-list.component.html',
  styleUrls: ['./acciones-ingresos-list.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class AccionesIngresosListComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any

  idIngresoLubricentro: any
  fecha: any
  descripcionIngreso: any
  monto: any

  dataArrayImg = []
  tiposClientes: string[] = []
  referencias: string[]
  tiposPagos:string[] = []
  tipoDocumento : string[] = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  //Modelo de Ingreso de rentacar
  modelo: IngresosLubricentro = new IngresosLubricentro()

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private lubricentroService: LubricentroService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.tiposClientes = ['Particular', 'Empresa']
    this.referencias = [
      'Llamada',
      'Booking',
      'Correo',
      'PaginaWeb',
      'Facebook',
      'Directo a Hostal'
    ]
     this.tiposPagos = [
       'Efectivo',
       'Tarjeta de debito',
       'Tarjeta de credito',
       'Transferencia',
       'Cheque'
     ]
     this.tipoDocumento=[
      'Factura',
      'Boleta'
     ]
  }

  ngOnInit(): void {
    this.idIngresoLubricentro = localStorage.getItem('idIngresoLubricentro')
    this.getIngresoLubricentro()
  }

  //Configuracion de formulario
  addressForm = this.fb.group({
    //idSucursal: ['',],
    descripcionIngreso: ['',],
    fecha: [''],
    monto: [''],
    cliente: [''],
    telefono: ['',],
    correo: [''],
    tipoCliente: [''],
    referenciaCliente: [''],
    tipoPago:[''],
    tipoDocumento:[''],

    estadoPago: [''],
    nDocumento: [''],
    nAutorizacion: [''],
    tipoVehiculo: [''],
    ppu: [''],
    marca: [''],
    modelo: [''],
    anio: [''],
    kmActual: [''],
    kmProximo: [''],
    archivos: null
  })
  get f() {
    return this.addressForm.controls
  }

  getIngresoLubricentro() {
    this.lubricentroService
      .ingresoGetById(this.idIngresoLubricentro)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idIngresoLubricentro) {
          //this.f.idSucursal.setValue(x[0].idSucursal)
          this.f.descripcionIngreso.setValue(x[0].descripcionIngreso)
          this.f.fecha.setValue(x[0].fecha)
          this.f.monto.setValue(x[0].monto)
          this.f.tipoDocumento.setValue(x[0].tipoDocumento)
          this.f.cliente.setValue(x[0].cliente)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.telefono.setValue(x[0].telefono)
          this.f.correo.setValue(x[0].correo)
          this.f.tipoCliente.setValue(x[0].tipoCliente)
          this.f.referenciaCliente.setValue(x[0].referenciaCliente)
          this.f.estadoPago.setValue(x[0].estadoPago)
          this.f.nDocumento.setValue(x[0].nDocumento)
          this.f.nAutorizacion.setValue(x[0].nAutorizacion)
          this.f.tipoVehiculo.setValue(x[0].tipoVehiculo)
          this.f.ppu.setValue(x[0].ppu)
          this.f.marca.setValue(x[0].marca)
          this.f.modelo.setValue(x[0].modelo)
          this.f.anio.setValue(x[0].anio)
          this.f.kmActual.setValue(x[0].kmActual)
          this.f.kmProximo.setValue(x[0].kmProximo)

          this.f.archivos.setValue(x[0].archivos)
        }
        this.previsualizacion = x[0].archivos
      })
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.lubricentroService.closeDialogModal()
    window.location.reload()
  }

  //Capturar imagen
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop() // Obtener la extensión del archivo original
    const nuevoNombre = 'IngresoLubricentro' + Date.now() + '.' + extension // Crear un nombre único con el identificador generado dinámicamente
    this.dataArrayImg.push(nuevoNombre) // Agregar el nuevo nombre al array de nombres de archivo
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    }) // Crear un nuevo objeto de tipo File con el nuevo nombre
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }

  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/ingresoLubricentro/Upload/LubricentroIngreso`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

  // Actualizar formulario
  onSubmit() {
    if (
      confirm('Esta seguro que desea actualizar el Ingreso de Lubricentro: ')
    ) {
      this.lubricentroService
        .getByIdIngresoSinBase64(this.idIngresoLubricentro)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idIngresoLubricentro) {
            const nombreArchivo = x[0].archivos
            const JsonNombreArchivo = JSON.parse(nombreArchivo)
            if (this.dataArrayImg[0] === undefined) {
              this.addressForm.value.archivos = JsonNombreArchivo
            } else {
              this.addressForm.value.archivos = this.dataArrayImg
            }
            switch (this.addressForm.status) {
              case 'VALID':
                this.subirIMG()
                this.lubricentroService
                  .updateIngresoLubricentro(
                    this.idIngresoLubricentro,
                    this.addressForm.value
                  )
                  .pipe(first())
                  .subscribe(
                    (data) => {
                      this.snackBar.open(
                        'Ingreso de Lubricentro editado con éxito.',
                        'Cerrar',
                        {
                          duration: 2000,
                          verticalPosition: 'top'
                        }
                      )

                      this.addressForm.reset()
                      this.lubricentroService.closeDialogModal()
                      window.location.reload()
                    },
                    (error) => {
                      this.snackBar.open(
                        'No se pudo editar el Ingreso de Lubricentro, favor contactar a informática.',
                        'Cerrar',
                        {
                          duration: 2000,
                          verticalPosition: 'top'
                        }
                      )
                    }
                  )
                break

              case 'INVALID':
                this.snackBar.open(
                  'El formulario debe ser completado.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
                break

              default:
                break
            }
          }
        })
    }
  }

  //Actualizar formulario
  AgregarIMG() {
    if (
      confirm('Esta seguro que desea agregar IMG el Ingreso de Lubricentro: ')
    ) {
      this.lubricentroService
        .getByIdIngresoSinBase64(this.idIngresoLubricentro)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idIngresoLubricentro) {
            const data = x[0]
            this.addressForm.value.descripcionIngreso = data.descripcionIngreso
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.monto = data.monto
            this.addressForm.value.cliente = data.cliente
            this.addressForm.value.telefono = data.telefono
            this.addressForm.value.correo = data.correo
            this.addressForm.value.tipoCliente = data.tipoCliente
            this.addressForm.value.referenciaCliente = data.referenciaCliente
            this.addressForm.value.estadoPago = data.estadoPago
            this.addressForm.value.nDocumento = data.nDocumento
            this.addressForm.value.nAutorizacion = data.nAutorizacion
            this.addressForm.value.tipoVehiculo = data.tipoVehiculo
            this.addressForm.value.ppu = data.ppu
            this.addressForm.value.marca = data.marca
            this.addressForm.value.modelo = data.modelo
            this.addressForm.value.anio = data.anio
            this.addressForm.value.kmActual = data.kmActual
            this.addressForm.value.kmProximo = data.kmProximo

            this.addressForm.value.archivos = this.dataArrayImg

            this.lubricentroService
              .updateIngresoLubricentro(
                this.idIngresoLubricentro,
                this.addressForm.value
              )
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(
                    'Ingreso de Lubricentro editado con éxito.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )

                  this.addressForm.reset()
                  this.lubricentroService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el Ingreso de Lubricentro, favor contactar a informática.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }
}
