import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { EgresoPersonal } from '@app/_models/gastoPersonal/egresoGastoPersonal'
import { environment } from '@environments/environment'
import { PersonalEgresosAccionesComponent } from './gasto-personal-egresos/personal-egreso-tabla/personal-egresos-acciones/personal-egresos-acciones.component'
import { MatDialog } from '@angular/material/dialog'
import { GastosFijos} from '@app/_models/gastosFijos/gastosFijos'
import { Observable, BehaviorSubject } from 'rxjs'
import { GastoPersonalGastosFijosEditComponent} from './gasto-personal-gastos-fijos/gasto-personal-gastos-fijos-edit/gasto-personal-gastos-fijos-edit.component'

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'

@Injectable({
  providedIn: 'root'
})
export class GastoService {
  constructor(private http: HttpClient, private dialog: MatDialog) {}

  createEgresos(modelo: EgresoPersonal) {
    //console.log(modelo)
    return this.http.post(`${environment.apiUrl}/egresoPersonal`, modelo)
  }

  egresoGetAll(): any {
    return this.http.get<[]>(`${environment.apiUrl}/egresoPersonal`)
  }

  getByIdEgreso(id: any) {
    console.log(id);
    return this.http.get<any>(
      `${environment.apiUrl}/egresoPersonal/oneGetEgresoPersonal/${id}`
    )
  }

  getByIdEgresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoPersonal/getByIdSinBase64/${id}`
    )
  }

  updateEgresoHostal(id: any, params: any[]) {
    console.log(params, id)
    return this.http.put(
      `${environment.apiUrl}/egresoPersonal/updateEgresoPersonal/${id}`,
      params
    )
  }
  deleteEgreso(id: number) {    
    return this.http.delete(`${environment.apiUrl}/egresoPersonal/${id}`)
  }
  //===> acciones
  openDialogEditEgresoPersonal(): void {
    const dialogRef = this.dialog.open(PersonalEgresosAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idEgresoPersonal')
  }

  //===> Exportar a Excel
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + 'export' + new Date().getTime() + EXCEL_EXTENSION
    )
  }
  createGastoFijos(gastos: GastosFijos): any {
    console.log("ruta", gastos )
    return this.http.post(`${environment.apiUrl}/gastosFijos`, gastos);
  }

  getGastoFijos(): Observable<GastosFijos[]> {
    return this.http.get<GastosFijos[]>(`${environment.apiUrl}/gastosFijos`);
  }

  openDialogGastoFijo(detallesGasto: any) {
    const dialogRef = this.dialog.open(GastoPersonalGastosFijosEditComponent, {
      width: '1000px',
      height: '500px',
      data: detallesGasto,
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  getByIdGastoFijo(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/gastosFijos/getIDSinBase64/${id}`)
  }
  getByIdGastoFijoImagen(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/gastosFijos/oneGetGastoFijos/${id}`)
  }
  updateGastoFijos(id: any, data: any) {
    return this.http.put(
      `${environment.apiUrl}/gastosFijos/updateGastoFijos/${id}`, data
    );
  }
}
