import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientesHomeComponent } from './clientes-home/clientes-home.component';

const routes: Routes = [
  {path: 'clientes', component: ClientesHomeComponent }

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],

})
export class ClientesRoutingModule { }
