import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { modeloCuentasBancaria } from '@app/_models/consolidadoBancario/cuentasBancaria'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { first } from 'rxjs/operators'
import { ConsolidadoBancarioService } from '../../../consolidado-bancario.service'

@Component({
  selector: 'app-home-acciones',
  templateUrl: './home-acciones.component.html',
  styleUrls: ['./home-acciones.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class HomeAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any

  //Data par registrar una cuenta
  idformCuentaBancaria: any
  numeroCuenta: any
  nombreCuenta: any
  rut: any
  tipoMonto: any
  totalMonto: any
  banco: any
  addTotal:any

  modelo: modeloCuentasBancaria = new modeloCuentasBancaria()

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService
  ) {}

  ngOnInit(): void {
    this.idformCuentaBancaria = localStorage.getItem('idformCuentaBancaria')
    this.getFormCuentaBancaria()
  }
  ////----------------------------Cambiar estado ------
  //Configuracion de formulario
  addressForm = this.fb.group({
    numeroCuenta: null,
    nombreCuenta: null,
    rut: null,
    tipoMonto: null,
    totalMonto: null,
    banco: null,
    addTotal: null,

  })
  get f() {
    return this.addressForm.controls
  }
  getFormCuentaBancaria() {
    this.consolidadoBancarioService
      .getById(this.idformCuentaBancaria)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idformCuentaBancaria) {
          this.f.numeroCuenta.setValue(x[0].numeroCuenta)
          this.f.nombreCuenta.setValue(x[0].nombreCuenta)
          this.f.rut.setValue(x[0].rut)
          this.f.tipoMonto.setValue(x[0].tipoMonto)
          this.f.totalMonto.setValue(x[0].totalMonto)
          this.f.banco.setValue(x[0].banco)
        }
      })
  }

  onSubmit() {
    const primerValor = this.addressForm.value.totalMonto
    const segundoValor =  this.addressForm.value.addTotal
    const sumaTotal=  primerValor+segundoValor
    this.addressForm.value.totalMonto=sumaTotal

    if (confirm(`Esta seguro que desea actualizar el valor Total con esta cantidad: $${segundoValor} dando un total de $${sumaTotal}`)) {
      switch (this.addressForm.status) {
        case 'VALID':
          this.consolidadoBancarioService
            .updateCuentaBancaria(this.idformCuentaBancaria, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Cuenta editada con éxito.', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })

                this.addressForm.reset()
                this.consolidadoBancarioService.closeDialogModal()
                window.location.reload()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar la cuenta, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break

        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    }else{
      this.administracionService.closeDialogModal()
    }


  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.consolidadoBancarioService.closeDialogModal()
    window.location.reload()
  }
}
