import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ConsolidadoBancarioService } from '../../consolidado-bancario.service'
import { first, map } from 'rxjs/operators';
import { Sucursal } from '@app/_models/shared/sucursal'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { validateRUT, clearRUT } from 'validar-rut';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table'
import { FormControl, FormGroup } from '@angular/forms'
import { SelectionModel } from '@angular/cdk/collections'
import { BehaviorSubject } from 'rxjs';
import { formatDate } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { EgresosRentacar } from '@app/_models/rentacar/egresoRentacar'
import { EgresoHostal } from '@app/_models/hostal/egresoHostal'
import { RegistroEgresoFirma } from '@app/_models/abogados/egresosFirma';
import { EgresosInmobiliaria } from '@app/_models/inmobiliaria/egresoInmobiliaria'
import { EgresosFijoImportadora } from '@app/_models/importadora/egresoFijoImportadora';
import { EgresoLubricentro } from '@app/_models/lubricentro/egresoLubricentro';
import { RentacarService } from '@app/_pages/rentacar/rentacar.service';
import { InmobiliariaService } from '@app/_pages/inmobiliaria/inmobiliaria.service'
import { LubricentroService } from '@app/_pages/lubricentro/lubricentro.service';
import { HostalService } from '@app/_pages/hostal/hostal.service';
import { ImportadoraService } from '@app/_pages/importadora/importadora.service';
import { AbogadosService } from '@app/_pages/abogados/abogados.service';
import { Empresa } from '@app/_models/shared/empresa';
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service';
import { AuthSharedService } from '../../../shared/shared-services/auth-shared.service';

@Component({
  selector: 'app-consolidado-fromulario',
  templateUrl: './consolidado-fromulario.component.html',
  styleUrls: ['./consolidado-fromulario.component.scss']
})
export class ConsolidadoFromularioComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild(MatSort) sort = null;



  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>() 
  dataEgresosRentacar: EgresosRentacar[] = [];
  dataEgresosHostal: EgresoHostal[] = [];
  dataEgresosLubricentro: EgresoLubricentro[] = [];
  dataEgresosInmobiliaria: EgresosInmobiliaria[] = [];
  dataEgresosAbogados: RegistroEgresoFirma[] = [];
  dataEgresosInportadora: EgresosFijoImportadora[] = [];
  combinedData: any[] = [];

  selection: SelectionModel<
    EgresosRentacar | EgresoHostal | EgresoLubricentro | EgresosInmobiliaria | RegistroEgresoFirma | EgresosFijoImportadora
  >;


  //modeloDia: EgresosRentacar = new EgresosRentacar()


  displayedColumns: string[] = [
    'select',
    'id',
    'fecha',
    'monto',
    'tipoEgreso',
    'tipoPago',
    'sucursal',
    'empresa',
    'descripcion',
    
  ]

  result = 'N/A'
  totalSeleccion: number = 0;
  totalEgresos: number = 0;
  sucursales: Sucursal[] = []
  empresas: Empresa[] = [];
  tiposEgresos: string[] = [];
  tiposPagos: string[] = [];
  selectedRows!: any[]
  razonSocial: string;


  formFilter = new FormGroup({
    id: new FormControl(),
    monto: new FormControl(),
    start: new FormControl(),
    end: new FormControl(),
    descripcion: new FormControl(),
    tipoEgreso: new FormControl(),
    tipoPago: new FormControl(),
    idSucursal: new FormControl(),
    empresa: new FormControl(),

  })

  constructor(
    private router: Router,
    private rentacarService: RentacarService,
    private hostalService: HostalService,
    private importadoraService: ImportadoraService,
    private inmobiliariaService: InmobiliariaService,
    private lubricentroService: LubricentroService,
    private abogadosService: AbogadosService,
    private empresaService: EmpresaSharedService,
    private sucursalService: SucursalSharedService,
    private snackBar: MatSnackBar,
    private consolidadosService : ConsolidadoBancarioService
  ) {
    this.tiposEgresos = [
      'Gastos', 
      'Costos', 
      'Remuneraciones', 
      'Impuestos', 
      'Bancarios', 
      'Prestamos Bancarios', 
      'Prestamos Automotriz'
    ]
    this.tiposPagos =[
      'Efectivo',
      'Debito',
      'Credito',
      'Transferencia',
      'Cheque',
      'Billetera Copec',
    ]
    this.selection = new SelectionModel<
    EgresosRentacar | EgresoHostal | EgresoLubricentro | EgresosInmobiliaria | RegistroEgresoFirma | EgresosFijoImportadora
    >(true, []);
  }


  ngOnInit(): void {
    //this.dataSource = new MatTableDataSource<any>(); Está instanciado arriba
    this.dataSource.data = [
      ...this.dataEgresosRentacar,
      ...this.dataEgresosHostal,
      ...this.dataEgresosLubricentro,
      ...this.dataEgresosInmobiliaria,
      ...this.dataEgresosAbogados,
      ...this.dataEgresosInportadora
    ];

    //this.aplicarfiltros();
    this.getAllEgresoRentacar();
    this.getAllEgresoHostal();
    this.getAllEgresoLubricentro();
    this.getAllEgresoInmobiliaria();
    this.getAllEgresoAbogados();
    this.getAllEgresoInportadora();


    this.sucursalService.getAll().subscribe(sucursales => {
      this.sucursales = sucursales;
    });

    
    this.empresaService.getAll().subscribe(empresas => {
      this.empresas = empresas;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getAllEgresoRentacar();
  }

  getAllEgresoRentacar() {
    this.rentacarService.getAllEgresos()
      .subscribe((egresos: EgresosRentacar[]) => {
        this.dataEgresosRentacar = egresos.map(res=>{
          res.sucursal = res.Sucursal.razonSocial;
          return res;
        })
        this.combineDataAndSetDataSource();
      });
  }

  getAllEgresoHostal() {
    this.hostalService.egresoGetAll()
      .subscribe((egresos: EgresoHostal[]) => {
        this.dataEgresosHostal = egresos;
        this.combineDataAndSetDataSource();
      });
  }
  
  getAllEgresoLubricentro(){
    this.lubricentroService.egresoGetAll()
    .subscribe((egresos: EgresoLubricentro[]) => {
      this.dataEgresosLubricentro = egresos;
      this.combineDataAndSetDataSource();
    });
  }
  getAllEgresoInmobiliaria(){
    this.inmobiliariaService.getAllEgresos()
    .subscribe((egresos: EgresosInmobiliaria[]) => {
      this.dataEgresosInmobiliaria = egresos;
      this.combineDataAndSetDataSource();
    });
  }
  getAllEgresoAbogados(){
    this.abogadosService.egresoGetAll()
    .subscribe((egresos: RegistroEgresoFirma[]) => {
      this.dataEgresosAbogados = egresos;
      this.combineDataAndSetDataSource();
    });
  }
  getAllEgresoInportadora(){
    this.importadoraService.getAllEgresosFijo()
    .subscribe((egresos: EgresosFijoImportadora[]) => {
      this.dataEgresosInportadora = egresos;
      this.combineDataAndSetDataSource();
    });
  }



  getEmpresaNombre(idEmpresa: number): string {
    const empresa = this.empresas.find(empresa => empresa.id === idEmpresa);
    return empresa ? empresa.razonSocial : '';
  }
  
  actualizar(): void {
    window.location.reload()
  }

  revelarTotal() {
    this.totalSeleccion = 0
    //console.log(this.selection.selected.length)
    this.selection.selected.forEach(data => {
      console.log(data)
      this.totalSeleccion += data.monto;
    });  
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        })
  }
  
  aplicarfiltros(){
    this.formFilter.valueChanges.subscribe(res => {
      console.log("s")
      const { monto,fecha,descripcionEgreso,start, end,tipoEgreso,tipoPago,idSucursal,empresa,id} = res;
      let dataFiltered = [
        ...this.dataEgresosRentacar, 
        ...this.dataEgresosHostal, 
        ...this.dataEgresosLubricentro, 
        ...this.dataEgresosInmobiliaria, 
        ...this.dataEgresosAbogados,
        ...this.dataEgresosInportadora
      ];

        if (res.tipoEgreso){
          dataFiltered = dataFiltered.filter((data: EgresosRentacar
            | EgresoHostal
            | EgresoLubricentro
            | EgresosInmobiliaria
            | RegistroEgresoFirma
            | EgresosFijoImportadora) =>  
            data.tipoEgreso == tipoEgreso
            );
        }
        if (res.descripcion) {
          const descripcionEgreso = res.descripcion.toLowerCase(); 
          dataFiltered = dataFiltered.filter((data: EgresosRentacar 
            | EgresoHostal 
            | EgresoLubricentro 
            | EgresosInmobiliaria 
            | RegistroEgresoFirma
            | EgresosFijoImportadora) => {
            const dataDescripcion = data.descripcion?.toLowerCase(); 
            return dataDescripcion && dataDescripcion.includes(descripcionEgreso); 
          });
        }      
        if (res.tipoPago){
          dataFiltered = dataFiltered.filter((data: EgresosRentacar
            | EgresoHostal
            | EgresoLubricentro
            | EgresosInmobiliaria
            | RegistroEgresoFirma
            | EgresosFijoImportadora) =>  
            data.tipoPago == tipoPago
            );
        }
        if (res.idSucursal){
          dataFiltered = dataFiltered.filter((data: EgresosRentacar
            | EgresoHostal
            | EgresoLubricentro
            | EgresosInmobiliaria
            | RegistroEgresoFirma
            | EgresosFijoImportadora) =>  
            data.idSucursal == idSucursal
            );
        }
        if (res.monto){
          dataFiltered = dataFiltered.filter((data: EgresosRentacar
            | EgresoHostal
            | EgresoLubricentro
            | EgresosInmobiliaria
            | RegistroEgresoFirma
            | EgresosFijoImportadora) =>  
            data.monto == monto
            );
        }
        if (res.id){
          dataFiltered = dataFiltered.filter((data: EgresosRentacar
            | EgresoHostal
            | EgresoLubricentro
            | EgresosInmobiliaria
            | RegistroEgresoFirma
            | EgresosFijoImportadora) =>  
            data.id == id
            );
        }
        if (res.empresa) {
          dataFiltered = dataFiltered.filter((data: EgresosRentacar 
            | EgresoHostal 
            | EgresoLubricentro 
            | EgresosInmobiliaria 
            | RegistroEgresoFirma
            | EgresosFijoImportadora) => {
            const sucursal = this.sucursales.find(sucursal => sucursal.id === data.idSucursal);
            const empresa = sucursal ? this.empresas.find(empresa => empresa.id === sucursal.idEmpresa) : null;
            return empresa && empresa === res.empresa;
          });
        }
        if (start && end) {
          const startDate = new Date(start);
          const endDate = new Date(end);
          dataFiltered = dataFiltered.filter((data: 
            EgresosRentacar 
            | EgresoHostal 
            | EgresoLubricentro 
            | EgresosInmobiliaria 
            | RegistroEgresoFirma
            | EgresosFijoImportadora) => {
            const dataDate = new Date(data.fecha); 
            return dataDate >= startDate && dataDate <= endDate;
          });
        }        
        this.dataSource = new MatTableDataSource(dataFiltered);
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.dataSource.sort = this.sort;

    })
  }
  
  combineDataAndSetDataSource() {
    this.combinedData = [
      ...this.dataEgresosRentacar, 
      ...this.dataEgresosHostal,
      ...this.dataEgresosLubricentro,
      ...this.dataEgresosInmobiliaria,
      ...this.dataEgresosAbogados,
      ...this.dataEgresosInportadora
    ];

    this.dataSource.data = this.combinedData;
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.dataSource.sort = this.sort;

    
  }

  exportAsXLSX(){
    this.selectedRows = []
    if (this.selection.selected.length == 0) {
      this.snackBar.open('!Seleccione algún registro!', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    } else {
      this.selection.selected.forEach((x) => this.selectedRows.push(x))
      const newArray = this.selectedRows.map((item) => {
        const { RespaldoIngresos, Usuario, Sucursal, ...newObject } = item
        return newObject
      })

      this.consolidadosService.exportAsExcelFile(newArray, 'Lista-Consolidados')
  }
}
  

}
 

