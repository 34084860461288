<mat-toolbar color="primary">
    <button style="margin-right: 9rem" mat-raised-button color="accent" (click)="limpiarFiltros()">
        Limpiar filtros
      </button>

    <span style="margin-right: 9rem; margin-left: 9rem"> Listado Ajuste de Inventario </span>
    <!-- ***************************** cambiar metodo botón ********************************** -->
    <button mat-raised-button color="accent" style="margin-left: 6rem" (click)="exportAsXLSX()">
        Exportar
    </button>
    <button mat-raised-button color="accent" style="margin-left: 1rem" onclick="location.reload()">
            <mat-icon>autorenew</mat-icon>
    </button>
</mat-toolbar>

<div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">

         <!-- check column -->

         <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>


        <!-- Tipo -->
        <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input  matInput placeholder="Tipo" formControlName="tipo" />
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="col" *ngIf="element.tipoAjuste == 'salida'">
                    - Consumo<div style="color: #707070" class="bottom">{{element.estado}}</div>
                </div>
                <div class="col" *ngIf="element.tipoAjuste == 'entrada'">
                    + Recepcion<div style="color: #707070" class="bottom">{{element.estado}}</div>
                </div>
            </td>
        </ng-container>

        <!-- Fecha -->
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef>
                 <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input  matInput placeholder="Fecha" formControlName="fecha" />
                    </mat-form-field>
                    </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.fechaEmision }} <br></td>
        </ng-container>

        <!-- Bodega -->
        <ng-container matColumnDef="bodega">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Bodega" formControlName="bodega">
                            <mat-option *ngFor="let bodega of bodegas" [value]="bodega.nombre">
                                {{ bodega.nombre }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.nombreBodega }}</td>
        </ng-container>

        <!-- Proveedor -->
        <ng-container matColumnDef="proveedor">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                     <mat-form-field class="full-width">
                        <mat-select placeholder="Proveedor" formControlName="proveedor">
                            <mat-option *ngFor="let proveedor of proveedores" [value]="proveedor.nombre">
                                {{ proveedor.nombre }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.nombreProveedor }}</td>
        </ng-container>

        <!-- Documento -->
        <ng-container matColumnDef="documento">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input  matInput placeholder="Documento" formControlName="documento" />
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.numeroDocumento }} <br></td>
        </ng-container>

        <!-- Producto -->
        <ng-container matColumnDef="producto">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input  matInput placeholder="Producto"/>
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
            <button mat-mini-fab disabled style="background-color: #707070; color: white;">
                {{element.consulta}}
            </button>
            </td>

            </ng-container>

        <!-- Botones -->
        <ng-container matColumnDef="botones">

        <th mat-header-cell *matHeaderCellDef>
            <div [formGroup]="formFilter">
                <mat-form-field class="fill">
                    <input  matInput placeholder="Acciones" formControlName="botones" />
                </mat-form-field>
            </div>
        </th>
            <td mat-cell *matCellDef="let element">
                <section>

                    <button (click)="openDialogVer(element.id)" mat-mini-fab aria-label="Example icon button with a home icon">
                      <mat-icon>remove_red_eye</mat-icon>
                    </button>

                    <button (click)="cambiarEstado(element.id)"  mat-mini-fab  aria-label="Example icon button with a home icon" >
                      <mat-icon>delete</mat-icon>
                    </button>

                </section>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No existen coincidencias</td>
        </tr>
    </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>
