import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoriasService } from '@app/_pages/categorias/categorias.service';
import { ProductosService } from '@app/_pages/productos/productos.service';
import { FileUploader } from 'ng2-file-upload';
import { DialogData } from '../dialog-productos-foto.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-dialog-productos-foto-subir',
  templateUrl: './dialog-productos-foto-subir.component.html',
  styleUrls: ['./dialog-productos-foto-subir.component.scss']
})
export class DialogProductosFotoSubirComponent implements OnInit {

  responses: string[] = [];
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  idProducto: any;
  dataNuevo :any ;
  dataNuevo2 :any ;
  fotoDelete!: any;
  productosDelete!: any;
  constructor(
    public dialogRef: MatDialogRef<DialogProductosFotoSubirComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private productosService: ProductosService,
    private categoriasService: CategoriasService,
    private fb: FormBuilder,
  ) {
    this.uploader = new FileUploader({
      //url: `http://localhost:3000/api/productos/archivo`,
      //url: `https://www.imlchile.cl:3000/api/${this.data.url}`,      
      url: `${environment.apiUrl}/productos/archivo`,

      itemAlias: 'producto',
    });    
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {

      if (!response) {
        this.snackBar.open(`tenemos problemas para procesar su solicitud, favor contactar equipo de desarrollo`, 'cerrar', {
          duration: 2000,
        });
      } else {
        this.responses.push(response);
        this.snackBar.open(`Registro Exitoso: ${response}`, 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
      }

    };
    this.uploader.onCompleteAll = () => {      
      // ? la funcion envia al origen de la llamada la data
      // ? luego se debe subscribir a la data
      // ? metodo Mediante el metodo afterClose() del dialogComponent;
      this.dialogRef.close(this.responses);
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('id', this.idProducto);
     
   };

  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  ngOnInit(): void {
    this.idProducto = localStorage.getItem("idProductosEdit");
    
  }

  //Cerrar Modal
  closeDialog(){
    this.productosService.closeDialogModal();
  }

  



}
