import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AlertHelper } from '@app/_helpers/alert.helper'
import { modeloProveedor } from '@app/_models/proveedores/proveedores'
import { ProveedoresService } from '../../proveedores.service'
import { validateRUT, clearRUT } from 'validar-rut'

@Component({
  selector: 'app-proveedores-form',
  templateUrl: './proveedores-form.component.html',
  styleUrls: ['./proveedores-form.component.scss']
})
export class ProveedoresFormComponent implements OnInit {
  @Output()
  formularioListo = new EventEmitter<string>()
  panelOpenState = false
  subido = false
  form!: FormGroup

  //Modelo de proveedores
  modelo: modeloProveedor = new modeloProveedor()

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertHelper,
    private proveedoresService: ProveedoresService,
    private snackBar: MatSnackBar
  ) {
    this.buildForm()
  }

  //Creacion del grupo del formulario
  private buildForm() {
    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      rut: ['', [Validators.required]],
      giro: ['', [Validators.required]],
      direccion: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      comuna: ['', [Validators.required]],
      contacto: ['', [Validators.required]],
      tipoPago: ['', [Validators.required]],
      fija: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      descripcion: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.email]]
    })
  }
  tamano_rut: string
  ngOnInit(): void {}

  //Subir el formulario
  onSubmit() {
    switch (this.form.status) {
      case 'VALID':
        this.subido = true

        this.modelo.nombre = this.form.value.nombre
        this.modelo.rut = this.form.value.rut
        this.modelo.giro = this.form.value.giro
        this.modelo.direccion = this.form.value.direccion
        this.modelo.ciudad = this.form.value.ciudad
        this.modelo.comuna = this.form.value.comuna
        this.modelo.contacto = this.form.value.contacto
        this.modelo.tipoPago = this.form.value.tipoPago
        this.modelo.fija = this.form.value.fija
        this.modelo.celular = this.form.value.celular
        this.modelo.descripcion = this.form.value.descripcion
        this.modelo.email = this.form.value.email

        this.proveedoresService
          .registrarFormulario(this.modelo)
          .pipe()
          .subscribe(
            (data: any) => {
              if (this.form.valid) {
                this.alert.createAlert('Registro Creado con exito!')
                this.formularioListo.emit('true')
              }
              if (this.form.touched == true) {
                this.onReset()
              } else {
                this.alert.errorAlert('Error al crear proveedor!')
              }
            },
            (error: any) => {
              this.snackBar.open(
                'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              console.log(error)
            }
          )
        break
      case 'INVALID':
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break
      default:
        break
    }
  }

  formatoRut() {
    if (this.tamano_rut.length>=8 && this.tamano_rut.length<11 ) {
      const rut = this.form.value.rut
      const limpiarRut = clearRUT(rut)
      const validacionRut = validateRUT(limpiarRut)
      if (validacionRut == true) {
        console.log('El rut es valido para ser registrado')
        this.validarRut()
      } else {
        this.snackBar.open('Rut es invalido', 'X', {
          duration: 2000,
          verticalPosition: 'top'
        })
      }
    }
    else{

    }

  }
  validarRut() {
    this.proveedoresService.getAllProveedores().subscribe(
      (data: any) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].rut === this.form.value.rut) {
            this.snackBar.open('Rut ya existe', 'X', {
              verticalPosition: 'top'
            })
            break
          } else {
            this.snackBar.open('Rut no existe', 'X', {
              duration: 2000,
              verticalPosition: 'top'
            })
          }
        }
      },
      (err) => {
        console.log(err)
      }
    )
  }

  onReset(): void {
    this.subido = false
    this.form.reset()
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setErrors(null)
    })
  }
}
