<mat-card>
  <mat-toolbar color="primary">
    <span>Proveedores</span>
    <button
      style="margin-left: 1rem; margin-bottom: 3px"
      mat-raised-button
      color="accent"
      (click)="actualizar()"
    >
      Actualizar tabla <mat-icon>autorenew_white_18dp</mat-icon>
    </button>
    <br />

    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="exporter.exportTable('csv')"
    >
      Exportar a Excel <mat-icon>description_white_18dp</mat-icon>
    </button>
  </mat-toolbar>
</mat-card>

<div class="mat-elevation-z8">
  <mat-form-field appearance="standard">
    <mat-label>Buscar</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ingresa nombre, Giro, Direccion, Contacto"
      #input
    />
  </mat-form-field>
  <table
    mat-table
    matTableExporter
    [dataSource]="dataSource"
    #exporter="matTableExporter"
  >
    <!-- id Columna -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Nombre Columna -->
    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let element">{{ element.nombre }}</td>
    </ng-container>

    <!-- Giro Columna -->
    <ng-container matColumnDef="giro">
      <th mat-header-cell *matHeaderCellDef>Giro</th>
      <td mat-cell *matCellDef="let element">{{ element.giro }}</td>
    </ng-container>

    <!-- direccion Columna -->
    <ng-container matColumnDef="direccion">
      <th mat-header-cell *matHeaderCellDef>Direccion</th>
      <td mat-cell *matCellDef="let element">{{ element.direccion }}</td>
    </ng-container>

    <!-- contacto Columna -->
    <ng-container matColumnDef="contacto">
      <th mat-header-cell *matHeaderCellDef>Contacto</th>
      <td mat-cell *matCellDef="let element">{{ element.contacto }}</td>
    </ng-container>

    <!-- Acciones Columna -->
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef style="text-align: center">
        Acciones
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-mini-fab
          color="accent"
          style="margin-left: 2rem; margin-bottom: 3px"
          (click)="updateProveedor(element.id)"
        >
          <mat-icon>save_as_white_18dp</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          style="margin-left: 2rem; margin-bottom: 3px"
          (click)="deleteProveedor(element.id)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- <div *ngIf="!clickedRows.size"></div> -->

  <mat-paginator
    [pageSizeOptions]="[10, 20, 30]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
