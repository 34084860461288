import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-consolidado-bancario-solicitud',
  templateUrl: './consolidado-bancario-solicitud.component.html',
  styleUrls: ['./consolidado-bancario-solicitud.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class ConsolidadoBancarioSolicitudComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit(): void {
  }


}
