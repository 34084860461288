import { Usuario } from "../shared/usuario";

export class DetalleTransferenciaStock {
  idBodegaInicio!: number
  idBodegaFin!:number
  estado!: string
  fechaTransferencia: Date
  idUsuario!: number
  observacion!: string
  cantidadATransferir!: number
  idProductoServicio!: number
  idTransferencia!:number
  ubicacionInicio!:string
  ubicacionDestino!:string
}