export class AjusteInventario{
    id!: number;
    tipoAjuste!: string;
    fechaEmision!: Date;
    numeroDocumento!: string;
    observacion!: string;
    idProducto!: number;
    idProveedor!: number;
    idBodega!: number;
    nombreBodega!: string;
    nombreProveedor!: string;
    estado!: string;
    consulta!: number;
}