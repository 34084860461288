import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { ClientesService } from '@app/_pages/clientes/clientes.service'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-clientes-table-edit',
  templateUrl: './clientes-table-edit.component.html',
  styleUrls: ['./clientes-table-edit.component.scss']
})
export class ClientesTableEditComponent implements OnInit {
  panelOpenState = false
  blockSelect = true

  idCliente: any
  //informacion basica
  estado = false
  nombre: any
  alias: any
  rut: any
  giro: any
  codigo: any
  //informacion ubicacion
  direccion: any
  ciudad: any
  comuna: any
  //informacion pago
  nombreResponsable: any
  email: any
  telefono: any
  //otra informacion
  descuento: any
  credito: any
  contactoComercial: any
  emailComercial: any
  lineaCredito: any
  formaPago: any
  referencia: any
  telefonoComercial: any
  celular: any

  //configuracion de formulario
  addressForm = this.fb.group({
    //informacion basica
    nombre: ['', Validators.required],
    alias: ['', Validators.required],
    rut: ['', Validators.required],
    giro: ['', Validators.required],
    codigo: ['', Validators.required],
    estado: ['', Validators.required],
    //informacion ubicacion
    direccion: ['', [Validators.required]],
    ciudad: ['', [Validators.required]],
    comuna: ['', [Validators.required]],
    //informacion de pago
    nombreResponsable: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    telefono: [
      '',
      [Validators.required, Validators.maxLength(9), Validators.minLength(9)]
    ],
    //otra informacion
    contactoComercial: ['', [Validators.required]],
    emailComercial: ['', [Validators.required, Validators.email]],
    telefonoComercial: [
      '',
      [Validators.required, Validators.maxLength(9), Validators.minLength(9)]
    ],
    celular: [
      '',
      [Validators.required, Validators.maxLength(9), Validators.minLength(9)]
    ],
    descuento: ['', [Validators.required]],
    credito: ['', [Validators.required]],
    lineaCredito: ['', [Validators.required]],
    formaPago: ['', [Validators.required]],
    referencia: ['', [Validators.required, Validators.maxLength(200)]]
  })

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private clienteService: ClientesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.idCliente = localStorage.getItem('idCliente')
    this.getCliente()
  }
  get f() {
    return this.addressForm.controls
  }

  //metodo para buscar los datos
  getCliente() {
    this.clienteService
      .getById(this.idCliente)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idCliente) {
          //informacion basica
          this.f.nombre.setValue(x[0].nombre)
          this.f.alias.setValue(x[0].alias)
          this.f.rut.setValue(x[0].rut)
          this.f.giro.setValue(x[0].giro)
          this.f.codigo.setValue(x[0].codigo)
          this.f.estado.setValue(x[0].estado)
          //informacion ubicacion
          this.f.direccion.setValue(x[0].direccion)
          this.f.ciudad.setValue(x[0].ciudad)
          this.f.comuna.setValue(x[0].comuna)
          //informacion de pago
          this.f.nombreResponsable.setValue(x[0].nombreResponsable)
          this.f.email.setValue(x[0].email)
          this.f.telefono.setValue(x[0].telefono)
          //otra informacion
          this.f.descuento.setValue(x[0].descuento)
          this.f.credito.setValue(x[0].credito)
          this.f.contactoComercial.setValue(x[0].contactoComercial)
          this.f.emailComercial.setValue(x[0].emailComercial)
          this.f.lineaCredito.setValue(x[0].lineaCredito)
          this.f.formaPago.setValue(x[0].formaPago)
          this.f.referencia.setValue(x[0].referencia)
          this.f.telefonoComercial.setValue(x[0].telefonoComercial)
          this.f.celular.setValue(x[0].celular)
        }
      })
  }

  //Actualizar formulario
  onSubmit() {
    if (confirm('Esta seguro que desea actualizar el usuario: ')) {
      switch (this.addressForm.status) {
        case 'VALID':
          this.clienteService
            .updateCliente(this.idCliente, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Cliente editado con éxito.', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })

                this.addressForm.reset()
                this.clienteService.closeDialogModal()
                window.location.reload()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar el cliente, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break

        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    }
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
  }
}
