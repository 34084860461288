<form [formGroup]="egresosForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card class="shipping-card mat-elevation-z10">
    <mat-card-header>
      <h1>Egresos Hostal Plaza Maule</h1>
    </mat-card-header>
    <br /><br />

    <mat-card-content>
      <div class="row">
        <!-- sucursales -->
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Sucursales" formControlName="idSucursal">
              <mat-option
                *ngFor="let sucursal of sucursales"
                [value]="sucursal.id"
              >
                {{ sucursal.razonSocial }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="egresosForm.controls['idSucursal'].hasError('required')"
            >
              sucursal es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- fin -->
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select
              placeholder="Tipo Egreso"
              formControlName="tipoEgreso"
              (ngModelChange)="capturar()"
              [(ngModel)]="opcionSeleccionado"
            >
              <mat-option value="Gastos" (click)="desactivarEdicion()">
                Gastos
              </mat-option>
              <mat-option value="Costos" (click)="desactivarEdicion()">
                Costos
              </mat-option>
              <mat-option value="Remuneraciones" (click)="desactivarEdicion()">
                Remuneraciones
              </mat-option>
              <mat-option value="Bancarios" (click)="desactivarEdicion()">
                Bancarios
              </mat-option>
              <mat-option value="Impuestos" (click)="desactivarEdicion()">
                Impuestos
              </mat-option>
              <mat-option value="Inversiones" (click)="desactivarEdicion()">
                Inversiones
              </mat-option>
              <mat-option
                value="Prestamos Bancarios"
                (click)="activarEdicion()"
              >
                Prestamos Bancarios
              </mat-option>
              <mat-option
                value="Prestamos Automotriz"
                (click)="activarEdicion()"
              >
                Prestamos Automotriz
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="egresosForm.controls['tipoEgreso'].hasError('required')"
            >
              tipo ingreso es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <!-- select fecha -->
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Seleccionar fecha</mat-label>
            <input
              matInput
              formControlName="fecha"
              [matDatepicker]="picker"
              [readonly]="true"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="egresosForm.controls['fecha'].hasError('required')"
            >
              fecha es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- numero de cuotas -->
        <div class="col" *ngIf="!mostrarDatos">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Numero de cuotas"
              formControlName="numeroCuota"
              type="number"
            />
            <mat-error
              *ngIf="egresosForm.controls['numeroCuota'].hasError('required')"
            >
              numero de cuotas <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- monto -->
        <div class="col" *ngIf="mostrarDatos">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Monto"
              formControlName="monto"
              type="text"
              [ngModel]="result3"
              (ngModelChange)="transform($event)"
              (keypress)="restrictNumeric($event)"
            />
            <mat-error
              *ngIf="egresosForm.controls['monto'].hasError('required')"
            >
              Monto es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- monto de las cuotas -->
        <div class="col" *ngIf="!mostrarDatos">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Monto por cuota"
              formControlName="montoCuota"
              type="text"
              [ngModel]="result2"
              (ngModelChange)="transform($event)"
              (keypress)="restrictNumeric($event)"
            />
            Capital + Interes
            <mat-error
              *ngIf="egresosForm.controls['monto'].hasError('required')"
            >
              monto es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- responsable -->

        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="ingrese nombre responsable"
              formControlName="responsable"
            />
            <mat-error
              *ngIf="egresosForm.controls['responsable'].hasError('required')"
            >
              este campo es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <!-- descripcion -->
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="ingrese una descripcion del registro"
              formControlName="descripcion"
            />
            <mat-error
              *ngIf="egresosForm.controls['descripcion'].hasError('required')"
            >
              descripcion es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- tipo pago -->
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Tipo Pago" formControlName="tipoPago">
              <mat-option
                *ngFor="let tipoPago of tiposPagos"
                [value]="tipoPago"
              >
                {{ tipoPago }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="egresosForm.controls['tipoPago'].hasError('required')"
            >
              este campo es <strong>requrido</strong>
            </mat-error>
          </mat-form-field>
        </div> 
      </div>
       <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select
              placeholder="Grupo de envio de gastos"
              formControlName="grupoGastos"
            >
              <mat-option *ngFor="let g of grupos" [value]="g">
                {{ g }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="egresosForm.controls['grupoGastos'].hasError('required')"
            >
              este campo es <strong>requrido</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div> 
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" type="submit">
        <mat-icon>send</mat-icon> Enviar
      </button>
    </mat-card-actions>
  </mat-card>
</form>
