export class GuiaDespacho{
    bodega!: string
    cliente!: string
    rutCliente!: string
    giro!: string
    direccion!: string
    ciudad!: string
    comuna!: string
    contacto!: string
    plazoEntrega!: number
    vendedor!: string
    estado!: string
    folio!: number
    tipoDocumento!: string
    fechaCreacion!: string
    valida!: string
    formaPago!: string
    moneda!: string
    observacionesPago!: string
    ordenAlfabetico!: string
    comentarios!: string
    respTotal!: number
    iva!: number
    totalGeneral!: number
    productos!: []
    rutChofer: string
    rutTransporte: string
    nombreChofer!: string
    patente: string
    direccionDestino: string
    comunaDestino: string
    ciudadDestino: string
    tipoTraslado: string
    tipoTrasladoDTE: number
    
  }