import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockActualComponent } from './stockActual-home/stockActual.component';
import { StockActualRoutingModule } from './stockActual-routing.module';
import { MaterialModule } from '../../material.module';
import { ComponentsModule } from '@app/_components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { StockActualFiltrosComponent } from './stockActual-home/stockActual-filtros/stockActual-filtros.component';
import { StockActualDataComponent } from './stockActual-home/stockActual-data/stockActual-data.component';
import { StockActualListComponent } from './stockActual-home/stockActual-list/stockActual-list.component';
import { DialogStockActualComponent } from './stockActual-home/stockActual-list/dialog-stockActual/dialog-stockActual.component';
//import { FacturasFormComponent } from './facturas-home/facturas-form/facturas-form.component';

@NgModule({
  declarations: [	StockActualComponent, StockActualDataComponent,StockActualListComponent,StockActualFiltrosComponent, DialogStockActualComponent
      
  ],
  imports: [
    CommonModule,
    StockActualRoutingModule,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
  ]
  
})
export class StockActualModule { }
