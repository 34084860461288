import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { PrestamosService } from '@app/_pages/prestamos/prestamos.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-acciones-prestamos',
  templateUrl: './acciones-prestamos.component.html',
  styleUrls: ['./acciones-prestamos.component.scss']
})
export class AccionesPrestamosComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any

  idPrestamoEmpresa: any
  dataArrayImg = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private prestamosService: PrestamosService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.idPrestamoEmpresa = localStorage.getItem('idPrestamoEmpresa')
    this.getPrestamo()
  }

  addressForm = this.fb.group({
    fecha: [null, Validators.required],
    empresaS: [null, Validators.required],
    bancoS: [null, Validators.required],
    empresaD: [null, Validators.required],
    bancoD: [null, Validators.required],
    monto: [null, Validators.required],
    tipoPago: [null, Validators.required],
    responsable: [null, Validators.required],
    descripcion: [null, Validators.required],
    archivos: null
  })

  get f() {
    return this.addressForm.controls
  }

  getPrestamo() {
    this.prestamosService
      .getByIdPrestamos(this.idPrestamoEmpresa)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idPrestamoEmpresa) {
          this.f.archivos.setValue(x[0].archivos)
        }
        this.previsualizacion = x[0].archivos
      })

    this.prestamosService
      .getPrestamos(this.idPrestamoEmpresa)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idPrestamoEmpresa) {
          this.f.fecha.setValue(x[0].fecha)
          this.f.empresaS.setValue(x[0].empresaS)
          this.f.bancoS.setValue(x[0].bancoS)
          this.f.empresaD.setValue(x[0].empresaD)
          this.f.bancoD.setValue(x[0].bancoD)
          this.f.monto.setValue(x[0].monto)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.responsable.setValue(x[0].responsable)
          this.f.descripcion.setValue(x[0].descripcion)
        }
      })
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.prestamosService.closeDialogModal()
    window.location.reload()
  }
  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })
  //Capturar imagen
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop() // Obtener la extensión del archivo original
    const nuevoNombre = 'Prestamo-' + Date.now() + '.' + extension // Crear un nombre único con el identificador generado dinámicamente
    this.dataArrayImg.push(nuevoNombre) // Agregar el nuevo nombre al array de nombres de archivo
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    }) // Crear un nuevo objeto de tipo File con el nuevo nombre
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/prestamos/Upload/Prestamo`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

  //Actualizar formulario
  AgregarIMG() {
    if (confirm('Esta seguro que desea agregar IMG en el prestamo: ')) {
      this.prestamosService
        .getByIdPrestamoSinBase64(this.idPrestamoEmpresa)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idPrestamoEmpresa) {
            const data = x[0]
            data.archivos = this.dataArrayImg
            this.prestamosService
              .updatePrestamo(this.idPrestamoEmpresa, data)
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open('Prestamo editado con éxito.', 'Cerrar', {
                    duration: 2000,
                    verticalPosition: 'top'
                  })

                  this.addressForm.reset()
                  this.prestamosService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el prestamo, favor contactar a informática.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }

  //Actualizar formulario
  onSubmit() {
    if (confirm('Esta seguro que desea actualizar el Prestamo: ')) {
      this.prestamosService
        .getByIdPrestamoSinBase64(this.idPrestamoEmpresa)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idPrestamoEmpresa) {
            const nombreArchivo = x[0].archivos
            const JsonNombreArchivo = JSON.parse(nombreArchivo)
            if (this.dataArrayImg[0] === undefined) {
              this.addressForm.value.archivos = JsonNombreArchivo
            } else {
              this.addressForm.value.archivos = this.dataArrayImg
            }
            this.prestamosService
              .updatePrestamoDetalle(this.idPrestamoEmpresa, this.addressForm.value)
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open('Prestamo editado con éxito.', 'Cerrar', {
                    duration: 2000,
                    verticalPosition: 'top'
                  })

                  this.addressForm.reset()
                  this.prestamosService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el prestamo, favor contactar a informática.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }
}
