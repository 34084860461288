<form [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
  <mat-card class="shipping-card mat-elevation-z10">
    <!-- Presentacion del Registro -->
    <mat-card-header>
      <h1>Registro de Proveedores</h1>
    </mat-card-header>
    <br /><br />
    <mat-card-content>
      <mat-card>
        <p>Informacion Basica</p>
        <hr />
        <div class="row">
          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Ingrese Nombre</mat-label>
              <input
                matInput
                type="string"
                placeholder="Nombre"
                formControlName="nombre"
              />
              <mat-icon matSuffix>badge_white_18dp</mat-icon>
              <mat-error *ngIf="form.controls['nombre'].hasError('required')">
                <strong> El nombre es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Ingrese Rut</mat-label>
              <input
                matInput
                minlength="8"
                maxlength="10"
                type="string"
                required
                placeholder="Rut"
                [(ngModel)]="tamano_rut"
                formControlName="rut"
                (input)="formatoRut()"
              />
              <mat-icon matSuffix>branding_watermark_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['rut'].hasError('required')
                "
              >
                <strong> El rut es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Ingrese Giro</mat-label>
              <input
                matInput
                type="string"
                class="example-right-align"
                formControlName="giro"
              />
              <span matPrefix>$&nbsp;</span>
              <mat-icon matSuffix>currency_exchange_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['giro'].hasError('required') &&
                  form.get('giro')?.touched
                "
              >
                <strong> El giro es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <br />
      <mat-card>
        <p>Informacion de Ubicación</p>
        <hr />

        <div class="row">
          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Ingrese Direccion</mat-label>
              <input
                matInput
                placeholder="Direccion"
                formControlName="direccion"
              />
              <mat-icon matSuffix>home_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['direccion'].hasError('required') &&
                  form.get('direccion')?.touched
                "
              >
                <strong> La direccion es requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Ingrese Ciudad</mat-label>
              <input matInput placeholder="Ciudad" formControlName="ciudad" />
              <mat-icon matSuffix>location_city_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['ciudad'].hasError('required') &&
                  form.get('ciudad')?.touched
                "
              >
                <strong> La ciudad es requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Ingrese Comuna</mat-label>
              <input matInput placeholder="Comuna" formControlName="comuna" />
              <mat-icon matSuffix>push_pin_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['comuna'].hasError('required') &&
                  form.get('comuna')?.touched
                "
              >
                <strong> La comuna es requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>

      <br />

      <mat-card>
        <p>Informacion de Contacto</p>
        <hr />

        <div class="row">
          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Ingrese Contacto</mat-label>
              <input
                matInput
                placeholder="Contacto"
                formControlName="contacto"
              />
              <mat-icon matSuffix>contacts_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['contacto'].hasError('required') &&
                  form.get('contacto')?.touched
                "
              >
                <strong> El contacto es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Ingrese Email</mat-label>
              <input
                matInput
                placeholder="pat@example.com"
                formControlName="email"
              />
              <mat-icon matSuffix>mail_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['email'].hasError('required') &&
                  form.get('email')?.touched
                "
              >
                <strong> El email es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Tipo de Pago </mat-label>
              <mat-select formControlName="tipoPago">
                <mat-option value="cheque">Cheque</mat-option>
                <mat-option value="credito">Credito</mat-option>
                <mat-option value="DepositoEfectivo"
                  >Deposito en efectivo</mat-option
                >
                <mat-option value="efectivo">Efectivo</mat-option>
                <mat-option value="tarjetaCredito">Tarjeta Credito</mat-option>
                <mat-option value="tarjetaDebito">Tarjeta Debito</mat-option>
                <mat-option value="transferenicaElectronica"
                  >Transferencia Electronica</mat-option
                >
                <mat-option value="notaCreditoDevolucion"
                  >Nota Credito Devolcuion</mat-option
                >
                <mat-option value="webPay">Webpay</mat-option>
              </mat-select>
              <mat-icon matSuffix>payments_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['tipoPago'].hasError('required') &&
                  form.get('tipoPago')?.touched
                "
              >
                <strong> El tipo de pago es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Ingrese Red Fija</mat-label>
              <input
                matInput
                placeholder="Fija"
                formControlName="fija"
                type="number"
              />
              <mat-icon matSuffix>add_call_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['fija'].hasError('required') &&
                  form.get('fija')?.touched
                "
              >
                <strong> El telefono fijo es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Ingrese Celular</mat-label>
              <input
                matInput
                placeholder="Celular"
                formControlName="celular"
                type="number"
              />
              <mat-icon matSuffix>phone_iphone_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['celular'].hasError('required') &&
                  form.get('number')?.touched
                "
              >
                <strong> El celular es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field
              appearance="legacy"
              style="margin-top: -1rem; margin-bottom: 3px"
            >
              <mat-label>Descripcion</mat-label>
              <textarea matInput formControlName="descripcion"></textarea>
              <mat-icon matSuffix>description_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  form.controls['descripcion'].hasError('required') &&
                  form.get('descripcion')?.touched
                "
              >
                <strong> La descripcion es requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </mat-card-content>

    <!-- Boton para enviar Formulario -->
    <mat-card-actions>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="form.invalid"
      >
        <mat-icon>send</mat-icon> Enviar
      </button>
    </mat-card-actions>
  </mat-card>
</form>
