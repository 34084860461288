import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Usuario } from '@models/shared/usuario';
import { Cesionario } from '@models/cesionarios/cesionario';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AlertHelper } from '@app/_helpers/alert.helper';
import { first } from 'rxjs/operators';
import { ConfiguracionService } from '../configuracion.service';

import {  validate, clean, format, getCheckDigit } from 'rut.js'
import { RutValidatorService } from '../../validator/rut-validator.service';
import { RutValidoService } from '../../validator/rut-valido.service';



@Component({
  selector: 'app-configuracion-cesionarios-form',
  templateUrl: './configuracion-cesionarios-form.component.html',
  styleUrls: ['./configuracion-cesionarios-form.component.scss']
})
export class ConfiguracionCesionariosFormComponent implements OnInit {

  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '');
  empresas : any;
  cesionarios : any;
  tamano_rut : string =  '';
  
  //TODO hacer los paterns en un servicio aparte
  private emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

  get nombre() { return this.addressForm.get('nombre'); }
  get direccion() { return this.addressForm.get('direccion'); }
  get telefono() { return this.addressForm.get('telefono'); }

  // ? Configuración de formulario
  addressForm = this.fb.group({
    nombre: ['', [Validators.required, Validators.minLength(5)]],
    rut: ['', [Validators.required], [this.rutValidator, this.rutValido] ],
    contacto : ['', Validators.minLength(5)],
    email: ['', [Validators.required, Validators.minLength(5), Validators.pattern(this.emailPattern)]],
    telefono: ['', Validators.required],
    direccion: ['',[Validators.required, Validators.minLength(5)]],
  });

  cesionariosDatos: Cesionario = new Cesionario();

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private configuracionService: ConfiguracionService,
    private alert: AlertHelper,
    private rutValidator: RutValidatorService,
    private rutValido: RutValidoService
  ) { }

  ngOnInit(): void {}
  validarRut(rut:string){
    this.configuracionService
    .getByRut(rut)
    .pipe(first())
    .subscribe((cesionarios: any) => {
      this.cesionarios = cesionarios;
      //console.log(this.cesionarios)
    });
  }

  formatoRut() {
    if (this.tamano_rut.length >= 10 && this.tamano_rut.length < 11) {
      const rut = this.addressForm.value.rut
      const limpiarRut = clean(rut)
      const validacionRut = validate(limpiarRut)
      if (validacionRut == true) {
        this.tamano_rut = format(this.addressForm.value.rut)
        console.log('El rut es valido para ser registrado')
        //console.log(this.validarRut(clean(this.tamano_rut).toString()))
      } else {
        this.snackBar.open('Rut es invalido', 'X', {
          duration: 2000,
          verticalPosition: 'top'
        })
      }
    } else {
    }
  }

  cleanPuntos(rut: string): string {
    return rut.replace(/\./g, '');
  }

  getCesionarios(){
    this.configuracionService
    .cesionariosGetAll()
    .pipe(first())
    .subscribe((cesionarios: any) => {
      this.cesionarios = cesionarios;
    });
  }
  //Metodo guardar cesionario
  onSubmit(){
      switch (this.addressForm.status) {
        //Si el formulario esta correcto
        case 'VALID':
          this.addressForm.value.rut = this.cleanPuntos(this.addressForm.value.rut);
          console.log("desde vlaue", this.addressForm.value.rut);
          console.log("desde funcion",this.cleanPuntos(this.addressForm.value.rut));
          this.addressForm.value.estado = '1';
          this.configuracionService
        .create(this.addressForm.value)
        .pipe()
        .subscribe(
          (data) => {
            // this.snackBar.open('Cesionario ingresado con exito', 'cerrar', {
            //   duration: 2000,
            //   verticalPosition: 'top',
            // });
            //this.addressForm.reset();
            window.location.reload()
            this.configuracionService.addResultCesionario('Holaaaa')
          },
          (error) => {
            this.snackBar.open('No se pudo ingresar la sucursal, contacte con informatica', 'cerrar', {
              duration: 2000,
              verticalPosition: 'top',
            });
          }
        );
          break;
        
        //Si el formulario es erroneo 
        case 'INVALID':
          this.snackBar.open('EL formulario debe ser Completado', 'cerrar', {
            duration: 2000,
            verticalPosition: 'top',
          });
          console.log("el formulario esta arrojando error")
          break;
        
        default:
          break;
      }
  
  }

}
