<mat-dialog-content>
    <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
        <mat-icon>highlight_off</mat-icon>
    </button>
    <form [formGroup]="addressFormContrato" novalidate class="mat-elevation-z10">
        <mat-card class="shipping-card">
            <mat-card-header>
                <h1> Cliente: {{ nombreClienteLocal }}
                </h1>
                <h1 class="nContrato"> Repactando Contrato N°: {{ contratoR.nContrato }}
                </h1>
            </mat-card-header>
            <br><br>
            <mat-card-content>
                <div class="row">
                    <div class="col">
                        <h1>Saldo Pendiente: {{ contratoR.saldoPendiente | currency: "$":"symbol":"1.0-0" }}</h1>
                        <mat-toolbar color="primary">
                            <span> Estado Cuotas </span>
                        </mat-toolbar>
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">
                                <!-- data columns -->
                                <ng-container matColumnDef="fecha">
                                    <th mat-header-cell *matHeaderCellDef> Fecha</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.fechaPago }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="monto">
                                    <th mat-header-cell *matHeaderCellDef> Monto Cuota</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.montoCuota | currency: "$":"symbol":"1.0-0" }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="estadoPago">
                                    <th mat-header-cell *matHeaderCellDef> Estado Pago</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.estado }}
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">No existen coincidencias</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <br>
                    <h1>Repactar Cuotas</h1>
                    <br>
                </div>

                <div class="row">
                    <!-- select fecha -->
                    <div class="col">
                        <mat-form-field class="full-width">
                            <mat-label>Seleccionar nueva fecha inicio</mat-label>
                            <input matInput formControlName="fechaCuota" [matDatepicker]="picker" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="addressFormContrato.controls['fechaCuota'].hasError('required')">
                                fecha es <strong>requerido</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Monto próxima cuota" formControlName="montoCuota" type="number" />
                            <mat-error *ngIf="addressFormContrato.controls['montoCuota'].hasError('required')">
                                este campo es <strong>requerido</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Cantidad de cuotas" formControlName="cantidadCuota" type="number" />
                            <mat-error *ngIf="addressFormContrato.controls['cantidadCuota'].hasError('required')">
                                este campo es <strong>requerido</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <!-- botón enviar -->
                        <button mat-raised-button color="primary" class="btn-calcular" (click)="calcularCuotas()">
                            <mat-icon>calculate</mat-icon> Calcular
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <h1>Cuotas Registradas</h1>
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSourceCuotas" class="mat-elevation-z10">

                                <ng-container matColumnDef="fechaCuotaTabla">
                                    <th mat-header-cell *matHeaderCellDef> Fecha </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.fechaPago }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="montoCuotaTabla">
                                    <th mat-header-cell *matHeaderCellDef> Monto </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.montoCuota | currency: "CLP" }}
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">No existen coincidencias</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <br>
                <!-- botón enviar -->
                <button mat-raised-button color="primary" class="btn-calcular" (click)="repactar()">
                    <mat-icon>send</mat-icon> Guardar
                </button>
            </mat-card-content>
        </mat-card>
    </form>
</mat-dialog-content>