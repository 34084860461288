import { Empresa } from "../empresas/empresas-simpleapi.interface"

export class Boletas{
    empresa!: Empresa
    bodega!: string
    cliente!: string
    rutCliente!: string
    giro!: string
    direccion!: string
    ciudad!: string
    comuna!: string
    contacto!: string
    plazoEntrega!: number
    vendedor!: string
    estado!: string
    folio!: number
    tipoDTE: number
    tipoDocumento!: string
    fechaCreacion!: string
    valida!: string
    formaPago!: string
    moneda!: string
    observacionesPago!: string
    ordenAlfabetico!: string
    comentarios!: string
    respTotal!: number
    iva!: number
    totalGeneral!: number
    totalExento: number
    productos!: []
    directo!:string
    folioObj!: any
  }