import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'

export class IngresosInmobiliaria {
  id!: number
  propiedad!: string
  fecha!: Date
  monto!: number
  sucursal!: string
  idSucursal!: number
  idUsuario!: number
  usuario!: string
  tipoIngreso!: string
  descripcionIngreso!: string
  Servicios!: any
  //agregado para la modificacion de formulario y tabla
  nAutorizacion!: string
  cliente!: string
  tipoCliente!: string
  referenciaCliente!: string
  tipoPago!: string
  estadoPago!: string
  nDocumento!: string
  tipoDocumento!: string

  Sucursal!: Sucursal
  Usuario!: Usuario
  RespaldoIngresoInmobiliaria!: any[]
  archivos!: any
  banco!: string
  numeroCuenta!: number
}
