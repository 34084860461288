import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog'
import { environment } from '@environments/environment';
import { Transferencia, TransferenciaBodega, Stock } from '@app/_models';
import { DialogTransferenciaEditarComponent } from "../../inventario/transferencia/transferencia-bodega-list/dialog-transferencia-editar/dialog-transferencia-editar.component";
import { DialogGuiaDespachoComponent } from "../../inventario/transferencia/transferencia-bodega-list/dialog-guia-despacho/dialog-guia-despacho.component";
import { ProductosServicio } from './interfaces/productos';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TransferenciasService {
  private myMap = new Map();
  private bodega_response$ = new Subject<any>();

  constructor(private router: Router, private http: HttpClient, public dialog: MatDialog) { }

  getAllTransferencias() : Observable<TransferenciaBodega[]> {
    return this.http.get<TransferenciaBodega[]>(`${environment.apiUrl}/transferencia`);
  }

  create( transferencia: TransferenciaBodega ) {
    console.log("",transferencia)
   return this.http.post(`${environment.apiUrl}/transferencia`, transferencia)
    .pipe(
      map((res:any) =>{
        return res;
      }),
  
    );
    
  }

  createProductoBodega(BodegaProducto: Transferencia) {
    return this.http.post(`${environment.apiUrl}/transferencia/bodega/productos`, BodegaProducto);
  }

  getProductosBodegas( id : string ) : Observable<ProductosServicio[]> {
    return this.http.get<ProductosServicio[]>(`${environment.apiUrl}/bodega/${id}/productos`);
  }
  //CONSULTA LOS PRODUCTOS QUE HAY EN ESA BODEGA
  getProductoStockBodega( idStock : any ) {
    return this.http.get(`${environment.apiUrl}/transferencia/${idStock}`);
  }

  getProductoStock(idBodega : any, idProductoServicio : any, ) : Observable<Stock> {
    return this.http.get<Stock>(`${environment.apiUrl}/transferencia/${idBodega}/${idProductoServicio}`);
  }
  
  getTransferencia( id : any )  {
    console.log("entra al service", id)
    return this.http.get(`${environment.apiUrl}/transferencia/porIde/${id}`);
  }

  nose( id : any ) : Observable<TransferenciaBodega> {
    console.log("entra al service", id)
    return this.http.get<TransferenciaBodega>(`${environment.apiUrl}/transferencia/${id}`);
  }

  updateStock(stock: any) {
    return this.http.put(`${environment.apiUrl}/transferencia/stock`, stock);
  }

  delete( id: number ) {
    return this.http.delete(`${environment.apiUrl}/transferencia/${id}`);
  }

  addResultBodega( termino: any ){
    this.bodega_response$.next(termino)
  }

  getResultBodega() : Observable<any>{
    return this.bodega_response$.asObservable()
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogVerTransferencia(): void {
      const dialogRef = this.dialog.open(DialogTransferenciaEditarComponent, {})
      dialogRef.afterClosed().subscribe((res) => {})
  }

   openDialogGuia(): void {
      const dialogRef = this.dialog.open(DialogGuiaDespachoComponent, {})
      dialogRef.afterClosed().subscribe((res) => {})
  }
  // Metodo que cierra el modal (Modal)
  closeDialogModal() {
    this.dialog.closeAll()
  }

}


