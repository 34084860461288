<button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
    <mat-icon>highlight_off</mat-icon>
</button>
<form [formGroup]="form" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
    <mat-card class="shipping-card">
        <mat-card-header>
            <!-- {{ transferencias | json }} -->
        </mat-card-header>
        <mat-card-content>
            <div class="row max-modal">
                <!-- Nombre -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Bodega origen" formControlName="bodegaOrigen"/>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Fecha transferencia" formControlName="fechaTransferencia"/>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <!-- Nombre -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Bodega destino" formControlName="bodegaDestino"/>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="example-full-width">
                        <mat-label>Observación</mat-label>
                        <textarea matInput placeholder="Observación" formControlName="observacion"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <!-- <mat-divider></mat-divider> -->
            <div class="row">
                <!-- <pre>{{ dataSource | json}}</pre> -->
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <!-- Imagen -->
                    <ng-container matColumnDef="imagen">
                      <th mat-header-cell *matHeaderCellDef> Imagen </th>
                      <td mat-cell *matCellDef="let element">  </td>
                    </ng-container>
                  
                    <!-- Name Producto -->
                    <ng-container matColumnDef="producto">
                      <th mat-header-cell *matHeaderCellDef> Producto </th>
                      <td mat-cell *matCellDef="let element"> {{element.nombre.toUpperCase()}} </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="estado">
                        <th mat-header-cell *matHeaderCellDef>Estado</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.estado==1; else elseBlock" class="status-ok">Vigente</span>
                            <ng-template #elseBlock> <span class="status-no">No vigente</span></ng-template>
                        </td>
                    </ng-container>
                  
                     <!-- ubicacion -->
                     <ng-container matColumnDef="ubicacion">
                        <th mat-header-cell *matHeaderCellDef> Ubicación </th>
                        <td mat-cell *matCellDef="let element"> {{transferencias[0].bodegaDestino.toUpperCase()}} </td>
                    </ng-container>
                     <!-- Cantidad -->
                    <ng-container matColumnDef="cantidad">
                        <th mat-header-cell *matHeaderCellDef> Cantidad </th>
                        <td mat-cell *matCellDef="let element"> {{element.DetalleTransferencia.cantidad}} </td>
                    </ng-container>
                    <!-- Botones -->
                    <ng-container matColumnDef="botones">
                        <th mat-header-cell *matHeaderCellDef> Acciones </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-button-toggle-group appearance="legacy">
                                <mat-button-toggle  matTooltip="Ver tarjeta de existencia">
                                    <mat-icon>list</mat-icon>
                                </mat-button-toggle>
                                <mat-button-toggle matTooltip="Stock actual">
                                    <mat-icon> layers</mat-icon>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
            </div>
        </mat-card-content>
    </mat-card>
</form>

<!-- bodegaOrigen : [''],
BodegaDestino: [''],
fechaTransferencia : [''],
observacion : [''], -->