<mat-toolbar color="primary">
  <button
    style="margin-right: 5rem"
    mat-raised-button
    (click)="resetTable()"
    color="accent"
  >
    Actualizar Tabla
  </button>
  <span style="margin-right: 5rem">Ingresos Hostal</span>
  <span style="margin-right: 3rem"
    >Monto Registros Seleccionados: $
    {{ totalSeleccion }}
  </span>
  <button
    mat-raised-button
    color="accent"
    style="margin-left: 1rem"
    (click)="revelarTotal()"
  >
    Revelar total
  </button>
  <button
    mat-raised-button
    color="accent"
    style="margin-left: 1rem"
    (click)="exportAsXLSX()"
  >
    Exportar
  </button>
</mat-toolbar>
<div class="table-responsive">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="id"
    matSortDirection="desc"
    class="mat-elevation-z8"
  >
    <!-- check column -->

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- data columns -->

      <!-- id -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill limit-width-40">
            <input matInput placeholder="ID" formControlName="id" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <!-- fecha -->
    <ng-container matColumnDef="fecha" class="mat-column-date">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="limit-width-180"
      >
        <mat-form-field appearance="fill" class="limit-width-180">
          <mat-label>Fecha</mat-label>
          <mat-date-range-input [formGroup]="formFilter" [rangePicker]="picker">
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
            />
            <input matEndDate formControlName="end" placeholder="End date" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error
            *ngIf="formFilter.controls.start.hasError('matStartDateInvalid')"
            >Invalid start date
          </mat-error>
          <mat-error
            *ngIf="formFilter.controls.end.hasError('matEndDateInvalid')"
            >Invalid end date
          </mat-error>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.fecha | date : 'dd/MM/yyyy' }}
      </td>
    </ng-container>

    <!-- Sucursal -->
    <ng-container matColumnDef="sucursal">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <mat-select placeholder="Sucursal" formControlName="idSucursal">
              <mat-option>Todo</mat-option>
              <mat-option
                *ngFor="let sucursal of sucursales"
                [value]="sucursal.razonSocial"
              >
                {{ sucursal.razonSocial }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.sucursal }}</td>
    </ng-container>

    <!-- Documento -->
    <ng-container matColumnDef="nDocumento">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input
              matInput
              placeholder="Documento"
              formControlName="nDocumento"
            />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.nDocumento }}</td>
    </ng-container>
    <!-- Estado Pago -->
    <ng-container matColumnDef="estadoPago">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <mat-select placeholder="Estado Pago" formControlName="estadoPago">
              <mat-option *ngFor="let estado of estadosPagos" [value]="estado">
                {{ estado }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.estadoPago }}</td>
    </ng-container>

    <!--Cliente-->
    <ng-container matColumnDef="cliente">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Cliente" formControlName="cliente" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.cliente }}</td>
    </ng-container>
    <!--Tipo Ingreso-->
    <ng-container matColumnDef="tipoIngreso">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <mat-select
              placeholder="Tipo Ingreso"
              formControlName="tipoIngreso"
            >
            <mat-option>Todo</mat-option>
              <mat-option *ngFor="let tipo of tiposIngresos" [value]="tipo">
                {{ tipo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.tipoIngreso }}</td>
    </ng-container>


    <!--Monto-->
    <ng-container matColumnDef="monto">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill limit-width-60">
            <input matInput placeholder="Monto" formControlName="monto" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <span matPrefix>$&nbsp;</span> {{ element.monto.toLocaleString('es-CL') }}
      </td>
    </ng-container>

    <!--Usuario-->
    <ng-container matColumnDef="usuario">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Usuario" formControlName="usuario" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.usuario }}</td>
    </ng-container>

    <!-- Autorizacion -->
    <ng-container matColumnDef="nAutorizacion">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input
              matInput
              placeholder="N° Autorizacion"
              formControlName="nAutorizacion"
            />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.nAutorizacion }}</td>
    </ng-container>
    <!-- Tipo Pago -->
    <ng-container matColumnDef="tipoPago">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <mat-select placeholder="Tipo Pago" formControlName="tipoPago">
              <mat-option>Todo</mat-option>
              <mat-option *ngFor="let tipoPago of tipoPago" [value]="tipoPago">
                {{ tipoPago }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.tipoPago }}</td>
    </ng-container>
    <!-- Descripcion -->
    <ng-container matColumnDef="descripcionIngreso">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input
              matInput
              placeholder="Descripcion"
              formControlName="descripcionIngreso"
            />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.descripcionIngreso }}
      </td>
    </ng-container>
    <!-- referencia del cliente -->
    <ng-container matColumnDef="referenciaCliente">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <mat-select
              placeholder="Referencia"
              formControlName="referenciaCliente"
            >
            <mat-option>Todos</mat-option>
              <mat-option
                *ngFor="let referencias of referenciaCliente"
                [value]="referencias"
              >
                {{ referencias }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.referenciaCliente }}
      </td>
    </ng-container>

    <!-- Botones de acciones -->
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef>Acciones</th>
      <td mat-cell *matCellDef="let element">
        {{ element.acciones }}
        <button
          mat-mini-fab
          color="primary"
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="accionIngresoInmobiliaria(element.id)"
        >
          <mat-icon>save_as_white_18dp</mat-icon>
        </button>
        <!-- Dowload -->
        <button
          mat-mini-fab
          color="accent"
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="downloadImage(element.id)"
        >
          <mat-icon>file_download</mat-icon>
        </button>
        <button
          mat-mini-fab
          color=""
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="deleteEgreso(element.id)"
          [disabled]="rolUsuario !== 'Administrador'"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No existen coincidencias</td>
    </tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>
