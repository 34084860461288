<mat-dialog-content>
    <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
        <mat-icon>highlight_off</mat-icon>
    </button>
    <h1>Conteiner: {{ idConteiner }}</h1>
    <mat-toolbar color="primary">
        <span> Registro Neumáticos</span>
        <button mat-raised-button color="accent" style="margin-left: 1rem" (click)="exportAsXLSX()">
            Exportar
        </button>            
    </mat-toolbar>

    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">
        
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
                <td mat-cell *matFooterCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <!-- data columns -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID </th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>                
                <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>

            <ng-container matColumnDef="unitarioChino">
                <th mat-header-cell *matHeaderCellDef>Unitario Chino </th>
                <td mat-cell *matCellDef="let element">{{ element.unitarioChino | number }}</td> 
                <td mat-footer-cell *matFooterCellDef> {{sumaUnitarioC | number}} </td>               
            </ng-container>

            <ng-container matColumnDef="totalTipoNeumatico">
                <th mat-header-cell *matHeaderCellDef>Total del tipo de neumatico </th>
                <td mat-cell *matCellDef="let element">{{ element.totalTipoNeumatico | number }}</td>  
                <td mat-footer-cell *matFooterCellDef> {{sumaUnitarioTC | number}} </td>              
            </ng-container>
                        
            <ng-container matColumnDef="neumatico">
                <th mat-header-cell *matHeaderCellDef> Tipo Neumático </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.tipoNeumatico }}
                </td>
                <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef> Cantidad </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.cantidad }}
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaCantidad | number}} </td>
            </ng-container>

            <ng-container matColumnDef="ganancia">
                <th mat-header-cell *matHeaderCellDef> Ganancia </th>
                <td mat-cell *matCellDef="let element ">
                    {{ element.pGanancia + '%'  }}                    
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaGanancia + '%'}} </td>
            </ng-container>

            <ng-container matColumnDef="conteiner">
                <th mat-header-cell *matHeaderCellDef> % Container </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.pContainer + '%'}}
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaConteiner + '%'}} </td>
            </ng-container>

            <ng-container matColumnDef="comision">
                <th mat-header-cell *matHeaderCellDef> Costo Comision </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.costoComision | number }}
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaComision | number}} </td>
            </ng-container>

            <ng-container matColumnDef="interior">
                <th mat-header-cell *matHeaderCellDef> Costo interior </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.costoInterior | number}}
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaInterior | number}} </td>
            </ng-container>

            <ng-container matColumnDef="maritimo">
                <th mat-header-cell *matHeaderCellDef> Costo Maritimo </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.costoMaritimo | number }}
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaMaritimo | number}} </td>
            </ng-container>

            <ng-container matColumnDef="portuario">
                <th mat-header-cell *matHeaderCellDef> Impuesto Portuario </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.impuestoProntuario | number }}
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaPortuario | number}} </td>
            </ng-container>

            <ng-container matColumnDef="seguros">
                <th mat-header-cell *matHeaderCellDef> Seguros </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.seguros | number}}
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaSeguros | number}} </td>
            </ng-container>

            <ng-container matColumnDef="unitario">
                <th mat-header-cell *matHeaderCellDef> Valor Unitario </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.valorUnitario | number }}
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaCostoU | number}} </td>
            </ng-container>

            <ng-container matColumnDef="costoNeumatico">
                <th mat-header-cell *matHeaderCellDef> Valor Venta </th>
                <td mat-cell *matCellDef="let element">                    
                    {{ element.costoNeumatico | number}}
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaUnitarioV | number}} </td>
            </ng-container>

            <ng-container matColumnDef="totalVenta">
                <th mat-header-cell *matHeaderCellDef> Total Venta </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.totalVenta | number }}
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaUnitarioTV | number}} </td>
            </ng-container>

            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef> Total Unitario </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.montoTotal | number}}                    
                </td>
                <td mat-footer-cell *matFooterCellDef> {{sumaCostoTU | number}} </td>
            </ng-container>

            <ng-container matColumnDef="utilidad">
                <th mat-header-cell *matHeaderCellDef> Utilidad por lote </th>
                <td mat-cell *matCellDef="let element">                
                    {{ element.utilidad | number}}                                        
                </td>                
                <td mat-footer-cell *matFooterCellDef> {{'-' + sumaUtilidad | number}} </td>                
            </ng-container>

                <!-- Botones -->
        <ng-container matColumnDef="botones">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let element">                                     
                <button mat-raised-button color="accent" style="margin-left: 1rem" 
                (click)="openDialogEdit2(
                    element.id, 
                    element.unitarioChino,
                    element.totalTipoNeumatico,
                    element.tipoNeumatico, 
                    element.cantidad,
                    element.pContainer,                    
                    element.costoComision,
                    element.costoInterior,
                    element.costoMaritimo,
                    element.impuestoProntuario,
                    element.seguros,
                    element.valorUnitario,
                    element.montoTotal,                    
                    element.pGanancia,
                    element.totalVenta,
                    element.costoNeumatico,
                    element.utilidad)">          
                        <mat-icon>mode_edit</mat-icon> Editar
                </button>
                <br>                
            </td>
            <td mat-footer-cell *matFooterCellDef> Totales </td>
        </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            
            
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="14">No existen coincidencias</td>
            </tr>
        </table>  
                
          
    </div>        
    <mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>
</mat-dialog-content>