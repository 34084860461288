import { Component, OnInit } from '@angular/core';
import { ConfiguracionCesionariosFormComponent } from "../configuracion-cesionarios-form/configuracion-cesionarios-form.component";

@Component({
  selector: 'app-configuracion-home',
  templateUrl: './configuracion-home.component.html',
  styleUrls: ['./configuracion-home.component.scss']
})
export class ConfiguracionHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
