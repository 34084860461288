<mat-dialog-content>
<mat-toolbar color="primary">
    <button style="margin-right: 9rem" mat-raised-button color="accent">
        Limpiar filtros
      </button>

    <span style="margin-right: 9rem; margin-left: 9rem"> Listado Variantes </span>
    <!-- ***************************** cambiar metodo botón ********************************** -->
</mat-toolbar>

<div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">

        <!-- Nombres -->
        <ng-container matColumnDef="nombreVariante">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input  matInput placeholder="Nombre" formControlName="nombreVariante" />
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.nombreVariante }} <br> <div style="color: #707070" class="bottom">{{element.codigoSKU}}</div></td>

        </ng-container>

        <!-- Codigo SKU -->
        <ng-container matColumnDef="codigoSKUVariante">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input matInput placeholder="CodigoSKU" formControlName="codigoSKUVariante" />
                    </mat-form-field>
                </div>
                </th>
            <td mat-cell *matCellDef="let element">{{ element.codigoSKUVariante }}</td>
        </ng-container>

        <!-- Estado -->
        <ng-container matColumnDef="estadoVariante">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input matInput placeholder="Estado" formControlName="estadoVariante" />
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element"> 
                 <div class="col" *ngIf="element.estadoVariante == 'Vigente'">
                     <button mat-raised-button style="background-color: #087f23; color: white;">
                          {{element.estadoVariante}}
                    </button>
                </div>
                <div class="col" *ngIf="element.estadoVariante == 'No Vigente'">
                    <button mat-raised-button style="background-color: #ac0800; color: white;">
                        {{element.estadoVariante}}
                    </button>
                </div>
            </td>
        </ng-container>


        <!-- Precio Neto -->
        <ng-container matColumnDef="unitarioVariante">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input matInput placeholder="Unitario" formControlName="unitarioVariante" />
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.unitarioVariante }}</td>
        </ng-container>

        <!-- Precio Venta -->
        <ng-container matColumnDef="netoVariante">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input matInput placeholder="Neto" formControlName="netoVariante" />
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.netoVariante }}</td>
        </ng-container>

        <!-- Botones -->
        <ng-container matColumnDef="botones">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let element">
              <div>
              
                <a (click)="borrarVariante(element.id, element.nombre)" class="btn btn-danger"><mat-icon>delete_black_24dp</mat-icon></a>
            </div>
            </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No existen coincidencias</td>
        </tr>
    </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>
</mat-dialog-content>