import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EgresoHostalCuota } from '@app/_models/hostal/egresoHostalCuota';
import { webPay } from '@app/_models/webpay/webpay';
import { environment } from '@environments/environment'

@Injectable({
  providedIn: 'root'
})
export class WebpayService {

  constructor(
    private http: HttpClient
  ) { 

  }


  pagar(webpay: webPay){
    return this.http.post(
      `${environment.apiUrl}/webpay/pagar`,
      webpay
    )
  }
}
