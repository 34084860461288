<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Actualizar IMG 📃 ">
    <form [formGroup]="addressForm" novalidate (ngSubmit)="AgregarIMG()">
      <mat-card class="shipping-card">
        <!-- Informacion IMG -->
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>Informacion IMG </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <!-- Capturar img -->
              <div>
                <input mat-stroked-button (change)="capturarFile($event)" type="file" />
              </div>
              <br />
              <div>
                <button mat-stroked-button color="primary" [disabled]="loading" (click)="subirIMG()"
                  (click)="clickButton = false">
                  {{ loading ? 'Cargando....' : 'Subir IMG' }}
                </button>
              </div>
            </div>
          </div>
          <br />
          <h3>Imagen Previsualizacion</h3>
          <hr />
          <div class="row">
            <!-- Preview img -->
            <div class="col" *ngIf="previsualizacion">
              <img width="400px" [src]="previsualizacion" alt="img" />
            </div>
          </div>
          <hr />
        </mat-expansion-panel>

        <mat-card-actions>
          <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="Comprobante 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()">
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>


  <mat-tab label="Editar 📝">
    <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form [formGroup]="addressForm" novalidate class="mat-elevation-z10" (ngSubmit)="onSubmit()">
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Editar Egreso</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Informacion Basica -->
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Basica </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- informacion fila uno -->
            <div class="row">
              <!-- fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Fecha" formControlName="fecha" required />
                  <mat-icon matSuffix>event_18dp</mat-icon>
                  <mat-error *ngIf="addressForm.controls['fecha'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
            <div class="row">
              <!-- nContainer -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="n Container" formControlName="nContainer" />
                  <mat-icon matSuffix>plus_one_18dp</mat-icon>
                  <mat-error *ngIf="addressForm.controls['nContainer'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- montoTotal -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Monto Total" formControlName="montoTotal" required />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['montoTotal'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- impuestoProntuario -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Impuesto Prontuario" formControlName="impuestoProntuario" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['impuestoProntuario'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- seguros -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Seguros" formControlName="seguros" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['seguros'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
            <div class="row">
              <!-- costoMaritimo -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Costo Maritimo" formControlName="costoMaritimo" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['costoMaritimo'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- costoNeumatico -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Costo Neumatico" formControlName="costoNeumatico" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['costoNeumatico'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- costoComision -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Costo Comision" formControlName="costoComision" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['costoComision'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- costoInterior -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Costo Interior" formControlName="costoInterior" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['costoInterior'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>

          <!-- Informacion IMG -->
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion IMG </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- Capturar img -->
                <div>
                  <input mat-stroked-button (change)="capturarFile($event)" type="file" />
                </div>
                <br />
                <div>
                  <button mat-stroked-button color="primary" [disabled]="loading" (click)="subirIMG()"
                    (click)="clickButton = false">
                    {{ loading ? 'Cargando....' : 'Subir IMG' }}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" type="submit"><mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>

</mat-tab-group>
