<mat-card>
  <mat-toolbar color="primary">
    <span>Clientes</span>
    <button
      style="margin-left: 1rem; margin-bottom: 3px"
      mat-raised-button
      color="accent"
      (click)="actualizar()"
    >
      Actualizar tabla <mat-icon>autorenew_white_18dp</mat-icon>
    </button>
    <br />

    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="exporter.exportTable('csv')"
    >
      Exportar a Excel <mat-icon>description_white_18dp</mat-icon>
    </button>
  </mat-toolbar>
</mat-card>

<div class="mat-elevation-z8">
  <br />
  <div class="button-container">
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="vigente()"
      type="reset"
      class="vigente-button"
    >
      Vigente
      <mat-icon>check_box_white_18dp</mat-icon>
    </button>
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="noVigente()"
      type="reset"
      class="Novigente-button"
    >
      No Vigente
      <mat-icon>cancel_white_18dp</mat-icon>
    </button>
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="todos()"
      class="todos-button"
    >
      Todos
      <mat-icon>list_white_18dp</mat-icon>
    </button>
  </div>
  <mat-form-field appearance="standard">
    <mat-label>Buscar</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ingresa Nombre"
      #input
    />
  </mat-form-field>
  <table
    mat-table
    matTableExporter
    [dataSource]="dataSource"
    #exporter="matTableExporter"
  >
    <!-- id Columna -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Nombre del cliente Columna -->
    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef>Cliente</th>
      <td mat-cell *matCellDef="let element">
        {{ element.nombre | titlecase }}
      </td>
    </ng-container>

    <!-- descuento Columna -->
    <ng-container matColumnDef="descuento">
      <th mat-header-cell *matHeaderCellDef>Descuento</th>
      <td mat-cell *matCellDef="let element">
        {{ element.descuento | percent }}
      </td>
    </ng-container>

    <!-- credito Columna -->
    <ng-container matColumnDef="credito">
      <th mat-header-cell *matHeaderCellDef>Credito</th>
      <td mat-cell *matCellDef="let element">{{ element.credito }}</td>
    </ng-container>

    <!-- contactoComercial Columna -->
    <ng-container matColumnDef="contactoComercial">
      <th mat-header-cell *matHeaderCellDef>Contacto</th>
      <td mat-cell *matCellDef="let element">
        {{ element.contactoComercial | titlecase }}
      </td>
    </ng-container>

    <!-- emailComercial Columna -->
    <ng-container matColumnDef="emailComercial">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">
        {{ element.emailComercial }}
      </td>
    </ng-container>

    <!-- estado Columna -->
    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef>Estado</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.estado == true; else elseTemplate">
          Vigente
          <mat-icon style="-webkit-text-fill-color: green"
            >check_box_white_18dp</mat-icon
          >
        </ng-container>
        <ng-template #elseTemplate>
          No vigente
          <mat-icon style="-webkit-text-fill-color: red"
            >cancel_white_18dp</mat-icon
          >
        </ng-template>
      </td>
    </ng-container>

    <!-- Acciones Columna -->
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef style="text-align: center">
        Acciones
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-mini-fab
          color="accent"
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="updateCliente(element.id)"
        >
          <mat-icon>save_as_white_18dp</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="deleteClientes(element.id)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 20, 30]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
