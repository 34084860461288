

<div class="mat-elevation-z8">
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> Compras Mes y Años </mat-panel-title>
      <mat-panel-description>Para Enviar y Actualizar Presiona Aqui
        <mat-icon>touch_app_white_18dp</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-card-actions>
      <form [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label>Ingrese Fecha</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="fechaFiltro"
          />
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="onSubmit()">
          <mat-icon>send</mat-icon> Enviar
        </button>
        <button mat-raised-button color="primary" (click)="Actualizar()">
          <mat-icon>update_white_18dp</mat-icon> Actualizar
        </button>
      </form>
    </mat-card-actions>
  </mat-expansion-panel>
  <br>
  <mat-card>
    <mat-toolbar color="primary">
      <span>Compras</span>
      <button
        style="margin-left: 1rem; margin-bottom: 3px"
        mat-raised-button
        color="accent"
        (click)="actualizar()"
      >
        Actualizar tabla <mat-icon>autorenew_white_18dp</mat-icon>
      </button>
      <br />

      <button
        mat-raised-button
        color="accent"
        style="margin-left: 1rem; margin-bottom: 3px"
        (click)="exporter.exportTable('csv')"
      >
        Exportar a Excel <mat-icon>description_white_18dp</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card>
  <br />
  <div class="button-container">
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="confirmado()"
      type="reset"
    >
      Confirmado
      <mat-icon>check_box_white_18dp</mat-icon>
    </button>
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="pendiente()"
      type="reset"
    >
      Pendiente
      <mat-icon>cancel_white_18dp</mat-icon>
    </button>
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="todos()"
    >
      Todos
      <mat-icon>list_white_18dp</mat-icon>
    </button>
  </div>
  <mat-form-field appearance="standard">
    <mat-label>Buscar</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ingresa Nombre"
      #input
    />
  </mat-form-field>
  <table
    mat-table
    matTableExporter
    [dataSource]="dataSource"
    #exporter="matTableExporter"
    matSort
  >
    <!-- id Columna -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Rut proveedor Columna -->
    <ng-container matColumnDef="rutProveedor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut</th>
      <td mat-cell *matCellDef="let element">
        {{ element.rutProveedor }}
      </td>
    </ng-container>

    <!-- proveedor Columna -->
    <ng-container matColumnDef="proveedor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Proveedor</th>
      <td mat-cell *matCellDef="let element">
        {{ element.razonSocial }}
      </td>
    </ng-container>

    <!-- tipoDocumento Columna -->
    <ng-container matColumnDef="tipoDocumento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Documento</th>
      <td mat-cell *matCellDef="let element">
        {{ element.tipoDTEString }}
      </td>
    </ng-container>

    <!-- folio Columna -->
    <ng-container matColumnDef="folio">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Folio</th>
      <td mat-cell *matCellDef="let element">{{ element.folio }}</td>
    </ng-container>

    <!-- fechaEmision Columna -->
    <ng-container matColumnDef="fechaEmision">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Emision</th>
      <td mat-cell *matCellDef="let element">
        {{ element.fechaEmision }}
      </td>
    </ng-container>

    <!-- montoTotal Columna -->
    <ng-container matColumnDef="montoTotal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
      <td mat-cell *matCellDef="let element"><span matPrefix>$&nbsp;</span>
        {{ element.montoTotal }}
      </td>
    </ng-container>

    <!-- estado Columna -->
    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
      <td mat-cell *matCellDef="let element">
        {{ element.estado }}
      </td>
    </ng-container>

    <!-- Acciones Columna -->
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef style="text-align: center">
        Acciones
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-mini-fab
          color="primary"
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="accionesComprasSII(element.id)"
        >
          <mat-icon>save_as_white_18dp</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 20, 30]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
