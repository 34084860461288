<mat-dialog-content>
    <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
      <mat-icon>highlight_off</mat-icon>
    </button>

<h3>Subir Foto</h3>
<div
  ng2FileDrop
  [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
  (fileOver)="fileOverBase($event)"
  [uploader]="uploader"
  class="well my-drop-zone"
>
  <mat-icon>add</mat-icon> arrastrar arquivos aqui
  <tr *ngFor="let item of uploader.queue">
    <td>
      <strong>{{ item?.file?.name }}</strong>
    </td>
    <td *ngIf="uploader.options.isHTML5" nowrap>
      {{ item?.file?.size / 1024 / 1024 | number: ".2" }} MB
    </td>    
  </tr>
</div>
<input mat-input type="file" ng2FileSelect [uploader]="uploader" multiple />

<button
  mat-fab
  color="accent"
  (click)="uploader.uploadAll()"
  [disabled]="!uploader.getNotUploadedItems().length"
>
  <mat-icon>add</mat-icon>
</button>
{{ responses }}

</mat-dialog-content>