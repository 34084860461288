import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ConsolidadoBancarioService } from '../../consolidado-bancario.service'
import { first, map } from 'rxjs/operators';
import { Sucursal } from '@app/_models/shared/sucursal'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { validateRUT, clearRUT } from 'validar-rut';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table'
import { FormControl, FormGroup } from '@angular/forms'
import { SelectionModel } from '@angular/cdk/collections'
import { BehaviorSubject } from 'rxjs';
import { formatDate } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { EgresosRentacar } from '@app/_models/rentacar/egresoRentacar'
import { EgresoHostal } from '@app/_models/hostal/egresoHostal'
import { RegistroEgresoFirma } from '@app/_models/abogados/egresosFirma';
import { EgresosInmobiliaria } from '@app/_models/inmobiliaria/egresoInmobiliaria'
import { EgresosFijoImportadora } from '@app/_models/importadora/egresoFijoImportadora';
import { EgresoLubricentro } from '@app/_models/lubricentro/egresoLubricentro';
import { RentacarService } from '@app/_pages/rentacar/rentacar.service';
import { InmobiliariaService } from '@app/_pages/inmobiliaria/inmobiliaria.service'
import { LubricentroService } from '@app/_pages/lubricentro/lubricentro.service';
import { HostalService } from '@app/_pages/hostal/hostal.service';
import { ImportadoraService } from '@app/_pages/importadora/importadora.service';
import { AbogadosService } from '@app/_pages/abogados/abogados.service';
import { Empresa } from '@app/_models/shared/empresa';
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service';
import { AuthSharedService } from '../../../shared/shared-services/auth-shared.service';

@Component({
  selector: 'app-consolidado-total',
  templateUrl: './consolidado-total.component.html',
  styleUrls: ['./consolidado-total.component.scss']
})
export class ConsolidadoTotalComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild(MatSort) sort = null;



  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>() 
  dataEgresosRentacar: EgresosRentacar[] = [];
  dataEgresosHostal: EgresoHostal[] = [];
  dataEgresosLubricentro: EgresoLubricentro[] = [];
  dataEgresosInmobiliaria: EgresosInmobiliaria[] = [];
  dataEgresosAbogados: RegistroEgresoFirma[] = [];
  dataEgresosInportadora: EgresosFijoImportadora[] = [];
  combinedData: any[] = [];


  totalEgresosRentacar: number = 0;
  totalEgresosHostal: number = 0;
  totalEgresosLubricentro: number = 0;
  totalEgresosInmobiliaria: number = 0;
  totalEgresosAbogados: number = 0;
  totalEgresosInportadora: number = 0;
  

  constructor(
    private router: Router,
    private rentacarService: RentacarService,
    private hostalService: HostalService,
    private importadoraService: ImportadoraService,
    private inmobiliariaService: InmobiliariaService,
    private lubricentroService: LubricentroService,
    private abogadosService: AbogadosService,
    private empresaService: EmpresaSharedService,
    private sucursalService: SucursalSharedService,
  ) { }

  ngOnInit(): void {
    this.getAllEgresoRentacar();
    this.getAllEgresoHostal();
    this.getAllEgresoLubricentro();
    this.getAllEgresoInmobiliaria();
    this.getAllEgresoAbogados();
    this.getAllEgresoInportadora();
  }

  getAllEgresoRentacar() {
    this.rentacarService.getAllEgresos()
    .subscribe((egresos: EgresosRentacar[]) => {
      this.dataEgresosRentacar = egresos
      this.totalEgresosRentacar = 0;
      for (const egreso of this.dataEgresosRentacar) {
        this.totalEgresosRentacar += egreso.monto;
      }
    });
  }

  getAllEgresoHostal() {
    this.hostalService.egresoGetAll()
      .subscribe((egresos: EgresoHostal[]) => {
        this.dataEgresosHostal = egresos;
        this.totalEgresosHostal = 0;
        for (const egreso of this.dataEgresosHostal) {
          this.totalEgresosHostal += egreso.monto;
        }
      });
  }

  getAllEgresoLubricentro(){
    this.lubricentroService.egresoGetAll()
    .subscribe((egresos: EgresoLubricentro[]) => {
      this.dataEgresosLubricentro = egresos;
      this.totalEgresosLubricentro = 0;
      for (const egreso of this.dataEgresosLubricentro) {
        this.totalEgresosLubricentro += egreso.monto;
      }
    });
  }

  getAllEgresoInmobiliaria(){
    this.inmobiliariaService.getAllEgresos()
    .subscribe((egresos: EgresosInmobiliaria[]) => {
      this.dataEgresosInmobiliaria = egresos;
      this.totalEgresosInmobiliaria = 0;
      for (const egreso of this.dataEgresosInmobiliaria) {
        this.totalEgresosInmobiliaria += egreso.monto;
      }
    });
  }

  getAllEgresoAbogados(){
    this.abogadosService.egresoGetAll()
    .subscribe((egresos: RegistroEgresoFirma[]) => {
      this.dataEgresosAbogados = egresos;
      this.totalEgresosAbogados = 0;
      for (const egreso of this.dataEgresosAbogados) {
        this.totalEgresosAbogados += egreso.monto;
      }
    });
  }

  getAllEgresoInportadora(){
    this.importadoraService.getAllEgresosFijo()
    .subscribe((egresos: EgresosFijoImportadora[]) => {
      this.dataEgresosInportadora = egresos;
      this.totalEgresosInportadora = 0;
      for (const egreso of this.dataEgresosInportadora) {
        this.totalEgresosInportadora += egreso.monto;
      }
    });
  }

  calcularTotalGeneral(): number {
    return (
      this.totalEgresosRentacar +
      this.totalEgresosHostal +
      this.totalEgresosLubricentro +
      this.totalEgresosInmobiliaria +
      this.totalEgresosAbogados +
      this.totalEgresosInportadora
    );
  }
}

