<mat-dialog-content>
<button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
    <mat-icon>highlight_off</mat-icon>
</button>
<form [formGroup]="addressForm" novalidate class="mat-elevation-z10" (ngSubmit)="onSubmit()">
    <mat-card class="shipping-card">
        <mat-card-header>
            <h1>Ajuste de inventario </h1>
        </mat-card-header>
        <br>
        <mat-card-content>
            <div class="row">
                <!-- Fecha Emision -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Fecha" formControlName="fecha"  [readonly]="true"/>
                            {{this.fecha}}
                    </mat-form-field>
                </div>

                <!-- N° Documento -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="N° Documento" formControlName="documento" readonly/>
                        {{this.numeroDocumento}}
                    </mat-form-field>
                </div>
                
            </div>

            <div class="row">

                <!-- Bodega -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Bodega" formControlName="bodega" readonly/>
                            {{this.nombreBodega}}
                    </mat-form-field>
                </div>

                <!-- Observacion -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Observacion" formControlName="observacion" readonly/>
                        {{this.observacion}}
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
            
                </div>
            
            </div>

        </mat-card-content>

        </mat-card>
    </form>
</mat-dialog-content>
<br>

<mat-dialog-content>
    
    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">
    
            <!-- Nombres -->
            <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef>
                    <div [formGroup]="formFilter">
                        <mat-form-field class="fill">
                            <input  matInput placeholder="Nombre" formControlName="nombre" />
                        </mat-form-field>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.nombre }} <br></td>
    
            </ng-container>
    
            <!-- Ubicacion -->
            <ng-container matColumnDef="ubicacion">
                <th mat-header-cell *matHeaderCellDef>
                    <div [formGroup]="formFilter">
                        <mat-form-field class="fill">
                            <input matInput placeholder="Ubicacion" formControlName="ubicacion" />
                        </mat-form-field>
                    </div>
                    </th>
                <td mat-cell *matCellDef="let element">{{ element.ubicacion }}</td>
            </ng-container>
    
            <!-- Cantidad -->
            <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef>
                    <div [formGroup]="formFilter">
                        <mat-form-field class="fill">
                            <input matInput placeholder="Cantidad" formControlName="cantidad" />
                        </mat-form-field>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.cantidad }}</td>
            </ng-container>
    
    
            <!-- Costo Unidad -->
            <ng-container matColumnDef="costo">
                <th mat-header-cell *matHeaderCellDef>
                    <div [formGroup]="formFilter">
                        <mat-form-field class="fill">
                            <input matInput placeholder="Unitario" formControlName="costo" />
                        </mat-form-field>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.unitario }}</td>
            </ng-container>
    
            <!-- Precio Subtotal -->
            <ng-container matColumnDef="subtotal">
                <th mat-header-cell *matHeaderCellDef>
                    <div [formGroup]="formFilter">
                        <mat-form-field class="fill">
                            <input matInput placeholder="Subtotal" formControlName="subtotal" />
                        </mat-form-field>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.subtotal }}</td>
            </ng-container>

    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No existen coincidencias</td>
            </tr>
        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>
    </mat-dialog-content>