import { EgresosFijoImportadora } from '@app/_models/importadora/egresoFijoImportadora';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { AdministracionService } from '@app/_pages/administracion/administracion.service';
import { ImportadoraService } from '@app/_pages/importadora/importadora.service';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-acciones-egresos-fijos-importadora',
  templateUrl: './acciones-egresos-fijos-importadora.component.html',
  styleUrls: ['./acciones-egresos-fijos-importadora.component.scss']
})
export class AccionesEgresosFijosImportadoraComponent implements OnInit {


  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any
  addt: Boolean= true
  addBanc : Boolean= true
  idEgresoFijoImportadora: any
  idSucursal: any
  tipoEgreso: any
  descripcion: any
  fecha: any
  tipoPago: any
  grupoGastos: any
  monto: any
  montoCuota: any
  numeroCuota: any
  responsable: any
  gastosFijo: string[];
  tiposEgresos: string[]
  tipoEgresoValue :string
  tipoPagoValue: string
  selectedTipoPago: string;
  selectedTipoegreso: string;
  tiposPagos: string[]


  dataArrayImg = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  //Modelo de egreso Fijo Importadora
  modelo: EgresosFijoImportadora = new EgresosFijoImportadora()

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private importadoraService: ImportadoraService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) { 
    this.tiposPagos = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Cheque',
      'Billetera Copec',
      'Caja Chica',
      'Pac'
    ]
    this.tiposEgresos = [
      'Gastos',
      'Costos',
      'Remuneraciones',
      'Bancarios',
      'Impuestos',
      'Gastos Fijos',
      'Inversiones'
    ]
    this.gastosFijo =['Luz','Agua','Gas','Internet','Otro']




  }

  ngOnInit(): void {
    this.idEgresoFijoImportadora = localStorage.getItem('idEgresoFijoImportadora')
    this.getEgresoFijoImportadora()
    this.addBanc = false;
    this.addt = false;
    
    this.addressForm.get('tipoPago').valueChanges.subscribe((tipoPagoValue) => {
      this.selectedTipoPago = tipoPagoValue;
      
      this.addBancNumber(tipoPagoValue);
      if (tipoPagoValue === "Efectivo") {
        this.addressForm.patchValue({
          // banco: null,
          // numeroCuenta: null
        });
      }
      

    });
    this.addressForm.get('tipoEgreso').valueChanges.subscribe((tipoEgresoValue) => {
      
      this.selectedTipoegreso =tipoEgresoValue,
      this.addtipo(tipoEgresoValue);
      if (tipoEgresoValue === "Gastos Fijos") {
        this.addressForm.patchValue({
          
          gastosFijo: null
        });
      }
      
    

    });








  }
  //Configuracion de formulario
  addressForm = this.fb.group({
    idSucursal: [null, Validators.required],
    tipoEgreso: [null, Validators.required],
    descripcion: [null, Validators.required],
    fecha: [null, Validators.required],
    tipoPago: [null, Validators.required],
    grupoGastos: [null, Validators.required],
    monto: [null],
    montoCuota: [null],
    numeroCuota: [null],
    responsable: [null, Validators.required],
    numeroCuenta: [null],
    gastosFijos: [null],
    archivos: null,
  })


  get f() {
    return this.addressForm.controls
  }
  addBancNumber(tipoPagoValue: string): void {

    if (tipoPagoValue !== "Efectivo") {
      this.addBanc = true;
    } else {
      this.addBanc = false;
    }

  }
  addtipo(tipoEgresoValue: string): void {

    if (tipoEgresoValue === "Gastos Fijos") {
      this.addt = true;
    } else {
      this.addt = false;
    }

  }
  getEgresoFijoImportadora() {
    this.importadoraService
      .getByIdEgresoFijosImportadora(this.idEgresoFijoImportadora)
      .pipe(first())
      .subscribe((x: any) => {
       // console.log(x[0]);
        if (x[0].id == this.idEgresoFijoImportadora) {
          this.f.fecha.setValue(x[0].fecha)
          this.f.tipoEgreso.setValue(x[0].tipoEgreso)
          this.f.descripcion.setValue(x[0].descripcion)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.grupoGastos.setValue(x[0].grupoGastos)
          this.f.montoCuota.setValue(x[0].montoCuota)
          this.f.numeroCuota.setValue(x[0].numeroCuota)
          this.f.responsable.setValue(x[0].responsable)
          this.f.monto.setValue(x[0].monto)
          this.f.archivos.setValue(x[0].archivos)
          this.f.numeroCuenta.setValue(x[0].numeroCuenta)
        }
        this.previsualizacion = x[0].archivos
      })
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.importadoraService.closeDialogModal()
    window.location.reload()
  }
  //Capturar imagen
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop() // Obtener la extensión del archivo original
    const nuevoNombre = "EgresosFijosImportadora" + Date.now() + "." + extension // Crear un nombre único con el identificador generado dinámicamente
    this.dataArrayImg.push(nuevoNombre) // Agregar el nuevo nombre al array de nombres de archivo
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, { type: archivoCapturado.type }) // Crear un nuevo objeto de tipo File con el nuevo nombre
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton= false
  }
  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    });

  //Subir IMG
  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/egresoFijoImportadora/Upload/ImportadoraEgresoFijo`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }
  //Actualizar formulario
  onSubmit() {
    if (confirm('Esta seguro que desea actualizar el Egreso Fijo Importadora: ')) {
      this.importadoraService.getByIdEgresoSinBase64(this.idEgresoFijoImportadora)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idEgresoFijoImportadora) {
            const data = x[0]
            const nombreArchivo = x[0].archivos
            const JsonNombreArchivo = JSON.parse(nombreArchivo);
            if (this.dataArrayImg[0] === undefined) {
              this.addressForm.value.archivos = JsonNombreArchivo;
            } else {
              this.addressForm.value.archivos = this.dataArrayImg
            }
            this.addressForm.value.idSucursal = data.idSucursal
            this.importadoraService
              .updateEgresoFijoImportadora(this.idEgresoFijoImportadora, this.addressForm.value)
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(' Egreso Fijo Importadora editado con éxito.', 'Cerrar', {
                    duration: 2000,
                    verticalPosition: 'top'
                  })

                  this.addressForm.reset()
                  this.importadoraService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el  Egreso Fijo Importadora:, favor contactar a informática.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )

          }
        })
    }
  }

  //Actualizar formulario
  AgregarIMG() {
    if (confirm('Esta seguro que desea agregar IMG  en Egreso Fijo Importadora: ')) {
      this.importadoraService.getByIdEgresoSinBase64(this.idEgresoFijoImportadora)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idEgresoFijoImportadora) {
            const data = x[0]

            this.addressForm.value.monto = data.monto
            this.addressForm.value.idSucursal = data.idSucursal;
            this.addressForm.value.tipoEgreso = data.tipoEgreso;
            this.addressForm.value.descripcion = data.descripcion
            this.addressForm.value.responsable = data.responsable
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.archivos = data.archivos
            this.addressForm.value.tipoPago = data.tipoPago
            this.addressForm.value.montoCuota = data.montoCuota
            this.addressForm.value.numeroCuota = data.numeroCuota
            this.addressForm.value.grupoGastos = data.grupoGastos
            this.addressForm.value.archivos = this.dataArrayImg

            this.importadoraService
              .updateEgresoFijoImportadora(this.idEgresoFijoImportadora, this.addressForm.value)
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(' Egreso Fijo Importadora editado con éxito.', 'Cerrar', {
                    duration: 2000,
                    verticalPosition: 'top'
                  })

                  this.addressForm.reset()
                  this.importadoraService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el  Egreso Fijo Importadora, favor contactar a informática.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )

          }
        })
    }
  }

}
