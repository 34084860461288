/*
######## Desarrollo ######
*/
/*  export const environment = {
  production: false,
  apiUrl: 'http://localhost:3000/api',
  //apiUrl: 'http://localhost:3004/api',
  apiRentacarUrl: 'http://localhost:3000/rentacar/',
  pathApiRent: 'http://localhost:3000/',
  indexUrl: 'http://localhost:4200/',
  indexUrlAntigua: 'http://localhost:4200/',
  // indexUrl: "http://localhost:4200/",
  usertoken:
    '9580af34ca299aa979a151280f6ef856bdee8b4dc9c17641d15df2cf54a5c7c876d369bc1b66715fa483c100755014f4'
} */
 

/* ############# Testing ############## */

export const environment = {
  production: false,
 // apiUrl: 'http://localhost:3000/api',

  apiUrl: 'http://179.57.172.224:4141/api',

  //apiUrl: 'https://api-finanzas.imlchile.cl',

  apiRentacarUrl: 'http://179.57.172.224:4141/rentacar/',
  pathApiRent: 'http://179.57.172.224:4141/',
  indexUrl: 'http://179.57.172.224:4242',
  indexUrlAntigua: 'http://179.57.172.224:4242/',
  // indexUrl: "http://localhost:4200",
  usertoken:
    '9580af34ca299aa979a151280f6ef856bdee8b4dc9c17641d15df2cf54a5c7c876d369bc1b66715fa483c100755014f4'
}



/*
######## Produccion ######
*/
/*  export const environment = {
  production: false,
  apiUrl: 'http://179.57.172.224:3001/api',
  apiRentacarUrl: 'http://179.57.172.224:3030/rentacar/',
  pathApiRent: 'http://179.57.172.224:3030/',
  indexUrl: "http://179.57.172.224:4202",
  indexUrlAntigua: "http://179.57.172.224:4202/",
  // indexUrl: "http://localhost:4200",
  usertoken: "9580af34ca299aa979a151280f6ef856bdee8b4dc9c17641d15df2cf54a5c7c876d369bc1b66715fa483c100755014f4"
}; */
