  <mat-card class="shipping-card">
      <h4 class="md-display-1">MANTENEDOR DE FOLIOS</h4>
    <mat-divider></mat-divider>
    <br><br>
      <mat-card-content class="content-folios-add">
          <mat-tab-group mat-align-tabs="start" class="contenedor">
            <mat-tab label="Solicitar CAF" class="tabs-folio">
              <br>
              <p>Solicitud de folios limitados a 20 consultas al mes <span *ngIf="sinConsultasb" style="color: red;">sin consultas restantes</span><!-- <span style="color: green;">consultas restantes: 17</span> --></p>
              <p>En caso de no haber consultas disponibles, puede subir el archivo CAF como alternativa</p>
              <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
                  <div>
                      <div class="row">
                        <!-- Tipo de documento -->
                        <div class="col">
                          <ng-select placeholder="Tipo de documento"f  bindLabel="name" formControlName="codigo">
                              <ng-option *ngFor="let d of dtes" [value]="d.id">{{d.nombre}}</ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <div class="row">
                        <!-- alerta -->
                        <div class="col">
                          <mat-form-field class="example-full-width">
                            <mat-label>Alertar</mat-label>
                            <input type="number" matInput placeholder="Ingrese alerta" autocomplete="off" formControlName="alerta">
                            <mat-icon matSuffix>notifications_white_18dp</mat-icon>
                          </mat-form-field>
                      </div>
                      <!-- seleccioar empresa -->
                      <div class="col">
                        <mat-form-field appearance="legacy">
                          <mat-select
                            placeholder="Seleccione empresa"
                            formControlName="empresa"
                            (selectionChange)="seleccionarEmpresa($event)"
                          >
                            <mat-option
                              *ngFor="let empresa of empresas"
                              value="{{ empresa.rut }}"
                            >
                              {{ empresa.nombre }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <!-- numeros de folios -->
                        <div class="col">
                          <mat-form-field class="full-width">
                              <mat-label>Números de folios</mat-label>
                              <input type="number" matInput maxlength="5" placeholder="Números de Folios a solicitar" autocomplete="off" formControlName="numero">
                              <mat-icon matSuffix>add_circle_outline</mat-icon>
                          </mat-form-field>
                        </div>
                    </div>
                  </div>
                <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid || subido">
                  <mat-icon>send</mat-icon> Enviar
              </button>
            </form>
            </mat-tab>
            <mat-tab label="Subir CAF">
                <mat-card>       
                    <mat-card-content>
                      <br><br>
                      <mat-form-field style="width: 100%;">
                        <mat-label>archivo CAF</mat-label>
                        <button mat-icon-button matPrefix (click)="f_input.click()">
                          <mat-icon>attach_file</mat-icon>
                        </button>
                        <input type="text" readonly matInput [formControl]="display"/>
                        <input
                          type="file"
                          hidden
                          #f_input
                          accept=".xml"
                          (change)="handleFileInputChange(f_input.files)"
                        />
                        <mat-error>El Campo es requerido</mat-error>
                      </mat-form-field>
                           <mat-form-field class="example-full-width">
                            <mat-label>Alertar</mat-label>
                            <input type="number" matInput placeholder="Ingrese alerta" autocomplete="off" [formControl]="numeroAlerta">
                            <mat-icon matSuffix>notifications_white_18dp</mat-icon>
                          </mat-form-field>
                        <button mat-stroked-button color="primary" (click)="handleSubmit()">
                          Registrar
                        </button>
                        <div class="row contenedor-spinner-col" *ngIf="subidoArchivo==true || folioAgregadoArchivo">
                          <div class="col spinner-col">
                            <div *ngIf="!folioAgregadoArchivo" class="sppiner">
                              <div class="img-spinner">
                                <mat-spinner [diameter]="24"></mat-spinner>
                              </div>
                              <br>
                              <div class="texto-spinner">
                                Subiendo folios, espere un momento...
                              </div>            
                            </div>
                            <p *ngIf="folioAgregadoArchivo">Folios agregados <span style="color: green;">✔️</span></p>
                          </div>
                        </div>
                    </mat-card-content>
               </mat-card>
            </mat-tab>
          </mat-tab-group>
      </mat-card-content>
      <div class="row contenedor-spinner-col" *ngIf="subido==true || folioAgregado">
        <div class="col spinner-col">
          <div *ngIf="!folioAgregado" class="sppiner">
            <div class="img-spinner">
              <mat-spinner [diameter]="24"></mat-spinner>
            </div>
            <br>
            <div class="texto-spinner">
              Solicitando folios al SII, espere un momento...
            </div>            
          </div>
          <p *ngIf="folioAgregado">Folios agregados <span style="color: green;">✔️</span></p>
        </div>
      </div>
  </mat-card>