import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {

  private onlineSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);

  constructor() {
    window.addEventListener('online', this.updateNetworkStatus.bind(this));
    window.addEventListener('offline', this.updateNetworkStatus.bind(this));
  }

  private updateNetworkStatus() {
    this.onlineSubject.next(navigator.onLine);
  }

  getNetworkStatus(): Observable<boolean> {
    return this.onlineSubject.asObservable();
  }

}
