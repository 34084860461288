import { AlertHelper } from './_helpers/alert.helper';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AuthInterceptor } from './_helpers/auth.interceptor';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { HomeMainComponent } from './_components/home-main/home-main.component';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { NavComponent } from './nav/nav.component';
import { HostalModule } from './_pages/hostal/hostal.module';
import { ComponentsModule } from './_components/components.module';
import { AuthSharedService } from './_pages/shared/shared-services/auth-shared.service';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import {MatStepperModule} from '@angular/material/stepper';


FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

/* Configurar Pipe español */
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {DecimalPipe} from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { ProveedoresModule } from './_pages/proveedores/proveedores.module';
import { CategoriasModule } from './_pages/categorias/categorias.module';
import { ClientesModule } from './_pages/clientes/clientes.module';
import { ProductosModule } from './_pages/productos/productos.module';
import { DialogProductosFotoComponent } from './_pages/productos/productos-list/dialog-productos-foto/dialog-productos-foto.component';
//import { PrimeNgModule } from './_pages/prime-ng/prime-ng.module';
import { DialogProductosEditarComponent } from './_pages/productos/productos-list/dialog-productos-editar/dialog-productos-editar.component';
import { AjusteInventarioModule } from './_pages/ajusteInventario/ajusteInventario.module';
import { ComprasModule } from './_pages/compras/compras.module';
import { FolioModule } from './_pages/folio/folio.module';
import { ConfiguracionModule } from './_pages/configuracion/configuracion.module';
import { InventarioModule } from './_pages/inventario/inventario.module';
import { StockActualModule } from './_pages/stockActual/stockActual.module';
import { ConsolidadoBancarioModule } from './_pages/consolidado-bancario/consolidado-bancario.module';
import { GuiaDespachoModule } from './_pages/guiaDespacho/guia-despacho.module';
import { CesionModule } from './_pages/cesiones/cesion.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PdfViewerModule } from 'ng2-pdf-viewer';




registerLocaleData(localeEs, 'es');




registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    HomeMainComponent,
    NavComponent,

  ],
  imports: [
    ComponentsModule,
    MatDatepickerModule,
    HostalModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MaterialModule,
    FullCalendarModule,
    MatAutocompleteModule,
    HttpClientModule,
    MatSortModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    CategoriasModule,
    ProveedoresModule,
    ProductosModule,
    // PrimeNgModule,
    AjusteInventarioModule,
    ClientesModule,
    MatStepperModule,
    ComprasModule,
    FolioModule,
    ConfiguracionModule,
    InventarioModule,
    StockActualModule,
    ConsolidadoBancarioModule,
    GuiaDespachoModule,
    CesionModule,
    PdfViewerModule,

  ],
  providers: [
    DecimalPipe,
    MatPaginatorIntl,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    /* Configurar Pipe español */
    { provide: LOCALE_ID, useValue: 'es' },
    AuthSharedService, AlertHelper,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
