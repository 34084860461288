import { Sucursal } from '../shared/sucursal';
import { Usuario } from '../shared/usuario';

export class EgresosRentacar {
    id!: string;
    tipoEgreso!: string;
    fecha!: string;
    monto!: number;
    montoCuota!: number;
    responsable!: string;
    descripcion!: string;
    idSucursal!: number;
    idUsuario!: number;
    RespaldoEgresoRentacar!: any[];
    idArriendo!: number;
    sucursal!: string;
    usuario!: string;
    tipoPago!: string;
    Sucursal!: Sucursal;
    Usuario!: Usuario;
    numeroCuota!: string;
    grupoGastos!: string;
    archivos!:any;
    banco!: string;
    numeroCuenta!: string;
    gastosFijos!:string;
    proyecto: String;
    region: String;   
    costoAmortizacion: number;
    tasaInteres: number;    
    cuentaGasto: string;
  }
