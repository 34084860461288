import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { modeloCompras } from '@app/_models/compras/compras'
import { ComprasService } from '../../compras.service'
import { first } from 'rxjs/operators'
import { ProveedoresService } from '../../../proveedores/proveedores.service';

@Component({
  selector: 'app-compras-form',
  templateUrl: './compras-form.component.html',
  styleUrls: ['./compras-form.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class ComprasFormComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  subido = false
  estado = false
  panelOpenState = false
  form!: FormGroup
  private fileTmp: any
  archivos: Array<File>
  proveedor: any

  //Modelo de cliente
  modelo: modeloCompras = new modeloCompras()

  constructor(
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private comprasService: ComprasService,
    private proveedoresService:ProveedoresService
  ) {}
  ngOnInit(): void {
    this.getProveedorInCompra()
  }

  infBasica = this._formBuilder.group({
    tipoDocumento: ['', [Validators.required]],
    proveedor: ['', [Validators.required]],
    direccion: ['', [Validators.required]],
    ciudad: ['', [Validators.required]],
    comuna: ['', [Validators.required]]
  })

  infPago = this._formBuilder.group({
    iva: ['', [Validators.required]],
    folio: ['', [Validators.required]],
    fechaEmision: ['', [Validators.required]],
    montoExento: ['', [Validators.required]],
    montoNeto: ['', [Validators.required]],
    montoIva: ['', [Validators.required]],
    montoTotal: ['', [Validators.required]],
    montoActivoFijo: null,
    montoIvaActivoFijo: null,
    montoIvaUsoComun: null,
    factorProporcionalidad: null,
    montoOtroImpuestoSinCredito: null
  })

  tabla = this._formBuilder.group({
    codigoImpuestoIvaNoRecuperable: null,
    montoIvaNoRecuperable: null,
    codigoImpuestoOtrosImpuestos: null,
    tasaImpuestoOtrosImpuestos: null,
    montoImpuestoOtrosImpuestos: null,
    comentarios: null
  })

  //LLamar proveedor
  getProveedorInCompra(){
    this.proveedoresService.getAllProveedores().pipe(first())
      .subscribe((proveedor)=>{
        this.proveedor= proveedor
      });

  }

  onSubmit() {
    //Union de formulario
    this.form = this._formBuilder.group(
      Object.assign(this.infBasica.value, this.infPago.value, this.tabla.value)
    )

    switch (this.form.status) {
      case 'VALID':
        this.subido = true

        this.modelo.tipoDTEString = this.form.value.tipoDocumento
        this.modelo.razonSocial = this.form.value.proveedor
        this.modelo.direccion = this.form.value.direccion
        this.modelo.ciudad = this.form.value.ciudad
        this.modelo.comuna = this.form.value.comuna
        this.modelo.montoIvaNoRecuperable = this.form.value.iva
        this.modelo.folio = this.form.value.folio
        this.modelo.fechaEmision = this.form.value.fechaEmision
        this.modelo.montoExento = this.form.value.montoExento
        this.modelo.montoNeto = this.form.value.montoNeto
        this.modelo.montoIvaRecuperable = this.form.value.montoIva
        this.modelo.montoTotal = this.form.value.montoTotal
        this.modelo.montoNetoActivoFijo = this.form.value.montoActivoFijo
        this.modelo.ivaActivoFijo = this.form.value.montoIvaActivoFijo
        this.modelo.ivaUsoComun = this.form.value.montoIvaUsoComun
        this.modelo.factorProporcionalidad =
          this.form.value.factorProporcionalidad
        this.modelo.impuestoSinDerechoCredito =
          this.form.value.montoOtroImpuestoSinCredito
        this.modelo.codigoIvaNoRecuperable =
          this.form.value.codigoImpuestoIvaNoRecuperable
        this.modelo.montoIvaNoRecuperable =
          this.form.value.montoIvaNoRecuperable
        this.modelo.valorOtroImpuesto =
          this.form.value.codigoImpuestoOtrosImpuestos
        this.modelo.tasaOtroImpuesto =
          this.form.value.tasaImpuestoOtrosImpuestos
        this.modelo.totalOtrosImpuestos =
          this.form.value.montoImpuestoOtrosImpuestos
        this.modelo.referencias = this.form.value.comentarios

        //Subir al servicio
        this.comprasService
          .registrarCompra(this.modelo)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.snackBar.open('Compra registrada con exito', 'cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })
              window.location.reload()
            },
            (error: any) => {
              this.snackBar.open(
                'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              this.snackBar.open(error, 'cerrar', {
                duration: 3000,
                verticalPosition: 'top'
              })
            }
          )
        break
      case 'INVALID':
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break
      default:
        break
    }
  }

  capturarArchivo(event: any) {
    this.archivos = event.target.files
    console.log('Captura:', this.archivos)
  }

  subirArchivo() {
    let formData = new FormData()
    for (let i = 0; i < this.archivos.length; i++) {
      formData.append('uploads[]', this.archivos[i], this.archivos[i].name)
    }
    //Call Service
    this.comprasService.subirArchivo(this.archivos).subscribe((res) => {
      console.log('Response: ', res)
    })
  }
}
