import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientesService } from '@app/_pages/clientes/clientes.service';
import { FolioService } from '@app/_pages/folio/folio.service';
import { BodegaService } from '@app/_pages/inventario/bodega/bodega.service';
import { ProductosService } from '@app/_pages/productos/productos.service';
import { first } from 'rxjs/operators';
import { GuiaDespacho } from '../../../_models/guiaDespacho/guiaDespacho';
import { envioSobre } from '../../../_models/guiaDespacho/envioSobre';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GuiaDespachoService } from '../guia-despacho.service';
import { Empresa } from '@app/_models/empresas/empresas-simpleapi.interface'
import { EmpresasSimpleapiService } from '@app/_pages/shared/shared-services/empresas-simpleapi.service'
import { MatSelectChange } from '@angular/material/select';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-despacho-form',
  templateUrl: './despacho-form.component.html',
  styleUrls: ['./despacho-form.component.scss']
})

export class DespachoFormComponent implements OnInit {
  isLoading: boolean = false;
  mensajeSII: string = '';
  modelo: GuiaDespacho = new GuiaDespacho()
  empresas: Empresa[];
  envioSobre: envioSobre = new envioSobre();
  bodega: any ;
  cliente: any;
  Costo : number [] = [];
  lista_productos : any;
  folio: any;
  incremento = -1
  form!: FormGroup

  val1: any
  val2: any
  totalGeneral: any
  respTotal: any
  respuestaTotal: any
  iva: any
  ProductosArray: any
  UltimoArray: any
  productosServicio: any
  panelOpenState = false
  afecto:any

  //cliente variable
  termino: any
  Clientes: any
  ClienteSeleccionado:any
  direccion:any
  comuna: any
  ciudad: any
  nombre: any
  rutCliente:any
  giro: any
  contacto:any
  rutEmpresa: any
  rutTransporte: any
  rutChofer: any
  Patente: any
  NombreChofer: any
  empresaSeleccionada: Empresa | null = null;
  DireccionDestino: any;
  ComunaDestino: any;
  CiudadDestino: any;
  archivoCAF: string;
  estado: string = '';
  trackId: string = '';
  razonRechazada: string = '';
  pdfUrl: SafeResourceUrl | null = null;
  guiaCreada: any;
  selectedValue: any;
  mostrarSpinner: boolean = false;

  rutValidator(control: AbstractControl): ValidationErrors | null {
    const rutPattern = /^[0-9]{7,8}-[0-9K]$/;
    const isValidRut = rutPattern.test(control.value);
    return isValidRut ? null : { invalidRut: true };
  }

  patenteValidator(control: AbstractControl): ValidationErrors | null {
    const patentePattern = /^[A-Z]{4}\d{2}$/;
    const isValidPatente = patentePattern.test(control.value);
    return isValidPatente ? null : { invalidPatente: true };
  }

  // Informacion Basica del Formulario
  infBasica = this.fb.group({
    bodega: null,
    cliente: ['', [Validators.required]],
    empresa: ['', [Validators.required]],
    rutCliente: null,
    giro: null,
    direccion: null,
    ciudad: null,
    comuna: null,
    contacto: null,
    plazoEntrega: null,
    vendedor: null,
    estado: ['Pendiente']
  })

  infTransporte = this.fb.group({
    rutTransporte: ['', [Validators.required,  this.rutValidator, Validators.maxLength(10)]],
    rutChofer: ['', [Validators.required, this.rutValidator, Validators.maxLength(10)]],
    Patente: ['', [Validators.required, this.patenteValidator, Validators.maxLength(6)]],
    NombreChofer: ['', [Validators.required]],
    DireccionDestino: ['', [Validators.required]],
    ComunaDestino: ['', [Validators.required]],
    CiudadDestino: ['', [Validators.required]],
    tipoTraslado: ['', [Validators.required]]
  })

  // Informacion Pago del Formulario
  infPago = this.fb.group({
    folio: null,
    tipoDocumento: ['Facturacion'],
    fechaCreacion: ['', [Validators.required]],
    valida: ['', [Validators.required]],
    formaPago: null,
    moneda: null,
    observacionesPago: null,
    ordenAlfabetico: null
  })

  // Informacion Prod del Formulario
  infProducto = this.fb.group({
    comentarios: null,
    respTotal: null,
    iva: null,
    totalGeneral: null,
    productos: this.fb.array([])
  })

  get productos() {
    return this.infProducto.get('productos') as FormArray
  }
  addProductos() {
    this.incremento = this.incremento + 1
    this.productos.push(
      this.fb.group({
        producto: [],
        descripcion: [],
        cantidad: [],
        precioNeto: [],
        descuento: [0],
        recargo: [0],
        AfEx: [''],
        subTotal: []
      })
    )
  }
  

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private _bodegaService: BodegaService,
    private _clientesService: ClientesService,
    private _productosService: ProductosService,
    private _folioService: FolioService,
    private _despachoService: GuiaDespachoService,
    private _empresaListService : EmpresasSimpleapiService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getClienteInfacturacion()
    this.getBodegaInFactura()
    this.getProductoInCotizacion()
    this.addProductos()
    this.getBodegaEmpresa()
  }

  //LLamar cliente
  getClienteInfacturacion() {
    this._clientesService
      .getAllClientes()
      .pipe(first())
      .subscribe((cliente) => {
        this.cliente = cliente
      })
  }

  getBodegaEmpresa() {
    this.empresas = this._empresaListService.getEmpresas();
  }

  //LLamar Bodega
  getBodegaInFactura() {
    this._bodegaService
      .bodegasGetAll()
      .pipe(first())
      .subscribe((bodega) => {
        this.bodega = bodega
      })
  }
  //LLamar producto
  getProductoInCotizacion() {
    this._productosService
      .productoGet()  
      .pipe(first())
      .subscribe((productosServicio) => {
        this.productosServicio = productosServicio
      })
  }

  cerrarModal() {
    this.isLoading = false;
    window.location.reload()
  }

  cerrarModalReintentar() {
    this.isLoading = false;
  }

  getFolioUtilizar(codigo, empresaRut) {
    this._folioService
      .getByFolioFactura(codigo, empresaRut)
      .pipe(first())
      .subscribe((folioServicio) => {
        console.log({folioServicio});
        this.archivoCAF = folioServicio[1];
        this.folio = folioServicio[0].siguienteFolio
      })
  }

  deleteProducto(index: number) {
    this.incremento = this.incremento - 1
    this.productos.removeAt(index)
  }

  magia(event: any, i : number) 
  {
    // this.productoForm = this.fb.group({
    //   productosSeleccionados: [''],
    //   ubicacion: [''],
    //   cantidad: [''],
    //   costo: [],
    //   subtotal: [''],
    //   stock: [''],
    //   ajuste: [''],
    // });
    // this.productos.push(this.productoForm)

    this._productosService.productoGetId(event.value).subscribe(
			(res) => {

        //this.productos.value[i].cantidad = res.neto
        this.Costo[i] = res.neto

         
    }
    );
  }


  opcionSeleccionada(event: MatAutocompleteSelectedEvent) {
    const cliente = event.option.value;
    this.termino = cliente.nombre;
    this.direccion = cliente.direccion;
    this.comuna = cliente.comuna;
    this.ciudad = cliente.ciudad;
    this.nombre = cliente.nombre;
    this.rutCliente = cliente.rut;
    this.giro = cliente.giro;
    this.contacto = cliente.telefono

    this._clientesService.getById(cliente.id)
    .subscribe(cliente => this.ClienteSeleccionado = cliente);

  }
  
  obtenerProductos(event: any){
    console.log(event.value)
  }

  OperacionTotal() {
    this.respuestaTotal = []
    const reducer = (accumulator, curr) => accumulator + curr
    this.val1 = this.infProducto.value.productos
    this.val1.forEach((element) => {
      this.val2 = element.cantidad * element.precioNeto
      element.subTotal = this.val2
      this.respuestaTotal = this.respuestaTotal.concat(this.val2)
      this.infProducto.value.productos[this.incremento].subTotal = this.val2
    })
    this.infProducto.value.productos[this.incremento].subTotal = this.val2
    this.respTotal = this.respuestaTotal.reduce(reducer)
    this.iva = this.respTotal * 0.19
    this.totalGeneral = this.iva + this.respTotal
    // this.afecto = 'Afecto'
  }

  async seleccionarEmpresa(event: any) {
    const rutEmpresaSeleccionada = event.value;
    this.empresaSeleccionada = this.empresas.find(emp => emp.rut === rutEmpresaSeleccionada);
    this.getFolioUtilizar('52', this.empresaSeleccionada.rut);
    console.log('Empresa seleccionada:', this.empresaSeleccionada);
    
  }

  getIdProducto(event:any, formChild: number){
    const refProductos = this.productos;
    for (let index = 0; index < this.productosServicio.length; index++) {
          const element = this.productosServicio[index];
          console.log({element});
          if (element.nombre == event.value ) {
              this.afecto = element.afecto ? 'Afecto' : 'Exento'
              console.log("afecto o no", this.afecto);

              refProductos.at(formChild).patchValue(
                  { 
                    id : element.id, 
                    descripcion : element.descripcion, 
                    precioNeto : parseInt(element.neto), 
                    Afex : element.afecto ? 'Afecto' : 'Exento', 
                  }
                )
            }
    }
    
  }


  onSelectionChange(event: MatSelectChange) {
    this.selectedValue = event.value;
    console.log(this.selectedValue.nombre);
    console.log(this.selectedValue.tipo);
  }

  onSubmit() {
    this.estado = '';
    this.pdfUrl = null;
    this.mostrarSpinner = true;
    console.log("rut", this.infBasica.value);
    this.infProducto.value.respTotal = this.respTotal
    this.infProducto.value.iva = this.iva
    this.infProducto.value.totalGeneral = this.totalGeneral
    this.infProducto.value.productos[this.incremento].subTotal = this.val2

    this.form = this.fb.group(
      Object.assign(this.infBasica.value, this.infTransporte.value,this.infPago.value, [
        this.infProducto.value
      ])
    )

    switch (this.form.status) {
      case 'VALID':
        // sobre envio
        this.envioSobre.folio = this.folio;
        this.envioSobre.passCert = this.empresaSeleccionada.claveCertificado;
        this.envioSobre.userCert = this.empresaSeleccionada.rutCertificado;
        this.envioSobre.rutEmisor = this.empresaSeleccionada.rut;
        this.envioSobre.rutReceptor = this.rutCliente;
        this.envioSobre.fechaResolucion = this.empresaSeleccionada.fechaResolucion;
        this.envioSobre.numeroResolucion = this.empresaSeleccionada.numeroResolucion;
        this.envioSobre.vendedor = this.form.value.vendedor;
        this.envioSobre.fechaCreacion = this.form.value.fechaCreacion

        // modelo despacho
        this.modelo.bodega = this.form.value.bodega
        this.modelo.cliente = this.form.value.cliente
        this.modelo.rutCliente = this.rutCliente
        this.modelo.giro = this.giro
        this.modelo.direccion = this.direccion
        this.modelo.ciudad = this.ciudad
        this.modelo.comuna = this.comuna
        this.modelo.contacto = this.contacto
        this.modelo.plazoEntrega = this.form.value.plazoEntrega
        this.modelo.vendedor = this.form.value.vendedor
        this.modelo.estado = this.form.value.estado
        this.modelo.folio = this.folio
        this.modelo.tipoDocumento = this.form.value.tipoDocumento
        this.modelo.fechaCreacion = this.form.value.fechaCreacion
        this.modelo.valida = this.form.value.valida
        this.modelo.formaPago = this.form.value.formaPago
        this.modelo.moneda = this.form.value.moneda

        this.modelo.observacionesPago = this.form.value.observacionesPago
        this.modelo.ordenAlfabetico = this.form.value.ordenAlfabetico
        this.modelo.comentarios = this.form.value[0].comentarios
        this.modelo.respTotal = this.form.value[0].respTotal
        this.modelo.iva = this.form.value[0].iva
        this.modelo.totalGeneral = this.form.value[0].totalGeneral
        this.modelo.productos = this.form.value[0].productos
        this.modelo.rutTransporte = this.form.value.rutTransporte
        this.modelo.rutChofer = this.form.value.rutChofer
        this.modelo.nombreChofer = this.form.value.NombreChofer
        this.modelo.patente = this.form.value.Patente
        this.modelo.direccionDestino = this.form.value.DireccionDestino
        this.modelo.comunaDestino = this.form.value.ComunaDestino
        this.modelo.ciudadDestino = this.form.value.CiudadDestino
        this.modelo.tipoTraslado = this.selectedValue.nombre
        this.modelo.tipoTrasladoDTE = this.selectedValue.tipo
        


        const consultarSIIPolling = () => {
          console.log("trackid", this.trackId);
          console.log("guia creada", this.guiaCreada);
          this._despachoService.consultarSII(this.envioSobre, this.guiaCreada).subscribe((resConsulta) => {
            console.log("consultar respuesta", resConsulta);
            if (resConsulta.success || resConsulta.pending) {
              if (resConsulta.response.cantidadAceptados >= 1) {
                this.estado = 'APROBADO';
              } else if (resConsulta.response.cantidadRechazados >= 1) {
                this.razonRechazada = resConsulta.response.glosa;
                this.estado = 'RECHAZADO';
              } else if (resConsulta.response.cantidadReparos >= 1) {
                this.estado = 'APROBADO CON REPAROS';
              } else if (resConsulta.pending === true) {
                // Si la respuesta está pendiente, volver a llamar a la consulta después de un delay
                setTimeout(() => {
                  consultarSIIPolling();
                }, 15000); 
                return;
              } else if (resConsulta.erR_CODE === true) {
                setTimeout(() => {
                  consultarSIIPolling();
                }, 15000); 
                return;
              }
              
              this.trackId = resConsulta.response.trackId;
              this.mensajeSII = `Estado: ${this.estado} (${resConsulta.response.estado})`;
              this._despachoService.imprimirDespacho(this.guiaCreada, this.empresaSeleccionada).subscribe(async (resConsulta) => {
                console.log("imprimir respuesta", resConsulta);
                const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                await delay(10000);
                this._despachoService.traerPDFDespacho(this.folio, this.empresaSeleccionada.rut).subscribe((resPDF) => {
                  console.log("imprimir respuesta", resPDF);
                  // Convertir Base64 a URL de datos
                  const pdfBase64 = resPDF.pdfb64;
                  const pdfDataUrl = `data:application/pdf;base64,${pdfBase64}`;
                  this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfDataUrl);
                  this.mostrarSpinner = false;
                  this.snackBar.open('Guía enviada con éxito', 'cerrar', {
                    duration: 2000,
                    verticalPosition: 'top'
                  });
                });
              });
            } else {
              console.log("consultar respuesta", resConsulta);
              this.estado = 'ERROR';
              this.mensajeSII = 'ERROR al consultar o enviar al SII, por favor intente nuevamente';
              this.mostrarSpinner = false;
            }
          });
        };
        
        this.isLoading = true;
        this.mensajeSII = 'Enviando datos y generando archivo DTE';
        if(this.trackId != ''){
          this.mensajeSII = 'Guía de despacho enviada y recibida por el SII, consultando estado del envío';
          this.guiaCreada.trackId = this.trackId;
          consultarSIIPolling();
        } else {
          this._despachoService
            .registrarDespacho(this.modelo, this.empresaSeleccionada, this.archivoCAF)
            .pipe(first())
            .subscribe(
              (data: any) => {
                console.log("data", data);
                this.guiaCreada = data.despachoGuia;
                console.log("guía", this.guiaCreada);
                if (data.success){
                  this.mensajeSII = 'Enviando archivo DTE y recibiendo sobre de envío';
  
                  this._despachoService
                  .despachoGenerarSobreEnvioDTE(this.envioSobre).subscribe((resSobre) => {
                    console.log("res", resSobre);
                    if (resSobre.success){
                      this.mensajeSII = 'Enviando sobre de envío al SII';
  
                      this._despachoService
                      .enviarSII(this.envioSobre).subscribe(async (resEnvio) => {
                        this.mensajeSII = 'Guía de despacho enviada y recibida por el SII, consultando estado del envío';
                        // Función para esperar un tiempo específico
                        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                        await delay(7000); // Espera 5 segundos
                        
                        console.log("resEnvio", resEnvio);
                        if (resEnvio.success) {
                        
                          consultarSIIPolling();
                        
                        } else {
                          this.estado = 'ERROR';
                          this.mensajeSII = 'ERROR al enviar al SII, por favor intente nuevamente';
                          this.mostrarSpinner = false;
                        }
                        // if (resEnvio.success) {
                        //   this._despachoService.consultarSII(this.envioSobre, this.guiaCreada).subscribe((resConsulta) => {
                        //     console.log("consultar respuesta", resConsulta);
                        //     if(resConsulta.success){
                        //       if (resConsulta.response.cantidadAceptados >= 1) {
                        //         this.estado = 'APROBADO'
                        //       } else if (resConsulta.response.cantidadRechazados >= 1) {
                        //         this.razonRechazada = resConsulta.response.glosa;
                        //         this.estado = 'RECHAZADO'
                        //       } else if (resConsulta.response.cantidadReparos >= 1) {
                        //         this.estado = 'APROBADO CON REPAROS'
                        //       } 
                        //       this.trackId = resConsulta.response.trackId;
                        //       this.mensajeSII = `Estado: ${this.estado}(${resConsulta.response.estado})`;
                        //       this._despachoService.imprimirDespacho(this.guiaCreada, this.empresaSeleccionada).subscribe(async (resConsulta) => {
                        //         console.log("imprimir respuesta", resConsulta);
                        //         const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                        //         await delay(10000);
                        //         this._despachoService.traerPDFDespacho(this.folio, this.empresaSeleccionada.rut).subscribe((resPDF) => {
                        //           console.log("imprimir respuesta", resPDF);
                        //           // Convertir Base64 a URL de datos
                        //           const pdfBase64 = resPDF.pdfb64;
                        //           const pdfDataUrl = `data:application/pdf;base64,${pdfBase64}`;
                        //           this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfDataUrl);
                        //           });
                        //       });
                        //     }else {
                        //       console.log("consultar respuesta", resConsulta);
                        //       this.estado = 'ERROR';
                        //       this.mensajeSII = 'ERROR al consultar o enviar al SII por favor intente nuevamente';
                        //     }
                        //   });
                        //   this.snackBar.open('Guía enviada con exito', 'cerrar', {
                        //     duration: 2000,
                        //     verticalPosition: 'top'
                        //   })
                        // } else {
                        //   this.estado = 'ERROR';
                        //   this.mensajeSII = 'ERROR al enviar al SII por favor intente nuevamente';
                          
                        // }
                      });
                    }
                  });
                }
  
              },
              (error: any) => {
                this.snackBar.open(
                  'Hubo un problema para realizar la guía',
                  'cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
                this.snackBar.open(error, 'cerrar', {
                  duration: 3000,
                  verticalPosition: 'top'
                })
              }
            )
        }
        break
      case 'INVALID':
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }

}
