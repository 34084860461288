import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Usuario } from '@app/_models/shared/usuario'
import { GastoService } from '@app/_pages/gasto-personal/gasto.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-personal-egresos-acciones',
  templateUrl: './personal-egresos-acciones.component.html',
  styleUrls: ['./personal-egresos-acciones.component.scss'],
  providers: [
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } }
  ]
})
export class PersonalEgresosAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  panelOpenState = false
  clickButton = true
  idEgresoPersonal: any
  sucursales: Sucursal[]
  tiposPagos: string[]
  grupos: string[] = []
  tiposEgresos: string[]
  dataArrayImg = []

  form!: FormGroup
  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    monto: ['', Validators.required],
    tipoEgreso: ['', Validators.required],
    descripcion: ['', Validators.required],
    responsable: ['', Validators.required],
    tipoPago: ['', Validators.required],
    grupoGastos: ['', Validators.required],
    archivos: null
  })
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  constructor(
    private fb: FormBuilder,
    private gastoService: GastoService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.tiposPagos = [
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Credito',
      'Transferencia',
      'Cheque'
    ]
    this.grupos = ['TRANSFERENCIAS GASTO PERSONAL']
    this.tiposEgresos = [
      'Gastos Fijos',
      'Gastos',
      'Costos',
      'Remuneraciones',
      'Bancarios',
      'Impuestos',
      'Combustible',
      'Inversiones'
    ]
  }

  ngOnInit(): void {
    this.idEgresoPersonal = localStorage.getItem('idEgresoPersonal')
    this.getEgresoPersonal()
  }

  get f() {
    return this.addressForm.controls
  }
  closeDialog() {
    this.gastoService.closeDialogModal()
    window.location.reload()
  }

  getEgresoPersonal() {
    this.gastoService
      .getByIdEgreso(this.idEgresoPersonal)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idEgresoPersonal) {
          this.f.fecha.setValue(x[0].fecha)
          this.f.monto.setValue(x[0].monto)
          this.f.tipoEgreso.setValue(x[0].tipoEgreso)
          this.f.descripcion.setValue(x[0].descripcion)
          this.f.responsable.setValue(x[0].responsable)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.grupoGastos.setValue(x[0].grupoGastos)
          this.f.archivos.setValue(x[0].archivos)
        }
        this.previsualizacion = x[0].archivos
          console.log('img gastopersonal',this.previsualizacion)

      })
  }
  agregarIMG() {
    if (confirm('Seguro que desea actulizar IMG de Ingreso')) {
      this.gastoService
        .getByIdEgresoSinBase64(this.idEgresoPersonal)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idEgresoPersonal) {
            const data = x[0]
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.monto = data.monto
            this.addressForm.value.tipoEgreso = data.tipoEgreso
            this.addressForm.value.descripcion = data.descripcion
            this.addressForm.value.responsable = data.responsable
            this.addressForm.value.tipoPago = data.tipoPago
            this.addressForm.value.grupoGastos = data.grupoGastos
            this.addressForm.value.archivos = this.dataArrayImg
            this.gastoService
              .updateEgresoHostal(this.idEgresoPersonal, this.addressForm.value)
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(
                    'Imagen actulizada correctamente!.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                  this.addressForm.reset()
                  this.gastoService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el Ingreso de Hostal, favor contactar a Soporte.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'egresoPersonal' + Date.now() + '.' + extension
    this.dataArrayImg.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        console.log(image)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/egresoPersonal/Upload/EgresoPersonal`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

  onSubmit() {
    if (confirm('Seguro que desea actualizar los Egresos Personales :')) {
      this.gastoService
        .getByIdEgresoSinBase64(this.idEgresoPersonal)
        .pipe(first())
        .subscribe((x: any) => {
          const nombreArchivo = x[0].archivos
          const JsonNombreArchivo = JSON.parse(nombreArchivo)
          if (this.dataArrayImg[0] === undefined) {
            this.addressForm.value.archivos = JsonNombreArchivo
          } else {
            this.addressForm.value.archivos = this.dataArrayImg
          }
          switch (this.addressForm.status) {
            case 'VALID':
               this.subirIMG() 
              this.gastoService
                .updateEgresoHostal(
                  this.idEgresoPersonal,
                  this.addressForm.value
                )
                .pipe(first())
                .subscribe(
                  (data) => {
                    this.snackBar.open(
                      'Egreso Hostal editado con exito',
                      'Cerrar',
                      { duration: 2000, verticalPosition: 'top' }
                    )
                    this.addressForm.reset()
                    this.gastoService.closeDialogModal()
                    window.location.reload()
                  },
                  (error) => {
                    this.snackBar.open(
                      'No se pudo editar el Egreso de Hostal, favor contactar a Soporte.',
                      'Cerrar',
                      {
                        duration: 2000,
                        verticalPosition: 'top'
                      }
                    )
                  }
                )
              break
            case 'INVALID':
              this.snackBar.open(
                'El formulario debe ser completado.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              break

            default:
              break
          }
        })
    }
  }
}
