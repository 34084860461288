import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AjusteInventarioFormSalidaComponent } from './ajuste-inventario-form-salida/ajuste-inventario-form-salida.component';
import { AjusteInventarioFormComponent } from './ajuste-inventario-form/ajuste-inventario-form.component';
import { AjusteInventarioHomeComponent } from './ajuste-inventario-home/ajuste-inventario-home.component';

const routes: Routes = [
  { path: 'inventario/ajusteInventario', component: AjusteInventarioHomeComponent },
  { path: 'inventario/ajusteInventario/form', component: AjusteInventarioFormComponent },   
  { path: 'inventario/ajusteInventario/form-salida', component: AjusteInventarioFormSalidaComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AjusteInventarioRoutingModule { }
