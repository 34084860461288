import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FolioHomeComponent } from './folio-home/folio-home.component';

const routes: Routes = [
  {path: 'folio', component: FolioHomeComponent }

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FolioRoutingModule { }
