import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FolioHomeComponent } from './folio-home/folio-home.component'
import { FolioTableComponent } from './folio-home/folio-table/folio-table.component'
import { FolioFormComponent } from './folio-home/folio-form/folio-form.component'
import { FolioRoutingModule } from './folio-routing.module'
import { ComponentsModule } from '@app/_components/components.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FullCalendarModule } from '@fullcalendar/angular'
import { HttpClientModule } from '@angular/common/http'
import { MaterialModule } from '@app/material.module'
import { MatTableExporterModule } from 'mat-table-exporter'
import { MatTabsModule } from '@angular/material/tabs';
import { FolioDisponibleComponent } from './folio-home/folio-table/folio-disponible/folio-disponible.component'
import { NgSelectModule } from '@ng-select/ng-select';
import { FolioRestantesComponent } from './folio-restantes/folio-restantes.component';

@NgModule({
  declarations: [FolioHomeComponent, FolioTableComponent, FolioFormComponent, FolioDisponibleComponent, FolioRestantesComponent],
  imports: [
    CommonModule,
    FolioRoutingModule,
    MatTabsModule,
    MaterialModule,
    HttpClientModule,
    ComponentsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FormsModule,
    MatTableExporterModule,
    NgSelectModule,
  ],
  exports: [
    FolioRoutingModule,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
    FolioRestantesComponent,
  ]
})
export class FolioModule {}
