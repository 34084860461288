
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@app/material.module";
import { ComponentsModule } from "@app/_components/components.module";
import { ConfiguracionRoutingModule } from "./configuracion.routing.module";
import { ConfiguracionCesionariosFormComponent } from "./configuracion-cesionarios/configuracion-cesionarios-form/configuracion-cesionarios-form.component";
import { ConfiguracionCesionariosListComponent } from "./configuracion-cesionarios/configuracion-cesionarios-list/configuracion-cesionarios-list.component";
import { DialogCesionarioEditarComponent } from "./configuracion-cesionarios/configuracion-cesionarios-list/dialog-cesionarios-editar/dialog-cesionario-editar.component";
import { ConfiguracionHomeComponent } from "./configuracion-cesionarios/configuracion-home/configuracion-home.component";
import { ConfiguracionService } from "./configuracion-cesionarios/configuracion.service";

@NgModule({
    declarations: [
      ConfiguracionCesionariosFormComponent,
      ConfiguracionCesionariosListComponent,
      ConfiguracionHomeComponent,
      DialogCesionarioEditarComponent
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      ComponentsModule,
      MaterialModule,
      // se importa el modulo rutas que se creo en el directorio de configuración 
      ConfiguracionRoutingModule
    ],
    providers: [
      ConfiguracionService
    ]
  })
export class ConfiguracionModule {}
