<h1>Registro de Cuenta</h1>
<div class="mat-elevation-z8">
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> Cuenta Bancaria </mat-panel-title>
      <mat-panel-description>
        Para Crear una Cuenta Bancaria Presiona Aqui
        <mat-icon>touch_app_white_18dp</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-horizontal-stepper linear>
      <mat-step [stepControl]="infBasica" completed="false">
        <ng-template matStepLabel>Informacion Basica</ng-template>
        <mat-card>
          <mat-card-title-group>
            <mat-card-title>Cuenta Bancarias</mat-card-title>
            <mat-card-subtitle
              >Para agregar una cuenta bancaria completar el
              formulario</mat-card-subtitle
            >
          </mat-card-title-group>

          <mat-card-content>
            <form [formGroup]="infBasica" novalidate (ngSubmit)="onSubmit()">
              <div class="row">
                <!-- Tipo banco   -->
                <div class="col">
                  <mat-form-field appearance="legacy">
                    <mat-label>Selecciona Banco </mat-label>
                    <mat-select formControlName="banco">
                      <mat-option value="BancoChile"> Banco Chile </mat-option>
                      <mat-option value="BancoEstado">Banco Estado</mat-option>
                      <mat-option value="BancoSantander"
                        >Banco Santander</mat-option
                      >
                      <mat-option value="BancoScotiabank"
                        >Banco Scotiabank</mat-option
                      >
                      <mat-option value="BancoDeCreditoeInverisiones"
                        >Banco De Credito e Inverisione</mat-option
                      >
                      <mat-option value="BancoBice">Banco Bice</mat-option>
                      <mat-option value="BancoItau"> Banco Itau </mat-option>
                      <mat-option value="HSBCBANK"> HSBC BANK </mat-option>
                      <mat-option value="BancoSecurity">
                        Banco Security
                      </mat-option>
                      <mat-option value="BancoFalabella">
                        Banco Falabella
                      </mat-option>
                      <mat-option value="BancoRipley"> Banco Ripley </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>account_balance_18dp</mat-icon>
                  </mat-form-field>
                </div>
                <!-- tipo de monto  -->
                <div class="col">
                  <mat-form-field appearance="legacy">
                    <mat-label>Selecciona Monto De Cuenta </mat-label>
                    <mat-select formControlName="tipoMonto">
                      <mat-option value="montoBancario">
                        Monto Bancario
                      </mat-option>
                      <mat-option value="montoCredito">
                        Monto Credito</mat-option
                      >
                      <mat-option value="montoDisponible"
                        >Monto Disponible</mat-option
                      >
                    </mat-select>
                    <mat-icon matSuffix>real_estate_agent_white_18dp</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <!-- Nombre -->
                <div class="col">
                  <mat-form-field appearance="legacy">
                    <input
                      matInput
                      placeholder="Ingresa Nombre "
                      formControlName="nombreCuenta"
                    />
                    <mat-icon matSuffix>face_18dp</mat-icon>
                  </mat-form-field>
                </div>
                <!-- rut -->
                <div class="col">
                  <mat-form-field appearance="legacy">
                    <mat-label>Rut</mat-label>
                    <input
                      matInput
                      minlength="8"
                      maxlength="10"
                      placeholder="Ingresa Rut"
                      formControlName="rut"
                      required
                      [(ngModel)]="tamano_rut"
                      type="string"
                      (input)="formatoRut()"
                    />
                    <mat-icon matSuffix>fingerprint_white_18dp</mat-icon>
                    <mat-error
                      *ngIf="infBasica.controls['rut'].hasError('required')"
                    >
                      <strong> El rut es requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- Numero de cuenta -->
                <div class="col">
                  <mat-form-field appearance="legacy">
                    <input
                      matInput
                      placeholder="Ingresa Numero De Cuenta"
                      formControlName="numeroCuenta"
                    />
                    <mat-icon matSuffix>verified_user_18dp</mat-icon>
                  </mat-form-field>
                </div>
                <!-- Monto de pago  -->
                <div class="col">
                  <mat-form-field appearance="legacy">
                    <input
                      matInput
                      type="number"
                      placeholder="Ingresa Monto A Adicionar "
                      formControlName="totalMonto"
                    />
                    <span matPrefix>$&nbsp;</span>
                  </mat-form-field>
                </div>
              </div>

              <hr />
              <!-- boton  de acciones-->
              <div>
                <button
                  mat-raised-button
                  color="primary"
                  type="submit"
                  [disabled]="infBasica.invalid"
                >
                  <mat-icon>send</mat-icon> Enviar
                </button>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-expansion-panel>
</div>
