import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsolidadoBancarioHomeComponent } from './consolidado-bancario-home/consolidado-bancario-home.component';
import { HttpClientModule } from '@angular/common/http';
import { ConsolidadoBancarioRoutingModule } from './consolidado-bancario-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MaterialModule } from '@app/material.module';
import { ComponentsModule } from '@app/_components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ConsolidadoBancarioSolicitudComponent } from './consolidado-bancario-solicitud/consolidado-bancario-solicitud.component';
import { ConsolidadoBancarioPagoComponent } from './consolidado-bancario-pago/consolidado-bancario-pago.component';
import { SolictudFormComponent } from './consolidado-bancario-solicitud/solictud-form/solictud-form.component';
import { ConsolidadoFromularioComponent } from './consolidado-diario-pago-gasto/consolidado-fromulario/consolidado-fromulario.component';
import { SolictudTableComponent } from './consolidado-bancario-solicitud/solictud-table/solictud-table.component';

import { HomeFormComponent } from './consolidado-bancario-home/home-form/home-form.component';
import { HomeGrafComponent } from './consolidado-bancario-home/home-graf/home-graf.component';
import { HomeTableComponent } from './consolidado-bancario-home/home-table/home-table.component';
import { HomeAccionesComponent } from './consolidado-bancario-home/home-table/home-acciones/home-acciones.component';
import { PagoTableComponent } from './consolidado-bancario-pago/pago-table/pago-table.component';
import { PagoAccionesComponent } from './consolidado-bancario-pago/pago-table/pago-acciones/pago-acciones.component';
import { PagoGrafComponent } from './consolidado-bancario-graf/pago-graf/pago-graf.component';
import { SolicitudTableAccionesComponent } from './consolidado-bancario-solicitud/solictud-table/solicitud-table-acciones/solicitud-table-acciones.component';
import { ConsolidadoBancarioGrafComponent } from './consolidado-bancario-graf/consolidado-bancario-graf.component';
import { ConsolidadoDiarioPagoGastoComponent } from './consolidado-diario-pago-gasto/consolidado-diario-pago-gasto.component';
import { ConsolidadoTotalComponent } from './consolidado-diario-pago-gasto/consolidado-total/consolidado-total.component';
import { TablaSolicitudesAntiguasComponent } from './consolidado-bancario-pago/pago-table/tabla-solicitudes-antiguas/tabla-solicitudes-antiguas.component';
import { DialogSolicitudesAntiguasComponent } from './consolidado-bancario-pago/pago-table/dialog-solicitudes-antiguas/dialog-solicitudes-antiguas.component';
//import {AutocompleteLibModule} from 'angular-ng-autocomplete';
//import { GastosFijosComponent } from './gastos-fijos/gastos-fijos.component';
//import { GastosFijosCalendarioComponent } from './gastos-fijos/gastos-fijos-calendario/gastos-fijos-calendario.component';
//import { GastosFijosFormularioComponent } from './gastos-fijos/gastos-fijos-formulario/gastos-fijos-formulario.component';



@NgModule({
  declarations: [ConsolidadoBancarioHomeComponent, ConsolidadoBancarioSolicitudComponent, ConsolidadoBancarioPagoComponent, SolictudFormComponent, SolictudTableComponent, HomeFormComponent, HomeGrafComponent, HomeTableComponent, HomeAccionesComponent, PagoTableComponent, PagoAccionesComponent, PagoGrafComponent, SolicitudTableAccionesComponent, ConsolidadoBancarioGrafComponent,ConsolidadoDiarioPagoGastoComponent,ConsolidadoFromularioComponent, ConsolidadoTotalComponent, TablaSolicitudesAntiguasComponent, DialogSolicitudesAntiguasComponent,],
  imports: [
    CommonModule,
    HttpClientModule,
    ConsolidadoBancarioRoutingModule,
    MatTabsModule,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FormsModule,
    MatTableExporterModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    //AutocompleteLibModule
  ],
  exports: [
    MaterialModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ConsolidadoBancarioRoutingModule,
    //AutocompleteLibModule
  ]
})
export class ConsolidadoBancarioModule { }
