
<mat-toolbar color="primary">
    <button
    style="margin-left: 1rem; margin-bottom: 3px"
    mat-raised-button
    color="accent"
    (click)="actualizar()"
    >
    Actualizar tabla <mat-icon>autorenew_white_18dp</mat-icon>
    </button>
    <span style="margin-right: 1rem">Egresos Empresas</span>
    <br>
    <button
    mat-raised-button
    color="accent"
    style="margin-left: 1rem"
    (click)="revelarTotal()"
    >
    Revelar total
    </button>
    <button
    mat-raised-button
    color="accent"
    style="margin-left: 1rem"
    (click)="exportAsXLSX()"
  >
    Exportar
  </button>
    <br> 
    <span style="margin-right: 3rem">  Monto Datos Filtrados: 
    {{ this.totalSeleccion | currency : '$' : 'symbol' : '1.0-0' }}
    </span>

</mat-toolbar>
<!-- Tabla -->
<div class="table-responsive">
    <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="id"
    matSortDirection="desc"
    class="mat-elevation-z10"
    >
    <!-- check -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- id Columna -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
              <input  matInput placeholder="id" formControlName="id" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <!-- Monto -->
    <ng-container matColumnDef="monto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div [formGroup]="formFilter">
            <mat-form-field class="fill limit-width-60">
              <input matInput placeholder="Monto" formControlName="monto" />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.monto?.toLocaleString('es-CL') }}
        </td>
      </ng-container>
    <!-- Fecha -->
    <ng-container matColumnDef="fecha" class="mat-column-date"> 
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="limit-width-180"
        >
          <mat-form-field appearance="fill" class="limit-width-180">

            <mat-label>Fecha</mat-label>

            <mat-date-range-input [formGroup]="formFilter" [rangePicker]="picker">
              <input
                matStartDate
                formControlName="start"
                placeholder="Start date"/>
              <input 
                matEndDate 
                formControlName="end" 
                placeholder="End date"/>

            </mat-date-range-input>

            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Cancel</button>
                <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
  
            <mat-error
              *ngIf="formFilter.controls.start.hasError('matStartDateInvalid')"
              >Invalid start date
            </mat-error>

            <mat-error
              *ngIf="formFilter.controls.end.hasError('matEndDateInvalid')"
              >Invalid end date
            </mat-error>

          </mat-form-field>

        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.fecha ? (element.fecha | date : 'dd/MM/yyyy') : '' }}
        </td>
    </ng-container>

    <!-- descripcionEgreso Columna -->
    <ng-container matColumnDef="descripcion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div [formGroup]="formFilter">
            <mat-form-field class="full-width">
              <input matInput placeholder="Descripcion" formControlName="descripcion" />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.descripcion?.toLowerCase() }}

        </td>
    </ng-container>
    
    <!-- tipoEgreso Columna -->
    <ng-container matColumnDef="tipoEgreso">
        <th mat-header-cell *matHeaderCellDef>
            <div [formGroup]="formFilter">
                <mat-form-field class="full-width">
                    <mat-select placeholder="Tipo Egreso" formControlName="tipoEgreso">
                        <mat-option *ngFor="let Egreso of tiposEgresos" [value]="Egreso">
                            {{ Egreso }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.tipoEgreso }}</td> 
    </ng-container>

    <!-- tipoPago Columna -->
    <ng-container matColumnDef="tipoPago">
        <th mat-header-cell *matHeaderCellDef>
            <div [formGroup]="formFilter">
                <mat-form-field class="full-width">
                    <mat-select placeholder="Tipo Pago" formControlName="tipoPago">
                        <mat-option *ngFor="let pago of tiposPagos" [value]="pago">
                            {{ pago }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.tipoPago }}</td> 
    </ng-container>

    <!-- Sucursal -->
    <ng-container matColumnDef="sucursal">
        <th mat-header-cell *matHeaderCellDef>
          <div [formGroup]="formFilter">
            <mat-form-field class="full-width">
              <mat-select placeholder="sucursal" formControlName="idSucursal">
                <mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">
                  {{ sucursal.razonSocial }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.Sucursal.razonSocial}}
        </td>
    </ng-container>
    <!-- Empresa -->
    <ng-container matColumnDef="empresa">
        <th mat-header-cell *matHeaderCellDef>
          <div [formGroup]="formFilter">
            <mat-form-field class="full-width">
              <mat-select placeholder="Empresa" formControlName="empresa">
                <mat-option *ngFor="let empresa of empresas" [value]="empresa">
                  {{ empresa.razonSocial }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No existen coincidencias</td>
      </tr>
    </table>
</div>
<!-- Paginator -->
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>

