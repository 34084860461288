import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'

export class modeloCompras {
  Usuario!: Usuario
  Sucursal!: Sucursal
  tipoDTEString!: string
  tipoDTE!: string
  tipoCompra!: string
  rutProveedor!: string
  razonSocial!: string
  folio!: number
  fechaEmision!: string
  fechaRecepcion!: string
  estadoAcuse!: number
  montoExento!: number
  montoNeto!: number
  montoIvaRecuperable!: number
  montoIvaNoRecuperable!: number
  codigoIvaNoRecuperable!: number
  montoTotal!: number
  montoNetoActivoFijo!: number
  ivaActivoFijo!: number
  ivaUsoComun!: number
  impuestoSinDerechoCredito!: number
  ivaNoRetenido!: number
  tabacosPuros!: number
  tabacosCigarrillos!: number
  tabacosElaborados!: number
  totalOtrosImpuestos!: number
  valorOtroImpuesto!: string
  tasaOtroImpuesto!: string
  tipoDocReferencia!: string
  referencias!: string
  referenciado!: string
  reparos!: string
  otrosImpuestos!: string
  estado!: string
  direccion!: string
  ciudad!: string
  comuna!: string
  factorProporcionalidad!: number
  descripcion!: string
}
