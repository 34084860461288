import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild
} from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { modeloSolicitudBancaria } from '@app/_models/consolidadoBancario/solicitudBancaria'
import { SelectionModel } from '@angular/cdk/collections'
import { FormControl, FormGroup } from '@angular/forms'
import { ConsolidadoBancarioService } from '@app/_pages/consolidado-bancario/consolidado-bancario.service'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { format } from 'rut.js'
import { Usuario } from '@app/_models/shared/usuario'

@Component({
  selector: 'app-tabla-solicitudes-antiguas',
  templateUrl: './tabla-solicitudes-antiguas.component.html',
  styleUrls: ['./tabla-solicitudes-antiguas.component.scss']
})
export class TablaSolicitudesAntiguasComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator: QueryList<MatPaginator>
  @ViewChild(MatSort) sort: MatSort

  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  dataSource: MatTableDataSource<modeloSolicitudBancaria> =
    new MatTableDataSource()
  selection = new SelectionModel<modeloSolicitudBancaria>(true, [])
  rolUsuario: string
  mostrarAlertaPorEmitir = false
  totalSeleccion = 0

  formFilter = new FormGroup({
    id: new FormControl(),
    nombreTransferencia: new FormControl(),
    start: new FormControl(),
    end: new FormControl(),
    rut: new FormControl(),
    NumeroCuenta: new FormControl(),
    jefeAutoriza: new FormControl(),
    estado: new FormControl(),
    montoPago: new FormControl(),
    motivoPago: new FormControl(),
    perfilUsuario: new FormControl(),
    nombreAutoriza: new FormControl(),
    razonRechazo: new FormControl()
  })

  dataSolicitudBancaria: modeloSolicitudBancaria[] = []
  displayedColumns: string[] = [
    'select',
    'id',
    'jefeAutoriza',
    'rut',
    'nombreTransferencia',
    'NumeroCuenta',
    'fechaSolicitud',
    'motivoPago',
    'perfilUsuario',
    'nombreAutoriza',
    'razonRechazo',
    'estado',
    'confirmadoPorFinanzas',
    'montoPago',
    'acciones'
  ]

  constructor(private consolidadoService: ConsolidadoBancarioService) {}

  panelOpenState = false
 
  ngOnInit(): void {
    this.getAllSolicitudesOld()
    this.filtro()
  }

  limpiarTabla() {
    this.dataSolicitudBancaria = []
    this.dataSource.data = []
    this.selection.clear()
  }

  verSolicitud(id:any){
    localStorage.setItem('idSolicitudOld',id)
    this.consolidadoService.verDialog()
  }

  getAllSolicitudesOld() {
    this.consolidadoService
      .getAllSolicitudBacariaOld()
      .subscribe((data: modeloSolicitudBancaria[]) => {
        const filteredData = data.filter(
          (element) => element.estado !== 'Pendiente'
        )
        this.dataSolicitudBancaria = filteredData.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          Solicitud.perfilUsuario = Solicitud.Usuario?.nombreUsuario
          return Solicitud
        })
        this.dataSource = new MatTableDataSource(this.dataSolicitudBancaria)
        this.dataSource.paginator = this.paginator.toArray()[0]
        this.dataSource.sort = this.sort
      })
  }

  filtro() {
    this.formFilter.valueChanges.subscribe((res) => {
      const { id, monto } = res
      let dataFiltered = this.dataSolicitudBancaria

      if (id) {
        dataFiltered = dataFiltered.filter(
          (data: modeloSolicitudBancaria) => data.id.toString() === id
        )
      }
      if (res.nombreAutoriza) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.jefeAutoriza
            .toLowerCase()
            .includes(res.nombreAutoriza.toLowerCase())
        )
      }
      if (res.rut) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.rut.toLowerCase().includes(res.rut.toLowerCase())
        )
      }
      if (res.nombreTransferencia) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.nombreTransferencia
            .toString()
            .toLowerCase()
            .includes(res.nombreTransferencia.toLowerCase())
        )
      }
      if (res.NumeroCuenta) {
        dataFiltered = dataFiltered.filter(
          (data: modeloSolicitudBancaria) =>
            data.NumeroCuenta === res.NumeroCuenta
        )
      }

      this.dataSource = new MatTableDataSource(dataFiltered)
      this.dataSource.paginator = this.paginator.toArray()[0]
      this.dataSource.sort = this.sort
      this.totalSeleccion = 0
      this.selection.clear()
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) =>
          this.selection.select(row)
        )
  }
}
