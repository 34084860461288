import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stockActual',
  templateUrl: './stockActual.component.html',
  styleUrls: ['./stockActual.component.scss']
})
export class StockActualComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
