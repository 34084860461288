import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AjusteProducto } from "@app/_models/ajusteProducto/ajusteProducto";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";



@Injectable({
    providedIn: 'root'
})

export class AjusteProductoService{

    constructor(private snackBar: MatSnackBar, private http: HttpClient){

    }

    createAjusteProducto(ajusteProducto: AjusteProducto){
        console.log(ajusteProducto)
        return this.http.post(`${environment.apiUrl}/ajusteProducto/crear/`,ajusteProducto);
    }

    ajusteProductoGetId(id: number) {
        return this.http.get<AjusteProducto>(`${environment.apiUrl}/ajusteProducto/${id}`);
      }

    ajusteProductoGet(): any {
        return this.http.get<[]>(`${environment.apiUrl}/ajusteProducto/`);
    }

    traerAjustes(ide: number):any{
        return this.http.get(`${environment.apiUrl}/ajusteProducto/porIde/${ide}`)
    }

    traerNumeroProductos(id: number):any{
        return this.http.get(`${environment.apiUrl}/ajusteProducto/numeroProductos/${id}`)
    }

    getStock(idProducto: number, idBodega: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/ajusteProducto/stock/${idProducto}/${idBodega}`);
      }

}