import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TransferenciaBodega } from '@app/_models';
import { first, delay } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdministracionService } from '@app/_pages/administracion/administracion.service';
import { TransferenciasService } from '../transferencias.service';

@Component({
  selector: 'app-transferencia-bodega-list',
  templateUrl: './transferencia-bodega-list.component.html',
  styleUrls: ['./transferencia-bodega-list.component.scss']
  
})
export class TransferenciaBodegaListComponent implements OnInit {

  public transferencias : TransferenciaBodega[] = [];

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()

  displayedColumns: string[] = [
      'select',
      'id',
      "fecha_transferencia",
      "origen",
      "destino",
      "usuario",
      "guias_asociadas",
      "botones"
  ]

  // Tabla en donde se almacenará los datos de la bd
  dataSource: MatTableDataSource<TransferenciaBodega> = new MatTableDataSource();

  formFilter = new FormGroup({
    direccion: new FormControl(),
    nombre: new FormControl(),
  })

  nombreEmpresa!: string;
  selection = new SelectionModel<TransferenciaBodega>(true, []);
  totalSeleccion = 0;
  selectedRows!: any[];
  sucursalesDelete!: any;

  constructor(
    private administracionService: AdministracionService,
    private transferenciaService: TransferenciasService,
    private snackBar: MatSnackBar
  ) { }

    ngOnInit(): void {
        // this.aplicarfiltros();
        this.getTransferencias();
    }
  
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    getTransferencias(){
        this.transferenciaService.getAllTransferencias()
        .subscribe( res => {
          this.transferencias = res;
          console.log("tablaaa",this.transferencias)
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.paginator = this.paginator.toArray()[0];
        })
    }
  
    // Metodo que sirve para la seleccion de un campo de la tabla
    masterToggle() {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.filteredData.forEach((row) => {
            this.selection.select(row)
          });
    }
    
    estado(filtro:string){
      let estadoBodegas = this.transferencias;
      estadoBodegas = this.transferencias.filter((data: TransferenciaBodega) => data.estado == filtro)
      this.dataSource = new MatTableDataSource(estadoBodegas);
      this.dataSource.paginator = this.paginator.toArray()[0];
    }
  
    //Limpiar los filtros
    limpiarFiltros() {
      this.formFilter.patchValue({nombre: null,direccion: null});
      this.dataSource = new MatTableDataSource(this.transferencias);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.selection.clear();
      this.totalSeleccion = 0;
    }
  
    //Metodo exportar excel
    exportAsXLSX(): void {
      this.selectedRows = [];
      this.selection.selected.forEach((x) => this.selectedRows.push(x));
      this.administracionService.exportAsExcelFile(this.selectedRows,'Transferencias entre bodegas');
    }
  
    //Abrir Modal Editar, se inserta id en localstorage
    openDialogEdit( idTransferencia:any ) {
      localStorage.setItem('idTransferencia', idTransferencia);
      this.transferenciaService.openDialogVerTransferencia();
    }

    openDialogGuia(idTransferencia:any){
      localStorage.setItem('idTransferencia', idTransferencia);
      this.transferenciaService.openDialogGuia();
    }
  
    //Metodo eliminar una sucursal
    delete(id: any, transferecia: any) {
      console.log(id)
      if (confirm('Esta seguro que desea eliminar el registro: ' + transferecia)) {
        this.transferenciaService
            .delete(id)
            .pipe(first())
            .subscribe(() => {
                this.getTransferencias();
            });
        this.snackBar.open('transferencia eliminada', 'cerrar', {
            duration: 3000,
            verticalPosition: 'top'
        });
      }
    }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
