import { Component, OnInit, ViewChildren, QueryList, ViewChild,TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EgresoRentacarCuota } from '@app/_models/rentacar/egresoRentacarCuota';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { RentacarService } from '@app/_pages/rentacar/rentacar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog,MatDialogRef } from '@angular/material/dialog';
import { AlertHelper } from '@app/_helpers/alert.helper';
import { DialogShow } from '@app/_components/dialogs/dialog-downloads/dialog-downloads.component';
import { DialogRespaldosComponent } from 'src/app/_components/dialogs/dialog-respaldos/dialog-respaldos.component';
import { DomSanitizer } from '@angular/platform-browser'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'

export interface ResWebPay{
  token:string;
  url:string;
}
@Component({
  selector: 'app-rentacar-egresos-cuotas',
  templateUrl: './rentacar-egresos-cuotas.component.html',
  styleUrls: ['./rentacar-egresos-cuotas.component.scss']
})
export class RentacarEgresosCuotasComponent implements OnInit {
  @ViewChild('modalContent') modalContent!: TemplateRef<any>;

  // ? childrens
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild(MatSort) sort = null;
  // ? table definitions.
  displayedColumns: string[] = [
    'select',
    'id',
    'fecha',
    'monto',    
    'estadoCuota',
    'numeroCuota',
    'respaldos',
    'acciones'
  ];
  dataSource: MatTableDataSource<EgresoRentacarCuota> = new MatTableDataSource();  
  dataCuota: EgresoRentacarCuota[] = [];
  dialogRef2 = this.dialog.getDialogById('modalContent')
  idEgreso = localStorage.getItem('idEgresoPago')
  empresa = 'rentacar'
  descripcion: string;
  idCuota : any;
  cuota: any;
  monto: any; 
  estadoCuota: any;
  token:string;
  url:string;
  clickButton = true
  dataArrayImg = []
  public archivos: any
  public previsualizacion: string
  public loading: boolean
  hasBaseDropZoneOver: boolean
  hasAnotherDropZoneOver: boolean
  // Variables que ayudan a aplicar los filtros
  formFilter = new FormGroup({    
    start: new FormControl(),    
    end: new FormControl(),
    estadoCuota: new FormControl(),
    numeroCuota: new FormControl(),
  })
  nombreArchivo : any
  selection = new SelectionModel<any>(true, []);
  totalSeleccion = 0;
  selectedRows!: any[];
  
  constructor(
    private fb: FormBuilder,
    private rentacarService: RentacarService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private alert: AlertHelper,
    private sanitizer: DomSanitizer,
    private http: HttpClient,

  ) { 
    
  }

  ngOnInit(): void {
    this.obtenerCuotas();
    this.aplicarfiltros();
  }

  pagar(){
    this.selectedRows = [];
    this.selection.selected.forEach((x) => this.selectedRows.push(x));    
    if(this.selectedRows.length > 0){
      this.selectedRows.forEach((x) => {                                       
      this.estadoCuota = x.estadoCuota;        
    });
        
    if(this.estadoCuota == "Pagado"){
      this.snackBar.open('Este egreso ya fue pagado', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top',          
      });      
    }else{
      //Esto abre un dialog que permite subir un archivo
      const dialogRef = this.dialog.open(DialogRespaldosComponent, {
        data: { url: 'respaldoEgresoRentacarCuota/upload' }
      })
      //Despues de subir el archivo se ejecuta esto
      dialogRef.afterClosed().subscribe(result => {     
        //Se declaran las variables que se usaran para subir el respaldo
        let respaldo = {};
        const arrayRespaldos: {}[] = [];
        const respuesta = result;
        let idCuota: string;
        let cuota: any;      
    
        //Se captura la id de la fila seleccionada en la tabla
        //this.selectedRows = [];
        this.selection.selected.forEach((x) => this.selectedRows.push(x));      
        this.selectedRows.forEach((x) => {        
          idCuota = x.id;
          localStorage.setItem("idCuota", idCuota)
          cuota = x;
          this.monto = x.monto;
          this.estadoCuota = x.estadoCuota;        
        });

        this.rentacarService.pagar(Number(localStorage.getItem("idCuota")),this.monto, this.empresa).subscribe(
          (response:ResWebPay) => {
              const link = `${response.url}?token_ws=${response.token}`;
              console.log("response", response)
              console.log("link", link)
              window.location.href = link;
            }, error =>{
              console.error("Error en la solicitud", error)
            })


        //Se guardan los datos en las variables creada
        respuesta.forEach((resp: any) => {
          respaldo = { idEgresoCuota: idCuota, url: resp, id: this.idEgreso, monto: this.monto, token: this.token };        
          arrayRespaldos.push(respaldo);        
        });
          
    
        //Se le agrega el respaldo al pago seleccionado
        this.rentacarService.agregarRespaldos(arrayRespaldos).subscribe(
          (data: any) => {         
            //this.pagarCuota(idCuota, cuota);
            this.rentacarService.closeDialogModal();
            this.alert.createAlert("Registro de pago Creado con exito!");                  
          },
          (error: any) => {
            console.log(error);
          }
        );    
        });
      }
  }}
  obtenerCuotas(){
    this.rentacarService.getCuotas(this.idEgreso)
    .pipe()
    .subscribe((x:any) => {
      x.map(res=>{
        this.descripcion = res.descripcion
      })
      this.dataSource = new MatTableDataSource(x);
      this.dataSource.sort
      this.dataSource.paginator = this.paginator.toArray()[0];      
      this.dataCuota = x;
    })
  }

  //Metodo que permite procesar pago
  procesarPago(){
    this.selectedRows = [];
    this.selection.selected.forEach((x) => this.selectedRows.push(x));    
    if(this.selectedRows.length > 0){
      this.selectedRows.forEach((x) => {                                        
        this.estadoCuota = x.estadoCuota;        
      });
    
      if(this.estadoCuota == "Pagado"){
        this.snackBar.open('Este egreso ya fue pagado', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',          
        });      
      }else{

    //Esto abre un dialog que permite subir un archivo

     this.dialogRef2 = this.dialog.open(this.modalContent,
      {
        width: '700px',
        height: '500px'

      });
     
      
    // const dialogRef = this.dialog.open(DialogRespaldosComponent, {
    //    data: { url: 'egresoRentacarCuota/upload/RentacarCuota' }
    //  }); 


    //Despues de subir el archivo se ejecuta esto
    

    
    this.dialogRef2.afterClosed().subscribe(result => {     
      if(this.archivos !== undefined){
        
      //Se declaran las variables que se usaran para subir el respaldo
      let respaldo = {};
      const arrayRespaldos: {}[] = [];
      const respuesta = result;
      let idCuota: string;
      let cuota: any;      

      //Se captura la id de la fila seleccionada en la tabla
      this.selectedRows = [];
     

      this.selection.selected.forEach((x) => this.selectedRows.push(x));      

      this.selectedRows.forEach((x) => {        
        idCuota = x.id;
        cuota = x;
        this.monto = x.monto;
        this.estadoCuota = x.estadoCuota;   

       });
  
      

      //Se guardan los datos en las variables creada
      this.selection.selected.forEach((resp: any) => {
        respaldo = { idEgreso: resp.idEgreso ,idEgresoCuota : resp.id, monto:resp.monto, url:this.nombreArchivo?.uploadedFileName}; 
        console.log(respaldo)       
        arrayRespaldos.push(respaldo);  
        console.log("arrayRespaldos" + arrayRespaldos)      
      });

      //Se le agrega el respaldo al pago seleccionado
      this.rentacarService.agregarRespaldos(arrayRespaldos).subscribe(
        (data: any) => {         
          //this.pagarCuota(idCuota, cuota);
          this.rentacarService.closeDialogModal();
          this.alert.createAlert("Registro de pago Creado con exito!");  
          setTimeout(function(){
            window.location.reload()
          },1000);
                       
        },
        (error: any) => {
          console.log(error);
        }
      );    
      }
    });
  
  }
    } else {
      this.snackBar.open('Seleccione una cuota', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top',        
      });
    }
      
  }
   //Extraer la img a base 64 para poder visualizar
   extraerBase64 = async ($event: any) =>
   new Promise((resolve, reject) => {
     try {
       const unsafeImg = window.URL.createObjectURL($event)
       const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
       const reader = new FileReader()
       reader.readAsDataURL($event)
       reader.onload = () => {
         resolve({
           base: reader.result
         })
       }
       reader.onerror = (error) => {
         resolve({
           base: null
         })
       }
     } catch (e) {
       return null
     }
   })

 //Capturar imagen
 capturarFile(event) {
   this.archivos = []
   this.dataArrayImg = []
   const archivoCapturado = event.target.files[0]
   const extension = archivoCapturado.name.split('.').pop() // Obtener la extensión del archivo original
   const nuevoNombre = 'EgresosRentacar' + Date.now() + '.' + extension // Crear un nombre único con el identificador generado dinámicamente
   this.dataArrayImg.push(nuevoNombre) // Agregar el nuevo nombre al array de nombres de archivo
   const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
     type: archivoCapturado.type
   }) // Crear un nuevo objeto de tipo File con el nuevo nombre
   this.extraerBase64(archivoNuevo).then((imagen: any) => {
     this.previsualizacion = imagen.base
   })
   this.archivos.push(archivoNuevo)
   this.clickButton = false
 }

 subirIMG() {
  

   try {
     this.loading = true
     const formularioDeDatos = new FormData()
     this.archivos.forEach((archivo) => {
       formularioDeDatos.append('File', archivo)
     })
     this.http
       .post(
         `${environment.apiUrl}/egresoRentacarCuota/Upload/RentacarCuota`,
         formularioDeDatos
       )
       .subscribe((res) => {
         this.nombreArchivo = res
      
         this.loading = false
         this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
           duration: 2000,
           verticalPosition: 'top'
         })

       })
       if (this.dialogRef2) {
        this.dialogRef2.close();
      }
   } catch (e) {
     this.loading = false
     this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
       duration: 2000,
       verticalPosition: 'top'
     })
     console.log('ERROR AL SUBIR IMG', e)
   }
 }
    
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  recuperarArchivos(archivos: any) {     
    if(archivos.estadoCuota == "Pendiente"){
      this.snackBar.open('Este egreso aun no ha sido registrado. Por favor registre el pago', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top',          
      });
    } else {            
      this.rentacarService.buscarImagenC(archivos.id).subscribe(
        (dataImagen: any) => {                  
          setTimeout(() => {
            this.dialog.open(DialogShow, {
              data: { archivos: dataImagen, servicio: 'rentacar-egreso-cuota' },
            });
          }, 1000);       
        },
        (error: any) => {
          console.log(error);
        }
      );
    }          
              
  }
  
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => {
        this.selection.select(row);
  
      });
    console.log(this.selection.selected);
  }

  edit(id: any, monto: any, cuota: any) {
    localStorage.setItem('idEgresoPago', id);
    localStorage.setItem('montoEgreso', monto);
    if(cuota == "Pendiente"){
      this.rentacarService.openDialogCuota();
    }else {    
    this.snackBar.open('El pago de esta cuota ya fue registrado', 'cerrar', {
      duration: 2000,
      verticalPosition: 'top',          
    });
  }
  }

  aplicarfiltros() {
    this.formFilter.valueChanges.subscribe(res => {
      
      let dataFiltered = this.dataCuota;      
      
      //Filtro Fecha
      if (res.start && res.end) {
        dataFiltered = dataFiltered.filter((data: any) => new Date(data.fecha) >= res.start && new Date(data.fecha) <= res.end);
      }
      
      //Filtro Estado Pago
      if (res.estadoPago) {
        dataFiltered = dataFiltered.filter((data: any) => data.estado == res.estadoPago);
      }
            

      this.dataSource = new MatTableDataSource(dataFiltered);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.totalSeleccion = 0;
      this.selection.clear();
    })
  }

  limpiarFiltros() {
    this.formFilter.patchValue({ start: null, end: null, estadoPago: null})
    this.dataSource = new MatTableDataSource(this.dataCuota);
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.selection.clear()
    this.totalSeleccion = 0;
  }

   // Cerrar Dialog
   closeDialog(){
    this.rentacarService.closeDialogModal();
   }
  
}
