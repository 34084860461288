export class envioSobre{
    userCert: string
    passCert: string
    folio: number
    rutEmisor: string
    rutReceptor: string
    fechaResolucion: string
    numeroResolucion: number
    vendedor: string
    fechaCreacion: number
    
  }