<mat-toolbar color="primary">
  <button style="margin-right: 9rem" mat-raised-button color="accent" (click)="limpiarFiltros()">
    Limpiar filtros
  </button>

  <span style="margin-right: 9rem; margin-left: 9rem"> Listado Productos/Servicios </span>
  <!-- ***************************** cambiar metodo botón ********************************** -->
  <button mat-raised-button color="accent" style="margin-left: 6rem" (click)="exportAsXLSX()">
    Exportar
  </button>
  <button mat-raised-button color="accent" style="margin-left: 1rem" onclick="location.reload()">
    <mat-icon>autorenew</mat-icon>
  </button>
</mat-toolbar>

<div class="table-responsive">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">

    <!-- check column -->

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="img">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Imagen" formControlName="img" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.img !== null">
          <img src="http://179.57.172.224:4141/verproductos/{{element.img}}" alt="">
          <!-- <p>{{element.img}}</p> -->
        </div>
        <div *ngIf="element.img === null">
          <img
            src="../../../../assets/images/ningún-icono-disponible-de-la-imagen-plano-ejemplo-del-vector-132484366.jpeg"
            alt="">
        </div>
      </td>
    </ng-container>

    <!-- data columns -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID </th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Nombres -->
    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Tipo" formControlName="tipo" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="col" *ngIf="element.tipo == 'Producto'">
          <button mat-button matTooltip="Producto" style="background-color: #707070; color: white;">
            PR
          </button>
        </div>
        <div class="col" *ngIf="element.tipo == 'Servicio'">
          <button mat-button matTooltip="Servicio" style="background-color: #707070; color: white;">
            SE
          </button>
        </div>
      </td>
    </ng-container>

    <!-- Nombres -->
    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Nombre" formControlName="nombre" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.nombre }} <br>
        <div style="color: #707070" class="bottom">{{element.codigoSKU}}</div>
      </td>

    </ng-container>

    <!-- Variante -->
    <ng-container matColumnDef="variante">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Variante" formControlName="variante" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="col">
          <button (click)="openDialogVariante(element.id)" mat-button style="background-color: #707070; color: white;">
            {{element.consultaVariantes}}
          </button>
        </div>
      </td>

    </ng-container>

    <!-- Categoria -->
    <ng-container matColumnDef="categoria">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <mat-select placeholder="Categoria" formControlName="categoria">
              <mat-option *ngFor="let categoria of categorias" [value]="categoria.nombre">
                {{ categoria.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.nombreCategoria }}</td>
    </ng-container>

    <!-- Precio Neto -->
    <ng-container matColumnDef="neto">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Precio Neto" formControlName="neto" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.neto | currency:'CLP':'symbol'}} </td>>
    </ng-container>

    <!-- Precio Venta -->
    <ng-container matColumnDef="venta">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Precio Venta" formControlName="venta" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.venta | currency:'CLP':'symbol' }} <br>{{ element.afecto ? 'AFECTO' : 'EXCENTO' }}
      </td>


    </ng-container>

    <!-- Estado -->
    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Estado" formControlName="estado" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="col" *ngIf="element.estado == 'Vigente'">
          <button mat-raised-button style="background-color: #087f23; color: white;">
            {{element.estado}}
          </button>
        </div>
        <div class="col" *ngIf="element.estado == 'No Vigente'">
          <button mat-raised-button style="background-color: #ac0800; color: white;">
            {{element.estado}}
          </button>
        </div>
      </td>




    </ng-container>


    <!-- Botones -->
    <ng-container matColumnDef="botones">
      <th mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let element">

        <div class="col">
          <div ngbDropdown container="body">
            <button mat-button id="gfg" style="background-color: #707070; color: white;"
              ngbDropdownToggle>Opciones</button>
            <div ngbDropdownMenu="gfg">
              <button (click)="openDialogEditar(element.id, element.nombre)"
                ngbDropdownItem><mat-icon>edit_black_18dp</mat-icon>Editar</button>
              <button (click)="openDialogFoto(element.id)"
                ngbDropdownItem><mat-icon>upload_file_black_18dp</mat-icon>Cargar Imagen</button>
              <button (click)="borrarProducto(element.id, element.nombre)"
                ngbDropdownItem><mat-icon>delete_black_18dp</mat-icon> Borrar</button>
            </div>
          </div>
        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No existen coincidencias</td>
    </tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>
