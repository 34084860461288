import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'
import { IngresosImportadora } from '@app/_models/importadora/ingresoImportadora'
import { EgresosFijoImportadora } from '@app/_models/importadora/egresoFijoImportadora'
import { EgresoFijoImportadoraCuota } from '@app/_models/importadora/egresoFijoImportadoraCuota'
import { EgresosContainerImportadora } from '../../_models/importadora/egresoContainerImportadora'
import { environment } from '@environments/environment'
import {GastosFijos} from '@app/_models/gastosFijos/gastosFijos'
import { ImportadoraGastosFijosEditComponent} from './importadora-gastos-fijos/importadora-gastos-fijos-edit/importadora-gastos-fijos-edit.component'
import { DialogNeumaticosComponent } from './importadora-egresos/importadora-egresos-tab-gasto-neumaticos/dialog-neumaticos/dialog-neumaticos.component'
import { DialogNeumaticosEditComponent } from './importadora-egresos/importadora-egresos-tab-gasto-neumaticos/dialog-neumaticos/dialog-neumaticos-edit/dialog-neumaticos-edit/dialog-neumaticos-edit.component'

/* Imports Excel */
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ImportadoraEgresosTabCuotasComponent } from './importadora-egresos/importadora-egresos-tab-gasto-fijo/importadora-egresos-tab-gasto-fijo-list/importadora-egresos-tab-cuotas/importadora-egresos-tab-cuotas.component'
import { ImportadoraEgresosTabCuotaDialogComponent } from './importadora-egresos/importadora-egresos-tab-gasto-fijo/importadora-egresos-tab-gasto-fijo-list/importadora-egresos-tab-cuotas/importadora-egresos-tab-cuota-dialog/importadora-egresos-tab-cuota-dialog.component'
import { ConsolidadosImportadora } from '@app/_models/importadora/consolidadosImportadora'
import { ImportadoraEgresosEditComponent } from './importadora-egresos/importadora-egresos-tab-gasto-fijo/importadora-egresos-tab-gasto-fijo-list/importadora-egresos-edit/importadora-egresos-edit.component'
import { AccionesIngresoImportadoraComponent } from './importadora-ingresos/importadora-ingreso-table/acciones-ingreso-importadora/acciones-ingreso-importadora.component'
import { EgresosNeumaticoImportadora } from '@app/_models/importadora/egresoNeumaticoImportadora'
import { AccionesEgresoNeumaticoComponent } from './importadora-egresos/importadora-egresos-neumatico/importadora-egresos-neumatico-list/acciones-egreso-neumatico/acciones-egreso-neumatico.component'
import { AccionesEgresosFijosImportadoraComponent } from './importadora-egresos/importadora-egresos-fijos/importadora-egresos-table/acciones-egresos-fijos-importadora/acciones-egresos-fijos-importadora.component'
import { UsuarioSharedService } from '../shared/shared-services/usuario-shared.service'
import { SucursalSharedService } from '../shared/shared-services/sucursal-shared.service'
import { Usuario } from '@models/shared/usuario';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'
/* Fin Import Excel */

@Injectable({
  providedIn: 'root'
})
export class ImportadoraService {
  private empresa = 'Importadora'

  constructor(
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioSharedService,
    private sucursalService: SucursalSharedService
  ) {}

  //*********** Inicio Metodos Ingresos ************/

  create(ingresosImportadora: IngresosImportadora) {
    return this.http.post(
      `${environment.apiUrl}/ingresoImportadora/conRespaldo`,
      ingresosImportadora
    )
  }

  getAll() {
    return this.http.get<[]>(`${environment.apiUrl}/ingresoImportadora`)
  }

  ingresoGetFiles(fileName: string) {
    const extencion = fileName.split('.')
    const extend = extencion[1]
    return this.http
      .get(
        `${environment.apiUrl}/ingreso${this.empresa}/download/${fileName}`,
        {
          responseType: 'blob'
        }
      )
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }
  buscarImagen(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/ingreso${this.empresa}/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  getAllWithUsuario() {
    return this.http.get<IngresosImportadora[]>(
      `${environment.apiUrl}/ingresosImportadora/conUsuario`
    )
  }

  getById(id: string) {
    return this.http.get<IngresosImportadora>(
      `${environment.apiUrl}/ingresoImportadora/${id}`
    )
  }

  // update(id: any, params: any) {
  //   return this.http.put(
  //     `${environment.apiUrl}/ingresosImportadora/${id}`,
  //     params
  //   )
  // }
  deleteIngreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/ingresoImportadora/${id}`)
  }

  //*********** Fin Metodos Ingresos ************/

  //*********** Inicio Metodos Egresos Fijos ************/

  getByEgreso(id: string): any {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoFijoImportadora/${id}`
    )
  }
  /** metodo actualizar datos editados de una inmobiliaria */
  updateImportadoraEgreso(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egresoFijoImportadora/${id}`,
      params
    )
  }

  getAllEgresosFijo() {
    return this.http.get<[]>(`${environment.apiUrl}/egresoFijoImportadora`)
  }

  createEgresosFijo(egresoFijoImportadora: EgresosFijoImportadora) {
    return this.http.post(
      `${environment.apiUrl}/egresoFijoImportadora/conRespaldo`,
      egresoFijoImportadora
    )
  }

  egresoFijoGetFiles(fileName: string): any {
    return this.http
      .get(
        `${environment.apiUrl}/egresoFijo${this.empresa}/download/${fileName}`,
        {
          responseType: 'blob'
        }
      )
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }
  buscarImagenEgreso(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoFijo${this.empresa}/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }
  //*********** Fin Metodos Egresos Fijos ************/

  //*********** Inicio Metodos Egresos Conteiner ************/
  createEgresosConteiner(
    egresoConteinerImportadora: EgresosContainerImportadora
  ) {
    return this.http.post(
      `${environment.apiUrl}/EgresoContainerImportadora/conRespaldo`,
      egresoConteinerImportadora
    )
  }

  //*********** Inicio Metodos Egresos Neumaticos ************/
  guardarNeumaticos(neumaticos: any): any {
    return this.http.post<[]>(
      `${environment.apiUrl}/EgresoNeumaticoImportadora/neumaticos`,
      neumaticos
    )
  }

  updateNeumaticos(id: any, body: any[]) {
    return this.http.put(
      `${environment.apiUrl}/EgresoNeumaticoImportadora/${id}`,
      body
    )
  }

  buscarConsolidado(consolidado: ConsolidadosImportadora): any {
    return this.http.post(
      `${environment.apiUrl}/ingreso${this.empresa}/ingresosEgresos`,
      consolidado
    )
  }

  getAllNeumaticos() {
    return this.http.get<[]>(`${environment.apiUrl}/EgresoNeumaticoImportadora`)
  }

  getNeumaticosById(id: number) {
    return this.http.get<[]>(
      `${environment.apiUrl}/EgresoNeumaticoImportadora/conteinerNumero/${'1'}`
    )
  }

  // Cerrar dialog Repactar Cuota y Registrar Pago
  closeDialogModal() {
    this.dialog.closeAll()
  }

  //*********** fin Metodos Egresos Neumaticos ************/

  egresoConteinerGetFiles(fileName: string): any {
    return this.http
      .get(
        `${environment.apiUrl}/egresoContainer${this.empresa}/download/${fileName}`,
        {
          responseType: 'blob'
        }
      )
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }
  buscarImagenEgresoC(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoContainer${this.empresa}/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  getAllEgresosConteiner() {
    return this.http.get<[]>(`${environment.apiUrl}/EgresoContainerImportadora`)
  }

  getConteinerById(id: number) {
    return this.http.get<[]>(
      `${environment.apiUrl}/EgresoContainerImportadora/conteinerNumero/${id}`
    )
  }

  getConteinerByIdN(id: number) {
    return this.http.get<EgresosContainerImportadora>(
      `${environment.apiUrl}/EgresoContainerImportadora/conteinerNumero/${id}`
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogNeumatico(idContrato: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idContrato != null) {
      const dialogRef = this.dialog.open(DialogNeumaticosComponent, {})
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open('Por favor seleccione un conteiner', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }
  //***************** Egresos por cuotas ********************//

  getCuotas(id: any) {
    return this.http.get<EgresoFijoImportadoraCuota>(
      `${environment.apiUrl}/egresoFijoImportadoraCuota/${id}`
    )
  }

  buscarImagenCuota(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoFijoImportadoraCuota/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  agregarRespaldos(arrayRespaldos: any): any {
    return this.http.post(
      `${environment.apiUrl}/egresoFijoImportadoraCuota/agregarRespaldos/`,
      arrayRespaldos
    )
  }
  buscarImagenC(id: any): any {
    return this.http.get<EgresoFijoImportadoraCuota>(
      `${environment.apiUrl}/respaldoEgresoFijoImportadoraCuota/${id}`
    )
  }

  openDialogCuota(): void {
    const dialogRef = this.dialog.open(
      ImportadoraEgresosTabCuotaDialogComponent,
      {}
    )
    dialogRef.afterClosed().subscribe((res) => {})
  }

  updateMonto(id: any, body: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egresoFijoImportadoraCuota/${id}`,
      body
    )
  }

  openDialogRegistrarPago(idEgreso: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idEgreso != null) {
      const dialogRef = this.dialog.open(
        ImportadoraEgresosTabCuotasComponent,
        {}
      )
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open(
        'Por favor seleccione un egreso con cuotas sin pagar',
        'cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }

  //*********** Fin Metodos Egresos Conteiner ************/

  /* Metodo Excel */
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    )
  }

  public async exportAsExcelFileNuevo(
    json: any[],
    excelFileName: string,
    selectedColumns: string[]
  ): Promise<void> {
    const columMapings = {
      id: 'ID',
      fecha: 'Fecha',
      monto: 'Monto',
      codigoAutorizacion: 'Codigo Autorizacion',
      medioPago: 'Tipo Pago',
      nombreCliente: 'Cliente',
      descripcionIngreso: 'Descripcion',
      tipoIngreso: 'Tipo Ingreso',
      Referencia: 'Referencia',
      idSucursal: 'Sucursal'
    }

    const newData = await Promise.all(
      json.map(async (item) => {
        const newItem: any = {}
        for (const col of selectedColumns) {
          if (col === 'idSucursal') {
            if (item[col] !== null) {
              try {
                const sucursal = await this.sucursalService
                  .getById(item[col])
                  .toPromise()

                newItem[columMapings[col]] = sucursal.razonSocial
              } catch (error) {
                console.error(
                  'Error al obtener el nombre de la sucursal:',
                  error
                )
                newItem[columMapings[col]] = ''
              }
            } else {
              newItem[columMapings[col]] = ''
            }
          } else if (col === 'idUsuario') {
            if (item[col] !== null) {
              try {
                const usuario = await this.usuarioService
                  .getById(item[col])
                  .toPromise()
                newItem[columMapings[col]] = usuario.nombre
              } catch (error) {
                console.error('Error al obtener el nombre del usuario:', error)
                newItem[columMapings[col]] = ''
              }
            } else {
              newItem[columMapings[col]] = ''
            }
          } else if (col === 'fecha') {
            if (item[col] !== null) {
              try {
                const fecha = new Date(item[col])
                const formattedFecha = `${fecha.getDate()}/${
                  fecha.getMonth() + 1
                }/${fecha.getFullYear()}`
                newItem[columMapings[col]] = formattedFecha
              } catch (error) {
                console.error('Error al obtener la fecha:', error)
                newItem[columMapings[col]] = ''
              }
            } else {
              newItem[columMapings[col]] = ''
            }
          } else {
            newItem[columMapings[col]] = item[col]
          }
        }
        return newItem
      })
    )

    console.log(newData)

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }
  public async exportAsExcelFileImportadoraEgreso(
    json: any[],
    excelFileName: string,
    selectedColumns: string[]
  ): Promise<void> {
    const columMapings = {
      id: 'ID',
      fecha: 'Fecha',
      tipoEgreso: 'Tipo Egreso',
      tipoPago: 'Tipo Pago',
      monto: 'Monto',
      numeroCuota: 'N° Cuota',
      descripcion: 'Descripcion',
      idSucursal: 'Sucursal',
      responsable: 'Responsable',
    }

    const newData = await Promise.all(
      json.map(async (item) => {
        const newItem: any = {}
        for (const col of selectedColumns) {
          if (col === 'idSucursal') {
            if (item[col] !== null) {
              try {
                const sucursal = await this.sucursalService
                  .getById(item[col])
                  .toPromise()

                newItem[columMapings[col]] = sucursal.razonSocial
              } catch (error) {
                console.error(
                  'Error al obtener el nombre de la sucursal:',
                  error
                )
                newItem[columMapings[col]] = ''
              }
            } else {
              newItem[columMapings[col]] = ''
            }
          } else if (col === 'idUsuario') {
            if (item[col] !== null) {
              try {
                const usuario = await this.usuarioService
                  .getById(item[col])
                  .toPromise()
                newItem[columMapings[col]] = usuario.nombre
              } catch (error) {
                console.error('Error al obtener el nombre del usuario:', error)
                newItem[columMapings[col]] = ''
              }
            } else {
              newItem[columMapings[col]] = ''
            }
          } else if (col === 'fecha') {
            if (item[col] !== null) {
              try {
                const fecha = new Date(item[col])
                const formattedFecha = `${fecha.getDate()}/${
                  fecha.getMonth() + 1
                }/${fecha.getFullYear()}`
                newItem[columMapings[col]] = formattedFecha
              } catch (error) {
                console.error('Error al obtener la fecha:', error)
                newItem[columMapings[col]] = ''
              }
            } else {
              newItem[columMapings[col]] = ''
            }
          } else {
            newItem[columMapings[col]] = item[col]
          }
        }
        return newItem
      })
    )

    console.log(newData)

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData)

    const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: C });
      // Limpiar cualquier estilo existente
      worksheet[headerCell].s = {};
      // Aplicar nuevo estilo
      worksheet[headerCell].s = { fill: { fgColor: { rgb: 'FFFF00' } }, font: { bold: true } };
    }
    
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
    
    // const workbook: XLSX.WorkBook = {
    //   Sheets: { data: worksheet },
    //   SheetNames: ['data']
    // }
    // const excelBuffer: any = XLSX.write(workbook, {
    //   bookType: 'xlsx',
    //   type: 'array'
    // })
    // this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditContainer(): void {
    const dialogRef = this.dialog.open(DialogNeumaticosEditComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditContainerN(idContrato: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idContrato != null) {
      const dialogRef = this.dialog.open(DialogNeumaticosEditComponent, {})
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open('Por favor seleccione un conteiner', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }

  //Metodos del Home
  buscarCuotas(): any {
    return this.http.get<EgresoFijoImportadoraCuota>(
      `${environment.apiUrl}/egresoFijoImportadoraCuota/`
    )
  }

  // Metodo que permite abrir un Dialog (Modal)-->editar
  openDialogEditEgreso(): void {
    const dialogRef = this.dialog.open(ImportadoraEgresosEditComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  deleteEgreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/egresoFijoImportadora/${id}`)
  }

  //Crear ingreso
  IngresosImportadora(modelo: IngresosImportadora): any {
    return this.http.post(
      `${environment.apiUrl}/ingresoImportadora/postForm`,
      modelo
    )
  }

  //Llamar tabla de Facturas
  getAllIngresosImportadora(): any {
    return this.http.get<[]>(`${environment.apiUrl}/ingresoImportadora`)
  }

  getByIdIngresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/ingresoImportadora/sinBase64/${id}`
    )
  }

  //Editar cliente
  updateIngresoImportadora(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/ingresoImportadora/${id}`,
      params
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditIngresoImportadora(): void {
    const dialogRef = this.dialog.open(AccionesIngresoImportadoraComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  //Egreso Importadora

  //Crear egreso
  CreateEgresoImportadora(modelo: EgresosContainerImportadora): any {
    return this.http.post(
      `${environment.apiUrl}/egresoContainerImportadora/form`,
      modelo
    )
  }

  //Editar cliente
  updateEgresoImportadoraNeumatico(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egresoContainerImportadora/UpdateForm/${id}`,
      params
    )
  }

  getByIdEgresoNeumaticoImportadora(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoContainerImportadora/oneGetEgresoImportadoraNeumatico/${id}`
    )
  }
  getByIdEgresosNuematicoImportadoraSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoContainerImportadora/sinBase64/${id}`
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditEgresoNeumaticoImportadora(): void {
    const dialogRef = this.dialog.open(AccionesEgresoNeumaticoComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  //Egresos fijos SH
  //Crear egreso
  CreateEgresoImportadoraFijo(modelo: EgresosFijoImportadora): any {
    return this.http.post(
      `${environment.apiUrl}/egresoFijoImportadora/form`,
      modelo
    )
  }
  //Editar el egreso fijo
  updateEgresoFijoImportadora(id: any, params: any[]) {
    console.log(params)
    return this.http.put(
      `${environment.apiUrl}/egresoFijoImportadora/UpdateForm/${id}`,
      params
    )
  }
  //Img sin base 64 y  el formulario
  getByIdEgresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoFijoImportadora/sinBase64/${id}`
    )
  }
  //Get de todo los datos
  getAllEgresosFijosImportadora() {
    return this.http.get<[]>(`${environment.apiUrl}/egresoFijoImportadora`)
  }
  getByIdEgresoFijosImportadora(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoFijoImportadora/oneGetEgresoFijoImportadora/${id}`
    )
  }
  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditEgresoFijoImportadora(): void {
    const dialogRef = this.dialog.open(
      AccionesEgresosFijosImportadoraComponent,
      {}
    )
    dialogRef.afterClosed().subscribe((res) => {})
  }

  createGastoFijos(gastos: GastosFijos): any {
    console.log('ruta', gastos)
    return this.http.post(`${environment.apiUrl}/gastosFijos`, gastos)
  }

  getGastoFijos(): Observable<GastosFijos[]> {
    return this.http.get<GastosFijos[]>(`${environment.apiUrl}/gastosFijos`)
  }

  openDialogGastoFijo(detallesGasto: any) {
    const dialogRef = this.dialog.open(ImportadoraGastosFijosEditComponent, {
      width: '1000px',
      height: '500px',
      data: detallesGasto
    })

    dialogRef.afterClosed().subscribe((result) => {})
  }
  getByIdGastoFijo(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/gastosFijos/getIDSinBase64/${id}`
    )
  }
  getByIdGastoFijoImagen(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/gastosFijos/oneGetGastoFijos/${id}`
    )
  }
  updateGastoFijos(id: any, data: any) {
    return this.http.put(
      `${environment.apiUrl}/gastosFijos/updateGastoFijos/${id}`,
      data
    )
  }

  createCredito(egresoImportadora: EgresosFijoImportadora): any {
    try {
      return this.http.post(
        `${environment.apiUrl}/egresoFijoImportadora/createEgresoHome/`,
        egresoImportadora
      )
    } catch (error) {
      console.log(error)
      return error
    }
  }
  //Métodos para gestionar transacciones webpay
  pagar(idCuota: number, monto: number, empresa: string) {
    let body = {
      idCuota,
      monto,
      empresa
    }
    return this.http.post(
      `${environment.apiUrl}/egresoFijoImportadora/pagar`,
      body
    )
  }
  confirmar(token: string) {
    console.log('HostalService token: ', token)
    let body = { token }
    console.log('Antes de hhtp post')
    return this.http.post(
      `${environment.apiUrl}/egresoFijoImportadora/confirmar`,
      body
    )
  }
  actualizarPagado(id: number): any {
    let body = {
      id
    }
    return this.http.post(
      `${environment.apiUrl}/egresoFijoImportadoraCuota/actualizarPagado/`,
      body
    )
  }

  getBancos() {
    return this.http.get<any>(`${environment.apiUrl}/banco/obtenerBancos/`)
  }
}
