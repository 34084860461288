<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Actualizar IMG 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <!-- Informacion IMG -->
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Informacion IMG </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <!-- Capturar img -->
              <div>
                <input
                  mat-stroked-button
                  (change)="capturarFile($event)"
                  type="file"
                />
              </div>
              <br />
            </div>
          </div>
          <br />
          <h3>Imagen Previsualizacion</h3>
          <hr />
          <div class="row">
            <!-- Preview img -->
            <div class="col" *ngIf="previsualizacion">
              <img width="400px" [src]="previsualizacion" alt="img" />
            </div>
          </div>
          <hr />
        </mat-expansion-panel>

        <mat-card-actions>
          <button
            mat-stroked-button
            color="primary"
            type="submit"
            [disabled]="loading"
            (click)="subirIMG()"
            [disabled]="clickButton"
            (click)="AgregarIMG()"
          >
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="Comprobante 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="Editar 📝">
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form [formGroup]="addressForm" novalidate class="mat-elevation-z10">
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Editar Cliente</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Informacion Basica -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Basica </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- informacion fila 1 -->
            <div class="row">
              <!-- fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Fecha"
                    formControlName="fecha"
                    [matDatepicker]="picker"
                    (click)="picker.open()"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    *ngIf="addressForm.controls['fecha'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- tipoIngreso -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Ingreso"
                    formControlName="tipoIngreso"
                  >
                    <mat-option
                      *ngFor="let tipoIngreso of tipoIngreso"
                      [value]="tipoIngreso"
                    >
                      {{ tipoIngreso }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>account_balance_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoIngreso'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- descripcionIngreso -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Descripcion Ingreso"
                    formControlName="descripcionIngreso"
                  />
                  <mat-icon matSuffix>edit_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['descripcionIngreso'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- monto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Monto" formControlName="monto" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error
                    *ngIf="addressForm.controls['monto'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- FILA 2 -->
            <div class="row">
              <!-- nombreCliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Nombre Cliente"
                    formControlName="nombreCliente"
                  />
                  <mat-icon matSuffix>account_circle</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['nombreCliente'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Tipo CLiente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Cliente"
                    formControlName="tipoCliente"
                  >
                    <mat-option
                      *ngFor="let tipo of tipoCliente"
                      [value]="tipo"
                    >
                      {{ tipo }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>assignment_ind</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoCliente'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Referencia -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Referencia"
                    formControlName="Referencia"
                  >
                    <mat-option
                      *ngFor="let Referencia of Referencia"
                      [value]="Referencia"
                    >
                      {{ Referencia }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>contact_mail</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['Referencia'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 3 -->
            <div class="row">
              <!-- Tipo Documento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Documento"
                    formControlName="tipoDocumento"
                  >
                    <mat-option
                      *ngFor="let tipoDocumento of tipoDocumento"
                      [value]="tipoDocumento"
                    >
                      {{ tipoDocumento }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>import_contacts</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoDocumento'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- nDocumento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="N° Documento"
                    formControlName="nDocumento"
                  />
                  <mat-icon matSuffix>assignment</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['nDocumento'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Estado Pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Estado Pago"
                    formControlName="estadoPago"
                  >
                    <mat-option
                      *ngFor="let estadoPago of estadoPago"
                      [value]="estadoPago"
                    >
                      {{ estadoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>monetization_on</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['estadoPago'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Medio Pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Medio Pago"
                    formControlName="medioPago"
                  >
                    <mat-option
                      *ngFor="let medioPago of medioPago"
                      [value]="medioPago"
                    >
                      {{ medioPago }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>payment</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['medioPago'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 4 -->
            <div class="row">
              <!-- Codigo Autorizacion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Codigo Autorizacion"
                    formControlName="codigoAutorizacion"
                  />
                  <mat-icon matSuffix>payment</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['codigoAutorizacion'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Sucursal -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Sucursal"
                    formControlName="idSucursal"
                  >
                    <mat-option
                      *ngFor="let s of empresa.Sucursals"
                      [value]="s.id"
                    >
                      {{ s.razonSocial }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>home</mat-icon>
                  <!-- <mat-error
                    *ngIf="
                      addressForm.controls['nombreSucursal'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error> -->
                </mat-form-field>
              </div>
              <!-- Vendedor -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Vendedor"
                    formControlName="vendedor"
                  />
                  <mat-icon matSuffix>face</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['vendedor'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>

          <!-- Informacion IMG -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion IMG </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- Capturar img -->
                <div>
                  <input
                    mat-stroked-button
                    (change)="capturarFile($event)"
                    type="file"
                  />
                </div>
                <br />
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-stroked-button
            color="primary"
            type="submit"
            [disabled]="loading"
            (click)="subirIMG()"
            [disabled]="clickButton"
            (click)="onSubmit()"
          >
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
