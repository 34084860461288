import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { EgresoHostal } from '@app/_models/hostal/egresoHostal'
import { Empresa } from '@app/_models/shared/empresa'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Usuario } from '@app/_models/shared/usuario'
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'
import { HostalService } from '../../hostal.service'
import { ChangeDetectorRef } from '@angular/core';
import { GastosFijos} from '@app/_models/gastosFijos/gastosFijos'
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-hostal-egresos-formulario',
  templateUrl: './hostal-egresos-formulario.component.html',
  styleUrls: ['./hostal-egresos-formulario.component.scss']
})
export class HostalEgresosFormularioComponent implements OnInit {
  @Output()
  formularioListo = new EventEmitter<string>()

  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  nombreUsuario = this.usuario.nombre
  apellidoUsuario = this.usuario.apellido
  nombreCompleto = this.usuario.nombre+ " " +this.usuario.apellido


  //cuentasRegistradas: any[] = []
  //Variables que usan para los egresos de Prestamos bancarios y automotriz
  mostrarDatos: boolean = true
  datoCuota = 'N/A'
  idEmpresa = 1
  empresa = new Empresa()
  montoTotal = '1.000'
  selected: any
  opcionSeleccionado: string = ''
  verSeleccion: string = ''
  result2 = ''
  result3 = ''
  numberConvert = ''
  public archivoGasto: any = []
  idGasto: any


  modelo: EgresoHostal = new EgresoHostal()
  modeloGastoFijo: GastosFijos = new GastosFijos()
  sucursales: Sucursal[]
  tiposEgresos: string[]
  tiposPagos: string[]
  grupos: string[] = []
  gastosFijo: string[]
  dataArrayImg = []
  sucursalesData: Sucursal[] = [];
  grupoGastos =[];



  maxDate = new Date();

  form!: FormGroup
  clickButton = true

  addressForm = this.fb.group({
    fecha: [null, Validators.required],
    monto: [null, Validators.required],
    tipoEgreso: [null, Validators.required],
    descripcion: [null, Validators.required],
    responsable: [this.nombreCompleto],
    idSucursal: [null],
    tipoPago: [null, Validators.required],
    // grupoGastos: [null, Validators.required],
    gastosFijos: [null, Validators.required],
    banco: [null],
    numeroCuenta: [null, [Validators.pattern('^[0-9]{4}$')]],
    otroGasto: [''],
    grupoGastos: [null],
    archivos: null
  })

  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private hostalService: HostalService,
    private sucursalService: SucursalSharedService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private empresaService: EmpresaSharedService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,

  ) {
    this.sucursales = this.sucursalService.sucursalListValue
    this.gastosFijo =['Luz','Agua','Gas','Internet','Otro']
    this.sucursalService.sucursalList.subscribe(sucursales => {
      this.sucursales = sucursales;
    });
    //this.sucursales = this.sucursalService.sucursalListValue
    this.tiposPagos = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Cheque',
      'Billetera Copec',
      'Caja Chica',
      'Billetera Mercado Libre',
      'Pago Retail',
      'Billetera Match',
      'Billetera Tenpo',
      'Billetera Mercado Pago',
      'Pac'
    ]
    this.grupos = [
      'TRANSFERENCIAS RENT A CAR',
      'TRANSFERENCIAS HOSTAL',
      'TRANSFERENCIAS GRUPO FIRMA',
      'TRANSFERENCIAS FIRMA ABOGADOS',
      'TRANSFERENCIAS IMPORTADORA',
      'TRANSFERENCIAS EXPORTADORA',
      'TRANSFERENCIAS CAMPO',
      'TRANSFERENCIAS INMOBILIARIA',
      'TRANSFERENCIAS LUBRICENTRO',
      'TRANSFERENCIAS GASTO PERSONAL',
      'TRANSFERENCIAS PRESTAMOS ENTRE EMPRESAS'
    ]
    this.tiposEgresos = [
      'Gastos',
      'Gastos Fijos',
      'Costos',
      'Remuneraciones',
      'Bancarios',
      'Impuestos',
      'Inversiones',
      'Combustible'
    ]
  }

  addBanc: Boolean;
  selectedTipoPago: string;
  numeroCuentaLength: string ;

  addBancNumber(tipoPagoValue: string): void {

    if (tipoPagoValue !== "Efectivo") {
      this.addBanc = true;
    } else {
      this.addBanc = false;
    }

  }

  ngOnInit(): void {

    this.getEmpresa(this.idEmpresa)

    this.addBanc = false;
    this.addressForm.get('tipoPago').valueChanges.subscribe((tipoPagoValue) => {
      this.selectedTipoPago = tipoPagoValue;
      this.addBancNumber(tipoPagoValue);
      if (tipoPagoValue === "Efectivo") {
        this.addressForm.patchValue({
          banco: null,
          numeroCuenta: null
        });
      }
    });
    this.addressForm.get('tipoEgreso').valueChanges.subscribe((value) => {
      if (value === 'Gastos Fijos') {
        this.addressForm.get('gastosFijos').enable();
      } else {
        this.addressForm.get('gastosFijos').setValue(null);
        this.addressForm.get('gastosFijos').disable();
      }
    });
    console.log(this.gastosFijo)
    this.addBanc = false;
    this.addressForm.get('tipoPago').valueChanges.subscribe((tipoPagoValue) => {
      this.selectedTipoPago = tipoPagoValue;
      this.addBancNumber(tipoPagoValue);
      if (tipoPagoValue === "Efectivo") {
        this.addressForm.patchValue({
          banco: null,
          numeroCuenta: null
        });
      }
    });
    this.loadSucursales();
    this.route.queryParams.subscribe(params => {
      this.cdRef.detectChanges();
      this.addressForm.get('idSucursal').setValue(params.idSucursal || null);
      this.addressForm.get('monto').setValue(params.monto || null);
      this.addressForm.get('fecha').setValue(params.fecha || null);
      this.addressForm.get('gastosFijos').setValue(params.gasto || null);
      this.addressForm.get('tipoEgreso').setValue(params.gastoFijos || 'Gastos Fijos');
      this.addressForm.get('descripcion').setValue(params.descripcionGasto || null);
      this.idGasto = params.idEvento;
    });
  }
  loadSucursales(): void {
    this.sucursalService.getAll() // Ajusta esto según el servicio y método correctos
      .subscribe(
        sucursales => {
          this.sucursalesData = sucursales;
        },
        error => {
          console.error('Error al obtener las sucursales', error);
        }
      );
  }

  getEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x) => {
        //console.log(x);
        //console.log(x);
        x.Sucursals = Object.values(x.Sucursals)
        this.empresa = x
        //console.log("este es: ", this.empresa);
        if (this.form && this.form.value && this.form.value.idSucursal) {
          const sucursal = this.sucursalesData.find(s => s.id === this.form.value.idSucursal);
          if (sucursal) {
            this.form.patchValue({
              idSucursal: sucursal.razonSocial
            });
          }
        }
      });
  }

  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    this.archivoGasto = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'egresoHostal' + Date.now() + '.' + extension
    this.dataArrayImg.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.archivoGasto.push(archivoCapturado);
    this.clickButton = false
  }
  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      const formularioDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })

      this.archivoGasto.forEach((archivoGasto)=> {
        formularioDatos.append('File', archivoGasto)
      })

      this.http
        .post(
          `${environment.apiUrl}/egresoHostal/upload/egresoHostal`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          // this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
          //   duration: 2000,
          //   verticalPosition: 'top'
          // })
        })

      this.http
      .post(
        `${environment.apiUrl}/gastosFijos/Upload/gastosFijos`,
        formularioDatos,
      )
      .subscribe((res)=> {
        this.loading = false
        this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
      })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }
  actualizarGastoFijo(archivos: any[]): void {
    //console.log("Hola soy el ID del GastoFijo del otro lado ", this.idGasto, archivos);
    const data = {
      archivos: archivos,
      estado:'Pagado'
    };
    this.hostalService.updateGastoFijos(this.idGasto, data)
      .subscribe(
        (data: any) => {
          console.log("Exito");
        },
        (error: any) => {
          console.log("Error", error);
        }
      );
  }


  capturar() {
    this.verSeleccion = this.opcionSeleccionado
    if (
      this.verSeleccion == 'Prestamos Bancarios' ||
      this.verSeleccion == 'Prestamos Automotriz'
    ) {
      this.transform('')
    } else {
      this.transform('')
    }
  }

  onSubmit() {
    this.form = this.fb.group(this.addressForm.value)
    
    if (this.dataArrayImg.length > 0) {
      this.form.value.archivos = this.dataArrayImg
      switch (this.addressForm.status) {
        case 'VALID':
          this.subirIMG()
          this.modelo.fecha = this.form.value.fecha
          this.modelo.monto = parseInt(this.numberConvert)
          this.modelo.descripcion = this.form.value.descripcion
          this.modelo.responsable = this.form.value.responsable
          this.modelo.tipoEgreso = this.form.value.tipoEgreso
          this.modelo.tipoPago = this.form.value.tipoPago
          this.modelo.archivos = this.form.value.archivos
          this.modelo.grupoGastos = this.form.value.grupoGastos
          this.modelo.idSucursal = this.form.value.idSucursal
          this.modelo.idUsuario = this.usuario.id
          this.modelo.gastosFijos = this.form.value.gastosFijos
          console.log(this.gastosFijo)
          this.modelo.banco = this.form.value.banco
          this.modelo.numeroCuenta = this.form.value.numeroCuenta

          this.numeroCuentaLength = this.form.value.numeroCuenta?.toString();

          if (this.addBanc && (!this.form.value.banco || this.numeroCuentaLength?.length !== 4)) {
            this.snackBar.open(
              'mensaje desde el primer form Debe seleccionar un banco e ingresar los últimos 4 digitos de la tarjeta / número cuenta',
              'cerrar',
              {
                duration: 5000,
                verticalPosition: 'top'
              }
            )
            return;
          }
          this.actualizarGastoFijo(this.form.value.archivos);
          this.modeloGastoFijo.archivos = this.form.value.archivos

          if(this.addressForm.value.tipoEgreso === 'Gastos Fijos'
           && this.form.value.gastosFijos === 'Otro'
          ){
            this.modelo.gastosFijos = this.addressForm.value.otroGasto;
          }

          //console.log("", this.modelo)

          if(this.addressForm.value.tipoEgreso === 'Gastos Fijos'
           && this.form.value.gastosFijos === 'Otro'
          ){
            this.modelo.gastosFijos = this.addressForm.value.otroGasto;
          }

          this.hostalService
            .EgresoHostal(this.modelo)
            .pipe(first())
            .subscribe(
              (data: any) => {
                this.snackBar.open('Ingreso agregado con exito!', 'cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                window.location.reload()
              },
              (error: any) => {
                this.snackBar.open(
                  'Ocurrio un problema, contacte a soporte',
                  'cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
      }
    }
  }

  transform(val: any) {
    if (val != '' || val != null) {
      val = this.format_number(val, '')
    } else {
      val = this.format_number('', '')
    }
    return val
  }

  format_number(number: any, prefix: any) {
    let result = '',
      number_string = ''
    if (number != '' && number != null) {
      let thousand_separator = '.',
        decimal_separator = ',',
        regex = new RegExp('[^' + decimal_separator + '\\d]', 'g')
      number_string = number.replace(regex, '').toString()
      let split = number_string.split(decimal_separator),
        rest = split[0].length % 3
      result = split[0].substr(0, rest)
      let thousands = split[0].substr(rest).match(/\d{3}/g)
      if (thousands) {
        let separator = rest ? thousand_separator : ''
        result += separator + thousands.join(thousand_separator)
      }
      result =
        split[1] != undefined ? result + decimal_separator + split[1] : result
    }
    this.result2 = result
    this.result3 = result
    this.numberConvert = number_string
    return prefix == undefined ? result : result ? prefix + result : ''
  }

  restrictNumeric(e: any) {
    let input
    if (e.metaKey || e.ctrlKey) {
      return true
    }
    if (e.which === 32) {
      return false
    }
    if (e.which === 0) {
      return true
    }
    if (e.which < 33) {
      return true
    }
    input = String.fromCharCode(e.which)
    return !!/[\d\s]/.test(input)
  }
}
