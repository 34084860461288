<hr>
<div class="row">
  <div class="col">
    <h3>Grafica De Los Valor Total </h3>
    <div style="width: 400px; height: 400px">
      <canvas id="myChart1"> </canvas>
    </div>
  </div>

  <div class="col">
    <h3>Grafica De Los Valores Desglozado</h3>
    <div style="width: 400px; height: 400px">
      <canvas id="myChart2"> </canvas>
    </div>
  </div>
</div>
<hr>
