<mat-toolbar color="primary">
  <!-- <button style="margin-right: 8rem" mat-raised-button (click)="limpiarFiltros()" color="accent">
  Limpiar filtros
</button> -->
  <span style="margin-right: 8rem; margin-left: 8rem">Listado de folios</span>
  <!-- ***************************** cambiar metodo botón ********************************** -->
  <button mat-raised-button color="accent" style="margin-left: 1rem" (click)="getAllFolio()">
        <mat-icon>autorenew</mat-icon>
  </button>
</mat-toolbar>

<div class="table-responsive">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Código -->
        <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef>Código</th>
            <td mat-cell *matCellDef="let element">{{ element.idDteTipo }}</td>
        </ng-container>
      
        <!-- tipoDocumento -->
        <ng-container matColumnDef="tipoDocumento">
            <th mat-header-cell *matHeaderCellDef>Documento</th>
            <td mat-cell *matCellDef="let element">{{ element.DteTipo.nombre }}</td>
        </ng-container>

        <!-- siguienteFolio -->
        <ng-container matColumnDef="siguienteFolio">
            <th mat-header-cell *matHeaderCellDef>Siguiente Folio</th>
            <td mat-cell *matCellDef="let element">
                {{element.folioSiguiente}}
            </td>
        </ng-container>

        <!-- totalDisponible -->
        <ng-container matColumnDef="totalDisponible">
            <th mat-header-cell *matHeaderCellDef>Total Disponibles</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.ultimoFolio - element.folioSiguiente == 0; else elseBlock">1</div>
                <ng-template #elseBlock>{{element.ultimoFolio - element.folioSiguiente + 1}}</ng-template>
            </td>
        </ng-container>

        <!-- FechaAutorizaciónCaf -->
        <ng-container matColumnDef="FechaAutorizacionCaf">
            <th mat-header-cell *matHeaderCellDef>Fecha Autorización Caf</th>
            <td mat-cell *matCellDef="let element">{{ element.fechaAutorizacion | date }}</td>
        </ng-container>

        <!-- FechaExpiración -->
        <ng-container matColumnDef="rutEmisor">
            <th mat-header-cell *matHeaderCellDef>Rut Emisor</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.rutEmisor != null; else elseBlock">{{element.rutEmisor}}</div>
                <ng-template #elseBlock>-</ng-template>
            </td>
        </ng-container>

        <!-- alerta -->
        <ng-container matColumnDef="alerta">
            <th mat-header-cell *matHeaderCellDef>Alerta</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.alerta <= element.ultimoFolio - element.folioSiguiente + 1; else elseBlock"> <div class="alerta">{{element.alerta}}</div></div>
                <ng-template #elseBlock>{{element.alerta}}</ng-template>
            </td>
        </ng-container>
      
        <!-- foliosAdicionales -->
        <!-- <ng-container matColumnDef="foliosAdicionales">
            <th mat-header-cell *matHeaderCellDef>Folios Adicionales</th>
            <td mat-cell *matCellDef="let element"></td>
        </ng-container> -->

        <!-- Botones -->
        <ng-container matColumnDef="acciones">
            <th hidden mat-header-cell *matHeaderCellDef>Acciones</th>
            <td hidden mat-cell *matCellDef="let element">
                <mat-button-toggle-group hidden name="favoriteColor" aria-label="Favorite Color">
                    <mat-button-toggle hidden value="green" (click)="openDialogEdit(element.id,  element.idDteTipo )" matTooltip="Ver">
                        <mat-icon>visibility</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle  value="green" (click)="delete(element.id, element.nombre)" matTooltip="Eliminar Folios">
                        <mat-icon>delete</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No existen coincidencias</td>
        </tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>