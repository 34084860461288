import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor() { }
  private spinner_response$ = new Subject<any>();

  addResultSpinner(spinner: any){
    this.spinner_response$.next(spinner)
  }

  getResultSpinner() : Observable<any>{
    return this.spinner_response$.asObservable()
  }

}