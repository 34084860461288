import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { EgresosRentacar } from '@app/_models/rentacar/egresoRentacar'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { RentacarService } from '@app/_pages/rentacar/rentacar.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-rentacar-egresos-acciones',
  templateUrl: './rentacar-egresos-acciones.component.html',
  styleUrls: ['./rentacar-egresos-acciones.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class RentacarEgresosAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any

  dataArrayImg = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  idEgresoRentacar: any
  fecha: any
  tipoEgreso: any
  monto: any
  descripcion: any
  tipoPago: any
  grupoGastos: any
  responsable: any
  tiposEgresos:string[]=[]
  addBanc:boolean
  addt:boolean
  selectedTipoPago:any
  selectedTipoegreso:any
  tiposPagos:string[]=[]
  gastosFijos:string[]=[]
  gastosFijo: string[];
  grupos: string[] = []
  cuentas: any

  //Modelo de Egresos Rentacar
  modelo: EgresosRentacar = new EgresosRentacar()

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private rentacarService: RentacarService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.tiposEgresos = [
      'Gastos Fijos',
      'Gastos',
      'Costos',
      'Remuneraciones',
      'Bancarios',
      'Impuestos',
      'Inversiones',
      'Prestamos Bancarios',
      'Prestamos Automotriz',
      'Automotriz',
      'Combustibles'
    ]
    this.tiposPagos = ['Efectivo', 'Debito', 'Credito', 'Transferencia', 'Cheque','Billetera Copec', 'Caja Chica','Billetera Mercado Libre',' Pago Retail', 'Billetera Match', 'Billetera Tenpo', 'Billetera Mercado Pago']
  





  }
  getCuentaBancaria() {
    this.rentacarService
      .getAllCuentaBancaria()
      .pipe(first())
      .subscribe((cuentas: any[]) => {
        // Extrae solo los nombres de las cuentas y los almacena en un nuevo array
        this.cuentas = cuentas.map(cuenta => cuenta.nombreCuenta);
        console.log('Nombres de las cuentas:', this.cuentas);
      });
}
  addBancNumber(tipoPagoValue: string): void {

    if (tipoPagoValue !== "Efectivo") {
      this.addBanc = true;
    } else {
      this.addBanc = false;
    }

  }
  addtipo(tipoEgresoValue: string): void {

    if (tipoEgresoValue === "Gastos Fijos") {
      this.addt = true;
    } else {
      this.addt = false;
    }

  }
  ngOnInit(): void {
    
    this.idEgresoRentacar = localStorage.getItem('idEgresoRentacar')
    this.getCuentaBancaria()
    this.getEgresoRentacar()
    this.addBanc = false;
    this.addt = false;
    
    this.addressForm.get('tipoPago').valueChanges.subscribe((tipoPagoValue) => {
      this.selectedTipoPago = tipoPagoValue;
      
      this.addBancNumber(tipoPagoValue);
      if (tipoPagoValue === "Efectivo") {
        this.addressForm.patchValue({
          // banco: null,
          // numeroCuenta: null
        });
      }
      

    });
    this.addressForm.get('tipoEgreso').valueChanges.subscribe((tipoEgresoValue) => {
      
      this.selectedTipoegreso =tipoEgresoValue,
      this.addtipo(tipoEgresoValue);
      if (tipoEgresoValue === "Gastos Fijos") {
        this.addressForm.patchValue({
          
          gastosFijos: null
        });
      }
      
    

    });


  }

  //Configuracion de formulario
  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    tipoEgreso: ['', Validators.required],
    descripcion: ['', Validators.required],
    tipoPago: ['', Validators.required],
    monto: ['', Validators.required],
    cuentaGastos:['',Validators.required],
    
    responsable: ['' ],
    numeroCuenta:[null,Validators.pattern('^[0-9]{4}$')],
    gastosFijos: [null],
    grupoGastos: [''],
    archivos: null
  })
  get f() {
    return this.addressForm.controls
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.rentacarService.closeDialogModal()
    window.location.reload()
  }

  getEgresoRentacar() {
    this.rentacarService
      .getByIdEgreso(this.idEgresoRentacar) //servicio
      .pipe(first())
      .subscribe((x: any) => {
        console.log(x[0])
        if (x[0].id == this.idEgresoRentacar) {
          this.f.fecha.setValue(x[0].fecha)
          this.f.tipoEgreso.setValue(x[0].tipoEgreso)
          this.f.descripcion.setValue(x[0].descripcion)
          this.f.archivos.setValue(x[0].archivos)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.monto.setValue(x[0].monto)
          
          
          this.f.responsable.setValue(x[0].responsable)
          this.f.numeroCuenta.setValue(x[0].numeroCuenta)
          console.log(x[0].cuentaGasto)
          this.f.cuentaGastos.setValue(x[0].cuentaGasto)

          console.log("aqui gastos",this.grupoGastos)
        }
        this.previsualizacion = x[0].archivos
      })
  }

  //Capturar imagen
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop() // Obtener la extensión del archivo original
    const nuevoNombre = 'EgresosRentacar' + Date.now() + '.' + extension // Crear un nombre único con el identificador generado dinámicamente
    this.dataArrayImg.push(nuevoNombre) // Agregar el nuevo nombre al array de nombres de archivo
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    }) // Crear un nuevo objeto de tipo File con el nuevo nombre
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }
  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        console.log(image);
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  //Actualizar formulario
  onSubmit() {
    if (confirm('Esta seguro que desea actualizar el Egreso de Rentacar: ')) {
      this.rentacarService
        .getByIdEgresoSinBase64(this.idEgresoRentacar)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idEgresoRentacar) {
            const nombreArchivo = x[0].archivos
            const JsonNombreArchivo = JSON.parse(nombreArchivo)
            if (this.dataArrayImg[0] === undefined) {
              this.addressForm.value.archivos = JsonNombreArchivo
            } else {
              this.addressForm.value.archivos = this.dataArrayImg
            }
            switch (this.addressForm.status) {
              case 'VALID':
                this.rentacarService
                  .updateEgresoRentacar(
                    this.idEgresoRentacar,
                    this.addressForm.value
                  )
                  .pipe(first())
                  .subscribe(
                    (data) => {
                      this.snackBar.open(
                        'Egreso de Rentacar editado con éxito.',
                        'Cerrar',
                        {
                          duration: 2000,
                          verticalPosition: 'top'
                        }
                      )

                      this.addressForm.reset()
                      this.rentacarService.closeDialogModal()
                      window.location.reload()
                    },
                    (error) => {
                      this.snackBar.open(
                        'No se pudo editar el Egreso de Rentacar, favor contactar a informática.',
                        'Cerrar',
                        {
                          duration: 2000,
                          verticalPosition: 'top'
                        }
                      )
                    }
                  )
                break

              case 'INVALID':
                this.snackBar.open(
                  'El formulario debe ser completado.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
                break

              default:
                break
            }
          }
        })
    }
  }

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/egresoRentacar/Upload/RentaACarEgreso`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }
  
  //Actualizar formulario
  AgregarIMG() {
    if (confirm('Esta seguro que desea agregar IMG el Egreso de Rentacar: ')) {
      this.rentacarService
        .getByIdEgresoSinBase64(this.idEgresoRentacar)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idEgresoRentacar) {
            const data = x[0]
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.monto = data.monto
            this.addressForm.value.tipoEgreso = data.tipoEgreso
            this.addressForm.value.descripcion = data.descripcion
            this.addressForm.value.tipoPago = data.tipoPago
            this.addressForm.value.grupoGastos = data.grupoGastos
            this.addressForm.value.responsable = data.responsable
            this.addressForm.value.cuentaGasto =data.cuentaGasto
            this.addressForm.value.archivos = this.dataArrayImg

            this.rentacarService
              .updateEgresoRentacar(
                this.idEgresoRentacar,
                this.addressForm.value
              )
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(
                    'Ingreso de Rentacar editado con éxito.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )

                  this.addressForm.reset()
                  this.rentacarService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el Ingreso de Rentacar, favor contactar a informática.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }
}
