import { NumberFormatStyle } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { EgresoLubricentro } from '@app/_models/lubricentro/egresoLubricentro';
import { AdministracionService } from '@app/_pages/administracion/administracion.service';
import { LubricentroService } from '@app/_pages/lubricentro/lubricentro.service';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-acciones-egresos-list',
  templateUrl: './acciones-egresos-list.component.html',
  styleUrls: ['./acciones-egresos-list.component.scss']
})
export class AccionesEgresosListComponent implements OnInit {


  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any
  opcionSeleccionado: string = ''
  verSeleccion: string = ''
  result2 = ''
  result3 = ''
   numberConvert = ''
   gastosFijo: string[]
   tiposPagos: string[]

  idEgresoLubricentro: any
  fecha: any
  descripcionIngreso: any
  monto: any
  mostrarDatos: boolean = true
  addBanc: Boolean = true
  selectedTipoegreso :string;
  
  addt : Boolean= true;
  selectedTipoPago: string;
  numeroCuentaLength: string;

  addressForm = this.fb.group({
    fecha: [null],
    monto: [null],
    gastosFijos: [null],
    tipoEgreso: [null ],
    descripcion: [null, Validators.required],
    responsable: [null, Validators.required],
    idSucursal: [null, Validators.required],
    tipoPago: [null, Validators.required],
    numeroCuota: [null],
    numeroCuenta:[null,Validators.pattern('^[0-9]{4}$')],
    archivos: null,
    banco: [null]
  })
  egresosForm = this.fb.group({
    //agregar el detalle del formulario;
    fecha: [null],
    monto: [null],
    montoCuota: [null],
    tipoEgreso: [null],
    descripcion: [null],
    gastosFijos: [null, Validators.required],
    tipoPago: [null],
    // grupoGastos: [null, Validators.required],
    
    idIngreso: [null],
    numeroCuota: [null],
    otroGasto: [''],
    banco: [null],
    numeroCuenta:[null,Validators.pattern('^[0-9]{4}$')],
    archivos: null
    /* idCuentaAsignada: [null, Validators.required], */
  })

  dataArrayImg = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  modelo: EgresoLubricentro = new EgresoLubricentro()

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private lubricentroService: LubricentroService,
    private sanitizer: DomSanitizer,
    private http: HttpClient

  ) {
    
    this.tiposPagos = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Cheque',
      'Pac'
    ]
    
    this.gastosFijo =['Luz','Agua','Gas','Internet','Otro']}



  desactivarEdicion(): void {
    this.mostrarDatos = true
    this.addressForm.controls['monto'].setValidators(Validators.required)
    this.addressForm.controls['monto'].updateValueAndValidity()
    this.addressForm.controls['numeroCuota'].clearValidators()
    this.addressForm.controls['numeroCuota'].updateValueAndValidity()
    
  }
  activarEdicion(): void {
    this.mostrarDatos = false
    this.egresosForm.controls['numeroCuota'].setValidators(Validators.required)
    this.egresosForm.controls['numeroCuota'].updateValueAndValidity()
    this.egresosForm.controls['monto'].clearValidators()
    this.egresosForm.controls['monto'].updateValueAndValidity()
  }
  capturar() {
    this.verSeleccion = this.opcionSeleccionado
    if (
      this.verSeleccion == 'Prestamos Bancarios' ||
      this.verSeleccion == 'Prestamos Automotriz'
    ) {
      this.transform('')
    } else {
      this.transform('')
    }
  }
  transform(val: any) {
    if (val != '' || val != null) {
      val = this.format_number(val, '')
    } else {
      val = this.format_number('', '')
    }
    return val
  }
  format_number(number: any, prefix: any) {
    let result = '',
      number_string = ''
    if (number != '' && number != null) {
      let thousand_separator = '.',
        decimal_separator = ',',
        regex = new RegExp('[^' + decimal_separator + '\\d]', 'g')
      number_string = number.replace(regex, '').toString()
      let split = number_string.split(decimal_separator),
        rest = split[0].length % 3
      result = split[0].substr(0, rest)
      let thousands = split[0].substr(rest).match(/\d{3}/g)
      if (thousands) {
        let separator = rest ? thousand_separator : ''
        result += separator + thousands.join(thousand_separator)
      }
      result =
        split[1] != undefined ? result + decimal_separator + split[1] : result
    }
    this.result2 = result
    this.result3 = result
    this.numberConvert = number_string
    return prefix == undefined ? result : result ? prefix + result : ''
  }

  addtipo(tipoEgresoValue: string): void {

    if (tipoEgresoValue === "Gastos Fijos") {
      this.addt = true;
    } else {
      this.addt = false;
    }

  }
  addBancNumber(tipoPagoValue: string): void {

    if (tipoPagoValue !== "Efectivo") {
      this.addBanc = true;
    } else {
      this.addBanc = false;
    }

  }




  ngOnInit(): void {
    this.idEgresoLubricentro = localStorage.getItem('idEgresoLubricentro')
    console.log(this.idEgresoLubricentro)
    this.getEgresoLubricentro()
    this.addBanc = false;
    this.addt = false;

    this.addressForm.get('tipoPago').valueChanges.subscribe((tipoPagoValue) => {
      this.selectedTipoPago = tipoPagoValue;
      console.log('llego a funcion pago',tipoPagoValue)
      this.addBancNumber(tipoPagoValue);
      if (tipoPagoValue === "Efectivo") 
        {
          console.log('entro arriba elimino banco')
        this.addressForm.patchValue({
          banco: null,
          numeroCuenta: null
        });
      }
      

    });
 
    this.addressForm.get('tipoEgreso').valueChanges.subscribe((tipoEgresoValue) => {
      
      this.selectedTipoegreso =tipoEgresoValue,
      this.addtipo(tipoEgresoValue);
      if (tipoEgresoValue === "Gastos Fijos") {
        this.addressForm.patchValue({
          
          gastosFijo: null
        });
      }
      
    

    });










  }
  
  
  get f() {
    return this.addressForm.controls
  }


  getEgresoLubricentro() {
    this.lubricentroService
      .egresoGetById(this.idEgresoLubricentro)
      .pipe(first())
      .subscribe((x: any) => {       
        if (x[0].id == this.idEgresoLubricentro) {       
          this.f.idSucursal.setValue(x[0].idSucursal)
          this.f.fecha.setValue(x[0].fecha)
          this.f.monto.setValue(x[0].monto)
          // this.f.tipoEgreso.setValue(x[0].tipoEgreso)
          this.f.descripcion.setValue(x[0].descripcion)
          this.f.responsable.setValue(x[0].responsable)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.numeroCuota.setValue(x[0].numeroCuota)
          this.f.tipoEgreso.setValue(x[0].tipoEgreso)
          this.f.archivos.setValue(x[0].archivos)
          this.f.numeroCuenta.setValue(x[0].numeroCuenta)
          this.f.banco.setValue(x[0].banco)
          
        }
        this.previsualizacion = x[0].archivos

      })
  }

    //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.lubricentroService.closeDialogModal()
    window.location.reload()
  }

  //Capturar imagen
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop() // Obtener la extensión del archivo original
    const nuevoNombre = "EgresoLubricentro" + Date.now() + "." + extension // Crear un nombre único con el identificador generado dinámicamente
    this.dataArrayImg.push(nuevoNombre) // Agregar el nuevo nombre al array de nombres de archivo
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, { type: archivoCapturado.type }) // Crear un nuevo objeto de tipo File con el nuevo nombre
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false;
  }
    //Extraer la img a base 64 para poder visualizar
    extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

    subirIMG() {
      try {
        this.loading = true
        const formularioDeDatos = new FormData()
        this.archivos.forEach((archivo) => {
          formularioDeDatos.append('File', archivo)
        })
        this.http
          .post(
            `${environment.apiUrl}/egresoLubricentro/Upload/LubricentroEgreso`,
            formularioDeDatos
          )
          .subscribe((res) => {
            this.loading = false
            this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top'
            })
          })
      } catch (e) {
        this.loading = false
        this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        console.log('ERROR AL SUBIR IMG', e)
      }
    }


     // Actualizar formulario
     onSubmit() {
      if (confirm('Esta seguro que desea actualizar el Ingreso de Lubricentro: ')) {
      
        this.lubricentroService.getByIdEgresoSinBase64(this.idEgresoLubricentro)
          .pipe(first())
          .subscribe((x: any) => {
            if (x[0].id == this.idEgresoLubricentro) {
              console.log("entro a funcion final,",x[0].tipoPago)
              if (x[0].tipoPago=='Efectivo' ) {
                this.f.banco= null
                console.log("paso banco null")

              }
              
              
              const nombreArchivo = x[0].archivos
              const JsonNombreArchivo = JSON.parse(nombreArchivo);
              if (this.dataArrayImg[0] === undefined) {
                this.addressForm.value.archivos = JsonNombreArchivo;
              } else {
                this.addressForm.value.archivos = this.dataArrayImg
              }
              switch (this.addressForm.status) {
                case 'VALID':
                  this.lubricentroService
                    .updateEgresoLubricentro(this.idEgresoLubricentro, this.addressForm.value)
                    .pipe(first())
                    .subscribe(
                      (data) => {
                        this.snackBar.open('Egreso de Rentacar editado con éxito.', 'Cerrar', {
                          duration: 2000,
                          verticalPosition: 'top'
                        })
                        this.addressForm.reset()
                        this.lubricentroService.closeDialogModal()
                         window.location.reload()
                      },
                      (error) => {
                        this.snackBar.open(
                          'No se pudo editar el Egreso de Lubricentro, favor contactar a informática.',
                          'Cerrar',
                          {
                            duration: 2000,
                            verticalPosition: 'top'
                          }
                        )
                      }
                    )
                  break
                case 'INVALID':
                  this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
                    duration: 2000,
                    verticalPosition: 'top'
                  })
                  break
                default:
                  break
              }
            }
          })
      }
    }


 //Actualizar formulario
 AgregarIMG() {
  if (confirm('Esta seguro que desea agregar IMG el Ingreso de Lubricentro: ')) {
    this.lubricentroService.getByIdEgresoSinBase64(this.idEgresoLubricentro)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idEgresoLubricentro) {
          const data = x[0]
           this.addressForm.value.idSucursal =  data.idSucursal
           this.addressForm.value.fecha = data.fecha
           this.addressForm.value.monto = data.monto
           this.addressForm.value.tipoEgreso = data.tipoEgreso
           this.addressForm.value.descripcion= data.descripcion
           this.addressForm.value.responsable= data.responsable
           this.addressForm.value.tipoPago=  data.tipoPago
           this.addressForm.value.grupoGastos=  data.grupoGastos
           this.addressForm.value.numeroCuota=  data.numeroCuota

          this.addressForm.value.archivos = this.dataArrayImg

          this.lubricentroService
          .updateEgresoLubricentro(this.idEgresoLubricentro, this.addressForm.value)
          .pipe(first())
          .subscribe(
            (data) => {
              this.snackBar.open('Ingreso de Lubricentro editado con éxito.', 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })

              this.addressForm.reset()
              this.lubricentroService.closeDialogModal()
              window.location.reload()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo editar el Ingreso de Lubricentro, favor contactar a informática.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
            }
          )

        }
      })
  }
}

}
