import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ClientesHomeComponent } from './clientes-home/clientes-home.component'
import { ClientesRoutingModule } from './clientes-routing.module'
import { ComponentsModule } from '@app/_components/components.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FullCalendarModule } from '@fullcalendar/angular'
import { MaterialModule } from '@app/material.module'
import { MatTableExporterModule } from 'mat-table-exporter'
import { MatTabsModule } from '@angular/material/tabs'
import { HttpClientModule } from '@angular/common/http'
import { ClientesTableComponent } from './clientes-home/clientes-table/clientes-table.component'
import { ClientesFormComponent } from './clientes-home/clientes-form/clientes-form.component'
import {MatStepperModule} from '@angular/material/stepper';
import { ClientesTableEditComponent } from './clientes-home/clientes-table/clientes-table-edit/clientes-table-edit.component';


@NgModule({
  declarations: [
    ClientesHomeComponent,
    ClientesTableComponent,
    ClientesFormComponent,
    ClientesTableEditComponent
  ],
  imports: [
    CommonModule,
    ClientesRoutingModule,
    MatTabsModule,
    MaterialModule,
    HttpClientModule,
    ComponentsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FormsModule,
    MatTableExporterModule,
    MatStepperModule,


  ],
  exports: [
    ClientesRoutingModule,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
    MatStepperModule,
    ClientesFormComponent,

  ],

})
export class ClientesModule {}
