import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Cesionario } from '@app/_models/cesionarios/cesionario';
import { Bodega } from '@app/_models/inventario/bodega/bodega';
import { first, delay } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

// identificar si es la importacion correcta
import { AdministracionService } from '@app/_pages/administracion/administracion.service';
import { BodegaService } from '../bodega.service';

@Component({
  selector: 'app-bodega-list',
  templateUrl: './bodega-list.component.html',
  styleUrls: ['./bodega-list.component.scss']
})
export class BodegaListComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()

  cesionarios : any;

    // ? table definitions bodegas
  displayedColumns: string[] = [
    'select',
    'id',
    'sucursal',
    'nombre',
    'direccion',
    'estado',
    'botones'
  ]

  // Tabla en donde se almacenará los datos de la bd
  dataSource: MatTableDataSource<Bodega> = new MatTableDataSource();
  dataCesionario: Cesionario[] = []
  dataBodegas: Bodega[] = []

  formFilter = new FormGroup({
    direccion: new FormControl(),
    nombre: new FormControl(),
  })

  nombreEmpresa!: string;
  selection = new SelectionModel<Bodega>(true, []);
  totalSeleccion = 0;
  selectedRows!: any[];
  sucursalesDelete!: any;

  constructor(
    private administracionService: AdministracionService,
    private bodegaService: BodegaService,
    private snackBar: MatSnackBar
  ) {}
  
  ngOnInit(): void {
    this.getBodegas();
    this.aplicarfiltros();
    this.bodegaService.getResultBodega().subscribe(result => {
      this.getBodegas()
    })
  }
  // Obtener el listado de bodegas desde la BD
  getBodegas() {
    //Carga Tabla
    this.bodegaService
    .bodegasGetAll()
    .pipe(first())
    .subscribe((result: Bodega[]) => {
        this.dataBodegas = result.map((Bodega) => {
          return Bodega;
        });
        this.dataSource = new MatTableDataSource(this.dataBodegas);
        this.dataSource.paginator = this.paginator.toArray()[0];
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  // Metodo que sirve para la seleccion de un campo de la tabla
  masterToggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        });
  }
  // Filtros
  aplicarfiltros() {
    this.formFilter.valueChanges.subscribe((res) => {
      let dataFiltered = this.dataBodegas;

        //Filtro nombre
        if (res.nombre) {
          dataFiltered = dataFiltered.filter((data: Bodega) =>  data.estado == '0');
        }
   
        //Filtro direccion
        if (res.direccion) {
          dataFiltered = dataFiltered.filter((data: Bodega) => data.direccion == res.direccion)
        }

      this.dataSource = new MatTableDataSource(dataFiltered);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.selection.clear();
      this.totalSeleccion = 0;
    })
  }
  
  estado(filtro:string){
    let estadoBodegas = this.dataBodegas;
    estadoBodegas = this.dataBodegas.filter((data: Bodega) => data.estado == filtro)
    this.dataSource = new MatTableDataSource(estadoBodegas);
    this.dataSource.paginator = this.paginator.toArray()[0];
  }

  //Limpiar los filtros
  limpiarFiltros() {
    this.formFilter.patchValue({nombre: null,direccion: null});
    this.dataSource = new MatTableDataSource(this.dataBodegas);
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.selection.clear();
    this.totalSeleccion = 0;
  }

  //Metodo exportar excel
  exportAsXLSX(): void {
    this.selectedRows = [];
    this.selection.selected.forEach((x) => this.selectedRows.push(x));
    this.administracionService.exportAsExcelFile(this.selectedRows,'Lista-bodegas');
  }

  //Abrir Modal Editar
  openDialogEdit(cesionario: any, idCesionario:any) {
    localStorage.setItem('cesionario', JSON.stringify( cesionario ));
    localStorage.setItem('idCesionario', idCesionario);
    this.bodegaService.openDialogEditSucursal();
  }

  //Metodo eliminar una sucursal
  deleteCesionario(id: any, cesionario: any) {
    if (confirm('Esta seguro que desea eliminar el registro: ' + cesionario)) {
      this.bodegaService
        .delete(id)
        .pipe(first())
        .subscribe(() => {
          this.getBodegas();
        });
      this.snackBar.open('Bodega eliminada', 'cerrar', {
        duration: 3000,
        verticalPosition: 'top'
      });
    }
    this.getBodegas();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
