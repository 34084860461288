import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { EgresosFirma } from '@app/_models/egresosFirma/egresosFirma'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { AbogadosService } from '@app/_pages/abogados/abogados.service'
import { first } from 'rxjs/operators'
import { environment } from '@environments/environment'
import { Console } from 'console'

@Component({
  selector: 'app-f-abogados-egresos-acciones',
  templateUrl: './f-abogados-egresos-acciones.component.html',
  styleUrls: ['./f-abogados-egresos-acciones.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class FAbogadosEgresosAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any

  dataArrayImg = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  idEgresoFirma: any
  fecha: any
  tipoEgreso: any
  monto: any
  descripcion: any
  tipoPago: any
  grupoGastos: any
  responsable: any
  gastosFijo: string[];
  addBanc: Boolean = true;
  addt: Boolean= true;
  selectedTipoPago: string;
  selectedTipoegreso: string;
  tiposPagos: string[]
  grupos: string[] = []
  tiposEgresos: string[]
  

  //Modelo de Egresos Firma
  modelo: EgresosFirma = new EgresosFirma()

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private AbogadosService: AbogadosService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {

    this.tiposPagos = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Cheque',
      'Pac'
    ]
    
    this.tiposEgresos = [
      'Gastos',
      'Costos',
      'Remuneraciones',
      'Bancarios',
      'Impuestos',
      'Gastos Fijos',
      'Inversiones'
    ]
    { this.gastosFijo =['Luz','Agua','Gas','Internet','Otro']}



  }

  addBancNumber(tipoPagoValue: string): void {

    if (tipoPagoValue !== "Efectivo") {
      this.addBanc = true;
    } else {
      this.addBanc = false;
    }

  }
  addtipo(tipoEgresoValue: string): void {

    if (tipoEgresoValue === "Gastos Fijos") {
      this.addt = true;
    } else {
      this.addt = false;
    }

  }


  ngOnInit(): void {
    this.idEgresoFirma = localStorage.getItem('idEgresoFirma')
    console.log(this.idEgresoFirma)
    this.getEgresoFirma()
    this.addBanc = false;
    this.addt = false;
    
    this.addressForm.get('tipoPago').valueChanges.subscribe((tipoPagoValue) => {
      this.selectedTipoPago = tipoPagoValue;
      
      this.addBancNumber(tipoPagoValue);
      if (tipoPagoValue === "Efectivo") {
        this.addressForm.patchValue({
          // banco: null,
          // numeroCuenta: null
        });
      }
      

    });
    this.addressForm.get('tipoEgreso').valueChanges.subscribe((tipoEgresoValue) => {
      
      this.selectedTipoegreso =tipoEgresoValue,
      this.addtipo(tipoEgresoValue);
      if (tipoEgresoValue === "Gastos Fijos") {
        this.addressForm.patchValue({
          
          gastosFijo: null
        });
      }
      
    

    });





  }

  //Configuracion de formulario
  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    tipoEgreso: ['', Validators.required],
    descripcion: ['', Validators.required],
    tipoPago: ['', Validators.required],
    monto: ['', Validators.required],
    
    responsable: [''],
    numeroCuenta:[null,Validators.pattern('^[0-9]{4}$')],
    gastosFijos: [null],
    archivos: null
  })

  get f() {
    return this.addressForm.controls
  }

  closeDialog() {
    this.administracionService.closeDialogModal()
    this.AbogadosService.closeDialogModal()
    window.location.reload()
  }

  getEgresoFirma() {
    this.AbogadosService.getByIdEgreso(this.idEgresoFirma)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idEgresoFirma) {
          this.f.fecha.setValue(x[0].fecha)
          this.f.tipoEgreso.setValue(x[0].tipoEgreso)
          this.f.descripcion.setValue(x[0].descripcion)
          this.f.archivos.setValue(x[0].archivos)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.monto.setValue(x[0].monto)
          this.f.responsable.setValue(x[0].responsable)
          this.f.numeroCuenta.setValue(x[0].numeroCuenta)
        }
        this.previsualizacion = x[0].archivos
      })
  }

  capturarFile(event) {
    const archivoCapturado = event.target.files[0]
    this.dataArrayImg.push(archivoCapturado.name)
    this.extraerBase64(archivoCapturado).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoCapturado)
  }

  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  //Actualizar formulario
  onSubmit() {
    if (
      confirm('Esta seguro que desea actualizar el Egreso de Firma Abogados: ')
    ) {
      this.AbogadosService.getByIdEgresoSinBase64(this.idEgresoFirma)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idEgresoFirma) {
            const nombreArchivo = x[0].archivos
            const JsonNombreArchivo = JSON.parse(nombreArchivo)
            if (this.dataArrayImg[0] === undefined) {
              this.addressForm.value.archivos = JsonNombreArchivo
            } else {
              this.addressForm.value.archivos = this.dataArrayImg
            }
            switch (this.addressForm.status) {
              case 'VALID':
                this.AbogadosService.updateEgresoFirma(
                  this.idEgresoFirma,
                  this.addressForm.value
                )
                  .pipe(first())
                  .subscribe(
                    (data) => {
                      this.snackBar.open(
                        'Egreso de Firma Abogados editado con éxito.',
                        'Cerrar',
                        {
                          duration: 2000,
                          verticalPosition: 'top'
                        }
                      )

                      this.addressForm.reset()
                      this.AbogadosService.closeDialogModal()
                      window.location.reload()
                    },
                    (error) => {
                      this.snackBar.open(
                        'No se pudo editar el Egreso de Firma Abogados, favor contactar a informática.',
                        'Cerrar',
                        {
                          duration: 2000,
                          verticalPosition: 'top'
                        }
                      )
                    }
                  )
                break

              case 'INVALID':
                this.snackBar.open(
                  'El formulario debe ser completado.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
                break

              default:
                break
            }
          }
        })
    }
  }

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/egresoFirma/Upload/FirmaEgreso`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

  //Actualizar formulario
  AgregarIMG() {
    if (confirm('Esta seguro que desea agregar IMG el Egreso de Firma Abogados: ')) {
      this.AbogadosService.getByIdEgresoSinBase64(this.idEgresoFirma)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idEgresoFirma) {
            const data = x[0]
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.monto = data.monto
            this.addressForm.value.tipoEgreso = data.tipoEgreso
            this.addressForm.value.descripcion = data.descripcion
            this.addressForm.value.tipoPago = data.tipoPago
            this.addressForm.value.grupoGastos = data.grupoGastos
            this.addressForm.value.responsable = data.responsable
            this.addressForm.value.archivos = this.dataArrayImg

            this.AbogadosService.updateEgresoFirma(
              this.idEgresoFirma,
              this.addressForm.value
            )
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(
                    'Ingreso de Rentacar editado con éxito.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )

                  this.addressForm.reset()
                  this.AbogadosService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el Ingreso de Rentacar, favor contactar a informática.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }
}
