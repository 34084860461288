import { Sucursal } from '../shared/sucursal';
import { Usuario } from '../shared/usuario';

export class modeloProveedor{
  Usuario!: Usuario;
  Sucursal!: Sucursal;
  id: number;
  nombre: string;
  rut: string;
  giro: string;
  direccion: string;
  ciudad: string;
  comuna: string;
  contacto: string;
  tipoPago: string;
  fija: number;
  celular: number;
  descripcion: string;
  email: string;
  usuario!: string;
  sucursal!: string;

}

