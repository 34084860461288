import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Sanitizer
} from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatSnackBar } from '@angular/material/snack-bar'

import { IngresosAbogado } from '@app/_models/abogados/ingresosAbogado'
import { Empresa } from '@app/_models/shared/empresa'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Usuario } from '@app/_models/shared/usuario'
import { HostalService } from '@app/_pages/hostal/hostal.service'
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'
import { AbogadosService } from '@app/_pages/abogados/abogados.service'
import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DatePipe } from '@angular/common'
import { FormGroup } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { AbogadosTabsService } from '@app/_pages/abogados/abogados-tabs.service'
import { ConsolidadosModule } from '@app/_pages/consolidados/consolidados.module'


@Component({
  selector: 'app-dialog-mostrar-contrato-acciones',
  templateUrl: './dialog-mostrar-contrato-acciones.component.html',
  styleUrls: ['./dialog-mostrar-contrato-acciones.component.scss'],
  providers: [
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } }
  ]
})
export class DialogContratosAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  empresa = new Empresa()
  
  clickButton = true
  public previsualizacion: any
  url: SafeResourceUrl

  public loading: boolean

  idIngresoAbogado: number

  panelOpenState: boolean
  pdfUrl: any
  pdf:any
  idcontrato = localStorage.getItem('idContrato')
  idContato = localStorage.getItem('idContratoPago')
  idCuota = localStorage.getItem('idCuota')
  idestaodCuota= localStorage.getItem('estadoPago')
  Cu:boolean
  cuotavalidator: boolean
  photoUrl: any
  dataF: any
  sucursal: any
  formattedDate: any
  selectedFile: File | null = null
  pdfPreview: any = null // Variable para la previsualización del PDF
  addressFormContrato: FormGroup
  imagenPreview: any
  addressFormimagen: FormGroup
  estadoCuota: string
  estadoContrato: string
  originalAddressFormContrato: any;
  originalAddressFormImagen: any;
  bolean:boolean
  browser: string;
  pdfSrc: string="/Users/grupofirma/Documents/GitHub/uploads/contratoabogado/1724690669789-1724441837657-1724182026241-prueba.pdf"
  base64File: string | null = null;
  fileType: string = '';
  contrato: any;
  base64Image: string | ArrayBuffer | null = null;
  base64Url :any
 

  addressForm = this.fb.group({
    fechaContrato: ['', Validators.required],
    idSucursal: ['', Validators.required],
    montoContrato: ['', Validators.required],
    nContrato: ['', Validators.required],
    descripcionIngreso: [''],
    nombreCliente: [''],
    rutCliente: [''],
    emailCliente: [''],
    direccionCliente: ['']
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private abogadoService: AbogadosService,
    private hostalService: HostalService,
    private sucursalService: SucursalSharedService,
    private sanitizer: DomSanitizer,
    private empresaService: EmpresaSharedService,
    private http: HttpClient,
    private abogadosTabsService: AbogadosTabsService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<DialogContratosAccionesComponent>
  ) {
    this.cuotavalidator = data.cuotavalidator
    this.addressFormContrato = this.fb.group({
      pdf: [null, Validators.required] // Campo para el archivo PDF
    })

    this.addressFormimagen = this.fb.group({
      imagen: [null, Validators.required] // Campo para el archivo imagen
    })
    this.originalAddressFormContrato = this.addressFormContrato.value;
    this.originalAddressFormImagen = this.addressFormimagen.value;
    this.browser = this.abogadoService.getBrowser()
   
  
  }





  ngOnInit(): void {
    console.log(this.cuotavalidator, 'cuando incia desde el ng0')
    
    console.log(this.browser)
    console.log(this.idestaodCuota,'estadod e la cueta')

    if (this.cuotavalidator == true) {
      this.cargarDatosCliente()
    } else {
      this.descargarCuota(this.idCuota)
      this.cargarDatosCuota(this.idCuota)
    }
    if (this.idestaodCuota === 'pendiente' || this.idestaodCuota === 'impago') {
      this.Cu= true
    }


  }


  idSucursal: number

  cargarDatosCliente(): void {
    console.log(this.cuotavalidator)
    const idCliente = localStorage.getItem('idCliente')
    console.log(idCliente)
    if (idCliente) {
      this.abogadoService.buscarPorId(idCliente).subscribe(
        (cliente) => {
          if (cliente) {
            
            this.addressForm.patchValue({
              nombreCliente: cliente.nombre,
              rutCliente: cliente.rut,
              emailCliente: cliente.email,
              direccionCliente: cliente.direccion
            })
            this.mostrarContrato()
          } else {
            this.snackBar.open('Cliente no encontrado', 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top'
            })
          }
        },
        (error) => {
          console.error('Error al cargar los datos del cliente:', error)
          this.snackBar.open(
            'Error al cargar los datos del cliente',
            'Cerrar',
            {
              duration: 2000,
              verticalPosition: 'top'
            }
          )
        }
      )
    } else {
      this.snackBar.open(
        'ID de cliente no encontrado en el almacenamiento local',
        'Cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }

  mostrarContrato() {
    const idCliente = localStorage.getItem('idCliente')
    
    this.abogadosTabsService
      .obtenerContratosClienteID(this.idContato)
      .subscribe((contratos: any) => {
       
       
        this.estadoContrato = contratos.estadoPago
        if (contratos.id == this.idContato) {
          // console.log('paso')
          if (contratos.file !== null) {
            this.base64Url = contratos.file
             console.log(contratos.file)
            this.convertirABase64(this.idContato)
          }
        } else {
          console.error('No se registro para el contrato')
        }
        // Convertir la ruta a URL HTTP
      })
  }

  convertirABase64(idContato: string) {
    this.abogadosTabsService
      .convertirArchivoABase64(idContato)
      .subscribe((response: { base64: string, type: string }) => {
        if (response.base64 && response.type) {
          // Creamos la URL en base64 segura
           console.log(response.base64,response.type)
          this.pdfUrl = `data:${response.type};base64,${response.base64}`;
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);
          this.pdf=this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);
          
          
        } else {
          console.error('No se pudo cargar el archivo.');
        }
      });
  }



  mostrarIframe(iframeElement) {
    if (this.browser=='Safari'){
      // Hacer visible el iframe
    iframeElement.style.display = 'block';
  
    // Forzar la recarga del iframe en Safari
    iframeElement.src = iframeElement.src;

    }
    
  }

  onFileChange(event: any): void {
    const file = event.target.files[0]
    // console.log(file)

    // Actualiza el formulario con el archivo seleccionado
    this.addressFormContrato.patchValue({
      pdf: file
    })
    this.addressFormContrato.get('pdf')!.updateValueAndValidity()

    // Usa FileReader para crear una URL segura para la vista previa
    const reader = new FileReader()
    reader.onload = () => {
      // Sanitiza la URL del PDF y asigna la URL segura a pdfPreview
      this.pdfPreview = this.sanitizer.bypassSecurityTrustResourceUrl(
        reader.result as string
      )
    }
    reader.readAsDataURL(file)
  }
  validarContrato() {
    if (this.estadoContrato === 'pagado') {
        // Si el contrato está pagado, se permite modificar sin alerta
        console.log('Modificando contrato pagado...');
        this.subirPDF();
    } else if (this.estadoContrato === 'pendiente') {
        // Si el contrato está pendiente, se muestra una alerta de confirmación
        const confirmacion = confirm('El contrato está pendiente. ¿Está seguro de que desea modificarlo?');
        
        if (confirmacion) {
            // El usuario ha confirmado, proceder con la modificación del contrato
            console.log('Modificando contrato pendiente...');
            this.subirPDF();
            this.bolean=true
            this.snackBar.open('Archivo actualizado con éxito', 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition: 'center'
            })

        } else {
          this.bolean=false
            // El usuario ha cancelado la modificación
            console.log('Modificación cancelada');
            this.snackBar.open('Modificacion cancelada', 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition: 'center'
            })
        }
    } else {
        // Manejar otros estados si existen
        console.log('Estado del contrato desconocido.');
    }
}


  subirPDF(): void {
    if (!this.addressFormContrato.get('pdf')?.value) return

    this.loading = true

    const formData = new FormData()
    formData.append('file', this.addressFormContrato.get('pdf')!.value)
  

    if (this.estadoContrato ) {
      this.abogadosTabsService
        .actualizarContrato(this.idContato, formData)
        .subscribe(
          (response: any) => {
            
            this.snackBar.open('Archivo actualizado con éxito', 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition: 'center'
            })
            this.loading = false
            // Actualizar la previsualización con la nueva URL del PDF si es necesario
          },
          (error) => {
            this.snackBar.open('Error al actualizar el archivo', 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top'
            })
            this.loading = false
          }
        )
    } else {
      this.snackBar.open(
        'El Contrato debe estar pagado revise el estado  de las Cuotas',
        'Cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }
  descargarPDF(): void {
    if ( this.base64Url) {
      let urls: string
      const nombre='Archivo'
      const dow:string= this.pdfUrl
      this.descargarArchivo(dow,nombre)

      
    } else {
      console.error('No hay URL de imagen disponible para descargar.')
    }
  }



    ////////////////////////// FUNCIONES GENERALES ///////////////////////////////




    descargarArchivo(url,nombre) {
      const enlaceDescarga = document.createElement('a');
      enlaceDescarga.href = url;
      enlaceDescarga.download = nombre;
    
      // Simular clic en el enlace
      document.body.appendChild(enlaceDescarga);
      enlaceDescarga.click();
      document.body.removeChild(enlaceDescarga);
    }
    obtenerEmpresa(id: number): any {
      this.empresaService
        .getByIdWithSucursales(id)
        .pipe(first())
        .subscribe((x: any) => {
          x.Sucursals = Object.values(x.Sucursals)
  
          this.empresa = x
        })
    }

    closeDialog() {
      this.abogadoService.closeDialogModal()
      window.location.reload()
    }

    get f() {
      return this.addressForm.controls
    }

    getEmpresa(id: number): any {
      this.empresaService
        .getByIdWithSucursales(id)
        .pipe(first())
        .subscribe((empresa) => {
          empresa.Sucursals = Object.values(empresa.Sucursals).sort((a, b) =>
            a.razonSocial > b.razonSocial ? 1 : -1
          )
          this.empresa = empresa
        })
    }
   

    guardar(): void {
      // Aquí podrías hacer alguna lógica antes de cerrar, como guardar datos.
  
      this.dialogRef.close() // Esto cierra el diálogo.
    }
    guardarYVerificarCambios() {
      if (this.cuotavalidator) {
        // Verificar cambios en el formulario de contratos (PDF)
        const currentContratoValues = this.addressFormContrato.value;
        const contratoSinCambios = JSON.stringify(this.originalAddressFormContrato) === JSON.stringify(currentContratoValues);
    
        if (contratoSinCambios) {
          alert('No se ha realizado ningún cambio en el contrato.');
          this.dialogRef.close();
        } else {
          if (this.bolean== true){
            this.snackBar.open(
              'Cambios registrados con exito',
              'Cerrar',
              {
                duration: 2000,
                verticalPosition: 'top'
              }
            )

          }else{
            alert('No se ha realizado ningún cambio en el Contrato.');

          }
          // Se han realizado cambios en el contrato, procede con la acción de guardar y cerrar el diálogo
          this.dialogRef.close();
        }
    
      } else {
        // Verificar cambios en el formulario de imágenes
        const currentImagenValues = this.addressFormimagen.value;
        const imagenSinCambios = JSON.stringify(this.originalAddressFormImagen) === JSON.stringify(currentImagenValues);
    
        if (imagenSinCambios) {
          alert('No se ha realizado ningún cambio en la imagen.');
          this.dialogRef.close();
        } else {
          if (this.estadoCuota == 'pagado'){
            this.snackBar.open(
              'Cambios registrados con exito',
              'Cerrar',
              {
                duration: 2000,
                verticalPosition: 'top'
              }
            )

          }else{
            alert('No se ha realizado ningún cambio en la imagen.');

          }
          // Se han realizado cambios en la imagen, procede con la acción de guardar y cerrar el diálogo
          
          this.dialogRef.close();
        }
      }
    }
  
    get formControl() {
      return this.addressForm.controls
    }
    

  convertirRutaEnHttp(rutaArchivo: string): string {
    const baseUrl = `${environment.apiUrl}`

    // Si `cuotavalidator` es falso, esto corresponde a imágenes
    if (!this.cuotavalidator) {
      let ruta = rutaArchivo

      // Verifica si la ruta contiene la ruta completa del sistema
      if (ruta.includes('uploads/')) {
        // Elimina la ruta innecesaria y deja solo el nombre del archivo
        ruta = ruta.replace('', '')
      } else if (!ruta.startsWith('uploads/')) {
        // Si solo viene el nombre del archivo, añade 'uploads/' al inicio
        ruta = `uploads/${ruta}`
      }

      // console.log(ruta)
      return `${baseUrl}${ruta}`
    }

    // Lógica existente para el caso donde `cuotavalidator` es verdadero
    if (rutaArchivo !== null) {
      console.log('ruta archivo',rutaArchivo);
      const rutaRelativa = rutaArchivo.replace(
        '/api../',
        ''
      ) // Ajusta la ruta según sea necesario
      return `${baseUrl}${rutaRelativa}`
    }

    return 'no se pudo convertir en http'
  }


    onSubmit() {
      const ingresoAbogado: IngresosAbogado = new IngresosAbogado()
    }







  /////////////////////Campo de las Cuotas//////////////////////////////////////////









  descargarCuota(id: any) {
    localStorage.setItem('idCuota', id)
    this.idCuota = id

    this.abogadosTabsService.obtenerRespaldos(this.idCuota).subscribe(
      (Cuota: any) => {
        if (Array.isArray(Cuota) && Cuota.length > 0) {
          // console.log(Cuota[0].url) // Accede al primer objeto del array y luego a la propiedad 'url'
          if (Cuota[0] && Cuota[0].url) {
            // console.log('paso a url')
            this.Base64Imagen(this.idCuota)
            // this.photoUrl = Cuota[0].url
            // this.photoUrl = this.convertirRutaEnHttp(Cuota[0].url)

            // this.photoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            //   this.photoUrl
            // )
          } else {
            console.error('No se encontró el archivo para la cuota')
          }
        } else {
          console.log('Cuota no es un array o está vacío')
        }
      },
      (error) => {
        console.error('Error al obtener el cuota:', error)
      }
    )
  }
 Base64Imagen(idCuota:string){

  this.abogadosTabsService.convertirArchivoCuotaABase64(idCuota).subscribe((response: any) => {
    if (response.base64 && response.type) {
      this.base64File = `data:${response.type};base64,${response.base64}`;
      this.photoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64File);
      // console.log(this.photoUrl)
    } else {
      console.error('No se pudo cargar el archivo.');
    }
  });

 }

  cargarDatosCuota(id: any) {
    this.idCuota = id

    if (this.idCuota) {
      this.abogadosTabsService.obtenerCuotasID(this.idCuota).subscribe(
        (cuota) => {
          if (cuota && typeof cuota === 'object') {
            // Verifica que cuota sea un objeto
            this.idIngresoAbogado = cuota.idContrato
            this.estadoCuota = cuota.estado

            // Obtener los datos del contrato usando el idContrato
            this.abogadosTabsService
              .obtenerContratosClienteID(this.idIngresoAbogado)
              .subscribe(
                (contrato) => {
                  this.dataF = contrato
                  this.obtenerEmpresa(this.dataF.idSucursal)
                  this.formattedDate = this.datePipe.transform(
                    contrato.fechaContrato,
                    'yyyy-MM-dd HH:mm'
                  )
                },
                (error) => {
                  console.error(
                    'Error al cargar los datos del contrato:',
                    error
                  )
                  this.snackBar.open(
                    'Error al cargar los datos del contrato',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          } else {
            console.log(
              'Cuota no es un objeto válido o no tiene las propiedades esperadas'
            )
          }
        },
        (error) => {
          console.error('Error al cargar los datos de la cuota:', error)
          this.snackBar.open(
            'Error al cargar los datos de la cuota',
            'Cerrar',
            {
              duration: 2000,
              verticalPosition: 'top'
            }
          )
        }
      )
    } else {
      this.snackBar.open(
        'ID de cuota no encontrado en el almacenamiento local',
        'Cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }

  onImagenChange(event: any) {
    const file = event.target.files[0]
    // console.log(file)

    // Verificar si el archivo seleccionado es una imagen

    // Actualiza el formulario con la imagen seleccionada
    this.addressFormimagen.patchValue({
      imagen: file
    })
    this.addressFormimagen.get('imagen')!.updateValueAndValidity()

    // Usa FileReader para crear una URL segura para la vista previa
    const reader = new FileReader()
    reader.onload = () => {
      // Sanitiza la URL de la imagen y asigna la URL segura a imagenPreview
      this.imagenPreview = this.sanitizer.bypassSecurityTrustUrl(
        reader.result as string
      )
    }
    reader.readAsDataURL(file)
  }
  descargarImagen(): void {
    if (this.photoUrl) {
      let url: string

      // Si `photoUrl` es un objeto, convertirlo a string
      if (
        typeof this.photoUrl === 'object' &&
        this.photoUrl.hasOwnProperty('changingThisBreaksApplicationSecurity')
      ) {
        url = this.photoUrl['changingThisBreaksApplicationSecurity']
      } else {
        url = this.photoUrl.toString()
      }

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const downloadUrl = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = downloadUrl
          link.download = url.split('/').pop() || 'imagen.png' // Establece el nombre del archivo
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(downloadUrl) // Libera el objeto URL creado
        })
        .catch((error) => {
          console.error('Error al descargar la imagen:', error)
        })
    } else {
      console.error('No hay URL de imagen disponible para descargar.')
    }
  }

  subirImagen() {
    if (!this.addressFormimagen.get('imagen')?.value) return
    this.loading = true
    // console.log(this.f.imagen)
    const formData = new FormData()
    formData.append('file', this.addressFormimagen.get('imagen')?.value)
    
    // console.log(this.idCuota)
    if (this.estadoCuota == 'pagado') {
      this.abogadosTabsService
        .actualizarRespaldoCuota(this.idCuota, formData)
        .subscribe(
          (cuota: any) => {
            this.snackBar.open('Archivo actualizado con éxito', 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition: 'center'
            })
            this.loading = false
          },
          (error) => {
            this.loading = false
            console.error('Error al obtener el respaldo de la cuota:', error)
          }
        )
    } else {
      this.loading = false
      this.snackBar.open(
        'La cuota debe estar pagada para modificar su registro',
        'cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }
}
