import { SelectionModel } from '@angular/cdk/collections'
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { modeloClientes } from '@app/_models/clientes/clientes'
import { ClientesService } from '../../clientes.service'

@Component({
  selector: 'app-clientes-table',
  templateUrl: './clientes-table.component.html',
  styleUrls: ['./clientes-table.component.scss']
})
export class ClientesTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort = null
  // ? Inputs & Outputs
  @Input() refrescar = ''

  //------inicio tabla-------
  displayedColumns: string[] = [
    'id',
    'nombre',
    'descuento',
    'credito',
    'contactoComercial',
    'emailComercial',
    'estado',
    'acciones'
  ]
  dataSource: MatTableDataSource<modeloClientes> = new MatTableDataSource()
  dataClientes: modeloClientes[] = []
  datoNoVigente: modeloClientes[] = []
  datoVigente: modeloClientes[] = []
  selection = new SelectionModel<modeloClientes>(true, [])

  constructor(
    public dialog: MatDialog,
    private clientesService: ClientesService
  ) {}

  ngOnInit(): void {
    this.getAllClientes()
  }
  getAllClientes() {
    this.clientesService.getAllClientes().subscribe(
      (data: any) => {
        this.dataClientes = data
        this.dataSource = new MatTableDataSource(this.dataClientes)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }
  //Boton para eliminar proveedor
  deleteClientes(id: any) {
    if (confirm('Esta seguro que desea eliminar el registro: ')) {
      this.clientesService.deleteClientes(id).subscribe(() => {
        this.getAllClientes()
      })
    }
  }

  //Boton para actualizar tabla de proveedores
  actualizar(): void {
    window.location.reload()
  }
  //Boton de busqueda
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  //Boton de editar
  updateCliente(id: any) {
    localStorage.setItem('idCliente', id)
    this.clientesService.openDialogEditCliente()
  }

  //Boton vigente
  vigente() {
    this.datoVigente = []
    this.clientesService.getAllClientes().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == true) {
            this.datoVigente.push(element)
            this.dataSource = new MatTableDataSource(this.datoVigente)
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort
          }
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //Boton no vigente
  noVigente() {
    this.datoNoVigente = []
    this.clientesService.getAllClientes().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == false) {
            this.datoNoVigente.push(element)
            this.dataSource = new MatTableDataSource(this.datoNoVigente)
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort
          }
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //Boton mostrar todos los datos
  todos() {
    this.clientesService.getAllClientes().subscribe(
      (data: any) => {
        this.dataClientes = data
        this.dataSource = new MatTableDataSource(this.dataClientes)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }
}
