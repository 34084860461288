<div class="mat-elevation-z8">
    <mat-card>
      <mat-toolbar color="primary">
        <span>Guias de Despacho</span>
        <button
          style="margin-left: 1rem; margin-bottom: 3px"
          mat-raised-button
          color="accent"
          (click)="actualizar()"
        >
          Actualizar tabla <mat-icon>autorenew_white_18dp</mat-icon>
        </button>
        <br />
  
        <button
          mat-raised-button
          color="accent"
          style="margin-left: 1rem; margin-bottom: 3px"
          (click)="exporter.exportTable('csv')"
        >
          Exportar a Excel <mat-icon>description_white_18dp</mat-icon>
        </button>

        <div class="row" style="margin-left: 1rem; ">
          <div class="col">
            <mat-form-field appearance="standard">
              <mat-label>Filtrar por:</mat-label>
              <mat-select [(ngModel)]="filtroSeleccionado">
                <mat-option *ngFor="let filtro of filtros" [value]="filtro">
                  {{ filtro }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" class="flex-grow-1">
              <mat-label>Buscar</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ingresa valor" />
            </mat-form-field>
          </div>
        </div>

        <button
        mat-raised-button
        style="margin-left: auto;"
        [ngClass]="{'verde': foliosRestantes > 5, 'rojo': foliosRestantes <= 0}"
        *ngIf="foliosRestantes !== undefined"
      >
        Folios Disponibles: {{ this.foliosRestantes }}
      </button>

      </mat-toolbar>
    </mat-card>
  
    <br />
    <div class="button-container">
      <button
        mat-raised-button
        color="accent"
        style="margin-left: 1rem; margin-bottom: 3px"
        type="reset"
        (click)=" SobreEnvio()"
      >
        Sobre de Envio
        <mat-icon>local_shipping_white_18dp</mat-icon>
      </button>
      <button
        mat-raised-button
        color="accent"
        style="margin-left: 1rem; margin-bottom: 3px"
        type="reset"
        (click)="EnviadaSII()"
      >
        Enviada a SII
        <mat-icon>checklist_white_18dp</mat-icon>
      </button>
  
      <button
        mat-raised-button
        color="accent"
        style="margin-left: 1rem; margin-bottom: 3px"
        type="reset"
        (click)="pendiente()"
      >
        Pendiente
        <mat-icon>sync_problem_white_18dp</mat-icon>
      </button>
      <button
        mat-raised-button
        color="accent"
        style="margin-left: 1rem; margin-bottom: 3px"
        type="reset"
        (click)="Rechazada()"
      >
        Rechazada
        <mat-icon>cancel_white_18dp</mat-icon>
      </button>
      <button
        mat-raised-button
        color="accent"
        style="margin-left: 1rem; margin-bottom: 3px"
        (click)="todos()"
      >
        Todos
        <mat-icon>list_white_18dp</mat-icon>
      </button>
    </div>
    <mat-form-field appearance="standard">
      <mat-label>Buscar</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Ingresa Nombre"
        #input
      />
    </mat-form-field>
  
    <table
      mat-table
      matTableExporter
      [dataSource]="dataSource"
      #exporter="matTableExporter"
      matSort
    >
      <!-- id Columna -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
  
      <!-- Cliente Columna -->
      <ng-container matColumnDef="cliente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cliente }}
        </td>
      </ng-container>
  
      <!-- Folio Columna -->
      <ng-container matColumnDef="folio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Folio</th>
        <td mat-cell *matCellDef="let element">
          {{ element.folio }}
        </td>
      </ng-container>
  
      <!-- totalGeneral Columna -->
      <ng-container matColumnDef="totalGeneral">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
        <td mat-cell *matCellDef="let element">
          <span matPrefix>$&nbsp;</span> {{ element.totalGeneral }}
        </td>
      </ng-container>
  
      <!-- fechaCreacion Columna -->
      <ng-container matColumnDef="fechaCreacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Emision</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fechaCreacion }}
        </td>
      </ng-container>
  
      <!-- vendedor Columna -->
      <ng-container matColumnDef="vendedor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendedor</th>
        <td mat-cell *matCellDef="let element">
          {{ element.vendedor }}
        </td>
      </ng-container>
  
      <!-- estado Columna -->
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
        <td mat-cell *matCellDef="let element">
          {{ element.estado }}
        </td>
      </ng-container>
  
      <!-- Acciones Columna -->
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">
          Acciones
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-mini-fab
            color="primary"
            style="margin-left: 1rem; margin-bottom: 2px"
            (click)="accionesDespacho(element.id)"
          >
            <mat-icon>save_as_white_18dp</mat-icon>
          </button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[10, 20, 30]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
  