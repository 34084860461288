import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import {  Observable, Subject } from 'rxjs';
import { VerCesionComponent } from './cesion-home/cesion-table/ver-cesion/ver-cesion.component';

@Injectable({
  providedIn: 'root'
})
export class CesionService {
  private folio_response$ = new Subject<any>();
  constructor(private http: HttpClient, private dialog: MatDialog) {}
  addResultFolio(termino: any){
    this.folio_response$.next(termino)
  }

  getAll(): any {
    return this.http.get<[]>(`${environment.apiUrl}/cesiones`)
  }

  getOne(id:any): any {
    return this.http.get<[]>(`${environment.apiUrl}/cesiones/${id}`)
  }

  updateEstadoPendiente(cesion:any, empresa: any): any {
    const body = {cesion, empresa};
    return this.http.post<[]>(`${environment.apiUrl}/cesiones/updateEstadoPendiente`, body)
  }

  getAllFilter(criterio:any, buscar:any): any {
    return this.http.get<[]>(`${environment.apiUrl}/cesiones/${criterio}/${buscar}`)
  }

  openDialogEditFolio(): void {
    const dialogRef = this.dialog.open(VerCesionComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  closeDialogModal(){
    this.dialog.closeAll();
    localStorage.removeItem("idFolio");
  }

}
