import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GuiaDespachoRoutingModule } from './guia-despacho-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { ComponentsModule } from '@app/_components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '@app/material.module';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatStepperModule } from '@angular/material/stepper';

import { DespachoHomeComponent } from './despacho-home/despacho-home.component';
import { DespachoFormComponent } from './despacho-form/despacho-form.component';
import { DespachoListComponent } from './despacho-list/despacho-list.component';
import { DespachoAccionesComponent } from './despacho-list/despacho-acciones/despacho-acciones.component';


@NgModule({
  declarations: [DespachoHomeComponent,DespachoFormComponent,DespachoListComponent,DespachoAccionesComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    MaterialModule,
    HttpClientModule,
    ComponentsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FormsModule,
    MatTableExporterModule,
    MatStepperModule,
    GuiaDespachoRoutingModule
  ],
  exports: [
    MaterialModule,
    ReactiveFormsModule,
    MatStepperModule,
    GuiaDespachoRoutingModule
  ]
})
export class GuiaDespachoModule { }
