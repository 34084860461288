import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComprasHomeComponent } from './compras-home/compras-home.component';


const routes:Routes=[
  {path: 'compras', component: ComprasHomeComponent}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComprasRoutingModule { }
