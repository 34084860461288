<h3>Archivos Adjuntos</h3>
<p>seleccione un archivo para descargar:</p>

<mat-dialog-content>
  <mat-list>
    <mat-list-item *ngFor="let item of archivos" (click)="descargar(item.url)">
      <mat-icon>get_app</mat-icon> {{ item.url }}
      <img src="{{item.url}}" alt="">
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
