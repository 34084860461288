import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductosHomeComponent } from './productos-home/productos-home.component';
import { MaterialModule } from '../../material.module';
import { ComponentsModule } from '../../_components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ProductosRoutingModule } from './productos.routing.module';
import { ProductosFormComponent } from './productos-form/productos-form.component';
import { ProductosListComponent } from './productos-list/productos-list.component';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogProductosFotoComponent } from './productos-list/dialog-productos-foto/dialog-productos-foto.component';
import { DialogProductosEditarComponent } from './productos-list/dialog-productos-editar/dialog-productos-editar.component';
import { DialogProductosFotoSubirComponent } from './productos-list/dialog-productos-foto/dialog-productos-foto-subir/dialog-productos-foto-subir.component';
import { DialogVariantesComponent } from './productos-list/dialog-variantes/dialog-variantes.component';



@NgModule({
  declarations: [
    ProductosHomeComponent,
    ProductosFormComponent,
    ProductosListComponent,
    DialogProductosFotoComponent,
    DialogProductosEditarComponent,
    DialogProductosFotoSubirComponent,
    DialogVariantesComponent
    
  ],
  imports: [
    ProductosRoutingModule,
    CommonModule,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
    //NgbModule
  ]
})
export class ProductosModule { }

