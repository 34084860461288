import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'

export class EgresosInmobiliaria {
  id!: string
  propiedad!: string
  tipoEgreso!: string
  fecha!: Date
  monto!: number
  sucursal!: string
  idSucursal!: string
  idUsuario!: number
  usuario!: string
  responsable!: string
  descripcion!: string
  Sucursal!: Sucursal
  Usuario!: Usuario
  numeroCuota!: string
  montoCuota!: number
  tipoPago!: string
  RespaldoEgresoInmobiliaria!: any[]
  grupoGastos!: string
  gastosFijos!: string
  banco!: string;
  numeroCuenta!: string; 
  archivos!: any
  proyecto: String;
  region: String; 
  costoAmortizacion: number;
  tasaInteres: number;    
}
