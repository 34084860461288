import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { modeloCuentasBancaria } from '@app/_models/consolidadoBancario/cuentasBancaria'
import { Chart } from 'chart.js'
import { ConsolidadoBancarioService } from '../../consolidado-bancario.service'

@Component({
  selector: 'app-home-graf',
  templateUrl: './home-graf.component.html',
  styleUrls: ['./home-graf.component.scss']
})
export class HomeGrafComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()
  @ViewChild(MatSort) sort = null

  @ViewChild('barChartDona', { static: false }) private chartRef1!: ElementRef

  chartMontoBancario: any
  chartMontoDisponible: any
  chartMontoCredito: any
  base64I: any
  DatosArray = []
  DatosMontoDebito = []
  DatosArrayMontoCredito = []
  DatosMontoCredito = []
  DatosArrayMontoDisponible = []
  DatosMontoDisponible = []
  dataCuentaBancaria = []

  modelo: modeloCuentasBancaria = new modeloCuentasBancaria()
  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService
  ) {}

  ngOnInit(): void {
    this.getAllCuentaBancaria()
    this.GraficaMontoDisponible()
    this.GraficaMontoCredito()
  }

  //Solicitar informacion de base de datos para traer la cuentas y graficar
  getAllCuentaBancaria() {
    this.consolidadoBancarioService.getAllCuentaBancaria().subscribe(
      (data: any) => {
        data.forEach((element) => {
          if (element.tipoMonto === 'montoBancario') {
            this.DatosMontoDebito.push(element.nombreCuenta)
            this.DatosArray.push(element.totalMonto)

          }
        })

        setTimeout(() => {
          const data = {
            labels: this.DatosMontoDebito,
            datasets: [
              {
                label: 'My First Dataset',
                data: this.DatosArray,
                backgroundColor: [
                  'rgb(255, 99, 132)',
                  'rgb(54, 162, 235)',
                  'rgb(255, 205, 86)',
                  'rgb(204, 51, 153)',
                  'rgb(151, 2, 2)',
                  'rgb(178, 84, 222)',
                  'rgb(84, 130, 222)',
                  'rgb(84, 199, 222)',
                  'rgb(84, 222, 144)',
                  'rgb(192, 222, 84)'
                ],
                hoverOffset: 4
              }
            ]
          }
          this.chartMontoBancario = new Chart('myChart1', {
            type: 'doughnut',
            data: data
          })
        }, 50)
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //GRAFICAR LAS CUENTAS
  GraficaMontoCredito() {
    this.consolidadoBancarioService.getAllCuentaBancaria().subscribe(
      (data: any) => {
        data.forEach((element) => {
          if (element.tipoMonto === 'montoCredito') {
            this.DatosMontoCredito.push(element.nombreCuenta)
            this.DatosArrayMontoCredito.push(element.totalMonto)

          }
        })
        setTimeout(() => {
          const data = {
            labels: this.DatosMontoCredito,
            datasets: [
              {
                label: 'My First Dataset',
                data: this.DatosArrayMontoCredito,
                backgroundColor: [
                  'rgb(255, 99, 132)',
                  'rgb(54, 162, 235)',
                  'rgb(255, 205, 86)',
                  'rgb(204, 51, 153)',
                  'rgb(151, 2, 2)',
                  'rgb(178, 84, 222)',
                  'rgb(84, 130, 222)',
                  'rgb(84, 199, 222)',
                  'rgb(84, 222, 144)',
                  'rgb(192, 222, 84)'
                ],
                hoverOffset: 4
              }
            ]
          }
          this.chartMontoDisponible = new Chart('myChart3', {
            type: 'polarArea',
            data: data
          })
        }, 50)
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  GraficaMontoDisponible() {
    this.consolidadoBancarioService.getAllCuentaBancaria().subscribe(
      (data: any) => {
        data.forEach((element) => {
          if (element.tipoMonto === 'montoDisponible') {
            this.DatosMontoDisponible.push(element.nombreCuenta)
            this.DatosArrayMontoDisponible.push(element.totalMonto)
          }
        })
        setTimeout(() => {
          const data = {
            labels: this.DatosMontoDisponible,
            datasets: [
              {
                label: 'My First Dataset',
                data: this.DatosArrayMontoDisponible,
                backgroundColor: [
                  'rgb(255, 99, 132)',
                  'rgb(54, 162, 235)',
                  'rgb(255, 205, 86)',
                  'rgb(204, 51, 153)',
                  'rgb(151, 2, 2)',
                  'rgb(178, 84, 222)',
                  'rgb(84, 130, 222)',
                  'rgb(84, 199, 222)',
                  'rgb(84, 222, 144)',
                  'rgb(192, 222, 84)'
                ]
              }
            ]
          }
          this.chartMontoCredito = new Chart('myChart2', {
            type: 'pie',
            data: data,
            options: {}
          })
        }, 50)
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }
}
