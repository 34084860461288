<h1>Registro de Compra</h1>
<!-- <mat-expansion-panel hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title> Subir Archivo XML </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-card-actions>
    <form action="/upload" method="post" enctype="multipart/form-data">
      <input (change)="capturarArchivo($event)" type="file" id="archivo" />
    </form>
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="subirArchivo()"
    >
      <mat-icon>send</mat-icon> Enviar
    </button>
  </mat-card-actions>
</mat-expansion-panel> -->

<div class="mat-elevation-z8">
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>  Compras Manual  </mat-panel-title>
      <mat-panel-description>
        Para Crear una Compra Presiona Aqui
        <mat-icon>touch_app_white_18dp</mat-icon>
      </mat-panel-description>

    </mat-expansion-panel-header>


    <mat-horizontal-stepper linear>
      <!-- Informacion Basica -->
      <mat-step [stepControl]="infBasica">
        <form [formGroup]="infBasica">
          <ng-template matStepLabel>Informacion Basica</ng-template>
          <!-- Fila 1 -->
          <div class="row">
            <!-- tipo de documento -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Seleccione...</mat-label>
                <select
                  matNativeControl
                  id="miSeleccionId"
                  formControlName="tipoDocumento"
                >
                  <option value="" disabled selected></option>
                  <option value="facturaElectronica">Factura Electronica</option>
                  <option value="facturaNoAfectaOExentaElectronica">
                    Factura no Afecta o Exenta Electronica
                  </option>
                  <option value="notaDebitoElectronica">
                    Nota de Debito Electronica
                  </option>
                  <option value="liquidacionFacturaElectronica">
                    Liquidacion Factura Electronica
                  </option>
                  <option value="facturaCompraElectronica">
                    Factura de Compra Electronica
                  </option>
                  <option value="notaCreditoElectronica">
                    Nota de Credito Electronica
                  </option>
                  <option value="factura">Factura</option>
                  <option value="declaracionIngreso">Declaracion de Ingreso</option>
                </select>
                <mat-icon matSuffix>show_chart_white_18dp</mat-icon>
                <mat-error
                  *ngIf="infBasica.controls['tipoDocumento'].hasError('required')"
                  ><strong> El tipo Documento es requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 2 -->
          <div class="row">
            <!-- proveedor -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-select
                  placeholder="Ingresa Proveedor"
                  formControlName="proveedor"
                >
                <mat-option *ngFor="let proveedor of proveedor" value="{{proveedor.nombre}}">
                  {{proveedor.nombre}}
                </mat-option>
                </mat-select>

                <mat-icon matSuffix>badge_white_18dp</mat-icon>
                <mat-error
                  *ngIf="infBasica.controls['proveedor'].hasError('proveedor')"
                >
                  <strong> El proveedor es requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Direccion -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Direccion</mat-label>
                <input
                  matInput
                  placeholder="Ingresa Direccion"
                  formControlName="direccion"

                />
                <mat-icon matSuffix>home_white_18dp</mat-icon>
                <mat-error
                  *ngIf="infBasica.controls['direccion'].hasError('required')"
                >
                  <strong> La direccion es requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- ciudad -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Ciudad</mat-label>
                <input
                  matInput
                  placeholder="Ingresa ciudad"
                  formControlName="ciudad"
                />
                <mat-icon matSuffix>location_city_white_18dp</mat-icon>
                <mat-error
                  *ngIf="infBasica.controls['ciudad'].hasError('required')"
                >
                  <strong> La ciudad es requerida</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- comuna -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Comuna</mat-label>
                <input
                  matInput
                  placeholder="Ingresa comuna"
                  formControlName="comuna"
                />
                <mat-icon matSuffix>push_pin_white_18dp</mat-icon>
                <mat-error
                  *ngIf="infBasica.controls['comuna'].hasError('required')"
                >
                  <strong> La comuna es requerida</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- boton  de acciones-->
          <div>
            <button mat-button matStepperNext>
              Siguiente <mat-icon>arrow_forward_ios_white_18dp</mat-icon>
            </button>
          </div>
        </form>
      </mat-step>

      <!-- Informacion de Pago  -->
      <mat-step [stepControl]="infPago">
        <form [formGroup]="infPago" novalidate>
          <ng-template matStepLabel>Informacion de Pago</ng-template>

          <!-- Monto Requerido -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Monto Requerido</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Fila 3 -->
            <div class="row">
              <!-- Fecha -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    placeholder="Fecha"
                    formControlName="fechaEmision"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    *ngIf="infPago.controls['fechaEmision'].hasError('required')"
                    >Este campo es <strong>requerido. Presiona el icono</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 4 -->
            <div class="row">
              <!-- iva -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>IVA</mat-label>
                  <input matInput formControlName="iva" placeholder="%" required />
                  <mat-icon matSuffix>price_check_white_18dp</mat-icon>
                  <mat-error *ngIf="infPago.controls['iva'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- folio -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Folio</mat-label>
                  <input
                    matInput
                    formControlName="folio"
                    placeholder="Ingrese Folio"
                    required
                  />
                  <mat-icon matSuffix>fact_check_white_18dp</mat-icon>
                  <mat-error *ngIf="infPago.controls['folio'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Monto Exento -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Monto Exento</mat-label>
                  <input
                    matInput
                    formControlName="montoExento"
                    placeholder="Ingrese Monto Exento"
                    required
                  />
                  <mat-icon matSuffix>monetization_on_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="infPago.controls['montoExento'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 5 -->
            <div class="row">
              <!-- Monto Neto -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Monto Neto</mat-label>
                  <input
                    matInput
                    formControlName="montoNeto"
                    placeholder="Ingrese Monto Neto"
                    required
                  />
                  <mat-icon matSuffix>attach_money_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="infPago.controls['montoNeto'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Monto IVA -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Monto IVA</mat-label>
                  <input
                    matInput
                    formControlName="montoIva"
                    placeholder="Ingrese Monto IVA"
                    required
                  />
                  <mat-icon matSuffix>price_change_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="infPago.controls['montoNeto'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Monto Total -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Monto Total</mat-label>
                  <input
                    matInput
                    formControlName="montoTotal"
                    placeholder="Ingrese Monto Total"
                    required
                  />
                  <mat-icon matSuffix>shopping_cart_checkout_white_18dp</mat-icon>
                  <mat-error
                    *ngIf="infPago.controls['montoNeto'].hasError('required')"
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>

          <!-- Monto No Requerido -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Monto No Requerido</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Fila 6 -->
            <div class="row">
              <!-- factor Proporcionalidad -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Factor Proporcionalidad</mat-label>
                  <input
                    matInput
                    formControlName="factorProporcionalidad"
                    placeholder="Ingrese Factor Proporcionalidad"
                  />
                  <mat-icon matSuffix>price_check_white_18dp</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 7 -->
            <div class="row">
              <!-- monto Activo Fijo -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Monto Activo Fijo</mat-label>
                  <input
                    matInput
                    formControlName="montoActivoFijo"
                    placeholder="Ingrese Monto Activo Fijo"
                  />
                  <mat-icon matSuffix>attach_money_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!-- monto Iva Activo Fijo -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Monto Iva Activo Fijo</mat-label>
                  <input
                    matInput
                    formControlName="montoIvaActivoFijo"
                    placeholder="Ingrese Monto Iva Activo Fijo"
                  />
                  <mat-icon matSuffix>price_change_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!-- Monto Iva Uso Comun -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Monto Total</mat-label>
                  <input
                    matInput
                    formControlName="montoIvaUsoComun"
                    placeholder="Ingrese Monto Iva Uso Comun"
                  />
                  <mat-icon matSuffix>shopping_cart_checkout_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!-- monto Otro Impuesto Sin Credito -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Monto Otro Impuesto Sin Credito</mat-label>
                  <input
                    matInput
                    formControlName="montoOtroImpuestoSinCredito"
                    placeholder="Ingrese Monto Otro Impuesto Sin Credito"
                  />
                  <mat-icon matSuffix>credit_card_white_18dp</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <div>
            <button mat-button matStepperPrevious>
              <mat-icon>arrow_back_ios_new_white_18dp</mat-icon> Anterior
            </button>
            <button mat-button matStepperNext>
              Siguiente <mat-icon>arrow_forward_ios_white_18dp</mat-icon>
            </button>
          </div>
        </form>
      </mat-step>

      <!-- Tabla -->
      <mat-step [stepControl]="tabla">
        <form [formGroup]="tabla" novalidate (ngSubmit)="onSubmit()">
          <ng-template matStepLabel>Tabla de Impuesto </ng-template>

          <!--Tabla de IVA no recuperable -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Tabla de IVA No Recuperable </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Fila 8 -->
            <div class="row">
              <!-- Código impuesto -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Seleccione...</mat-label>
                  <select
                    matNativeControl
                    id="miSeleccionId"
                    formControlName="codigoImpuestoIvaNoRecuperable"
                  >
                    <option value="" disabled selected></option>
                    <option value="comprasIva">
                      Compras destinadas a IVA a generar operaciones no gravadas o
                      exentas
                    </option>
                    <option value="facturaProveedores">
                      Factura de proveedores registradas fuera de plazo
                    </option>
                    <option value="gastosRechazados">Gastos rechazados</option>
                    <option value="entregasGratuitas">
                      Entregas gratis(premios, bonificiones, etc)
                    </option>
                    <option value="otro">Otros</option>
                  </select>
                  <mat-icon matSuffix>show_chart_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!-- monto -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Monto</mat-label>
                  <input
                    matInput
                    formControlName="montoIvaNoRecuperable"
                    placeholder="Monto Iva No Recuperable"
                  />
                  <mat-icon matSuffix>price_change_white_18dp</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <!--Tabla de otros impuestos -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Tabla de otros impuestos</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Fila 9 -->
            <div class="row">
              <!-- Tabla de otros impuestos -->
              <!-- Código impuesto -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Codigo</mat-label>
                  <input
                    matInput
                    formControlName="codigoImpuestoOtrosImpuestos"
                    placeholder="Ingrese codigo"
                  />
                  <mat-icon matSuffix>integration_instructions_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!-- tasa -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Tasa</mat-label>
                  <input
                    matInput
                    formControlName="tasaImpuestoOtrosImpuestos"
                    placeholder="Ingrese Tasa"
                  />
                  <mat-icon matSuffix>price_check_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!-- monto -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <mat-label>Monto</mat-label>
                  <input
                    matInput
                    formControlName="montoImpuestoOtrosImpuestos"
                    placeholder="Ingrese Monto"
                  />
                  <mat-icon matSuffix>attach_money_white_18dp</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- Documento Adicionales -->
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Documento Adicionales</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- comentarios -->
                <mat-form-field appearance="legacy">
                  <mat-label>Comentario</mat-label>
                  <textarea
                    matInput
                    type="string"
                    placeholder="Ingrese comentario"
                    formControlName="comentarios"
                  ></textarea>
                  <mat-icon matSuffix>description_white_18dp</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <br />
          <!-- boton de acciones  -->
          <div>
            <button mat-button matStepperPrevious>
              <mat-icon>arrow_back_ios_new_white_18dp</mat-icon> Anterior
            </button>
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="tabla.invalid"
            >
              <mat-icon>send</mat-icon> Enviar
            </button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>

  </mat-expansion-panel>

</div>


