  export function formatNumber(number: any) {
    let result = ''
    let result2 = ''
    let result3 = ''
    let numberConvert = '',
    
      number_string = ''    
    if (number != '' && number != null) {
      let thousand_separator = '.',
        decimal_separator = ',',
        regex = new RegExp('[^' + decimal_separator + '\\d]', 'g')
      number_string = number.replace(regex, '').toString()
      let split = number_string.split(decimal_separator),
        rest = split[0].length % 3
      result = split[0].substr(0, rest)
      let thousands = split[0].substr(rest).match(/\d{3}/g)
      if (thousands) {
        let separator = rest ? thousand_separator : ''
        result += separator + thousands.join(thousand_separator)
      }
      result =
        split[1] != undefined ? result + decimal_separator + split[1] : result
    }
    result2 = result
    result3 = result
    numberConvert = number_string
    return  result
  }

  export function restrictNumeric(e: any) {
    let input
    if (e.metaKey || e.ctrlKey) {
      return true
    }
    if (e.which === 32) {
      return false
    }
    if (e.which === 0) {
      return true
    }
    if (e.which < 33) {
      return true
    }
    input = String.fromCharCode(e.which)
    return !!/[\d\s]/.test(input)
  }
