<button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
    <mat-icon>highlight_off</mat-icon>
</button>
<form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
    <mat-card class="shipping-card">
        <mat-card-header>
            <h1>{{ nombreCategoria }}</h1>
        </mat-card-header>
        <br><br>
        <mat-card-content>
            <div class="row">
                <!-- Nombre Categoria -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Nombre" formControlName="nombre" />
                        <mat-error *ngIf="addressForm.controls['nombre'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Estado Categoria -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Seleccione" formControlName="estado">
                            <mat-option value="Vigente"> Vigente </mat-option>
                            <mat-option value="No Vigente"> No Vigente </mat-option>
                        </mat-select>
                        <mat-error *ngIf="addressForm.controls['estado'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>

        <!-- botón enviar -->
        <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon> Enviar
        </button>
    </mat-card>
</form>
