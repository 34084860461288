import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router';
import { Observable, observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { Cesionario } from '@app/_models/cesionarios/cesionario';
import { DialogCesionarioEditarComponent } from "./configuracion-cesionarios-list/dialog-cesionarios-editar/dialog-cesionario-editar.component";

/* Imports Excel */
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'
/* Fin Import Excel */

@Injectable({
  providedIn: 'root'
})

export class ConfiguracionService {

  private cesionario_response$ = new Subject<any>();
  
  constructor(private router: Router, private http: HttpClient, public dialog: MatDialog) { }


  addResultCesionario(termino: any){
    this.cesionario_response$.next(termino)
  }

  getResultCesionario() : Observable<any>{
    return this.cesionario_response$.asObservable()
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditSucursal(): void {
      const dialogRef = this.dialog.open(DialogCesionarioEditarComponent, {})
      dialogRef.afterClosed().subscribe((res) => {})
  }

  // Cerrar dialog Repactar Cuota y Registrar Pago
  closeDialogModal() {
      this.dialog.closeAll()
      //localStorage.removeItem("idContratoPago");
  }

  cesionariosGetAll(): any {
    return this.http.get<Cesionario[]>(`${environment.apiUrl}/cesionario`);
  }

  getById(id: string) {
    return this.http.get<Cesionario>(`${environment.apiUrl}/cesionario/${id}`);
  }

  getByRut(rut: string) {
    return this.http.get<Cesionario>(`${environment.apiUrl}/cesionario/${rut}`);
  }

  create(cesionario: Cesionario) {
    
    return this.http.post(`${environment.apiUrl}/cesionario`, cesionario);
  }

  update(id: number, params: any[]) {
    return this.http.put(`${environment.apiUrl}/cesionario/${id}`, params);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/cesionario/${id}`);
  }
 
}


