import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { IngresoRentacar } from '@app/_models/rentacar/ingresoRentacar';
import { AdministracionService } from '@app/_pages/administracion/administracion.service';
import { RentacarService } from '@app/_pages/rentacar/rentacar.service';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';
import { Sucursal } from '@app/_models/shared/sucursal'

import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatCheckboxChange } from '@angular/material/checkbox'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'

@Component({
  selector: 'app-ingreso-egreso-acciones',
  templateUrl: './ingreso-egreso-acciones.component.html',
  styleUrls: ['./ingreso-egreso-acciones.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class IngresoEgresoAccionesComponent implements OnInit {

  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any

  idIngresoRentacar: any
  fecha: any
  cliente: any
  tipoCliente: any
  tipoPago: any
  estadoPago: any
  referencia:any
  nDocumento: any
  nAutorizacion: any

  tipoIngreso: any
  tipoDocumento: any
  descripcionIngreso: any
  monto: any
  Servicios: any

  tiposCliente: string[]
  estadoPagos: string[]
  tiposIngreso:string[] = []
  tiposDocumentos:string[] = []
  sucursales: Sucursal[] = []
  tipoServicios: any[] = []
  referencias: string[]= []
  tiposPagos: string[]= []



  dataArrayImg = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  //Modelo de Ingreso de rentacar
  modelo: IngresoRentacar = new IngresoRentacar()


  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private rentacarService: RentacarService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private sucursalService: SucursalSharedService,

  ) {
    

    this.tiposCliente = ['Particular', 'Empresa']
    this.estadoPagos = ['PENDIENTE', 'PAGADO']
    this.tiposIngreso = ['Venta', 'Arriendo','Licitacion']
    this.referencias = ['Llamada', 'Booking', 'Correo', 'PaginaWeb', 'Facebook']
    this.tiposDocumentos = ['Factura','Boleta']
    this.tiposPagos = [
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Credito',
      'Transferencia',
      'Cheque',
      'Efectivo'
    ]
    this.sucursales = this.sucursalService.sucursalListValue;

   }

  ngOnInit(): void {
    this.idIngresoRentacar = localStorage.getItem('idIngresoRentacar')
    this.getIngresoRentacar()
   
  }


  //Configuracion de formulario
  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    tipoIngreso: ['', Validators.required],
    descripcionIngreso: ['', Validators.required],
    archivos: null,
    monto: ['', Validators.required],



    cliente: ['', Validators.required],
    tipoCliente: ['', Validators.required],
    tipoPago: ['', Validators.required],
    estadoPago: ['', Validators.required],
    idSucursal: ['', Validators.required],
    referencia: ['', Validators.required],
    nDocumento: ['', Validators.required],
    Servicios: ['', Validators.required],
    nAutorizacion: ['', Validators.required],
    tipoDocumento: ['', Validators.required],

  })
  get f() {
    return this.addressForm.controls
  }

  getIngresoRentacar() {
    this.rentacarService
      .getByIdIngreso(this.idIngresoRentacar)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idIngresoRentacar) {
          this.f.fecha.setValue(x[0].fecha.slice(0, 10))
          this.f.tipoIngreso.setValue(x[0].tipoIngreso)
          this.f.descripcionIngreso.setValue(x[0].descripcionIngreso)
          this.f.archivos.setValue(x[0].archivos)
          this.f.monto.setValue(x[0].monto)

          this.f.cliente.setValue(x[0].cliente)
          this.f.tipoCliente.setValue(x[0].tipoCliente)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.estadoPago.setValue(x[0].estadoPago)
          this.f.idSucursal.setValue(x[0].idSucursal)
          this.f.referencia.setValue(x[0].referenciaCliente)
          this.f.nDocumento.setValue(x[0].nDocumento)
          this.f.Servicios.setValue(x[0].Servicios)
          this.f.tipoDocumento.setValue(x[0].tipoDocumento)
          this.f.nAutorizacion.setValue(x[0].nAutorizacion)

        }
        this.previsualizacion = x[0].archivos
      })
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.rentacarService.closeDialogModal()
    window.location.reload()
  }

  //Capturar imagen
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop() // Obtener la extensión del archivo original
    const nuevoNombre = "IngresoRentacar" + Date.now() + "." + extension // Crear un nombre único con el identificador generado dinámicamente
    this.dataArrayImg.push(nuevoNombre) // Agregar el nuevo nombre al array de nombres de archivo
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, { type: archivoCapturado.type }) // Crear un nuevo objeto de tipo File con el nuevo nombre
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }

  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/ingresoRentacar/Upload/RentaACarIngreso`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }




  //Actualizar formulario
  onSubmit() {
    if (confirm('Esta seguro que desea actualizar el Ingreso de Rentacar: ')) {
      this.rentacarService.getByIdIngresoSinBase64(this.idIngresoRentacar)
      
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idIngresoRentacar) {
            const nombreArchivo = x[0].archivos
            const JsonNombreArchivo = JSON.parse(nombreArchivo);
            if (this.dataArrayImg[0] === undefined) {
              this.addressForm.value.archivos = JsonNombreArchivo;
              
            } else {
              this.addressForm.value.archivos = this.dataArrayImg          
            }
            this.addressForm.value.referenciaCliente = this.addressForm.value.referencia;

         
            switch (this.addressForm.status) {
              case 'VALID':
                 this.subirIMG()
                this.rentacarService                
                  .updateIngresoRentacar(this.idIngresoRentacar, this.addressForm.value)
                  .pipe(first())
                  .subscribe(
                    (data) => {
                      this.snackBar.open('Ingreso de Rentacar editado con éxito.', 'Cerrar', {
                        duration: 2000,
                        verticalPosition: 'top'
                      })

                      this.addressForm.reset()
                      this.rentacarService.closeDialogModal()
                      window.location.reload()
                    },
                    (error) => {
                      this.snackBar.open(
                        'No se pudo editar el Ingreso de Rentacar, favor contactar a informática.',
                        'Cerrar',
                        {
                          duration: 2000,
                          verticalPosition: 'top'
                        }
                      )
                    }
                  )
                break

              case 'INVALID':
                this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                break

              default:
                break
            }
          }
        })
    }
  }
  

  //Actualizar formulario
  AgregarIMG() {
    if (confirm('Esta seguro que desea agregar IMG el Ingreso de Rentacar: ')) {
      this.rentacarService.getByIdIngresoSinBase64(this.idIngresoRentacar)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idIngresoRentacar) {
            const data = x[0]
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.monto = data.monto
            this.addressForm.value.tipoIngreso = data.tipoIngreso
            this.addressForm.value.descripcionIngreso = data.descripcionIngreso
            this.addressForm.value.archivos = this.dataArrayImg
            //this.addressForm.value.referencia = data.referenciaCliente

            this.rentacarService
            .updateIngresoRentacar(this.idIngresoRentacar, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Ingreso de Rentacar editado con éxito.', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })

                this.addressForm.reset()
                this.rentacarService.closeDialogModal()
                window.location.reload()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar el Ingreso de Rentacar, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )

          }
        })
    }
  }
}