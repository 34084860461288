import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { CategoriasService } from '../../categorias.service';
import { Categoria } from '../../../../_models/categoria/categoria';

@Component({
  selector: 'app-dialog-categorias-editar',
  templateUrl: './dialog-categorias-editar.component.html',
  styleUrls: ['./dialog-categorias-editar.component.scss']
})
export class DialogCategoriasEditarComponent implements OnInit {


  formularioListo = new EventEmitter<string>()
  idCategoria : any;
  nombreCategoria: any;
  estadoCategoria: any;
  categorias: any;
  dataCategoria: any;

  addressForm = this.fb.group({
    idCategoria: ['', Validators.required],
    nombre: ['', Validators.required],
    estado: ['',Validators.required],
  })

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private categoriasService: CategoriasService,
  ) { }

  ngOnInit(): void {
    this.idCategoria = localStorage.getItem("idCategoriasEdit");
    this.nombreCategoria = localStorage.getItem("nombreEdit");
    this.getCategoria();
  }

  get f() {
    return this.addressForm.controls;
  }

    // Obtener la categoria que se quiere editar
    getCategoria() {
      //Carga Tabla 
      this.categoriasService.categoriaGet().pipe(first()).subscribe((result: Categoria[]) => {
        this.dataCategoria = result.map(Categoria => {
          return Categoria;
        });
        // Obtener la categoria que se quiere editar y asignar sus valores al formulario
        this.dataCategoria.forEach((x:any) => {
          if(x.id == this.idCategoria){
            this.f.nombre.setValue(x.nombre);
            this.f.estado.setValue(x.estado);
          }
        });
      });
    }


    // Metodo editar categoria
  onSubmit(){
    this.categoriasService
    .updateCategoria(this.idCategoria, this.addressForm.value)
    .pipe(first())
    .subscribe(
      (data) => {
        this.snackBar.open('Categoria actualizada con exito', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
        this.categoriasService.closeDialogModal();
      },
      (error) => {
        this.snackBar.open(error, 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
      }
    );
  }

  //Cerrar Modal
  closeDialog(){
    this.categoriasService.closeDialogModal();
  }

}
