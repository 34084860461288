import { ProveedoresService } from './../../proveedores/proveedores.service'
import { Component, OnInit } from '@angular/core'
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { BodegaService } from '@app/_pages/inventario/bodega/bodega.service'
import { debounceTime, first } from 'rxjs/operators'
import { combineLatest } from 'rxjs'

import { AjusteInventario } from '@app/_models/ajusteInventario/ajusteInventario'
import { AjusteInventarioService } from '../ajusteInventario.service'
import { ProductosService } from '@app/_pages/productos/productos.service'
import { AjusteProducto } from '@app/_models/ajusteProducto/ajusteProducto'
import { AjusteProductoService } from '@app/_pages/ajusteProducto/ajusteProducto.service'
import { ThirdPartyDraggable } from '@fullcalendar/interaction'
import { MatFormFieldModule } from '@angular/material/form-field'
import { StockNew } from '@app/_models/stocks/stockNew'

@Component({
  selector: 'app-ajuste-inventario-form',
  templateUrl: './ajuste-inventario-form.component.html',
  styleUrls: ['./ajuste-inventario-form.component.scss']
})
export class AjusteInventarioFormComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private bodegasService: BodegaService,
    private proveedoresService: ProveedoresService,
    private ajusteInventarioService: AjusteInventarioService,
    private productosService: ProductosService,
    private ajusteProductoService: AjusteProductoService
  ) {}

  stock: number[] = []
  cantidadHabilitada: Boolean = false
  stockFormControl: FormControl
  selectedProductForm: FormGroup

  ajusteInventario: AjusteInventario = new AjusteInventario()
  ajusteProducto: AjusteProducto = new AjusteProducto()
  stockNew: StockNew = new StockNew()

  bodegas: any
  proveedores: any
  Costo: number[] = []
  ajuste: number[] = []
  subtotal: number[] = []
  idAjusteInventario: number
  idAjuste: any
  dataAjusteInventario: any
  total: number = 0
  //selectedOptionIdProduct: number;

  selectedOptionIdProducts: any[] = []
  lista_productos: any

  productosDisponibles: any[] = []

  copiProductosDisponibles: any[] = []

  ngOnInit(): void {
    this.getBodegas()
    this.getProveedores()
    this.getProducts()
    this.stockFormControl = new FormControl(this.stock)
  }

  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    documento: ['', Validators.required],
    bodega: ['', Validators.required],
    observacion: ['', Validators.required],
    proveedor: ['', Validators.required]
  })

  addressForm2 = this.fb.group({
    productos: this.fb.array([])
  })

  get productos() {
    return this.addressForm2.get('productos') as FormArray
  }

  private createProductoForm(): FormGroup {
    return this.fb.group({
      formularioId: this.productos.length,
      productosSeleccionados: ['', Validators.required],
      ubicacion: ['', Validators.required],
      cantidad: ['', Validators.required],
      costo: [''],
      subtotal: ['', Validators.required],
      stock: ['', Validators.required],
      ajuste: ['', Validators.required],
      idAjusteInventario: [],
      productosDisponibles: [...this.productosDisponibles]
    })
  }
  onInputSelected(event: any) {
    const formularioIndex = event.target.getAttribute('data-formulario-index')
  }

  i: number = 0

  agregarProducto() {
    const productoForm = this.createProductoForm()
    this.productos.push(productoForm)

    // //  EXTRAER PRODUCTO SELECCIONADO
    //   this.productosDisponibles = this.productosDisponibles.filter((producto) => {

    //     const selectedOptionIdNumbers = this.selectedOptionIdProducts.map(id => Number(id));

    //     const shouldKeep = !selectedOptionIdNumbers.includes(producto.id);

    //     return shouldKeep;

    //   });

    // console.log("Despues de ",productoForm.get('productosDisponibles').value);
    // console.log("this.productos",this.productosDisponibles);

    //para abajo no tocar
    const controlBodega = this.addressForm.get('bodega')

    this.selectedProductForm = productoForm

    const controlProductosSeleccionados = productoForm.get(
      'productosSeleccionados'
    )
    const x = productoForm.get('productosDisponibles').value
    //console.log("esto es x ",x);

    controlProductosSeleccionados.setValue('')
    controlProductosSeleccionados.valueChanges.subscribe(
      (valorProductosSeleccionados) => {
        if (valorProductosSeleccionados && valorProductosSeleccionados !== '') {
          if (controlBodega.value) {
            this.selectedProductForm = productoForm
            this.getStock(
              this.addressForm.value.bodega,
              valorProductosSeleccionados,
              this.selectedProductForm
            )
          } else {
            this.snackBar.open(
              'Debe seleccionar una bodega para saber el stock del producto',
              'Cerrar',
              {
                duration: 2000,
                verticalPosition: 'top'
              }
            )
            this.showBodegaError = true
          }
        }
      }
    )
  }

  showBodegaError: boolean = false
  addButton: boolean = false

  onBodegaChange() {
    const bodegaControl = this.addressForm.get('bodega')
    const productosFormArray = this.addressForm2.get('productos') as FormArray

    if (bodegaControl.value) {
       this.addButton = true
      this.selectedProductForm = productosFormArray.at(0) as FormGroup
      if (this.showBodegaError) {
        const valorProductosSeleccionados = this.selectedProductForm.get(
          'productosSeleccionados'
        )?.value
        // this.getStock(
        //   this.addressForm.value.bodega,
        //   valorProductosSeleccionados,
        //   this.selectedProductForm
        // )
        // Restablece la bandera de error a false
        this.showBodegaError = false
        this.addButton = true
      }
    } else {
      this.snackBar.open(
        'Debe seleccionar una bodega para saber el stock del producto',
        'Cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
      // Establece la bandera de error en true
      this.showBodegaError = true
    }
  }

  validarFormProductos(): boolean {
    for (let i = 0; i < this.productos.value.length; i++) {
      const productoForm = this.productos.controls[i] as FormGroup
      if (!productoForm.valid) {
        return false
      }
    }
    return true
  }

  calcularSubtotal(i: number) {
    const selectedProductId = this.selectedOptionIdProducts[i]

    this.productosService.productoGetId(selectedProductId).subscribe((res) => {
      this.Costo[i] = res.neto
      this.subtotal[i] = this.productos.value[i].cantidad * this.Costo[i]
      this.ajuste[i] = this.productos.value[i].cantidad
    })
  }

  getStock(bodegaValue: any, productosValue: any, productForm: FormGroup) {
    this.ajusteProductoService.getStock(productosValue, bodegaValue).subscribe(
      (response) => {
        if (response.stock === null) productForm.get('stock').patchValue(0)
        else {
          productForm.get('stock').patchValue(response.stock)
        } //seteamos el valor del stock
        //
        const controlCantidad = productForm.get('cantidad')
        const stockValue = productForm.get('stock').value

        controlCantidad.valueChanges.subscribe((valorCantidad) => {
          const ajusteActual = parseInt(stockValue) + parseInt(valorCantidad)
          productForm.get('ajuste').patchValue(ajusteActual)
        })
      },
      (error) => {
        console.error('Error al obtener el stock:', error)
      }
    )

    // Verificar si el campo producto existe antes de suscribirse a valueChanges

    const productoControl = productForm.get('productosSeleccionados')
    if (productoControl) {
      productoControl.valueChanges.subscribe(() => {
        productForm.get('cantidad').reset()
        productForm.get('ajuste').reset()
      })
    }
  }

  onSubmit() {
    if (this.productos.length > 0) {
      if (this.addressForm.valid && this.validarFormProductos()) {
        this.ajusteInventario.tipoAjuste = 'entrada'
        this.ajusteInventario.fechaEmision = this.addressForm.value.fecha
        this.ajusteInventario.numeroDocumento = this.addressForm.value.documento
        this.ajusteInventario.idBodega = this.addressForm.value.bodega
        this.ajusteInventario.observacion = this.addressForm.value.observacion
        this.ajusteInventario.idProveedor = this.addressForm.value.proveedor
        this.ajusteInventario.estado = 'valido'
        this.ajusteInventarioService
          .create(this.ajusteInventario)
          .pipe()
          .subscribe(
            (data) => {
              //DATA
              const ajusteInventario = data.data
              //ID_AJUSTE
              this.idAjusteInventario = ajusteInventario.id
              //SUCCESS
              const success = data.success

              //INSERCION DE PRODUCTOS
              if (success) {
                for (let i = 0; i < this.productos.value.length; i++) {
                  this.ajusteProducto.idAjusteInventario =
                    this.idAjusteInventario
                  this.ajusteProducto.idProducto =
                    this.productos.value[i].productosSeleccionados
                  this.ajusteProducto.cantidad =
                    this.productos.value[i].cantidad
                  this.ajusteProducto.ajuste = this.productos.value[i].ajuste
                  this.ajusteProducto.ubicacion =
                    this.productos.value[i].ubicacion
                  this.ajusteProducto.subtotal =
                    this.productos.value[i].subtotal
                  // this.ajusteProducto.idAjusteInventario = this.productos.value[i].idAjusteInventario;

                  //* metodo nuevo
                  this.stockNew.fecha = this.addressForm.value.fecha
                  this.stockNew.ndocumento = this.addressForm.value.documento
                  this.stockNew.idBodega = parseInt( this.addressForm.value.bodega,10)
                  this.stockNew.observacion = this.addressForm.value.observacion
                  this.stockNew.idProveedor = this.addressForm.value.proveedor
                  this.stockNew.ubicacion = this.productos.value[i].ubicacion
                  this.stockNew.tipoAjuste = 'entrada'
                  this.stockNew.idProducto = parseInt( this.productos.value[i].productosSeleccionados,10)
                  this.stockNew.cantidad = this.productos.value[i].cantidad
                  this.stockNew.costoUnitario = this.productos.value[i].costo
                  this.stockNew.subTotal = this.productos.value[i].subtotal
                  this.stockNew.stockActual = this.productos.value[i].ajuste
                  this.stockNew.estado = 'valido'
                  this.stockNew.idAjusteInventario = this.idAjusteInventario
                  // this.stockNew.estadoActual = 'VIGENTE ACTUAL'
                  


                  this.ajusteInventarioService
                    .createNewStock(this.stockNew)
                    .pipe()
                    .subscribe(
                      (data) => {
                        this.snackBar.open(
                          'Ajuste Producto ingresado con exito',
                          'cerrar',
                          {
                            duration: 2000,
                            verticalPosition: 'top'
                          }
                        )
                      },
                      (error) => {
                        this.snackBar.open(
                          'No se pudo ingresar la variante, contacte con informatica',
                          'cerrar',
                          {
                            duration: 2000,
                            verticalPosition: 'top'
                          }
                        )
                      }
                    )

                  this.ajusteProductoService
                    .createAjusteProducto(this.ajusteProducto)
                    .pipe()
                    .subscribe(
                      (data) => {
                        this.snackBar.open(
                          'Ajuste Producto ingresado con exito',
                          'cerrar',
                          {
                            duration: 2000,
                            verticalPosition: 'top'
                          }
                        )
                      },
                      (error) => {
                        this.snackBar.open(
                          'No se pudo ingresar la variante, contacte con informatica',
                          'cerrar',
                          {
                            duration: 2000,
                            verticalPosition: 'top'
                          }
                        )
                      }
                    )
                }
                this.addressForm2.reset()

                this.addressForm.reset()
                this.productos.clear()
                this.agregarProducto()
              }
              this.snackBar.open(
                'Ajuste Inventario ingresado con exito',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              this.addressForm.reset()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo ingresar el ajuste de inventario, contacte con informatica',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
            }
          )
      } else {
        this.snackBar.open(
          'El formulario debe ser completado correctamente',
          'Cerrar',
          {
            duration: 2000,
            verticalPosition: 'top'
          }
        )
      }
    } else {
      this.snackBar.open('No ha ingresado ningún producto', 'cerrar', {
        duration: 3000,
        verticalPosition: 'top'
      })
    }
  }

  getProducts() {
    this.productosService.productoGet().subscribe((res) => {
      this.lista_productos = res
      this.productosDisponibles = [...this.lista_productos]

      this.agregarProducto()
    })
  }

  getBodegas() {
    this.bodegasService
      .bodegasGetAll()
      .pipe(first())
      .subscribe((bodegas) => {
        this.bodegas = bodegas
      })
  }

  eliminarProducto(indice: number) {
    this.productos.removeAt(indice)
  }
  getProveedores() {
    this.proveedoresService
      .getAllProveedores()
      .pipe(first())
      .subscribe((proveedores) => {
        this.proveedores = proveedores
      })
  }
}
