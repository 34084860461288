import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NetworkStatusService } from '../networkStatus.service';

@Component({
  selector: 'app-networkStatus',
  templateUrl: './networkStatus.component.html',
  styleUrls: ['./networkStatus.component.scss']
})
export class NetworkStatusComponent implements OnInit {

  isOnline: boolean = true;
  private networkStatusSubscription!: Subscription;

  constructor(private networkStatusService: NetworkStatusService) { }

  ngOnInit() {
    this.networkStatusSubscription = this.networkStatusService.getNetworkStatus().subscribe(status => {
      this.isOnline = status;
    });
  }

  ngOnDestroy() {
    if (this.networkStatusSubscription) {
      this.networkStatusSubscription.unsubscribe();
    }
  }

}
