<!-- Modal de carga -->
<div *ngIf="isLoading" class="modal-overlay">
    <div class="modal-content">
      <div class="spinner"></div>
      <p>Consultando al SII cesiones pendientes</p>
    </div>
</div>
<div class="filtros">
    <div class="btn-filtro">
        <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="bold">Enviados</mat-button-toggle>
            <mat-button-toggle value="italic">Aceptados</mat-button-toggle>
            <mat-button-toggle value="underline">Rechazado</mat-button-toggle>
            <mat-button-toggle value="underline">Todo</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="btn-search">
        <mat-form-field appearance="fill">
            <mat-label>Filtrar por</mat-label>
                <mat-select (selectionChange)="changeClient($event.value)">
                    <mat-option *ngFor="let filtro of filtros" [value]="filtro.value">
                    {{filtro.viewValue}}
                    </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" class="full-width">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="" #input autocomplete="off">
        </mat-form-field>
    </div>
    {{ criterio }} {{ buscar }}
</div>

<div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">
        <!-- check column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <!-- data columns -->
        <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef>Cliente </th>
            <td mat-cell *matCellDef="let element" >
                <div class="td-info">
                    <span class="td-info-first" [matTooltip]="element.Cliente.alias">{{ element.Cliente.alias }}</span>
                    <span class="td-info-second">{{ element.Cliente.rut }}</span>
                </div>
            </td>
        </ng-container>

        <!-- Sucursal -->
        <ng-container matColumnDef="folio">
            <th mat-header-cell *matHeaderCellDef>Folio</th>
            <td mat-cell *matCellDef="let element">
                <div class="td-info">
                    <span class="td-info-first">{{ element.Factura.folio }}</span>
                    <span class="td-info-second">Factura electrónica</span>
                </div>
            </td>
        </ng-container>
        
        <!-- Nombre -->
        <ng-container matColumnDef="cesionario">
            <th mat-header-cell *matHeaderCellDef>Cesionario</th>
            <td mat-cell *matCellDef="let element">
                <div class="td-info">
                    <span class="td-info-first" [matTooltip]="element.Cesionario.nombre">{{ element.Cesionario.nombre }}</span>
                    <span class="td-info-second">{{ element.Cesionario.rut }}</span>
                </div>
            </td>
        </ng-container>

        <!-- estado -->
        <ng-container matColumnDef="monto_cesion">
            <th mat-header-cell *matHeaderCellDef>Monto Cesion</th>
            <td mat-cell *matCellDef="let element">
                <div class="td-info">
                    <span class="td-info-first">{{ element.montoCesion }}</span>
                    <span class="td-info-second">{{ element.createdAt | date }}</span>
                </div>
            </td>
        </ng-container>

         <!-- Dirección -->
         <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element.estado }}</span>
            </td>
        </ng-container>
     
        <!-- Botones -->
        <ng-container matColumnDef="botones">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let element">
                <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color">
                    <mat-button-toggle value="green" (click)="openDialogEdit(element.id)" matTooltip="ver">
                        <mat-icon>remove_red_eye</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No existen coincidencias</td>
        </tr>
    </table>
</div>

<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>