<mat-tab-group *ngIf="!verPDF" mat-align-tabs="center">
  <mat-tab label="Guía Despacho">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <hr />

        <mat-card-content>
          <mat-card>
            <h2>Informacion de la guía de despacho</h2>
            <hr />
            <!-- Fila 1 -->
            <div class="row">
              <!--Cliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Cliente"
                    formControlName="cliente"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>person</mat-icon> <!-- Icono de persona -->
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Rut Cliente"
                    formControlName="rutCliente"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>badge</mat-icon> <!-- Icono de identificación -->
                </mat-form-field>
              </div>
              <!--Estado -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Estado"
                    formControlName="estado"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>check_circle</mat-icon> <!-- Icono de estado -->
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- Vendedor -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Vendedor"
                    formControlName="vendedor"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>store</mat-icon> <!-- Icono de tienda -->
                </mat-form-field>
              </div>

              <!-- Folio -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Folio"
                    formControlName="folio"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>receipt</mat-icon> <!-- Icono de recibo -->
                </mat-form-field>
              </div>

              <!-- Total -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Total"
                    formControlName="totalGeneral"
                    readonly="readonly"
                  />
                  <span matPrefix>$&nbsp;</span>
                  <mat-icon matSuffix>attach_money</mat-icon> <!-- Icono de dinero -->
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <!-- Bodega -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Bodega"
                    formControlName="bodega"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>inventory_2</mat-icon> <!-- Icono de inventario -->
                </mat-form-field>
              </div>

              <!-- Plazo entrega -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Plazo entrega"
                    formControlName="plazoEntrega"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>calendar_today</mat-icon> <!-- Icono de calendario -->
                </mat-form-field>
              </div>

              <!-- Fecha creación -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Fecha creación"
                    formControlName="fechaCreacion"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>event</mat-icon> <!-- Icono de evento/fecha -->
                </mat-form-field>
              </div>

            </div>
            <div class="row">
              <!-- Forma pago -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Forma pago"
                    formControlName="formaPago"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>credit_card</mat-icon> <!-- Icono de tarjeta de crédito -->
                </mat-form-field>
              </div>

              <!-- Moneda -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Moneda"
                    formControlName="moneda"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>attach_money</mat-icon> <!-- Icono de dinero -->
                </mat-form-field>
              </div>

              <!-- Tiempo válido de pago -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Tiempo válido de pago"
                    formControlName="validaPago"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>schedule</mat-icon> <!-- Icono de reloj -->
                </mat-form-field>
              </div>

              <!-- Observaciones de pago -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Observaciones de pago"
                    formControlName="observacionPago"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>note</mat-icon> <!-- Icono de nota -->
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <!-- Comentarios -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Comentarios"
                    formControlName="comentarios"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>comment</mat-icon> <!-- Icono de comentario -->
                </mat-form-field>
              </div>

              <!-- Track ID -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="TrackId SII"
                    formControlName="trackId"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>track_changes</mat-icon> <!-- Icono de seguimiento -->
                </mat-form-field>
              </div>

              <!-- Razón rechazado -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Razón rechazado"
                    formControlName="razonRechazado"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>report_problem</mat-icon> <!-- Icono de problema/report -->
                </mat-form-field>
              </div>
            </div>
          </mat-card>
          <mat-card-actions>
            <button
              mat-stroked-button
              color="accent"
              style="margin-left: 1rem; margin-bottom: 3px"
              (click)="closeDialog()"
            >
              <mat-icon>cancel</mat-icon>Cancelar <!-- Icono de cancelar -->
            </button>
            <button
              mat-stroked-button
              color="warn"
              style="margin-left: 1rem; margin-bottom: 3px"
              (click)="ImprimirPdfDespacho()"
            >
              Ver
              <mat-icon>picture_as_pdf</mat-icon> <!-- Icono de PDF -->
            </button>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>
    </form>
  </mat-tab>

  <mat-tab label="Información Transportista">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <h1>Informacion de transporte</h1>
        <hr />

        <mat-card-content>
          <mat-card>
            <hr />
            <!-- Fila 1 -->
            <div class="row">
              <!-- Rut transportista -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Rut Transportista"
                    formControlName="rutTransportista"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>badge</mat-icon> <!-- Icono de identificación -->
                </mat-form-field>
              </div>
              <!-- Rut chofer -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Rut Chofer"
                    formControlName="rutChofer"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>badge</mat-icon> <!-- Icono de identificación -->
                </mat-form-field>
              </div>

              <!-- Nombre chofer -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Nombre Chofer"
                    formControlName="nombreChofer"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>person</mat-icon> <!-- Icono de persona -->
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- Dirección destino -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Direccion Destino"
                    formControlName="direccionDestino"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>location_on</mat-icon> <!-- Icono de ubicación -->
                </mat-form-field>
              </div>

              <!-- Comuna destino -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Comuna Destino"
                    formControlName="comunaDestino"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>location_city</mat-icon> <!-- Icono de ciudad -->
                </mat-form-field>
              </div>

              <!-- Ciudad destino -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Ciudad Destino"
                    formControlName="ciudadDestino"
                    readonly="readonly"
                  />
                  <mat-icon matSuffix>location_city</mat-icon> <!-- Icono de ciudad -->
                </mat-form-field>
              </div>
            </div>
          </mat-card>
          <mat-card-actions>
            <button
              mat-stroked-button
              color="accent"
              style="margin-left: 1rem; margin-bottom: 3px"
              (click)="closeDialog()"
            >
              <mat-icon>cancel</mat-icon>Cancelar <!-- Icono de cancelar -->
            </button>
            <button
              mat-stroked-button
              color="warn"
              style="margin-left: 1rem; margin-bottom: 3px"
              (click)="ImprimirPdfDespacho()"
            >
              Ver
              <mat-icon>picture_as_pdf</mat-icon> <!-- Icono de PDF -->
            </button>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>


<div *ngIf="verPDF" class="modal-overlay2">
  <div *ngIf="verPDF" class="modal-content2">
    <!-- Mostrar PDF solo si hay una URL y el estado es 'RECHAZADO' -->
    <div *ngIf="pdfUrl" class="pdf-container">
      <iframe [src]="pdfUrl" class="iframe-pdf"></iframe>
      <button class="btn-cerrar" (click)="cerrarModal()">Cerrar</button>

    </div>
  </div>
  
</div>
  