import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'
export class EgresoPersonal {
  id!: string
  fecha!: Date
  monto!: number
  tipoPago!: string
  tipoEgreso!: string
  descripcion!: string
  responsable!: string
  grupoGastos!: string
  archivos!: any

  Sucursal!: Sucursal
  Usuario!: Usuario
  gastosFijos!: string
  idSucursal!: number
  idUsuario!: number
  sucursal!: string;
  banco!: string;
  numeroCuenta!: string;
}
