import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { modeloFolio } from '@app/_models/folio/folio'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { FolioService } from '@app/_pages/folio/folio.service'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-folio-disponible',
  templateUrl: './folio-disponible.component.html',
  styleUrls: ['./folio-disponible.component.scss']
})

export class FolioDisponibleComponent implements OnInit {
  displayedColumns: string[] = ['id','folioSiguiente', 'primerFolio', 'ultimoFolio', 'fechaAutorizacion', 'fechaVencimiento', 'foliosDisponibles', 'estado'];
  dataSource = [];
  idDocumentoCaf: any
  idDteTipo: any;
  totalDisponible: any
  //Modelo de folio
  modelo: modeloFolio = new modeloFolio()

  //Configuracion de formulario
  addressForm = this.fb.group({
    totalDisponible: [{value : '',disabled: true }],
    tipoDocumento: [{value : '',disabled: true }],
    alerta: [{value : '',disabled: true }],
    codigo: [{value : '',disabled: true }],
  })

  constructor(
    private fb: FormBuilder,
    private folioService: FolioService,
    private snackBar: MatSnackBar,
    private administracionService: AdministracionService
  ) {}

  ngOnInit(): void {
    this.idDocumentoCaf = localStorage.getItem('idFolio');
    this.idDteTipo = localStorage.getItem('idDteTipo');
    this.getCaf();
    this.getCafsCodigo();
  }

  get f() {
    return this.addressForm.controls
  }

  getCaf() {
    this.folioService
      .getById(this.idDocumentoCaf)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idDocumentoCaf) {
          this.f.totalDisponible.setValue(x[0].foliosDisponibles)
          this.f.tipoDocumento.setValue(x[0].DteTipo.nombre)
          this.f.alerta.setValue(x[0].alerta)
          this.f.codigo.setValue(x[0].idDteTipo)
        }
      })
  }

  getCafsCodigo() {
    this.folioService
      .getByCodigoCafs(this.idDteTipo)
      .pipe(first())
      .subscribe((x: any) => {
        this.dataSource = x;
      })
  }

  onSubmit() {
    switch (this.addressForm.status) {
      case 'VALID':
        this.folioService
          .folioDisponibles(
            this.idDocumentoCaf,
            this.addressForm.value
          )
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.snackBar.open(
                'Consultado a SII esto tardara unos segundos',
                'cerrar',
                {
                  duration: 10000,
                  verticalPosition: 'top'
                }
              )
              setTimeout(() => {
                window.location.reload()
              }, 11000)
            },
            (error: any) => {
              this.snackBar.open(
                'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              this.snackBar.open(error, 'cerrar', {
                duration: 3000,
                verticalPosition: 'top'
              })
            }
          )

        break
      case 'INVALID':
        this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.folioService.addResultFolio('nuevo')
  }
}
