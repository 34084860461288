import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ProveedoresService } from '../../proveedores.service'
import { MatTableDataSource } from '@angular/material/table'
import { modeloProveedor } from '@app/_models/proveedores/proveedores'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { SelectionModel } from '@angular/cdk/collections'

@Component({
  selector: 'app-proveedores-table',
  templateUrl: './proveedores-table.component.html',
  styleUrls: ['./proveedores-table.component.scss']
})
export class ProveedoresTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort = null

  // ? Inputs & Outputs
  @Input() refrescar = ''

  //------inicio tabla-------
  displayedColumns: string[] = [
    'id',
    'nombre',
    'giro',
    'direccion',
    'contacto',
    'acciones'
  ]

  dataSource: MatTableDataSource<modeloProveedor> = new MatTableDataSource()
  dataProveedores: modeloProveedor[] = []
  selection = new SelectionModel<modeloProveedor>(true, []);


  constructor(
    private proveedoresService: ProveedoresService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getAllProveedores()
  }

  //Llamado de los datos para la tabla y boton de busqueda
  getAllProveedores() {
    this.proveedoresService.getAllProveedores().subscribe(
      (data: any) => {
        //console.log(data) // data contiene tu respuesta
        this.dataProveedores = data
        this.dataSource = new MatTableDataSource(this.dataProveedores)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //Boton para actualizar tabla de proveedores
  actualizar(): void {
    window.location.reload()
  }

  //Boton para eliminar proveedor
  deleteProveedor(id: any) {
    if (confirm('Esta seguro que desea eliminar el registro: ')) {
      this.proveedoresService.deleteProveedor(id).subscribe(() => {
        this.getAllProveedores()
      })
    }
  }

  //Boton para editar
  updateProveedor(id: any) {
    localStorage.setItem('idProveedor', id)
    this.proveedoresService.openDialogEditProveedor()
  }

  //Boton de busqueda
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

}
