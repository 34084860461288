import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { modeloCuentasBancaria } from '@app/_models/consolidadoBancario/cuentasBancaria'
import { ConsolidadoBancarioService } from '../../consolidado-bancario.service'

@Component({
  selector: 'app-home-table',
  templateUrl: './home-table.component.html',
  styleUrls: ['./home-table.component.scss']
})
export class HomeTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort = null
  // ? Inputs & Outputs
  @Input() refrescar = ''

  //------inicio tabla-------
  displayedColumns: string[] = [
    'id',
    'nombreCuenta',
    'rut',
    'numeroCuenta',
    'tipoMonto',
    'banco',
    'totalMonto',
    'acciones'
  ]

  dataSource: MatTableDataSource<modeloCuentasBancaria> = new MatTableDataSource()
  dataCuentaBancaria: modeloCuentasBancaria[] = []
  modelo: modeloCuentasBancaria = new modeloCuentasBancaria()

  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService
  ) {}

  ngOnInit(): void {
    this.getAllCuentaBancaria()
  }

  getAllCuentaBancaria() {
    this.consolidadoBancarioService.getAllCuentaBancaria().subscribe(
      (data: any) => {
        this.dataCuentaBancaria = data
        this.dataSource = new MatTableDataSource(this.dataCuentaBancaria)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //Boton para actualizar tabla de facturas
  actualizar(): void {
    window.location.reload()
  }
  //Boton de busqueda
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }
  //Boton de acciones
  accionesFormCuentaBancaria(id: any) {
    localStorage.setItem('idformCuentaBancaria', id)
    this.consolidadoBancarioService.openDialogEditFormCuentaBancaria()
  }
}
