<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Eventos">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <h1>Eventos Asociados Al Documento</h1>
        <hr />

        <mat-card-content>
          <!-- Fila 1 -->
          <div class="row">
            <!-- proveedor -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Proveedor"
                  formControlName="razonSocial"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>badge_white_18dp</mat-icon>
              </mat-form-field>
            </div>
            <!-- rut -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Rut"
                  formControlName="rutProveedor"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>fingerprint_white_18dp</mat-icon>
              </mat-form-field>
            </div>
          </div>

          <!-- Fila 2 -->
          <div class="row">
            <!-- folio -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Folio"
                  formControlName="folio"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>vpn_key_white_18dp</mat-icon>
              </mat-form-field>
            </div>
            <!-- tipo documento  -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Tipo Documento"
                  formControlName="tipoDTE"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>description_white_18dp</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 3 -->
          <div class="row">
            <!-- Fecha Emision -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Fecha Emision"
                  formControlName="fechaEmision"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>calendar_today_white_18dp</mat-icon>
              </mat-form-field>
            </div>
            <!-- total  -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Total"
                  formControlName="montoTotal"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>payments_white_18dp</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Descripcion"
                  formControlName="descripcion"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>payments_white_18dp</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <hr />
          <button
          mat-stroked-button
          style="margin-left: 1rem; margin-bottom: 3px"
          color="primary"
          (click)="EventoDoc()"
        >
        <mat-icon>update_white_18dp</mat-icon>Actualizar Evento
        </button>
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-content>
      </mat-card>
    </form>
  </mat-tab>

  <mat-tab label="Aceptación o Reclamo ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Registro de Aceptación o Reclamo de un Documento</h1>
        </mat-card-header>
        <mat-card-content>
          <!-- Fila 1 -->
          <div class="row">
            <!-- proveedor -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Proveedor"
                  formControlName="razonSocial"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>badge_white_18dp</mat-icon>
              </mat-form-field>
            </div>
            <!-- rut -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Rut"
                  formControlName="rutProveedor"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>fingerprint_white_18dp</mat-icon>
              </mat-form-field>
            </div>
          </div>

          <!-- Fila 2 -->
          <div class="row">
            <!-- folio -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Folio"
                  formControlName="folio"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>vpn_key_white_18dp</mat-icon>
              </mat-form-field>
            </div>
            <!-- tipo documento  -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Tipo Documento"
                  formControlName="tipoDTE"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>description_white_18dp</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 3 -->
          <div class="row">
            <!-- Fecha Emision -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Fecha Emision"
                  formControlName="fechaEmision"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>calendar_today_white_18dp</mat-icon>
              </mat-form-field>
            </div>
            <!-- total  -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Total"
                  formControlName="montoTotal"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>payments_white_18dp</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <mat-card-content>
          <h4>
            Acuse Recibo de Mercaderías o Servicios permite al comprador o
            beneficiario del servicio utilizar el crédito de IVA.
          </h4>
        </mat-card-content>
        <mat-card-actions class="shipping-card">
          <button
            mat-flat-button
            style="margin-left: 1rem; margin-bottom: 3px"
            color="primary"
            (click)=" ReciboMercancia()"
          >
            Acuse recibido de mercaderias o servicios
          </button>
          <button
            mat-flat-button
            style="margin-left: 1rem; margin-bottom: 3px"
            color="primary"
            (click)="AceptarDocumento()"
          >
            Aceptar contenido del documento
          </button>
        </mat-card-actions>
        <mat-card-footer>
          <h4>
            Cualquier Reclamo impedirá hacer uso del crédito fiscal de IVA
          </h4>
          <button mat-stroked-button color="warn" (click)="ReclamarDocumento()">
            <mat-icon>border_color_white_18dp</mat-icon> Reclamar
          </button>
          <hr />
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 45rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-footer>
      </mat-card>
    </form>
  </mat-tab>

  <mat-tab label="Documento XML">
    <br />
    <mat-card-header>
      <h1>Descargar Documento XML</h1>
    </mat-card-header>
    <hr />
    <button
      mat-raised-button
      style="margin-left: 2rem; margin-bottom: 3px"
      color="accent"
      (click)="descargarXml()"
    >
      <mat-icon>cloud_download</mat-icon> XML
    </button>
  </mat-tab>
</mat-tab-group>
