import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Sucursal } from '@app/_models/shared/sucursal'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { RentacarService } from '@app/_pages/rentacar/rentacar.service'

import { first } from 'rxjs/operators'
import { Empresa } from '@app/_models/shared/empresa';
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'

@Component({
  selector: 'app-rentacar-egresos-edit',
  templateUrl: './rentacar-egresos-edit.component.html',
  styleUrls: ['./rentacar-egresos-edit.component.scss']
})
export class RentacarEgresosEditComponent implements OnInit {
  idRentacar: any
  fecha: any
  monto: any
  responsable: any
  tipoEgreso: any
  sucursal: any
  descripcion: any

  /**  Configuración de formulario  */
  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    monto: ['', Validators.required],
    responsable: ['', Validators.required],
    tipoEgreso: ['', Validators.required],
    tipoPago: ['', Validators.required],
    idSucursal: ['', Validators.required],
    sucu: [''],
    descripcion: ['', Validators.required],
   
  })
  sucursales: Sucursal[]
  tiposEgresos: string[] = []
  tiposPagos: any[] = []
  sucu: any;
  idEmpresa = 4;

  empresa = new Empresa();
  constructor(
    private fb: FormBuilder,
    private empresaService: EmpresaSharedService,

    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private administracionService: AdministracionService,
    private rentacarService: RentacarService
  ) {
    //this.sucursales = this.sucursalService.sucursalListValue
    this.tiposPagos = [
      'Efectivo',
      'Debito',
      'Credito',
      'Transferencia',
      'Cheque'
    ]
    this.tiposEgresos = [
      'Gastos',
      'Costos',
      'Remuneraciones',
      'Impuestos',
      'Bancarios'
    ]
  }


  ngOnInit(): void {
    this.idRentacar = localStorage.getItem('idEgresoRentacar')
    this.getEgresoRentacar()
    this.getEmpresa(this.idEmpresa);
    
   

  }

  getEgresoRentacar() {
    this.rentacarService
      .getEgresosById(this.idRentacar)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idRentacar) {
          this.f.fecha.setValue(x[0].fecha)
          this.f.monto.setValue(x[0].monto)
          this.f.responsable.setValue(x[0].responsable)
          this.f.tipoEgreso.setValue(x[0].tipoEgreso)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.descripcion.setValue(x[0].descripcion)
          this.f.idSucursal.setValue(x[0].idSucursal)

        

          this.sucu = x[0].Sucursal.razonSocial
          
        }
        this.sucu = x[0].Sucursal.razonSocial



      })


  }

  getEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x) => {
        x.Sucursals = Object.values(x.Sucursals);
       
        this.empresa = x;


      
      });
  }



  get f() {
    return this.addressForm.controls
  }
  onSubmit() {
    switch (this.addressForm.status) {
      case 'VALID':
        this.rentacarService
          .updateRentacarEgreso(this.idRentacar, this.addressForm.value)
          .pipe(first())
          .subscribe(
            (data) => {
              this.snackBar.open('Lubricentro editada con éxito.', 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top',
                
              })
            
              this.addressForm.reset()
              this.rentacarService.closeDialogModal()
              
            },
            (error) => {
              this.snackBar.open(
                'No se pudo editar la lubricentro, favor contactar a informática.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                });
            }
          );
        break;

      case 'INVALID':
        this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
        break;

      default:
        break;
    }
  }
  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
  }


}
