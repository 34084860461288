import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CategoriasService } from '@app/_pages/categorias/categorias.service';
import { BodegaService } from '@app/_pages/inventario/bodega/bodega.service';
import { ProductosService } from '@app/_pages/productos/productos.service';

@Component({
  selector: 'app-stockActual-filtros',
  templateUrl: './stockActual-filtros.component.html',
  styleUrls: ['./stockActual-filtros.component.scss']
})
export class StockActualFiltrosComponent implements OnInit {

  Filtro = this.fb.group({
    idBodegas: [null],
    idCategorias: [null],
    idProductos: [null],
    idStock: [null],
    CodigoBarras: [null]
    
  })
  bodegas : any;
  categorias: any;
  productos: any;
  lista_bodegas:any=[];
  lista_categorias:any=[];
  lista_productos:any=[];
  dataSource : any;
  
  

  
  constructor(
    private fb: FormBuilder,
    private bodegasService: BodegaService,
    private categoriasService: CategoriasService,
    private productosService: ProductosService
    ) { }

  ngOnInit() {
    this.traerBodegas();
    this.traerCategorias();
    this.traerProductos();
    
  }
  onSubmit()
  {

  }

  formFilter = new FormGroup({
    idStock: new FormControl(),
    CodigoBarras :new FormControl(),
    idBodegas :new FormControl(),
    idCategorias :new FormControl(),
    idProductos :new FormControl(),
   
  })



  filtrar(){

   
    this.formFilter.valueChanges.subscribe(res => {

     
      console.log("llego aqui", res)
    
      
  })

  }




  traerBodegas()
  {
    this.bodegasService.bodegasGetAll().subscribe((result: any) => {
        this.bodegas = result
        for(var i=0; i<this.bodegas.length; i++){
          this.lista_bodegas.push(this.bodegas[i]);
        }
      }
    )
  }
  traerCategorias()
  {
    this.categoriasService.categoriaGet().subscribe((result:any) =>{
      this.categorias=result;
      for(var i=0; i<this.categorias.length; i++){
        this.lista_categorias.push(this.categorias[i]);
      }
    })
  }
  traerProductos()
  {
    this.productosService.productoGet().subscribe((result:any)=>{
      this.productos = result;
      for(var i=0; i<this.productos.length; i++)
      {
        this.lista_productos.push(this.productos[i]);
      }
    })
  }
}
