<button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
    <mat-icon>highlight_off</mat-icon>
</button>
<form [formGroup]="form" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
    <mat-card class="shipping-card">
        <mat-card-header>
            <!-- {{ transferencias | json }} -->
        </mat-card-header>
        <mat-card-content>

            <div class="row">
                <!-- Nombre -->
                {{ id }}
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Bodega origen" formControlName="bodegaOrigen"/>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Fecha transferencia" formControlName="fechaTransferencia"/>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <!-- Nombre -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Bodega destino" formControlName="bodegaDestino"/>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="example-full-width">
                        <mat-label>Observación</mat-label>
                        <textarea matInput placeholder="Observación" formControlName="observacion"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <br>
            <!-- <mat-divider></mat-divider> -->
            <br>
            <div class="row">
                <!-- <pre>{{ dataSource | json}}</pre> -->
               
            </div>
        </mat-card-content>

    </mat-card>
</form>

<!-- bodegaOrigen : [''],
BodegaDestino: [''],
fechaTransferencia : [''],
observacion : [''], -->