import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransferenciaBodega } from '@app/_models';
import { TransferenciaBodegaListComponent } from '../transferencia-bodega-list.component';
import { first } from 'rxjs/operators';
import { TransferenciasService } from '../../transferencias.service';

@Component({
  providers:[ TransferenciaBodegaListComponent ],
  selector: 'app-dialog-transferencia-editar',
  templateUrl: './dialog-transferencia-editar.component.html',
  styleUrls: ['./dialog-transferencia-editar.component.scss']
})

export class DialogTransferenciaEditarComponent implements OnInit {

  transferencias: TransferenciaBodega[] = [];
  displayedColumns: string[] = ['imagen', 'producto', 'estado', 'ubicacion', 'cantidad', 'botones'];
  dataSource: any[] = [];
  formularioListo = new EventEmitter<string>();
  id : any;

  // ? Configuración de formulario
  form = this.fb.group({
    bodegaOrigen : [{value : '', disabled: true}],
    bodegaDestino: [{value : '', disabled: true}],
    fechaTransferencia : [{value : '', disabled: true}],
    observacion : [{value : '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private transferenciasService: TransferenciasService,
    private datePipe: DatePipe
  )
  {}
  
  ngOnInit(): void {
    this.id = localStorage.getItem("idTransferencia");
    console.log(this.id)
    this.getTransferenciaa(this.id)
  }

  get f() {
    return this.form.controls;
  }

  getTransferenciaa(id : number){
    this.transferenciasService
    .getTransferencia(id).
    subscribe((transferencia: any) => {
        this.transferencias = transferencia;
        console.log("dentro del dialog",this.transferencias)
        this.dataSource = transferencia[0].Producto;
        this.f.bodegaOrigen.setValue(transferencia[0].bodegaInicio);
        this.f.bodegaDestino.setValue(transferencia[0].bodegaDestino);
        this.f.fechaTransferencia.setValue(this.datePipe.transform(transferencia[0].fechaTransferencia));
        this.f.observacion.setValue(transferencia[0].observacion);
    });
  }
  // Metodo para enviar info
  onSubmit(){
    // this.BodegaService
    // .update(this.idBodega, this.form.value)
    // .pipe(first())
    // .subscribe(
    //   (data) => {
    //     this.snackBar.open('bodega actualizada con éxito', 'cerrar', {
    //       duration: 2000,
    //       verticalPosition: 'top',
    //     });
    //     this.BodegaService.closeDialogModal();
    //     this.BodegaService.addResultBodega('Holaaaa')
    //   },
    //   (error) => {
    //     this.snackBar.open(error, 'cerrar', {
    //       duration: 2000,
    //       verticalPosition: 'top',
    //     });
    //   }
    // );
  }
  //Cerrar Modal
  closeDialog(){
    this.transferenciasService.closeDialogModal();
  }
}
