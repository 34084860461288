import { Component, OnInit } from '@angular/core';
import { Empresa } from '@app/_models/shared/empresa';
import { Sucursal } from '@app/_models/shared/sucursal';
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service';
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service';


@Component({
  selector: 'app-consolidado-diario-pago-gasto',
  templateUrl: './consolidado-diario-pago-gasto.component.html',
  styleUrls: ['./consolidado-diario-pago-gasto.component.scss'],

})
export class ConsolidadoDiarioPagoGastoComponent implements OnInit {
  sucursales: Sucursal[] = [];
  empresa: Empresa = new Empresa();
  refrescar = '';


  constructor(
  ) {}
  ngOnInit(): void {
  }

}
