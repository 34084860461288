<form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
    <mat-card class="shipping-card">
        <mat-card-header>
            <h2 class="md-display-1">Nuevo Cesionario</h2>
        </mat-card-header>
        <br><br>
        <mat-card-content>
            <div class="row">
                <!-- Cesionarios -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Nombre" formControlName="nombre" name="nombre" autocomplete="off"/>
                        <mat-error *ngIf="addressForm.controls['nombre'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['nombre'].hasError('minlength')">
                            El nombre <strong>debe contener más de 5 carácteres</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Contacto" formControlName="contacto" autocomplete="off"/>
                        <mat-error *ngIf="addressForm.controls['contacto'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <!-- Rut-->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input 
                        matInput
                        placeholder="Ingrese Rut (11111111-1)" 
                        formControlName="rut" 
                        autocomplete="off"
                        minlength="8"
                        maxlength="12" 
                        [(ngModel)]="tamano_rut"
                        (input)="formatoRut()"
                        />
                        <mat-error *ngIf="addressForm.controls['rut'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['rut'].hasError('rutTomado')">
                            este rut se encuentra <strong>registrado</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['rut'].hasError('rutInvalido')">
                            el rut es <strong>inválido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Rut -->
                <!-- Correo electrónico -->
                  <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Correo electrónico" formControlName="email" autocomplete="off"/>
                        <mat-error *ngIf="addressForm.controls['email'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['email'].hasError('pattern')">
                            El <strong>email es inválido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Correo electrónico -->
            </div>

            <div class="row">
                <!-- Dirección -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Dirección" formControlName="direccion" autocomplete="off"/>
                        <mat-error *ngIf="addressForm.controls['direccion'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['direccion'].hasError('minlength')">
                            La Dirección debe contener más de <strong>5 carácteres</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Dirección -->
                <!-- telefono -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Teléfono" formControlName="telefono" autocomplete="off"/>
                        <mat-error *ngIf="addressForm.controls['telefono'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- telefono -->
        </mat-card-content>
        <!-- botón enviar -->
        <button mat-raised-button color="primary" type="submit" [disabled]="addressForm.invalid">
            <mat-icon>send</mat-icon> Enviar
        </button>
    </mat-card>
</form>