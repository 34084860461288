import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { EgresosNeumaticoImportadora } from '@app/_models/importadora/egresoNeumaticoImportadora';
import { AdministracionService } from '@app/_pages/administracion/administracion.service';
import { ImportadoraService } from '@app/_pages/importadora/importadora.service';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-acciones-egreso-neumatico',
  templateUrl: './acciones-egreso-neumatico.component.html',
  styleUrls: ['./acciones-egreso-neumatico.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class AccionesEgresoNeumaticoComponent implements OnInit {

  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any

  idEgresoImportadoraNeumatico: any
  respaldos: any
  nContainer: any
  costoNeumatico: any
  costoComision: any
  costoInterior: any
  costoMaritimo: any
  seguros: any
  impuestoProntuario: any
  montoTotal: any
  fecha: any

  dataArrayImg = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  //Modelo de Ingreso de rentacar
  modelo: EgresosNeumaticoImportadora = new EgresosNeumaticoImportadora()

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private importadoraService: ImportadoraService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.idEgresoImportadoraNeumatico = localStorage.getItem('idEgresoImportadoraNeumatico')
    this.getEgresoImportadoraNeumatico()
  }

  //Configuracion de formulario
  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    montoTotal: ['', Validators.required],
    impuestoProntuario: ['', Validators.required],
    archivos: null,
    seguros: ['', Validators.required],
    respaldos: ['', Validators.required],
    nContainer: ['', Validators.required],
    costoNeumatico: ['', Validators.required],
    costoComision: ['', Validators.required],
    costoInterior: ['', Validators.required],
    costoMaritimo: ['', Validators.required],
  })

  getEgresoImportadoraNeumatico() {
    this.importadoraService
      .getByIdEgresoNeumaticoImportadora(this.idEgresoImportadoraNeumatico)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idEgresoImportadoraNeumatico) {
          this.f.fecha.setValue(x[0].fecha)
          this.f.montoTotal.setValue(x[0].montoTotal)
          this.f.impuestoProntuario.setValue(x[0].impuestoProntuario)
          this.f.archivos.setValue(x[0].archivos)
          this.f.seguros.setValue(x[0].seguros)
          this.f.nContainer.setValue(x[0].nContainer)
          this.f.costoNeumatico.setValue(x[0].costoNeumatico)
          this.f.costoComision.setValue(x[0].costoComision)
          this.f.costoInterior.setValue(x[0].costoInterior)
          this.f.costoMaritimo.setValue(x[0].costoMaritimo)
        }
        this.previsualizacion = x[0].archivos

      })
  }


  get f() {
    return this.addressForm.controls
  }
  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.importadoraService.closeDialogModal()
    window.location.reload()
  }

  //Capturar imagen
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop() // Obtener la extensión del archivo original
    const nuevoNombre = "EgresosNeumaticoImportadora" + Date.now() + "." + extension // Crear un nombre único con el identificador generado dinámicamente
    this.dataArrayImg.push(nuevoNombre) // Agregar el nuevo nombre al array de nombres de archivo
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, { type: archivoCapturado.type }) // Crear un nuevo objeto de tipo File con el nuevo nombre
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
  }

  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/egresoContainerImportadora/Upload/EgresoNeumaticoImportadora`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

   //Actualizar formulario
   onSubmit() {
    if (confirm('Esta seguro que desea actualizar el Egreso  de Neumatico: ')) {
      this.importadoraService.getByIdEgresosNuematicoImportadoraSinBase64(this.idEgresoImportadoraNeumatico)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idEgresoImportadoraNeumatico) {
            const nombreArchivo = x[0].archivos
            const JsonNombreArchivo = JSON.parse(nombreArchivo);
            if (this.dataArrayImg[0] === undefined) {
              this.addressForm.value.archivos = JsonNombreArchivo;
            } else {
              this.addressForm.value.archivos = this.dataArrayImg
            }

            this.importadoraService
            .updateEgresoImportadoraNeumatico(this.idEgresoImportadoraNeumatico, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Egreso  de Neumatico editado con éxito.', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })

                this.addressForm.reset()
                this.importadoraService.closeDialogModal()
                window.location.reload()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar el Egreso  de Neumatico, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )

          }
        })
    }
  }

  //Actualizar formulario
  AgregarIMG() {
    if (confirm('Esta seguro que desea agregar IMG el Ingreso de Rentacar: ')) {
      this.importadoraService.getByIdEgresosNuematicoImportadoraSinBase64(this.idEgresoImportadoraNeumatico)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idEgresoImportadoraNeumatico) {
            const data = x[0]
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.montoTotal = data.montoTotal
            this.addressForm.value.impuestoProntuario = data.impuestoProntuario
            this.addressForm.value.seguros = data.seguros
            this.addressForm.value.archivos = this.dataArrayImg
            this.addressForm.value.nContainer = data.nContainer
            this.addressForm.value.costoNeumatico = data.costoNeumatico
            this.addressForm.value.costoComision = data.costoComision
            this.addressForm.value.costoInterior = data.costoInterior
            this.addressForm.value.costoMaritimo = data.costoMaritimo

            console.log(this.addressForm.value);

            this.importadoraService
            .updateEgresoImportadoraNeumatico(this.idEgresoImportadoraNeumatico, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Ingreso de Rentacar editado con éxito.', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })

                this.addressForm.reset()
                this.importadoraService.closeDialogModal()
                window.location.reload()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar el Ingreso de Rentacar, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )

          }
        })
    }
  }


}
