<form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
    <mat-card class="shipping-card">
        <mat-card-header>
            <h1>{{nombreRol}}</h1>
        </mat-card-header>
        <br><br>
        <mat-card-content>
            <div class="row">
                <!-- nombre -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Nombre" formControlName="nombre" />
                        <mat-error *ngIf="addressForm.controls['nombre'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>

        <!-- botón enviar -->
        <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon> Enviar
        </button>
    </mat-card>
</form>