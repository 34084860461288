import { Sucursal } from "../shared/sucursal";
import { Usuario } from "../shared/usuario";

export class IngresoGarridoProyectos {
  id!: string
  fecha!: Date
  Sucursal!: Sucursal
  Usuario!:Usuario
  nombreCliente!: string
  tipoCliente!: string
  referencia!: string
  monto!: number
  nDocumento!: number
  tipoPago!: string
  banco!: string
  numeroCuenta!: number
  descripcion!: string
  nAutorizacion!: number
  tipoIngreso!: string
  tipoDocumento!: string
  archivos!: string[]
  idSucursal!: number
  idUsuario!: number
  servicios!: any
  sucursal!:string
  usuario!: string;
  // usuario!:string
}