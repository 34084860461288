import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { modeloFolio } from '../../../../_models/folio/folio';
import { CesionService } from '../../cesion.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { interval } from 'rxjs';
import { EmpresasSimpleapiService } from '@app/_pages/shared/shared-services/empresas-simpleapi.service';

@Component({
  selector: 'app-cesion-table',
  templateUrl: './cesion-table.component.html',
  styleUrls: ['./cesion-table.component.scss']
})
export class CesionTableComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  isLoading = false;
  public criterio = ''
  public buscar = ''
  //------inicio tabla-------

  constructor(
    public dialog: MatDialog,
    private cesionService: CesionService,
    private config: NgSelectConfig, 
    public _MatPaginatorIntl: MatPaginatorIntl,
    private empresasSimpleapiService: EmpresasSimpleapiService
    ) 
  {
    this.config.notFoundText = 'No se encontraron resultados';
    this.config.appendTo = 'body';
    this.config.bindValue = 'value';
  }

  ngOnInit(): void {
    this._MatPaginatorIntl.itemsPerPageLabel = 'Items por páginas';
    this.criterio = 'all';
    this.buscar = '';
    this.getCesiones();
  }

  selection = new SelectionModel<any>(true, []);
  selectedRows!: any[];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
 
  displayedColumns: string[] = [
    'select',
    'cliente',
    "folio",
    "cesionario",
    "monto_cesion",
    "estado",
    "botones"
  ]

  filtros: any[] = [
    {value: 'folio', viewValue: 'N° folio'},
    {value: 'rutCliente', viewValue: 'RUT cliente'},
    {value: 'nombre', viewValue: 'Nombre/razón social'},
    {value: 'codigoCliente', viewValue: 'Código cliente'},
    {value: 'montoCesion', viewValue: 'Monto Total'},
    {value: 'rutCesionario', viewValue: 'Rut cesionario'},
    {value: 'all', viewValue: 'Todo'},
  ];

  getCesiones(){
    this.criterio='';
    this.buscar='';
    this.cesionService.getAllFilter(this.criterio, this.buscar)
    .subscribe((resultado=>{
      console.log(resultado)
      for (let i = 0; i < resultado.length; i++) {
        if (resultado[i].estado === 'PENDIENTE'){
          this.isLoading = true;
          const empresa = this.empresasSimpleapiService.getEmpresasRut(resultado[i].rutCedente);
          console.log(empresa);

          let cesion = resultado[i];
          this.cesionService.updateEstadoPendiente(cesion, empresa).subscribe((res) => {
            this.cesionService.getAllFilter(this.criterio, this.buscar)
            .subscribe((actualizados=>{ 
              this.isLoading = false;
              this.dataSource = new MatTableDataSource(actualizados);
              this.dataSource.paginator = this.paginator.toArray()[0];
            }));
            console.log("actualizado", res);
          });
        }
        
      }
      if (!this.isLoading) {
        this.dataSource = new MatTableDataSource(resultado);
        this.dataSource.paginator = this.paginator.toArray()[0];
      }
    }))
  }

    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    // Metodo que sirve para la seleccion de un campo de la tabla
    masterToggle() {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.filteredData.forEach((row) => {
            this.selection.select(row)
        });
    }


  //Boton de busqueda
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }
  //Abrir Modal Editar
  openDialogEdit(id:any) {
    console.log(id);
    localStorage.setItem('idCesion', id);
    this.cesionService.openDialogEditFolio();
  }

  changeClient(value) {
    console.log(value);
    this.criterio = value;
  }

  
}
