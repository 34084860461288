<mat-card class="shipping-card">
  <mat-expansion-panel hideToggle="">
    <mat-expansion-panel-header>
      <mat-panel-title> Hostal: Egresos Manuales </mat-panel-title>
      <mat-panel-description>Presiona Aquí!</mat-panel-description>
      <mat-icon>touch_app_white_18dp</mat-icon>
    </mat-expansion-panel-header>
    <mat-horizontal-stepper>
      <mat-step [stepControl]="addressForm" completed="false">
        <mat-card-content>
          <form [formGroup]="addressForm">
            <!-- Fila 1 -->
            <div class="row">
              <!-- sucursales -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Sucursales" formControlName="idSucursal">
                    <mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">
                      {{ sucursal.razonSocial }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      addressForm.controls['idSucursal'].hasError('required')
                    ">
                    sucursal es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Tipo egresos -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Tipo Egresos" formControlName="tipoEgreso">
                    <mat-option *ngFor="let tipoEgreso of tiposEgresos" [value]="tipoEgreso">
                      {{ tipoEgreso }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    ">
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 2 -->
            <div class="row">
              <!-- select fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-label>Seleccionar fecha</mat-label>
                  <input
                    matInput
                    formControlName="fecha"
                    [matDatepicker]="picker"
                    [readonly]="true"
                    [max]="maxDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="addressForm.controls['fecha'].hasError('required')">
                    fecha es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- numero de cuotas -->
              <div class="col" *ngIf="!mostrarDatos">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Numero de cuotas" formControlName="numeroCuota" type="number" />
                  <mat-error *ngIf="
                      addressForm.controls['numeroCuota'].hasError('required')
                    ">
                    numero de cuotas <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- monto -->
              <div class="col" *ngIf="mostrarDatos">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Monto" formControlName="monto" type="text" [ngModel]="result3"
                    (ngModelChange)="transform($event)" (keypress)="restrictNumeric($event)" />
                  <mat-error *ngIf="addressForm.controls['monto'].hasError('required')">
                    Monto es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- monto de las cuotas -->
              <div class="col" *ngIf="!mostrarDatos">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Monto por cuota" formControlName="montoCuota" type="text"
                    [ngModel]="result2" (ngModelChange)="transform($event)" (keypress)="restrictNumeric($event)" />
                  Capital + Interes
                  <mat-error *ngIf="addressForm.controls['monto'].hasError('required')">
                    monto es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Responsable -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="ingrese nombre responsable"
                    formControlName="responsable"
                    readonly="»readonly»"
                  />
                  <mat-error
                    *ngIf="
                      addressForm.controls['responsable'].hasError('required')
                    ">
                    este campo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 3 -->
            <div class="row">
              <!-- descripcion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="ingrese una descripcion del registro" formControlName="descripcion" />
                  <mat-error *ngIf="
                      addressForm.controls['descripcion'].hasError('required')
                    ">
                    descripcion es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Grupo de envio de gastos -->
               <!-- <div class="col">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Grupo de envio de gastos" formControlName="grupoGastos">
                    <mat-option *ngFor="let g of grupos" [value]="g">
                      {{ g }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                                    addressForm.controls['grupoGastos'].hasError('required')
                                  ">
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>  -->
              
              <!-- Gastos Fijos -->
              <div class="col" *ngIf="verSeleccion !== 'Gastos Fijos'">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Gastos Fijos" formControlName="gastosFijos">
                    <mat-option *ngFor="let gF of gastosFijo" [value]="gF">
                      {{ gF }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="addressForm.controls['gastosFijos'].hasError('required')">
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" *ngIf="addressForm.get('gastosFijos').value === 'Otro'">
                  <input
                    matInput
                    placeholder="Otro Gasto Fijo"
                    formControlName="otroGasto"
                    type="text"
                  />
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 4 -->
            <div class="row">
              <!-- tipo pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Tipo Pago" formControlName="tipoPago">
                    <mat-option *ngFor="let tipoPago of tiposPagos" [value]="tipoPago">
                      {{ tipoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    ">
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Tipo banco   -->
              <div class="col">
                <div *ngIf="addBanc">
                  <mat-form-field appearance="legacy" class="full-width">
                    <mat-label>Seleccionar Banco</mat-label>
                    <mat-select formControlName="banco">
                      <mat-option value="BancoItau"> Banco Itau </mat-option>
                      <mat-option value="BancoEstado"> Banco Estado </mat-option>
                      <mat-option value="BancoSantander">
                        Banco Santander - Santiago
                      </mat-option>
                      <mat-option value="BancoChile">
                        Banco de Chile / Edwards-Citi</mat-option>
                      <mat-option value="BCI - MACH">
                        BCI - MACH</mat-option>
                      <mat-option value="BancoFalabella">
                        Banco Falabella</mat-option>
                      <mat-option value="BancoScotiabank">
                        Banco Scotiabank Sud Americano</mat-option>
                      <mat-option value="BancoSecurity"> Banco Security</mat-option>
                      <mat-option value="BancoScotiabankAzul">
                        Banco Scotiabank Azul-Ex BBVA</mat-option>
                      <mat-option value="BancoInternacional">
                        Banco Internacional</mat-option>
                      <mat-option value="BancoCorpbanca">
                        Banco Corpbanca</mat-option>
                      <mat-option value="BancoBice"> Banco Bice</mat-option>
                      <mat-option value="BancoHsbc">
                        Banco HSBC Bank Chile</mat-option>
                      <mat-option value="BancoMufg">
                        Banco MUFG Bank Chile</mat-option>
                      <mat-option value="BancoSudAmericano">
                        Banco Sud Americano</mat-option>
                      <mat-option value="BancoRipley">
                        Banco Ripley/Chek</mat-option>
                      <mat-option value="BancoRabobank"> Banco Rabobank</mat-option>
                      <mat-option value="BancoConsorcio">
                        Banco Consorcio</mat-option>
                      <mat-option value="BancoParis"> Banco Paris</mat-option>
                      <mat-option value="BancoDesarrollo">
                        Banco de Desarrollo</mat-option>
                      <mat-option value="BancoCoopeuch"> Banco Coopeuch</mat-option>
                      <mat-option value="BancoHeroes">
                        Banco Prepago los Heroes</mat-option>
                      <mat-option value="BancoTenpo"> Banco Tenpo SA</mat-option>
                      <mat-option value="BancoDoBrasil">
                        Banco Do Brasil</mat-option>
                      <mat-option value="BancoMorgan">
                        Banco JP Morgan Chass Bank</mat-option>
                      <mat-option value="BancoPactual">
                        Banco BTG Pactual Chile</mat-option>
                      <mat-option value="BancoChinaConstruccion">
                        Banco China Construccion Bank AG Chile</mat-option>
                      <mat-option value="BancoChina">
                        Banco Bank of China</mat-option>
                      <mat-option value="BancoTransbank">
                        Banco Transbank</mat-option>
                      <mat-option value="BancoMercadoPago">
                        Banco Mercado Pago Emisora SA</mat-option>
                      <mat-option value="BancoCajaLosAndes">
                        Banco TAPP Caja los Andes</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>account_balance_18dp</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <!-- ncuenta -->
              <div class="col">
                <div *ngIf="addBanc">
                  <mat-form-field class="full-width">
                    <mat-label>N° Cuenta corriente/Tarjeta</mat-label>
                    <input matInput placeholder="XXXX/4 Últimos Dígitos" formControlName="numeroCuenta" type="number" />
                    <mat-error *ngIf="addressForm.get('numeroCuenta').hasError('required')">
                      Este campo es obligatorio.
                    </mat-error>
                    <mat-error *ngIf="addressForm.get('numeroCuenta').hasError('pattern')">
                      Debe ingresar los últimos dígitos de la tarjeta/cuenta
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- Fila 5 -->
            <div class="row">
              <div class="col">
                <input mat-stroked-button (change)="capturarFile($event)" type="file" />
                <br />
                <hr class="separador" />

                <div class="row">
                  <!-- Preview img -->
                  <div class="col" *ngIf="previsualizacion">
                    <img width="400px" [src]="previsualizacion" alt="img" />
                  </div>
                </div>
                <!-- Boton de accion -->
                <div>
                  <button mat-stroked-button color="primary" type="submit" [disabled]="loading" 
                    [disabled]="clickButton" (click)="onSubmit()">
                    {{ loading ? 'Cargando....' : 'Guardar' }}
                    <mat-icon>send</mat-icon>
                  </button>
                </div>
              </div>
            </div>




          </form>
        </mat-card-content>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-expansion-panel>
</mat-card>