import { HttpClient } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'
import { IngresoAgroFirma } from '@app/_models/agroFirma/ingresoAgroFirma'
import { Usuario } from '@app/_models/shared/usuario'
import { Sucursal } from '@app/_models/shared/sucursal'
import { AgroFirmaService } from '@app/_pages/agroFirma/agro-firma.service'
import { Observable } from 'rxjs'
@Component({
  selector: 'app-agro-firma-ingreso-acciones',
  templateUrl: './agro-firma-ingreso-acciones.component.html',
  styleUrls: ['./agro-firma-ingreso-acciones.component.scss']
})
export class AgroFirmaIngresoAccionesComponent implements OnInit {
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  @Input() projectId!: Observable<number>

  panelOpenState = false
  clickButton = true
  sucursales: Sucursal[]
  tiposPagos: string[] = []
  referencias: string[] = []
  tipoIngresos: string[] = []
  tipoDocumentos: string[] = []
  modelo: IngresoAgroFirma = new IngresoAgroFirma()
  dataArrayImg = []
  tipoCliente: string[] = []
  idRegistroAgrofirma: number

  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private agroServices: AgroFirmaService
  ) {
    this.tiposPagos = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Cheque'
    ]
    this.referencias = [
      'Llamada',
      'booking',
      'Correo',
      'PaginaWeb',
      'Facebook',
      'Directo a Hostal'
    ]
    this.tipoIngresos = ['Transferencia', 'Cheque', 'Efectivo']
    this.tipoDocumentos = ['BOLETA', 'FACTURA']
    this.tipoCliente = ['Particular', 'Empresa']
  }

  ngOnInit(): void {
    this.getIngresoAgrofirma()
  }

  //Configuracion de formulario
  addressForm = this.fb.group({
    fecha: [''],
    cliente: [''],
    tipoCliente: [''],
    monto: [''],
    tipoDocumento: [''],
    tipoIngreso: [''],
    tipoPago: null,
    nAutorizacion: [''],
    referenciaCliente: [''],
    nDocumento: [],
    descripcionIngreso: [''],
    archivos: null
  })
  get f() {
    return this.addressForm.controls
  }

  getIngresoAgrofirma() {
    this.agroServices
      .getIngreso(localStorage.getItem('idIngresoAgrofirma'))
      .pipe(first())
      .subscribe((x: any) => {
        if (x.id == localStorage.getItem('idIngresoAgrofirma')) {
          this.f.fecha.setValue(x.fecha)
          this.f.cliente.setValue(x.cliente)
          this.f.tipoCliente.setValue(x.tipoCliente)
          this.f.monto.setValue(x.monto)
          this.f.tipoDocumento.setValue(x.tipoDocumento)
          this.f.tipoIngreso.setValue(x.tipoIngreso)
          this.f.tipoPago.setValue(x.tipoPago)
          this.f.nAutorizacion.setValue(x.nAutorizacion)
          this.f.referenciaCliente.setValue(x.referenciaCliente)
          this.f.nDocumento.setValue(x.nDocumento)
          this.f.descripcionIngreso.setValue(x.descripcionIngreso)
          this.f.archivos.setValue(x.archivos)
          console.log('img', x.archivos)
        }
        this.previsualizacion = x.archivos
      })
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    window.location.reload()
  }

  //Capturar imagen
  capturarFile(event) {
    console.log('capturar file')
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    // Obtener la extensión del archivo original
    const extension = archivoCapturado.name.split('.').pop()
    // Crear un nombre único con el identificador generado dinámicamente
    const nuevoNombre = 'ingresoAgroFirma' + Date.now() + '.' + extension
    // Agregar el nuevo nombre al array de nombres de archivo
    this.dataArrayImg.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    // Crear un nuevo objeto de tipo File con el nuevo nombre
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }

  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })

      this.http
        .post(
          `${environment.apiUrl}/ingresoAgrofirma/Upload/IngresoAgrofirma`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

  
  onSubmit() {
    if (confirm('Esta seguro que desea actualizar el ')) {
      this.agroServices
        .getIngresoByIdSinBase64(localStorage.getItem('idIngresoAgrofirma'))
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == localStorage.getItem('idIngresoAgrofirma')) {
            const nombreArchivo = x[0].archivos
            const JsonNombreArchivo = JSON.parse(nombreArchivo)
            if (this.dataArrayImg[0] === undefined) {
              this.addressForm.value.archivos = JsonNombreArchivo
            } else {
              this.addressForm.value.archivos = this.dataArrayImg
            }
            switch (this.addressForm.status) {
              case 'VALID':
                this.subirIMG()
                this.agroServices
                  .updateIngresoAgroFirma(
                    localStorage.getItem('idIngresoAgrofirma'),
                    this.addressForm.value
                  )
                  .pipe(first())
                  .subscribe(
                    (data) => {
                      this.snackBar.open(
                        'Ingreso de AgroFirma editado con éxito.',
                        'Cerrar',
                        {
                          duration: 2000,
                          verticalPosition: 'top'
                        }
                      )
                     this.addressForm.reset()
                     this.administracionService.closeDialogModal()
                     window.location.reload()
                    },
                    (error) => {
                      this.snackBar.open(
                        'No se pudo editar el Ingreso de AgroFirma, favor contactar a informática.',
                        'Cerrar',
                        {
                          duration: 2000,
                          verticalPosition: 'top'
                        }
                      )
                    }
                  )
                break
              case 'INVALID':
                this.snackBar.open(
                  'El formulario debe ser completado.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
                break
              default:
                break
            }
          }
        })
    }
  }
}
