import { Component, OnInit } from '@angular/core';
import { Empresa } from '@app/_models/shared/empresa';
import { Sucursal } from '@app/_models/shared/sucursal';
import { AuthSharedService } from '@app/_pages/shared/shared-services/auth-shared.service';
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service';
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service';

@Component({
  selector: 'app-hostal-gastos-fijos',
  templateUrl: './hostal-gastos-fijos.component.html',
  styleUrls: ['./hostal-gastos-fijos.component.scss']
})
export class HostalGastosFijosComponent implements OnInit {
  sucursales: Sucursal[] = [];
  empresa: Empresa = new Empresa();
  refrescar = '';
  errorRol: boolean;
  rolUser: string;

  constructor(
    private sucursalService: SucursalSharedService,
    private empresaService: EmpresaSharedService,
    private auth: AuthSharedService
  ) {
    this.errorRol = false;
    this.rolUser = this.auth.usuario.rol;
    this.empresaService.getAll().subscribe(empresas => {
      const empresaFound = empresas.filter(data => data.razonSocial.includes('HOSTAL'));

      this.sucursalService.getByEmpresa(empresaFound[0].id).subscribe(sucursales => {
        this.sucursales = sucursales;
      });

    }, error => {
      console.error(error);
      this.errorRol = true;
    });
   }

  ngOnInit(): void {
  }
  formularioListo(e: string): void {

    this.ngOnInit();
    this.refrescar = e;
  }

}
