import { DetalleTransferencia } from "../inventario/transferencia/DetalleTransferencia";
import { Categoria } from "../categoria/categoria";

export class Producto{
    id!: number;
    tipo!: string;
    nombre!: string;
    codigoSKU!: string;
    codigoBarra!: string;
    estado!: string;
    unitario!: number;
    neto!: number;
    venta!: number;
    ganancia!: number;
    stock !: boolean;
    moneda!: string;
    totall!:number
    img!: string;
    idCategoria!: number;
    nombreCategoria!: string;
    consultaVariantes!: number;
    DetalleTransferencia!: DetalleTransferencia;
    Categoria!: Categoria;
    afecto: boolean;
}
