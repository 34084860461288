import { ProyectoAgrofirma } from "./proyectoAgroFirma"
import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'

export class IngresoAgroFirma {
    id!: number;
    fecha!: string;
    monto!: number;
    tipoPago!: string;
    nDocumento!: string;
    cliente!: string;
    tipoCliente!: string;
    descripcionIngreso!: string;
    tipoIngreso!: string;
    estadoPago!: string;
    tipoDocumento!: string;
    nAutorizacion!: string;
    idUsuario!: number;
    idProyecto!: number;
    idCuentaProyecto!: number;
    archivos!: string[];
  idSucursal: any;
  sucursal: any;
  Sucursal: Sucursal;
  referenciaCliente!:any;
  Servicios!: any
}
