import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { modeloFolio } from '../../../../_models/folio/folio'
import { FolioService } from '../../folio.service'
import { NgSelectConfig } from '@ng-select/ng-select';
import { first } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { interval } from 'rxjs'

@Component({
  selector: 'app-folio-table',
  templateUrl: './folio-table.component.html',
  styleUrls: ['./folio-table.component.scss']
})
export class FolioTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort = null
  // ? Inputs & Outputs
  @Input() refrescar = ''

  //------inicio tabla-------
  displayedColumns: string[] = [
    'codigo',
    'tipoDocumento',
    'siguienteFolio',
    'totalDisponible',
    'FechaAutorizacionCaf',
    'rutEmisor',
    'alerta',
    // 'foliosAdicionales',
    'acciones'
  ]

  dataSource: MatTableDataSource<modeloFolio> = new MatTableDataSource()
  dataFolios: modeloFolio[] = []

  constructor(public dialog: MatDialog, private foliosService: FolioService, private config: NgSelectConfig, public _MatPaginatorIntl: MatPaginatorIntl) 
  {
    this.config.notFoundText = 'No se encontraron resultados';
    this.config.appendTo = 'body';
    this.config.bindValue = 'value';
  }

  ngOnInit(): void {
    this._MatPaginatorIntl.itemsPerPageLabel = 'Items por páginas';
    this.getAllFolio()
    this.foliosService.getResultFolio().subscribe(() => { 
      this.getAllFolio()
      this.getAllFolio()
    });
    const contador = interval(10000);
    // contador.subscribe((n)=>{
    //   this.getAllFolio()
    // })
  }

  getAllFolio() {
    this.foliosService.getAllFolios().subscribe(
      (data: any) => {
        this.dataFolios = data
        this.dataSource = new MatTableDataSource(this.dataFolios)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      },
      (error) => {
        console.log('Se ha presentado el siguiente error: ', error)
      }
    )
  }

  //Boton para actualizar tabla de folio
  actualizar(): void {
    window.location.reload()
  }

  //Boton de busqueda
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  //Abrir Modal Editar
  openDialogEdit(id:any, idDteTipo:any) {
    localStorage.setItem('idFolio', id);
    localStorage.setItem('idDteTipo', idDteTipo);
    this.foliosService.openDialogEditFolio();
  }

  delete(id: any, nombre: any) {
    if (confirm('Esta seguro que desea eliminar el registro: ' + nombre)) {
      this.foliosService.delete(id, nombre)
        .pipe(first())
        .subscribe(result => {
          if(result['data'] == "se encuentra en uso"){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'El archivo CAF se encuentra en uso!',
            })
            this.getAllFolio();
          }else{
            this.getAllFolio();
          }
        });
    }
   
  }
  
}
