import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import {  validate, clean, format, getCheckDigit } from 'rut.js'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class RutValidatorService implements AsyncValidator{

  constructor(private http:HttpClient) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const rut = control.value;
    return this.http.get<any>(`${environment.apiUrl}/cesionario/${clean(rut).toString()}`)
            .pipe(
                map(resp => {
                  return (resp.length === 0)
                          ? null 
                      : { rutTomado : true }
                  })
            );
 
  }
  
}
