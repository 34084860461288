<mat-tab-group>
  <mat-tab label="Ver">
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form
      [formGroup]="addressForm"
      novalidate
      (ngSubmit)="getProveedor()"
      class="mat-elevation-z10"
    >
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Ver Proveedor</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Fila 1 -->
          <div class="row">
            <!-- Nombre -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Nombre"
                  formControlName="nombre"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>badge_white_18dp</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 2 -->
          <div class="row">
            <!-- Giro -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Giro"
                  formControlName="giro"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>currency_exchange_white_18dp</mat-icon>
              </mat-form-field>
            </div>
            <!-- Direccion -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Direccion"
                  formControlName="direccion"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>home_white_18dp</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 3 -->
          <div class="row">
            <!-- contacto  -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Contacto"
                  formControlName="contacto"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>contacts_white_18dp</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="Editar">
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form
      [formGroup]="addressForm"
      novalidate
      (ngSubmit)="onSubmit()"
      class="mat-elevation-z10"
    >
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Editar Proveedor</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Fila 1 -->
          <div class="row">
            <!-- Nombre -->
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="Nombre" formControlName="nombre" />
                <mat-icon matSuffix>badge_white_18dp</mat-icon>
                <mat-error
                  *ngIf="addressForm.controls['nombre'].hasError('required')"
                >
                  Este campo es <strong>requerido.</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 2 -->
          <div class="row">
            <!-- Giro -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Giro"
                  formControlName="giro"
                />
                <mat-icon matSuffix>currency_exchange_white_18dp</mat-icon>
                <mat-error
                  *ngIf="addressForm.controls['giro'].hasError('required')"
                >
                  Este campo es <strong>requerido.</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Direccion -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Direccion"
                  formControlName="direccion"
                />
                <mat-icon matSuffix>home_white_18dp</mat-icon>
                <mat-error
                  *ngIf="addressForm.controls['direccion'].hasError('required')"
                >
                  Este campo es <strong>requerido.</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 3 -->
          <div class="row">
            <!-- contacto  -->
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Contacto"
                  formControlName="contacto"
                />
                <mat-icon matSuffix>contacts_white_18dp</mat-icon>
                <mat-error
                  *ngIf="addressForm.controls['contacto'].hasError('required')"
                >
                  Este campo es <strong>requerido.</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-raised-button
            color="primary"
            type="submit"
          >
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
