import { Sucursal } from '../shared/sucursal';
import { Usuario } from '../shared/usuario';


export class IngresoRentacar {
  id!: string;
  Sucursal!: Sucursal;
  Usuario!: Usuario;
  monto!: number;
  tipoIngreso!: string;
  descripcionIngreso!: string;
  fecha!: any
  archivos!:any
  nDocumento!: any
  cliente!: any
  tipoCliente!: any
  tipoPago!: any
  idSucursal!: any
  idUsuario!: number
  estadoPago!: any
  RespaldoIngresos!: any[]
  Servicios!: any
  referenciaCliente!: string
  sucursal!: any
  tipoDocumento!:any
  nAutorizacion!: any
  usuario!: string
  numeroCuenta!:number
  banco!:string
}
