import { Sucursal } from '../shared/sucursal';
import { Usuario } from '../shared/usuario';

export class modeloClientes{
  Usuario!: Usuario
  Sucursal!: Sucursal
  usuario!: string
  sucursal!: string
  nombre!: string
  alias!: string
  rut!: string
  giro!: string
  codigo!: number
  direccion!: string
  ciudad!: string
  comuna!: string
  nombreResponsable!: string
  email!: string
  telefono!: number
  contactoComercial!: string
  emailComercial!: string
  telefonoComercial!: number
  celular!: number
  descuento!: number
  credito!: number
  lineaCredito!: number
  formaPago!: string
  referencia!: string
  estado!: boolean
}

