<button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
  <mat-icon>highlight_off</mat-icon>
</button>

<mat-card class="card-mensaje">
  <div class="mensaje-cesion-estado">
    <div class="caja-mensaje" [ngClass]="{'rechazado': estado === 'RECHAZADA', 'aprobado': estado === 'APROBADA', 'enviado': estado === 'PENDIENTE'}">
      {{ estado }}
    </div>
  </div>
  <div class="mensaje-cesion">
    <h5>La cesión del documento está {{ estado }}, Track ID: {{ trackID }}</h5>
    <h5 *ngIf="estado === 'RECHAZADA' ">Motivo: {{ motivo }}</h5>
  </div>
</mat-card>

<mat-list>
  <!-- Información del documento -->
  <div mat-subheader>Información del documento</div>
  <mat-list-item>
    <div mat-line>
      <div class="content">
        <div class="conten-info">
          Tipo de documento: <span>{{ tipoDocumento }}</span>
        </div>
      </div>
    </div>
    <div mat-line>
      <div class="content">
        <div class="conten-info">
          Fecha emisión: {{ fechaEmision | date }}
        </div>
      </div>
    </div>
    <div mat-line>
      <div class="content">
        <div class="conten-info">
          Folio: {{ folio }}
        </div>
      </div>
    </div>
    <div mat-line>
      <div class="content">
        <div class="conten-info">
          Cliente: {{ cliente }}
        </div>
        <div class="sub-content-info">
          Rut cliente: {{ rutCliente }}
        </div>
      </div>
    </div>
  </mat-list-item>
  <mat-divider></mat-divider>
  <div mat-subheader>Información del cesionario (factoring)</div>
  <mat-list-item>
    <div mat-line>
      <div class="content">
        <div class="conten-info">
          Fecha envío: {{ fechaEnvio | date }}
        </div>
      </div>
    </div>
    <div mat-line>
      <div class="content">
        <div class="conten-info">
          Cesionario: {{ cesionario }}
        </div>
        <div class="sub-content-info">
          Rut Cesionario: {{ rutCesionario }}
        </div>
      </div>
    </div>
    <div mat-line>
      <div class="content">
        <div class="conten-info">
          Monto Cesión: {{ monto }}
        </div>
      </div>
    </div>
    <div mat-line>
      <div class="content">
        <div class="conten-info">
          Fecha vencimiento: {{ fechaVe }}
        </div>
      </div>
    </div>
  </mat-list-item>
</mat-list>
