import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { ClientesService } from '../../clientes.service'
import { modeloClientes } from '@app/_models/clientes/clientes'
import { MatSnackBar } from '@angular/material/snack-bar'
import { first } from 'rxjs/operators'
import { validateRUT, clearRUT } from 'validar-rut'

@Component({
  selector: 'app-clientes-form',
  templateUrl: './clientes-form.component.html',
  styleUrls: ['./clientes-form.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class ClientesFormComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()

  codigo= '';
  subido = false
  estado = false
  state = false;
  form!: FormGroup
  result1 = ' '
  termino: any;
  cargando: boolean = false; // Estado de carga

  //Modelo de proveedores
  modelo: modeloClientes = new modeloClientes()

  constructor(
    private _formBuilder: FormBuilder,
    private clienteService: ClientesService,
    private snackBar: MatSnackBar
  ) {}
  tamano_rut: string
  ngOnInit(): void {
  }

  infBasica = this._formBuilder.group({
    nombre: ['', [Validators.required]],
    alias: ['', ],
    rut: ['', [Validators.required]],
    giro: ['', [Validators.required]],
    codigo: this.result1,
    estado: [true, [Validators.required]]
  })

  infUbicacion = this._formBuilder.group({
    direccion: ['', [Validators.required]],
    ciudad: ['', [Validators.required]],
    comuna: ['', [Validators.required]]
    // docReferencia: ['', Validators.required],
    // direccionDespacho: ['', Validators.required],
  })

  infPago = this._formBuilder.group({
    nombreResponsable: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    telefono: [
      '',
      [Validators.required, Validators.maxLength(9), Validators.minLength(9)]
    ]
  })

  infAdicional = this._formBuilder.group({
    contactoComercial: ['', [Validators.required]],
    emailComercial: ['', [Validators.required, Validators.email]],
    telefonoComercial: [
      '',
      [Validators.required, Validators.maxLength(9), Validators.minLength(9)]
    ],
    celular: [
      '',
      [Validators.required, Validators.maxLength(9), Validators.minLength(9)]
    ],
    descuento: ['0', ],
    credito: ['', ],
    lineaCredito: ['', ],
    formaPago: ['', [Validators.required]],
    referencia: ['', [Validators.required, Validators.maxLength(200)]]
  })

  // formatoRut() {
  //   if (this.tamano_rut.length >= 10 && this.tamano_rut.length < 11) {
  //     const rut = this.infBasica.value.rut
  //     const limpiarRut = clearRUT(rut)
  //     const validacionRut = validateRUT(limpiarRut)
  //     if (validacionRut == true) {
  //       console.log('El rut es valido para ser registrado')
  //       this.validarRut()
  //     } else {
  //       this.snackBar.open('Rut es invalido', 'X', {
  //         duration: 2000,
  //         verticalPosition: 'top'
  //       })
  //     }
  //   } else {
  //   }
  // }

  // validarRut() {
  //   this.clienteService.getAllClientes().subscribe(
  //     (data: any) => {
  //       for (let i = 0; i < data.length; i++) {
  //         if (data[i].rut === this.infBasica.value.rut) {
  //           this.snackBar.open('Rut ya existe', 'X', {
  //             verticalPosition: 'top'
  //           })
  //           break
  //         } else {
  //           // this.snackBar.open('Rut no existe', 'X', {
  //           //   duration: 2000,
  //           //   verticalPosition: 'top'
  //           // })
  //         }
  //       }
  //     },
  //     (err) => {
  //       console.log(err)
  //     }
  //   )
  // }

  generateRandomString = (num) => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    const charactersLength = characters.length
    for (let i = 0; i < num; i++) {
      this.result1 += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return  this.result1

  }



  onSubmit() {
    this.form = this._formBuilder.group(
      Object.assign(
        this.infAdicional.value,
        this.infPago.value,
        this.infUbicacion.value,
        this.infBasica.value
      )
    )
    switch (this.form.status) {
      case 'VALID':
        this.subido = true

        this.modelo.nombre = this.form.value.nombre
        this.modelo.alias = this.form.value.alias
        this.modelo.rut = this.form.value.rut
        this.modelo.giro = this.form.value.giro
        this.modelo.codigo = this.form.value.codigo
        this.modelo.direccion = this.form.value.direccion
        this.modelo.ciudad = this.form.value.ciudad
        this.modelo.comuna = this.form.value.comuna
        this.modelo.nombreResponsable = this.form.value.nombreResponsable
        this.modelo.email = this.form.value.email
        this.modelo.telefono = this.form.value.telefono
        this.modelo.contactoComercial = this.form.value.contactoComercial
        this.modelo.emailComercial = this.form.value.emailComercial
        this.modelo.telefonoComercial = this.form.value.telefonoComercial
        this.modelo.celular = this.form.value.celular
        this.modelo.descuento = this.form.value.descuento
        this.modelo.credito = this.form.value.credito
        this.modelo.lineaCredito = this.form.value.lineaCredito
        this.modelo.formaPago = this.form.value.formaPago
        this.modelo.referencia = this.form.value.referencia
        this.modelo.estado = this.form.value.estado

        //Subir al servicio
        this.clienteService
          .registrarClientes(this.modelo)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.snackBar.open('Cliente  registrado con exito', 'cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })
              window.location.reload()
            },
            (error: any) => {
              this.snackBar.open(
                'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              this.snackBar.open(error, 'cerrar', {
                duration: 3000,
                verticalPosition: 'top'
              })
            }
          )
        break
      case 'INVALID':
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }
 // En tu archivo clientes-form.component.ts

 formatoRut() {
  let rut = this.infBasica.value.rut;

  // Paso 1: Limpiar el RUT de cualquier formato ingresado incorrectamente
  rut = this.clearRUT(rut); // Elimina puntos y guion
  
  // Verificar longitud mínima y máxima para personas y empresas
  if (rut && rut.length >= 8 && rut.length <= 9) {
    const rutConGuion = this.formatearRut(rut); // Agregar el guion en el lugar correcto

    if (this.validateRUT(rutConGuion)) {
      console.log('El rut es válido para ser registrado');
      this.infBasica.get('rut')?.setValue(rutConGuion); // Actualizar el RUT con el formato correcto en el campo
      this.verificarRutEnBaseDeDatos(rutConGuion); // Verificar si el RUT ya está en la base de datos
    } else {
      this.snackBar.open('Rut es inválido', 'X', {
        duration: 2000,
        verticalPosition: 'top'
      });
    }
  } else {
    this.snackBar.open('El RUT debe tener entre 8 y 9 caracteres antes del dígito verificador', 'X', {
      duration: 2000,
      verticalPosition: 'top'
    });
  }
}

// Función para limpiar el RUT (elimina puntos y guion)
clearRUT(rut: string): string {
  return rut.replace(/\./g, '').replace('-', '');
}

// Función para formatear el RUT agregando el guion antes del dígito verificador
formatearRut(rut: string): string {
  return rut.slice(0, -1) + '-' + rut.slice(-1); // Inserta el guion antes del último dígito
}

// Verificar si el dígito verificador del RUT es válido
validateRUT(rut: string): boolean {
  const [rutSinDigito, digitoVerificador] = rut.split('-');
  if (!rutSinDigito || !digitoVerificador) return false;

  let suma = 0;
  let multiplicador = 2;

  for (let i = rutSinDigito.length - 1; i >= 0; i--) {
    suma += +rutSinDigito[i] * multiplicador;
    multiplicador = multiplicador < 7 ? multiplicador + 1 : 2;
  }

  const dvEsperado = 11 - (suma % 11);
  const dvCalculado = dvEsperado === 11 ? '0' : dvEsperado === 10 ? 'k' : dvEsperado.toString();

  return digitoVerificador.toLowerCase() === dvCalculado;
}

// Función para verificar si el RUT ya existe en la base de datos
verificarRutEnBaseDeDatos(rut: string) {
  this.clienteService.getAllClientes().subscribe(
    (data: any) => {
      const rutExiste = data.some((cliente: any) => cliente.rut === rut);

      if (rutExiste) {
        this.snackBar.open('Rut ya existe en los registros', 'X', {
          duration: 2000,
          verticalPosition: 'top'
        });
      } else {
        this.buscarRutEnSii(rut); // Solo llama al SII si el RUT no está registrado
      }
    },
    (error) => {
      console.error('Error al verificar RUT en la base de datos', error);
    }
  );
}

// Función para llamar al SII si el RUT no existe en la base de datos
buscarRutEnSii(rut: string) {
  this.cargando = true;

  this.clienteService.consultarContribuyente(rut).subscribe(
    (data: any) => {
      this.cargando = false;

      if (data) {
        this.infBasica.patchValue({
          nombre: data.razonSocial,
          alias: data.nombreFantasia !== 'Sin datos' ? data.nombreFantasia : '',
          giro: data.giro
        });

        if (data.domicilios && data.domicilios.length > 0) {
          const domicilio = data.domicilios[0];
          this.infUbicacion.patchValue({
            direccion: domicilio.direccion,
            ciudad: domicilio.ciudad,
            comuna: domicilio.comuna
          });
        }

        this.snackBar.open('Datos encontrados en SII', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        });
      } else {
        this.snackBar.open('No se encontraron datos en el SII para este RUT', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        });
      }
    },
    (error) => {
      this.cargando = false;
      console.error('Error al consultar RUT en el SII', error);
      this.snackBar.open('Error al buscar el RUT en el SII', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      });
    }
  );
}

}
