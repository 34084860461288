import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ComponentsModule } from '@app/_components/components.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FullCalendarModule } from '@fullcalendar/angular'
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '@app/material.module';
import { CesionarioFormComponent } from './cesion-home/cesionario-form/cesionario-form.component'
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTabsModule } from '@angular/material/tabs';
import { VerCesionComponent } from './cesion-home/cesion-table/ver-cesion/ver-cesion.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CesionTableComponent } from './cesion-home/cesion-table/cesion-table.component';
import { CesionHomeComponent } from './cesion-home/cesion-home.component';
import { CesionRoutingModule } from './cesion-routing.module'

@NgModule({
  declarations: [ CesionHomeComponent, CesionTableComponent, VerCesionComponent, CesionarioFormComponent ],
  imports: [
    CommonModule,
    CesionRoutingModule,
    MatTabsModule,
    MaterialModule,
    HttpClientModule,
    ComponentsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FormsModule,
    MatTableExporterModule,
    NgSelectModule,
  ],
  exports: [
    CesionRoutingModule,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class CesionModule {}
