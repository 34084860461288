export class StockNew {
  fecha!: string
   ndocumento!: string
  idBodega!: number
  observacion!: string
  idProveedor!: number
  ubicacion!: string
  tipoAjuste!: string
  idProducto!: number
  cantidad!: number
  costoUnitario!: number
  subTotal!: number
  stockActual!: number
  // ajusteStockActual!: number
  estado!: string
  idAjusteInventario!: number
  estadoActual!:string
 
}