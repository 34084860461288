import { Component, ElementRef, EventEmitter, OnInit, Output, VERSION, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertHelper } from '@app/_helpers/alert.helper';
import { FolioService } from '../../folio.service';
import { modeloFolio } from '../../../../_models/folio/folio';
import { first } from 'rxjs/operators';
import { Dte } from '@app/_models/folio/dte';
import Swal from 'sweetalert2';
import { Empresa } from '@app/_models/empresas/empresas-simpleapi.interface'
import { EmpresasSimpleapiService } from '@app/_pages/shared/shared-services/empresas-simpleapi.service'

@Component({
  selector: 'app-folio-form',
  templateUrl: './folio-form.component.html',
  styleUrls: ['./folio-form.component.scss']
})
export class FolioFormComponent implements OnInit {
  @ViewChild('f_input')
  myInputVariable: ElementRef;

  reset() {
    this.myInputVariable.nativeElement.value = "";  
  }
  // termino del codigo input file
  @Output() formularioListo = new EventEmitter<string>()
  subido = false
  form!: FormGroup
  dtes: Dte[] = [];
  srcResult: any;
  //Modelo de folio
  modelo: modeloFolio = new modeloFolio()
  formCafXml: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertHelper,
    private folioService: FolioService,
    private snackBar: MatSnackBar,
    private _empresaListService : EmpresasSimpleapiService,
  ) {
    this.buildForm()
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      totalDisponible: null,
      codigo: ['', [Validators.required]],
      numero: ['', [Validators.required]],
      alerta: ['', [Validators.required]],
      empresa: ['', [Validators.required]]
    })
  }

  display: FormControl = new FormControl("", Validators.required);
  numeroAlerta: FormControl = new FormControl("", Validators.required);
  empresaSeleccionada: Empresa | null = null;
  empresas: Empresa[];

  sinConsultasb: boolean = false;
  folioAgregado: boolean = false;
  folioAgregadoArchivo: boolean = false;
  subidoArchivo: boolean = false;
  file_store: any;
  file_list: Array<string> = [];
  handleFileInputChange(l: FileList): void {
    
    this.file_store = l;
    if (l.length) {
      const f = l[0];
      const count = l.length > 1 ? `(+${l.length - 1} files)` : "";
      this.display.patchValue(`${f.name}${count}`);
    } else {
      this.display.patchValue({
        display: '',
      })
    }
  }
  handleSubmit(): void {
    if( this.file_store.length > 0  && this.numeroAlerta.value ){
      this.subidoArchivo = true;
      this.folioAgregadoArchivo = false;
      var fd = new FormData();
      this.file_list = [];
      for (let i = 0; i < this.file_store.length; i++) {
        fd.append("files", this.file_store[i], this.file_store[i].name);
        fd.append("alerta", this.numeroAlerta.value);
        this.file_list.push(this.file_store[i].name);
      }
      this.folioService.sendCafFile(fd).subscribe(res=>{
        if(res.data=='ok'){
          this.subidoArchivo = false;
          this.folioAgregadoArchivo = true;
          this.display.patchValue({
            display: '',
          })
          this.numeroAlerta.patchValue({
            numeroAlerta: '',
          })
          this.reset()
          this.display.reset()
          this.numeroAlerta.reset()
          this.folioService.addResultFolio('nuevo')
        }else if(res.data=='alerta es mayor'){
          this.subidoArchivo = false;
          this.folioAgregadoArchivo = false;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'La alerta no puede ser mayor que los folios disponibles!',
          })
        }
      },(error: any) => {
        this.subidoArchivo = false;
        this.folioAgregadoArchivo = false;
      })
    }else{
      this.snackBar.open('Debe completar el Formulario', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      return;
    }
  }

  ngOnInit(): void {
    this.getDtes()
    this.getEmpresa()
    this.sinConsultas()
  }

  getDtes(){
    this.folioService
    .getAllDtes()
    .pipe(first())
    .subscribe((dtes: Dte[]) => {
        this.dtes = dtes;
        console.log("dtess",this.dtes)
    });
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
  
    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
  
      reader.onload = (e: any) => {
        this.srcResult = e.target.result;
      };
  
      reader.readAsArrayBuffer(inputNode.files[0]);
    }
  }

  async seleccionarEmpresa(event: any) {
    const rutEmpresaSeleccionada = event.value;
    this.empresaSeleccionada = this.empresas.find(emp => emp.rut === rutEmpresaSeleccionada);
    console.log('Empresa seleccionada:', this.empresaSeleccionada);
    
  }

  sinConsultas(){
    // this.sinConsultasb = true;
  }


  getEmpresa() {
    this.empresas = this._empresaListService.getEmpresas();
  }

  onSubmit() {
    this.folioAgregado = false;
    switch (this.form.status) {
      case 'VALID':
        this.subido = true
        this.modelo.tipoDocumento = this.form.value.empresa
        this.modelo.alerta = this.form.value.alerta
        this.modelo.codigo = this.form.value.codigo
        this.modelo.totalDisponible = this.form.value.totalDisponible

        console.log("ESTE ES EL MODELO",this.modelo);

        this.folioService
          .solicitarCaf(this.form.value, this.empresaSeleccionada)
          .pipe()
          .subscribe(
            (data: any) => {
              console.log(data)
              if(data['data']=='alerta es mayor'){
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'La alerta no puede ser mayor que el número de folios a solicitar o igual!',
                })
                this.subido = false
              }else if(data['data']=='ok'){
                this.subido = false
                this.folioAgregado = true;
                 this.snackBar.open('folio agregado con exito', 'cerrar', {
                  duration: 5000,
                  verticalPosition: 'top'
                })
                this.folioService.addResultFolio('nuevo')
                this.form.reset()
                this.form.patchValue({
                  alerta: '',
                  numero: ''
                })
              }else if(data['resultado'].mensaje == 'Cantidad solicitada supera lo permitido'){
                if(data['resultado'].maximoPermitido>1){
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${data['resultado'].mensaje}, Máximo permitido: ${data['resultado'].maximoPermitido} folios`,
                  })
                }else{
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${data['resultado'].mensaje}, Máximo permitido: ${data['resultado'].maximoPermitido} folio`,
                  })
                }
                this.subido = false
              }
              else{
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'No se puede ejecutar la petición al SII intente más tarde!',
                })
                this.subido = false
              }
            },
            (error: any) => {
              this.snackBar.open(
                'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )

              this.snackBar.open(error, 'cerrar', {
                duration: 3000,
                verticalPosition: 'top'
              })
              this.subido = false
            }
          )

        break
      case 'INVALID':
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break
      default:
        break
    }
  }
}
