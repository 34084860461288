import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsolidadoBancarioHomeComponent } from './consolidado-bancario-home/consolidado-bancario-home.component';
import { ConsolidadoBancarioSolicitudComponent } from './consolidado-bancario-solicitud/consolidado-bancario-solicitud.component';
import { ConsolidadoBancarioPagoComponent } from './consolidado-bancario-pago/consolidado-bancario-pago.component';
import { HomeFormComponent } from './consolidado-bancario-home/home-form/home-form.component';
import { ConsolidadoBancarioGrafComponent } from './consolidado-bancario-graf/consolidado-bancario-graf.component';
import { ConsolidadoDiarioPagoGastoComponent } from './consolidado-diario-pago-gasto/consolidado-diario-pago-gasto.component';
//import {GastosFijosComponent}from './gastos-fijos/gastos-fijos.component';



const routes: Routes = [
  { path: 'consolidado-bancario-graf', component: ConsolidadoBancarioGrafComponent },
  { path: 'consolidado-diario-pago-gasto', component: ConsolidadoDiarioPagoGastoComponent },
  //{ path: 'gastos-fijos', component: GastosFijosComponent },
  { path: 'consolidadoBancario', component: ConsolidadoBancarioHomeComponent },
  { path: 'consolidadoBancario/formCuentaBancaria', component: HomeFormComponent },
  { path: '', component: ConsolidadoBancarioSolicitudComponent },
  { path: 'consolidadoBancario/pagoOrdenCompra', component: ConsolidadoBancarioPagoComponent },

]


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsolidadoBancarioRoutingModule { }
