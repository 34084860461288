import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'
import { ProyectoAgrofirma } from '@app/_models/agroFirma/proyectoAgroFirma'
import { EgresoAgroFirma } from '@app/_models/agroFirma/egresoAgroFirma'
import { BehaviorSubject, Observable } from 'rxjs'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { IngresoAgroFirma } from '@app/_models/agroFirma/ingresoAgroFirma'
import { AgroFirmaEgresosCuotasComponent } from './agro-firma-egresos/agro-firma-egresos-list/agro-firma-egresos-cuotas/agro-firma-egresos-cuotas.component'
import { AgroFirmaEgresosCuotaDialogComponent } from './agro-firma-egresos/agro-firma-egresos-list/agro-firma-egresos-cuotas/agro-firma-egresos-cuota-dialog/agro-firma-egresos-cuota-dialog.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog'
import { EgresoAgroFirmaCuota } from '@app/_models/agroFirma/egresoAgroFirmaCuota'
import { ConsolidadosAgroFirma } from '@app/_models/agroFirma/consolidados-agro-firma'
import { AgroFirmaEgresosEditComponent } from './agro-firma-egresos/agro-firma-egresos-list/agro-firma-egresos-edit/agro-firma-egresos-edit.component'
import { UsuarioSharedService } from '../shared/shared-services/usuario-shared.service'
import { SucursalSharedService } from '../shared/shared-services/sucursal-shared.service'
import { AgroFirmaIngresoAccionesComponent } from './agro-firma-ingresos/agro-firma-ingresos-list/agro-firma-ingreso-acciones/agro-firma-ingreso-acciones.component'
import { AgroFirmaEgresoLisVerImgComponent } from './agro-firma-egresos/agro-firma-egresos-list/agro-firma-egreso-lis-ver-img/agro-firma-egreso-lis-ver-img.component'

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'

@Injectable({
  providedIn: 'root'
})
export class AgroFirmaService {
  private proyecto = 'obtenerProyecto'

  //egresos values
  public tiposEgresosList: Observable<string[]>

  //egresos values
  private tiposEgresosListSubject: BehaviorSubject<string[]>
  //proyectos values
  private proyectosListSubject: BehaviorSubject<ProyectoAgrofirma[]>

  public proyectosList: Observable<ProyectoAgrofirma[]>

  private tiposEgresos = [
    'Gastos',
    'Costos',
    'Gastos Fijos',
    'Remuneraciones',
    'Impuestos',
    'Bancarios',
    'Prestamos Bancarios',
    'Prestamos Automotriz'
  ]
  private empresa = 'AgroFirma'

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioSharedService,
    private sucursalService: SucursalSharedService
  ) {
    //egresos;
    this.tiposEgresosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tiposEgresos')!)
    )

    this.proyectosListSubject = new BehaviorSubject<ProyectoAgrofirma[]>(
      JSON.parse(localStorage.getItem('proyectos')!)
    )

    //egresos;
    this.tiposEgresosList = this.tiposEgresosListSubject.asObservable()
    this.proyectosList = this.proyectosListSubject.asObservable()
    localStorage.setItem('tiposEgresos', JSON.stringify(this.tiposEgresos))
  }

  //egresos values get methods:
  public get tiposEgresosListValue(): string[] {
    return this.tiposEgresosListSubject.value
  }

  //proyectos values methods:
  public get proyectosListValue(): ProyectoAgrofirma[] {
    return this.proyectosListSubject.value
  }

  // METODO PARA EXPORTAR EXCEL
  public async exportAsExcelFile(
    json: any[],
    excelFileName: string,
    selectedColumns: string[]
  ): Promise<void> {
    const newData = await Promise.all(
      json.map(async (item) => {
        const newItem: any = {}
        for (const col of selectedColumns) {
          if (col === 'idUsuario') {
            if (item[col] !== null) {
              console.log('entro')
              try {
                console.log(item[col])
                const usuario = await this.usuarioService
                  .getById(item[col])
                  .toPromise()
                console.log(usuario)
                newItem[col] = usuario.nombre
              } catch (error) {
                console.error('Error al obtener el nombre del usuario:', error)
                newItem[col] = ''
              }
            } else {
              newItem[col] = ''
            }
          } else if (col === 'fecha') {
            if (item[col] !== null) {
              try {
                const fecha = new Date(item[col])
                const formattedFecha = `${fecha.getDate()}/${
                  fecha.getMonth() + 1
                }/${fecha.getFullYear()}`
                newItem[col] = formattedFecha
              } catch (error) {
                console.error('Error al obtener la fecha:', error)
                newItem[col] = ''
              }
            } else {
              newItem[col] = ''
            }
          } else {
            newItem[col] = item[col]
          }
        }
        return newItem
      })
    )

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  public async exportAsExcelFileE(
    json: any[],
    excelFileName: string,
    selectedColumns: string[]
  ): Promise<void> {
    const columnMappings = {
      id: 'ID',
      tipoIngreso: 'Tipo de Ingreso',
      fecha: 'Fecha',
      monto: 'Monto',
      //'tipoCliente': 'Cliente',
      nAutorizacion: 'Autorización',
      //'responsable': 'Responsable',
      descripcionIngreso: 'Descripción',
      tipoPago: 'Tipo de Pago',
      cliente: 'Cliente',
      idUsuario: 'Usuario',
      idSucursal: 'Sucursal',
      referenciaCliente: 'Referencia Cliente'
    }
    console.log('el excel', columnMappings)

    const newData = await Promise.all(
      json.map(async (item) => {
        const newItem: any = {}
        for (const col of selectedColumns) {
          if (col === 'idSucursal') {
            if (item[col] !== null) {
              try {
                const sucursal = await this.sucursalService
                  .getById(item[col])
                  .toPromise()
                newItem[columnMappings[col]] = sucursal.razonSocial
              } catch (error) {
                console.error(
                  'Error al obtener el nombre de la sucursal:',
                  error
                )
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else if (col === 'idUsuario') {
            if (item[col] !== null) {
              try {
                const usuario = await this.usuarioService
                  .getById(item[col])
                  .toPromise()
                newItem[columnMappings[col]] = usuario.nombre + usuario.apellido
              } catch (error) {
                console.error('Error al obtener el nombre del usuario:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else if (col === 'fecha') {
            if (item[col] !== null) {
              try {
                const fecha = new Date(item[col])
                const formattedFecha = `${fecha.getDate()}/${
                  fecha.getMonth() + 1
                }/${fecha.getFullYear()}`
                newItem[columnMappings[col]] = formattedFecha
              } catch (error) {
                console.error('Error al obtener la fecha:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else {
            newItem[columnMappings[col]] = item[col]
          }
        }

        return newItem
      })
    )

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData)

    const headerRange = XLSX.utils.decode_range(worksheet['!ref'])
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: C })
      // Limpiar cualquier estilo existente
      worksheet[headerCell].s = {}
      // Aplicar nuevo estilo
      worksheet[headerCell].s = {
        fill: { fgColor: { rgb: 'FFFF00' } },
        font: { bold: true }
      }
    }

    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + 'export' + new Date().getTime() + EXCEL_EXTENSION
    )
  }

  /** Consolidados */
  buscarConsolidado(consolidado: ConsolidadosAgroFirma): any {
    return this.http.post(
      `${environment.apiUrl}/ingreso${this.empresa}/ingresosEgresos`,
      consolidado
    )
  }

  //----------Metodos Egresos------------

  // Primer metodo antes de los cambios
  // egresoGetAll(): any {
  //   return this.http.get<EgresoAgroFirma[]>(`${environment.apiUrl}/obtenerEgresos/:idProyecto`)
  // }

  //Segundo metodo testeando
  // egresoGetAll(): any {
  //   return this.http.get<EgresoAgroFirma[]>(`${environment.apiUrl}/egresoAgrofirma/obtenerEgresos`)
  // }

  egresoGetAll(): any {
    return this.http.get<EgresoAgroFirma[]>(
      `${environment.apiUrl}/egreso${this.empresa}`
    )
  }

  egresoRegistrar(egreso: EgresoAgroFirma): any {
    console.log(egreso)
    return this.http.post(
      `${environment.apiUrl}/egreso${this.empresa}/registrarEgreso`,
      egreso
    )
  }

  getIncomeByProject(projectId: number): any {
    return this.http.get<EgresoAgroFirma[]>(
      `${environment.apiUrl}/egresoAgrofirma/obtenerEgresos/${projectId}`
    )
  }

  getById(id: string): any {
    return this.http.get<any>(
      `${environment.apiUrl}/egreso${this.proyecto}/${id}`
    )
  }

  obtenerEgreso(id: string): any {
    return this.http.get<EgresoAgroFirma>(
      `${environment.apiUrl}/egresoAgrofirma/${id}`
    )
  }

  buscarImagen(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoAgrofirma/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  /** metodo actualizar datos editados de una agro */
  updateAgroFirmaEgreso(id: any, params: any[]) {
    return this.http.put(`${environment.apiUrl}/egresoAgrofirma/${id}`, params)
  }

  //-----------Metodos Ingresos---------------//

  registrarIngreso(ingreso: IngresoAgroFirma): any {
    return this.http.post(
      `${environment.apiUrl}/ingresoAgrofirma/registrarIngreso`,
      ingreso
    )
  }

  getCountsProyects(idProyect: number) {
    return this.http.get(
      `${environment.apiUrl}/ingresoAgrofirma/getProjectAccounts/${idProyect}`
    )
  }

  obtenerIngresos(id: number): Observable<IngresoAgroFirma[]> {
    return this.http.get<[]>(
      `${environment.apiUrl}/ingresoAgrofirma/obtenerIngresos/${id}`
    )
  }

  obtenerIngresosPorProyecto(idProyecto: number): any {
    return this.http.get(
      `${environment.apiUrl}/ingresoAgrofirma/obtenerIngresosByProyecto/${idProyecto}`
    )
  }

  //----------Metodos Proyectos--------------//

  GetAllProyectos(): any {
    return this.http.get<[]>(
      `${environment.apiUrl}/proyectoAgrofirma/obtenerProyectos`
    )
  }

  createProject(project: any): any {
    return this.http.post<[]>(
      `${environment.apiUrl}/proyectoAgrofirma/registrarProyecto`,
      project
    )
  }

  updateProject(projectId: any, project: any): any {
    return this.http.put<[]>(
      `${environment.apiUrl}/proyectoAgrofirma/actualizarProyecto/${projectId}`,
      project
    )
  }

  //----------Metodos Cuentas Bancarias-----------//

  getBankAccountsById(id: any): any {
    return this.http.get<[]>(
      `${environment.apiUrl}/banco/obtenerCuentasByEntity/${id}`
    )
  }

  createBankAccount(account: any): any {
    return this.http.post<[]>(
      `${environment.apiUrl}/banco/registrarCuentasBancarias`,
      account
    )
  }

  getBanks(): any {
    return this.http.get<[]>(`${environment.apiUrl}/banco/obtenerBancos`)
  }
  /** EGRESOS POR CUOTAS */
  getCuotas(id: any) {
    return this.http.get<AgroFirmaEgresosCuotasComponent>(
      `${environment.apiUrl}/egresoAgroFirmaCuota/${id}`
    )
  }

  buscarImagenCuota(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoAgroFirmaCuota/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  agregarRespaldos(arrayRespaldos: any): any {
    return this.http.post(
      `${environment.apiUrl}/egresoAgroFirmaCuota/agregarRespaldos/`,
      arrayRespaldos
    )
  }
  buscarImagenC(id: any): any {
    return this.http.get<AgroFirmaEgresosCuotasComponent>(
      `${environment.apiUrl}/respaldoEgresoAgroFirmaCuota/${id}`
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogCuota(): void {
    const dialogRef = this.dialog.open(AgroFirmaEgresosCuotaDialogComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  updateMonto(id: any, body: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egresoAgroFirmaCuota/${id}`,
      body
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogRegistrarPago(idEgreso: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idEgreso != null) {
      const dialogRef = this.dialog.open(AgroFirmaEgresosCuotasComponent, {})
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open(
        'Por favor seleccione un egreso con cuotas sin pagar',
        'cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }

  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idEgresoPago')
  }

  //Calendario
  buscarCuotas(): any {
    return this.http.get<EgresoAgroFirmaCuota>(
      `${environment.apiUrl}/egresoAgroFirmaCuota/`
    )
  }
  // Metodo que permite abrir un Dialog (Modal)-->editar
  openDialogEditEgreso(): void {
    const dialogRef = this.dialog.open(AgroFirmaEgresosEditComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }
  openDialogEditIngresoAgrofirma(): void {
    const dialogRef = this.dialog.open(AgroFirmaIngresoAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }
  openDialogVerIMGEgreso(): void {
    const dialogRef = this.dialog.open(AgroFirmaEgresoLisVerImgComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  deleteEgreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/egresoAgrofirma/${id}`)
  }
  createCredito(egresoAgrofirma: EgresoAgroFirma): any {
    try {
      return this.http.post(
        `${environment.apiUrl}/egresoAgrofirma/createEgresoHome/`,
        egresoAgrofirma
      )
    } catch (error) {
      console.log(error)
      return error
    }
  }
  //Métodos para gestionar transacciones webpay
  pagar(idCuota: number, monto: number, empresa: string) {
    let body = {
      idCuota,
      monto,
      empresa
    }
    return this.http.post(`${environment.apiUrl}/egresoAgrofirma/pagar`, body)
  }
  confirmar(token: string) {
    console.log('HostalService token: ', token)
    let body = { token }
    console.log('Antes de hhtp post')
    return this.http.post(
      `${environment.apiUrl}/egresoAgrofirma/confirmar`,
      body
    )
  }
  actualizarPagado(id: number): any {
    let body = {
      id
    }
    return this.http.post(
      `${environment.apiUrl}/egresoAgrofirmaCuota/actualizarPagado/`,
      body
    )
  }

  getIngreso(id: string): any {
    return this.http.get<IngresoAgroFirma>(
      `${environment.apiUrl}/ingresoAgrofirma/${id}`
    )
  }
  updateIngresoAgroFirma(id: string, params: any[]) {
    return this.http.put(`${environment.apiUrl}/ingresoAgrofirma/${id}`, params)
  }
  getIngresoSinBase64(id: string): any {
    return this.http.get<IngresoAgroFirma>(
      `${environment.apiUrl}/ingresoAgrofirma/sinBase64/${id}`
    )
  }

  getIngresoByIdSinBase64(id: string): any {
    return this.http.get<IngresoAgroFirma>(
      `${environment.apiUrl}/ingresoAgrofirma/sinBase64/${id}`
    )
  }
  deleteIngreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/ingresoAgrofirma/${id}`)
  }

  //* para ver la imagen en egresos agrofirma
  getEgresoByID(id : string){
  return this.http.get<EgresoAgroFirma>(
    `${environment.apiUrl}/egresoAgrofirma/getImg/${id}`
  )
  }
}
