<mat-toolbar color="primary">
  <!-- <button style="margin-right: 8rem" mat-raised-button (click)="limpiarFiltros()" color="accent">
        Limpiar filtros
  </button> -->
    <span style="margin-right: 8rem; margin-left: 8rem"> Transferencias entre Bodegas </span>
    <!-- ***************************** cambiar metodo botón ********************************** -->
    <button mat-raised-button color="accent" style="margin-left: 8rem" (click)="exportAsXLSX()">
        Exportar
    </button>
    <button mat-raised-button color="accent" style="margin-left: 1rem" (click)="getTransferencias()">
        <mat-icon>autorenew</mat-icon>
    </button>
</mat-toolbar>


<div class="filtros">
    <div class="btn-filtro">
        <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="bold" >Todos</mat-button-toggle>
            <mat-button-toggle value="italic">Vigente</mat-button-toggle>
            <mat-button-toggle value="underline" >No vigente</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <mat-form-field appearance="standard">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input autocomplete="off">
    </mat-form-field>
</div>     

<div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">
        <!-- check column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        
        <!-- data columns -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID </th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Fecha_transferencia -->
        <ng-container matColumnDef="fecha_transferencia">
            <th mat-header-cell *matHeaderCellDef>Fecha de transferencia </th>
            <td mat-cell *matCellDef="let element">{{ element.fechaTransferencia | date }}</td>
        </ng-container>
        
        <!-- Origen -->
        <ng-container matColumnDef="origen">
            <th mat-header-cell *matHeaderCellDef>Origen</th>
            <td mat-cell *matCellDef="let element">{{ element.bodegaInicio }}</td>
        </ng-container>


         <!-- Destino -->
         <ng-container matColumnDef="destino">
            <th mat-header-cell *matHeaderCellDef>
                Destino
            </th>
            <td mat-cell *matCellDef="let element">{{ element.bodegaDestino }}</td>
        </ng-container>

        <!-- Usuario -->
        <ng-container matColumnDef="usuario">
            <th mat-header-cell *matHeaderCellDef>
                Usuario
            </th>
            <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <!-- Guias -->
        <ng-container matColumnDef="guias_asociadas">
            <th mat-header-cell *matHeaderCellDef>
                Guías Asociadas
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.guias}}</td>
        </ng-container>

        <!-- Botones -->
        <ng-container matColumnDef="botones">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let element">
                <mat-button-toggle-group appearance="legacy">
                    <mat-button-toggle (click)="openDialogEdit(element.id)" matTooltip="Ver">
                        <mat-icon>remove_red_eye</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle routerLink="/despacho/despacho/form"  matTooltip="Generar guía de despacho">
                        <mat-icon> local_taxi</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle (click)="delete(element.id, element.Usuario.nombre)" matTooltip="borrar">
                        <mat-icon>delete</mat-icon>
                    </mat-button-toggle>
                   
                </mat-button-toggle-group>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No existen coincidencias</td>
        </tr>
    </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>