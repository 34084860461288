import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { modeloCompras } from '../../../../_models/compras/compras'
import { ComprasService } from '../../compras.service'
import { first } from 'rxjs/operators'
import { MatSnackBar } from '@angular/material/snack-bar'
import { modeloComprasDia } from '@app/_models/compras/comprasDia'

@Component({
  selector: 'app-compras-table',
  templateUrl: './compras-table.component.html',
  styleUrls: ['./compras-table.component.scss']
})
export class ComprasTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort = null
  mesAño: any
  form!: FormGroup
  // ? Inputs & Outputs
  @Input() refrescar = ''
  //------inicio tabla-------
  displayedColumns: string[] = [
    'id',
    'proveedor',
    'rutProveedor',
    'tipoDocumento',
    'folio',
    'fechaEmision',
    'montoTotal',
    'estado',
    'acciones'
  ]

  dataSource: MatTableDataSource<modeloCompras> = new MatTableDataSource()
  dataCompras: modeloCompras[] = []
  dataConfirmado: modeloCompras[] = []
  dataPendiente: modeloCompras[] = []

  //Modelo de cliente
  modeloDia: modeloComprasDia = new modeloComprasDia()

  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private comprasService: ComprasService,
    private snackBar: MatSnackBar
  ) {
    this.buildForm()
  }

  ngOnInit(): void {
    this.getAllCompras()
  }

  getAllCompras() {
    this.comprasService.getAllCompras().subscribe(
      (data: any) => {
        this.dataCompras = data
        this.dataSource = new MatTableDataSource(this.dataCompras)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }
  //Boton para actualizar tabla de proveedores
  actualizar(): void {
    window.location.reload()
  }
  //Boton de busqueda
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  //Boton capturar mes y años
  private buildForm() {
    this.form = this._formBuilder.group({
      fechaFiltro: ['', [Validators.required]]
    })
  }

  Actualizar() {
    switch (this.form.status) {
      case 'VALID':
        this.modeloDia.fechaFiltro = this.form.value.fechaFiltro
        this.comprasService
          .ActualizarEstado(this.modeloDia)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.snackBar.open(
                'Consultado a SII esto tardara unos segundos',
                'cerrar',
                {
                  duration: 45000,
                  verticalPosition: 'top'
                }
              )
              setTimeout(() => {
                window.location.reload()
              }, 46000)
            },
            (error: any) => {
              this.snackBar.open(
                'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              this.snackBar.open(error, 'cerrar', {
                duration: 3000,
                verticalPosition: 'top'
              })
            }
          )

        break
      case 'INVALID':
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }

  onSubmit() {
    switch (this.form.status) {
      case 'VALID':
        this.modeloDia.fechaFiltro = this.form.value.fechaFiltro
        this.comprasService
          .fechaPostDia(this.modeloDia)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.snackBar.open(
                'Consultado a SII esto tardara unos segundos',
                'cerrar',
                {
                  duration: 40000,
                  verticalPosition: 'top'
                }
              )
              setTimeout(() => {
                window.location.reload()
              }, 41000)
            },
            (error: any) => {
              this.snackBar.open(
                'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              this.snackBar.open(error, 'cerrar', {
                duration: 3000,
                verticalPosition: 'top'
              })
            }
          )

        break
      case 'INVALID':
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }

  //Boton confirmado
  confirmado() {
    this.dataConfirmado = []
    this.comprasService.getAllCompras().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Confirmada') {
            this.dataConfirmado.push(element)
            this.dataSource = new MatTableDataSource(this.dataConfirmado)
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort
          }
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //Boton pendiente
  pendiente() {
    this.dataPendiente = []
    this.comprasService.getAllCompras().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Pendiente') {
            this.dataPendiente.push(element)
            this.dataSource = new MatTableDataSource(this.dataPendiente)
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort
          }
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //Boton mostrar todos los datos
  todos() {
    this.comprasService.getAllCompras().subscribe(
      (data: any) => {
        this.dataCompras = data
        this.dataSource = new MatTableDataSource(this.dataCompras)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )

  }

  //Boton de acciones
  accionesComprasSII(id: any) {
    localStorage.setItem('idCompras', id)
    this.comprasService.openDialogEditCompra()
  }


}
