import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from "@angular/core";
import { CategoriasRoutingModule } from './categorias-routing.module';
import { CategoriasHomeComponent } from './categorias-home/categorias-home.component';
import { CategoriasFormComponent } from './categorias-form/categorias-form.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { ComponentsModule } from '../../_components/components.module';
import { CategoriasListComponent } from './categorias-list/categorias-list.component';
import { DialogCategoriasEditarComponent } from './categorias-list/dialog-categorias-editar/dialog-categorias-editar.component';


@NgModule({
    declarations: [
        CategoriasHomeComponent,
        CategoriasFormComponent,
        CategoriasListComponent,
        DialogCategoriasEditarComponent,
        
    ],
    imports: [
        CategoriasRoutingModule,
        CommonModule,
        MaterialModule,
        ComponentsModule,
        ReactiveFormsModule
    ]
})

export class CategoriasModule {
    
}