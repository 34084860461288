import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProductosFormComponent } from "./productos-form/productos-form.component";
import { ProductosHomeComponent } from "./productos-home/productos-home.component";


const routes: Routes = [
    { path: 'productos', component: ProductosHomeComponent },
    { path: 'productos/form', component: ProductosFormComponent },
  ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })

export class ProductosRoutingModule{

}
