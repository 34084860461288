import { Producto } from '@app/_models/productos/producto';
import { Usuario } from '../../shared/usuario';


export class TransferenciaBodega {
    id!: number;
    fechaTransferencia!: Date;
    observacion!: string;
    bodegaInicio!: string;
    bodegaDestino!: string;
    createdAt!: Date;
    updatedAt!: Date;
    idBodega!: number;
    idUsuario!: number;
    estado!: string;
    Usuario!: Usuario;
    Producto!: Producto;
  }