<form
  [formGroup]="addressForm"
  class="mat-elevation-z10"
  novalidate
  (ngSubmit)="onSubmit()"
>
  <mat-card class="shipping-card">
    <mat-card-header>
      <h2 class="md-display-1">Transferencia entre bodegas</h2>
    </mat-card-header>

    <mat-card-content>
      <br /><br />
      <div class="row">
        <!-- Bodega de origen -->
        <div class="col">
          <ng-select
            placeholder="Bodega Inicio"
            bindLabel="name"
            formControlName="bodegaInicio"
            (change)="onBodegaChangeInicio($event)"
          >
            <ng-option *ngFor="let bodega of bodegas" [value]="bodega[1]">{{
              bodega[0]
            }}</ng-option>
          </ng-select>
        </div>

        <!-- Bodega de destino -->
        <div class="col">
          <ng-select
            placeholder="Bodega destino"
            bindLabel="name"
            formControlName="bodegaDestino"
             (change)="onInputChangeDestino($event)"
          >
            <ng-option *ngFor="let bodega of bodegas" [value]="bodega[1]">{{
              bodega[0]
            }}</ng-option>
          </ng-select>
        </div>

        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Fecha de transferencia</mat-label>
            <input
              matInput
              formControlName="fechaTransferencia"
              [matDatepicker]="picker"
              (dateInput)="addEvent('input', $event)"
              (dateChange)="addEvent('change', $event)"
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col text-observacion">
          <mat-form-field class="full-width">
            <mat-label>Observación</mat-label>
            <textarea
              matInput
              formControlName="observacion"
              placeholder="Ingrese observación"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>

<form
  [formGroup]="addressForm2"
  novalidate
  class="mat-elevation-z10"
  (ngSubmit)="onSubmit()"
>
  <mat-card class="shipping-card">
    <div class="row">
      <button mat-raised-button type="button" (click)="agregarProducto()">
        <mat-icon>library_add</mat-icon> Agregar Producto
      </button>
    </div>
    <br />
    <ng-container formArrayName="productos">
      <ng-container *ngFor="let producto of productos.controls; let i = index">
        <div [formGroupName]="i">
          <mat-card-content>
            <div class="row">
              <!-- Nombre Producto -->
              <div class="column-producto">
                <mat-form-field class="element-hijo-tamaño">
                  <mat-select
                    placeholder="Producto"
                    formControlName="productosSeleccionados"
                    [attr.for]="'productosSeleccionados' + i"
                    [attr.id]="'productosSeleccionados' + i"
                    (selectionChange)="onBodegaChangeProduct($event, i)"
                  >
                    <mat-option
                      *ngFor="let producto of lista_productos"
                      [value]="producto.id_producto"
                    >
                      {{ producto.nombre_producto }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="column-10">
                <mat-form-field class="element-hijo-tamaño">
                  <input
                    [attr.for]="'ubicacion' + i"
                    [attr.id]="'ubicacion' + i"
                    matInput
                    placeholder="Ubicación"
                    formControlName="ubicacion"
                    [(ngModel)]="dirreccionBodega"
                    readonly="true"
                  />
                </mat-form-field>
              </div>

              <div class="column-10">
                <mat-form-field class="element-hijo-tamaño">
                  <input
                    [attr.for]="'cantidad_inicial'"
                    [attr.id]="'cantidad_inicial'"
                    matInput
                    placeholder="Cantidad"
                    formControlName="cantidad_inicial"
                    type="number"
                  />
                </mat-form-field>
              </div>

              <div class="column-15">
                <mat-form-field class="element-hijo-tamaño">
                  <input
                    [attr.for]="'ubicacion_destino' + i"
                    [attr.id]="'ubicacion_destino' + i"
                    matInput
                    placeholder="Ubicación destino"
                    formControlName="ubicacion_destino"
                     [(ngModel)]="dirreccionBodegaDestino"
                    readonly="true"

                  />
                </mat-form-field>
              </div>

              <div class="column-10">
                <mat-form-field class="element-hijo-tamaño">
                  <input
                    [attr.for]="'cantidad' + i"
                    [attr.id]="'cantidad' + i"
                    matInput
                    placeholder="Cantidad"
                    formControlName="cantidad"
                    type="number"
                  />
                </mat-form-field>
              </div>
              <a
                class="btn cont-basurero"
                style="float: right; padding: 13px"
                (click)="eliminarProducto(i)"
              >
                <mat-icon class="mat-accent">delete_black_24dp</mat-icon>
              </a>
            </div>
          </mat-card-content>
        </div>
      </ng-container>
    </ng-container>

    <button mat-raised-button color="primary" type="submit">
      <mat-icon>send</mat-icon> Enviar
    </button>
  </mat-card>
</form>
