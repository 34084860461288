<mat-dialog-content>
  <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
    <mat-icon>highlight_off</mat-icon>
  </button>

  <div class="table-responsive" >
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z10" >

      <!--Imagen-->
        <ng-container matColumnDef="img">
            <th mat-header-cell *matHeaderCellDef>
            <div [formGroup]="formFilter">
                <mat-form-field class="fill">
                    <input  matInput placeholder="Imagen" formControlName="img" />
                </mat-form-field>
            </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.img !== null">
                    <img src="http://179.57.172.224:4141/verproductos/{{element.img}}" alt="">
                    

                </div>
                <div *ngIf="element.img === null">
                    <img src="../../../../../assets/images/ningún-icono-disponible-de-la-imagen-plano-ejemplo-del-vector-132484366.jpeg" alt="">
                </div>
            </td> 
        </ng-container>

        <!-- Nombres -->
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input  matInput placeholder="Nombre" formControlName="nombre" />
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.nombre }} <br> <div style="color: #707070" class="bottom">{{element.codigoSKU}}</div></td>

        </ng-container>

        <!-- Precio Venta -->
        <ng-container matColumnDef="venta">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input matInput placeholder="Precio Venta" formControlName="venta" />
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.venta | currency:'CLP':'symbol'}}</td>
        </ng-container>

        <!-- Botones -->
        <ng-container matColumnDef="botones">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <a  (click)="openDialogFotoSubir(element.id)" class="btn btn-primary" style="float: left" style="margin: 10px"><mat-icon>cloud_upload_black_24dp</mat-icon></a>
              <a (click)="borrarFoto(element.id)" class="btn btn-danger"><mat-icon>delete_black_24dp</mat-icon></a>
          </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No existen coincidencias</td>
        </tr>
    </table>
</div>

</mat-dialog-content>
