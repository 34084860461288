import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { modeloProveedor } from '@app/_models/proveedores/proveedores'
import { environment } from '@environments/environment'
import { ProveedoresTableEditComponent } from './proveedores-home/proveedores-table/proveedores-table-edit/proveedores-table-edit.component'

@Injectable({
  providedIn: 'root'
})
export class ProveedoresService {
  constructor(private http: HttpClient, private dialog: MatDialog) {}

  //Registar Proveedor
  registrarFormulario(modelo: modeloProveedor): any {
    return this.http.post(`${environment.apiUrl}/proveedores`, modelo)
  }

  //Llamar tabla de Proveedores
  getAllProveedores(): any {
    return this.http.get<[]>(`${environment.apiUrl}/proveedores`)
  }

  //Eliminar proveedor
  deleteProveedor(id: any) {
    return this.http.delete(`${environment.apiUrl}/proveedores/${id}`)
  }

  //Editar proveedor
  updateProveedor(id: any, params: any[]) {
    return this.http.put(`${environment.apiUrl}/proveedores/${id}`, params)
  }
  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditProveedor(): void {
    const dialogRef = this.dialog.open(ProveedoresTableEditComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  getById(id: string): any {
    return this.http.get<any>(`${environment.apiUrl}/proveedores/${id}`)
  }

  closeDialogModal(){
    this.dialog.closeAll();
    localStorage.removeItem("idProveedor");
  }
}
