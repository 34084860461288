import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Producto } from '@app/_models/productos/producto';
import { Variante } from '@app/_models/variante/variante';
import { VariantesService } from '@app/_pages/variantes/variantes.service';
import { first, groupBy } from 'rxjs/operators';
import { ProductosService } from '../../productos.service';

@Component({
  selector: 'app-dialog-variantes',
  templateUrl: './dialog-variantes.component.html',
  styleUrls: ['./dialog-variantes.component.scss']
})
export class DialogVariantesComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  dataSource: MatTableDataSource<Variante> = new MatTableDataSource();

  dataVariante: Variante[] = [];
  productos: Producto[] = [];
  idProducto: any;
  dataNuevo :any ;
  dataNuevo2 = [] ;
  dataNuevo3 :any ;
  variantesDelete!: any;

  constructor(
    private variantesService: VariantesService,
    private productosService: ProductosService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getProductos();
    this.idProducto = localStorage.getItem("idProductosEdit");
    this.getVariantes();

    this.variantesService
    .varianteGet()
    .pipe(first())
    .subscribe(
      (variantesDelete) => (this.variantesDelete = variantesDelete)
    );
    
  }

  displayedColumns: string[] = [
    'nombreVariante',
    'codigoSKUVariante',
    'estadoVariante',
    'unitarioVariante',
    'netoVariante',
    'botones',
  ];

  formFilter = new FormGroup({
    nombreVariante: new FormControl(),
    codigoSKUVariante: new FormControl(),
    estadoVariante: new FormControl(),
    unitarioVariante: new FormControl(),
    netoVariante: new FormControl(), 
    botones: new FormControl(),  
    })

    // Obtener los productos
    getProductos() {
      this.productosService
        .productoGet()
        .pipe(first())
        .subscribe((productos) => {
        this.productos = productos;
      });
    }
  
  getVariantes() {
     //Carga Tabla
     this.variantesService.varianteGet().pipe(first()).subscribe((result: Variante[]) => {
      this.dataNuevo= result
      for (let i = 0; i < this.dataNuevo.length; i++)
      {
        if(this.dataNuevo[i].idProducto == this.idProducto)
        {
          this.dataNuevo2.push(this.dataNuevo[i]);
          
        }
      }
    this.dataNuevo3 = this.dataNuevo2.length;
    console.log(this.dataNuevo2)
    this.dataSource = new MatTableDataSource(this.dataNuevo2);
    });
  }

  //Metodo eliminar una variante
    borrarVariante(id: any, nombre: any) {
      const variante = this.variantesDelete.find((x: any) => x.id === id)
      if (confirm('Esta seguro que desea eliminar el registro')) {
      variante.isDeleting = true
        this.variantesService
          .deleteVariante(id)
          .pipe(first())
          .subscribe(() => {
          this.dataVariante = this.dataVariante.filter((x: any) => x.id !== id)
          });
          variante.isDeleting = false;
          }
      this.snackBar.open('Variante eliminada', 'cerrar', {
      duration: 2000,
      verticalPosition: 'top'
      });
      }
  
}
