import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stockActual-data',
  templateUrl: './stockActual-data.component.html',
  styleUrls: ['./stockActual-data.component.scss']
})
export class StockActualDataComponent implements OnInit {


detallecategoria = 0

  constructor() { }

  ngOnInit() {

  }


}
