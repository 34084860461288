
<mat-tab-group mat-align-tabs="center">
    <mat-tab label="Detalle de Gasto Fijo 📝">
        <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
            <mat-icon>highlight_off</mat-icon>
        </button>
        <form [formGroup]="addressForm" novalidate class="mat-elevation-z10">
            <mat-card class="shipping-card">
                <mat-card-content>
                    <mat-card>
                        <h3>Detalle Gastos Fijos</h3>
                        <div class="row">
                            <!-- Sucursal -->
                            <div class="col">
                               <mat-form-field class="full-width">
                                   <input matInput placeholder="Sucursal" formControlName="idSucursal" [readonly]="true" [value]="razonSocialSucursal"  />
                               </mat-form-field>
                           </div>
                           <!-- Gasto Fijo -->
                           <div class="col">
                               <mat-form-field class="full-width">
                                   <input matInput placeholder="Gasto Fijo" formControlName="gasto" [readonly]="true" />
                               </mat-form-field>
                           </div>
                           <!-- Fecha -->
                           <div class="col">
                               <mat-form-field class="full-width">
                                   <input matInput placeholder="Fecha" formControlName="fecha" [readonly]="true" />
                               </mat-form-field>
                           </div>
                       </div>     
                       <div class="row">
                           <!-- Monto -->
                           <div class="col">
                               <mat-form-field class="full-width">
                                   <input matInput placeholder="Monto" formControlName="monto" [readonly]="true" />
                               </mat-form-field>
                           </div>
                           <!-- Estado -->
                           <div class="col">
                               <mat-form-field class="full-width">
                                   <input matInput placeholder="Estado" formControlName="estado" [readonly]="true" />
                               </mat-form-field>
                           </div>
                           <!-- Descripion -->
                           <div class="col">
                               <mat-form-field class="full-width">
                                   <input matInput placeholder="Descripion" formControlName="descripcionGasto" [readonly]="true" />
                               </mat-form-field>
                           </div>
                       </div>
                       <div class="row">
                           <button mat-raised-button color="primary" (click)="navegarALaPagina()" [disabled]="estado === 'Pagado'">
                               <mat-icon>send</mat-icon> Pagar Gasto Fijo 
   
                           </button>
                       </div>
                      </mat-card>
                </mat-card-content>

            </mat-card>
        </form>
    </mat-tab>
    <mat-tab label="Comprobante 📃 ">
        <form [formGroup]="addressForm" novalidate>
          <mat-card class="shipping-card">
            <mat-card-content>
              <mat-card>
                <h3>Imagen Previsualizacion</h3>
                <hr />
                <div class="row">
                  <!-- Preview img -->
                  <div class="col"  *ngIf="previsualizacion">
                    <img width="400px"  [src]="previsualizacion" alt="img" />
                  </div>
                </div>
              </mat-card>
            </mat-card-content>
 
          </mat-card>
        </form>
    </mat-tab>

</mat-tab-group>

