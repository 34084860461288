import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Categoria } from '@app/_models/categoria/categoria';
import { environment } from '@environments/environment';
/* Imports Excel */
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { DialogCategoriasEditarComponent } from './categorias-list/dialog-categorias-editar/dialog-categorias-editar.component';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'
/* Fin Import Excel */

@Injectable({
  providedIn: 'root'
})

export class CategoriasService {


  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private http: HttpClient) { }

  getDetailCategory(params: any) {
    const url = `${environment.apiUrl}/categorias/detailCategory`;
    const queryParams = new HttpParams({ fromObject: params });

    return this.http.get<any>(url, { params: queryParams });
  }

  //Metodo crear categoria
  create(categoria: Categoria) {
    console.log(categoria)
    return this.http.post(`${environment.apiUrl}/categorias/crear/`, categoria);
  }

  //Metodo que trae todas las categorias de la bd
  categoriaGet() {
    return this.http.get<[]>(`${environment.apiUrl}/categoria/`);
  }
  //Metodo para borrar una categoria
  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/categorias/borrar/${id}`);
  }

  updateCategoria(id: any, params: any) {
    return this.http.put(`${environment.apiUrl}/categoria/update/${id}`, params);
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditSucursal(): void {
    const dialogRef = this.dialog.open(DialogCategoriasEditarComponent, {})
    dialogRef.afterClosed().subscribe((res) => { })
  }

  closeDialogModal() {
    this.dialog.closeAll()
  }

  /* Metodo Excel */
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    )
  }
}