/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { EgresoLubricentro } from '@app/_models/lubricentro/egresoLubricentro'
import { EgresoLubricentroCuota } from '@app/_models/lubricentro/egresoLubricentroCuota'
import { IngresosLubricentro } from '@app/_models/lubricentro/ingresoLubricentro'
import { environment } from '@environments/environment'
import { Observable, BehaviorSubject } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { LubricentroEgresosCuotasComponent } from '@app/_pages/lubricentro/lubricentro-egresos/lubricentro-egresos-list/lubricentro-egresos-cuotas/lubricentro-egresos-cuotas.component'
import { LubricentroEgresosCuotaDialogComponent } from '@app/_pages/lubricentro/lubricentro-egresos/lubricentro-egresos-list/lubricentro-egresos-cuotas/lubricentro-egresos-cuota-dialog/lubricentro-egresos-cuota-dialog.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { GastosFijos} from '@app/_models/gastosFijos/gastosFijos'
import { LubricentroGastosFijosEditComponent} from './lubricentro-gastos-fijos/lubricentro-gastos-fijos-edit/lubricentro-gastos-fijos-edit.component'
import { ConsolidadosLubricentro } from '@app/_models/lubricentro/consolidados-lubricentro'
import { LubricentroEgresosEditComponent } from './lubricentro-egresos/lubricentro-egresos-list/lubricentro-egresos-edit/lubricentro-egresos-edit.component'

/* Imports Excel */
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { AccionesIngresosListComponent } from './lubricentro-ingresos/lubricentro-ingresos-list/acciones-ingresos-list/acciones-ingresos-list.component'
import { AccionesEgresosListComponent } from './lubricentro-egresos/lubricentro-egresos-list/acciones-egresos-list/acciones-egresos-list.component'
import { UsuarioSharedService } from '../shared/shared-services/usuario-shared.service'
import { SucursalSharedService } from '../shared/shared-services/sucursal-shared.service'

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'
/* Fin Import Excel */

@Injectable({
  providedIn: 'root'
})
export class LubricentroService {
  public tiposIngresosList: Observable<string[]>
  public tiposClientesList: Observable<string[]>
  public referenciasList: Observable<string[]>
  public tiposPagosList: Observable<string[]>
  public estadosPagosList: Observable<string[]>
  public estadosVentaList: Observable<string[]>
  public tiposEgresosList: Observable<string[]>
  public tiposVehiculosList: Observable<string[]>
  // private values

  //ingresos values
  private tiposIngresosListSubject: BehaviorSubject<any[]>
  private tiposClientesListSubject: BehaviorSubject<string[]>
  private referenciasListSubject: BehaviorSubject<string[]>
  private tiposPagosListSubject: BehaviorSubject<string[]>
  private estadosPagosListSubject: BehaviorSubject<string[]>
  private estadosVentaListSubject: BehaviorSubject<string[]>
  private tiposVehiculosListSubject: BehaviorSubject<string[]>
  //egresos values
  private tiposEgresosListSubject: BehaviorSubject<string[]>

  // ! strict lists
  private tiposIngresos = [
    'Lavado',
    'Reparacion',
    'Mantencion',
    'Venta de Insumos',
    'Otros'
  ]
  private tiposClientes = ['Particular', 'Empresa']
  private referencias = [
    'Llamada',
    'Booking',
    'Correo',
    'PaginaWeb',
    'Facebook',
    'Institucion Publica',
    'otros...'
  ]
  private tiposPagos = [
    'Efectivo',
    'Tarjeta de debito',
    'Tarjeta de credito',
    'Transferencia',
    'Cheque',
    //'Entidad Publica',
    'pago 30 dias',
    'Billetera Mercado Libre',
    'Pago Retail'
  ]
  private estadosPagos = ['BOLETA', 'FACTURA']

  private estadosVenta = ['PENDIENTE', 'PAGADO']

  private tiposEgresos = [
    'Gastos',
    'Costos',
    'Remuneraciones',
    'Impuestos',
    'Bancarios'
  ]
  private empresa = 'Lubricentro'
  private tiposVehiculos = ['Automóvil', 'Camioneta']
  constructor(
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioSharedService,
    private sucursalService : SucursalSharedService
  ) {
    //Init private Subjects;
    //ingresos;

    this.tiposIngresosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tiposIngresos')!)
    )
    this.tiposClientesListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tipos_clientes')!)
    )
    this.referenciasListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('referencias')!)
    )
    this.tiposPagosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tipos_pagos')!)
    )
    this.estadosPagosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('estadosPagos')!)
    )
    this.estadosVentaListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('estadosVenta')!)
    )
    //egresos;
    this.tiposEgresosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tiposEgresos')!)
    )
    this.tiposVehiculosListSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('tiposVehiculos')!)
    )

    // public states:
    //ingresos;
    this.tiposIngresosList = this.tiposIngresosListSubject.asObservable()
    localStorage.setItem('tiposIngresos', JSON.stringify(this.tiposIngresos))

    this.tiposClientesList = this.tiposClientesListSubject.asObservable()
    localStorage.setItem('tipos_clientes', JSON.stringify(this.tiposClientes))

    this.referenciasList = this.referenciasListSubject.asObservable()
    localStorage.setItem('referencias', JSON.stringify(this.referencias))

    this.tiposPagosList = this.tiposPagosListSubject.asObservable()
    localStorage.setItem('tipos_pagos', JSON.stringify(this.tiposPagos))

    this.estadosPagosList = this.estadosPagosListSubject.asObservable()
    localStorage.setItem('estadosPagos', JSON.stringify(this.estadosPagos))

    this.estadosVentaList = this.estadosVentaListSubject.asObservable()
    localStorage.setItem('estadosVenta', JSON.stringify(this.estadosVenta))

    this.tiposVehiculosList = this.tiposVehiculosListSubject.asObservable()
    localStorage.setItem('tiposVehiculos', JSON.stringify(this.tiposVehiculos))

    //egresos;
    this.tiposEgresosList = this.tiposEgresosListSubject.asObservable()
    localStorage.setItem('tiposEgresos', JSON.stringify(this.tiposEgresos))
  }

  //ingresos values get methods:
  public get tiposIngresosListValue(): string[] {
    return this.tiposIngresosListSubject.value
  }
  public get tiposClientesListValue(): string[] {
    return this.tiposClientesListSubject.value
  }
  public get referenciasListValue(): string[] {
    return this.referenciasListSubject.value
  }
  public get tiposPagosListValue(): string[] {
    return this.tiposPagosListSubject.value
  }
  public get estadosPagosListValue(): string[] {
    return this.estadosPagosListSubject.value
  }
  public get estadosVentaListValue(): string[] {
    return this.estadosVentaListSubject.value
  }
  //egresos values get methods:
  public get tiposEgresosListValue(): string[] {
    return this.tiposEgresosListSubject.value
  }
  public get tiposVehiculosListValue(): string[] {
    return this.tiposVehiculosListSubject.value
  }

  /* ingresos */
  ingresoRegistrar(ingresos: IngresosLubricentro): any {
    console.log(ingresos)
    return this.http.post(`${environment.apiUrl}/ingresoLubricentro`, ingresos)
  }
  ingresoGetAll(): any {
    return this.http.get<IngresosLubricentro[]>(
      `${environment.apiUrl}/ingreso${this.empresa}`
    )
  }
  ingresoGetFiles(fileName: string) {
    const extencion = fileName.split('.')
    const extend = extencion[1]
    return this.http
      .get(
        `${environment.apiUrl}/ingreso${this.empresa}/download/${fileName}`,
        {
          responseType: 'blob'
        }
      )
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }

  buscarImagen(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/ingreso${this.empresa}/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  ingresoGetAllWithUsuario() {
    return this.http.get<[]>(
      `${environment.apiUrl}/ingreso${this.empresa}/conUsuario`
    )
  }
  ingresoGetById(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/ingresoLubricentro/${id}`)
  }

  updateIngresoLubricentro(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/ingresoLubricentro/${id}`,
      params
    )
  }

  getByIdIngresoSinBase64(id: any) {
    console.log(id)
    return this.http.get<any>(
      `${environment.apiUrl}/ingresoLubricentro/sinBase64/${id}`
    )
  }
  /* /ingresos */

  /* egresos */
  egresoRegistrar(egreso: EgresoLubricentro): any {
   console.log(egreso)
    return this.http.post(`${environment.apiUrl}/egresoLubricentro`, egreso)
  }
  egresoGetAll(): any {
    return this.http.get<EgresoLubricentro[]>(
      `${environment.apiUrl}/egreso${this.empresa}`
    )
  }

  egresoGetById(id: any) {
    console.log(id)
    return this.http.get<any>(`${environment.apiUrl}/egresoLubricentro/${id}`)
  }

  getByIdEgresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoLubricentro/sinBase64/${id}`
    )
  }

  updateEgresoLubricentro(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egresoLubricentro/${id}`,
      params
    )
  }

  buscarCuotas(): any {
    return this.http.get<EgresoLubricentroCuota>(
      `${environment.apiUrl}/egresoLubricentroCuota/`
    )
  }

  openDialogEditIngresoLubricentro(): void {
    const dialogRef = this.dialog.open(AccionesIngresosListComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  openDialogEditEgresoLubricentro(): void {
    const dialogRef = this.dialog.open(AccionesEgresosListComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  egresoGetFiles(fileName: string): any {
    return this.http
      .get(`${environment.apiUrl}/egreso${this.empresa}/download/${fileName}`, {
        responseType: 'blob'
      })
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }
  buscarImagenEgreso(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egreso${this.empresa}/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  getById(id: string): any {
    return this.http.get<EgresoLubricentro>(
      `${environment.apiUrl}/egreso${this.empresa}/${id}`
    )
  }

  /* Egresos Por cuotas */
  getCuotas(id: any) {
    return this.http.get<EgresoLubricentroCuota>(
      `${environment.apiUrl}/egresoLubricentroCuota/${id}`
    )
  }

  buscarImagenCuota(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoLubricentroCuota/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  /** metodo actualizar datos editados de una inmobiliaria */
  updateLubricentroEgreso(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egreso${this.empresa}/${id}`,
      params
    )
  }

  agregarRespaldos(arrayRespaldos: any): any {
    return this.http.post(
      `${environment.apiUrl}/egresoLubricentroCuota/agregarRespaldos/`,
      arrayRespaldos
    )
  }
  buscarImagenC(id: any): any {
    return this.http.get<EgresoLubricentroCuota>(
      `${environment.apiUrl}/respaldoEgresoLubricentroCuota/${id}`
    )
  }

  /** Consolidados */
  buscarConsolidado(consolidado: ConsolidadosLubricentro): any {
    return this.http.post(
      `${environment.apiUrl}/ingreso${this.empresa}/ingresosEgresos`,
      consolidado
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogCuota(): void {
    const dialogRef = this.dialog.open(
      LubricentroEgresosCuotaDialogComponent,
      {}
    )
    dialogRef.afterClosed().subscribe((res) => {})
  }

  updateMonto(id: any, body: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egresoLubricentroCuota/${id}`,
      body
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogRegistrarPago(idEgreso: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idEgreso != null) {
      const dialogRef = this.dialog.open(LubricentroEgresosCuotasComponent, {})
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open(
        'Por favor seleccione un egreso con cuotas sin pagar',
        'cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }

  // METODO PARA EXPORTAR EXCEL
  public async exportAsExcelFile(json: any[], excelFileName: string, selectedColumns: string[]): Promise<void> {

    const columnMappings = {
      'id': 'ID',
      'fecha': 'Fecha',
      'monto': 'Monto',
      'nAutorizacion': 'N° Autorización',
      'tipoPago': 'Tipo de Pago',
      'cliente': 'Cliente',
      'descripcionIngreso': 'Descripción',
      'tipoIngreso': 'Tipo de Ingreso',
      'referenciaCliente': 'Referencia',
      'idUsuario': 'Usuario',
      'idSucursal': 'Sucursal'
    };
  
    const newData = await Promise.all(json.map(async (item) => {
      const newItem: any = {};
      for (const col of selectedColumns) {
        if (col === 'idSucursal') {
          if (item[col] !== null) {
            try {
              const sucursal = await this.sucursalService.getById(item[col]).toPromise();
              newItem[columnMappings[col]] = sucursal.razonSocial;
            } catch (error) {
              console.error("Error al obtener el nombre de la sucursal:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else if (col === 'idUsuario') {
          if (item[col] !== null) {
            try {
              const usuario = await this.usuarioService.getById(item[col]).toPromise();
              newItem[columnMappings[col]] = usuario.nombre + ' ' + usuario.apellido;
            } catch (error) {
              console.error("Error al obtener el nombre del usuario:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else if (col === 'fecha') {
          if (item[col] !== null) {
            try {
              const fecha = new Date(item[col]);
              const formattedFecha = `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`;
              newItem[columnMappings[col]] = formattedFecha;
            } catch (error) {
              console.error("Error al obtener la fecha:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else {
          newItem[columnMappings[col]] = item[col];
        }
      }
  
      return newItem;
    }));
  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData);
  
    // Establecer el estilo del encabezado (primera fila)
    const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: C });
      console.log('Setting style for cell:', headerCell);
      // Limpiar cualquier estilo existente
      worksheet[headerCell].s = {};
      // Aplicar nuevo estilo
      worksheet[headerCell].s = { fill: { fgColor: { rgb: 'FFFF00' } }, font: { bold: true } };
    }
  
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public async exportAsExcelFileE(json: any[], excelFileName: string, selectedColumns: string[]): Promise<void> {

    const columnMappings = {
      'id': 'ID',
      'tipoEgreso': 'Tipo de Egreso',
      'fecha': 'Fecha',
      'monto': 'Monto',
      'responsable': 'Responsable',
      'descripcion': 'Descripción',
      'tipoPago': 'Tipo de Pago',
      'idUsuario': 'Usuario',
      'idSucursal': 'Sucursal'
    };
  
    const newData = await Promise.all(json.map(async (item) => {
      const newItem: any = {};
      for (const col of selectedColumns) {
        if (col === 'idSucursal') {
          if (item[col] !== null) {
            try {
              const sucursal = await this.sucursalService.getById(item[col]).toPromise();
              newItem[columnMappings[col]] = sucursal.razonSocial;
            } catch (error) {
              console.error("Error al obtener el nombre de la sucursal:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else if (col === 'idUsuario') {
          if (item[col] !== null) {
            try {
              const usuario = await this.usuarioService.getById(item[col]).toPromise();
              newItem[columnMappings[col]] = usuario.nombre   + ' ' + usuario.apellido;
            } catch (error) {
              console.error("Error al obtener el nombre del usuario:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else if (col === 'fecha') {
          if (item[col] !== null) {
            try {
              const fecha = new Date(item[col]);
              const formattedFecha = `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`;
              newItem[columnMappings[col]] = formattedFecha;
            } catch (error) {
              console.error("Error al obtener la fecha:", error);
              newItem[columnMappings[col]] = '';
            }
          } else {
            newItem[columnMappings[col]] = '';
          }
        } else {
          newItem[columnMappings[col]] = item[col];
        }
      }
  
      return newItem;
    }));
  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData);
  
    // Establecer el estilo del encabezado (primera fila)
    const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: C });
      console.log('Setting style for cell:', headerCell);
      // Limpiar cualquier estilo existente
      worksheet[headerCell].s = {};
      // Aplicar nuevo estilo
      worksheet[headerCell].s = { fill: { fgColor: { rgb: 'FFFF00' } }, font: { bold: true } };
    }
  
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    )
  }

  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idEgresoPago')
  }

  // Metodo que permite abrir un Dialog (Modal)-->editar
  openDialogEditEgreso(): void {
    const dialogRef = this.dialog.open(LubricentroEgresosEditComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  deleteEgreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/egresoLubricentro/${id}`)
  }

  //Crear ingreso:
  IngresosLubricentro(modelo: IngresosLubricentro): any {
    console.log(modelo)
    return this.http.post(
      `${environment.apiUrl}/ingreso${this.empresa}`,
      modelo
    )
  }

  createGastoFijos(gastos: GastosFijos): any {
    console.log("ruta", gastos )
    return this.http.post(`${environment.apiUrl}/gastosFijos`, gastos);
  }

  getGastoFijos(): Observable<GastosFijos[]> {
    return this.http.get<GastosFijos[]>(`${environment.apiUrl}/gastosFijos`);
  }

  openDialogGastoFijo(detallesGasto: any) {
    const dialogRef = this.dialog.open(LubricentroGastosFijosEditComponent, {
      width: '1000px',
      height: '500px',
      data: detallesGasto,
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  getByIdGastoFijo(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/gastosFijos/getIDSinBase64/${id}`)
  }
  getByIdGastoFijoImagen(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/gastosFijos/oneGetGastoFijos/${id}`)
  }
  updateGastoFijos(id: any, data: any) {
    return this.http.put(
      `${environment.apiUrl}/gastosFijos/updateGastoFijos/${id}`, data
    );
  }
  deleteIngreso(id:number){
    return this.http.delete(`${environment.apiUrl}/ingresoLubricentro/${id}`)
  }
  createCredito(egresoLubricentro: EgresoLubricentro):any{
    try {
      return this.http.post(
        `${environment.apiUrl}/egresoLubricentro/createEgresoHome/`,
        egresoLubricentro
      )
    }catch(error){
      console.log(error)
      return  error
    }
  }
  //Métodos para gestionar transacciones webpay
  pagar(idCuota:number, monto:number, empresa:string){
    let body = {
      idCuota,
      monto,
      empresa
    }
    return this.http.post(
      `${environment.apiUrl}/egresoLubricentro/pagar`, body
    )
  }
  confirmar(token:string){
    let body = {token}
      return this.http.post(
        `${environment.apiUrl}/egresoLubricentro/confirmar`, body
      )
  }
  actualizarPagado(id: number): any {
    let body = {
      id
    }
    return this.http.post(
      `${environment.apiUrl}/egresoLubricentroCuota/actualizarPagado/`,
      body
    )
  }


}
