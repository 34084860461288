import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GuiaDespacho } from '../../../_models/guiaDespacho/guiaDespacho';
import { GuiaDespachoService } from '../guia-despacho.service';
import { FolioService } from '../../folio/folio.service';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-despacho-list',
  templateUrl: './despacho-list.component.html',
  styleUrls: ['./despacho-list.component.scss']
})
export class DespachoListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort = null

  // ? Inputs & Outputs
  @Input() refrescar = ''

  //------inicio tabla-------
  displayedColumns: string[] = [
    'id',
    'cliente',
    'folio',
    'totalGeneral',
    'fechaCreacion',
    'vendedor',
    'estado',
    'acciones'
  ]

  filtroSeleccionado: string = 'Nombre'; // Valor predeterminado del filtro
  filtros: string[] = ['Nombre', 'Rut']; // Lista de opciones de filtro
  uniqueTipoValues: string[] = [];

  folio: any
  foliosRestantes: any;


  dataSource: MatTableDataSource<GuiaDespacho> = new MatTableDataSource()
  dataDespacho: GuiaDespacho[] = []
  dataPendiente: GuiaDespacho[] = []
  dataEnviadaSII: GuiaDespacho[] = []
  dataRechazada: GuiaDespacho[] = []
  dataSobreEnvio: GuiaDespacho[] = []

  //Modelo de cotizacion
  modeloDia: GuiaDespacho = new GuiaDespacho()

  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private despachoService: GuiaDespachoService,
    private snackBar: MatSnackBar,
    private foliosService : FolioService
  ) {}

  ngOnInit(): void {
    this.getAllDespacho()
    // this.traerFolios()
  }

  getAllDespacho() {
    this.despachoService.getAllDespacho().subscribe(
      (data: any) => {
        this.dataDespacho = data
        this.dataSource = new MatTableDataSource(this.dataDespacho)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }
  //Boton para actualizar tabla de facturas
  actualizar(): void {
    window.location.reload()
  }

  //Boton de busqueda
  applyFilter(event: any): void {
    const valor = event.target.value.trim().toLowerCase();
  
    // Aplica el filtro solo si hay un valor en el campo de búsqueda
    if (valor !== '') {
      if (this.filtroSeleccionado === 'Nombre') {
        // Filtra por nombre
        this.dataSource.filterPredicate = (data, filter) =>
          data.cliente.toLowerCase().includes(filter);
      } else if (this.filtroSeleccionado === 'Rut') {
        // Filtra por rut
        //this.dataSource.filterPredicate = (data, filter) =>
        //data.Rut.toLowerCase().includes(filter);
      }
    } else {
      this.dataSource.filterPredicate = () => true;
    }
    this.dataSource.filter = valor;
  }

  
  //Boton de acciones
  accionesDespacho(id: any) {
    localStorage.setItem('idBoleta', id)
    this.despachoService.openDialogEditDespacho()
  }
  
  
  

  //Boton mostrar todos los datos
  todos() {
    this.despachoService.getAllDespacho().subscribe(
      (data: any) => {
        this.dataDespacho = data
        this.dataSource = new MatTableDataSource(this.dataDespacho)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //Boton pendiente
  pendiente() {
    this.dataPendiente = []
    this.despachoService.getAllDespacho().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Pendiente') {
            this.dataPendiente.push(element)
            this.dataSource = new MatTableDataSource(this.dataPendiente)
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort
          }
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //Boton  SobreEnvio
  SobreEnvio() {
    this.dataSobreEnvio = []
    this.despachoService.getAllDespacho().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Sobre de Envio') {
            this.dataSobreEnvio.push(element)
            this.dataSource = new MatTableDataSource(this.dataSobreEnvio)
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort
          }
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //Boton Enviada SII
  EnviadaSII() {
    this.dataEnviadaSII = []
    this.despachoService.getAllDespacho().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Envio a SII') {
            this.dataEnviadaSII.push(element)
            this.dataSource = new MatTableDataSource(this.dataEnviadaSII)
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort
          }
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

   //Boton Enviada SII
   Rechazada() {
    this.dataRechazada = []
    this.despachoService.getAllDespacho().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Rechazada') {
            this.dataRechazada.push(element)
            this.dataSource = new MatTableDataSource(this.dataRechazada)
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort
          }
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  getFolioUtilizar() {
    return this.foliosService
      .getByFolioFactura('52', '') //NO COMITEAR PELIGRO PELIGRO
      .pipe(first())
      .pipe(map(folioServicio => folioServicio[0].siguienteFolio));
  }
  
  traerFolios() {
    var tipoDte = 52;
  
    this.getFolioUtilizar().subscribe(thisFolio => {
      this.folio = thisFolio;
      
      this.foliosService.verificarFoliosDisponibles(tipoDte).subscribe(data => {
  
        const foliosFaltantes = data.documentoCafs[0].ultimoFolio;  
        this.foliosRestantes = foliosFaltantes - this.folio;
  
        if (this.foliosRestantes <= 5 && this.foliosRestantes >= 0) {
          console.log(`¡Alerta! Solo quedan ${this.foliosRestantes} folios antes de alcanzar el límite.`);
        }
      });
    });
  }

}
