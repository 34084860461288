<h1>Registro de Cesionario</h1>

<div class="mat-elevation-z8">
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> Cesionario </mat-panel-title>
      <mat-panel-description>
        Para Crear un Cesionario Presiona Aqui
        <mat-icon>touch_app_white_18dp</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="form-container">
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <div class="form-group basic-info">
          <h3>Información Básica</h3>
          
          <div class="col">
            <mat-form-field appearance="legacy">
              <input matInput placeholder="Nombre" formControlName="nombre" type="text" />
              <mat-icon matSuffix>badge_white_18dp</mat-icon>
            </mat-form-field>
            <div *ngIf="contactForm.get('nombre').invalid && contactForm.get('nombre').touched">
              <small class="error">Nombre es requerido.</small>
            </div>
          </div>
          
          <div class="col">
            <mat-form-field appearance="legacy">
              <input matInput placeholder="RUT" formControlName="rut" type="text" />
              <mat-icon matSuffix>fingerprint_white_18dp</mat-icon>
            </mat-form-field>
            <div *ngIf="contactForm.get('rut').invalid && contactForm.get('rut').touched">
              <small class="error">RUT en formato 11222333-4 requerido.</small>
            </div>
          </div>
          
          <div class="col">
            <mat-form-field appearance="legacy">
              <input matInput placeholder="Dirección" formControlName="direccion" type="text" />
              <mat-icon matSuffix>push_pin_white_18dp</mat-icon>
            </mat-form-field>
            <div *ngIf="contactForm.get('direccion').invalid && contactForm.get('direccion').touched">
              <small class="error">Dirección es requerida.</small>
            </div>
          </div>
        </div>
    
        <div class="form-group contact-info">
          <h3>Información de Contacto</h3>
          
          <div class="col">
            <mat-form-field appearance="legacy">
              <input matInput placeholder="Contacto" formControlName="contacto" type="text" />
              <mat-icon matSuffix>badge_white_18dp</mat-icon>
            </mat-form-field>
          </div>
    
          <div class="col">
            <mat-form-field appearance="legacy">
              <input matInput placeholder="Correo Electrónico" formControlName="email" type="email" />
              <mat-icon matSuffix>mail</mat-icon>
            </mat-form-field>
            <div *ngIf="contactForm.get('email').invalid && contactForm.get('email').touched">
              <small class="error" *ngIf="contactForm.get('email').errors.required">Correo electrónico es requerido.</small>
              <small class="error" *ngIf="contactForm.get('email').errors.email">Correo electrónico no es válido.</small>
            </div>
          </div>
    
          <div class="col">
            <mat-form-field appearance="legacy">
              <input matInput placeholder="Teléfono" formControlName="telefono" type="text" />
              <mat-icon matSuffix>phone_callback_white_18dp</mat-icon>
            </mat-form-field>
          </div>
        </div>
    
        <div class="form-actions">
          <button type="button" (click)="contactForm.reset()">Limpiar formulario</button>
          <button type="submit" [disabled]="contactForm.invalid">Guardar</button>
        </div>
      </form>
    </div>
    
    
    
    
  </mat-expansion-panel>
</div>
<br>
