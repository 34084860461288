import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AjusteInventario } from "@app/_models/ajusteInventario/ajusteInventario";
import { StockNew } from "@app/_models/stocks/stockNew";
/* Imports Excel */
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { environment } from "@environments/environment";
import { DialogAjusteInventarioVerComponent } from "./ajuste-inventario-list/dialog-ajuste-inventario-ver/dialog-ajuste-inventario-ver.component";
import { MatDialog } from "@angular/material/dialog";
import { catchError, map } from "rxjs/operators";
import { StockSaliente } from "@app/_models/stocks/stockSaliente";
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'
/* Fin Import Excel */



@Injectable({
  providedIn: 'root'
})
export class AjusteInventarioService {
  res: {
    success: boolean
    data?: any
    msg?: string
  }
  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {}

  create(ajusteInventario: AjusteInventario) {
    return this.http
      .post(`${environment.apiUrl}/ajusteInventario/crear/`, ajusteInventario)
      .pipe(
        map((res: any) => {
          return res
        })
      )
  }

  ajusteInventarioGet(): any {
    return this.http.get<[]>(`${environment.apiUrl}/ajusteInventario/`)
  }

  updateAjusteInventario(id: any, params: any) {
    return this.http.delete(
      `${environment.apiUrl}/ajusteInventario/updateAjuste/${id}`,
      params
    )
  }

  ajusteInventarioGetId(id: number) {
    return this.http.get<AjusteInventario>(
      `${environment.apiUrl}/ajusteInventario/${id}`
    )
  }

  traerConsulta(id: number): any {
    return this.http.get(`${environment.apiUrl}/ajusteInventario/porId/${id}`)
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogVerAjusteInventario(): void {
    const dialogRef = this.dialog.open(DialogAjusteInventarioVerComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  closeDialogModal() {
    this.dialog.closeAll()
  }

  /* Metodo Excel */
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    )
  }
  getProductByBodega(id: string) {
    return this.http.get(
      `${environment.apiUrl}/ajusteInventario/getProductByBodega/${id}`
    )
  }

  createNewStock(modelo: StockNew): any {
    return this.http.post(
      `${environment.apiUrl}/ajusteInventario/createStock`,
      modelo
    )
  }

  restarStockSaliente(modelo: StockSaliente):any{
     return this.http.post(
       `${environment.apiUrl}/ajusteInventario/restarStock`,
       modelo
     )

  }
}