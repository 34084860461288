import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-cesionario-form',
  templateUrl: './cesionario-form.component.html',
  styleUrls: ['./cesionario-form.component.css']
})
export class CesionarioFormComponent implements OnInit {
  contactForm: FormGroup;
  
  constructor(private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      nombre: ['', Validators.required],
      rut: ['', [Validators.required, this.rutValidator]],
      direccion: ['', Validators.required],
      contacto: [''],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['']
    });
  }

  ngOnInit() {
  }

  // Función de validación personalizada para el RUT
  rutValidator(control: AbstractControl): ValidationErrors | null {
    const rutPattern = /^\d{7,8}-[0-9kK]{1}$/; // Patrón para RUT en formato "11111111-1" o "1111111-1"
    const valid = rutPattern.test(control.value);
    return valid ? null : { invalidRut: true };
  }

  onSubmit() {
    if (this.contactForm.valid) {
      console.log(this.contactForm.value);
    } else {
      console.log('Formulario inválido');
      this.displayValidationErrors();
    }
  }

  displayValidationErrors() {
    for (const key in this.contactForm.controls) {
      if (this.contactForm.controls[key].invalid) {
        console.log(`Error en el campo ${key}:`, this.contactForm.controls[key].errors);
      }
    }
  }
}