<hr />

<div class="row">
  <div class="col">
    <h3>Monto Bancario</h3>
    <div style="width: 300px; height: 300px">
      <canvas id="myChart1"> </canvas>
    </div>
  </div>
  <div class="col">
    <h3>Monto Credito</h3>
    <div style="width: 300px; height: 300px">
      <canvas id="myChart3"> </canvas>
    </div>
  </div>
  <div class="col">
    <h3>Monto Disponible</h3>
    <div style="width: 300px; height: 300px">
      <canvas id="myChart2"> </canvas>
    </div>
  </div>
</div>

<hr />
