import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { GuiaDespacho } from '../../_models/guiaDespacho/guiaDespacho';
import { DespachoAccionesComponent } from './despacho-list/despacho-acciones/despacho-acciones.component';

@Injectable({
  providedIn: 'root'
})
export class GuiaDespachoService {

  constructor(private http: HttpClient, private dialog: MatDialog) { }


    //Registrar Guia de despacho
    registrarDespacho(modelo: GuiaDespacho, empresa: any, archivoCAF: string): any {
      const body = {modelo, empresa, archivoCAF}
      return this.http.post(`${environment.apiUrl}/despacho/generarDTE`, body)
    }
    
    //Registrar Guia de despacho
    despachoGenerarSobreEnvioDTE(envioSobre: any): any {
      const body = {envioSobre}
      console.log(envioSobre)
      return this.http.post(`${environment.apiUrl}/despacho/despachoGenerarSobreEnvioDTE`, body)
    }

    //Registrar Guia de despacho
    enviarSII(envio: any): any {
      const body = {envio}
      return this.http.post(`${environment.apiUrl}/despacho/enviarSII`, body)
    }
  
    //Registrar Guia de despacho
    consultarSII(envio: any, guiaCreada: any): any {
      const body = {envio, guiaCreada}
      return this.http.post(`${environment.apiUrl}/despacho/consultarSII`, body)
    }

    //realizar pdf Guia de despacho
    imprimirDespacho(envio: any, empresa: any): any {
      const body = {envio, empresa}
      return this.http.post(`${environment.apiUrl}/despacho/imprimirDespacho`, body)
    }

    //traer pdf Guia de despacho
    traerPDFDespacho(folio: number, rutEmisor: string): any {
      const body = {folio, rutEmisor}
      return this.http.post(`${environment.apiUrl}/despacho/traerPdfDespacho`, body)
    }

    //Llamar tabla de Guia de despacho
    getAllDespacho(): any {
      return this.http.get<[]>(`${environment.apiUrl}/despacho`)
    }
  
    getById(id: any) {
      return this.http.get<any>(`${environment.apiUrl}/despacho/${id}`)
    }
    
    
    // Metodo que permite abrir un Dialog (Modal)
    openDialogEditDespacho(): void {
      const dialogRef = this.dialog.open(DespachoAccionesComponent, {})
      dialogRef.afterClosed().subscribe((res) => {})
    }
    
  
    //ACTUALIZAR Guia de despacho
    updateDespacho(id: any, params: any[]): any {
      return this.http.put(`${environment.apiUrl}/despacho/${id}`, params)
    }
  
    //Imprimir Pdf Guia de despacho
    ImprimirPdfDespacho(id: any, params: any[]): any {
      return this.http.post(
        `${environment.apiUrl}/despacho/imprimirDespacho/${id}`,
        params
      )
    }
  
    //Envio a SII
    EnvioSII(id: any, params: any[]): any {
      return this.http.put(
        `${environment.apiUrl}/despacho/envioSII/${id}`,
        params
      )
    }
}
