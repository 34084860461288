import { SelectionModel } from '@angular/cdk/collections'
import { HttpClient } from '@angular/common/http'
import {
  Component,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { DialogShow } from '@app/_components/dialogs/dialog-downloads/dialog-downloads.component'
import { IngresosHostal } from '@app/_models/hostal/ingresoHostal'
import { Sucursal } from '@app/_models/shared/sucursal'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { environment } from '@environments/environment'
import { HostalService } from '../../hostal.service'
import { saveAs } from 'file-saver'
import { Usuario } from '@app/_models/shared/usuario'

@Component({
  selector: 'app-hostal-ingresos-tabla',
  templateUrl: './hostal-ingresos-tabla.component.html',
  styleUrls: ['./hostal-ingresos-tabla.component.scss']
})
export class HostalIngresosTablaComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()
  @ViewChild(MatSort) sort = new MatSort()

  @Input()
  refrescar = ''
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  displayedColumns: string[] = [
    'select',
    'id',
    'fecha',
    'monto',
    //'estadoPago',
    //'respaldos',
    //'nDocumento',
    'nAutorizacion',
    'tipoPago',
    'cliente',
    'descripcionIngreso',
    'tipoIngreso',
    'referenciaCliente',
    'usuario',
    'sucursal',
    'acciones'
  ]
  dataSource: MatTableDataSource<IngresosHostal> = new MatTableDataSource()
  dataIngresos: IngresosHostal[] = []
  changelog: string[] = []
  rolUsuario: string

  formFilter = new FormGroup({
    id: new FormControl(),
    monto: new FormControl(),
    start: new FormControl(),
    end: new FormControl(),
    idSucursal: new FormControl(),
    tipoIngreso: new FormControl(),
    cliente: new FormControl(),
    estadoPago: new FormControl(),
    nDocumento: new FormControl(),
    tipoPago: new FormControl(),
    nAutorizacion: new FormControl(),
    descripcionIngreso: new FormControl(),
    referenciaCliente: new FormControl(),
    usuario: new FormControl()
  })

  sucursales: Sucursal[] = []
  selection = new SelectionModel<IngresosHostal>(true, [])
  tiposIngresos: string[] = []
  estadosPagos: string[] = []
  totalSeleccion = 0
  selectedRows!: any[]
  cuentasRegistradas: any[] = []
  tipoPago: string[]
  referenciaCliente: string[]

  constructor(
    private hostalService: HostalService,
    public dialog: MatDialog,
    private sucursalService: SucursalSharedService,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {
    this.sucursales = this.sucursalService.sucursalListValue
    this.tiposIngresos = [
      'Alojamiento',
      'Desayuno',
      'Almuerzo',
      'Cena',
      'Consumo Bebidas',
      'Consumo Varios'
    ]
    this.estadosPagos = ['PENDIENTE', 'PAGADO']
    this.tipoPago = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Cheque'
    ]
    this.referenciaCliente = [
      'Llamada',
      'Booking',
      'Correo',
      'PaginaWeb',
      'Facebook',
      'Directo a Hostal'
    ]
  }
  ngOnInit(): void {
    this.aplicarfiltros()
    this.updateTable()
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName]
      const to = JSON.stringify(change.currentValue)
      const from = JSON.stringify(change.previousValue)
      const changeLog = `${propName}: changed from ${from} to ${to} `
      this.changelog.push(changeLog)
      this.hostalService
        .ingresoGetAll()
        .subscribe((ingresos: IngresosHostal[]) => {
          this.dataIngresos = ingresos.map((ingreso) => {
            ingreso.sucursal = ingreso.Sucursal.razonSocial
            ingreso.usuario = ingreso.Usuario?.nombreUsuario
            ingreso.monto = ingreso.monto
            return ingreso
          })
          this.dataSource = new MatTableDataSource(this.dataIngresos)
          this.dataSource.paginator = this.paginator.toArray()[0]
          this.dataSource.sort = this.sort
        })
    }
    this.rolUsuario = this.usuario.rol
  }

  revelarTotal() {
    this.totalSeleccion = 0
    console.log(this.selection.selected.length)
    this.selection.selected.forEach((data) => {
      this.totalSeleccion += data.monto
    })
  }

  aplicarfiltros() {
    this.formFilter.valueChanges.subscribe((res) => {
      const { id, monto } = res
      let dataFiltered = this.dataIngresos

      if (id) {
        dataFiltered = dataFiltered.filter(
          (data: IngresosHostal) => data.id.toString() === id
        )
      }
      if (monto) {
        dataFiltered = dataFiltered.filter(
          (data: IngresosHostal) => data.monto.toString() === monto
        )
      }

      if (res.cliente) {
        dataFiltered = dataFiltered.filter((data: IngresosHostal) =>
          data.cliente.toLowerCase().includes(res.cliente.toLowerCase())
        )
      }

      if (res.nDocumento) {
        dataFiltered = dataFiltered.filter((data: IngresosHostal) =>
          data.nDocumento.includes(res.nDocumento)
        )
      }

      if (res.nAutorizacion) {
        dataFiltered = dataFiltered.filter(
          (data: IngresosHostal) => data.nAutorizacion === res.nAutorizacion
        )
      }

      if (res.estadoPago) {
        dataFiltered = dataFiltered.filter(
          (data: IngresosHostal) => data.estadoPago == res.estadoPago
        )
      }

      if (res.tipoPago) {
        dataFiltered = dataFiltered.filter((data: IngresosHostal) =>
          data.tipoPago.includes(res.tipoPago)
        )
      }

      if (res.referenciaCliente) {
        dataFiltered = dataFiltered.filter((data: IngresosHostal) =>
          data.referenciaCliente.includes(res.referenciaCliente)
        )
      }

      if (res.descripcionIngreso) {
        dataFiltered = dataFiltered.filter((data: IngresosHostal) =>
          data.descripcionIngreso
            .toLowerCase()
            .includes(res.descripcionIngreso.toLowerCase())
        )
      }

      if (res.usuario) {
        dataFiltered = dataFiltered.filter((data: IngresosHostal) =>
          data.usuario.includes(res.usuario)
        )
      }

      if (res.tipoIngreso) {
        dataFiltered = dataFiltered.filter((data: IngresosHostal) =>
          data.tipoIngreso.includes(res.tipoIngreso)
        )
      }

      if (res.idSucursal) {
        dataFiltered = dataFiltered.filter(
          (data: IngresosHostal) => data.sucursal == res.idSucursal
        )
      }

      if (res.start && res.end) {
        dataFiltered = dataFiltered.filter(
          (data: IngresosHostal) =>
            new Date(data.fecha) >= res.start && new Date(data.fecha) <= res.end
        )
      }

      if (res.monto) {
        dataFiltered = dataFiltered.filter(
          (data: IngresosHostal) => data.monto == res.monto
        )
      }

      this.dataSource = new MatTableDataSource(dataFiltered)
      this.dataSource.paginator = this.paginator.toArray()[0]
      this.dataSource.sort = this.sort
      this.totalSeleccion = 0
      this.selection.clear()
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  masterToggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        })
  }
  resetTable() {
    this.formFilter.patchValue({
      start: null,
      end: null,
      idSucursal: null,
      tipoIngreso: null,
      estadoPago: null,
      cliente: null,
      nDocumento: null,
      tipoPago: null,
      nAutorizacion: null,
      descripcionIngreso: null,
      usuario: null,
      referenciaCliente: null
    })
    this.dataSource = new MatTableDataSource(this.dataIngresos)
    this.dataSource.paginator = this.paginator.toArray()[0]
    this.dataSource.sort = this.sort
    this.dataSource.paginator['_pageIndex'] = 0
    this.updateTable()
    this.selection.clear()
    this.totalSeleccion = 0
  }

  updateTable() {
    this.hostalService
      .ingresoGetAll()
      .subscribe((ingresos: IngresosHostal[]) => {
        this.dataIngresos = ingresos.map((ingreso) => {
          ingreso.sucursal = ingreso.Sucursal.razonSocial
          ingreso.usuario = ingreso.Usuario?.nombreUsuario
          return ingreso
        })
        this.dataSource = new MatTableDataSource(this.dataIngresos)
        this.dataSource.paginator = this.paginator.toArray()[0]
        this.dataSource.sort = this.sort
      })
    this.rolUsuario = this.usuario.rol
  }

  //Excell

  selectedColumns: string[] = [
    'id',
    'fecha',
    'tipoIngreso',
    'monto',
    'cliente',
    'nAutorizacion',
     'idUsuario',
    'tipoPago',
     'tipoCliente',
    'descripcionIngreso',
     'referenciaCliente',
    'idSucursal'
  ]

  exportAsXLSX(): void {
    this.selectedRows = []
    if (this.selection.selected.length == 0) {
      this.snackBar.open('!Seleccione algún registro!', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    } else {
      this.selection.selected.forEach((x) => this.selectedRows.push(x))
      const newArray = this.selectedRows.map((item) => {
        const { RespaldoIngresos, Usuario, Sucursal, ...newObject } = item
        return newObject
      })

      this.hostalService.exportAsExcelFile(
        newArray,
        'Lista-Ingresos-Hostal',
        this.selectedColumns
      )
    }
  }

  //Botones de acciones 🚒🚒🚒🚒🚒🚒🚒🚒🚒
  accionIngresoInmobiliaria(id: any) {
    localStorage.setItem('idIngresoHostal', id)
    this.hostalService.openDialogEditIngresoInmobiliaria()
  }

  downloadImage(id: any) {
    localStorage.setItem('idIngresoHostal', id) //====> revisar
    this.http
      .get(`${environment.apiUrl}/ingresoHostal/upload/download-image/${id}`, {
        responseType: 'blob'
      })
      .subscribe((response) => {
        const url = URL.createObjectURL(response)
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(blob)
          })
      })
  }

  deleteEgreso(id: number): void {
    if (confirm('Seguro que deseas eliminar el Registro!!')) {
      console.log(id)
      this.hostalService.deleteIngreso(id).subscribe(
        (res) => {
          this.snackBar.open('Egreso eliminado', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          //location.reload()
          this.resetTable()
        },
        (error) => {
          this.snackBar.open(
            'No se pudo editar la hostal, favor contactar a informática.',
            'Cerrar',
            {
              duration: 2000,
              verticalPosition: 'top'
            }
          )
        }
      )
    }
  }
}
