import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { Usuario } from '@app/_models/shared/usuario'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Empresa } from '@app/_models/shared/empresa'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { EgresoAgroFirma } from '@app/_models/agroFirma/egresoAgroFirma'
import { AgroFirmaService } from '@app/_pages/agroFirma/agro-firma.service'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-agro-firma-egreso-lis-ver-img',
  templateUrl: './agro-firma-egreso-lis-ver-img.component.html',
  styleUrls: ['./agro-firma-egreso-lis-ver-img.component.scss']
})
export class AgroFirmaEgresoLisVerImgComponent implements OnInit {
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  @Input() projectId!: Observable<number>


  clickButton = true
  
  tiposPagos: string[] = []
  referencias: string[] = []
  tipoIngresos: string[] = []
  tipoDocumentos: string[] = []
  modelo: EgresoAgroFirma = new EgresoAgroFirma()
  dataArrayImg = []
  tipoCliente: string[] = []
  idRegistroAgrofirma: number

  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private agroServices: AgroFirmaService
  ) {

  }

  ngOnInit(): void {
    this.getIngresoAgrofirma()
  }

  //Configuracion de formulario
  addressForm = this.fb.group({
    archivos: null
  })
  get f() {
    return this.addressForm.controls
  }

  getIngresoAgrofirma() {
    this.agroServices
      .getEgresoByID(localStorage.getItem('idEgresoAgrofirmaImg'))
      .pipe(first())
      .subscribe((x: any) => {
        if (x.id == localStorage.getItem('idEgresoAgrofirmaImg')) {
          this.f.archivos.setValue(x.archivos)
          console.log('img', x.archivos)
        }
        this.previsualizacion = x.archivos
      })
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    window.location.reload()
  }

  //Capturar imagen
  capturarFile(event) {
    console.log("jajajja")
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'egresoAgrofirma' + Date.now() + '.' + extension
    this.dataArrayImg.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }

  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })
}
