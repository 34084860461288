<mat-dialog-content class="my-dialog-content" style="margin-top: -30px;">
    <div class="fill-dialog-content">
        <mat-card class="mat-card">



            <div class="box-header">
                <div style="display: flex; align-items: center;">
                    <mat-icon style="padding: 0.3rem;">room</mat-icon>
                    <h1 style="font-size: large; margin-left: 0.3rem;margin-top: 18px;">Stock Disponible</h1>
                </div>
                <div>
                    <button color="accent" style="margin-top: 15px;" class="cerrar" (click)="closeDialog()"
                        title="Cerrar">
                        <mat-icon>highlight_off</mat-icon>
                    </button>
                </div>
            </div>


            <!-- <mat-card-header class="header" style="background-color: aqua;">
                <div class="">
                    <mat-icon style="background-color: blue;">room</mat-icon>
                    <h1 style="background-color: brown;font-size: large;">Stock Disponible</h1>
                </div>
                <div class="">
                    <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
                        <mat-icon>highlight_off</mat-icon>
                    </button>
                </div>
            </mat-card-header> -->

            <hr>


            <p class="product-title" style="margin-top: 20px;">Producto: {{ nombreProducto }}</p>

            <p class="category-label" style="margin-bottom: 20px;">Categoría: {{ categoria }}</p>



            <mat-card-content class="table-container">

                <table mat-table [dataSource]="listProducts" class="">

                    <ng-container matColumnDef="bodega">
                        <th mat-header-cell *matHeaderCellDef class="size-store">Bodega/Ubicación </th>
                        <td mat-cell *matCellDef="let element">{{ element.bodega }}</td>
                    </ng-container>

                    <ng-container matColumnDef="estado">
                        <th mat-header-cell *matHeaderCellDef class="size-state">Estado </th>
                        <td mat-cell *matCellDef="let element">
                            <span [ngClass]="element.estado === 'Vigente' ? 'Vigente' : 'no-vigente'">
                                {{ element.estado}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="stock">
                        <th mat-header-cell *matHeaderCellDef class="size-stock">Stock disponible</th>
                        <td mat-cell *matCellDef="let element">{{ element.stockdisponible | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="costo">
                        <th mat-header-cell *matHeaderCellDef class="size-cost">Costo unitario</th>
                        <td mat-cell *matCellDef="let element">
                            <span matPrefix>$&nbsp;</span>
                            {{ element.unitario | number }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No existen coincidencias</td>
                    </tr>

                </table>

            </mat-card-content>

            <br>
            <hr>
            <mat-card-footer class="footer">
                <button mat-button style="margin-right: 20px;" (click)="closeDialog()">cancelar</button>
            </mat-card-footer>

        </mat-card>

    </div>
</mat-dialog-content>
<!-- ---------------------------------------------------------------------- -->