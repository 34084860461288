<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Informacion De La Cuenta">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <h1>Cuenta Bancaria</h1>
        <hr />
        <br />
        <mat-accordion>
          <!-- Informacion General -->
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Informacion General </mat-panel-title>
              <mat-panel-description>
                Presiona para mostrar los datos bancario
              </mat-panel-description>
            </mat-expansion-panel-header>
            <hr />
            <!-- Fila 1 -->
            <div class="row">
              <!-- estado -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Estado"
                    formControlName="estado"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>check_circle</mat-icon>
                </mat-form-field>
              </div>
              <!-- Responsable -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Responsable"
                    formControlName="nombreResponsable"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </div>

              <!--NumeroCuenta -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Cuenta"
                    formControlName="NumeroCuenta"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 2 -->
            <div class="row">
             
              <!--rut -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Rut"
                    formControlName="rut"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>account_box</mat-icon>
                </mat-form-field>
              </div>
              <!-- link -->
             <!--  <div class="col">
                <a mat-button href="{{ linkValue }}" target="_blank">{{ linkValue }}
                  <mat-icon matSuffix>https</mat-icon>
                </a>
              </div> -->

              <!-- fechaSolicitud -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Fecha Solicitud"
                    formControlName="fechaSolicitud"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>calendar_today</mat-icon>
                </mat-form-field>
              </div>
              <!-- Hora de Solicitud-->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Hora Solicitud"
                    formControlName="horaSolicitud"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>watch_later</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 2 -->
            <div class="row">
              <!-- Solicita -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Solicita"
                    formControlName="nombreResponsable"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </div>
              <!-- Autoriza -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Autoriza"
                    formControlName="jefeAutoriza"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>supervisor_account</mat-icon>
                </mat-form-field>
              </div>
              <!-- Sucursal -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Sucursal"
                    formControlName="nombreSucursal"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>store</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 3 -->
            <div class="row">
              <!-- Empresa q asume -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Cuenta Asume Gasto"
                    formControlName="asumeFactura"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>shop</mat-icon>
                </mat-form-field>
              </div>
              <!-- Rut Empresa -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Rut Empresa"
                    formControlName="RutEmpresa"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </div>

              <!-- N° cuenta q asume -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="N° Cuenta Empresa"
                    formControlName="NumeroCuentaAsume"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 4 -->
            <div class="row">
              <!-- Banco Empresa -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Banco Origen"
                    formControlName="banco"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>

              <!-- Tipo Gasto -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Tipo de Egreso"
                    formControlName="inversion"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>assignment_turned_in</mat-icon>
                </mat-form-field>
              </div>
              <!-- Trasferir a -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Trasnferir a"
                    formControlName="nombreTransferencia"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>assignment_ind</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 5 -->
            <div class="row">
              <!--rut -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Rut"
                    formControlName="rut"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>account_box</mat-icon>
                </mat-form-field>
              </div>
              <!--NumeroCuenta -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Cuenta Destino"
                    formControlName="NumeroCuenta"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>

              <!-- Banco destino -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Banco Destino"
                    formControlName="bancoCliente"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>
            </div>



            <!-- Fila -->
            <div class="row">
              <!-- Tipo Cuenta   -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  placeholder="Tipo de Cuenta"
                  formControlName="tipoCuenta"
                  readonly="»readonly»"
                />
                <mat-icon matSuffix>account_balance_18dp</mat-icon>
              </mat-form-field>
            </div>
              <!-- montoPago -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Monto Pago"
                    formControlName="montoPago"
                    readonly="»readonly»"
                  />
                  <span matPrefix>$&nbsp;</span>
                  <mat-icon matSuffix>monetization_on_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!-- Correo Destino -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Correo Destino"
                    formControlName="correo"
                    readonly="»readonly»"
                  />

                  <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>
              </div>
              <!--Fecha Rechazo/Autorizado-->
              <div class="col" [hidden]="!mostrarDiv">
                <mat-form-field appearance="legacy">
                  <input
                    matInput
                    placeholder="Hora Autorizado/Rechazado"
                    formControlName="horaEstado"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>watch_later</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 6 -->
            <div class="row">
              <!-- link de pago -->
              <div class="col">
                <div class="col">
                  <label>Link de pago: </label>
                  <a mat-button (click)="verificarURL()" href="{{ linkValue }}" target="_blank">{{ linkValue }}
                    <mat-icon matSuffix>https</mat-icon>
                  </a>
                </div>
              </div>
            </div>

            <!-- Fila 7 -->
            <div class="row">
              <!--Motivo Pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Motivo Pago"
                    formControlName="motivoPago"
                    readonly="»readonly»"
                  />
                  <mat-icon matSuffix>question_answer</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <br />
        <mat-accordion>
          <!-- Informacion adicional -->
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Informacion Adicional </mat-panel-title>
              <mat-panel-description>
                Presiona para mostrar los datos Adicionales
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card-content>
              <mat-card>
                <div class="row">
                  <div class="col" *ngIf="rol=='Administrador'">
                    <mat-form-field appearance="legacy">
                      <mat-label>Selecciona Estado </mat-label>
                      <mat-select formControlName="estado">
                        <mat-option value="Autorizada"> Autorizada </mat-option>
                        <mat-option value="Rechazada">Rechazada</mat-option>
                      </mat-select>
                      <mat-icon matSuffix>account_balance_18dp</mat-icon>
                    </mat-form-field>
                  </div>
                  <div class="col" *ngIf="rol!='Administrador'">
                    <mat-label>No tienes permiso para continuar.</mat-label>
                  </div>

                  <div
                    class="col"
                    *ngIf="addressForm.get('estado').value === 'Rechazada'"
                  >
                    <mat-form-field appearance="legacy">
                      <input
                        matInput
                        placeholder="Razon de Rechazo"
                        formControlName="razonRechazo"
                      />
                    </mat-form-field>
                  </div>

                  <div
                    class="col"
                    *ngIf="addressForm.get('estado').value === 'Autorizada'"
                  >
                    <mat-form-field appearance="legacy">
                      <input
                        matInput
                        placeholder="Razon de Apruebo"
                        formControlName="razonApruebo"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <br />
              </mat-card>
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>
        <br>
         
        
          
        <br />
        <mat-card-actions>
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
          <button
         
            mat-stroked-button
            color="primary"
            type="submit"
            (click)="onSubmit()"
            (click)="subirFactura()"
            [disabled]="
              (addressForm.get('estado').value == 'Rechazada' &&
                (addressForm.get('razonRechazo').value == null ||
                  addressForm.get('razonRechazo').value == '')) ||
              this.est === 'Saldado' ||
              this.est === 'Autorizada' ||
              this.est === 'Rechazada' ||
              this.est === 'Pagado'
            "
          >
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
