import { Component, OnInit } from '@angular/core'
import { ConsolidadoBancarioService } from '../../../consolidado-bancario.service'
import { first } from 'rxjs/operators'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { DomSanitizer } from '@angular/platform-browser'
import { FormBuilder, FormsModule } from '@angular/forms'

@Component({
  selector: 'app-dialog-solicitudes-antiguas',
  templateUrl: './dialog-solicitudes-antiguas.component.html',
  styleUrls: ['./dialog-solicitudes-antiguas.component.scss']
})
export class DialogSolicitudesAntiguasComponent implements OnInit {
  idSolicitudOld: string
  panelOpenState = false
  previsualizacion: string
  numeroCuenta: string
  numeroCuentaAsume: string
  asumeFactura: string
  banco: string
  bancoCliente: string
  correo: string
  empresaAsumeGasto: string
  estado: string
  fechaSolicitud: string
  nombreSucursal: string
  nombreUsuario: string
  inversion: string
  jefeAutoriza: string
  montoPago: string
  motivoPago: string
  nombreResponsable: string
  nombreTransferencia: string
  razonApruebo: string
  razonRechazo: string
  rut: string
  tipoCuenta: string

  constructor(
    private administracionService: AdministracionService,
    private bancarioService: ConsolidadoBancarioService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.idSolicitudOld = localStorage.getItem('idSolicitudOld')
    this.getSolicitudOld()
  }

  closeDialog() {
    this.administracionService.closeDialogModal()
    window.location.reload()
  }

  //* cargar el dialog con solicitud antigua
  getSolicitudOld() {
    this.bancarioService
      .getSolicitudOld(this.idSolicitudOld)
      .pipe(first())
      .subscribe((x: any) => {
        console.log(x)
        this.numeroCuenta = x[0]?.NumeroCuenta ?? 'NO APLICA '
        this.numeroCuentaAsume =
          x[0]?.NumeroCuentaAsume ?? 'valorPredeterminado'
        this.asumeFactura = x[0]?.asumeFactura ?? 'NO APLICA '
        this.banco = x[0]?.banco ?? 'NO APLICA '
        this.bancoCliente = x[0]?.bancoCliente ?? 'NO APLICA '
        console.log('corrreoooo:', typeof x[0]?.correo)
        this.correo =
          x[0]?.correo !== undefined && x[0].correo !== ''
            ? x[0].correo
            : 'NO APLICA '
        this.empresaAsumeGasto =
          x[0]?.empresaAsumeGasto ?? 'NO APLICA '
        this.estado = x[0]?.estado ?? 'NO APLICA '
        this.fechaSolicitud = x[0]?.fechaSolicitud ?? 'NO APLICA '
        this.nombreSucursal = x[0]?.nombre_sucursal ?? 'NO APLICA '
        this.nombreUsuario = x[0]?.nombre_usuario ?? 'NO APLICA '
        this.inversion = x[0]?.inversion ?? 'NO APLICA '
        this.jefeAutoriza = x[0]?.jefeAutoriza ?? 'NO APLICA '
        this.montoPago = x[0]?.montoPago ?? 'NO APLICA '
        this.motivoPago = x[0]?.motivoPago ?? 'NO APLICA '
        this.nombreResponsable =
          x[0]?.nombreResponsable ?? 'NO APLICA '
        this.nombreTransferencia =
          x[0]?.nombreTransferencia ?? 'NO APLICA '
        this.razonApruebo = x[0]?.razonApruebo ?? 'NO APLICA '
        this.razonRechazo = x[0]?.razonRechazo ?? 'NO APLICA '
        this.rut = x[0]?.rut ?? 'NO APLICA '
        this.tipoCuenta = x[0]?.tipoCuenta ?? 'NO APLICA '

        this.previsualizacion = x[0].archivos
      })
  }
}
