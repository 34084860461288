<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Actualizar IMG">
    <form [formGroup]="addressForm" novalidate (ngSubmit)="agregarImg()">
      <mat-card class="shipping-card">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>Informacion IMG </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <input mat-stroked-button (change)="capturarFile($event)" type="file" />
              <br />
              <button mat-stroked-button color="primary" [disabled]="loading" (click)="subirImg()"
                (click)="clickButton = false" class="separador">
                {{ loading ? 'Cargando....' : 'Subir IMG' }}
              </button>
              <hr />
              <h3>Previsualizacion</h3>
              <div class="row">
                <div class="col">
                  <img width="400px" [src]="previsualizacion" alt="img" />
                </div>
              </div>
            </div>
            <hr class="separador" />
          </div>
        </mat-expansion-panel>
        <mat-card-actions>
          <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="IMAGEN 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()">
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="EDITAR 📝">
    <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form [formGroup]="addressForm" novalidate class="mat-elevation-z10" (ngSubmit)="onSubmit()">
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Egresos Inmobiliaria</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Informacion Basica -->
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>Información Cliente </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- informacion fila uno -->
            <div class="row">
              <!-- Propiedades -->
              <!-- <div class="col">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Propiedad" formControlName="propiedad">
                    <mat-option *ngFor="let p propiedades " [value]="p">
                   {{p}}
                    </mat-option>
                    
                  </mat-select>
                  <mat-error *ngIf="
                      addressForm.controls['propiedad'].hasError('required')
                    ">
                    propiedad es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" *ngIf="f.propiedad.value == 'Otra'">
                  <input matInput placeholder="Otra Propiedad" formControlName="otraPropiedad" type="text" />
                </mat-form-field>
              </div> -->
              <!-- Engresos -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Tipo Egreso" formControlName="tipoEgreso">
                    <mat-option value="Gastos"> Gastos </mat-option>
                    <mat-option value="Costos"> Costos </mat-option>
                    <mat-option value="Remuneraciones">
                      Remuneraciones
                    </mat-option>
                    <mat-option value="Bancarios"> Bancarios </mat-option>
                    <mat-option value="Impuestos"> Impuestos </mat-option>
                    <mat-option value="Inversiones"> Inversiones </mat-option>
                    <mat-option value="Prestamos Bancarios">
                      Prestamos Bancarios
                    </mat-option>
                    <mat-option value="Prestamos Automotriz">
                      Prestamos Automotriz
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      addressForm.controls['tipoEgreso'].hasError('required')
                    ">
                    tipo ingreso es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- Descripcion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Descripción Egreso" formControlName="descripcion" />
                  <mat-error *ngIf="
                      addressForm.controls['descripcion'].hasError('required')
                    ">
                    este campo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-label>Seleccionar fecha</mat-label>
                  <input matInput formControlName="fecha" [matDatepicker]="picker" [readonly]="true" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="addressForm.controls['fecha'].hasError('required')">
                    fecha es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- monto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Monto" formControlName="monto" type="text" [ngModel]="result2" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['monto'].hasError('required')">
                    Monto es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- tipo pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Tipo Pago" formControlName="tipoPago">
                    <mat-option *ngFor="let tipoPago of tiposPagos" [value]="tipoPago">
                      {{ tipoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    ">
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Grupo de envio de gastos -->
              <!-- <div class="col">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Grupo de envio de gastos" formControlName="grupoGastos">
                    <mat-option *ngFor="let g of grupos" [value]="g">
                      {{ g }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      addressForm.controls['grupoGastos'].hasError('required')
                    ">
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div> -->
            </div>
          </mat-expansion-panel>
          <br class="separador" />

          <!-- Informacion IMG -->
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>Información Imagen</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- Capturar img -->
                <div>
                  <input mat-stroked-button (change)="capturarFile($event)" type="file" />
                </div>
                <br />
                <div>
                  <!-- <button mat-stroked-button color="primary" [disabled]="loading" (click)="subirImg()"
                    (click)="clickButton = false">
                    {{ loading ? 'Cargando....' : 'Subir IMG' }}
                  </button> -->
                </div>
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
