import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ComprasHomeComponent } from './compras-home/compras-home.component'
import { ComprasFormComponent } from './compras-home/compras-form/compras-form.component'
import { ComprasTableComponent } from './compras-home/compras-table/compras-table.component'
import { ComprasRoutingModule } from './compras-routing.module'
import { ComponentsModule } from '@app/_components/components.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FullCalendarModule } from '@fullcalendar/angular'
import { HttpClientModule } from '@angular/common/http'
import { MaterialModule } from '@app/material.module'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableExporterModule } from 'mat-table-exporter'
import { MatTabsModule } from '@angular/material/tabs';
import { ComprasAccionesComponent } from './compras-home/compras-table/compras-acciones/compras-acciones.component'

@NgModule({
  declarations: [
    ComprasHomeComponent,
    ComprasFormComponent,
    ComprasTableComponent,
    ComprasAccionesComponent
  ],
  imports: [CommonModule,
    ComprasRoutingModule,
    MatTabsModule,
    MaterialModule,
    HttpClientModule,
    ComponentsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FormsModule,
    MatTableExporterModule,
    MatStepperModule,
  ],
  exports:[
    ComprasRoutingModule,
    ComprasFormComponent,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
    MatStepperModule,
  ]
})
export class ComprasModule {}
