<mat-dialog-content class="dialog-content">
  
  <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
(click)="guardarYVerificarCambios(false)">
<mat-icon>cancel_white_18dp</mat-icon> 
</button>

</mat-dialog-content>

<mat-dialog-content class="dialog-content">
 
<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Informacion De La Cuenta">
    <!-- Mostrar el mensaje de error si existe -->
    <div *ngIf="mensajeError" class="alert alert-danger mensaje-error-global">
      {{ mensajeError }}
    </div>
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <h1>Cuenta Bancaria</h1>
        <hr />
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Informacion General </mat-panel-title>
              <mat-panel-description>
                Presiona para mostrar los datos bancario
              </mat-panel-description>
            </mat-expansion-panel-header>
            <hr />
            <!-- Datos de la Empresa... Fila 1 -->
            <div class="rows">
              <!-- estado -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Estado" formControlName="estado" readonly="»readonly»" />
                  <mat-icon matSuffix>monetization_on_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!-- Solicita -->
              <div class="cols">
                <mat-form-field class="legacy">
                  <input matInput placeholder="Solicita" formControlName="nombreResponsable" readonly="»readonly»" />
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </div>
              <!-- jefeAutoriza -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Autoriza" formControlName="jefeAutoriza" readonly="»readonly»" />
                  <mat-icon matSuffix>supervisor_account</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 2 -->
            <div class="rows">
              <!-- fechaSolicitud -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Fecha Solicitud" formControlName="fechaSolicitud"
                    readonly="»readonly»" />
                  <mat-icon matSuffix>calendar_today</mat-icon>
                </mat-form-field>
              </div>
              <!--Hora Solicitud -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Hora Solicitud" formControlName="horaSolicitud" readonly="»readonly»" />
                  <mat-icon matSuffix>watch_later</mat-icon>
                </mat-form-field>
              </div>

              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Hora Autorizado/Rechazado" formControlName="horaEstado"
                    readonly="»readonly»" />
                  <mat-icon matSuffix>watch_later</mat-icon>
                </mat-form-field>
              </div>

            </div>


            <!-- Fila 2 -->
            <div class="rows">
              <!-- Empresa q asume -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Cuenta Asume Gasto" formControlName="asumeFactura"
                    readonly="»readonly»" />
                  <mat-icon matSuffix>shop</mat-icon>
                </mat-form-field>
              </div>
              <!-- Rut Empresa -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Rut Empresa" formControlName="RutEmpresa" readonly="»readonly»" />
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </div>
              <!--Cuenta q Asume -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="N° Cuenta empresa" formControlName="NumeroCuentaAsume"
                    readonly="»readonly»" />
                  <mat-icon matSuffix>account_balance_wallet</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 3 -->
            <div class="rows">
              <!-- Banco Empresa -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Banco Origen" formControlName="banco" readonly="»readonly»" />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>
              <!-- Tipo de Gasto  -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Tipo de Egreso" formControlName="inversion" readonly="»readonly»" />
                  <mat-icon matSuffix>assignment_turned_in</mat-icon>
                </mat-form-field>
              </div>
              <!-- Trasferir a -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Pagar a" formControlName="nombreTransferencia" readonly="»readonly»" />
                  <mat-icon matSuffix>assignment_ind</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 4 -->
            <div class="rows">
              <!--Rut Cliente -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Rut" formControlName="rut" readonly="»readonly»" />
                  <mat-icon matSuffix>account_box</mat-icon>
                </mat-form-field>
              </div>
              <!--NumeroCuenta Cliente -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Cuenta Destino" formControlName="NumeroCuenta" readonly="»readonly»" />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>
              <!--Banco a Trasferir -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Banco Destino" formControlName="bancoCliente" readonly="»readonly»" />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <!-- fila 5  -->

            <!--Fila-->
            <div class="rows">
              <!-- montoPago -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Monto Pago" formControlName="montoPago" readonly="»readonly»" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-icon matSuffix>monetization_on_white_18dp</mat-icon>
                </mat-form-field>
              </div>

              <!-- Tipo Cuenta   -->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Tipo de Cuenta" formControlName="tipoCuenta" readonly="»readonly»" />
                  <mat-icon matSuffix>account_balance_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!--Correo-->
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Correo Destino" formControlName="correo" readonly="»readonly»" />
                  <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <!-- Sucursal -->
            <div class="cols">
              <mat-form-field appearance="legacy">
                <input matInput placeholder="Sucursal" formControlName="nombreSucursal" readonly="»readonly»" />
                <mat-icon matSuffix>store</mat-icon>
              </mat-form-field>
            </div>
            <div class="rows">
              <div class="cols">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Hora Pagado/Transferida" formControlName="horapago"
                    readonly="»readonly»" />
                  <mat-icon matSuffix>watch_later</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <!-- fila 6 -->

            <!--Hora Autoriza/rechaza-->
            <div class="rows">
              <div class="cols">
                <label>Link de pago: </label>
                  <a mat-button (click)="verificarURL()" href="{{ linkValue }}" target="_blank">{{ linkValue }}
                    <mat-icon matSuffix>https</mat-icon>
                  </a>
              </div>
            </div>

            <!-- Fila 7 -->
            <div class="rows">
              <!--Motivo Pago -->
              <div class="cols">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Motivo Pago" formControlName="motivoPago" readonly="»readonly»" />
                  <mat-icon matSuffix>question_answer</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <br />
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Informacion Adicional </mat-panel-title>
              <mat-panel-description>
                Presiona para mostrar los datos Adicionales
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card-content>
              <mat-card>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="legacy">
                      <mat-label>Selecciona Estado </mat-label>
                      <mat-select formControlName="estado">
                        <mat-option value="Pagado">Pagado</mat-option>
                        <mat-option value="Rechazada">Rechazada</mat-option>
                        <mat-option value="Devuelto">Devuelto</mat-option>

                      </mat-select>
                      <mat-icon matSuffix>ballot</mat-icon>
                    </mat-form-field>
                  </div>

                  <div class="col" *ngIf="addressForm.get('estado').value === 'Rechazada'">
                    <mat-form-field appearance="legacy">
                      <input matInput placeholder="Razón de Rechazo" formControlName="razonRechazo" />
                    </mat-form-field>
                  </div>

                  <div class="col"
                    *ngIf="addressForm.get('estado').value === 'Pagado' || addressForm.get('estado').value === 'Devuelto'">
                    <!-- Capturar img -->
                    <div>
                      <!-- <div class="file-upload-container">
                        <input type="file" id="fileInput2" (change)="onFileChange($event, null, this.idPagoBancario)" class="input-file">
                        
                        <label for="fileInput2" class="btn-custom-upload">
                          Seleccionar archivo
                        </label>
                      </div> -->
                      <div class="file-upload-container">
                        <input type="file" id="fileInput2" (change)="onFileChange($event, null, this.idPagoBancario)" class="input-file">
                        
                        <label for="fileInput2" class="btn-custom-upload">
                          Seleccionar archivo
                        </label>
                        
                        <p *ngIf="fileSize">Tamaño del archivo: {{ fileSize }} MB</p>
                      </div>
                      <!-- <input mat-stroked-button (change)="capturarFile($event,addressForm.get('estado').value)"
                        type="file" /> -->
                        <!-- <input mat-stroked-button (change)="onFileChange($event,null,this.idPagoBancario)"
                        type="file" /> -->
                    </div>
                    <br />
                  </div>
                </div>
                <div
                  *ngIf="addressForm.get('estado').value === 'Pagado' || addressForm.get('estado').value === 'Devuelto'">
                  <br />
                  <h3>Pago Previsualizacion</h3>
                  <hr />
                </div>
                <!-- Preview img -->
                <div class="row">
                  <!-- Columna para PrevwI, pdfPreviewPago o imagePreviewPago -->
                  <div class="col" *ngIf="PrevwI || pdfPreviewPago || imagePreviewPago">
                    <!-- Iframe para PrevwI -->
                    <iframe
                      *ngIf="PrevwI && archivoSrc"
                      [src]="archivoSrc"
                      class="iframe-window"
                      width="800"
                      height="600"
                      frameborder="0"
                    ></iframe>
                
                    <!-- Iframe para pdfPreviewPago -->
                    <iframe
                      *ngIf="pdfPreviewPago"
                      [src]="pdfPreviewPago"
                      class="iframe-window"
                      width="800"
                      height="600"
                      frameborder="0"
                    ></iframe>
                
                    <!-- Imagen para imagePreviewPago -->
                    <div *ngIf="imagePreviewPago" class="iframe-window">
                      <img [src]="imagePreviewPago" alt="Imagen de pago" class="image-style" />
                    </div>
                
                    <!-- Botón debajo del contenido -->
                    <div *ngIf=" PrevwI" class="button-container">
                      <div class="button-container">
                        <button class="btn-descargar" (click)="descargarImagen(archivoSrc)">
                          Descargar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>
        <br>
        <mat-accordion *ngIf="addressForm.get('estado').value === 'Pagado'">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Informacion Factura </mat-panel-title>
              <mat-panel-description>
                Presiona para mostrar los datos de factura
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card-content>
              <mat-card>
                <div class="row">

                  <!-- <div class="col">
                    <mat-form-field appearance="legacy">
                      <mat-label>Selecciona Estado </mat-label>
                      <mat-select formControlName="estadoFactura">
                        <mat-option value="Por Emitir" *ngIf="addressForm.get('estadoFactura').value === 'Por Emitir'||
                          addressForm.get('estadoFactura').value === null ||
                          addressForm.get('estadoFactura').value === 'Por Confirmar'">Por Emitir</mat-option>
                        <mat-option value="Por Confirmar">Emitida</mat-option>
                        <mat-option value="Emitida">Emitida</mat-option>
                        
                      </mat-select>
                      <mat-icon matSuffix>ballot</mat-icon>
                    </mat-form-field>
                  </div> -->
                  <div class="col">
                    <mat-form-field appearance="legacy">
                      <mat-label>Selecciona Estado</mat-label>
                      <mat-select formControlName="estadoFactura">
                        <!-- Opción "No Emitida" siempre disponible -->
                        <mat-option value="No Emitida">No Emitida</mat-option>
                  
                        <!-- Opción "Por Confirmar" siempre disponible -->
                        <mat-option value="Por Confirmar">Por Confirmar</mat-option>
                  
                        <!-- Opción "Emitida" solo si ya viene con ese estado desde la base de datos -->
                        <mat-option *ngIf="addressForm.get('estadoFactura').value === 'Emitida'" value="Emitida">Emitida</mat-option>
                      </mat-select>
                      <mat-icon matSuffix>ballot</mat-icon>
                    </mat-form-field>
                  </div>

                  <!-- Capturar Facturas -->
                  <div class="col" *ngIf="addressForm.get('estadoFactura').value === 'Por Confirmar'|| addressForm.get('estadoFactura').value === 'Emitida'">
                    <div class="file-upload-container">
                      <input type="file" id="fileInput" (change)="onFileChange($event, addressForm.get('estadoFactura').value,this.idPagoBancario)" class="input-file">
                      
                      <label for="fileInput" class="btn-custom-upload">
                        Seleccionar archivo
                      </label>
                    </div>
                    <br /> 
                    <div class="col">
                      <label class="label-titulo">Confirmado por Finanzas</label>
                      <!-- <div class="btn-group">
                      
                        <button
                          type="button"
                          class="btn btn-custom"
                          [class.active]="this.f.confirmadoPorFinanzas.value == 'Confirmado' || f.confirmadoPorFinanzas.value == true"
                          (click)="addressForm.get('confirmadoPorFinanzas').setValue(true); f.confirmadoPorFinanzas.setValue(true)"
                          [disabled]="rolUsuario === 'User' || rolUsuario === 'Operaciones' || rolUsuario === 'RecursoHumano'"
                        >
                          Sí
                        </button>
                    
                       
                        <button
                          type="button"
                          class="btn btn-custom"
                          [class.active]="this.f.confirmadoPorFinanzas.value === 'No Confirmado' || f.confirmadoPorFinanzas.value === false"
                          (click)="addressForm.get('confirmadoPorFinanzas').setValue(false); f.confirmadoPorFinanzas.setValue(false)"
                          [disabled]="rolUsuario === 'User' || rolUsuario === 'Operaciones' || rolUsuario === 'RecursoHumano'"
                        >
                          No
                        </button>
                      </div> -->

                      <!-- Pruebaaaaaaa -->
                      <div class="col">
                        <!-- <label>Estado de Confirmación</label> -->
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn"
                            [ngClass]="{'active': addressFormPrueba.get('confirmadoPorFinanzas')?.value === 'Confirmado'}"
                            (click)="setConfirmado('Confirmado')"
                            [disabled]="rolUsuario === 'User' || rolUsuario === 'Operaciones' || rolUsuario === 'RecursoHumano'|| rolUsuario === 'Finanza'"
                          >
                            Confirmado
                          </button>
                      
                          <button
                            type="button"
                            class="btn"
                            [ngClass]="{'active': addressFormPrueba.get('confirmadoPorFinanzas')?.value === 'No Confirmado'}"
                            (click)="setConfirmado('No Confirmado')"
                            [disabled]="rolUsuario === 'User' || rolUsuario === 'Operaciones' || rolUsuario === 'RecursoHumano'|| rolUsuario === 'Finanza'"
                          >
                            No Confirmado
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="addressForm.get('estadoFactura').value === 'Por Confirmar' || addressForm.get('estadoFactura').value === 'Emitida'">
                  <br />
                  <h3>Factura Previsualizacion</h3>
                  <hr />
                </div>

                <!-- Preview Factura-->
                 <!-- Preview Factura-->
                <!-- Preview Factura-->
                <div class="row">
                  <!-- Columna para iframe o imagen -->
                  <div class="col" *ngIf="prevw || pdfPreview || imagePreview">
                    <!-- Iframe para prevw -->
                    <iframe
                      *ngIf="prevw && url"
                      [src]="url"
                      class="iframe-window"
                      width="800"
                      height="600"
                      frameborder="0"
                    ></iframe>

                    <!-- Iframe para pdfPreview -->
                    <iframe
                      *ngIf="pdfPreview"
                      [src]="pdfPreview"
                      class="iframe-window"
                      width="800"
                      height="600"
                      frameborder="0"
                    ></iframe>

                    <!-- Imagen para imagePreview -->
                    <img
                      *ngIf="imagePreview"
                      [src]="imagePreview"
                      class="image-window"
                      width="800"
                      height="600"
                    />
                   

                    <!-- Botón de descargar abajo a la izquierda del iframe o imagen -->
                    
                  </div>
                  <div *ngIf="prevw" class="button-container">
                    <div class="button-container">
                      <button class="btn-descargar" (click)="descargarImagen(url)">
                        Descargar
                      </button>
                    </div>
                  </div>
                </div>
               
              </mat-card>
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>
        <br />
        <mat-card-actions>
          <!-- Botón de Cancelar -->
          <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
            (click)="guardarYVerificarCambios(false)">
            <mat-icon>cancel_white_18dp</mat-icon> Cancelar
          </button>
        
          <!-- Botón para estados Pagado, Devuelto o Por Confirmar -->
  
          <button
          *ngIf="addressForm.get('estado').value == 'Pagado' || addressForm.get('estado').value == 'Devuelto' || addressForm.get('estadoFactura').value == 'Por Confirmar'"
            mat-stroked-button color="primary" type="submit" [disabled]="loading"
            (click)="ejecutarTodasLasFunciones()">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
          
        
          <!-- Botón para estado Rechazada -->
          <button *ngIf="addressForm.get('estado').value == 'Rechazada'"
            mat-stroked-button color="primary" type="submit"
            (click)="ejecutarTodasLasFunciones()">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </form>



  </mat-tab>
</mat-tab-group>
</mat-dialog-content>