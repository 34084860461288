import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfiguracionCesionariosListComponent } from '../configuracion-cesionarios-list.component';
import { first } from 'rxjs/operators';
import { ConfiguracionService } from '../../configuracion.service';
import { format } from 'rut.js'

@Component({
  // providers:[ ConfiguracionCesionariosListComponent ],
  selector: 'app-dialog-cesionario-editar',
  templateUrl: './dialog-cesionario-editar.component.html',
  styleUrls: ['./dialog-cesionario-editar.component.scss']
})
export class DialogCesionarioEditarComponent implements OnInit {

  formularioListo = new EventEmitter<string>();

  idCesionario : any;
  dataCesionario: any;
  razonSocial!: any;

  private emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

  cesionarios : any;

  // ? Configuración de formulario
  addressForm = this.fb.group({
    idEmpresa: ['', Validators.required],
    razonSocial: ['', Validators.required],

    id : ['', Validators.required],
    nombre : ['', Validators.required],
    rut: [{
      value : '',
      disabled: true
    }, Validators.required],
    direccion: ['', Validators.required],
    contacto : ['', Validators.required],
    email: ['', [Validators.required, Validators.minLength(5), Validators.pattern(this.emailPattern)]],
    telefono: ['', Validators.required],
    estado: ['1']
  });

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private configuracionService: ConfiguracionService,
    private comp: ConfiguracionCesionariosListComponent 
  )
  {}

  ngOnInit(): void {
    this.idCesionario = localStorage.getItem("idCesionario");
    this.getCesionarios();
  }

  get f() {
    return this.addressForm.controls;
  }

  // Obtener cesionarios
  //TODO hacer la consulta únicamente por cesionario no traer todos
  getCesionarios(){
    this.configuracionService
    .cesionariosGetAll()
    .pipe(first())
    .subscribe((cesionarios: any) => {
      cesionarios.forEach((x:any) => {
        if(x.id == this.idCesionario){
          this.f.nombre.setValue(x.nombre);
          this.f.rut.setValue(format(x.rut));
          this.f.telefono.setValue(x.telefono);
          this.f.direccion.setValue(x.direccion);
          this.f.contacto.setValue(x.contacto);
          this.f.email.setValue(x.email);
          this.f.estado.setValue(x.estado);
        }
      });
    });
  }
  // Metodo editar cesionario
  onSubmit(){
  
    this.configuracionService
    .update(this.idCesionario, this.addressForm.value)
    .pipe(first())
    .subscribe(
      (data) => {
        this.snackBar.open('Cesionario actualizado con exito', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
        this.configuracionService.closeDialogModal();
        this.configuracionService.addResultCesionario('Holaaaa')
      },
      (error) => {
        this.snackBar.open(error, 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
      }
    );
  }

  //Cerrar Modal
  closeDialog(){
    this.configuracionService.closeDialogModal();
  }
}
