import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular';
import { GastosFijos} from '@app/_models/gastosFijos/gastosFijos'
import { Empresa } from '@app/_models/shared/empresa';
import { HostalService} from '../../hostal.service'
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-hostal-gastos-fijos-calendario',
  templateUrl: './hostal-gastos-fijos-calendario.component.html',
  styleUrls: ['./hostal-gastos-fijos-calendario.component.scss']
})
export class HostalGastosFijosCalendarioComponent implements OnInit {

  dataEgresos: GastosFijos[] = [];
  eventsCalendar : any = [];
  calendarOptions!: CalendarOptions; 

  empresas: Empresa[] = [];
  idEmpresa = 1;

  constructor(
    private HostalService: HostalService,
    private empresaService: EmpresaSharedService,
    private dialog: MatDialog,
    private miDatePipe: DatePipe,
  ) { 
    this.calendarOptions = {
      eventClick: this.mostrarDetalles.bind(this),
      eventTimeFormat: {
      //meridiem: false 
      }
  
    };
  }

  ngOnInit(): void {
    this.getGastosFijos();

    this.empresaService.getAll().subscribe(empresas => {
      this.empresas = empresas;
    });
  }
  getEmpresaNombre(idEmpresa: number): string {
    const empresa = this.empresas.find(empresa => empresa.id === idEmpresa);
    return empresa ? empresa.razonSocial : '';
  }

  getGastosFijos() {
    this.HostalService.getGastoFijos().subscribe((gastosFijos: GastosFijos[]) => {
      const fechaActual = new Date();
  
      const eventosCalendario = gastosFijos.reduce((result, data) => {
        if (data.Sucursal.idEmpresa === this.idEmpresa) { // Filtrar por idEmpresa
          const fechaGasto = new Date(data.fecha);
          const dia = fechaGasto.getDate();
          const mes = fechaGasto.getMonth() + 1;
          const año = fechaGasto.getFullYear();
          const fechaFormateada = `${dia < 10 ? '0' : ''}${dia}/${mes < 10 ? '0' : ''}${mes}/${año}`;
          let backgroundColor = 'blue';
  
          if (fechaGasto <= fechaActual) {
            backgroundColor = 'red'; // Pasado: rojo
          }
  
          result.push({
            title: `${data.gasto} - ${data.Sucursal.razonSocial} - ${data.monto}`,
            tipodeEgreso: data,
            start: fechaGasto,
            backgroundColor: data.estado === 'Pagado' ? 'green' : backgroundColor,

          });
        }
  
        return result;
      }, []);
  
      this.calendarOptions.events = eventosCalendario;
    });
  }
  mostrarDetalles(info: any) {
    const eventoSeleccionado = info.event;
    const idEvento = eventoSeleccionado.extendedProps.tipodeEgreso.id;
    localStorage.setItem('IDgastosFijos', idEvento)
    this.HostalService.openDialogGastoFijo(idEvento);
  }



}
