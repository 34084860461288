import { Component, OnInit } from '@angular/core';
import { AdministracionService } from '@app/_pages/administracion/administracion.service';
import { RentacarService } from '@app/_pages/rentacar/rentacar.service';
import { first } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import {GastosFijos} from '@app/_models/gastosFijos/gastosFijos'
import { Sucursal } from '@app/_models/shared/sucursal'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser'
import { RentacarConsolidadosComponent } from '../../rentacar-consolidados/rentacar-consolidados.component';
import {RentacarEgresosFormularioComponent} from '../../rentacar-egresos/rentacar-egresos-formulario/rentacar-egresos-formulario.component'


@Component({
  selector: 'app-rentacar-gastos-fijos-edit',
  templateUrl: './rentacar-gastos-fijos-edit.component.html',
  styleUrls: ['./rentacar-gastos-fijos-edit.component.scss']
})
export class RentacarGastosFijosEditComponent implements OnInit {

  modelo: GastosFijos = new GastosFijos()

  idEvento: any
  fecha: any
  monto: any
  idSucursal:any
  gasto:any
  estado:any
  descripcionGasto:any
  sucursales: Sucursal[] = []
  razonSocialSucursal: string; 
  public previsualizacion: string
  public archivos: any = []
  dataArrayImg = []
  clickButton = true
  idEmpresa = 4;

  constructor(
    private administracionService: AdministracionService,
    private rentacarService: RentacarService,
    private fb: FormBuilder,
    private router: Router,
    private sucursalService: SucursalSharedService,
    private sanitizer: DomSanitizer,
  ) { 

    this.sucursales = this.sucursalService.sucursalListValue;

  }



  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    monto: ['', Validators.required],
    idSucursal: ['', Validators.required],
    gasto: ['', Validators.required],
    estado: ['', Validators.required],
    descripcionGasto: ['', Validators.required],
  })

  ngOnInit(): void {
    this.idEvento = localStorage.getItem('IDgastosFijos')
    //console.log("id",this.idGastoFijo)
    this.getGastosFijos()
    //console.log(this.idEvento)
  }
  
  get f() {
    return this.addressForm.controls
  }

  getGastosFijos(){
    this.rentacarService
      .getByIdGastoFijo(this.idEvento)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idEvento) {
          this.f.fecha.setValue(x[0].fecha.slice(0, 10))
          this.f.monto.setValue(x[0].monto)
          this.f.idSucursal.setValue(x[0].idSucursal)
          this.f.gasto.setValue(x[0].gasto)
          this.f.estado.setValue(x[0].estado)
          this.f.descripcionGasto.setValue(x[0].descripcion)

          const sucursal = this.sucursales.find(s => s.id === x[0].idSucursal);
          if (sucursal) {
            this.razonSocialSucursal = sucursal.razonSocial;
          }

          this.monto = x[0].monto;
          this.estado = x[0].estado;
          this.fecha = x[0].fecha;
          this.idSucursal = x[0].idSucursal;
          this.gasto = x[0].gasto;
          this.descripcionGasto= x[0].descripcion;

          if(this.estado == 'Pagado') {
            this.rentacarService
              .getByIdGastoFijoImagen(this.idEvento)
              .pipe(first())
              .subscribe((x: any) => {
                if (x && x.length > 0 && x[0].archivos) {
                  this.previsualizacion = x[0].archivos;
                } else {
                  this.previsualizacion = ''; 
                }
              });
          }
        }
    });
  }
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop() // Obtener la extensión del archivo original
    const nuevoNombre = 'EgresosRentacar' + Date.now() + '.' + extension // Crear un nombre único con el identificador generado dinámicamente
    this.dataArrayImg.push(nuevoNombre) // Agregar el nuevo nombre al array de nombres de archivo
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    }) // Crear un nuevo objeto de tipo File con el nuevo nombre
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }
  extraerBase64 = async ($event: any) =>
  new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event)
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
      console.log(image);
      const reader = new FileReader()
      reader.readAsDataURL($event)
      reader.onload = () => {
        resolve({
          base: reader.result
        })
      }
      reader.onerror = (error) => {
        resolve({
          base: null
        })
      }
    } catch (e) {
      return null
    }
  })


  closeDialog() {
    this.administracionService.closeDialogModal()
    this.rentacarService.closeDialogModal()
    window.location.reload()
  }
  navegarALaPagina() {
    const queryParams = {
      idEvento: this.idEvento,
      monto: this.monto,
      fecha: this.fecha,
      idSucursal: this.idSucursal,
      gasto: this.gasto,
      descripcionGasto: this.descripcionGasto,
    };
    this.router.navigate(['/rentacar/egresos/'], { queryParams });
    console.log("hola somos los parametros",queryParams)
    this.rentacarService.closeDialogModal()

    
  }


}
