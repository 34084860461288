import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Categoria } from '@app/_models/categoria/categoria';
import { Producto } from '@app/_models/productos/producto';
import { Variante } from '@app/_models/variante/variante';
import { VariantesService } from '@app/_pages/variantes/variantes.service';
import { first } from 'rxjs/operators';
import { CategoriasService } from '../../categorias/categorias.service';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-productos-list',
  templateUrl: './productos-list.component.html',
  styleUrls: ['./productos-list.component.scss']

})
export class ProductosListComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  

  displayedColumns: string[] = [
    'select',
    'img',
    'id',
    'tipo',
    'nombre',
    'categoria',
    'variante',
    'neto',
    'venta',
    'estado',
    'botones',
  ];

    // Tabla en donde se almacenará los datos de la bd
    dataSource: MatTableDataSource<Producto> = new MatTableDataSource();
    dataProducto: Producto[] = [];
    selectedRows!: any[];
    estadoAfecto = ''


    formFilter = new FormGroup({
    img: new FormControl(),
    tipo: new FormControl(),
    nombre: new FormControl(),
    categoria: new FormControl(),
    neto: new FormControl(),
    venta: new FormControl(),
    estado: new FormControl(),
    botones: new FormControl(),
    estadoAfecto: new FormControl()
    })
    mostrarcategoria: any = [];
    productos: Producto[] = [];
    categorias: Categoria[] = [];
    variantes: Variante[] = [];
    nombreCategoria!: string;
    selection = new SelectionModel<Producto>(true, []);
    productosDelete!: any;
    totalSeleccion = 0;
    dataNuevo :any ;
    dataNuevo2 = [] ;
    dataNuevo3 :any ;
    conteo : any;


  constructor(
    private categoriasService: CategoriasService,
    private productosService: ProductosService,
    private variantesService: VariantesService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getCategorias();
    this.getProductos();
    //this.getVariantes();
    this.aplicarfiltros();

    this.productosService
    .productoGet()
    .pipe(first())
    .subscribe(
      (productosDelete) => (this.productosDelete = productosDelete)
    );
  }


    // Obtener las categorias
    getCategorias() {
      this.categoriasService
        .categoriaGet()
        .pipe(first())
        .subscribe((categorias) => {
          this.categorias = categorias;
        });
    }

    getProductos() {
      //Carga Tabla
      this.productosService.productoGet().pipe(first()).subscribe((result: Producto[]) => {
          this.dataProducto = result.map((Producto) => {
          this.variantesService.traerNumeroVariantes(Producto.id).subscribe((res: any) => {
            this.dataNuevo= res
            this.conteo = this.dataNuevo[0].consultaVariantes
            Producto.consultaVariantes = this.conteo
            console.log("este es el id:",Producto.id, "y este es el valor del contador",this.conteo)
          })
            //Buscar Categoria del Producto
          this.categorias.forEach((categorias) => {
            //console.log(Producto.idCategoria, "  -  " , categorias.id);
             if (Producto.idCategoria == categorias.id) {
                Producto.nombreCategoria = categorias.nombre;
             }
          })

          return Producto;
        });
          this.dataSource = new MatTableDataSource(this.dataProducto);
          console.log(this.dataSource)
          //console.log(this.dataSource)
          this.dataSource.paginator = this.paginator.toArray()[0];
        });
    }


    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

      // Metodo que sirve para la seleccion de un campo de la tabla
    masterToggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        });
  }


      //Metodo eliminar un producto
      borrarProducto(id: any, nombre: any) {
        const producto = this.productosDelete.find((x: any) => x.id === id)
        if (confirm('Esta seguro que desea eliminar el registro: ' + nombre)) {
        producto.isDeleting = true
        this.productosService
          .delete(id)
          .pipe(first())
          .subscribe(() => {
            this.productos = this.productos.filter((x: any) => x.id !== id)
        });
          producto.isDeleting = false;
        }
        this.snackBar.open('Producto eliminado', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top'
        });
        this.getProductos();
    }

    //Abrir Modal Subir Foto
    openDialogFoto(id: any) {
      localStorage.setItem('idProductosEdit', id);
      this.productosService.openDialogFotoProducto();
    }

    //Abrir Modal Subir Foto
    openDialogVariante(id: any) {
      localStorage.setItem('idProductosEdit', id);
      this.productosService.openDialogVariante();
    }

    //Abrir Modal Editar
    openDialogEditar(id: any, nombre: any) {
      localStorage.setItem('idProductosEdit', id);
      localStorage.setItem('nombreProductosEdit', nombre);
      this.productosService.openDialogEditProductos();
        }

    // Filtros
    aplicarfiltros() {
      this.formFilter.valueChanges.subscribe(res => {

      let dataFiltered = this.dataProducto;

      //Filtro Tipo
      if (res.tipo) {
          dataFiltered = dataFiltered.filter((data: Producto) => data.tipo == res.tipo);
      }
      //Filtro Nombre
      if (res.nombre) {
          dataFiltered = dataFiltered.filter((data: Producto) => data.nombre == res.nombre);
      }
      // //Filtro Categoria
      // if (res.categoria) {
      //   dataFiltered = dataFiltered.filter((data: Producto) => data.nombreCategoria== res.categoria);
      // }

      //Filtro Estado
      if (res.estado) {
        dataFiltered = dataFiltered.filter((data: Producto) => data.estado== res.estado);
      }



        this.dataSource = new MatTableDataSource(dataFiltered);
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.selection.clear()
        this.totalSeleccion = 0;
        })
      }

          //Limpiar los filtros
    limpiarFiltros() {
      this.formFilter.patchValue({ tipo: null})
      this.formFilter.patchValue({ nombre: null})
      this.formFilter.patchValue({ categoria: null})
      this.formFilter.patchValue({ estado: null})
      this.dataSource = new MatTableDataSource(this.dataProducto);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.selection.clear()
      this.totalSeleccion = 0;
    }

    exportAsXLSX(): void {
      this.selectedRows = [];
       this.selection.selected.forEach((x) => this.selectedRows.push(x));
       this.productosService.exportAsExcelFile(this.selectedRows, 'Lista-Productos');
     }

}

