<form
  [formGroup]="addressForm"
  novalidate
  class="mat-elevation-z10"
  (ngSubmit)="onSubmit()"
>
  <mat-card class="shipping-card">
    <mat-card-header>
      <h1>Ajuste de inventario - Consumo (salida)</h1>
    </mat-card-header>
    <br />
    <mat-card-content>
      <div class="row">
        <!-- Fecha Emision -->
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Fecha"
              formControlName="fecha"
              [matDatepicker]="picker"
              [readonly]="true"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="addressForm.controls['fecha'].hasError('required')"
            >
              Fecha es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- N° Documento -->
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="N° Documento"
              formControlName="documento"
              type="number"
            />
            <mat-error
              *ngIf="addressForm.controls['documento'].hasError('required')"
            >
              Bodega es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <!-- Bodega -->
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select
              placeholder="Bodega"
              formControlName="bodega"
              (selectionChange)="onSelectionChange($event)"
            >
              <mat-option
                *ngFor="let bodega of bodegas"
                value="{{ bodega.id }}"
              >
                {{ bodega.nombre }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="addressForm.controls['bodega'].hasError('required')"
            >
              Fecha es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Observacion -->
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Observacion"
              formControlName="observacion"
            />
            <mat-error
              *ngIf="addressForm.controls['observacion'].hasError('required')"
            >
              Nombre es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <!-- botón volver -->
          <button
            routerLink="/ajusteInventario"
            mat-raised-button
            color="primary"
            type="submit"
          >
            <mat-icon>arrow_back_black_24dp</mat-icon> Volver
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>

<form
  [formGroup]="addressForm2"
  novalidate
  class="mat-elevation-z10"
  (ngSubmit)="onSubmit()"
>
  <mat-card class="shipping-card" *ngIf="addButton"
    ><br />
    <div>
      <a
        class="btn btn-primary"
        mat-raised-button
        style="float: right"
        color="primary"
        (click)="agregarProducto()"
      >
        <mat-icon>add_black_18dp</mat-icon>Agregar Producto
      </a>
    </div>
    <ng-container formArrayName="productos">
      <ng-container *ngFor="let producto of productos.controls; let i = index">
        <div [formGroupName]="i">
          <mat-card-content>
            <div class="row">
              <!-- Nombre Producto-->

              <div class="col-sm-4">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Producto"
                    formControlName="productosSeleccionados"
                    [ngModelOptions]="{ standalone: true }"
                    (onChange)="obtenerProductos($event)"
                    (selectionChange)="magia($event, i)"
                    [attr.for]="'productosSeleccionados' + i"
                    [attr.id]="'productosSeleccionados' + i"
                  >
                    <mat-option
                      *ngFor="let producto of lista_productos"
                      value="{{ producto.id_producto }}"
                    >
                      {{ producto.nombre_producto }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Ubicacion -->
              <div class="col-sm-4">
                <mat-form-field class="full-width">
                  <input
                    [attr.for]="'ubicacion' + i"
                    [attr.id]="'ubicacion' + i"
                    matInput
                    placeholder="Ubicacion"
                    formControlName="ubicacion"
                  />
                </mat-form-field>
              </div>

              <!-- Cantidad -->
              <div class="col-sm-3">
                <mat-form-field class="full-width">
                  <input
                    [attr.for]="'cantidad' + i"
                    [attr.id]="'cantidad' + i"
                    matInput
                    placeholder="Cantidad"
                    formControlName="cantidad"
                    (input)="onInputChange($event)"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <!-- Stock-->
              <div class="col-sm-3">
                <mat-form-field class="full-width">
                  <input
                    [attr.for]="'stock' + i"
                    [attr.id]="'stock' + i"
                    matInput
                    placeholder="Stock Actual"
                    [value]="productos.at(i).get('stock').value"
                    readonly
                  />
                </mat-form-field>
              </div>

              <!-- Ajuste -->
              <div class="col-sm-3">
                <mat-form-field class="full-width">
                  <input
                    [attr.for]="'ajuste' + i"
                    [attr.id]="'ajuste' + i"
                    matInput
                    placeholder="Ajuste Actual"
                    formControlName="ajuste"
                    [(ngModel)]="resta"
                    readonly="true"
                  />
                </mat-form-field>
              </div>

              <div>
                <a
                  style="height: 10%"
                  class="btn btn-danger"
                  style="float: right"
                  (click)="eliminarProducto(i)"
                >
                  <mat-icon>delete_black_24dp</mat-icon>
                </a>
              </div>
            </div>
            <br />
          </mat-card-content>
        </div>
      </ng-container>
    </ng-container>
    <button
      style="float: right"
      mat-raised-button
      color="primary"
      type="submit"
    >
      <mat-icon>send</mat-icon> Enviar
    </button>
  </mat-card>
</form>
