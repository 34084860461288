import { Sucursal } from '../shared/sucursal'
import { Usuario } from '../shared/usuario'

export class IngresosAbogado {
  id!: number
  monto!: number
  nAutorizacion!: string
  fecha!: Date
  rutCliente!: string
  nombreCliente!: string
  tipoCliente!: string
  tipoIngreso!: string
  estadoPago!: any
  movil!: string
  correo!: string
  tipoPago!: string
  referenciaCliente!: string
  nDocumento!: string
  tipoDocumento!: string
  descripcionIngreso!: string
  img!: string[]
  contrato!: string[]
  idSucursal!: number
  idUsuario!: number
  Sucursal!: Sucursal
  Usuario!: Usuario
  Servicios!: any
  telefono: number
  numeroCuenta:number
  banco:string
  infoCliente!: string
  
}

