import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { environment } from '@environments/environment'
import { modeloCompras } from '../../_models/compras/compras'
import { ComprasAccionesComponent } from './compras-home/compras-table/compras-acciones/compras-acciones.component'

@Injectable({
  providedIn: 'root'
})
export class ComprasService {
  constructor(private http: HttpClient, private dialog: MatDialog) {}

  //Registrar compra
  registrarCompra(modelo: modeloCompras): any {
    return this.http.post(`${environment.apiUrl}/compras`, modelo)
  }

  //Enviar Fecha dia mes y año
  fechaPostDia(modelo): any {
    return this.http.post(`${environment.apiUrl}/compras/RCV/dia`, modelo)
  }

  //Enviar Fecha dia mes y año
  ActualizarEstado(modelo): any {
    return this.http.put(`${environment.apiUrl}/compras/RCV/actualizar`, modelo)
  }

  //Subir Archivo
  subirArchivo(formData) {
    return this.http.post(`${environment.apiUrl}/compras/upload`, formData)
  }

  //Llamar tabla de usuario
  getAllCompras(): any {
    return this.http.get<[]>(`${environment.apiUrl}/compras`)
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditCompra(): void {
    const dialogRef = this.dialog.open(ComprasAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  getById(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/compras/${id}`)
  }
  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idCompras')
  }

  //Aceptar Compra
  AceptarCompra(id: any, params: any[]) {
    return this.http.post(`${environment.apiUrl}/compras/aceptar/${id}`, params)
  }
  //ReciboMercancia
  ReciboMercancia(id: any, params: any[]) {
    return this.http.post(`${environment.apiUrl}/compras/reciboMercancia/${id}`, params)
  }

  //Reclamar Compra
  ReclamarCompra(id: any, params: any[]) {
    return this.http.post(
      `${environment.apiUrl}/compras/reclamar/${id}`,
      params
    )
  }

  //Evento Compra
  EventoCompra(id: any, params: any[]) {
    return this.http.post(`${environment.apiUrl}/compras/evento/${id}`, params)
  }
}
