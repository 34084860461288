<mat-accordion>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        
      </mat-panel-title>
      <mat-panel-description>
       Tabla Solicitudes Antiguas
      </mat-panel-description>
    </mat-expansion-panel-header>
<div class="table-responsive">
  <table
    mat-table
    [dataSource]="dataSource"
    matTableExporter
    matSort
    #exporter="matTableExporter"
    matSortActive="id"
    matSortDirection="desc"
    class="mat-elevation-z10"
  >
    <!-- SELECCCION -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- id Columna -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
         <div [formGroup]="formFilter">
          <mat-form-field class="fill limit-width-40">
            <input matInput placeholder="ID" formControlName="id" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Nombre  Columna -->
    <ng-container matColumnDef="nombreTransferencia">
      <th mat-header-cell *matHeaderCellDef>
         <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Nombre"  formControlName="nombreTransferencia" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.nombreTransferencia }}
      </td>
    </ng-container>

    <!-- rut Columna -->
    <ng-container matColumnDef="rut">
      <th mat-header-cell *matHeaderCellDef>
         <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Rut"  formControlName="rut" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.rut }}
      </td>
    </ng-container>

    <!-- Numero Cuenta Columna -->
    <ng-container matColumnDef="NumeroCuenta" class="mat-column-numero-cuenta">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="limit-width-120"
      >
      <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Numero Cuenta"  formControlName="NumeroCuenta" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="limit-width-120">
        {{ element.NumeroCuenta }}
      </td>
    </ng-container>

    <!-- jefeAutoriza Columna -->
    <ng-container matColumnDef="jefeAutoriza">
      <th mat-header-cell *matHeaderCellDef >
         <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Jefe Autoriza"  formControlName="jefeAutoriza" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.jefeAutoriza }}
      </td>
    </ng-container>

    <!-- Campo Fecha -->
    <ng-container matColumnDef="fechaSolicitud" class="mat-column-date">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="limit-width-120"
      >
        <mat-form-field appearance="fill" class="limit-width-120 date-field">
          <!-- Reducí el tamaño -->
          <mat-label>Fecha</mat-label>
          <mat-date-range-input [formGroup]="formFilter" [rangePicker]="picker">
            <input
              matStartDate
              formControlName="start"
              placeholder="Inicio"
              class="small-input"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="Fin"
              class="small-input"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error
            *ngIf="formFilter.controls.start.hasError('matStartDateInvalid')"
            >Fecha inicio inválida</mat-error
          >
          <mat-error
            *ngIf="formFilter.controls.end.hasError('matEndDateInvalid')"
            >Fecha fin inválida</mat-error
          >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.fechaSolicitud | date : 'dd/MM/yyyy' }}
      </td>
    </ng-container>

    <!-- estado Columna -->
    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef>Estado</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; align-items: center">
          <ng-container
            *ngIf="
              element.estado == 'Aceptada' || element.estado == 'Autorizada'
            "
          >
            Autorizado
            <mat-icon style="-webkit-text-fill-color: green"
              >check_box_white_18dp</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estado == 'Pendiente'">
            {{ element.estado }}
            <mat-icon style="-webkit-text-fill-color: rgb(239, 108, 8)"
              >pending_actions_18dp</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estado == 'Rechazada'">
            {{ element.estado }}
            <mat-icon style="-webkit-text-fill-color: rgb(216, 58, 37)"
              >cancel_white_18dp</mat-icon
            >
          </ng-container>
          <ng-container
            *ngIf="element.estado == 'Saldado' || element.estado == 'Pagado'"
          >
            Pagado
            <mat-icon style="-webkit-text-fill-color: rgb(37, 115, 216)"
              >check_box_white_18dp</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estadoFactura === 'Emitida'">
            Emitida
            <mat-icon style="-webkit-text-fill-color: rgb(0, 242, 255)"
              >check_circle-24px</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estadoFactura === 'Por Confirmar'">
            Por Confirmar
            <mat-icon style="-webkit-text-fill-color: rgb(255, 166, 0)"
              >check_circle-24px</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estadoFactura == 'No Emitida'">
            No Emitida
            <mat-icon style="-webkit-text-fill-color: rgb(255, 166, 0)"
              >history-24px</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estado == 'Devuelto'">
            {{ element.estado }}
            <mat-icon style="-webkit-text-fill-color: rgb(255, 213, 0)"
              >cancel_white_18dp</mat-icon
            >
          </ng-container>
        </div>
      </td>
    </ng-container>

    <!-- montoPago Columna -->
    <ng-container matColumnDef="montoPago">
      <th mat-header-cell *matHeaderCellDef>
         <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Monto"  formControlName="montoPago" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <span matPrefix>$&nbsp;</span
        >{{ element.montoPago?.toLocaleString('es-CL') }}
        <!-- agregar .toLocaleString('es-CL') -->
      </td>
    </ng-container>
    <!-- Montivo Pago -->
    <ng-container matColumnDef="motivoPago">
      <th mat-header-cell *matHeaderCellDef>
         <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Motivo Pago"  formControlName="motivoPago" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="motivo-pago-columna">
        {{ element.motivoPago }}
      </td>
    </ng-container>

    <!-- confirmado por finazas -->
    <ng-container matColumnDef="confirmadoPorFinanzas">
      <th mat-header-cell *matHeaderCellDef>Confirmado Finanzas</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; align-items: center; white-space: nowrap">
          <!-- Mostrar siempre el valor de confirmadoPorFinanzas -->
          <span>{{ element.confirmadoPorFinanzas || 'No Confirmado' }}</span>

          <!-- Mostrar ícono si está confirmado -->
          <mat-icon
            *ngIf="
              element.confirmadoPorFinanzas === 'Confirmado' ||
              element.confirmadoPorFinanzas === true
            "
            style="color: rgb(0, 255, 64); margin-left: 5px"
          >
            check_circle
          </mat-icon>

          <!-- Mostrar ícono si no está confirmado -->
          <mat-icon
            *ngIf="
              element.confirmadoPorFinanzas === 'No Confirmado' ||
              element.confirmadoPorFinanzas === false
            "
            style="color: rgb(255, 128, 0); margin-left: 5px"
          >
            history
          </mat-icon>

          <!-- Mostrar ícono de estado desconocido si no hay valor -->
          <mat-icon
            *ngIf="
              !element.confirmadoPorFinanzas &&
              element.confirmadoPorFinanzas !== false &&
              element.confirmadoPorFinanzas !== 'No Confirmado'
            "
            style="color: grey; margin-left: 5px"
          >
            help_outline
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <!-- Perfil del Usario -->
    <ng-container matColumnDef="perfilUsuario">
      <th mat-header-cell *matHeaderCellDef> 
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Perfil Usuario"  formControlName="perfilUsuario" />
          </mat-form-field>
        </div> </th>
      <td mat-cell *matCellDef="let element">
        {{ element.perfilUsuario }}
      </td>
    </ng-container>

    <!-- Jefe Autoriza/Rechza -->
    <ng-container matColumnDef="nombreAutoriza">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Autoriza"  formControlName="nombreAutoriza" />
          </mat-form-field>
        </div></th>
      <td mat-cell *matCellDef="let element">
        {{ element.razonApruebo }}
      </td>
    </ng-container>

    <!-- Motivo Rechazo -->
    <ng-container matColumnDef="razonRechazo">
      <th mat-header-cell *matHeaderCellDef>
         <div [formGroup]="formFilter">
          <mat-form-field class="fill">
            <input matInput placeholder="Motivo Rezacho"  formControlName="razonRechazo" />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.razonRechazo }}
      </td>
    </ng-container>

    <!-- correo Columna -->
    <ng-container matColumnDef="correo">
      <th mat-header-cell *matHeaderCellDef>Correo</th>
      <td mat-cell *matCellDef="let element">
        {{ element.correo }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="row-with-bottom-line"
    ></tr>

    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef>Acciones</th>
      <td mat-cell *matCellDef="let element">
        {{ element.acciones }}
        <button
          mat-mini-fab
          color="primary"
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="verSolicitud(element.id)"  
        >
          <mat-icon>save_as_white_18dp</mat-icon>
        </button>
        <!-- Dowload -->
        <!-- <button
          mat-mini-fab
          color="accent"
          style="margin-left: 1rem; margin-bottom: 2px"       
        >
          (click)="downloadImage(element.id)" 
          <mat-icon>file_download</mat-icon>
        </button> --> 
      </td>
    </ng-container>
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 20, 30]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
  </mat-expansion-panel>
</mat-accordion>
