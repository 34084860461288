<mat-tab-group>
    <!-- <mat-tab label="Actualizar IMG">

        <form [formGroup]="addressForm" novalidate (ngSubmit)="agregarIMG()">
            <mat-card class="shipping-card" style="overflow-y: auto; max-height: 500px;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Informacion IMG </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col">
                            <input mat-stroked-button (change)="capturarFile($event)" type="file" />
                            <br />
                            <br />
                            <button mat-stroked-button color="primary" [disabled]="loading" (click)="subirIMG()"
                                (click)="clickButton = false" class="separador">
                                {{ loading ? 'Cargando....' : 'Subir IMG' }}
                            </button>
                            <hr />
                            <h3>Previsualizacion</h3>
                            <div class="row">
                                <div class="col">
                                    <img width="400px" [src]="previsualizacion" alt="img" />
                                </div>
                            </div>
                        </div>
                        <hr class="separador" />
                    </div>
                </mat-expansion-panel>
                <mat-card-actions>
                    <button mat-raised-button color="primary" type="submit">
                        <mat-icon>send</mat-icon>
                        Guardar
                    </button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab> -->

    <mat-tab label="IMAGEN 📃 ">
        <form [formGroup]="addressForm" novalidate>
            <mat-card class="shipping-card" style="overflow-y: auto; max-height: 500px;">
                <mat-card-content>
                    <mat-card>
                        <h3>Imagen Previsualizacion</h3>
                        <hr />
                        <div class="row">
                            <div class="col" *ngIf="previsualizacion">
                                <img width="400px" [src]="previsualizacion" alt="img" />
                            </div>
                        </div>
                    </mat-card>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
                        (click)="closeDialog()">
                        <mat-icon>cancel_white_18dp</mat-icon>Cancelar
                    </button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab>

    <mat-tab label="CONTRATO 📃 ">
        <form [formGroup]="addressForm" novalidate>
            <mat-card class="shipping-card" style="overflow-y: auto; max-height: 500px;">
                <mat-card-content>
                    <mat-card>
                        <h3>Contrato Previsualizacion</h3>
                        <hr />
                        <div class="row">
                            <div class="col" *ngIf="previsualizacionContrato">
                                <img width="400px" [src]="previsualizacionContrato" alt="img" />
                            </div>
                        </div>
                    </mat-card>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
                        (click)="closeDialog()">
                        <mat-icon>cancel_white_18dp</mat-icon>Cancelar
                    </button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab>

    <mat-tab label="EDITAR 📝">
        <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
            <mat-icon>highlight_off</mat-icon>
        </button>
        <form [formGroup]="addressForm" novalidate class="mat-elevation-z10" (ngSubmit)="onSubmit()">
            <mat-card class="shipping-card-edit" style="overflow-y: auto; max-height: 500px;">
                <mat-card-header>
                    <h1>Ingresos Firma Abogados</h1>
                </mat-card-header>
                <mat-card-content>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Información Cliente </mat-panel-title>
                        </mat-expansion-panel-header>
                        <!-- Formulario de edicion -->
                        <div class="row">
                            <!-- Fecha -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <mat-label>Seleccionar fecha</mat-label>
                                    <input matInput formControlName="fecha" [matDatepicker]="picker"
                                        (click)="picker.open()" [readonly]="true" />
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error *ngIf="addressForm.controls['fecha'].hasError('required')">
                                        fecha es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- Cliente -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input matInput placeholder="ingrese nombre cliente"
                                        formControlName="nombreCliente" />
                                    <mat-icon matSuffix>account_circle</mat-icon>
                                    <mat-error *ngIf="addressForm.controls['nombreCliente'].hasError('required')">
                                        cliente es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!-- referencia-->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Referencia (N° Cuota)"
                                        formControlName="referenciaCliente"/>
                                    <mat-error *ngIf="addressForm.controls['referenciaCliente'].hasError('required')">
                                        cliente es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- sucursales -->
                            <!-- <div class="col">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Sucursal" formControlName="idSucursal">
                                        <mat-option *ngFor="let sucursal of empresa.Sucursals" [value]="sucursal.id">
                                            {{ sucursal.razonSocial }}
                                        </mat-option>
                                    </mat-select>
                  <mat-icon matSuffix>home</mat-icon>

                                    <mat-error *ngIf="
                      addressForm.controls['idSucursal'].hasError('required')
                    ">
                                        sucursal es <strong>requrido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div> -->
                        </div>
                        <div class="row">
                            <!-- tipo cliente -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Tipo Cliente" formControlName="tipoCliente">
                                        <mat-option *ngFor="let tipo of tiposCliente" [value]="tipo">
                                            {{ tipo }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>assignment_ind</mat-icon>
                                    <mat-error *ngIf="
                        addressForm.controls['tipoCliente'].hasError('required')
                      ">
                                        Tipo_Cliente es <strong>requrido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- correo -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input matInput placeholder="ingrese correo" formControlName="correo" />
                                    <mat-error *ngIf="addressForm.controls['correo'].hasError('required')">
                                        cliente es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- movil -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input matInput placeholder="ingrese movil" formControlName="movil" />
                                    <mat-error *ngIf="addressForm.controls['movil'].hasError('required')">
                                        cliente es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <!-- tipo pago -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Tipo Pago" formControlName="tipoPago">
                                        <mat-option *ngFor="let tipoPago of tiposPagos" [value]="tipoPago">
                                            {{ tipoPago }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>payment</mat-icon>
                                    <mat-error *ngIf="
                        addressForm.controls['tipoPago'].hasError('required')
                      ">
                                        este campo es <strong>requrido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!-- monto -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Monto" 
                                    formControlName="monto"  />
                                    <mat-error *ngIf="addressForm.controls['monto'].hasError('required')">
                                        Monto es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!-- nAutorizacion -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Código Autorización" formControlName="nAutorizacion"
                                        type="text" />
                                    <mat-icon matSuffix>payment</mat-icon>
                                    <mat-error *ngIf="addressForm.controls['nAutorizacion'].hasError('required')">
                                        Monto es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Tipo documento -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Tipo Documento" formControlName="tipoDocumento">
                                        <mat-option *ngFor="let tipoDocumento of tiposDocumentos"
                                            [value]="tipoDocumento">
                                            {{ tipoDocumento }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>import_contacts</mat-icon>
                                    <mat-error *ngIf="
                          addressForm.controls['tipoDocumento'].hasError('required')
                        ">
                                        este campo es <strong>requrido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- numero documento -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input matInput placeholder="N° documento" formControlName="nDocumento" />
                                    <mat-error *ngIf="
                        addressForm.controls['nDocumento'].hasError('required')
                      ">
                                        este campo es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- descripcion -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input matInput placeholder="ingrese una descripcion del registro"
                                        formControlName="descripcionIngreso" />
                                    <mat-error *ngIf="
                        addressForm.controls['descripcionIngreso'].hasError('required')
                      ">
                                        descripcion es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <br class="separador" />
                    <!-- Subir imagen -->
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Información Imagen</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col">
                                <!-- Capturar img -->
                                <div>
                                    <input mat-stroked-button (change)="capturarFile($event)" type="file" />
                                </div>
                                <br />
                                <div>
                                    <button mat-stroked-button color="primary" [disabled]="loading" (click)="subirIMG(archivos)"
                                        (click)="clickButton = false">
                                        {{ loading ? 'Cargando....' : 'Subir IMG' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <br />
                        <h3>Imagen Previsualizacion</h3>
                        <hr />
                        <div class="row">
                            <!-- Preview img -->
                            <div class="col" *ngIf="previsualizacion">
                                <img width="400px" [src]="previsualizacion" alt="img" />
                            </div>
                        </div>
                        <hr />
                    </mat-expansion-panel>
                    <br class="separador" />

                    <!-- SUBIR CONTRATO -->
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Subir contrato</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col">
                                <!-- Capturar img -->
                                <div>
                                    <input mat-stroked-button (change)="capturarFile2($event)" type="file" />
                                </div>
                                <br />
                                <div>
                                  <button mat-stroked-button color="primary" [disabled]="loading" (click)="subirIMG(archivos2)"
                                      (click)="clickButton = false">
                                      {{ loading ? 'Cargando....' : 'Subir IMG' }}
                                  </button>
                              </div>
                            </div>
                        </div>
                        <br />
                        <h3>Imagen Previsualizacion</h3>
                        <hr />
                        <div class="row">
                            <!-- Preview img -->
                            <div class="col" *ngIf="previsualizacionContrato">
                                <img width="400px" [src]="previsualizacionContrato" alt="img" />
                            </div>
                        </div>
                        <hr />
                    </mat-expansion-panel>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary" type="submit">
                        <mat-icon>send</mat-icon>
                        Guardar
                    </button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab>
</mat-tab-group>