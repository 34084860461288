<mat-toolbar color="primary">
    <button style="margin-right: 9rem" mat-raised-button (click)="limpiarFiltros()" color="accent">
        Limpiar filtros
      </button>

    <span style="margin-right: 9rem; margin-left: 9rem"> Listado Categorias </span>
    <!-- ***************************** cambiar metodo botón ********************************** -->
    <button mat-raised-button color="accent" style="margin-left: 8rem" (click)="exportAsXLSX()">
        Exportar
    </button>
    <button mat-raised-button color="accent" style="margin-left: 1rem" onclick="location.reload()">
            <mat-icon>autorenew</mat-icon>
    </button>
</mat-toolbar>

<div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">

         <!-- check column -->

         <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        
        <!-- data columns -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID </th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Nombres -->
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input  matInput placeholder="Nombre" formControlName="nombre" />
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.nombre }}</td>
        </ng-container>

        <!-- Estado -->
        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef>
                <div [formGroup]="formFilter">
                    <mat-form-field class="fill">
                        <input matInput placeholder="Estado" formControlName="estado" />
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.estado }}</td>
        </ng-container>

        <!-- Botones -->
        <ng-container matColumnDef="botones">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button routerLink="/despacho/despacho/form" color="accent" style="margin-left: 1rem">
                    <mat-icon>mode_edit</mat-icon> Ruta
                </button>
                <button mat-raised-button color="accent" style="margin-left: 1rem"  (click)="openDialogEdit(element.id, element.nombre)">
                        <mat-icon>mode_edit</mat-icon> Editar
                </button>
                <button mat-raised-button color="accent" style="margin-left: 1rem" (click)="borrarCategoria(element.id, element.nombre)">
                    <mat-icon>delete icon</mat-icon> Eliminar
            </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No existen coincidencias</td>
        </tr>
    </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>