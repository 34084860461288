import { Component, Input, OnInit } from '@angular/core';
import { Empresa } from '@app/_models/empresas/empresas-simpleapi.interface';
import { EmpresasSimpleapiService } from '@app/_pages/shared/shared-services/empresas-simpleapi.service';
import { FolioService } from '../folio.service';

@Component({
  selector: 'app-folio-restantes',
  templateUrl: './folio-restantes.component.html',
  styleUrls: ['./folio-restantes.component.scss']
})
export class FolioRestantesComponent implements OnInit {
  @Input('tipoDTE') tipoDTE: string;
  empresas:Empresa[];
  empresasFolios: any[];
  nombresTiposDTE: any;
  nombreDTE: string;
  showFullContent: boolean;

  constructor(
    private folioService: FolioService,
    private empresaService: EmpresasSimpleapiService
  ) {
    this.showFullContent = false;
    this.empresasFolios = [];
    this.nombresTiposDTE = {
      '33': 'Factura electrónica',
      '34': 'Factura no afecta',
      '39': 'Boleta electrónica',
      '46': 'Factura de compra electrónica',
      '52': 'Guía de despacho electrónica',
      '56': 'Nota de débito electrónica',
      '61': 'Nota de crédito electrónica',
    }
  }

  async ngOnInit() {
    this.getEmpresas();
    await this.getFoliosEmpresas();
    this.nombreDTE = this.obtenerTipoDTE(this.tipoDTE);
  }
  obtenerTipoDTE(tipoDTE: string) {
    return this.nombresTiposDTE[tipoDTE];
  }
  getEmpresas() {
    this.empresas = this.empresaService.getEmpresas();
  }

  async getFoliosEmpresas() {
    for (const empresa of this.empresas) {
      const folio = await this.folioService.obtenerFoliosRestantes(this.tipoDTE, empresa.rut);
      const color = folio > 5 ? 'primary' 
        : folio <= 5 && folio > 0 ? 'accent' 
        : folio <= 0 || folio === null || folio === undefined ? 'warn' 
        : 'primary'
      this.empresasFolios.push({nombre: empresa.nombre, folioRestante: folio, color: color});
    }
    this.empresasFolios = this.empresasFolios.sort((a:any, b:any) => a.folioRestante??0 - b.folioRestante??0);
    console.log(this.empresasFolios);
  }
}