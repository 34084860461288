<button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
  <mat-icon>highlight_off</mat-icon>
</button>
<mat-tab-group>
  <mat-tab label="Folios">
    <form [formGroup]="addressForm" novalidate class="mat-elevation-z10">
      <mat-card class="shipping-card">
        <mat-card-header>
        </mat-card-header>
        <mat-card-content>
          <!-- Información archivo caf -->
            <div class="row">
                <!-- tipoDocumento  -->
                <div class="col">
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Documento"
                        formControlName="tipoDocumento"
                        readonly="»readonly»"
                      />
                    </mat-form-field>
                </div>
            </div>
              
            <div class="row">
              <div class="col">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Folios disponibles"
                        formControlName="totalDisponible"
                        readonly="»readonly»"
                      />
                  </mat-form-field>
              </div>
          
              <!-- alerta -->
              <div class="col">
                <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Alerta"
                      formControlName="alerta"
                      readonly="»readonly»"
                    />
                </mat-form-field>
              </div>
          
              <!-- codigo -->
              <div class="col">
                <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Código"
                      formControlName="codigo"
                      readonly="»readonly»"
                    />
                </mat-form-field>
              </div>
          </div>
          <!-- Fila 1 -->
          <div class="row">
            <!-- tipoDocumento -->
            <div class="col">
              <h4>Archivos CAF  {{ dataSource[0]?.DteTipo.nombre }} </h4>
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
               <!-- Name Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="folioSiguiente">
                  <th mat-header-cell *matHeaderCellDef> Siguiente Folio</th>
                  <td mat-cell *matCellDef="let element"> {{element.folioSiguiente}} </td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="primerFolio">
                  <th mat-header-cell *matHeaderCellDef> Primer Folio </th>
                  <td mat-cell *matCellDef="let element"> {{element.primerFolio}} </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="ultimoFolio">
                  <th mat-header-cell *matHeaderCellDef> Último Folio </th>
                  <td mat-cell *matCellDef="let element"> {{element.ultimoFolio}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="fechaAutorizacion">
                    <th mat-header-cell *matHeaderCellDef> Fecha autorización </th>
                    <td mat-cell *matCellDef="let element"> {{element.fechaAutorizacion | date  }} </td>
                </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="fechaVencimiento">
                    <th mat-header-cell *matHeaderCellDef> Fecha vencimiento </th>
                    <td mat-cell *matCellDef="let element"> {{element.fechaVencimiento}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="foliosDisponibles">
                      <th mat-header-cell *matHeaderCellDef> Folios disponibles </th>
                      <td mat-cell *matCellDef="let element"> {{element.foliosDisponibles}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef> Estado </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div *ngIf="element.estado == true; else elseBlock"><mat-icon>check_circle</mat-icon></div>
                        <ng-template #elseBlock></ng-template>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
