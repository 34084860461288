import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Caf, Dte} from '@app/_models/';
import { modeloFolio } from '@app/_models/folio/folio';
import { environment } from '@environments/environment';
import {  Observable, Subject } from 'rxjs';
import { FolioDisponibleComponent } from './folio-home/folio-table/folio-disponible/folio-disponible.component';

@Injectable({
  providedIn: 'root'
})
export class FolioService {
  private folio_response$ = new Subject<any>();
  constructor(private http: HttpClient, private dialog: MatDialog) {}
  addResultFolio(termino: any){
    this.folio_response$.next(termino)
  }
  getResultFolio() : Observable<any>{
    return this.folio_response$.asObservable()
  }
  //Registar Folio
  registrarFolio(modelo: modeloFolio): any {
    console.log("folios: ", modelo);
    return this.http.post(`${environment.apiUrl}/folios`, modelo);
  }
  // Actualizar folio
  async actualizarFolio(id: number, folio: any){
    return await this.http.put(`${environment.apiUrl}/folios/update/${id}`, folio).toPromise();
  }

  async incrementarFolio(tipoDTE: number){
    return await this.http.post(`${environment.apiUrl}/folios/incrementarFolio`, {tipoDTE}).toPromise();
  }
  //Registar Folio
  solicitarCaf(Caf: any, empresaSeleccionada: any): any {
    try {
      const body = { Caf, empresaSeleccionada}
      return this.http.post(`${environment.apiUrl}/folios/caf`, body)
    } catch (error) {
        console.log(error)
    }
  }
  //Registar Folio
  sendCafFile(Caf: FormData): any {
      try {
        return this.http.post(`${environment.apiUrl}/folios/caf/registrar`, Caf)
      } catch (error) {
          console.log(error)
      }
  }
  //Llamar tabla de folio
  getAllFolios(): any {
    return this.http.get<[]>(`${environment.apiUrl}/folios`)
  }
  getDatosXml(nombre:string, alerta:any): any {
    return this.http.get<[]>(`${environment.apiUrl}/folios/grabarxml/${nombre}/${alerta}`)
  }
  //Llamar tabla de dtes
  getAllDtes() {
    return this.http.get<Dte[]>(`${environment.apiUrl}/folios/dtes`)
  }
  //folio Disponibles actualizar
  folioDisponibles(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/folios/${id}`,
      params
    )
  }
  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditFolio(): void {
    const dialogRef = this.dialog.open(FolioDisponibleComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  getById(id: string): any {
    return this.http.get<any>(`${environment.apiUrl}/folios/${id}`)
  }

  getByCodigoCafs(codigo: string): any {
    return this.http.get<any>(`${environment.apiUrl}/folios/documentos/${codigo}`)
  }

  //servicio para llamar el utlimo folio
  getByFolioFactura(codigo: string, rutEmisor: string): any {
    return this.http.get<any>(`${environment.apiUrl}/folios/facturaSiguienteFolio/${rutEmisor}/${codigo}`)
  }
  //servicio para llamar el utlimo folio
  getByFolioNotaDebito(codigo: string): any {
    return this.http.get<any>(`${environment.apiUrl}/folios/NotaDebitoSiguienteFolio/${codigo}`)
  }

  verificarFoliosDisponibles(tipoDTE: number){
    return this.http.get<any>(`${environment.apiUrl}/folios/${tipoDTE}`)
  }

  getFolioRestantesEmpresas(tipoDTE: string) {
    return this.http.get<any>(`${environment.apiUrl}/folios/getFoliosEmpresas/${tipoDTE}`)
  }

  async obtenerFoliosRestantes(tipoDTE: string, rutEmpresa: string): Promise<number> {
    const folios = await this.getFolioRestantesEmpresas(tipoDTE).toPromise();
    if (folios?.length > 0) {
      const folio = folios.find(x => x.rutEmisor === rutEmpresa);
      return folio?.foliosFaltantes;
    }
    return null;
  }


  closeDialogModal(){
    this.dialog.closeAll();
    localStorage.removeItem("idFolio");
  }
  delete( id: number, nombre: string ) {
    return this.http.delete(`${environment.apiUrl}/folios/${id}/${nombre}`);
  }
}
