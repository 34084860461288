<mat-tab-group mat-align-tabs="center">
    <!-- <mat-tab label="Actualizar IMG 📃 ">
      <form [formGroup]="addressForm" novalidate (ngSubmit)="AgregarIMG()">
        <mat-card class="shipping-card"> -->
          <!-- Informacion IMG -->
          <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion IMG </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                 Capturar img
             <div>
                  <input mat-stroked-button (change)="capturarFile($event)" type="file" />
                </div> 
                <br />
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
               Preview img
              <div class="col" *ngIf="previsualizacion">
                <img width="300px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel> -->

          <mat-card-actions>
            <button mat-stroked-button color="primary" type="submit" [disabled]="loading" (click)="subirIMG()"
            [disabled]="clickButton" (click)="AgregarIMG()">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
          </mat-card-actions>
        <!-- </mat-card>
      </form>
    </mat-tab> -->
    <mat-tab label="Comprobante 📃 ">
      <form [formGroup]="addressForm" novalidate>
        <mat-card class="shipping-card">
          <mat-card-content>
            <mat-card>
              <h3>Imagen Previsualizacion</h3>
              <hr />
              <div class="row">
                <!-- Preview img -->
                <div class="col" *ngIf="previsualizacion">
                  <img width="400px" [src]="previsualizacion" alt="img" />
                </div>
              </div>
            </mat-card>
          </mat-card-content>
          <mat-card-actions>
            <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
              (click)="closeDialog()">
              <mat-icon>cancel_white_18dp</mat-icon>Cancelar
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-tab>
    <mat-tab label="Editar 📝">
      <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
        <mat-icon>highlight_off</mat-icon>
      </button>
      <form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
        <mat-card class="shipping-card">
          <mat-card-header>
            <h1>Editar Cliente</h1>
          </mat-card-header>
          <mat-card-content>
            <!-- Informacion Basica -->
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>Informacion Basica </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- informacion fila uno -->
              <div class="row">
                <!-- Sucursal -->
                <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="descripcionIngreso" formControlName="descripcionIngreso" required />

                    <mat-error *ngIf="addressForm.controls['descripcionIngreso'].hasError('required')">
                      Este campo es <strong>requerido.</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Fecha"
                    [matDatepicker]="picker"
                    (click)="picker.open()"
                    required
                    formControlName="fecha"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error>
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>

                <div class="col">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="monto" formControlName="monto" required />

                      <mat-error *ngIf="
                          addressForm.controls['monto'].hasError('required')
                        ">
                        Este campo es <strong>requerido.</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>

              </div>

              <div class="row">

                <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="cliente" formControlName="cliente" required />

                    <mat-error *ngIf="addressForm.controls['cliente'].hasError('required')">
                      Este campo es <strong>requerido.</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <!-- <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="telefono" formControlName="telefono" required />

                    <mat-error *ngIf="
                        addressForm.controls['telefono'].hasError('required')
                      ">
                      Este campo es <strong>requerido.</strong>
                    </mat-error>
                  </mat-form-field>
                </div> -->

                <!-- <div class="col">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="correo" formControlName="correo" required />

                      <mat-error *ngIf="
                          addressForm.controls['correo'].hasError('required')
                        ">
                        Este campo es <strong>requerido.</strong>
                      </mat-error>
                    </mat-form-field>
                  </div> -->

              </div>

              <div class="row">

               <!-- tipo cliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Cliente"
                    formControlName="tipoCliente"
                  >
                    <mat-option
                      *ngFor="let tipo of tiposClientes"
                      [value]="tipo"
                    >
                      {{ tipo }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoCliente'].hasError('required')
                    "
                  >
                    Tipo_Cliente es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

               <!-- referencia -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Referencia"
                    formControlName="referenciaCliente"
                  >
                    <mat-option
                      *ngFor="let referencia of referencias"
                      [value]="referencia"
                    >
                      {{ referencia }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['referenciaCliente'].hasError('required')
                    "
                  >
                    referencia es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

               <!-- tipo pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Pago"
                    formControlName="tipoPago"
                  >
                    <mat-option
                      *ngFor="let tipoPago of tiposPagos"
                      [value]="tipoPago"
                    >
                      {{ tipoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    "
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Documento"
                    formControlName="tipoDocumento"
                  >
                    <mat-option *ngFor="let td of tipoDocumento" [value]="td" > {{td}}</mat-option>
                   
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoDocumento'].hasError('required')
                    "
                  >
                    este campo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

                <!-- <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="estadoPago" formControlName="estadoPago" required />

                    <mat-error *ngIf="addressForm.controls['estadoPago'].hasError('required')">
                      Este campo es <strong>requerido.</strong>
                    </mat-error>
                  </mat-form-field>
                </div> -->


              </div>

              <div class="row">



                <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="nDocumento" formControlName="nDocumento" required />

                    <mat-error *ngIf="
                        addressForm.controls['nDocumento'].hasError('required')
                      ">
                      Este campo es <strong>requerido.</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="nAutorizacion" formControlName="nAutorizacion" required />

                      <mat-error *ngIf="
                          addressForm.controls['nAutorizacion'].hasError('required')
                        ">
                        Este campo es <strong>requerido.</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- <div class="col">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="tipoVehiculo" formControlName="tipoVehiculo" required />
                      <mat-error *ngIf="addressForm.controls['tipoVehiculo'].hasError('required')">
                        Este campo es <strong>requerido.</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>

              </div>

              <div class="row">


                <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="ppu" formControlName="ppu" required />

                    <mat-error *ngIf="
                        addressForm.controls['ppu'].hasError('required')
                      ">
                      Este campo es <strong>requerido.</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="marca" formControlName="marca" required />

                      <mat-error *ngIf="
                          addressForm.controls['marca'].hasError('required')
                        ">
                        Este campo es <strong>requerido.</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="modelo" formControlName="modelo" required />
                      <mat-error *ngIf="addressForm.controls['modelo'].hasError('required')">
                        Este campo es <strong>requerido.</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>

              </div>

              <div class="row">



                <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="anio" formControlName="anio" required />

                    <mat-error *ngIf="
                        addressForm.controls['anio'].hasError('required')
                      ">
                      Este campo es <strong>requerido.</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="kmActual" formControlName="kmActual" required />
                      <mat-error *ngIf="
                          addressForm.controls['kmActual'].hasError('required')
                        ">
                        Este campo es <strong>requerido.</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="kmProximo" formControlName="kmProximo" required />
                      <mat-error *ngIf="addressForm.controls['kmProximo'].hasError('required')">
                        Este campo es <strong>requerido.</strong>
                      </mat-error>
                    </mat-form-field>
                  </div> -->

              </div>

            </mat-expansion-panel>
            <!-- Informacion IMG -->
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>Informacion IMG </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col">
                  <!-- Capturar img -->
                  <div>
                    <input mat-stroked-button (change)="capturarFile($event)" type="file" />
                  </div>
                  <br />
                  <div>
                    <!-- <button mat-stroked-button color="primary" [disabled]="loading" (click)="subirIMG()"
                      (click)="clickButton = false">
                      {{ loading ? 'Cargando....' : 'Subir IMG' }}
                    </button> -->
                  </div>
                </div>
              </div>
              <br />
              <h3>Imagen Previsualizacion</h3>
              <hr />
              <div class="row">
                <!-- Preview img -->
                <div class="col" *ngIf="previsualizacion">
                  <img width="300px" [src]="previsualizacion" alt="img" />
                </div>
              </div>
              <hr />
            </mat-expansion-panel>
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="primary" type="submit">
              <mat-icon>send</mat-icon>
              Guardar
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-tab>
  </mat-tab-group>
