import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { Sucursal } from '@app/_models/shared/sucursal'
import { ImportadoraService } from '@app/_pages/importadora/importadora.service'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-importadora-egresos-edit',
  templateUrl: './importadora-egresos-edit.component.html',
  styleUrls: ['./importadora-egresos-edit.component.scss']
})
export class ImportadoraEgresosEditComponent implements OnInit {
  idImportadora: any
  fecha: any
  monto: any
  responsable: any
  tipoEgreso: any

  /**  Configuración de formulario  */
  addressForm = this.fb.group({
    fecha: ['', Validators.required],
    monto: ['', Validators.required],
    responsable: ['', Validators.required],
    tipoEgreso: ['', Validators.required],
    tipoPago: ['', Validators.required]
  })

  sucursales: Sucursal[]
  tiposEgresos: string[] = []
  tiposPagos: any[] = []

  constructor(
    private fb: FormBuilder,
    private sucursalService: SucursalSharedService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private administracionService: AdministracionService,
    private importadoraService: ImportadoraService
  ) {
    this.sucursales = this.sucursalService.sucursalListValue
    this.tiposPagos = [
      'Efectivo',
      'Debito',
      'Credito',
      'Transferencia',
      'Cheque'
    ]
    this.tiposEgresos = [
      'Gastos',
      'Costos',
      'Remuneraciones',
      'Impuestos',
      'Bancarios'
    ]
  }

  ngOnInit(): void {
    this.idImportadora = localStorage.getItem('idEgresoImportadora')
    this.getEgresoImportadora()
  }
  getEgresoImportadora() {
    this.importadoraService
      .getByEgreso(this.idImportadora)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idImportadora) {
          this.f.fecha.setValue(x[0].fecha)
          this.f.monto.setValue(x[0].monto)
          this.f.responsable.setValue(x[0].responsable)
          this.f.tipoEgreso.setValue(x[0].tipoEgreso)
          this.f.tipoPago.setValue(x[0].tipoPago)
        }
      })
  }
  get f() {
    return this.addressForm.controls
  }
  onSubmit() {
    switch (this.addressForm.status) {
      case 'VALID':
        this.importadoraService
          .updateImportadoraEgreso(this.idImportadora, this.addressForm.value)
          .pipe(first())
          .subscribe(
            (data) => {
              this.snackBar.open('Lubricentro editada con éxito.', 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })

              this.addressForm.reset()
              this.importadoraService.closeDialogModal()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo editar la lubricentro, favor contactar a informática.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
            }
          )
        break

      case 'INVALID':
        this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }
  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
  }
}
