<!-- Barra -->
<mat-toolbar color="primary">
  <button
    style="margin-right: 5rem"
    mat-raised-button
    (click)="limpiarFiltros()"
    color="accent"
  >
    Actualizar Tabla!
  </button>
  <span style="margin-right: 5rem">Egresos Hostal</span>
  <span style="margin-right: 3rem"
    >Monto Registros Seleccionados: $
    {{ totalSeleccion }}
  </span>
  <button
    mat-raised-button
    color="accent"
    style="margin-left: 1rem"
    (click)="revelarTotal()"
  >
    Revelar total
  </button>
  <button
    mat-raised-button
    color="accent"
    style="margin-left: 1rem"
    (click)="exportAsXLSX()"
  >
    Exportar
  </button>
</mat-toolbar>
<!-- Tabla -->
<div class="table-responsive">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="id"
    matSortDirection="desc"
    class="mat-elevation-z10"
  >
    <!-- check column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- ID with checkbox -->

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill limit-width-40">
            <input matInput placeholder="ID" formControlName="id" (click)="$event.stopPropagation()"/>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <!-- date with datapicker -->
    <ng-container matColumnDef="fecha" class="mat-column-date">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="limit-width-180"
      >
        <mat-form-field appearance="fill" class="limit-width-180">
          <mat-label>Fecha</mat-label>
          <mat-date-range-input [formGroup]="formFilter" [rangePicker]="picker">
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
              (click)="$event.stopPropagation()"
            />
            <input matEndDate formControlName="end" placeholder="End date" (click)="$event.stopPropagation()"  />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            (click)="$event.stopPropagation()"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error
            *ngIf="formFilter.controls.start.hasError('matStartDateInvalid')"
            >Invalid start date
          </mat-error>
          <mat-error
            *ngIf="formFilter.controls.end.hasError('matEndDateInvalid')"
            >Invalid end date
          </mat-error>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.fecha | date : 'dd/MM/yyyy' }}
      </td>
    </ng-container>

    <!-- sucursal with text input -->
    <ng-container matColumnDef="sucursal">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width" style="margin-left: 10px">
            <mat-select placeholder="Sucursal" formControlName="idSucursal">
              <mat-option>Todo</mat-option>
              <mat-option
                *ngFor="let sucursal of sucursales"
                [value]="sucursal.id"
              >
              {{ sucursal.razonSocial }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.Sucursal?.razonSocial }}</td>
    </ng-container>
    
    <!-- Campo Numero de cuotas -->
    <ng-container matColumnDef="numeroCuota">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº de cuotas
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.numeroCuota }}
      </td>
    </ng-container>


    <!-- Responsable -->
    <ng-container matColumnDef="responsable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <input
              (keydown)="$event.stopPropagation()"
              matInput
              placeholder="Responsable"
              formControlName="responsable"
              (click)="$event.stopPropagation()"
            />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.responsable }}
      </td>
    </ng-container>

   <!-- descripcion -->
   <ng-container matColumnDef="descripcion">
    <th mat-header-cell *matHeaderCellDef> 
      <div [formGroup]="formFilter">
        <mat-form-field class="fill limit-width-80">
          <input matInput placeholder="Descripcion" formControlName="descripcion" (click)="$event.stopPropagation()"/>
        </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">{{ element.descripcion }}</td>
  </ng-container> 

    <!-- tipo egreso with select -->
    <!-- monto currency fomrat -->

    <ng-container matColumnDef="monto">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div [formGroup]="formFilter">
          <mat-form-field class="fill limit-width-80">
            <input matInput placeholder="Monto" formControlName="monto" (click)="$event.stopPropagation()"/>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <span matPrefix>$&nbsp;</span> {{ element.monto.toLocaleString('es-CL') }}
        <!--   {{ element.monto | currency : '$' : 'symbol' : '1.0-0' }} -->
      </td>
    </ng-container>
    <!-- TODO repair source ts -->
    <ng-container matColumnDef="tipoEgreso">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <mat-select placeholder="Tipo Egreso" formControlName="tipoEgreso">
              <mat-option>Todo</mat-option>
              <mat-option *ngFor="let tipo of tiposEgresos" [value]="tipo">
                {{ tipo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.tipoEgreso }}</td>
    </ng-container>

    <!-- TODO repair source ts -->
    <ng-container matColumnDef="tipoPago">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width" style="margin-right: 10px">
            <mat-select placeholder="Tipo de Pago" formControlName="tipoPago">
              <mat-option >Todo</mat-option>
              <mat-option *ngFor="let tipo of tiposPagos" [value]="tipo">
                {{ tipo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.tipoPago }}</td>
    </ng-container>

    <!-- ID USUARIO -->
    <ng-container matColumnDef="gastosFijos">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="ID Usuario"
              formControlName="idUsuario"
              (click)="$event.stopPropagation()"
            />
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.idUsuario }}</td>
    </ng-container>

    <!-- regiones -->
    <ng-container matColumnDef="regiones">
      <th mat-header-cell *matHeaderCellDef>
        <div [formGroup]="formFilter">
          <mat-form-field class="full-width" style="margin-right: 10px">
            <mat-select
              placeholder="Región"
              formControlName="regiones"
            >
              <mat-option *ngFor="let region of regiones" [value]="region">
                {{ region }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.region }}</td>
    </ng-container>

  <!-- proyectos -->
  <ng-container matColumnDef="proyecto">
    <th mat-header-cell *matHeaderCellDef> 
      <div [formGroup]="formFilter">
        <mat-form-field class="fill limit-width-80">
          <input matInput placeholder="Proyectos" formControlName="proyecto" (click)="$event.stopPropagation()"/>
        </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">{{ element.proyecto }}</td>
  </ng-container> 
<!-- Grupo de envio de gastos -->
  <ng-container matColumnDef="grupoGastos">
    <th mat-header-cell *matHeaderCellDef>
      <div [formGroup]="formFilter">
        <mat-form-field class="full-width">
          <mat-select placeholder="Grupo de envio de gastos" formControlName="grupoGastos">
            <mat-option
            *ngFor="let g of grupos"
            [value]="g"
          >
            {{ g }}
          </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">{{ element.grupoGastos}}</td>
  </ng-container> 

    <!-- Botones de acciones -->
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef>
        Acciones
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-mini-fab
          color="primary"
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="accionIngresoInmobiliaria(element.id)"
        >
          <mat-icon>save_as_white_18dp</mat-icon>
        </button>
        <!-- Dowload -->
        <button
          mat-mini-fab
          color="accent"
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="downloadImage(element.id)"
        >
          <mat-icon>file_download</mat-icon>
        </button>
        <button
          mat-mini-fab
          color=""
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="deleteEgreso(element.id)"
          [disabled]="rolUsuario !== 'Administrador'"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No existen coincidencias</td>
    </tr>
  </table>
</div>
<!-- Paginator -->
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>
