import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StockActualComponent } from "../stockActual/stockActual-home/stockActual.component";
import { BodegaHomeComponent } from "./bodega/bodega-home/bodega-home.component";
import { TransferenciaBodegaHomeComponent } from "./transferencia/transferencia-bodega-home/transferencia-bodega-home.component";

const routes: Routes = [
  { path: 'bodega', component : BodegaHomeComponent },
  { path: 'transferencia-bodega', component : TransferenciaBodegaHomeComponent },
  { path: 'stock', component: StockActualComponent }
];

@NgModule({
    imports: [RouterModule.forChild( routes )],
    exports: [RouterModule]
  })

export class InventarioRoutingModule{}

