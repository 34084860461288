
import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@app/material.module";
import { ComponentsModule } from "@app/_components/components.module";
import { BodegaFormComponent } from "./bodega/bodega-form/bodega-form.component";
import { BodegaListComponent } from "./bodega/bodega-list/bodega-list.component";
import { DialogBodegaEditarComponent } from "./bodega/bodega-list/dialog-bodega-editar/dialog-bodega-editar.component";
import { DialogTransferenciaEditarComponent } from "./transferencia/transferencia-bodega-list/dialog-transferencia-editar/dialog-transferencia-editar.component";
import { DialogGuiaDespachoComponent } from "./transferencia/transferencia-bodega-list/dialog-guia-despacho/dialog-guia-despacho.component";
import { BodegaHomeComponent } from "./bodega/bodega-home/bodega-home.component";
import { BodegaService } from "./bodega/bodega.service";
import { InventarioRoutingModule } from "./inventario.routing.module";
import { TransferenciaBodegaFormComponent } from "./transferencia/transferencia-bodega-form/transferencia-bodega-form.component";
import { TransferenciaBodegaListComponent } from "./transferencia/transferencia-bodega-list/transferencia-bodega-list.component";
import { TransferenciaBodegaHomeComponent } from "./transferencia/transferencia-bodega-home/transferencia-bodega-home.component";
import { MultiselectAutocompleteComponent } from "./transferencia/multiselect-autocomplete/multiselect-autocomplete.component";
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations: [
      BodegaFormComponent,
      BodegaListComponent,
      BodegaHomeComponent,
      DialogBodegaEditarComponent,
      TransferenciaBodegaFormComponent,
      TransferenciaBodegaListComponent,
      TransferenciaBodegaHomeComponent,
      MultiselectAutocompleteComponent,
      DialogTransferenciaEditarComponent,
      DialogGuiaDespachoComponent
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      MaterialModule,
      ComponentsModule,
      InventarioRoutingModule,
      NgSelectModule
    ],
    providers: [
      BodegaService,
      DatePipe
    ]
  })
export class InventarioModule {}
