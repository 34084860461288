<button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
    <mat-icon>highlight_off</mat-icon>
</button>
<form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
    <mat-card class="shipping-card">
        <mat-card-header>
            <!-- <h1>{{ razonSocial }}</h1> -->
        </mat-card-header>
        <br><br>
        <mat-card-content>

            <div class="row">
                <!-- Nombre -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Nombre" formControlName="nombre" />
                        <mat-error *ngIf="addressForm.controls['nombre'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- RUT  -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="RUT Cesionario" formControlName="rut" />
                        <mat-error *ngIf="addressForm.controls['rut'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <!-- Dirección -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Dirección" formControlName="direccion" />
                        <mat-error *ngIf="addressForm.controls['direccion'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- contacto -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Contacto" formControlName="contacto" />
                        <mat-error *ngIf="addressForm.controls['contacto'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            

            <div class="row">
                <!-- Email -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Email" formControlName="email" />
                        <mat-error *ngIf="addressForm.controls['email'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="addressForm.controls['email'].hasError('pattern')">
                            El <strong>email es inválido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Teléfono -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Teléfono" formControlName="telefono" />
                        <mat-error *ngIf="addressForm.controls['telefono'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <!-- Estados -->
            <div class="row">
                <div class="col">
                    <section class="example-section col-estado-bodega" >
                        <h4>Vigencia</h4>
                        <mat-radio-group formControlName="estado">
                            <mat-radio-button value="1">Vigente</mat-radio-button>
                            <mat-radio-button value="0">No Vigente</mat-radio-button>
                        </mat-radio-group>
                        <!-- {{ addressForm.value | json }} -->
                    </section>
                </div>
            </div>
        </mat-card-content>

        <!-- botón enviar -->
        <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon> Enviar
        </button>
    </mat-card>
</form>