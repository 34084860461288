import { EgresosFijoImportadora } from './../../../../../_models/importadora/egresoFijoImportadora'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import { Component, HostListener, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { modeloSolicitudBancaria } from '@app/_models/consolidadoBancario/solicitudBancaria'
import { EgresosRentacar } from '@app/_models/rentacar/egresoRentacar'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { ConsolidadoBancarioService } from '@app/_pages/consolidado-bancario/consolidado-bancario.service'
import { ImportadoraService } from '@app/_pages/importadora/importadora.service'
import { RentacarService } from '@app/_pages/rentacar/rentacar.service'
import { first } from 'rxjs/operators'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { EgresoLubricentro } from '../../../../../_models/lubricentro/egresoLubricentro'
import { LubricentroService } from '@app/_pages/lubricentro/lubricentro.service'
import { RegistroEgresoFirma } from '../../../../../_models/abogados/egresosFirma'
import { EgresoHostal } from '@app/_models/hostal/egresoHostal'
import { AbogadosService } from '@app/_pages/abogados/abogados.service'
import { HostalService } from '../../../../hostal/hostal.service'
import { EgresosInmobiliaria } from '@app/_models/inmobiliaria/egresoInmobiliaria'
import { InmobiliariaService } from '@app/_pages/inmobiliaria/inmobiliaria.service'
import { Sucursal } from '@app/_models/shared/sucursal'
import { AlertHelper } from '@app/_helpers/alert.helper'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { EgresoPersonal } from '@app/_models/gastoPersonal/egresoGastoPersonal'
import { GastoService } from '@app/_pages/gasto-personal/gasto.service'
import { ConsolidadoBancarioSharedService } from '../../../consolidado-bancario-shared.service'
import { Usuario } from '@app/_models/shared/usuario'
import { environment } from '@environments/environment'
import { pipe } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { of } from 'rxjs'

//prueba
import { FormGroup } from '@angular/forms'
@Component({
  selector: 'app-pago-acciones',
  templateUrl: './pago-acciones.component.html',
  styleUrls: ['./pago-acciones.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class PagoAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  private servicioCompartidoConsolidado: ConsolidadoBancarioSharedService

  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  nombreUsuario = this.usuario.nombre
  apellidoUsuario = this.usuario.apellido
  nombreCompleto = this.usuario.nombre + ' ' + this.usuario.apellido
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  showAlert: boolean = true
  descomponerArray: any
  UpdateCuentaBancaria = []
  rolUsuario: string
  confirmadoPorFinanzas: string

  dataArrayImg = []
  dataArrayDevuelto = []
  public archivos: any = []
  public archivoDevuelto: any[]
  public previsualizacion: string
  public previsualizacionDevuelto: string
  public loading: boolean
  addressFormPrueba: FormGroup

  dataArrayFactura = []
  public fotoFactura: any = []
  estadoFactura: any
  public previsualizacion2: string
  public loading2: boolean
  public fechaFormateada: any
  public horaPagado: any

  //data
  idPagoBancario: any
  NumeroCuenta: any
  nombreResponsable: any
  rut: any
  jefeAutoriza: any
  fechaSolicitud: any
  estado: any
  montoPago: any
  NumeroCuentaAsume: any
  inversion: any
  motivoPago: any
  correo: any
  idSucursal: any
  idUsuario: any
  razonRechazo: any
  nombreTransferencia: any
  bancoCliente: any
  est: any
  horaEstado: Date
  horaSolicitud: Date
  fechassss: string
  RutEmpresa: any
  estadoDevuelto: string
  sucursales: Sucursal[]
  sucursal: any
  asumeFactura: any
  link: any
  linkValue: any
  tipoCuenta: any
  Empresa: string
  prevw: boolean = true
  PrevwI: boolean = true
  pdfPreviewPago: any
  imagePreviewPago: any
  archivoUrl: any
  archivoSrc: any
  tipo: boolean = false
  tipo2: boolean = false
  Type: string
  photoUrl: any
  mensajeError: string = '' // Esta propiedad almacenará el mensaje de error
  mostrarEmpresa: string = null
  pdfPreviewer: any;
  fileSize: number | null = null;
  mb: boolean = false

  //Modelo de Solicitud bancaria
  modelo: modeloSolicitudBancaria = new modeloSolicitudBancaria()
  modeloEgresoRentcar: EgresosRentacar = new EgresosRentacar()
  modeloEgresoImportadora: EgresosFijoImportadora = new EgresosFijoImportadora()
  modeloEgresoLubricentro: EgresoLubricentro = new EgresoLubricentro()
  modeloEgresoFirmaAbogado: RegistroEgresoFirma = new RegistroEgresoFirma()
  modeloEgresoHostal: EgresoHostal = new EgresoHostal()
  modeloEgresoInmobiliaria: EgresosInmobiliaria = new EgresosInmobiliaria()
  modeloEgresoGastoPersonal: EgresoPersonal = new EgresoPersonal()
  factura: any
  imagen: any
  pdfPreview: any
  imagePreview: any
  pdfUrl: any
  url: any
  originalAddressForm: any
  pdf: any
  desdeEdicion: boolean = true
  razonRechazoValida: boolean = false;

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService,
    private rentacarService: RentacarService,
    private importadoraService: ImportadoraService,
    private lubricentroService: LubricentroService,
    private firmaAbogadoService: AbogadosService,
    private hostalService: HostalService,
    private inmobiliariaService: InmobiliariaService,
    private gastoService: GastoService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private sucursalService: SucursalSharedService,
    private sharedService: ConsolidadoBancarioSharedService,
    private alert: AlertHelper,
    private consolidadoBancarioSharedService: ConsolidadoBancarioSharedService, // Inicializa la instancia

    //prueba
    private fba: FormBuilder
  ) {
    this.servicioCompartidoConsolidado = consolidadoBancarioSharedService

    //prueba
    this.addressFormPrueba = this.fba.group({
      confirmadoPorFinanzas: [null] // Puede ser 'Confirmado', 'No Confirmado', o null
    })
  }

  ngOnInit(): void {
    this.idPagoBancario = localStorage.getItem('idPagoBancario')
    this.getPagoCuentaBancaria()
    this.rolUsuario = this.usuario.rol
    this.convertirABase64(this.idPagoBancario)

    this.onInputChange();
  }

  updateServicioCompartidoConsolidado() {
    this.servicioCompartidoConsolidado.recargarTabla()
  }
  ////----------------------------Cambiar estado ------
  //Configuracion de formulario
  addressForm = this.fb.group({
    NumeroCuenta: null,
    nombreResponsable: null,
    rut: null,
    jefeAutoriza: null,
    fechaSolicitud: null,
    estado: ['', [Validators.required]],
    archivos: null,
    archivoDevuelto: null,
    montoPago: null,
    motivoPago: null,
    inversion: null,
    correo: null,
    NumeroCuentaAsume: null,
    // empresaAsumeGasto: null,
    banco: null,
    nombreTransferencia: null,
    bancoCliente: null,
    razonRechazo: null,
    razonApruebo: null,
    nombreSucursal: null,
    asumeFactura: null,
    horaEstado: null,
    horaSolicitud: null,
    horapago: null,
    RutEmpresa: null,
    link: null,
    linkValue: null,
    fotoFactura: null,
    estadoFactura: null,
    confirmadoPorFinanzas: null,
    Usuario: null,
    nombreCompleto: null,
    idUsuario: null,
    tipoCuenta: null,
    rutaFactura: null,
    rutaArchivopago: null,
    idArriendo: null
    //----
  })

  get f() {
    return this.addressForm.controls
  }
  // //////////////////////////////////////MODIFICACION ISAI ///////////////////////////////////////
  obtenerSucursales(id: number) {
    this.sucursalService
      .getById(id)
      .pipe(first())
      .subscribe((x: any) => {
        //console.log('Respuesta de la API:', x)

        // Verificar si el objeto x tiene el campo idEmpresa
        if (x && x.idEmpresa) {
          this.Empresa = x.idEmpresa
        } else {
          console.log('No se encontró idEmpresa en la respuesta o es null') // Asignar null explícitamente si no se encuentra idEmpresa
        }

        //console.log('idEmpresa obtenido:', this.Empresa)

        // Aquí puedes continuar con la lógica después de obtener idEmpresa
      })
  }
  // //////////////////////////////////////MODIFICACION ISAI ///////////////////////////////////////

  getPagoCuentaBancaria() {
    this.consolidadoBancarioService
      .getByIdPagoBancario(this.idPagoBancario)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idPagoBancario) {
          this.f.Usuario.setValue(x[0].Usuario)
          this.est = x[0].estado
          this.f.NumeroCuenta.setValue(x[0].NumeroCuenta)
          this.f.nombreResponsable.setValue(x[0].nombreResponsable)
          this.f.rut.setValue(x[0].rut)
          this.f.jefeAutoriza.setValue(x[0].jefeAutoriza)
          this.f.fechaSolicitud.setValue(x[0].fechaSolicitud.slice(0, 10))
          this.f.estado.setValue(x[0].estado)
          this.f.archivos.setValue(x[0].archivos)
          this.f.archivoDevuelto.setValue(x[0].archivoDevuelto)
          this.f.montoPago.setValue(x[0].montoPago)
          this.f.inversion.setValue(x[0].inversion)
          this.f.motivoPago.setValue(x[0].motivoPago)
          this.f.correo.setValue(x[0].correo)
          this.f.razonRechazo.setValue(x[0].razonRechazo)
          this.f.NumeroCuentaAsume.setValue(x[0].NumeroCuentaAsume)
          // this.f.empresaAsumeGasto.setValue(x[0].empresaAsumeGasto)
          this.f.asumeFactura.setValue(x[0].asumeFactura)
          this.f.banco.setValue(x[0].banco)
          this.f.nombreTransferencia.setValue(x[0].nombreTransferencia)
          this.f.bancoCliente.setValue(x[0].bancoCliente)
          this.f.correo.setValue(x[0].correo)
          this.f.link.setValue(x[0].link)
          this.f.tipoCuenta.setValue(x[0].tipoCuenta)
          this.f.idUsuario.setValue(x[0].idUsuario)
          this.f.estadoFactura.setValue(x[0].estadoFactura)
          this.f.rutaFactura.setValue(x[0].rutaFactura)
          this.f.rutaArchivopago.setValue(x[0].rutaArchivopago)
          this.f.confirmadoPorFinanzas.setValue(x[0].confirmadoPorFinanzas)
          this.f.idArriendo.setValue(x[0].idArriendo)// solo se ocupa contador no tiene info relevante, sin el se pueden duplicar registros


          ////////////////////////////////////////VALORES PARA ESTADOS AL INGRESAR ///////////////////////////////////////
          const estadoFactura = this.addressForm.get('estadoFactura').value
          const estadoConfirmado = this.f.confirmadoPorFinanzas.value
          this.addressFormPrueba
            .get('confirmadoPorFinanzas')
            ?.setValue(estadoConfirmado)

          console.log(this.f.confirmadoPorFinanzas.value)

          if (
            this.f.confirmadoPorFinanzas.value === true ||
            this.f.confirmadoPorFinanzas.value === 'Confirmado'
          ) {
            this.addressForm.get('confirmadoPorFinanzas').setValue('Confirmado')
          } else {
            this.addressForm
              .get('confirmadoPorFinanzas')
              .setValue('No Confirmado')
          }

          if (
            this.addressForm.value.estadoFactura !== 'Por Confirmar' ||
            this.addressForm.value.estadoFactura !== 'Emitida' ||
            this.addressForm.value.estadoFactura == null
          ) {
            this.addressForm.value.estadoFactura = 'No Emitida'
          }

          ////////////////////////////////////////FIN DE VALORES///////////////////////////////////////

          const fechaHora = new Date(x[0].createdAt)
          const hora = fechaHora.getHours()
          const minutos = fechaHora.getMinutes()
          const horaFormateada = `${hora.toString().padStart(2, '0')}:${minutos
            .toString()
            .padStart(2, '0')}`
          this.f.horaSolicitud.setValue(horaFormateada)

          const fechaEstado = new Date(x[0].updatedAt)
          const horas = fechaEstado.getHours()
          const minuto = fechaEstado.getMinutes()
          this.fechaFormateada = `${horas.toString().padStart(2, '0')}:${minuto
            .toString()
            .padStart(2, '0')}`
          localStorage.setItem('HoraAutorizacion', this.fechaFormateada)

          if (
            this.f.estado.value === 'Autorizada' ||
            this.f.estado.value === 'Rechazada' ||
            this.f.estado.value === 'Transferida'
          ) {
            const fechaEstadoPago = new Date(x[0].updatedAt)
            const horaPago = fechaEstadoPago.getHours()
            const minutoPago = fechaEstadoPago.getMinutes()
            this.horaPagado = `${horaPago
              .toString()
              .padStart(2, '0')}:${minutoPago.toString().padStart(2, '0')}`
            localStorage.setItem('HoraPago', this.horaPagado)

            const fechaFormateadaAutorizacion =
              localStorage.getItem('HoraAutorizacion')
            if (fechaFormateadaAutorizacion) {
              this.f.horaEstado.setValue(fechaFormateadaAutorizacion)
            }
          }
          if (
            this.f.estado.value === 'Pagado' ||
            this.f.estado.value === 'Rechazada'
          ) {
            const fechaFormateadaAutorizacion = localStorage.getItem('HoraPago')
            const horaPago = localStorage.getItem('HoraAutorizacion')
            if (fechaFormateadaAutorizacion && horaPago) {
              this.f.horaEstado.setValue(fechaFormateadaAutorizacion)
              this.f.horapago.setValue(horaPago)
            }
          }
        }
        if (
          this.f.asumeFactura.value ===
          'Empresa Teresa del Carmen Garrido Rojas e hijos' /* 'TERESA 1 BANCO ITAU 76.791.832-1' */
        ) {
          this.f.RutEmpresa.setValue('76.791.832-1')
        } else if (
          this.f.asumeFactura.value === 'Empresa Firma Abogados'
          /* 'FIRMA ABOGADOS CHILE BANCO ITAU 76.438.914-K' */
        ) {
          this.f.RutEmpresa.setValue('76.438.914-K')
        } else if (
          this.f.asumeFactura.value === 'SOC. GARRIDO BANCO ITAU 76.971.509-6'
        ) {
          this.f.RutEmpresa.setValue('76.971.509-6')
        } else if (
          this.f.asumeFactura.value ===
          'MIGUEL VARGAS E HIJOS BANCO ITAU 76.849.793-1'
        ) {
          this.f.RutEmpresa.setValue('76.849.793-1')
        } else if (
          this.f.asumeFactura.value ===
          'Empresa Teresa II' /* 'TERESA 2 BANCO ITAU 77.265.668-8' */
        ) {
          this.f.RutEmpresa.setValue('77.265.668-8')
        } else if (
          this.f.asumeFactura.value ===
          'Señora  Teresa' /* 'TERESA GARRIDO ROJAS BANCO ESTADO' */
        ) {
          this.f.RutEmpresa.setValue('11070934-K')
        } else if (
          this.f.asumeFactura.value ===
          'Diego Vargas' /* 'DIEGO VARGAS GARRIDO ITAU' */
        ) {
          this.f.RutEmpresa.setValue('18891594-9')
        } else if (
          this.f.asumeFactura.value ===
          'Miguel Vargas' /* 'MIGUEL VARGAS GARRIDO SANTANDER' */
        ) {
          this.f.RutEmpresa.setValue('17886328-2')
        } else if (
          this.f.asumeFactura.value ===
          'Miguel A Vargas Espinoza' /* 'MIGUEL VARGAS ESPINOZA BANCO ESTADO' */
        ) {
          this.f.RutEmpresa.setValue('8781641-9')
        } else if (
          this.f.asumeFactura.value === 'BRAULIO VARGAS GARRIDO SCOTIABANCK'
        ) {
          this.f.RutEmpresa.setValue('18228581-1')
        } else if (this.f.asumeFactura.value === 'JAVIER VARGAS GARRIDO ITAU') {
          this.f.RutEmpresa.setValue('19474232-0')
        } else if (
          this.f.asumeFactura.value ===
          'SOC. VARGAS II' /* 'SOC. VARGAS 2 BANCO ITAU 77.258.157-2' */
        ) {
          this.f.RutEmpresa.setValue('77.258.157-2')
        } else if (
          this.f.asumeFactura.value ===
          'SOC. VARGAS I' /* 'SOC. VARGAS 1 BANCO ITAU 76.457.046-4' */
        ) {
          this.f.RutEmpresa.setValue('76.457.046-4')
          this.f.razonApruebo.setValue(x[0].razonApruebo)
        } else if (
          this.f.asumeFactura.value ===
          'Empresa Solanch Macarena Tejos Carrasco'
        ) {
          this.f.RutEmpresa.setValue('19.105.142-4')
        }
        else if (
          this.f.asumeFactura.value === 'Empresa Miguel Vargas Espinoza e hijos'
        ) {

          this.f.RutEmpresa.setValue('76849914-k')
        }

        this.NumeroCuentaAsume = x[0].NumeroCuentaAsume
        this.idSucursal = x[0].idSucursal
        console.log(this.idSucursal)
        this.idUsuario = x[0].idUsuario
        this.linkValue = x[0].link
        console.log('link aca :', this.linkValue)

        if (
          this.linkValue !== null &&
          this.linkValue !== undefined &&
          this.linkValue !== ''
        ) {
          // Eliminar cualquier cosa antes de 'http' y después de la última comilla
          const regex = /.*(http.*?)(?="|\\|$)/
          const match = this.linkValue.match(regex)

          if (match && match[1]) {
            this.linkValue = match[1] // Mantener solo la parte que comienza con 'http'
          }

          // Validar que el link tiene 'http', si no agregarlo
          if (!this.linkValue.startsWith('http')) {
            this.linkValue = 'https://' + this.linkValue.trim()
          }

          // Eliminar comillas dobles adicionales al principio y al final
          if (this.linkValue.startsWith('"')) {
            this.linkValue = this.linkValue.slice(1)
          }

          if (this.linkValue.endsWith('"')) {
            this.linkValue = this.linkValue.slice(0, -1)
          }

          console.log('Link limpio:', this.linkValue)
        }

        ////////////////////////////VALIDACION PARA ENVIAR A EMPRESAS///////////////////
        // Solo se tomo Id arriendo para usarlo como contador ya que no se ocupa en el registro original
        //Si llega en null se la da 1 y puede pasar a la divicion de empresas sino no pude enviarse mas, para evitar datos duplicados
        if (
          this.f.idArriendo.value == null ||
          this.f.idArriendo.value === undefined
        ) {
          // Si es null o undefined, asigna 0
          this.f.idArriendo.setValue(0)
          console.log(this.f.idArriendo.value)
        } else {
          if (this.f.idArriendo.value < 0) {
            // Si el valor es negativo, asigna 0
            this.f.idArriendo.setValue(0)
          } else if (this.f.idArriendo.value === 1) {
            // Si el valor ya es 1, lo mantiene en 1
            this.f.idArriendo.setValue(1)
          }
          console.log(this.f.idArriendo.value)
        }
        ////////////////////////////FIN DE VALIDACION PARA ENVIAR A EMPRESAS///////////////////

        console.log(
          'Formulario cuando recoje los datos desde el incio',
          this.addressForm.value
        )
        this.Empresas()
        this.originalAddressForm = this.addressForm.value
        console.log('formulario original', this.originalAddressForm)
      })
  }
  //HACE QUE EL BOTON DE CONFIRMADO POR FINANZAS SE MUESTRE COMO VIENE DESDE LA BASE
  setConfirmado(valor: string) {
    this.addressForm.get('confirmadoPorFinanzas')?.setValue(valor)
    this.addressFormPrueba.get('confirmadoPorFinanzas')?.setValue(valor)
  }
  onInputChange() {
    const razonRechazo = this.addressForm.get('razonRechazo').value;
    this.razonRechazoValida = razonRechazo && razonRechazo.trim() !== '';
  }
  ////////////////////////////////////////MODIFICACION ISAI ///////////////////////////////////////


  ////////////////////////////////////////FUNCIONES PARA ARCHIVOS ///////////////////////////////////////
  onFileChange(event: any, estadoFactura: any, id: string): void {
    const file = event.target.files[0]
    if (file) {
      const fileSizeInBytes = file.size;  // Tamaño en bytes
      this.fileSize = parseFloat((fileSizeInBytes / (1024 * 1024)).toFixed(2));  // Convertir a MB con dos decimales

      // Puedes agregar alguna condición si el tamaño supera un límite
      if (this.fileSize > 2) {
        alert("El archivo es demasiado grande para visualizarlo. Sin embargo, será guardado en el sistema, y un botón de descarga se habilitará después de la subida");
        // Resetea el tamaño si es demasiado grande
        // event.target.value = '';  // Limpia el input
        this.mb = true
      }
    }

    if (!file) {
      console.error('No se ha seleccionado ningún archivo')
      return
    }

    // Obtener la extensión del archivo
    const extension = file.name.split('.').pop()

    // Crear un nuevo nombre usando el ID y la fecha/hora actual
    const timestamp = new Date().getTime()
    let nuevoNombreArchivo = ''

    if (estadoFactura !== null) {
      nuevoNombreArchivo = `Factura-${id}-${timestamp}.${extension}`
    } else {
      nuevoNombreArchivo = `pagoOC-${id}-${timestamp}.${extension}`
    }

    console.log('Nuevo nombre del archivo:', nuevoNombreArchivo)

    // Crear un nuevo archivo con el nombre modificado
    const archivoRenombrado = new File([file], nuevoNombreArchivo, {
      type: file.type
    })

    const reader = new FileReader()
    reader.onload = () => {
      const fileType = file.type

      if (estadoFactura !== null) {
        console.log('Cargando archivo como factura')
        this.prevw = false
        console.log(this.prevw, this.tipo)
        this.pdfPreview = null
        this.imagePreview = null

        // Asignar el archivo renombrado al formulario
        this.addressForm.patchValue({
          fotoFactura: archivoRenombrado
        })
        this.addressForm.get('fotoFactura')!.updateValueAndValidity()

        // Mostrar vista previa del archivo
        if (fileType === 'application/pdf') {
          this.pdfPreview = this.sanitizer.bypassSecurityTrustResourceUrl(
            reader.result as string
          )
        } else if (fileType === 'image/jpeg' || fileType === 'image/png') {
          this.imagePreview = this.sanitizer.bypassSecurityTrustUrl(
            reader.result as string
          )
        } else {
          console.error('Tipo de archivo no soportado para facturas')
        }
      } else {
        console.log('Cargando archivo como pago')
        this.PrevwI = false
        this.pdfPreviewPago = null
        this.imagePreviewPago = null

        // Asignar el archivo renombrado al formulario
        this.addressForm.patchValue({
          archivos: archivoRenombrado
        })
        this.addressForm.get('archivos')!.updateValueAndValidity()

        // Mostrar vista previa del archivo
        if (fileType === 'application/pdf') {
          this.pdfPreviewPago = this.sanitizer.bypassSecurityTrustResourceUrl(
            reader.result as string
          )
        } else if (fileType === 'image/jpeg' || fileType === 'image/png') {
          this.imagePreviewPago = this.sanitizer.bypassSecurityTrustUrl(
            reader.result as string
          )
        } else {
          console.error('Tipo de archivo no soportado para pago')
        }
      }

      console.log(
        'Formulario después de cargar archivo:',
        this.addressForm.value
      )
    }

    // Lee el archivo como URL de datos
    reader.readAsDataURL(file)
  }

  convertirABase64(idContato: string) {
    // Verificar si el estado es 'Autorizada' o 'Rechazada'
    if (
      this.f.estado.value !== 'Autorizada' ||
      this.f.estado.value !== 'Rechazada'
    ) {
      this.consolidadoBancarioService
        .convertirArchivoABase64(idContato)
        .pipe(
          catchError((error) => {
            // Si el error es 404, muestra un mensaje de "No se encontraron datos"
            if (error.status === 404) {
              this.mensajeError =
                'No se encontraron archivos para el ID proporcionado.'
            } else if (error.status === 500) {
              // Si el error es 500, muestra un mensaje de "Error en el servidor"
              this.mensajeError =
                'Error en el servidor. Por favor, intente nuevamente más tarde.'
            } else {
              // Si es otro tipo de error, muestra un mensaje general
              this.mensajeError = 'No existe Pagos o Facturas del registro.'
            }
            // Retornamos un observable vacío para que el flujo continúe
            return of(null)
          })
        )
        .subscribe((response: any) => {
          if (response) {
            this.mensajeError = '' // Limpiar el mensaje de error si se encuentra un archivo

            // Si existe fotoFactura, generamos la URL para el archivo PDF o imagen
            if (response.rutaFactura) {
              this.pdfUrl = `data:${response.rutaFactura.mimeType};base64,${response.rutaFactura.base64}`
              this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.pdfUrl
              )
              this.Type = `${response.rutaFactura.mimeType}`
              if (this.Type == 'application/pdf') {

                this.tipo = true

              }
              this.photoUrl = this.url
            }

            // Si existe archivo (pago), generamos la URL para el archivo PDF o imagen
            if (response.rutaArchivopago) {
              this.archivoUrl = `data:${response.rutaArchivopago.mimeType};base64,${response.rutaArchivopago.base64}`
              this.archivoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.archivoUrl
              )
              if (response.rutaArchivopago.mimeType == 'application/pdf') {

                this.tipo2 = true

              }
              this.photoUrl = this.archivoSrc
            }
          } else {
            console.log('No se encontraron archivos.')
          }
        })
    } else {
      console.log(
        'La conversión a base64 no se realiza ya que el estado no es "Autorizada" o "Rechazada".'
      )
    }
  }

  subirArchivos() {
    try {
      this.loading2 = true
      const formularioDeDatos = new FormData()

      // Verificar si existe fotoFactura y añadirla al FormData
      const fotoFactura = this.addressForm.get('fotoFactura')?.value
      if (fotoFactura) {
        console.log('Al subir fotoFactura:', fotoFactura)
        formularioDeDatos.append('fotoFactura', fotoFactura) // Añadir fotoFactura

        // Extraer solo el nombre del archivo
        const nombreFotoFactura = fotoFactura.name
        console.log(nombreFotoFactura) // Obtener el nombre del archivo
        this.addressForm.patchValue({
          rutaFactura: nombreFotoFactura // Actualizar el formulario con el nombre
        })
      }

      // Verificar si existe archivo (pago) y añadirlo al FormData
      const archivo = this.addressForm.get('archivos')?.value

      if (archivo && typeof archivo === 'object' && 'name' in archivo) {
        const nombreArchivo = String(archivo.name) // Asegurarte de que el nombre del archivo es un string
        console.log('Nombre de archivo:', nombreArchivo)

        // Añadir el archivo al FormData
        formularioDeDatos.append('archivos', archivo) // Subir el archivo real

        // También podrías actualizar el formulario con el nombre del archivo si es necesario
        this.addressForm.patchValue({
          rutaArchivopago: nombreArchivo // Solo el nombre del archivo, no el objeto
        })
      } else {
        console.error('El archivo no es válido o no tiene un nombre.')
      }

      // Enviar los archivos al backend
      this.http
        .post(
          `${environment.apiUrl}/solicitudBancaria/uploadFactura`, // URL del backend
          formularioDeDatos
        )
        .subscribe(
          (res) => {
            this.loading2 = false
            this.snackBar.open(`Archivos cargados con éxito`, 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top'
            })
          },
          (error) => {
            this.loading2 = false
            this.snackBar.open(`Error al cargar archivos`, 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top'
            })
            console.log('ERROR AL SUBIR ARCHIVOS', error)
          }
        )
    } catch (e) {
      this.loading2 = false
      this.snackBar.open(`Error al cargar archivos`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR ARCHIVOS', e)
    }
    console.log('despues de subir imagen: ', this.addressForm.value)
  }

  ////////////////////////////////////////FIN DE FUNCIONES PARA ARCHIVOS ///////////////////////////////////////

  //////////////////////////////////////////////// FUNCIONES GENERALES ////////////////////////////////////////////////
  ejecutarTodasLasFunciones() {
    // this.subirArchivos();

    //  this.onSubmit();
    this.guardarYVerificarCambios(this.desdeEdicion)// la variable indica si es desde el boton de cancelar o es para guardar
    //  this.closeDialog()
  }

  Empresas() {
    this.sucursalService
      .getAll()
      .pipe(first())
      .subscribe((data: any) =>
        data.forEach((element) => {
          if (element.id === this.idSucursal) {
            this.sucursal = element.razonSocial
            this.f.nombreSucursal.setValue(this.sucursal)
            console.log(this.f.nombreSucursal.value)
          }
        })
      )


  }


  descargarImagen(a: any): void {
    if (a) {
      let url: string;
      let nombreArchivo: string = 'archivo'; // Nombre por defecto

      // Si `a` es un objeto, convertir a string
      if (
        typeof a === 'object' &&
        a.hasOwnProperty('changingThisBreaksApplicationSecurity')
      ) {
        url = a['changingThisBreaksApplicationSecurity'];
      } else {
        url = a.toString();
      }

      // Verifica si la URL proviene de la ruta de factura o de la ruta de archivo de pago
      if (this.tipo == true) {
        if (this.addressForm.value.rutaFactura == null) {
          nombreArchivo = this.idPagoBancario + '-factura'
        } else { nombreArchivo = this.addressForm.value.rutaFactura.split('/').pop() || 'factura.pdf'; } // Nombre basado en la ruta de factura} 



      } else if (this.tipo2) {

        if (this.addressForm.value.rutaFactura == null) {
          nombreArchivo = this.idPagoBancario + '-factura'
        } else { nombreArchivo = this.addressForm.value.rutaFactura.split('/').pop() || 'factura.pdf'; }

        // Nombre basado en la ruta de archivo de pago
      }
      else {

        if (this.addressForm.value.rutaArchivopago == null) {
          nombreArchivo = this.idPagoBancario + '-pago'
         } else { nombreArchivo = this.addressForm.value.rutaArchivopago.split('/').pop() || 'pago.pdf'; }
        

        // Nombre basado en la ruta de factura

      }

      // Realiza la descarga del archivo
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = nombreArchivo; // Establece el nombre del archivo
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(downloadUrl); // Libera el objeto URL creado
        })
        .catch((error) => {
          console.error('Error al descargar la imagen:', error);
        });
    } else {
      console.error('No hay URL de imagen disponible para descargar.');
    }
  }
  ////////////////////////////////////////FUNCION PARA GUARDAR LOS REGISTROS///////////////////////////////////////
  guardarYVerificarCambios(desdeEdicion: boolean) {
    const valoresOriginales = this.originalAddressForm // Valores originales del formulario
    const valoresActuales = this.addressForm.value // Valores actuales del formulario

    // Detectar los campos con cambios
    const camposConCambios = this.obtenerCambiosFormulario(
      valoresOriginales,
      valoresActuales
    )

    if (camposConCambios.length === 0) {
      // Si no hay cambios y viene desde cancelación, cerrar el diálogo directamente
      if (!desdeEdicion) {
        this.mostrarMensaje('No se ha realizado ningún cambio.')
        this.closeDialog()
      } else {
        this.mostrarMensaje('No se ha realizado ningún cambio.')
        this.closeDialog()
      }
    } else {
      // Si hay cambios y viene desde la edición
      if (desdeEdicion) {
        // Preguntar si desea guardar los cambios
        const mensajeDeCambios = `Se detectaron cambios En El Registro :  ${camposConCambios.join(', ')} ¿Deseas guardar los cambios?`
        const confirmacion = confirm(mensajeDeCambios)

        if (confirmacion) {
          // Guardar los cambios y cerrar el diálogo

          this.onSubmit()
          this.mostrarMensaje('Cambios en el Pago guardados con éxito.')
          // this.convertirABase64(this.idPagoBancario)

          if (this.f.idArriendo.value == 0) {
            this.CuentaBancaria()
            this.DivicionEmpresas()
            this.mostrarMensaje(
              `Registro añadido a Egresos de Empresa : ${this.mostrarEmpresa}. Con Exito`// el fin de todo el dialog es aca una vez se manden los registros
            )
            if (this.f.idArriendo.value == 0 && this.mostrarEmpresa !== null) {
              this.f.idArriendo = this.f.idArriendo.value + 1
            }
          } else {
            console.log('este registro ya fue añadido')
          }

          // Aquí iría la lógica de guardar en el backend, si aplica
          this.closeDialog()
        } else {
          this.mostrarMensaje('Cambios no guardados. Puedes seguir editando.')
        }
      } else {
        // Si se viene desde cancelar, preguntar si desea descartar los cambios
        const mensajeDescartar = `Tienes los siguientes cambios sin guardar: ${camposConCambios.join(
          ', '
        )}. ¿Deseas descartar los cambios y salir sin guardar?`
        const descartarCambios = confirm(mensajeDescartar)

        if (descartarCambios) {
          // Descartar cambios y cerrar el diálogo
          this.mostrarMensaje(
            `Cambios descartados: ${camposConCambios.join(', ')}`
          )
          this.closeDialog()
        } else {
          // Si el usuario no quiere descartar, simplemente no cerrar el diálogo
          this.mostrarMensaje('Cambios no descartados. Puedes seguir editando.')
        }
      }
    }
  }




  // Función para obtener los cambios entre el formulario original y el actual
  obtenerCambiosFormulario(originalForm: any, currentForm: any): string[] {
    const camposConCambios: string[] = [];

    // Mapa de nombres descriptivos
    const nombresDescriptivos: { [key: string]: string } = {
      Usuario: 'Usuario',
      NumeroCuenta: 'Número de Cuenta',
      nombreResponsable: 'Nombre del Responsable',
      rut: 'RUT',
      jefeAutoriza: 'Jefe Autoriza',
      fechaSolicitud: 'Fecha de Solicitud',
      estado: 'Estado',
      archivos: 'Archivos',
      archivoDevuelto: 'Archivo Devuelto',
      montoPago: 'Monto del Pago',
      inversion: 'Inversión',
      motivoPago: 'Motivo del Pago',
      correo: 'Correo Electrónico',
      razonRechazo: 'Razón del Rechazo',
      NumeroCuentaAsume: 'Número de Cuenta que Asume',
      asumeFactura: 'Asume Factura',
      banco: 'Banco',
      nombreTransferencia: 'Nombre de la Transferencia',
      bancoCliente: 'Banco del Cliente',
      link: 'Enlace',
      tipoCuenta: 'Tipo de Cuenta',
      idUsuario: 'ID Usuario',
      estadoFactura: 'Estado de la Factura',
      fotoFactura: 'Respaldo de Factura',
      rutaFactura: 'Ruta de la Factura',
      rutaArchivopago: 'Ruta del Archivo de Pago',
      confirmadoPorFinanzas: 'Confirmado por Finanzas',
      idArriendo: 'ID de Arriendo'
    };

    // Lista de campos que deseas ignorar en la comparación
    const camposExcluidos = ['nombreSucursal', 'idArriendo']; // Añade aquí los campos que quieras excluir

    Object.keys(originalForm).forEach((campo) => {
      // Verificar si el campo no está en la lista de excluidos
      if (!camposExcluidos.includes(campo) && originalForm[campo] !== currentForm[campo]) {
        // Usar el nombre descriptivo si existe, de lo contrario, usar el nombre del campo original
        const nombreCampo = nombresDescriptivos[campo] || campo;
        camposConCambios.push(nombreCampo);
      }
    });

    console.log('cambios', camposConCambios);
    return camposConCambios;
  }

  // Función para mostrar mensajes con snackBar
  mostrarMensaje(mensaje: string) {
    this.snackBar.open(mensaje, 'Cerrar', {
      duration: 2000,
      verticalPosition: 'top'
    })
  }

  // //////////////////////////////////////FIN DE FUNCIONES GENERALES ///////////////////////////////////////

  // //////////////////////////////////////ESTO ACTUALIZA EL MONTO DE  CUENTAS BANCARIAS///////////////////////////////////////
  CuentaBancaria() {
    if (
      this.f.estado.value === 'Pagado' ||
      this.f.estado.value === 'Rechazada' ||
      this.f.estado.value === 'Devuelto'
    ) {
      switch (this.addressForm.status) {
        case 'VALID':
          this.consolidadoBancarioService
            .getAllCuentaBancaria()
            .pipe(first())
            .subscribe((data: any) => {
              data.forEach((element) => {
                if (element.numeroCuenta === this.NumeroCuentaAsume) {
                  const MontoPago = this.f.montoPago.value
                  const MontoTotal = element.totalMonto
                  const data = MontoTotal - MontoPago
                  element.totalMonto = data
                  this.UpdateCuentaBancaria.push(element)
                  console.log(
                    'cuenta cancaria',
                    this.UpdateCuentaBancaria[0].id
                  )
                  if (this.UpdateCuentaBancaria[0].id !== null) {
                    this.consolidadoBancarioService
                      .updateCuentaBancaria(
                        this.UpdateCuentaBancaria[0].id,
                        this.UpdateCuentaBancaria[0]
                      )
                      .pipe(first())
                      .subscribe(
                        (data) => {
                          this.snackBar.open(
                            'Monto de Cuenta Asume registrado  con éxito.',
                            'Cerrar',
                            {
                              duration: 2000,
                              verticalPosition: 'top'
                            }
                          )
                          // this.addressForm.reset()
                          this.consolidadoBancarioService.closeDialogModal()
                          // window.location.reload()
                        },
                        (error) => {
                          this.snackBar.open(
                            'No se pudo editar El monto de la Cuenta, favor contactar a informática.',
                            'Cerrar',
                            {
                              duration: 2000,
                              verticalPosition: 'top'
                            }
                          )
                        }
                      )
                  } else {
                    this.snackBar.open(
                      'No se pudo editar El monto de la Cuenta, Recarge la pagina sino r contactar a informática.',
                      'Cerrar',
                      {
                        duration: 2000,
                        verticalPosition: 'top'
                      }
                    )
                  }
                }
              })
            })

          break
        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    } else {
      this.snackBar.open('Falta cambiar estado.', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }

  moveUp() {
    this.clickButton = true
  }
  //Cerrar Modal
  closeDialog() {
    // this.sharedService.recargarTabla()
    this.administracionService.closeDialogModal()
    this.consolidadoBancarioService.closeDialogModal()

    // window.location.reload()
  }






  // //////////////////////////////////////MODIFICACION ISAI ///////////////////////////////////////
  //Desshabilutar mat-expansion-panel


  onSubmit() {
    this.subirArchivos()// antes de subir los demas cambios se mandan los archivos, no se confunden por que van por id y por fecha
    //===> Cambia el estado
    if (
      this.addressForm.value.estado === 'Saldado' ||
      this.addressForm.value.estado === 'Pagado' ||
      this.addressForm.value.estado === 'Rechazada' ||
      this.addressForm.value.estado === 'Devuelto'
    ) {
      // Si no hay estadoFactura, asignar 'No Emitida' (inicialmente nulo)
      if (
        this.addressForm.value.estadoFactura !== 'Por Confirmar' ||
        this.addressForm.value.estadoFactura !== 'Emitida' ||
        this.addressForm.value.estadoFactura == null
      ) {
        this.addressForm.value.estadoFactura = 'No Emitida'
      }

      // Verificar si existe una rutaFactura (this.url o addressForm.value.rutaFactura)
      if (
        this.url ||
        (this.addressForm.value.rutaFactura &&
          this.addressForm.value.rutaFactura.length > 0)
      ) {
        this.addressForm.value.estadoFactura = 'Por Confirmar'
      }

      // Manejar el estado de 'confirmadoPorFinanzas'
      if (this.addressForm.value.estadoFactura === 'Por Confirmar') {
        const confirmadoPorFinanzas = this.addressForm.get(
          'confirmadoPorFinanzas'
        ).value
        console.log(confirmadoPorFinanzas)

        // Si confirmadoPorFinanzas viene como true o false (nuevo registro)
        if (typeof confirmadoPorFinanzas === 'boolean') {
          if (
            confirmadoPorFinanzas === true &&
            (this.url || this.addressForm.value.rutaFactura)
          ) {
            this.addressForm.value.estadoFactura = 'Emitida'
            this.addressForm.value.confirmadoPorFinanzas = 'Confirmado' // Cambia a 'Confirmado'
          } else if (
            confirmadoPorFinanzas === false &&
            (this.url || this.addressForm.value.rutaFactura)
          ) {
            console.log('paso al no')
            this.addressForm.value.estadoFactura = 'Por Confirmar'
            this.addressForm.value.confirmadoPorFinanzas = 'No Confirmado' // Cambia a 'No Confirmado'
          }
        }
        // Si confirmadoPorFinanzas ya viene como 'Confirmado' o 'No Confirmado' (registro anterior)
        else if (
          this.addressForm.value.confirmadoPorFinanzas === 'Confirmado' ||
          this.addressForm.value.confirmadoPorFinanzas === 'No Confirmado'
        ) {
          // Mantener el valor tal cual, no lo sobreescribimos
        }
      }

      if (
        this.addressForm.value.estadoFactura === 'Por Confirmar' &&
        this.addressForm.value.confirmadoPorFinanzas === 'Confirmado'
      ) {
        console.log('paso entre por confirmar a confirmado')

        this.addressForm.value.estadoFactura = 'Emitida'
      }

      // Si ya está en 'Emitida' pero no está confirmado por finanzas, revertir a 'Por Confirmar'
      if (
        this.addressForm.value.estadoFactura === 'Emitida' &&
        this.addressForm.value.confirmadoPorFinanzas === 'No Confirmado'
      ) {
        this.addressForm.value.estadoFactura = 'Por Confirmar'
      }
      if (this.addressForm.value.estado === 'Rechazada') {
        this.addressForm.value.estadoFactura = 'No Emitida'
      }


      this.addressForm.value.fotoFactura = null
      this.addressForm.value.archivos = null
      console.log('formulario aqui dios', this.addressForm.value)
      this.addressForm.value.nombreCompleto = this.nombreCompleto

      this.addressForm.value.link = this.linkValue

      switch (this.addressForm.status) {
        case 'VALID':
          this.consolidadoBancarioService
            .updatePagoBancario(this.idPagoBancario, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Operacion realizada con exito', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                // this.addressForm.reset()
                this.consolidadoBancarioService.closeDialogModal()
                //window.location.reload()
                this.updateServicioCompartidoConsolidado()
                // this.closeDialog()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar la cuenta, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break
        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    }
    /////////////////////////////////////////////ENVIO EN CASO DE QUE SE RECHACE///////////////////////////////////////
    if (this.addressForm.value.estado === 'Rechazada') {
      this.estado = 'Rechazada'
      switch (this.addressForm.status) {
        case 'VALID':
          this.consolidadoBancarioService
            .updatePagoBancario(this.idPagoBancario, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Operacion realizada con exito', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                // this.addressForm.reset()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar la cuenta, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break
        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
      //====> Mejorar interfaz de usuario cuando este rechazada!
      console.log('Rechazada')
    }

    else {
      /// ////////////////////SOLO SI ESTA PAGADA + CONFIRMADA POR FINANZAS ////////////////
      console.log('Aceptada')
    }
  }



  // //////////////////////////////////////FUNCIONES PARA DIVISION DE EMPRESAS ///////////////////////////////////////
  registrarEgresoEnEmpresa(empresa, egreso) {
    console.log('Empresa en funcion registrar', empresa)
    empresa.modeloEgreso.idSucursal = this.idSucursal
    empresa.modeloEgreso.tipoEgreso = egreso.inversion
    empresa.modeloEgreso.descripcion = egreso.motivoPago
    empresa.modeloEgreso.fecha = egreso.fechaSolicitud
    empresa.modeloEgreso.tipoPago = egreso.tipoPago
    empresa.modeloEgreso.monto = (egreso.montoPago * empresa.porcentaje) / 100
    empresa.modeloEgreso.montoCuota = egreso.montoCuota
    empresa.modeloEgreso.responsable = egreso.nombreResponsable
    empresa.modeloEgreso.numeroCuota = egreso.numeroCuota
    empresa.modeloEgreso.grupoGastos =
      'PORTAL DE PAGOS OC TRANSFERENCIAS DESDE CASA MATRIZ'
    empresa.modeloEgreso.archivos = [this.addressForm.value.rutaArchivopago] //este es el mismo archivo que se subio pero en base64 un avez subido se va a buscar deneuvo para inclirlo en el modelo
    empresa.modeloEgreso.idUsuario = egreso.idUsuario
    console.log('modelo empresa en funcion registrar', empresa.modeloEgreso)
    this.mostrarEmpresa = empresa.nombre
    if (empresa.nombre == 'RentaCar') {
      empresa.modeloEgreso.cuentaGasto = egreso.asumeFactura
    }
    //this.modeloEgresoGastoPersonal.monto =
    //                 this.addressForm.value.montoPago

    // Llamar al método correcto utilizando el nombre almacenado en 'metodo'
    if (
      empresa.servicio &&
      typeof empresa.servicio[empresa.metodo] === 'function'
    ) {
      empresa.servicio[empresa.metodo](empresa.modeloEgreso)
        .pipe(first())
        .subscribe(
          (data) => {
            this.snackBar.open(
              'Se Agrego el Egreso A la Empresa Correspondiente  con éxito',
              'cerrar',
              {
                duration: 2000,
                verticalPosition: 'top'
              }
            )
            this.closeDialog()
          },
          (error) => {
            this.snackBar.open(
              'Tenemos problemas para realizar el registro del Egreso, por favor contactar al equipo de desarrollo',
              'cerrar',
              { duration: 2000, verticalPosition: 'top' }
            )
            this.snackBar.open(error, 'cerrar', {
              duration: 3000,
              verticalPosition: 'top'
            })
          }
        )
    } else {
      console.error(
        `El método ${empresa.metodo} no está definido en el servicio para ${empresa.nombre}`
      )
    }
  }

  DivicionEmpresas() {
    if (this.addressForm.value.estadoFactura === 'Emitida') {
      const empresas = [
        //El array de empresas contiene las empresas en las cuales se divide el gasto de Casa Matriz con sus porsentajes(llama a los servicios y modelos correspondientes)
        {
          nombre: 'RentaCar',
          porcentaje: 45,
          servicio: this.rentacarService,
          modeloEgreso: this.modeloEgresoRentcar,
          metodo: 'EgresoRentACar'
        },
        {
          nombre: 'Firma',
          porcentaje: 30,
          servicio: this.firmaAbogadoService,
          modeloEgreso: this.modeloEgresoFirmaAbogado,
          metodo: 'egresoRegistrar'
        },
        {
          nombre: 'Lubricentro',
          porcentaje: 10,
          servicio: this.lubricentroService,
          modeloEgreso: this.modeloEgresoLubricentro,
          metodo: 'egresoRegistrar'
        },
        {
          nombre: 'Importadora',
          porcentaje: 5,
          servicio: this.importadoraService,
          modeloEgreso: this.modeloEgresoImportadora,
          metodo: 'createEgresosFijo'
        },
        {
          nombre: 'Hostal',
          porcentaje: 10,
          servicio: this.hostalService,
          modeloEgreso: this.modeloEgresoHostal,
          metodo: 'egresoRegistrar'
        }
      ]

      const empresas2 = [
        // el array empresas2 contiene las empresas que o bien son un DIV o para sucursales que solo tienen asociado una empresa en particuar
        {
          id: 4, // idEmpresa
          nombre: 'RentaCar',
          servicio: this.rentacarService,
          modeloEgreso: this.modeloEgresoRentcar,
          metodo: 'EgresoRentACar'
        },
        {
          id: 2,
          nombre: 'Firma',/* Abogados */
          servicio: this.firmaAbogadoService,
          modeloEgreso: this.modeloEgresoFirmaAbogado,
          metodo: 'egresoRegistrar'
        },
        {
          id: 3,
          nombre: 'Lubricentro',
          servicio: this.lubricentroService,
          modeloEgreso: this.modeloEgresoLubricentro,
          metodo: 'egresoRegistrar'
        },
        {
          id: 9,
          nombre: 'Importadora',
          servicio: this.importadoraService,
          modeloEgreso: this.modeloEgresoImportadora,
          metodo: 'createEgresosFijo'
        },
        {
          id: 1,
          nombre: 'Hostal',
          servicio: this.hostalService,
          modeloEgreso: this.modeloEgresoHostal,
          metodo: 'egresoRegistrar'
        },
        {
          nombre: 'Gasto Personal', //A excepcion de Gasto Personal este no se puede mover a menos que se cambie desde el codigo ya que es el unico que lo busca por posicion en el array
          servicio: this.gastoService,
          modeloEgreso: this.modeloEgresoGastoPersonal,
          metodo: 'createEgresos'
        },
        {
          id: 5,
          nombre: 'Inmobiliaria',
          servicio: this.inmobiliariaService,
          modeloEgreso: this.modeloEgresoInmobiliaria,
          metodo: 'egresoInmobiliaria'
        }
      ]

      // Definir los ids que requieren la empresa [5] de empresas2
      const idsEspeciales = [122, 121, 120, 119, 118, 127] //Los ID especiales se ocupan para las Sucursales o cuenta que van a gasto personal en caso de queiran agregar a otra persona o sucurlas apuntado a gasto personal
      //console.log('antes del if de id sucrusales')
      if (this.idSucursal === 1) {
        if (this.addressForm.status === 'VALID') {
          empresas.forEach((empresa) => {
            this.registrarEgresoEnEmpresa.call(
              this,
              empresa,
              this.addressForm.value
            )
          })
        }
        // }
      }

      // Comprobar si el idSucursal está en el array idsEspeciales
      if (idsEspeciales.includes(this.idSucursal)) {
        //console.log('encontro una id especial')
        // Seleccionar la empresa en la posición [5] del array empresas2
        let nuevasEmpresas: any[] = []
        const empresaSeleccionada = empresas2[5]
        nuevasEmpresas.push(empresas2[5])
        const numeroDeEmpresas = nuevasEmpresas.length
        const porcentajeDividido = 100 / numeroDeEmpresas
        nuevasEmpresas.forEach((empresa) => {
          empresa.porcentaje = porcentajeDividido
          this.registrarEgresoEnEmpresa(empresa, this.addressForm.value)
        })

        // Lógica para registrar el egreso con la empresa seleccionada
      } else {
        //console.log('paso al else')
        const sucursalEmpresasMap = {
          // Estos ID corresponden a los DIV de sucrusales Para agregar una sucursal con gasto dividio se buscan las posiciones dende esta el array de empresas
          86: [empresas[0], empresas[2], empresas[3]],
          87: [empresas[0], empresas[1]],
          88: [empresas[0], empresas[1]],
          89: [empresas[0], empresas[2], empresas[3]],
          90: [empresas[0], empresas[2], empresas[3]]
          // Agrega aquí más sucursales si es necesario dividir el gasto
        }

        // Primero verifica si la sucursal está en la lista de división de gastos
        if (sucursalEmpresasMap[this.idSucursal]) {
          // Si la sucursal requiere división de gastos, usa el mapeo
          let nuevasEmpresas = sucursalEmpresasMap[this.idSucursal]
          //console.log('empresa con gasto compartido', nuevasEmpresas)

          const numeroDeEmpresas = nuevasEmpresas.length
          const porcentajeDividido = 100 / numeroDeEmpresas

          nuevasEmpresas.forEach((empresa) => {
            empresa.porcentaje = porcentajeDividido
            this.registrarEgresoEnEmpresa(empresa, this.addressForm.value)
          })
        } else {
          //console.log('segundo else')

          if (this.addressForm.status === 'VALID') {
            // this.addressForm.value.archivos = this.dataArrayImg
            // this.addressForm.value.archivoDevuelto = this.dataArrayDevuelto
            //console.log('omaiga')
            if (this.addressForm.status === 'VALID') {
              //console.log('skere')
              this.sucursalService
                .getById(this.idSucursal)
                .pipe(first())
                .subscribe((x: any) => {
                  //console.log('entro a la funcion')
                  const idEmpresa = x.idEmpresa
                  //console.log(idEmpresa)
                  let nuevasEmpresas = []

                  if (sucursalEmpresasMap[this.idSucursal]) {
                    // Si la sucursal requiere división de gastos, usar el mapeo
                    nuevasEmpresas = sucursalEmpresasMap[this.idSucursal]
                  } else {
                    // Buscar la empresa seleccionada directamente en empresas2 si es un array de objetos
                    // El número que estás buscando
                    let nuevasEmpresas: any[] = [] // Declarar nuevasEmpresas como un array vacío

                    for (let i = 0; i < empresas2.length; i++) {
                      if (empresas2[i].id === idEmpresa) {
                        nuevasEmpresas.push(empresas2[i]) // Agregar el objeto al array
                        break // Termina el bucle al encontrar la empresa
                      }
                    }

                    if (nuevasEmpresas.length > 0) {
                      //console.log('Empresa encontrada:', nuevasEmpresas)
                      const numeroDeEmpresas = nuevasEmpresas.length
                      const porcentajeDividido = 100 / numeroDeEmpresas
                      //console.log(nuevasEmpresas)
                      nuevasEmpresas.forEach((empresa) => {
                        empresa.porcentaje = porcentajeDividido
                        this.registrarEgresoEnEmpresa(
                          empresa,
                          this.addressForm.value
                        )
                      })
                    } else {
                      console.log(
                        'No se encontró una empresa con el id especificado.'
                      )
                    }
                  }

                  const numeroDeEmpresas = nuevasEmpresas.length
                  const porcentajeDividido = 100 / numeroDeEmpresas
                  //console.log(nuevasEmpresas)
                  nuevasEmpresas.forEach((empresa) => {
                    empresa.porcentaje = porcentajeDividido
                    this.registrarEgresoEnEmpresa(
                      empresa,
                      this.addressForm.value
                    )
                  })
                })
            }
          } else {
            //console.log('no esta entreando a la funcions de enviar , Data Imagen ',this.dataArrayImg.length,
            this.dataArrayDevuelto.length
          }
        }
      }

    } else {
      this.f.idArriendo.setValue(0)
    }
  }
  /////////////////////////////////////////FIN DE MODIFICACION ISAI ///////////////////////////////////////




  verificarURL() {
    // Verificar si la URL no comienza con "http://" o "https://"
    if (
      !this.linkValue.startsWith('http://') &&
      !this.linkValue.startsWith('https://')
    ) {
      // Si no comienza con ninguno de los dos, agregar "https://"
      this.linkValue = 'https://' + this.linkValue
    }
  }
}
