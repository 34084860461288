import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { modeloCuentasBancaria } from '@app/_models/consolidadoBancario/cuentasBancaria'
import { ConsolidadoBancarioService } from '../../consolidado-bancario.service'
import { first } from 'rxjs/operators'
@Component({
  selector: 'app-home-form',
  templateUrl: './home-form.component.html',
  styleUrls: ['./home-form.component.scss']
})
export class HomeFormComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  form!: FormGroup

  //Data par registrar una cuenta
  numeroCuenta: any
  nombreCuenta: any
  rut: any
  tipoMonto: any
  totalMonto: any
  banco: any
  tamano_rut: string

  modelo: modeloCuentasBancaria = new modeloCuentasBancaria()

  constructor(
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService
  ) {}

  ngOnInit(): void {}

  infBasica = this._formBuilder.group({
    numeroCuenta: ['', [Validators.required]],
    nombreCuenta: ['', [Validators.required]],
    rut: ['', [Validators.required]],
    banco: ['', [Validators.required]],
    tipoMonto: ['', [Validators.required]],
    totalMonto: ['', [Validators.required]]
  })

  formatoRut() {
    if (this.tamano_rut.length >= 10 && this.tamano_rut.length < 11) {
      this.snackBar.open('Rut es valido', 'X', {
        duration: 2000,
        verticalPosition: 'top'
      })
    } else {
      this.snackBar.open('Rut es invalido', 'X', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }

  onSubmit() {
    this.form = this._formBuilder.group(Object.assign(this.infBasica.value))
    switch (this.form.status) {
      case 'VALID':
        this.modelo.nombreCuenta = this.form.value.nombreCuenta
        this.modelo.numeroCuenta = this.form.value.numeroCuenta
        this.modelo.rut = this.form.value.rut
        this.modelo.tipoMonto = this.form.value.tipoMonto
        this.modelo.totalMonto = this.form.value.totalMonto
        this.modelo.banco = this.form.value.banco

        this.consolidadoBancarioService
          .registrarCuentaBancaria(this.modelo)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.snackBar.open('Agrego cuenta con exito', 'cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })
              window.location.reload()
            },
            (error: any) => {
              this.snackBar.open(
                'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              this.snackBar.open(error, 'cerrar', {
                duration: 3000,
                verticalPosition: 'top'
              })
            }
          )

        break
      case 'INVALID':
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }
}
