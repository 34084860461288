import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Cesionario } from '@app/_models/cesionarios/cesionario';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  format } from 'rut.js'
import { AdministracionService } from '@app/_pages/administracion/administracion.service';
import { ConfiguracionService } from '../configuracion.service';

@Component({
  selector: 'app-configuracion-cesionarios-list',
  templateUrl: './configuracion-cesionarios-list.component.html',
  styleUrls: ['./configuracion-cesionarios-list.component.scss']
})
export class ConfiguracionCesionariosListComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()
  cesionarios : any;

    // ? table definitions cesionarios.
  displayedColumns: string[] = [
    'select',
    'id',
    'nombre',
    'rut',
    'telefono',
    'direccion',
    'contacto',
    'email',
    'estado',
    'botones'
  ]

  // Tabla en donde se almacenará los datos de la bd
  dataSource: MatTableDataSource<Cesionario> = new MatTableDataSource();
  dataCesionario: Cesionario[] = [];

  formFilter = new FormGroup({
    rut: new FormControl(),
    direccion: new FormControl(),
    descripcion: new FormControl(),
    nombre: new FormControl(),
    email: new FormControl(),
    telefono: new FormControl()
  })

  nombreEmpresa!: string;
  selection = new SelectionModel<Cesionario>(true, []);
  totalSeleccion = 0;
  selectedRows!: any[];
  sucursalesDelete!: any;

  constructor(
    private administracionService: AdministracionService,
    private configuracionService: ConfiguracionService,
    private snackBar: MatSnackBar
  ) {}
  
  ngOnInit(): void {
    this.getCesionarios();
    this.aplicarfiltros();
    this.configuracionService.getResultCesionario().subscribe(result => {
      this.getCesionarios()
    })
  }
  getCesionarios() {
      this.configuracionService
    .cesionariosGetAll()
    .pipe(first())
    .subscribe((result: Cesionario[]) => {
        this.dataCesionario = result.map((Cesionario) => {
          Cesionario.rut = format(Cesionario.rut)
          return Cesionario;
        });
        this.dataSource = new MatTableDataSource(this.dataCesionario);
        this.dataSource.paginator = this.paginator.toArray()[0];
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        });
  }

  estado(filtro:string){
    let estadoCesionarios = this.dataCesionario;
    estadoCesionarios = this.dataCesionario.filter((data: Cesionario) => data.estado == filtro)
    this.dataSource = new MatTableDataSource(estadoCesionarios);
    this.dataSource.paginator = this.paginator.toArray()[0];
  }
  // Filtros
  aplicarfiltros() {
    this.formFilter.valueChanges.subscribe((res) => {
      let dataFiltered = this.dataCesionario;
        //Filtro nombre
        if (res.nombre) {
          dataFiltered = dataFiltered.filter((data: Cesionario) =>  data.nombre == res.nombre);
        }
        //Filtro Rut
        if (res.rut) {
            dataFiltered = dataFiltered.filter((data: Cesionario) =>  data.rut == res.rut)
        }

        //Filtro direccion
        if (res.direccion) {
          dataFiltered = dataFiltered.filter((data: Cesionario) => data.direccion == res.direccion)
        }

        //Filtro contacto
        if (res.contacto) {
          dataFiltered = dataFiltered.filter((data: Cesionario) => data.contacto == res.contacto)
        }

        //Filtro email
        if (res.email) {
          dataFiltered = dataFiltered.filter((data: Cesionario) => data.email == res.email)
        }

        //Filtro telefono
        if (res.telefono) {
          dataFiltered = dataFiltered.filter((data: Cesionario) => data.telefono == res.telefono)
        }
      this.dataSource = new MatTableDataSource(dataFiltered);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.selection.clear();
      this.totalSeleccion = 0;
    })
  }

  //Limpiar los filtros
  limpiarFiltros() {
    this.formFilter.patchValue({nombre: null,rut: null,direccion: null,contacto: null,email: null,telefono: null});
    this.dataSource = new MatTableDataSource(this.dataCesionario);
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.selection.clear();
    this.totalSeleccion = 0;
  }

  //Metodo exportar excel
  exportAsXLSX(): void {
    this.selectedRows = [];
    this.selection.selected.forEach((x) => this.selectedRows.push(x));
    this.administracionService.exportAsExcelFile(this.selectedRows,'Lista-Cesionarios');
  }

  //Abrir Modal Editar
  openDialogEdit(cesionario: any, idCesionario:any) {
    localStorage.setItem('cesionario', JSON.stringify( cesionario ));
    localStorage.setItem('idCesionario', idCesionario);
    this.configuracionService.openDialogEditSucursal();
  }

  deleteCesionario(id: any, cesionario: any) {
    if (confirm('Esta seguro que desea eliminar el registro: ' + cesionario)) {
      this.configuracionService
        .delete(id)
        .pipe(first())
        .subscribe(() => {
          this.getCesionarios();
        });
      this.snackBar.open('Cesionario eliminado', 'cerrar', {
        duration: 3000,
        verticalPosition: 'top'
      });
    }
    this.getCesionarios();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
