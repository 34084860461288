
<div lass="mat-elevation-z8">
    <mat-expansion-panel [expanded]="true">
        <mat-horizontal-stepper linear>
            <mat-step [stepControl]="addressForm" completed="false">
                <mat-card-content>
                    <form [formGroup]="addressForm">
                        <ng-template matStepLabel>Formulario de nuevo Gasto Fijo</ng-template>
                        <!-- Fila 1-->
                        <div class="row">
                            <!-- Sucursales -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Sucursales" formControlName="idSucursal">
                                        <mat-option *ngFor="let s of empresa.Sucursals" value="{{ s.id }}">
                                            {{ s.razonSocial }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="addressForm.controls['idSucursal'].hasError('required')">
                                        sucursal es <strong>requrido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- Tipo Egreso -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <mat-select
                                    placeholder="Gasto Fijo"
                                    formControlName="tipoEgreso"
                                    >     
                                    <mat-option value="Internet"> Internet </mat-option>
                                    <mat-option value="Luz"> Luz</mat-option>
                                    <mat-option value="Gas"> Gas </mat-option>    
                                    <mat-option value="Internet"> Internet </mat-option>    
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <!-- Fecha -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <mat-label>Fecha de Vencimiento </mat-label>
                                    <input
                                    matInput
                                    formControlName="fecha"
                                    [matDatepicker]="picker"
                                    [readonly]="true"
                                    />
                                    <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- Fila 2-->
                        <div class="row">
                            <!-- Monto -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input
                                    matInput
                                    placeholder="Monto"
                                    formControlName="monto"
                                    type="number"
                                    />
                                </mat-form-field>
                            </div>
                            <!-- Estado de Gasto Fijo -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <mat-select
                                    placeholder="Estado Gasto Fijo"
                                    formControlName="estadoGasto"
                                    >  
                                    <mat-option value="Pendiente"> Pendiente </mat-option>

                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <!-- Descripcion -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input
                                    matInput
                                    placeholder="Descripción Gasto Fijo"
                                    formControlName="descripcionGasto"
                                    />
                                </mat-form-field>
                            </div>
                         
                        </div>

                    </form>
                </mat-card-content>
                    <!-- Botón enviar -->
                    <mat-card-actions>
                        <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">
                            <mat-icon>send</mat-icon> Enviar
                        </button>
                    </mat-card-actions>
            </mat-step>
        </mat-horizontal-stepper>        
    </mat-expansion-panel>
</div>

<br>
<table>
    <td>
    </td>
</table>