import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Categoria } from '@app/_models/categoria/categoria';
import { first } from 'rxjs/operators';
import { CategoriasModule } from '../categorias.module';
import { CategoriasService } from '../categorias.service';

@Component({
  selector: 'app-categorias-list',
  templateUrl: './categorias-list.component.html',
  styleUrls: ['./categorias-list.component.scss']
})
export class CategoriasListComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  displayedColumns: string[] = [
    'select',
    'id',
    'nombre',
    'estado',
    'botones',
  ];

    // Tabla en donde se almacenará los datos de la bd 
    dataSource: MatTableDataSource<Categoria> = new MatTableDataSource();
    dataCategoria: Categoria[] = [];

    formFilter = new FormGroup({
      nombre: new FormControl(),
      estado: new FormControl(),
    
    })

    //Declaraciones
    categorias: Categoria[] = [];
    selection = new SelectionModel<Categoria>(true, []);
    totalSeleccion = 0;
    selectedRows!: any[];
    categoriasDelete!: any;
    detallecategoria = 0;

  constructor(
    private CategoriasModule: CategoriasModule,
    private categoriasService: CategoriasService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getCategorias();
    this.aplicarfiltros();

    this.categoriasService
      .categoriaGet()
      .pipe(first())
      .subscribe(
        (categoriasDelete) => (this.categoriasDelete = categoriasDelete)
      );

      setTimeout(() => {
        this.getCategorias()
       }, 300);
  }

  getCategorias(){
  //Carga Tabla 
  this.categoriasService.categoriaGet().pipe(first()).subscribe((result: Categoria[]) => {
    this.dataCategoria = result.map(Categoria => {
      return Categoria;
    });
    this.dataSource = new MatTableDataSource(this.dataCategoria);
    this.dataSource.paginator = this.paginator.toArray()[0];
  });
  }

    // ? selection rows
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  
    // Metodo que sirve para la seleccion de un campo de la tabla
    masterToggle() {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => {
          this.selection.select(row);
  
        });
      
    }

    // Filtros
    aplicarfiltros() {
    this.formFilter.valueChanges.subscribe(res => {

    let dataFiltered = this.dataCategoria;

    //Filtro Nombre
    if (res.nombre) {
      dataFiltered = dataFiltered.filter((data: Categoria) => data.nombre == res.nombre);
    }
    //Filtro Estado
    if (res.estado) {
      dataFiltered = dataFiltered.filter((data: Categoria) => data.estado == res.estado);
    }

    this.dataSource = new MatTableDataSource(dataFiltered);
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.selection.clear()
    this.totalSeleccion = 0;
  })
}

    //Limpiar los filtros
    limpiarFiltros() {
      this.formFilter.patchValue({ nombre: null})
      this.formFilter.patchValue({ estado: null})
      this.dataSource = new MatTableDataSource(this.dataCategoria);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.selection.clear()
      this.totalSeleccion = 0;
     

      
    }

    exportAsXLSX(): void {
      this.selectedRows = [];
       this.selection.selected.forEach((x) => this.selectedRows.push(x));
       this.categoriasService.exportAsExcelFile(this.selectedRows, 'Lista-Categorias');
     }


    //Metodo eliminar una categoria
    borrarCategoria(id: any, nombre: any) {
      const categoria = this.categoriasDelete.find((x: any) => x.id === id)
      if (confirm('Esta seguro que desea eliminar el registro: ' + nombre)) {
      categoria.isDeleting = true
      this.categoriasService
        .delete(id)
        .pipe(first())
        .subscribe(() => {
          this.categorias = this.categorias.filter((x: any) => x.id !== id)
      });
        categoria.isDeleting = false;
      }
      this.snackBar.open('Categoria eliminada', 'cerrar', {
      duration: 2000,
      verticalPosition: 'top'
      });
      this.getCategorias();
  }

    //Abrir Modal Editar
    openDialogEdit(id: any, nombre: any) {
      localStorage.setItem('idCategoriasEdit', id);
      localStorage.setItem('nombreEdit', nombre);
      this.categoriasService.openDialogEditSucursal();
    }
    


}
