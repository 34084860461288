import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { IngresosImportadora } from '@app/_models/importadora/ingresoImportadora'
import { Empresa } from '@app/_models/shared/empresa'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { ImportadoraService } from '@app/_pages/importadora/importadora.service'
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-acciones-ingreso-importadora',
  templateUrl: './acciones-ingreso-importadora.component.html',
  styleUrls: ['./acciones-ingreso-importadora.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class AccionesIngresoImportadoraComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any
  empresa = new Empresa();

  dataArrayImg = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  //Modelo de Ingreso de rentacar
  modelo: IngresosImportadora = new IngresosImportadora()

  idIngresoImportadora: any
  idSucursal: any
  tipoIngreso: any
  descripcionIngreso: any
  fecha: any
  monto: any
  medioPago: any
  vendedor: any
  codigoAutorizacion: any

  nombreCliente: any
  tipoCliente: string[]
  Referencia: string[]
  tipoDocumento: string[]
  nDocumento: any
  estadoPago: string[]
  sucursal: any
  idEmpresa = 9;

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private importadoraService: ImportadoraService,
    private sucursalService: SucursalSharedService,
    private empresaService: EmpresaSharedService,
  ) {
    this.tipoCliente = ['Particular', 'Empresa']
    this.Referencia = ['Llamada', 'Booking', 'Correo', 'PaginaWeb', 'Facebook']
    this.estadoPago = ['PENDIENTE', 'PAGADO']
    this.tipoDocumento = ['BOLETA', 'FACTURA']
    this.tipoIngreso = ['VENTA NEUMÁTICOS']
    this.medioPago = ['TARJETA DE DEBITO','TARJETA DE CREDITO','TRANSFERENCIA','EFECTIVO','CHEQUE']
  }

  ngOnInit(): void {
    this.idIngresoImportadora = localStorage.getItem('idIngresoImportadora')
    this.getIngresoImportadora()
    this.getEmpresa(this.idEmpresa);
  }

  getEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x) => {
        x.Sucursals = Object.values(x.Sucursals).sort((a, b) => (a.razonSocial > b.razonSocial) ? 1 : -1);
        this.empresa = x;
      });
  }

  //Configuracion de formulario
  addressForm = this.fb.group({
    idSucursal: [null, Validators.required],
    tipoIngreso: [null, Validators.required],
    descripcionIngreso: [null, Validators.required],
    fecha: [null, Validators.required],
    monto: [null, [Validators.required]],
    medioPago: [null, Validators.required],
    vendedor: [''],
    codigoAutorizacion: [''],
    nombreCliente: [null, Validators.required],
    tipoCliente: [null, Validators.required],
    Referencia: [null, Validators.required],
    tipoDocumento: [null, Validators.required],
    nDocumento: [null, Validators.required],
    estadoPago: [null, Validators.required],
    nombreSucursal: null,
    archivos: null
  })
  get f() {
    return this.addressForm.controls
  }

  getIngresoImportadora() {
    this.importadoraService
      .getById(this.idIngresoImportadora)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idIngresoImportadora) {
          this.f.fecha.setValue(x[0].fecha.slice(0, 10))
          this.f.tipoIngreso.setValue(x[0].tipoIngreso)
          this.f.descripcionIngreso.setValue(x[0].descripcionIngreso)
          this.f.archivos.setValue(x[0].archivos)
          this.f.monto.setValue(x[0].monto.toLocaleString('es-CL'))
          this.f.medioPago.setValue(x[0].medioPago)
          this.f.vendedor.setValue(x[0].vendedor)
          this.f.codigoAutorizacion.setValue(x[0].codigoAutorizacion)
          this.f.nombreCliente.setValue(x[0].nombreCliente)
          this.f.tipoCliente.setValue(x[0].tipoCliente)
          this.f.Referencia.setValue(x[0].Referencia)
          this.f.tipoDocumento.setValue(x[0].tipoDocumento)
          this.f.nDocumento.setValue(x[0].nDocumento)
          this.f.estadoPago.setValue(x[0].estadoPago)
          this.f.idSucursal.setValue(x[0].idSucursal)
        }
        this.previsualizacion = x[0].archivos
      })
  }

  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.importadoraService.closeDialogModal()
    window.location.reload()
  }

  //Capturar imagen
  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop() // Obtener la extensión del archivo original
    const nuevoNombre = 'IgresosImportadora' + Date.now() + '.' + extension // Crear un nombre único con el identificador generado dinámicamente
    this.dataArrayImg.push(nuevoNombre) // Agregar el nuevo nombre al array de nombres de archivo
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    }) // Crear un nuevo objeto de tipo File con el nuevo nombre
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }

  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/ingresoImportadora/Upload/ImportadoraIngreso`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

  //Actualizar formulario
  onSubmit() {
    if (
      confirm('Esta seguro que desea actualizar el Ingreso de Importadora: ')
    ) {
      this.importadoraService
        .getByIdIngresoSinBase64(this.idIngresoImportadora)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idIngresoImportadora) {
            const data = x[0]
            const nombreArchivo = x[0].archivos
            const JsonNombreArchivo = JSON.parse(nombreArchivo)
            if (this.dataArrayImg[0] === undefined) {
              this.addressForm.value.archivos = JsonNombreArchivo
            } else {
              this.addressForm.value.archivos = this.dataArrayImg
            }
            this.addressForm.value.idSucursal = data.idSucursal
            this.addressForm.value.medioPago = data.medioPago
            this.addressForm.value.vendedor = data.vendedor
            this.addressForm.value.codigoAutorizacion = data.codigoAutorizacion

            this.addressForm.value.nombreCliente = data.nombreCliente
            this.addressForm.value.tipoCliente = data.tipoCliente
            this.addressForm.value.Referencia = data.Referencia
            this.addressForm.value.tipoDocumento = data.tipoDocumento
            this.addressForm.value.nDocumento = data.nDocumento
            this.addressForm.value.estadoPago = data.estadoPago
            this.importadoraService
              .updateIngresoImportadora(
                this.idIngresoImportadora,
                this.addressForm.value
              )
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(
                    'Ingreso de Importadora editado con éxito.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )

                  this.addressForm.reset()
                  this.importadoraService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el Ingreso de Importadora, favor contactar a informática.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }

  //Actualizar formulario
  AgregarIMG() {
    if (
      confirm('Esta seguro que desea agregar IMG el Ingreso de Importadora: ')
    ) {
      this.importadoraService
        .getByIdIngresoSinBase64(this.idIngresoImportadora)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idIngresoImportadora) {
            const data = x[0]
            this.addressForm.value.idSucursal = data.idSucursal
            this.addressForm.value.tipoIngreso = data.tipoIngreso
            this.addressForm.value.descripcionIngreso = data.descripcionIngreso
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.monto = data.monto
            this.addressForm.value.medioPago = data.medioPago
            this.addressForm.value.vendedor = data.vendedor
            this.addressForm.value.codigoAutorizacion = data.codigoAutorizacion

            this.addressForm.value.nombreCliente = data.nombreCliente
            this.addressForm.value.tipoCliente = data.tipoCliente
            this.addressForm.value.Referencia = data.Referencia
            this.addressForm.value.tipoDocumento = data.tipoDocumento
            this.addressForm.value.nDocumento = data.nDocumento
            this.addressForm.value.estadoPago = data.estadoPago
            this.addressForm.value.archivos = this.dataArrayImg
            this.importadoraService
              .updateIngresoImportadora(
                this.idIngresoImportadora,
                this.addressForm.value
              )
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(
                    'Ingreso de Importadora editado con éxito.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )

                  this.addressForm.reset()
                  this.importadoraService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el Ingreso de Importadora, favor contactar a informática.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }
}
