import { Component, OnInit } from '@angular/core';
import { DialogContratosComponent } from './dialog-contratos/dialog-contratos.component';
import { MatDialog } from '@angular/material/dialog';
import { AbogadosService } from '../../abogados.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Cliente } from '@app/_models/abogados/cliente';
import { Usuario } from '@models/shared/usuario';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { AbogadosTabsService } from '../../abogados-tabs.service';
//import { AbogadosTabsService } from '../abogados/abogados-tabs.service';
//import { DialogMostrarContratosComponent } from '/Users/grupofirma/Documents/GitHub/client-angular-finanzas2/src/app/_pages/abogados/abogados-ingresos/abogados-ingresos-acciones/dialog-mostrar-contratos/dialog-mostrar-contratos.component';





@Component({
  selector: 'app-abogados-ingresos-acciones',
  templateUrl: './abogados-ingresos-acciones.component.html',
  styleUrls: ['./abogados-ingresos-acciones.component.scss']
})
export class AbogadosIngresosAccionesComponent implements OnInit {

  validacion: boolean = false;
  
  nombreClienteLocal : any;
  addressForm: FormGroup;
  cliente: Cliente;
  elPadreDice: any;
  idCliente: string;
  causasCliente: any;
  rutClienteModel: string;
 
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  constructor(
    public dialog:MatDialog,
    private abogadosService: AbogadosService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder ,
    private abogadosTabsService: AbogadosTabsService,
    
    ) { 
      this. addressForm = this.fb.group({
        rutCliente: ['', Validators.required],
        
      });
    }
    
    
    

  ngOnInit(): void {
    
    

    localStorage.removeItem("nombreCliente");
    localStorage.removeItem("idCliente");
    localStorage.removeItem("idContratoPago");

  }
  
  
  //Metodo que hace aparecer el componente dialog-mostrar-contratos
  // activarTablaContratos(){
  //   //Se captura el valor del LocalStorage
  //   this.nombreClienteLocal = localStorage.getItem("nombreCliente");
  //   //Pregunta si el localStorage Existe
  //   if(this.nombreClienteLocal != null){
  //     // Si existe se muestra el componente
  //     this.validacion = true;
  //   }
  //   else{
  //     // Si no existe muestra un mensaje
  //     this.snackBar.open('Por favor seleccione un cliente, en el formulario', 'cerrar', {
  //       duration: 2000,
  //       verticalPosition: 'top',
  //     });
  //   }
  // }

  //Este metodo oculta el componente dialog-mostrar-contratos
  // desactivarTablaContratos(){
  //   this.validacion = false;
  //   //Se eliminan los localStorage
  //   localStorage.removeItem("nombreCliente");
  //   localStorage.removeItem("idCliente");
  //   localStorage.removeItem("idContratoPago");
  // }

  //Este metodo abre el dialog-contratos (Modal)
  openDialogContratos():void{
    //Se captura el valor del LocalStorage
    this.nombreClienteLocal = localStorage.getItem("nombreCliente");
    //Pregunta si el localStorage Existe
    if(this.nombreClienteLocal){
      // Si existe se abre el dialog
    this.abogadosService.openDialogContratos();
    }
    else{
      // Si no existe muestra un mensaje
      this.snackBar.open('Por favor seleccione un cliente, en la barra de Busqueda', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top',
      });
    }
  }
  realizarAccion(): void {
    console.log('Formulario completo:', this.addressForm.value);
    const rutCliente = this.addressForm.controls['rutCliente'].value as string || localStorage.getItem('rutCliente');
    console.log('RUT Cliente:', rutCliente);
    console.log(rutCliente)
    // Verifica que el RUT no sea vacío o indefinido
    // if (!rutCliente) {
    //   this.snackBar.open('Por favor, ingresa un RUT válido', 'cerrar', {
    //     duration: 2000,
    //     verticalPosition: 'top',
    //   });
    //   return;
    // }
  
    this.abogadosTabsService.buscarPorRut(rutCliente).subscribe(cliente => {
      if (cliente) {
        this.snackBar.open('El RUT existe en la base de datos', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
      } else {
        this.snackBar.open('El RUT no existe en la base de datos', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
      }
    }, error => {
      this.snackBar.open('Error al verificar el RUT', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top',
      });
    });
  }
 

get f() {
  return this.addressForm.controls;
}
contratos(cliente):void{
  this.abogadosTabsService.obtenerContratosCliente(cliente.id).subscribe(
    (contratos: any) => {
      this.causasCliente = contratos;
    },
    (error) => {
      console.error('Error al obtener los contratos del cliente:', error);
      this.snackBar.open('Error al obtener los contratos del cliente', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top',
      });
    }
  );
}


activarTablaContratos(cliente: any): void {
  this.nombreClienteLocal = localStorage.getItem("nombreCliente");

  // Actualiza siempre los datos de la tabla al activar
  if (this.nombreClienteLocal != null) {
    this.validacion = true;

    //los datos de la tabla con los nuevos contratos
    this.causasCliente = cliente.contratos;  
    // 
  } else {
    // Si no hay cliente, desactiva la tabla y muestra un mensaje
    this.desactivarTabContratos();
    this.snackBar.open('Por favor seleccione un cliente, en la barra de búsqueda', 'Cerrar', {
      duration: 2000,
      verticalPosition: 'top',
    });
  }
}

buscarEx(): void {
  this.validacion = false;
  const rutFormateado = this.formateaRut(this.f.rutCliente.value);
  console.log(rutFormateado);

  this.abogadosTabsService.buscarPorRut(rutFormateado).subscribe(
    (cliente) => {  
      console.log(cliente);
      
      if (cliente && cliente.id) {
        console.log(this.validacion)
        
        this.desactivarTabContratos();

        localStorage.setItem("idCliente", cliente.id.toString());
        localStorage.setItem("nombreCliente", cliente.nombre);
        
        // Activa y actualiza la tabla con los nuevos contratos del cliente
        this.activarTablaContratos(cliente);

        this.snackBar.open('RUT encontrado. Presione "Mostrar Contratos" para ver los contratos o "Crear Nuevo Contrato".', 'Cerrar', {
          duration: 5000,  // Duración en milisegundos
          verticalPosition: 'top',
        });

        this.elPadreDice = cliente;
       
        
      } else {
        this.desactivarTabContratos();
        this.addressForm.reset();
        console.log('Cliente no encontrado');
        this.snackBar.open('Cliente no encontrado', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
      }
    },
    (error) => {
      if (error.status === 404) {
        this.desactivarTabContratos();
        // this.addressForm.reset();
        console.log('Cliente no encontrado');
        this.snackBar.open('Cliente no encontrado', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
      } else {
        console.error('Error inesperado al buscar el cliente:', error);
        this.snackBar.open('Error inesperado al buscar el cliente', 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top',
        });
      }
    }
  );
}

desactivarTabContratos(): void {

  this.validacion = false;
  localStorage.removeItem("nombreCliente");
  localStorage.removeItem("idCliente");
  localStorage.removeItem("idContratoPago");
  this.causasCliente = [];  // Limpia la tabla al desactivarla
}

formateaRut(rut: string): string {
  return this.abogadosService.formateaRut(rut);
}



}
