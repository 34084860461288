
import { Producto } from '../productos/producto';
export class Variante{
    id!: number;
    nombreVariante!: string;
    codigoSKUVariante!: string;
    codigoBarraVariante!: string;
    estadoVariante!: string;
    unitarioVariante!: number;
    netoVariante!: number;
    stockVariante!: number;
    idProducto!: number;
    descripcionVariante!: string;
}