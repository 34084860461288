import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthComponent } from './_components/auth/auth.component'
import { HomeMainComponent } from './_components/home-main/home-main.component'
import { AuthGuard } from './_helpers/auth.guard'
import { DialogStockActualComponent } from './_pages/stockActual/stockActual-home/stockActual-list/dialog-stockActual/dialog-stockActual.component'

// * Modules Imported for Route Main components
const hostalModule = () =>
  import('./_pages/hostal/hostal.module').then((x) => x.HostalModule)
const lubricentroModule = () =>
  import('./_pages/lubricentro/lubricentro.module').then(
    (x) => x.LubricentroModule
  )
const inmobiliariaModule = () =>
  import('./_pages/inmobiliaria/inmobiliaria.module').then(
    (x) => x.InmobiliariaModule
  )
const rentacarModule = () =>
  import('./_pages/rentacar/rentacar.module').then((x) => x.RentacarModule)
const agroFirmaModule = () =>
  import('./_pages/agroFirma/agro-firma.module').then((x) => x.AgroFirmaModule)
const abogadosModule = () =>
  import('./_pages/abogados/abogados.module').then((x) => x.AbogadosModule)
const administracionModule = () =>
  import('./_pages/administracion/administracion.module').then(
    (x) => x.AdministracionModule
  )
const exportadoraModule=()=> //FelipeMod
  import('./_pages/exportadora/exportadora.module').then(
    (x)=> x.ExportadoraModule)

const importadoraModule = () =>
  import('./_pages/importadora/importadora.module').then(
    (x) => x.ImportadoraModule
  )
const prestamosModule = () =>
  import('./_pages/prestamos/prestamos.module').then((x) => x.PrestamosModule)

  /* Mendex */
const gastoPersonalModule = () =>
  import('./_pages/gasto-personal/gasto-personal.module').then(
    (x) => x.GastoPersonalModule
  )


const facturaModule = () =>
  import('./_pages/factura/factura-module.module').then((x) => x.FacturaModule)
const consolidadosModule = () =>
  import('./_pages/consolidados/consolidados.module').then(
    (x) => x.ConsolidadosModule
  )
const egresosFirmaGeneralModule = () =>
  import('./_pages/egresosFirmaGeneral/egresosFirmaGeneral.module').then(
    (x) => x.EgresosFirmaGeneralModule
  )
const proveedoresModule = () =>
  import('./_pages/proveedores/proveedores.module').then(
    (x) => x.ProveedoresModule
  )
const clientesModule = () =>
  import('./_pages/clientes/clientes.module').then((x) => x.ClientesModule)
const comprasModule = () =>
  import('./_pages/compras/compras.module').then((x) => x.ComprasModule)
const folioModule = () =>
  import('./_pages/folio/folio.module').then((x) => x.FolioModule)
const productosModule = () =>
  import('./_pages/productos/productos.module').then((x) => x.ProductosModule)
const cotizacionModule = () =>
  import('./_pages/cotizaciones/cotizaciones.module').then(
    (x) => x.CotizacionesModule
  )
const recaudacionModule = () =>
  import('./_pages/recaudacion/recaudacion.module').then(
    (x) => x.RecaudacionModule
  )
const estadoDTEModule = () =>
  import('./_pages/estado-dte/estado-dte.module').then((x) => x.EstadoDTEModule)
const facturasModule = () =>
  import('./_pages/facturas/facturas.module').then((x) => x.FacturasModule)
const boletasModule = () =>
  import('./_pages/boletas/boletas.module').then((x) => x.BoletasModule)
const cesionesModule = () =>
  import('./_pages/cesiones/cesion.module').then((x) => x.CesionModule)
const notaDebitoModule = () =>
  import('./_pages/notas-debito/notas-debito.module').then(
    (x) => x.NotasDebitoModule
  )
const notaCreditoModule = () =>
  import('./_pages/notas-credito/notas-credito.module').then(
    (x) => x.NotasCreditoModule
  )
const ajusteInventarioModule = () =>
  import('./_pages/ajusteInventario/ajusteInventario.module').then(
    (x) => x.AjusteInventarioModule
  )

const consolidadoBancarioModule = () =>
  import('./_pages/consolidado-bancario/consolidado-bancario.module').then(
    (x) => x.ConsolidadoBancarioModule
  )

const categoriasModule = () =>
  import('./_pages/categorias/categorias.module').then(
    (x) => x.CategoriasModule
  )
const confirguracionModule = () =>
  import('./_pages/configuracion/configuracion.module').then(
    (x) => x.ConfiguracionModule
  )
const inventarioModule = () =>
  import('./_pages/inventario/inventario.module').then(
    (x) => x.InventarioModule
  )
const stockActualModule = () =>
  import('./_pages/stockActual/stockActual.module').then(
    (x) => x.StockActualModule
  )

const registroVentasModule = () =>
  import('./_pages/registro-ventas/registro-ventas.module').then(
    (x) => x.RegistroVentasModule
  )

const guiaDespachoModule = () =>
  import('./_pages/guiaDespacho/guia-despacho.module').then(
    (x) => x.GuiaDespachoModule
  )
const garridoProyectoModule = ()=>import('./_pages/garrido-proyectos/garrido-proyectos.module')
  .then((x)=> x.GarridoProyectoModule)

const autosYaModule = ()=>import('./_pages/autos-ya/autos-ya.module').then((x)=>x.AutosYaModule)
  
const routes2:Routes =[
  { path: 'hostal', 
  canActivate: [AuthGuard], 
  loadChildren: hostalModule, 
  // runGuardsAndResolvers: 'always' 
},
  {
    path: 'lubricentro',
    canActivate: [AuthGuard],
    loadChildren: lubricentroModule
  },
]
const routes: Routes = [
  { path: '', canActivate: [AuthGuard], component: HomeMainComponent },
  { path: 'auth/:token', component: AuthComponent },
  { path: 'home', canActivate: [AuthGuard], component: HomeMainComponent },

  {
    path: 'lubricentro',
    canActivate: [AuthGuard],
    loadChildren: lubricentroModule
  },
  {
    path: 'inmobiliaria',
    canActivate: [AuthGuard],
    loadChildren: inmobiliariaModule
  },
  { path: 'rentacar', canActivate: [AuthGuard], loadChildren: rentacarModule },
  {
    path: 'agrofirma',
    canActivate: [AuthGuard],
    loadChildren: agroFirmaModule
  },
  {
    path: 'firmaAbogado',
    canActivate: [AuthGuard],
    loadChildren: abogadosModule
  },
  {
    path: 'administracion',
    canActivate: [AuthGuard],
    loadChildren: administracionModule
  },
  {
    //FelipeMod
    path: 'exportadora',
    canActivate: [AuthGuard],
    loadChildren: exportadoraModule
  },

  {
    path: 'importadora',
    canActivate: [AuthGuard],
    loadChildren: importadoraModule
  },
  {
    path: 'prestamos',
    canActivate: [AuthGuard],
    loadChildren: prestamosModule
  },
  {
    path: 'gasto-personal',
    canActivate: [AuthGuard],
    loadChildren: gastoPersonalModule
  },

  { path: 'sii', canActivate: [AuthGuard], loadChildren: facturaModule },
  {
    path: 'consolidadostotal',
    canActivate: [AuthGuard],
    loadChildren: consolidadosModule
  },
  {
    path: 'egresosFirmaGeneral',
    canActivate: [AuthGuard],
    loadChildren: egresosFirmaGeneralModule
  },

  {
    path: 'proveedores',
    canActivate: [AuthGuard],
    loadChildren: proveedoresModule
  },
  { path: 'clientes', canActivate: [AuthGuard], loadChildren: clientesModule },
  { path: 'compras', canActivate: [AuthGuard], loadChildren: comprasModule },
  { path: 'folio', canActivate: [AuthGuard], loadChildren: folioModule },
  {
    path: 'productos',
    canActivate: [AuthGuard],
    loadChildren: productosModule
  },
  {
    path: 'categorias',
    canActivate: [AuthGuard],
    loadChildren: categoriasModule
  },
  {
    path: 'cesionarios',
    canActivate: [AuthGuard],
    loadChildren: confirguracionModule
  },
  { path: 'bodega', canActivate: [AuthGuard], loadChildren: inventarioModule },
  { path: 'stock', canActivate: [AuthGuard], loadChildren: stockActualModule },
  {
    path: 'inventario',
    canActivate: [AuthGuard],
    loadChildren: inventarioModule
  },
  {
    path: 'cotizaciones',
    canActivate: [AuthGuard],
    loadChildren: cotizacionModule
  },
  { path: 'facturas', canActivate: [AuthGuard], loadChildren: facturasModule },
  { path: 'boletas', canActivate: [AuthGuard], loadChildren: boletasModule },

  {
    path: 'cesiones-factoring',
    canActivate: [AuthGuard],
    loadChildren: cesionesModule
  },

  {
    path: 'notaDebito',
    canActivate: [AuthGuard],
    loadChildren: notaDebitoModule
  },
  {
    path: 'notaCredito',
    canActivate: [AuthGuard],
    loadChildren: notaCreditoModule
  },
  {
    path: 'ajusteInventario',
    canActivate: [AuthGuard],
    loadChildren: ajusteInventarioModule
  },
  {
    path: 'solicitudBancaria',
    canActivate: [AuthGuard],
    loadChildren: consolidadoBancarioModule
  },
  {
    path: 'formCuentaBancaria',
    canActivate: [AuthGuard],
    loadChildren: consolidadoBancarioModule
  },
  {
    path: 'solicitudOrdenCompra',
    canActivate: [AuthGuard],
    loadChildren: consolidadoBancarioModule
  },
  {
    path: 'pagoOrdenCompra',
    canActivate: [AuthGuard],
    loadChildren: consolidadoBancarioModule
  },
  {
    path: 'consolidado-bancario-graf',
    canActivate: [AuthGuard],
    loadChildren: consolidadoBancarioModule
  },
  {
    path: 'consolidado-diario-pago-gasto',
    canActivate: [AuthGuard],
    loadChildren: consolidadoBancarioModule
  },

  {
    path: 'gastos-fijos',
    canActivate: [AuthGuard],
    loadChildren: consolidadoBancarioModule
  },
  {
    path: 'EstadoDTE',
    canActivate: [AuthGuard],
    loadChildren: estadoDTEModule
  },
  {
    path: 'recaudacion',
    canActivate: [AuthGuard],
    loadChildren: recaudacionModule
  },
  {
    path: 'registroVentas',
    canActivate: [AuthGuard],
    loadChildren: registroVentasModule
  },
  {
    path: 'despacho',
    canActivate: [AuthGuard],
    loadChildren: guiaDespachoModule
  },
  {
    path: 'garrido-proyectos',
    canActivate: [AuthGuard],
    loadChildren: garridoProyectoModule
  },
  {
    path:'autos-ya',
    canActivate:[AuthGuard],
    loadChildren: autosYaModule
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // relativeLinkResolution: 'legacy',
      useHash: true
    }),
    RouterModule.forRoot(routes2, {
      // relativeLinkResolution: 'legacy',
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
