import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ConsolidadoBancarioSharedService {
  private recargarTablaSubject = new Subject<void>()

  recargarTabla$ = this.recargarTablaSubject.asObservable()

  constructor() {}

  recargarTabla() {
    this.recargarTablaSubject.next()
  }
}
