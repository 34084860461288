<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Actualizar IMG 📃 ">
    <form [formGroup]="addressForm" novalidate (ngSubmit)="AgregarIMG()">
      <mat-card class="shipping-card">
        <!-- Informacion IMG -->
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>Informacion IMG </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <!-- Capturar img -->
              <div>
                <input mat-stroked-button (change)="capturarFile($event)" type="file" />
              </div>
              <br />
            </div>
          </div>
          <br />
          <h3>Imagen Previsualizacion</h3>
          <hr />
          <div class="row">
            <!-- Preview img -->
            <div class="col" *ngIf="previsualizacion">
              <img width="400px" [src]="previsualizacion" alt="img" />
            </div>
          </div>
          <hr />
        </mat-expansion-panel>

        <mat-card-actions>
          <button mat-stroked-button color="primary" type="submit" [disabled]="loading" (click)="subirIMG()"
            [disabled]="clickButton" (click)="AgregarIMG()">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>

  <mat-tab label="Comprobante 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()">
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>

  <mat-tab label="Editar 📝">
    <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form [formGroup]="addressForm" novalidate class="mat-elevation-z10">
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Editar Cliente</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Informacion Basica -->
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Basica </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- informacion fila uno -->
            <div class="row">
              <!-- fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Fecha" formControlName="fecha" required />
                  <mat-icon matSuffix>event_18dp</mat-icon>
                  <mat-error *ngIf="addressForm.controls['fecha'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- empresaS -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="empresaS" formControlName="empresaS" required />
                  <mat-icon matSuffix>account_balance_18dp</mat-icon>
                  <mat-error *ngIf="
                      addressForm.controls['empresaS'].hasError('required')
                    ">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- empresaD -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="empresaD" formControlName="empresaD" />
                  <mat-icon matSuffix>edit_18dp</mat-icon>
                  <mat-error *ngIf="
                      addressForm.controls['empresaD'].hasError(
                        'required'
                      )
                    ">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- monto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="monto" formControlName="monto" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['monto'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- informacion fila uno -->
            <div class="row">
              <!-- bancoS -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="bancoS" formControlName="bancoS" required />
                  <mat-icon matSuffix>event_18dp</mat-icon>
                  <mat-error *ngIf="addressForm.controls['bancoS'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- tipoPago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="tipoPago" formControlName="tipoPago" required />
                  <mat-icon matSuffix>account_balance_18dp</mat-icon>
                  <mat-error *ngIf="
                          addressForm.controls['tipoPago'].hasError('required')
                        ">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- responsable -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="responsable" formControlName="responsable" />
                  <mat-icon matSuffix>edit_18dp</mat-icon>
                  <mat-error *ngIf="
                          addressForm.controls['responsable'].hasError(
                            'required'
                          )
                        ">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- bancoD -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="bancoD" formControlName="bancoD" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['bancoD'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- descripcion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="descripcion" formControlName="descripcion" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['descripcion'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button color="primary" type="submit" [disabled]="loading"
           (click)="onSubmit()">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>

</mat-tab-group>
