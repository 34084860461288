import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consolidado-bancario-graf',
  templateUrl: './consolidado-bancario-graf.component.html',
  styleUrls: ['./consolidado-bancario-graf.component.scss']
})
export class ConsolidadoBancarioGrafComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
