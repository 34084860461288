import { Component, OnInit, QueryList, ViewChildren, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AjusteInventario } from '@app/_models/ajusteInventario/ajusteInventario';
import { AjusteProducto } from '@app/_models/ajusteProducto/ajusteProducto';
import { BodegaService } from '@app/_pages/inventario/bodega/bodega.service';
import { ProveedoresService } from '@app/_pages/proveedores/proveedores.service';
import { first } from 'rxjs/operators';
import { AjusteInventarioService } from '../../ajusteInventario.service';
import { ProductosService } from '../../../productos/productos.service';
import { Producto } from '@app/_models/productos/producto';
import { AjusteProductoService } from '@app/_pages/ajusteProducto/ajusteProducto.service';

@Component({
  selector: 'app-dialog-ajuste-inventario-ver',
  templateUrl: './dialog-ajuste-inventario-ver.component.html',
  styleUrls: ['./dialog-ajuste-inventario-ver.component.scss']
})
export class DialogAjusteInventarioVerComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  dataSource: MatTableDataSource<AjusteProducto> = new MatTableDataSource();

  idAjusteInventario : any;
  bodegas : any;
  proveedores : any;
  dataAjusteInventario: any;
  dataNuevo : any;
  dataNuevo2: any;
  dataNuevo3 : any;
  data:any;
  nombreBodega:any;
  nombreProveedor:any;
  fecha : any;
  observacion: any;
  numeroDocumento : any;
  productos: Producto[] = [];
  dataAjuste: AjusteProducto[] = [];
  ide: number;

  constructor(
    private ajusteInventarioService: AjusteInventarioService,
    private fb: FormBuilder,
    private bodegasService: BodegaService,
    private proveedoresService: ProveedoresService,
    private productosService: ProductosService,
    private ajusteProductoService: AjusteProductoService
  ) { }

  displayedColumns: string[] = [
    'nombre',
    'ubicacion',
    'cantidad',
    'costo',
    'subtotal',
  ];

  formFilter = new FormGroup({
    nombre: new FormControl(),
    ubicacion: new FormControl(),
    cantidad: new FormControl(),
    costo: new FormControl(),
    subtotal: new FormControl(),   
    })

  addressForm = this.fb.group({
    fecha: [''],
    documento: [''],
    bodega: [''],
    observacion: [''],
    proveedor: ['']
  });

  ngOnInit(): void {
    this.idAjusteInventario = localStorage.getItem("idAjusteInventario");
    console.log(this.idAjusteInventario);
    this.obtenerProductosAjuste(this.idAjusteInventario);
    this.obtenerAjusteInventario(this.idAjusteInventario);
    
    //this.getProductos();
  }

  obtenerAjusteInventario(id : number){
    this.ajusteInventarioService.traerConsulta(id).subscribe((res: any) => {
      this.data= res;
      this.nombreBodega = this.data[0].nombre;
      this.fecha = this.data[0].fechaEmision;
      this.numeroDocumento = this.data[0].numeroDocumento;
      this.observacion = this.data[0].observacion;
    })
  }

  obtenerProductosAjuste(ide : number )
  {
    
    this.ajusteProductoService.traerAjustes(ide).subscribe((res: any) => {
      this.dataNuevo= res
      this.dataNuevo3 = this.dataNuevo.length
      this.dataSource = new MatTableDataSource(this.dataNuevo);
    })
    
  }


  getBodegas(){
    this.bodegasService
    .bodegasGetAll()
    .pipe(first())
    .subscribe((bodegas) => {
      this.bodegas = bodegas;
    });
  }

  getProveedores(){
    this.proveedoresService
    .getAllProveedores()
    .pipe(first())
    .subscribe((proveedores) => {
      this.proveedores = proveedores;
    });
  }

  //Cerrar Modal
  closeDialog(){
    this.ajusteInventarioService.closeDialogModal();
  }

  onSubmit() {

  }

  get f() {
    return this.addressForm.controls;
  }
 
}
