<button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
    <mat-icon>highlight_off</mat-icon>
</button>
<form [formGroup]="form" novalidate (ngSubmit)="onSubmit()" class="mat-elevation-z10">
    <mat-card class="shipping-card">
        <mat-card-header>
        
        </mat-card-header>
        <br><br>
        <mat-card-content>

            <div class="row">
                <!-- Nombre -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Nombre" formControlName="nombre" />
                        <mat-error *ngIf="form.controls['nombre'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
       
            </div>

            <div class="row">
                <!-- Dirección -->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Dirección" formControlName="direccion" />
                        <mat-error *ngIf="form.controls['direccion'].hasError('required')">
                            este campo es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- Estados -->
            <div class="row">
                <div class="col">
                    <section class="example-section col-estado-bodega" >
                        <h4>Vigencia</h4>
                        <mat-radio-group formControlName="estado">
                            <mat-radio-button value="1">Vigente</mat-radio-button>
                            <mat-radio-button value="0">No Vigente</mat-radio-button>
                          </mat-radio-group>
                          <!-- {{ addressForm.value | json }} -->
                      </section>
                </div>
            </div>
        </mat-card-content>

        <!-- botón enviar -->
        <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon> Enviar
        </button>
    </mat-card>
</form>