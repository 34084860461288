<div class="mat-elevation-z8">
  <mat-card>
    <mat-toolbar color="primary">
      <span>Cuentas Bancaria</span>
      <button
        style="margin-left: 1rem; margin-bottom: 3px"
        mat-raised-button
        color="accent"
        (click)="actualizar()"
      >
        Actualizar tabla <mat-icon>autorenew_white_18dp</mat-icon>
      </button>
      <br />

      <button
        mat-raised-button
        color="accent"
        style="margin-left: 1rem; margin-bottom: 3px"
        (click)="exporter.exportTable('csv')"
      >
        Exportar a Excel <mat-icon>description_white_18dp</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card>
  <mat-form-field appearance="standard">
    <mat-label>Buscar</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ingresa Nombre"
      #input
    />
  </mat-form-field>

  <br />
  <table
  mat-table
  matTableExporter
  [dataSource]="dataSource"
  #exporter="matTableExporter"
  matSort
>
  <!-- id Columna -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <!-- Nombre  Columna -->
  <ng-container matColumnDef="nombreCuenta">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
    <td mat-cell *matCellDef="let element">
      {{ element.nombreCuenta }}
    </td>
  </ng-container>

  <!-- rut Columna -->
  <ng-container matColumnDef="rut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut</th>
    <td mat-cell *matCellDef="let element">
      {{ element.rut }}
    </td>
  </ng-container>

  <!-- Numero Cuenta Columna -->
  <ng-container matColumnDef="numeroCuenta">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Numero Cuenta</th>
    <td mat-cell *matCellDef="let element">
     {{ element.numeroCuenta }}
    </td>
  </ng-container>

  <!-- tipoMonto Columna -->
  <ng-container matColumnDef="tipoMonto">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Monto</th>
    <td mat-cell *matCellDef="let element">
      {{ element.tipoMonto }}
    </td>
  </ng-container>

  <!-- banco Columna -->
  <ng-container matColumnDef="banco">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Banco</th>
    <td mat-cell *matCellDef="let element">
      {{ element.banco }}
    </td>
  </ng-container>

  <!-- totalMonto Columna -->
  <ng-container matColumnDef="totalMonto">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Monto</th>
    <td mat-cell *matCellDef="let element">
      <span matPrefix>$&nbsp;</span>{{ element.totalMonto }}
    </td>
  </ng-container>

  <!-- Acciones Columna -->
  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef style="text-align: center">
      Acciones
    </th>
    <td mat-cell *matCellDef="let element">
      <button
        mat-mini-fab
        color="primary"
        style="margin-left: 1rem; margin-bottom: 2px"
        (click)="accionesFormCuentaBancaria(element.id)"
      >
        <mat-icon>save_as_white_18dp</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

  <mat-paginator
    [pageSizeOptions]="[10, 20, 30]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
