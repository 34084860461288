<mat-dialog-content>
    <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
        <mat-icon>highlight_off</mat-icon>
    </button>
    <h1>Detalle por empresa</h1>
    <mat-toolbar color="primary">
        <!-- <span> Prestamos</span>         -->
        <span> {{nombreEmpresa}}</span>        
        <button mat-raised-button color="accent" style="margin-left: 1rem" (click)="limpiarFiltros()">
            Limpiar Filtros 
        </button>
    </mat-toolbar>    

    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">
            <!-- check column -->

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            
            <!-- data columns -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID </th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="fecha" class="mat-column-date">
                <th mat-header-cell *matHeaderCellDef class="limit-width-180">
                    <mat-form-field appearance="fill" class="limit-width-180">
                        <mat-label>Fecha de Pago</mat-label>
                        <mat-date-range-input [formGroup]="formFilter" [rangePicker]="picker">
                            <input matStartDate formControlName="start" placeholder="Start date" />
                            <input matEndDate formControlName="end" placeholder="End date" />
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>

                        <mat-error *ngIf="formFilter.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                        <mat-error *ngIf="formFilter.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                    </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.fecha | date: 'dd/MM/yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="monto">
                <th mat-header-cell *matHeaderCellDef> Monto Cuota</th>
                <td mat-cell *matCellDef="let element">                    
                    {{ element.monto | currency: "$":"symbol":"1.0-0" }}
                </td>
            </ng-container>                      

            <ng-container matColumnDef="empresaD">
                <th mat-header-cell *matHeaderCellDef>
                    <div [formGroup]="formFilter">
                        <mat-form-field class="fill" style="padding-right: 10px;">
                            <input matInput placeholder="Empresa" formControlName="empresaD" />
                        </mat-form-field>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.empresaD }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No existen coincidencias</td>
            </tr>
        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>
</mat-dialog-content>