<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Actualizar IMG 📃 ">
    <form [formGroup]="addressForm" novalidate (ngSubmit)="AgregarIMG()">
      <mat-card class="shipping-card">
        <!-- Informacion IMG -->
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>Informacion IMG </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <!-- Capturar img -->
              <div>
                <input mat-stroked-button (change)="capturarFile($event)" type="file" />
              </div>
              <br />
            </div>
          </div>
          <br />
          <h3>Imagen Previsualizacion</h3>
          <hr />
          <div class="row">
            <!-- Preview img -->
            <div class="col" *ngIf="previsualizacion">
              <img width="400px" [src]="previsualizacion" alt="img" />
            </div>
          </div>
          <hr />
        </mat-expansion-panel>

        <mat-card-actions>
          <button mat-stroked-button color="primary" type="submit" [disabled]="loading" (click)="subirIMG()"
            [disabled]="clickButton" (click)="AgregarIMG()">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="Comprobante 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()">
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="Editar 📝">
    <button color="accent" class="cerrar" (click)="closeDialog()" title="Cerrar">
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form [formGroup]="addressForm" novalidate class="mat-elevation-z10">
      <mat-card class="shipping-card">
        <mat-card-header>
          <h1>Editar Cliente</h1>
        </mat-card-header>

        <mat-card-content>
          <!-- Informacion Basica -->
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion Basica </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- informacion fila uno -->

            <div class="row">
               <!-- fecha -->
            <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Fecha"
                    [matDatepicker]="picker"
                    (click)="picker.open()"
                    required
                    formControlName="fecha"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error>
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Sucursales -->
              <!-- <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Sucursales"
                    formControlName="idSucursal"
                  >
                  <mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">
                    {{ sucursal.razonSocial }}
                  </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['idSucursal'].hasError('required')
                    "
                  >
                    Este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div> -->
              <!-- Servicios  -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder=" Tipo Servicios" formControlName="Servicios" />
                  <mat-icon matSuffix>edit_18dp</mat-icon>
                  <mat-error *ngIf="
                      addressForm.controls['Servicios'].hasError(
                        'required'
                      )
                    ">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- Nombre Cliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Ingrese nombre cliente" formControlName="cliente" required />
                  <mat-icon matSuffix>event_18dp</mat-icon>
                  <mat-error *ngIf="addressForm.controls['cliente'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Tipo Cliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Cliente"
                    formControlName="tipoCliente"
                  >
                    <mat-option
                      *ngFor="let tipo of tiposCliente"
                      [value]="tipo"
                    >
                      {{ tipo }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoCliente'].hasError('required')
                    "
                  >
                    Tipo_Cliente es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Referencias -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Referencia"
                    formControlName="referencia"
                  >
                    <mat-option
                      *ngFor="let referencia of referencias"
                      [value]="referencia"
                    >
                      {{ referencia }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['referencia'].hasError('required')
                    "
                  >
                    Este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- Tipo Documento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Documento"
                    formControlName="tipoDocumento"
                  >
                    <mat-option
                      *ngFor="let documento of tiposDocumentos"
                      [value]="documento"
                    >
                      {{ documento }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoDocumento'].hasError('required')
                    "
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- N° Documento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="N° documento" formControlName="nDocumento" required />
                  <mat-icon matSuffix>event_18dp</mat-icon>
                  <mat-error *ngIf="addressForm.controls['nDocumento'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Estado Pago"
                    formControlName="estadoPago"
                  >
                    <mat-option
                      *ngFor="let estadoPago of estadoPagos"
                      [value]="estadoPago"
                    >
                      {{ estadoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['estadoPago'].hasError('required')
                    "
                  >
                    estadoPago es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- monto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Monto" formControlName="monto" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="addressForm.controls['monto'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Tipo Pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Pago"
                    formControlName="tipoPago"
                  >
                    <mat-option
                      *ngFor="let tipoPago of tiposPagos"
                      [value]="tipoPago"
                    >
                      {{ tipoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    "
                  >
                    estadoPago es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- N° Documento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="N° Autorizacion/N° Papelera" formControlName="nAutorizacion" required />
                  <mat-icon matSuffix>event_18dp</mat-icon>
                  <mat-error *ngIf="addressForm.controls['nAutorizacion'].hasError('required')">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- tipoIngreso -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Ingreso"
                    formControlName="tipoIngreso"
                  >
                    <mat-option
                      *ngFor="let ingreso of tiposIngreso"
                      [value]="ingreso"
                    >
                      {{ ingreso }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoIngreso'].hasError('required')
                    "
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- descripcionIngreso -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Descripcion Ingreso" formControlName="descripcionIngreso" />
                  <mat-icon matSuffix>edit_18dp</mat-icon>
                  <mat-error *ngIf="
                      addressForm.controls['descripcionIngreso'].hasError(
                        'required'
                      )
                    ">
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

           
          </mat-expansion-panel>
        

          <!-- Informacion IMG -->
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>Informacion IMG </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- Capturar img -->
                <div>
                  <input mat-stroked-button (change)="capturarFile($event)" type="file" />
                </div>
                <br />
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            (click)="onSubmit()"
          >
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions> 
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
